// todo: formalize naming convention, construct logo url
// on the fly insead of storing it here explicitly

export const DEFAULT_SKIN = {
  brand_color: '#1E847A',
  map_pin_highlighted_fill_color: '#111',
  map_pin_highlighted_stroke_color: '#111',
}

const createSkin = (logoUrl) => {
  return { logoUrl }
}

const createAwsSkin = (name) => {
  return createSkin(
    `https://leaseup-production.s3.us-east-2.amazonaws.com/company_logos/${name}`
  )
}

export const skins = {
  judsoncre: createAwsSkin('judsoncre_logo.jpg'),
  tdallassmith: createAwsSkin('tdallassmith_logo.png'),
  franklinst: createAwsSkin('franklinst_logo.png'),
  'aspire-cre': createAwsSkin('aspire-cre_logo.png'),
  baumrealty: createAwsSkin('baumrealty_logo.png'),
  kidder: createAwsSkin('kidder_logo.png'),
  naicapital: createAwsSkin('naicapital_logo.png'),
  cretucson: createAwsSkin('cretucson_logo.jpg'),
  verv: createAwsSkin('verv_logo.png'),
  overfeldsnowcap: createAwsSkin('overfeldsnowcap_logo.png'),
  proveras: createAwsSkin('proveras_logo.png'),
  burnsscalo: createAwsSkin('burnsscalo_logo.png'),
  cushwake: createAwsSkin('cushmanwakefield_logo.png'),
  naimexico: createAwsSkin('naimexico_logo.png'),
  'watchhill-re': createAwsSkin('watchhill-re_logo.png'),
  lloyd: createAwsSkin('lloyd_logo.png'),
  collierswa: createAwsSkin('colliers_logo.png'),
  'so-calwarehouses': createAwsSkin('so-calwarehouses_logo.png'),
  'creg-tucson': createAwsSkin('creg_tucson_logo.png'),
  'lee-associates': createAwsSkin('lee_and_associates_logo.jpg'),
  'site-selection-group': createAwsSkin('site_selection_group_logo.png'),
  'svn-latus': createAwsSkin('svn_latus_logo.jpg'),
  'thompson-hennessey': createAwsSkin('thompson_hennessey_logo.png'),
  aleshire: createAwsSkin('aleshire_logo.jpg'),
  allanbaileyjohnson: createAwsSkin('allanbaileyjohnson_logo.png'),
  austinofficespace: createAwsSkin('aos-logo.png'),
  avocatgroup: createAwsSkin('avocat_logo.png'),
  benchmark: createAwsSkin('benchmark_logo.png'),
  bullrealty: createAwsSkin('bullrealty_logo.png'),
  cbre: createAwsSkin('cbre_logo.png'),
  colliers: createAwsSkin('colliers_logo.png'),
  colliershouston: createAwsSkin('colliershouston_logo.png'),
  colliersindianapolis: createAwsSkin('colliers_logo.png'),
  colliersla: createAwsSkin('colliers_logo.png'),
  coppolacheney: createAwsSkin('coppola_cheney_logo.png'),
  coryladuke: createAwsSkin('coryladuke_logo.png'),
  cresa: createAwsSkin('cresa_logo.png'),
  cresaboston: createAwsSkin('cresa_logo.png'),
  crs: createAwsSkin('crs_logo.png'),

  cushmanwakefield: createAwsSkin('cushmanwakefield_logo.png'),
  cwedm: createAwsSkin('cwedm_logo.png'),
  dovetailcre: createAwsSkin('dovetailcre_logo.png'),
  dtspade: createAwsSkin('dtspade_logo.png'),
  encoradvisors: createAwsSkin('encoradvisors_logo.png'),
  groundfloorrealty: createAwsSkin('groundworkrealty_logo.svg'),
  handler: createAwsSkin('handler_logo.jpg'),
  hughesmarino: createAwsSkin('hughesmarino_logo.png'),
  jll: createAwsSkin('jll_logo.png'),
  katzretail: createAwsSkin('katz_retail_logo.png'),
  latterblum: createAwsSkin('latter_blum_logo.png'),
  lennard: createAwsSkin('lennard_logo.jpg'),
  mccallalmy: createAwsSkin('mccallalmy_logo.png'),
  menlocre: createAwsSkin('menlo_logo.png'),
  mgaco: createAwsSkin('mgaco_logo.png'),

  naipartners: createAwsSkin('naipartners_logo.png'),
  nash: createAwsSkin('nash_logo.png'),
  orangetheory: createAwsSkin('orange_theory_logo.svg'),
  oxfordcres: createAwsSkin('oxford_logo.png'),
  recreate: createAwsSkin('recreate_logo.png'),
  regent: createAwsSkin('regent_logo.png'),
  rokos: createAwsSkin('rokos_logo.png'),
  royallepage: createAwsSkin('royallepage_logo.jpg'),
  savills: createAwsSkin('savills_logo.jpg'),
  savillsus: createAwsSkin('savillsus_logo.png'),
  spacesnyc: createAwsSkin('spaces-nyc.png'),
  springboardcres: createAwsSkin('springboard_logo.png'),
  steelegroup: createAwsSkin('steele_group_logo.png'),
  stokasbieri: createAwsSkin('stokasbieri_logo.png'),
  svn: createAwsSkin('svn_logo.png'),
  swearingen: createAwsSkin('swearingen_logo.png'),
  t3advisors: createAwsSkin('t3_logo.jpg'),
  tactix: createAwsSkin('tactix_logo.jpg'),
  tampacre: createAwsSkin('tampa_commercial_real_estate_logo.png'),
  tartan: createAwsSkin('tartan_logo.jpg'),
  tenantside: createAwsSkin('tenant-side-logo.png'),
  thewainwrightgroup: createAwsSkin('thewainwrightgroup_logo.jpg'),
  transwestern: createAwsSkin('transwestern_logo.png'),
  vericre: createAwsSkin('vericre_logo.png'),
  workthere: createAwsSkin('workthere_logo.png'),
  oceanco: createSkin(
    'http://static1.squarespace.com/static/54b43a5fe4b0226e88a7c5e4/t/54d3edd0e4b0fa4c3b2a161f/1604382018929/?format=1500w'
  ),
}
