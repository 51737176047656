import { Container, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import SVGs from './SVGs';
import Theme from './Theme';

const tabs = [
  { name: 'SVGs', component: SVGs },
  { name: 'Theme', component: Theme },
];

function Brand() {
  const [tab, setTab] = useState(0);
  const selection = tabs[tab];

  const selectTab = (event, value) => {
    setTab(value);
  };

  return (
    <Container maxWidth="md">
      <Tabs value={tab} onChange={selectTab}>
        {tabs.map(({ name }) => (
          <Tab key={name} label={name} />
        ))}
      </Tabs>
      <selection.component />
    </Container>
  );
}

export default Brand;
