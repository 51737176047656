import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { SquareCheckbox } from '~/legacy/components'

export const CheckboxField = withStyles({
  container: {
    borderBottom: '1px solid #e0e0e0',
    alignItems: 'center',
    display: 'flex',
    padding: '18px 0',
    cursor: 'pointer',
    width: '100%',
    gap: '14px',
  },
  checkbox: {
    margin: -4,
    color: '#111111',
  },
})(({ children, classes, selected, onChange }) => (
  <div className={classes.container} onClick={() => onChange(!selected)}>
    <SquareCheckbox className={classes.checkbox} checked={selected} />
    <Typography variant="h3">{children}</Typography>
  </div>
))
