import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '~/legacy/components';
import { toast } from '~/legacy/utils/notifications';
import { setSurveyListingStatus } from '~/legacy/store/actions/surveyListingsActions';
import { useApiHelper } from '~/legacy/utils/hooks';
// eslint-disable-next-line
import {
  SURVEY_LISTING_STATUSES,
  SURVEY_LISTING_STATUS_CHANGES,
} from '~/legacy/consts';

// The styles for the tenant actions. This method allows us to "easily" share/override styles for specific use cases
const actionsClass = (theme) => {
  return {
    maxWidth: '160px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      maxWidth: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      maxWidth: '100%',
    },
  };
};
const tourButtonClass = (theme) => {
  return {
    marginBottom: '20px',
    [theme.breakpoints.only('sm')]: {
      marginRight: '20px',
      marginBottom: '0',
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: '0',
      marginRight: '4px',
    },
  };
};
const notInterestedButtonClass = (theme) => {
  return {
    [theme.breakpoints.only('xs')]: {
      marginLeft: '4px',
    },
  };
};

// The base styles for tenant actions
const useStylesBase = makeStyles((theme) => ({
  actions: actionsClass(theme),
  tourButton: tourButtonClass(theme),
  notInterestedButton: notInterestedButtonClass(theme),
}));

// The style overrides for the tenant map view
const useStylesTenantMap = makeStyles((theme) => ({
  actions: {
    ...actionsClass(theme),
    display: 'flex',
    maxWidth: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.only('sm')]: null,
    [theme.breakpoints.only('xs')]: null,
  },
  tourButton: {
    ...tourButtonClass(theme),
    marginRight: '20px',
    marginBottom: '0',
    [theme.breakpoints.only('sm')]: null,
    [theme.breakpoints.only('xs')]: null,
  },
  notInterestedButton: {
    ...notInterestedButtonClass(theme),
    [theme.breakpoints.only('xs')]: null,
  },
}));

/**
 * Actions for a tenant to take on a client listing. Shown on multiple pages. Should be
 * used alongside a DECLINE_LISTING modal to handle tenant client listing actions. The modal will be controlled via
 * supplied params setListingToDecline and setDeclineListingModalOpen.
 *
 * forMap (optional): whether or not we are displaying the card on the tenant map or not. Mostly for styling
 */
function TenantActions({
  clientListing,
  setListingToDecline,
  setDeclineListingModalOpen,
  forMap,
}) {
  const classesBase = useStylesBase();
  const classesTenantMap = useStylesTenantMap();
  const dispatch = useDispatch();
  const theme = useTheme();
  const tourRequested =
    clientListing.status === SURVEY_LISTING_STATUSES.TOUR_REQUESTED;

  const actionsClassToUse = forMap
    ? classesTenantMap.actions
    : classesBase.actions;
  const tourButtonClassToUse = forMap
    ? classesTenantMap.tourButton
    : classesBase.tourButton;

  const declineListingTextAlign = forMap ? 'left' : 'center';
  const apiHelper = useApiHelper();

  return (
    <div className={actionsClassToUse}>
      {clientListing.status === SURVEY_LISTING_STATUSES.DECLINED && (
        <Typography align={declineListingTextAlign} variant="body1">
          You have declined this listing. If you change your mind,
          <Link
            className={classesBase.link}
            onClick={(event) => {
              event.stopPropagation();
              const newStatus = SURVEY_LISTING_STATUSES.TOUR_REQUESTED;
              apiHelper
                .updateSurveyListingStatus({
                  surveyListingId: clientListing.id,
                  newStatus,
                  colors: theme.palette.primary,
                  statusChange: newStatus,
                })
                .then(() => {
                  dispatch(setSurveyListingStatus(clientListing.id, newStatus));
                  toast('Tour requested! Your broker has been notified', {
                    appearance: 'success',
                  });
                });
            }}
          >
            <span> click here </span>
          </Link>
          to request a tour.
        </Typography>
      )}
      {clientListing.status !== SURVEY_LISTING_STATUSES.DECLINED && (
        <>
          <Button
            className={tourButtonClassToUse}
            color="primary"
            disabled={tourRequested}
            onClick={(event) => {
              event.stopPropagation();
              const newStatus = SURVEY_LISTING_STATUSES.TOUR_REQUESTED;
              apiHelper
                .updateSurveyListingStatus({
                  surveyListingId: clientListing.id,
                  newStatus,
                  colors: theme.palette.primary,
                  statusChange: newStatus,
                })
                .then(() => {
                  dispatch(setSurveyListingStatus(clientListing.id, newStatus));
                  toast('Tour requested! Your broker has been notified', {
                    appearance: 'success',
                  });
                });
            }}
          >
            {tourRequested ? 'Tour Requested' : 'Request Tour'}
          </Button>
          <Button
            className={classesBase.notInterestedButton}
            onClick={(event) => {
              event.stopPropagation();
              if (tourRequested) {
                // cancel
                const newStatus = SURVEY_LISTING_STATUSES.SHARED;
                apiHelper
                  .updateSurveyListingStatus({
                    surveyListingId: clientListing.id,
                    newStatus,
                    statusChange: SURVEY_LISTING_STATUS_CHANGES.TOUR_CANCELED,
                  })
                  .then(() => {
                    dispatch(
                      setSurveyListingStatus(clientListing.id, newStatus)
                    );
                  });
              } else {
                setListingToDecline(clientListing);
                setDeclineListingModalOpen(true);
              }
            }}
          >
            {tourRequested ? 'Cancel Tour' : 'Not Interested'}
          </Button>
        </>
      )}
    </div>
  );
}
TenantActions.propTypes = {
  clientListing: PropTypes.object.isRequired,
  setListingToDecline: PropTypes.func.isRequired,
  setDeclineListingModalOpen: PropTypes.func.isRequired,
  forMap: PropTypes.bool,
};
TenantActions.defaultProps = {
  forMap: false,
};

export default TenantActions;
