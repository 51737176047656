import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useSWR from 'swr';
import findIndex from 'lodash/findIndex';
import { v4 as uuidv4 } from 'uuid';

import _ from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  buildingApi,
  listingApi,
  surveyBuildingApi,
  surveyListingApi,
} from '~/legacy/fetchApi';
import { uploadFiles } from '~/legacy/utils/fileHelpers';
import { getExtension } from '~/legacy/utils/miscUtils';
import { METADATA_FIELD_TYPES } from '~/legacy/consts';
import { BULK_IMPORT_CONSTANTS, SnackbarUtils } from '~/legacy/utils';
import {
  formatBuildingForDisplayProper,
  formatListingForDisplayProper,
} from '~/legacy/utils/listingHelpers';
import {
  defaultMutateBinder,
  defaultMutateOptimisticBinder,
} from './selectors';

// Fetch fresh bdp data from our backend
const fetchSurveyBuilding = async (surveyId, buildingId, isAnonymous) => {
  return surveyBuildingApi
    .getSurveyBuilding({ surveyId, buildingId, skipAuth: isAnonymous })
    .then(([, responseSurveyBuilding]) => {
      return responseSurveyBuilding;
    });
};

const swrToFormattedSurveyBuilding = (
  swrSurveyBuilding,
  formattedSurveyBuilding = {}
) => {
  return swrSurveyBuilding
    ? _.mergeWith(
        { building: { metadata: [] } },
        formattedSurveyBuilding || {},
        swrSurveyBuilding
      )
    : null;
};

// Our bdp data in SWR
export const SWR_SURVEY_BUILDING = 'surveys/:survey_id/buildings/:building_id';
const DEFAULT_API_ERROR_MESSAGE =
  'Error updating survey building, please try again.';

// TODO: Make the selector a singleton if we use it in multiple places, so we don't have to redo
//   local state reformatting multiple times
// Hook to manage the raw Survey Building in SWR as well as format the raw Survey Building.
export const useSurveyBuildingSelector = (
  surveyId,
  buildingId = null,
  isAnonymous = false
) => {
  const theme = useTheme();
  // Async wrapper for fetching our bdp data from backend
  const swrArgs = [];
  if (surveyId && buildingId) {
    swrArgs.push(async () =>
      fetchSurveyBuilding(surveyId, buildingId, isAnonymous)
    );
  }

  // SWR bdp data
  const {
    data: swrSurveyBuilding,
    error,
    mutate,
  } = useSWR(
    SWR_SURVEY_BUILDING.replace(':survey_id', surveyId).replace(
      ':building_id',
      buildingId
    ),
    ...swrArgs
  );
  const [formattedSurveyBuilding, setFormattedSurveyBuilding] = useState(
    swrToFormattedSurveyBuilding(swrSurveyBuilding)
  );

  const safeSurveyBuilding = swrSurveyBuilding || {};
  const rawBuilding = _.get(swrSurveyBuilding, 'building', null);
  const safeSurveyBuildingFieldOrder = safeSurveyBuilding.field_order || {};
  const buildingAttachments = safeSurveyBuilding.attachments || null;
  const buildingMetadata =
    rawBuilding && Object.keys(rawBuilding).length
      ? {
          property_type: rawBuilding.property_type,
          floors: rawBuilding.floors,
          building_size: rawBuilding.building_size,
          parking_ratio: rawBuilding.parking_ratio,
          description: rawBuilding.description,
          amenities: rawBuilding.amenities,
          custom_fields: rawBuilding.custom_fields,
        }
      : {};
  const surveyListings = _.get(swrSurveyBuilding, 'survey_listings', null);

  // When the swrSurveyBuilding changes, refresh the formatted sb
  useEffect(() => {
    setFormattedSurveyBuilding((currentFormattedSurveyBuilding) => {
      return swrToFormattedSurveyBuilding(
        swrSurveyBuilding,
        currentFormattedSurveyBuilding
      );
    });
  }, [swrSurveyBuilding]);

  // Create a model field.
  const _makeGenericField = (name, reserved, dataType, order) => ({
    name,
    reserved,
    dataType,
    order,
  });

  // When the survey spaces change, remake the ordered metadata
  // We order the space's metadata uniformly. We pluck the order from the first survey space that has an order, or if none do, then
  //   use the order of the default order of the first space

  // Sets: surveyBuilding.ordered_fields
  //   ie: [{name, reserved, dataType, order}, ...]
  // Sets: surveyBuilding.survey_listings[].listing.metadata
  //   ie: [{id, name, value, reserved, dataType}, ...]
  // Sets: surveyBuilding.survey_listings[].listing.metadataLookup
  //   ie: {[name]: {id, name, value, reserved, dataType}, ...}
  // Sets: surveyBuilding.survey_listings[].listing.metadataLookupById
  //   ie: {[id]: {id, name, value, reserved, dataType}, ...}
  useEffect(() => {
    setFormattedSurveyBuilding((currentFormattedSurveyBuilding) => {
      if (!currentFormattedSurveyBuilding) {
        return currentFormattedSurveyBuilding;
      }
      const surveyListingsLocal = surveyListings;
      let newSurveyListings = surveyListingsLocal;
      let uniqueCustomFields = [];
      let newOrderedFields = _.get(
        currentFormattedSurveyBuilding,
        'ordered_fields',
        []
      );

      if (surveyListingsLocal && surveyListingsLocal.length) {
        // Get a unique list of all of the custom field keys to ensure all survey spaces have the same fields in the metadata. Custom and reserved fields.
        // [ {name: "Custom Field 1", reserved: false, dataType: 1}, ... ]
        const arraysOfFields = surveyListingsLocal.map((sl) =>
          sl.listing.custom_fields.map((cf) =>
            _makeGenericField(
              cf.name,
              false,
              BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES_LOOKUP[
                cf.custom_field.data_type
              ]
            )
          )
        );
        uniqueCustomFields =
          arraysOfFields && arraysOfFields.length
            ? _.unionWith(...arraysOfFields, (a, b) => a.name === b.name)
            : [];

        // Create the metadata and metadataLookup for each space so they have the same fields
        newSurveyListings = surveyListingsLocal.map((sl) => {
          const metadata = formatListingForDisplayProper({
            space: sl.listing,
            requiredCustomFieldKeys: uniqueCustomFields,
          });

          return {
            ...sl,
            listing: {
              ...sl.listing,
              metadata,
              metadataLookup: Object.fromEntries(
                metadata.map((m) => [m.name, m])
              ),
              metadataLookupById: Object.fromEntries(
                metadata.map((m) => [m.id, m])
              ),
            },
          };
        });

        // Ordering
        // Get a uniform field ordering for all of the spaces.
        // Grab the metadata of the first space with an order. If there isn't one, then create default ordering from the first space's metadata
        const chosenSurveySpace = newSurveyListings.find(
          (sl) => sl.field_order && sl.field_order.length
        );

        let chosenFieldOrder;
        if (chosenSurveySpace) {
          chosenFieldOrder = chosenSurveySpace.field_order
            .filter(
              (fo) =>
                chosenSurveySpace.listing.metadataLookupById[fo.field_id] !==
                undefined
            )
            .map(
              (fo) => chosenSurveySpace.listing.metadataLookupById[fo.field_id]
            );
        } else {
          chosenFieldOrder = newSurveyListings[0].listing.metadata.map(
            (m, index) => {
              return { ...m, field_id: m.id, order: index };
            }
          );
        }

        // Use the chosen field order to get a uniform order as an array. Names in order
        // Make sure to add any missing custom fields here since not all spaces technically have the same custom fields across a building
        // ie: [{order: 0, name: 'Notes', reserved, dataType},  {order: 1, name: 'Custom Field 1', reserved, dataType}, ...]
        newOrderedFields = chosenFieldOrder.map((cfo, index) =>
          _makeGenericField(cfo.name, cfo.reserved, cfo.dataType, index)
        );
        newOrderedFields = _.unionWith(
          newOrderedFields,
          uniqueCustomFields,
          (a, b) => a.name === b.name
        );

        // Create a lookup dict of the name to order
        // ie: { 'Notes': 0, 'Custom Field 1': 1, 'Price / SwFt': 2, ...}
        const fieldOrderLookup = Object.fromEntries(
          newOrderedFields.map((fo) => [fo.name, fo.order])
        );

        // Finally, order the space's metadata by the uniform order
        newSurveyListings = newSurveyListings.map((sl) => ({
          ...sl,
          listing: {
            ...sl.listing,
            metadata: _.orderBy(
              sl.listing.metadata,
              [(m) => fieldOrderLookup[m.name]],
              ['asc']
            ),
          },
        }));
      }

      return {
        ...currentFormattedSurveyBuilding,
        survey_listings: newSurveyListings,
        ordered_fields: newOrderedFields,
      };
    });
  }, [surveyListings]);

  // When the sb building metadata or field order changes, remake the sorted metadata
  useDeepCompareEffect(() => {
    setFormattedSurveyBuilding((currentFormattedSurveyBuilding) => {
      if (buildingMetadata && Object.keys(buildingMetadata).length) {
        // Sort the building metadata. We get the field ordering, then sort the custom/reserved fields
        const buildingMetadataFormatted =
          formatBuildingForDisplayProper(buildingMetadata);
        let surveyBuildingFieldOrder = [];
        if (
          safeSurveyBuildingFieldOrder &&
          safeSurveyBuildingFieldOrder.length
        ) {
          surveyBuildingFieldOrder = safeSurveyBuildingFieldOrder;
        } else if (buildingMetadataFormatted) {
          surveyBuildingFieldOrder = buildingMetadataFormatted.map(
            (field, index) => ({ ...field, field_id: field.id, order: index })
          );
        }

        let newBuildingMetadataOrdered = buildingMetadataFormatted;
        if (surveyBuildingFieldOrder) {
          // Create an easy lookup of the existing field order
          const lookup = {
            [METADATA_FIELD_TYPES.RESERVED_FIELD]: {},
            [METADATA_FIELD_TYPES.CUSTOM_FIELD]: {},
          };
          surveyBuildingFieldOrder.forEach((m) => {
            lookup[
              m.reserved
                ? METADATA_FIELD_TYPES.RESERVED_FIELD
                : METADATA_FIELD_TYPES.CUSTOM_FIELD
            ][m.field_id] = m.order;
          });
          // Order the metadata by the field order
          newBuildingMetadataOrdered = _.orderBy(
            buildingMetadataFormatted,
            [
              (m) =>
                lookup[
                  m.reserved
                    ? METADATA_FIELD_TYPES.RESERVED_FIELD
                    : METADATA_FIELD_TYPES.CUSTOM_FIELD
                ][m.id],
            ],
            ['asc']
          );
        }
        return {
          ...currentFormattedSurveyBuilding,
          building: {
            ...currentFormattedSurveyBuilding.building,
            metadata: newBuildingMetadataOrdered,
          },
        };
      }
      return currentFormattedSurveyBuilding;
    });
  }, [buildingMetadata, safeSurveyBuildingFieldOrder]);

  useEffect(() => {
    setFormattedSurveyBuilding((currentFormattedSurveyBuilding) =>
      currentFormattedSurveyBuilding
        ? {
            ...currentFormattedSurveyBuilding,
            attachments: buildingAttachments,
          }
        : currentFormattedSurveyBuilding
    );
  }, [buildingAttachments]);

  // Bind our mutators with our default settings and error handling
  const mutateSurveyBuilding = defaultMutateBinder(
    mutate,
    DEFAULT_API_ERROR_MESSAGE
  );
  const mutateSurveyBuildingOptimistic = defaultMutateOptimisticBinder(
    mutateSurveyBuilding,
    DEFAULT_API_ERROR_MESSAGE
  );

  // Update the project via api and then mutate the project state. Optionally update the local state with optimistic data before the API call
  // TODO: Further generalize this? Let's start with this for now
  const mutateUpdate = async (newSurveyBuildingPartial, optimistic = true) => {
    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyBuildingApi
        .updatePartial({
          surveyBuildingId: rawSurveyBuildingLocal.id,
          partial: newSurveyBuildingPartial,
          theme,
        })
        .then(([, responseSurveyBuilding]) => responseSurveyBuilding);

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: { ...safeSurveyBuilding, ...newSurveyBuildingPartial },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateUpdateBuilding = async (
    newBuildingPartial,
    optimistic = true
  ) => {
    const apiMutator = async (rawSurveyBuildingLocal) =>
      buildingApi
        .updatePartial({
          buildingId: rawSurveyBuildingLocal.building.id,
          partial: newBuildingPartial,
          theme,
        })
        .then(([, responseBuilding]) => ({
          ...rawSurveyBuildingLocal,
          building: {
            ...rawSurveyBuildingLocal.building,
            ...responseBuilding.data,
          },
        }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          building: { ...safeSurveyBuilding.building, ...newBuildingPartial },
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateAddSurveyBuildingAttachments = async (
    attachments,
    optimistic = true
  ) => {
    // Spoof attachment models that mimic our API resonse so that the optmistic works and we can show the attachments before officially uploaded
    const newAttachments = attachments.map((attachment) => ({
      name: attachment.name,
      // Spoof the ID, but give them a unique UUID (tempId)
      id: -1,
      tempId: uuidv4(),
      ext: getExtension(attachments[0].name),
      extension: getExtension(attachments[0].name),
      uuid: attachment.uuid,
      rawFile: attachment,
    }));

    const apiMutator = async (rawSurveyBuildingLocal) => {
      // Remove the temporary previews we inserted at the end for the optimistic
      const newTempIds = new Set(
        attachments.map((attachment) => attachment.tempId)
      );
      const currentAttachments = rawSurveyBuildingLocal.attachments.filter(
        (attachment) => newTempIds.has(attachment.tempId)
      );

      return uploadFiles(attachments, 'survey_building_attachments', {
        survey_building_id: rawSurveyBuildingLocal.id,
      })
        .then((resp) =>
          // Add the new attachments
          ({
            ...rawSurveyBuildingLocal,
            attachments: [...currentAttachments, ...resp.data.file],
          })
        )
        .catch(() => {
          // Error, go back to the original attachments
          SnackbarUtils.error('Error uploading attachment, please try again');
          return { ...rawSurveyBuildingLocal, attachments: currentAttachments };
        });
    };

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          attachments: [...safeSurveyBuilding.attachments, ...newAttachments],
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateDeleteSurveyBuildingAttachment = async (
    attachmentId,
    optimistic = true
  ) => {
    const apiMutator = async (rawSurveyBuildingLocal) => {
      return surveyBuildingApi
        .deleteAttachment({ surveyBuildingAttachmentId: attachmentId })
        .then(() => ({
          ...rawSurveyBuildingLocal,
          attachments: [
            ...rawSurveyBuildingLocal.attachments.filter(
              (attachment) => attachment.id !== attachmentId
            ),
          ],
        }));
    };

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          attachments: [
            ...safeSurveyBuilding.attachments.filter(
              (attachment) => attachment.id !== attachmentId
            ),
          ],
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateCreateBuildingCustomField = async (
    name,
    value,
    optimistic = true
  ) => {
    const apiMutator = async (rawSurveyBuildingLocal) =>
      buildingApi
        .createCustomField({
          buildingId: rawSurveyBuildingLocal.building.id,
          name,
          value,
        })
        .then(([, responseCustomField]) => ({
          ...rawSurveyBuildingLocal,
          building: {
            ...rawSurveyBuildingLocal.building,
            custom_fields: rawSurveyBuildingLocal.building.custom_fields.map(
              (cf) => {
                return cf.name === responseCustomField.data.name
                  ? responseCustomField.data
                  : cf;
              }
            ),
          },
        }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          building: {
            ...safeSurveyBuilding.building,
            custom_fields: safeSurveyBuilding.building.custom_fields.concat({
              name,
              custom_field: {
                value,
                data_type: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id, // default new fields to string
              },
            }),
          },
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateUpdateBuildingCustomField = async (
    buildingCustomField,
    optimistic = true
  ) => {
    const incorporateUpdatedCustomField = (
      _surveyBuilding,
      updatedCustomField
    ) => {
      // clone the custom fields to avoid directly modifying SWR's data
      const customFields = [..._surveyBuilding.building.custom_fields];
      customFields.splice(
        findIndex(customFields, ['id', updatedCustomField.id]),
        1,
        updatedCustomField
      );
      return {
        ..._surveyBuilding,
        building: {
          ..._surveyBuilding.building,
          custom_fields: customFields,
        },
      };
    };

    const apiMutator = async (rawSurveyBuildingLocal) =>
      buildingApi
        .updateCustomField({
          buildingId: rawSurveyBuildingLocal.building.id,
          buildingCustomField,
        })
        .then(([, responseCustomField]) =>
          incorporateUpdatedCustomField(
            rawSurveyBuildingLocal,
            responseCustomField.data
          )
        );

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: incorporateUpdatedCustomField(
          safeSurveyBuilding,
          buildingCustomField
        ),
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateDeleteBuildingCustomField = async (
    buildingCustomFieldId,
    optimistic = true
  ) => {
    const filterOutDeletedCustomField = (
      _surveyBuilding,
      deletedCustomFieldId
    ) => ({
      ..._surveyBuilding,
      building: {
        ..._surveyBuilding.building,
        custom_fields: _surveyBuilding.building.custom_fields.filter(
          (cf) => cf.id !== deletedCustomFieldId
        ),
      },
    });

    const apiMutator = async (rawSurveyBuildingLocal) =>
      buildingApi
        .deleteCustomField({
          buildingId: rawSurveyBuildingLocal.building.id,
          buildingCustomFieldId,
        })
        .then(([, responseCustomField]) =>
          filterOutDeletedCustomField(
            rawSurveyBuildingLocal,
            responseCustomField.id
          )
        );

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: filterOutDeletedCustomField(
          safeSurveyBuilding,
          buildingCustomFieldId
        ),
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateChangeBuildingCustomFieldType = async (
    buildingCustomFieldId,
    newFieldTypeId,
    newValue,
    optimistic = true
  ) => {
    const incorporateUpdatedCustomField = (
      _surveyBuilding,
      updatedCustomFieldData
    ) => {
      return {
        ..._surveyBuilding,
        building: {
          ..._surveyBuilding.building,
          custom_fields: _surveyBuilding.building.custom_fields.map((cf) =>
            cf.id === buildingCustomFieldId
              ? { ...cf, ...updatedCustomFieldData }
              : cf
          ),
        },
      };
    };

    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyBuildingApi
        .changeBuildingCustomFieldType({
          buildingCustomFieldId,
          newType: newFieldTypeId,
          newValue,
        })
        .then(([, responseCustomField]) =>
          incorporateUpdatedCustomField(
            rawSurveyBuildingLocal,
            responseCustomField
          )
        );

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: incorporateUpdatedCustomField(safeSurveyBuilding, {
          custom_field: {
            data_type: newFieldTypeId,
            value: newValue,
          },
        }),
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateDeleteSurveyListing = async (
    surveyListingId,
    optimistic = true
  ) => {
    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyListingApi.deleteSurveyListing({ surveyListingId }).then(() => ({
        ...rawSurveyBuildingLocal,
        survey_listings: rawSurveyBuildingLocal.survey_listings.filter(
          (sl) => sl.id !== surveyListingId
        ),
      }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: safeSurveyBuilding.survey_listings.filter(
            (sl) => sl.id !== surveyListingId
          ),
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateUpdateListing = async (
    listingId,
    newListingPartial,
    optimistic = true
  ) => {
    const incorporateUpdatedListing = (_surveyListings, updatedListing) =>
      _surveyListings.map((sl) => {
        if (sl.listing.id === updatedListing.id) {
          return {
            ...sl,
            listing: {
              ...sl.listing,
              ...updatedListing,
            },
          };
        }
        return { ...sl };
      });

    const apiMutator = async (rawSurveyBuildingLocal) =>
      listingApi
        .updatePartial({
          listingId,
          partial: newListingPartial,
        })
        .then(([, responseListing]) => ({
          ...rawSurveyBuildingLocal,
          survey_listings: incorporateUpdatedListing(
            rawSurveyBuildingLocal.survey_listings,
            responseListing
          ),
        }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: incorporateUpdatedListing(
            safeSurveyBuilding.survey_listings,
            { id: listingId, ...newListingPartial }
          ),
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateCreateSurveyListing = async ({ surveyBuildingId }) => {
    const apiMutator = async () =>
      surveyBuildingApi
        .createSurveyListing({
          surveyBuildingId,
        })
        .then(([, responseSurveyBuilding]) => responseSurveyBuilding.data);

    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateCreateListingCustomField = async (
    listingId,
    name,
    value,
    optimistic = true
  ) => {
    const incorporateNewCustomField = (
      _surveyListings,
      _listingId,
      newCustomField
    ) =>
      _surveyListings.map((sl) => {
        if (sl.listing.id === _listingId) {
          return {
            ...sl,
            listing: {
              ...sl.listing,
              custom_fields: sl.listing.custom_fields
                // filter out the optimistically added field if present (it has no id)
                .filter((cf) => cf.name !== newCustomField.name)
                .concat(newCustomField),
            },
          };
        }
        return {
          ...sl,
        };
      });

    const apiMutator = async (rawSurveyBuildingLocal) =>
      listingApi
        .createCustomField({
          listingId,
          name,
          value,
        })
        .then(([, responseCustomField]) => ({
          ...rawSurveyBuildingLocal,
          survey_listings: incorporateNewCustomField(
            rawSurveyBuildingLocal.survey_listings,
            listingId,
            responseCustomField.data
          ),
        }));
    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: incorporateNewCustomField(
            safeSurveyBuilding.survey_listings,
            listingId,
            {
              name,
              custom_field: {
                value,
                data_type: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id, // default new fields to string
              },
            }
          ),
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateCreateListingCustomFields = async (
    listingIds,
    name,
    valueMap,
    optimistic = true
  ) => {
    const apiMutator = async (rawSurveyBuildingLocal) =>
      listingApi
        .createCustomFields({
          listingIds,
          name,
          valueMap,
        })
        .then(([, responseListings]) => ({
          ...rawSurveyBuildingLocal,
          survey_listings: rawSurveyBuildingLocal.survey_listings.map((sl) => ({
            ...sl,
            listing: {
              ...sl.listing,
              ...responseListings.find((rl) => rl.id === sl.listing.id),
            },
          })),
        }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: safeSurveyBuilding.survey_listings.map((sl) => ({
            ...sl,
            listing: {
              ...sl.listing,
              custom_fields: sl.listing.custom_fields.concat({
                name,
                custom_field: {
                  value: valueMap[sl.listing.id],
                  data_type: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id, // default new fields to string
                },
              }),
            },
          })),
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateUpdateListingCustomField = async (
    listingId,
    listingCustomField,
    optimistic = true
  ) => {
    const incorporateUpdatedCustomField = (
      _surveyListings,
      updatedCustomField
    ) =>
      _surveyListings.map((sl) => {
        if (sl.listing.id === listingId) {
          // clone the custom fields to avoid directly modifying SWR's data
          const customFields = [...sl.listing.custom_fields];
          customFields.splice(
            findIndex(customFields, ['id', updatedCustomField.id]),
            1,
            updatedCustomField
          );
          return {
            ...sl,
            listing: {
              ...sl.listing,
              custom_fields: customFields,
            },
          };
        }
        return sl;
      });
    const apiMutator = async (rawSurveyBuildingLocal) =>
      listingApi
        .updateCustomField({
          listingId,
          listingCustomField,
        })
        .then(([, responseCustomField]) => ({
          ...rawSurveyBuildingLocal,
          survey_listings: incorporateUpdatedCustomField(
            rawSurveyBuildingLocal.survey_listings,
            responseCustomField.data
          ),
        }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: incorporateUpdatedCustomField(
            safeSurveyBuilding.survey_listings,
            listingCustomField
          ),
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateDeleteListingCustomField = async (
    listingId,
    listingCustomFieldId,
    optimistic = true
  ) => {
    const filterOutDeletedCustomField = (
      _surveyListings,
      deletedCustomFieldId
    ) =>
      _surveyListings.map((sl) => {
        if (sl.listing.id === listingId) {
          return {
            ...sl,
            listing: {
              ...sl.listing,
              custom_fields: sl.listing.custom_fields.filter(
                (cf) => cf.id !== deletedCustomFieldId
              ),
            },
          };
        }
        return sl;
      });
    const apiMutator = async (rawSurveyBuildingLocal) =>
      listingApi
        .deleteCustomField({
          listingId,
          listingCustomFieldId,
        })
        .then(([, responseCustomField]) => ({
          ...rawSurveyBuildingLocal,
          survey_listings: filterOutDeletedCustomField(
            rawSurveyBuildingLocal.survey_listings,
            responseCustomField.data.id
          ),
        }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: filterOutDeletedCustomField(
            safeSurveyBuilding.survey_listings,
            listingCustomFieldId
          ),
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateDeleteListingCustomFields = async (
    spaceCustomFieldName,
    optimistic = true
  ) => {
    const filterOutDeletedCustomFields = (_surveyListings) =>
      _surveyListings.map((sl) => {
        return {
          ...sl,
          listing: {
            ...sl.listing,
            custom_fields: sl.listing.custom_fields.filter(
              (cf) => cf.name !== spaceCustomFieldName
            ),
          },
        };
      });
    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyBuildingApi
        .deleteCustomFields({
          surveyBuildingId: rawSurveyBuildingLocal.id,
          spaceCustomFieldName,
        })
        .then(() => ({
          ...rawSurveyBuildingLocal,
          survey_listings: filterOutDeletedCustomFields(
            rawSurveyBuildingLocal.survey_listings
          ),
        }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: filterOutDeletedCustomFields(
            safeSurveyBuilding.survey_listings
          ),
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateChangeListingCustomFieldsType = async (
    listingIds,
    name,
    valueMap,
    dataType,
    optimistic = true
  ) => {
    const apiMutator = async (rawSurveyBuildingLocal) =>
      listingApi
        .changeCustomFieldDataType({
          listingIds,
          name,
          valueMap,
          dataType,
        })
        .then(([, responseListings]) => ({
          ...rawSurveyBuildingLocal,
          survey_listings: rawSurveyBuildingLocal.survey_listings.map((sl) => ({
            ...sl,
            listing: {
              ...sl.listing,
              ...responseListings.find((rl) => rl.id === sl.listing.id),
            },
          })),
        }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: safeSurveyBuilding.survey_listings.map((sl) => ({
            ...sl,
            listing: {
              ...sl.listing,
              custom_fields: sl.listing.custom_fields.map((cf) =>
                cf.name === name
                  ? {
                      ...cf,
                      custom_field: {
                        ...cf.custom_field,
                        value: valueMap[sl.listing.id],
                        data_type: dataType,
                      },
                    }
                  : cf
              ),
            },
          })),
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateClearSpacesField = async (modelFieldName, optimistic = true) => {
    const clearSpaceField = (_surveyListings) =>
      _surveyListings.map((sl) => {
        return {
          ...sl,
          listing: {
            ...sl.listing,
            [modelFieldName]: null,
          },
        };
      });
    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyBuildingApi
        .clearSpacesField({
          surveyBuildingId: rawSurveyBuildingLocal.id,
          modelFieldName,
        })
        .then(() => ({
          ...rawSurveyBuildingLocal,
          survey_listings: clearSpaceField(
            rawSurveyBuildingLocal.survey_listings
          ),
        }));

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: {
          ...safeSurveyBuilding,
          survey_listings: clearSpaceField(safeSurveyBuilding.survey_listings),
        },
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateRenameBuildingCustomField = async (
    buildingCustomFieldId,
    newFieldName,
    optimistic = true
  ) => {
    const renameBuildingCustomField = (_surveyBuilding, newFieldData) => ({
      ..._surveyBuilding,
      building: {
        ..._surveyBuilding.building,
        custom_fields: _surveyBuilding.building.custom_fields.map((cf) =>
          cf.id === newFieldData.id ? { ...cf, ...newFieldData } : cf
        ),
      },
    });

    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyBuildingApi
        .renameBuildingCustomField({
          buildingCustomFieldId,
          newName: newFieldName,
        })
        .then(([, responseCustomField]) =>
          renameBuildingCustomField(rawSurveyBuildingLocal, responseCustomField)
        );

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: renameBuildingCustomField(safeSurveyBuilding, {
          id: buildingCustomFieldId,
          name: newFieldName,
        }),
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateRenameListingCustomFields = async (
    listingCustomFieldIds,
    newFieldName,
    optimistic = true
  ) => {
    const renameListingCustomFields = (_surveyBuilding, newFields) => ({
      ..._surveyBuilding,
      survey_listings: _surveyBuilding.survey_listings.map((sl) => ({
        ...sl,
        listing: {
          ...sl.listing,
          custom_fields: sl.listing.custom_fields.map((cf) => {
            const foundField = newFields.find(
              (newField) => newField.id === cf.id
            );
            if (foundField) {
              return { ...cf, ...foundField };
            }
            return cf;
          }),
        },
      })),
    });

    const apiMutator = async (rawSurveyBuildingLocal) =>
      surveyBuildingApi
        .renameListingCustomFields({
          surveyBuildingId: rawSurveyBuildingLocal.id,
          listingCustomFieldIds,
          newName: newFieldName,
        })
        .then(([, responseCustomFields]) =>
          renameListingCustomFields(
            rawSurveyBuildingLocal,
            responseCustomFields
          )
        );

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: renameListingCustomFields(
          safeSurveyBuilding,
          listingCustomFieldIds.map((id) => ({
            id,
            name: newFieldName,
          }))
        ),
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateSetFieldOrder = async ({ fieldOrder, optimistic = false }) => {
    const setFieldOrder = (_surveyBuilding, newFieldOrder) => ({
      ..._surveyBuilding,
      field_order: [...newFieldOrder],
    });

    const apiMutator = async () =>
      surveyBuildingApi
        .setFieldOrder({
          surveyBuildingId: safeSurveyBuilding.id,
          fieldOrder: fieldOrder.map((fo) => fo.field_id),
        })
        .then(([, responseCustomFields]) =>
          setFieldOrder(safeSurveyBuilding, responseCustomFields)
        );

    if (optimistic) {
      return mutateSurveyBuildingOptimistic({
        newObject: setFieldOrder(safeSurveyBuilding, fieldOrder),
        mutator: apiMutator,
      });
    }
    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateSetSpacesFieldOrder = async ({ fieldOrder }) => {
    const apiMutator = async () =>
      surveyBuildingApi
        .setSpacesFieldOrder({
          surveyBuildingId: safeSurveyBuilding.id,
          fieldOrder,
        })
        .then(([, response]) => response);

    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  const mutateCopySpacesFromSurvey = async ({ copySpacesFromSurveyId }) => {
    const apiMutator = async () =>
      surveyBuildingApi
        .copySpacesFromSurvey({
          surveyBuildingId: safeSurveyBuilding.id,
          copySpacesFromSurveyId,
        })
        .then(([, response]) => response);

    return mutateSurveyBuilding({ mutator: apiMutator });
  };

  return {
    surveyBuilding: formattedSurveyBuilding,
    swrSurveyBuilding,
    mutate,
    mutateUpdate,
    mutateUpdateBuilding,
    mutateCreateBuildingCustomField,
    mutateUpdateBuildingCustomField,
    mutateDeleteBuildingCustomField,
    mutateRenameBuildingCustomField,
    mutateChangeBuildingCustomFieldType,
    mutateDeleteSurveyListing,
    mutateUpdateListing,
    mutateCreateListingCustomField,
    mutateCreateListingCustomFields,
    mutateUpdateListingCustomField,
    mutateDeleteListingCustomField,
    mutateDeleteListingCustomFields,
    mutateRenameListingCustomFields,
    mutateChangeListingCustomFieldsType,
    mutateAddSurveyBuildingAttachments,
    mutateDeleteSurveyBuildingAttachment,
    mutateClearSpacesField,
    mutateCreateSurveyListing,
    mutateSetFieldOrder,
    mutateSetSpacesFieldOrder,
    mutateCopySpacesFromSurvey,
    loading: !swrSurveyBuilding || !formattedSurveyBuilding,
    error,
  };
};
