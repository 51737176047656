export const SET_SURVEY_LISTINGS_DATA = 'SET_SURVEY_LISTINGS_DATA';
export const SET_SURVEY_LISTINGS_DATA_STATUS =
  'SET_SURVEY_LISTINGS_DATA_STATUS';

export function setSurveyListings(surveyListings) {
  return { type: SET_SURVEY_LISTINGS_DATA, surveyListings };
}
export function setSurveyListingStatus(id, status) {
  return { type: SET_SURVEY_LISTINGS_DATA_STATUS, id, status };
}
