export default {
  deleteSurveyComment: {
    url: '/survey_comments/:id/',
    options: {
      method: 'DELETE',
    },
  },
  createSurveyComment: {
    url: '/survey_comments/',
    options: {
      method: 'POST',
    },
  },
  createSurveyComparisonComment: {
    url: '/survey_comparison_comments/',
    options: {
      method: 'POST',
    },
  },
  deleteSurveyComparisonComment: {
    url: '/survey_comparison_comments/:id/',
    options: {
      method: 'DELETE',
    },
  },
};
