import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { SimpleCompressedSourceImage } from '~/legacy/components'
import { useLogoUrl } from '~/legacy/utils'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px',
  },
  companyLogo: {
    maxWidth: '90px',
    maxHeight: '30px',
    objectFit: 'contain',
    marginBottom: '24px',
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    marginTop: '4px',
  },
  brokersContainer: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    marginTop: '24px',
    paddingLeft: '8px',
  },
  brokerCard: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  coverImageContainer: {
    overflow: 'hidden',
    flex: 1,
    width: '100%',
    maxHeight: '40%',
    marginTop: 'auto',
  },
  coverImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
}))

function CoverPageLayout2({ settings, surveyName }) {
  const { brokers, subtitle, replacementImage } = settings

  const classes = useStyles({ brokers })
  const logoUrl = useLogoUrl()

  return (
    <div className={classes.container}>
      {replacementImage.id && (
        <div className={classes.replacementImageContainer}>
          <SimpleCompressedSourceImage
            imageFile={replacementImage}
            ImgProps={{
              alt: 'cover',
              className: classes.replacementImage,
            }}
          />
        </div>
      )}
      {!replacementImage.id && (
        <>
          <div className={classes.topContainer}>
            <img
              className={classes.companyLogo}
              src={logoUrl}
              alt="company logo"
            />
            <div className={classes.textContent}>
              <Typography variant="h2">{surveyName}</Typography>
              <Typography className={classes.subtitle} variant="body1">
                {subtitle}
              </Typography>
              <div className={classes.brokersContainer}>
                {brokers.map((broker) => (
                  <div key={broker.email} className={classes.brokerCard}>
                    <Typography variant="h3">{`${broker.first_name} ${broker.last_name}`}</Typography>
                    <Typography variant="body1">{broker.email}</Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={classes.coverImageContainer}>
            <img
              className={classes.coverImage}
              src="https://leaseup-production.s3.us-east-2.amazonaws.com/pdf_cover_small.jpg"
              alt="hero"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default CoverPageLayout2
