import { useRef, useState } from 'react'

export const useEnterField = (value, setValue) => {
  const [state, setState] = useState(null)
  const editing = useRef(false)

  return {
    value: state == null ? value : state,
    onBlur: () => {
      if (!editing.current) return
      if (state !== value) setValue(state)
      editing.current = false
      setState(null)
    },
    onChange: (event) => {
      setState(event.target.value)
    },
    onFocus: () => {
      editing.current = true
      setState(value)
    },
    onKeyDown: (event) => {
      if (event.key === 'Enter') {
        event.target.blur()
      } else if (event.key === 'Escape') {
        setState(null)
        editing.current = false
        event.target.blur()
      }
    },
  }
}
