import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getViewBuildingRoute, getViewListingRoute } from '~/legacy/utils';
import { EditIcon } from '~/legacy/components';
import { BASE_ICON_STYLES } from './ButtonUtils';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}));

const EditListingMenuItem = React.forwardRef(
  ({ listingId, surveyId, buildingId, isBdpBuildout, ...props }, ref) => {
    const classes = useStyles();
    const history = useHistory();

    return (
      <MenuItem
        ref={ref}
        className={classes.menuItemRoot}
        onClick={() =>
          history.push(
            !isBdpBuildout
              ? getViewListingRoute(listingId, surveyId)
              : getViewBuildingRoute(surveyId, buildingId)
          )
        }
        {...props}
      >
        <EditIcon className={classes.icon} />
        <Typography className={classes.text}> Edit Space </Typography>
      </MenuItem>
    );
  }
);

export default EditListingMenuItem;
