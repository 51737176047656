import React, { useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ClearTextInputSvg } from '~/legacy/components'
import { useFieldWithDelay } from '~/support/useFieldDelay'
import { useSearchMaps } from '~/support/useLocationSearch'
import { Text } from './Text'

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },

  popover: {
    top: '100%',
    left: '0px',
    right: '0px',
    position: 'absolute',
    borderRadius: '4px',
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
  },

  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  clear: {
    transform: 'scale(57.5%)',
  },
})

const geocoder = new google.maps.Geocoder()

export const Location = (props) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const field = useFieldWithDelay(search, setSearch)
  const results = useSearchMaps(field.value)

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(prediction) => {
        return typeof prediction === 'string'
          ? prediction
          : prediction.description
      }}
      autoHighlight
      freeSolo
      options={results.data ?? []}
      autoComplete
      filterSelectedOptions
      clearOnEscape
      clearOnBlur
      value={props.value}
      onChange={async (event, newValue) => {
        if (newValue == null) {
          props.onChange?.(null)
        } else if (typeof newValue === 'string') {
          props.onChange?.(null)
          setSearch('')
        } else {
          const response = await geocoder.geocode({
            placeId: newValue.place_id,
          })

          response.results.forEach((result) => {
            const { lat, lng } = result.geometry.location

            props.onChange?.({
              id: newValue.place_id,
              description: newValue.description,
              coordinates: [lng(), lat()],
            })
          })
        }
      }}
      closeIcon={<ClearTextInputSvg className={classes.clear} />}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue)
      }}
      renderInput={(params) => (
        <Text {...params} placeholder={props.placeholder} fullWidth />
      )}
      renderOption={(prediction) => {
        return (
          <div className={classes.item}>
            <Typography className={classes.label} variant="h3">
              {prediction.structured_formatting.main_text}
            </Typography>

            <Typography className={classes.label} variant="body1">
              {prediction.structured_formatting.secondary_text}
            </Typography>
          </div>
        )
      }}
    />
  )
}
