import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAvatarGroup from '@material-ui/lab/AvatarGroup';

import { Avatar } from '~/legacy/components';

export const AvatarGroup = withStyles({
  root: {
    overflowX: 'auto',
  },
})(({ classes, max, children, startingZIndex = 0 }) => {
  const numChildrenToShow = max || (children && children.length) || 0;
  const numChildrenHidden =
    max && children && children.length ? children.length - max : 0;
  let childZIndex = numChildrenToShow + startingZIndex;

  if (!children) {
    return '';
  }

  return (
    <MuiAvatarGroup max={999999} classes={{ root: classes.root }}>
      {React.Children.map(children.slice(0, numChildrenToShow), (child) =>
        React.cloneElement(child, { ...child.props, zIndex: --childZIndex })
      )}
      {!!(numChildrenHidden > 0) && (
        <Avatar text={`+${numChildrenHidden}`} zIndex={--childZIndex} />
      )}
    </MuiAvatarGroup>
  );
});
