import React, { useState, useCallback } from 'react';

import {
  useEditProjectMenuItem,
  useDeleteProjectMenuItem,
} from '~/legacy/components';
import { Menu } from './Menu';

// TODO: Generalize / abstract menu creation and item addition. Will do as part of Survey adding on PDP
export const useProjectMenu = (
  updateProject = async () => {},
  deleteProject = async () => {}
) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState({});
  const selectedProject =
    anchorMenuEl && anchorMenuEl.project ? anchorMenuEl.project : {};

  const handleMenuClick = useCallback((event, project) => {
    setAnchorMenuEl({ anchor: event.currentTarget, project });
  }, []);

  const handleMenuClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorMenuEl({ ...anchorMenuEl, anchor: null });
  };

  // Edit Project Menu Item
  const { EditProjectMenuItemComponent, EditProjectMenuItemModalComponent } =
    useEditProjectMenuItem({
      project: selectedProject,
      updateProject,
      handleMenuClose,
    });

  // Delete Project Menu Item
  const {
    DeleteProjectMenuItemComponent,
    DeleteProjectMenuItemModalComponent,
  } = useDeleteProjectMenuItem({
    project: selectedProject,
    deleteProject,
    handleMenuClose,
  });

  const ProjectMenuComponent = (
    <ProjectMenu
      anchorMenuEl={anchorMenuEl ? anchorMenuEl.anchor : null}
      handleMenuClose={handleMenuClose}
      EditProjectMenuItemComponent={EditProjectMenuItemComponent}
      DeleteProjectMenuItemComponent={DeleteProjectMenuItemComponent}
    />
  );

  return {
    ProjectMenuComponent,
    handleMenuClick,
    EditProjectMenuItemModalComponent,
    DeleteProjectMenuItemModalComponent,
  };
};

export const ProjectMenu = ({
  anchorMenuEl,
  handleMenuClose,
  EditProjectMenuItemComponent,
  DeleteProjectMenuItemComponent,
}) => {
  return (
    <Menu
      id="more-options-menu"
      anchorEl={anchorMenuEl}
      open={!!anchorMenuEl}
      onClose={handleMenuClose}
      getContentAnchorEl={null}
    >
      {EditProjectMenuItemComponent}
      {DeleteProjectMenuItemComponent}
    </Menu>
  );
};
