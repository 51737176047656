import React from 'react';
import { format, parse } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker as MuiDatePicker,
} from '@material-ui/pickers';

function DatePicker({ disablePast = false, label, onChange, required, value }) {
  const date = value ? parse(value, 'yyyy-MM-dd', new Date()) : new Date();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiDatePicker
        autoOk
        disableToolbar
        disablePast={disablePast}
        format="MM-dd-yyyy"
        fullWidth
        id="date-picker-inline"
        inputVariant="outlined"
        label={label}
        onChange={newDate => {
          onChange(format(newDate, 'yyyy-MM-dd'));
        }}
        required={required}
        variant="inline"
        value={date}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;
