import { number, prop, styled, when } from '~/libraries/styled'

const keys = [
  'align',
  'background',
  'gap',
  'grow',
  'inline',
  'justify',
  'margin',
  'padding',
]

export const Stack = styled('div', keys, {
  display: 'flex',
  flexShrink: 0,
  alignItems: prop('align'),
  backgroundColor: prop('background'),
  flexGrow: when('grow', 1),
  flexDirection: when('inline', 'row', 'column'),
  justifyContent: prop('justify'),
  padding: prop('padding'),
  margin: prop('margin'),
  gap: number('gap'),
})
