import React from 'react'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { CresaDivider } from '../patterns/CresaDivider'

export const CresaIteneraryDivider = definePageTemplate({
  id: '4787944a-4a6d-4ac1-a912-d924c81b8ddb',

  name: 'Tour Itenerary Divider',

  Page: () => {
    return <CresaDivider title="Tour Itinerary" />
  },
})
