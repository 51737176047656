import React from 'react'
import { Collapse, lighten, makeStyles } from '@material-ui/core'
import { Typography, TextLink, CloseIcon } from '~/legacy/components'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'sticky',
    width: '100%',
    top: '0px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    zIndex: 100,
  },
  collapse: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 32px 0 32px',
    height: '60px',
    gap: '12px',
  },
  text: {
    lineHeight: '24px',
    flex: 1,
  },
  closeIcon: {
    cursor: 'pointer',
  },
}))

export const FtuxBanner = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Collapse in={props.visible} classes={{ wrapperInner: classes.collapse }}>
        <div className={classes.content}>
          <Typography className={classes.text} variant="bodyBold">
            {props.children}
          </Typography>
          {props.cta && <TextLink onClick={props.onCta}>{props.cta}</TextLink>}
          <CloseIcon className={classes.closeIcon} onClick={props.onClose} />
        </div>
      </Collapse>
    </div>
  )
}
