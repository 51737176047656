import { Button } from '@material-ui/core'
import range from 'lodash/range'
import React from 'react'
import { SimpleSelect, Skeleton, Spacer, Typography } from '~/legacy/components'
import { styled } from '~/legacy/utils/styleHelpers'
import { useProjectOrder } from '../hooks/useProjectOrder'
import { Project, ProjectSkeleton } from './Project'

export const ProjectList = ({
  children,
  data,
  onAction,
  onSelect,
  ...props
}) => {
  const order = useProjectOrder(data)
  const count = data.length
  const empty = count === 0

  const handleSelect = (project) => () => {
    if (onSelect) onSelect(project)
  }

  return (
    <Container {...props}>
      <Controls>
        <Count>{`${count} Projects`}</Count>

        {!empty && (
          <SimpleSelect
            label={`Order by ${order.label}`}
            menu={{ style: { width: 228 } }}
            value={order.value}
            options={order.options}
            onSelect={order.select}
          />
        )}

        <Spacer />

        <Button
          variant="contained"
          color="primary"
          onClick={onAction}
          disableElevation
        >
          Create Project
        </Button>
      </Controls>

      {order.data.map((project) => (
        <Project
          key={project.id}
          onSelect={handleSelect(project)}
          {...project}
        />
      ))}

      {empty && children}
    </Container>
  )
}

export const ProjectListSkeleton = ({ count = 3, ...props }) => (
  <Container {...props}>
    <Controls>
      <Skeleton width={250} height={16} rounded={2} />

      <Spacer />

      <Button variant="contained" color="primary" disabled disableElevation>
        Create Project
      </Button>
    </Controls>

    {range(count).map((key) => (
      <ProjectSkeleton key={key} />
    ))}
  </Container>
)

const Container = styled('div', {
  flexDirection: 'column',
  display: 'flex',
  gap: '16px',
})

const Controls = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
})

const Count = styled(Typography, {
  color: '#111111',
})

Count.defaultProps = {
  variant: 'boldText',
}
