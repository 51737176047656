import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import {
  DefaultMenu,
  useMenuAnchor,
  useDeleteSpaceFieldMenuItem,
  FieldDataTypeMenuSection,
  ConfirmModalComponent,
} from '~/legacy/components'
import { Typography } from '~/legacy/components/themeComponents'

const useStyles = makeStyles({
  menuRoot: {
    width: '227px',
  },
  fieldHeaderMenuSection: {
    color: '#666',
    paddingTop: '20px',
    paddingBottom: '6px',
  },
  menuItem: {
    paddingLeft: '12px',
    paddingRight: '12px',
    alignItems: 'center',
  },
})

// Get the menu for editing a space field
export const useFieldMenu = ({
  isBuildingField,
  deleteField = () => {},
  changeCustomFieldDataType = () => {},
}) => {
  const classes = useStyles()
  // Get the common menu anchor controls
  const { anchorMenuEl, setAnchorMenuEl, handleMenuClose } = useMenuAnchor()

  const [newFieldType, setNewFieldType] = useState(null)

  // Get the selected field details from the anchor
  // Building only
  const selectedFieldId =
    anchorMenuEl && anchorMenuEl.fieldId ? anchorMenuEl.fieldId : null

  // Space only
  const selectedFieldName =
    anchorMenuEl && anchorMenuEl.fieldName ? anchorMenuEl.fieldName : null

  // Space / Building Shared
  let fieldType =
    anchorMenuEl && anchorMenuEl.fieldType ? anchorMenuEl.fieldType : null
  const selectedBuildingName =
    anchorMenuEl && anchorMenuEl.buildingName ? anchorMenuEl.buildingName : null
  const selectedModelFieldName =
    anchorMenuEl && anchorMenuEl.modelFieldName
      ? anchorMenuEl.modelFieldName
      : null
  const isCustomField = !selectedModelFieldName

  // Get the Delete Field Menu Item
  const { DeleteSpaceFieldMenuItemComponent, DeleteSpaceFieldModalComponent } =
    useDeleteSpaceFieldMenuItem({
      fieldName: selectedFieldName,
      buildingName: selectedBuildingName,
      isCustomField: !selectedModelFieldName,
      isBuildingField,
      deleteSpaceField: () => {
        return deleteField({
          modelFieldName: selectedModelFieldName,
          spaceFieldName: selectedFieldName,
          fieldId: selectedFieldId,
        })
      },
      handleMenuClose,
    })

  // Get the Menu
  const SpaceFieldMenuComponent = (
    <DefaultMenu
      classes={{
        paper: classes.menuRoot,
      }}
      anchorMenuEl={anchorMenuEl}
      handleMenuClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {isCustomField && (
        <div>
          <FieldDataTypeMenuSection
            sectionLabel="Field Type"
            onClick={(_newFieldType) => {
              setNewFieldType(_newFieldType)
              handleMenuClose()
            }}
            fieldDataTypeId={fieldType}
          />
        </div>
      )}
      {isCustomField && (
        <div
          key="fieldDataType"
          className={clsx(classes.fieldHeaderMenuSection, classes.menuItem)}
        >
          <Typography variant="tableHeaderAlt">Delete</Typography>
        </div>
      )}
      {DeleteSpaceFieldMenuItemComponent}
    </DefaultMenu>
  )

  const changeFieldType = () => {
    if (isBuildingField) {
      // validatedValue.value will be null if there is an error validating the field
      // this is fine as we want to clear the value in that case anyway
      const validatedValue = newFieldType.validator(
        anchorMenuEl.buildingFieldValue
      )
      changeCustomFieldDataType(
        selectedFieldId,
        newFieldType.id,
        validatedValue.value
      )
      fieldType = newFieldType.id
      setNewFieldType(null)
    } else {
      changeCustomFieldDataType(selectedFieldName, newFieldType)
      fieldType = newFieldType.id
      setNewFieldType(null)
    }
  }

  const ConfirmChangeFieldTypeModalComponent = (
    <ConfirmModalComponent
      ModalComponentProps={{
        open: !!newFieldType,
        onClose: () => setNewFieldType(null),
      }}
      onClose={() => setNewFieldType(null)}
      onConfirm={changeFieldType}
      title="Change Data Type"
      confirmButtonLabel="Change"
      text={`Are you sure you want to change the ${selectedFieldName} field data type to ${
        newFieldType ? newFieldType.name : ''
      }? This may delete the field’s current values.`}
    />
  )

  return {
    setAnchorMenuEl,
    SpaceFieldMenuComponent,
    DeleteSpaceFieldModalComponent,
    ConfirmChangeFieldTypeModalComponent,
  }
}
