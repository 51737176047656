import React from 'react'
import { Mapbox } from '~/components/Mapbox'
import { styled } from '~/libraries/styled'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { buildLocationName } from '../../leaseup/support/buildLocationName'
import { Font } from '../patterns/Font'
import CalgaryLogo from '../patterns/CalgaryLogo'

export const CalgaryMap = definePageTemplate({
  id: '23f3005c-df8b-4be5-8a3d-c4e9a7a0e951',

  name: 'Map + Buildings',

  props: (pdf, area) => ({
    name: pdf.data.name,
    area: area.name,
    locations: area.buildings.map((building, index) => ({
      id: building.id,
      name: buildLocationName(building.location),
      coordinates: building.location.coordinates,
      order: index + 1,
    })),
  }),

  Page: (props) => {
    const coordinates = props.locations.map((location) => {
      return location.coordinates
    })

    return (
      <Page>
        <Header>
          <Font format="78/94/300" color="#ffffff" truncate>
            {props.name}
          </Font>
          <Font format="78/94/300" color="#ffffff" truncate>
            -
          </Font>
          <Font format="78/94/700" color="#ffffff" truncate>
            {`${props.area} Options`}
          </Font>
        </Header>
        <Content>
          <Locations>
            {props.locations.map((location) => (
              <Location key={location.id}>
                <Number>
                  <Font format="29/59/400" color="#FF0000">
                    {location.order}
                  </Font>
                </Number>
                <Text>
                  <Font format="29/59/300" truncate>
                    {location.name}
                  </Font>
                </Text>
              </Location>
            ))}
          </Locations>
          <Mapbox
            coordinates={coordinates}
            width={1105}
            height={1221}
            scale={2.5}
          />
        </Content>
        <Logo />
      </Page>
    )
  },
})

const Page = styled('div', {
  flexDirection: 'column',
  position: 'absolute',
  display: 'flex',
  inset: '0px',
  overflow: 'hidden',
})

const Header = styled('div', {
  backgroundColor: '#000000',
  flexShrink: 0,
  minWidth: '0px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '68px',
  padding: '12px 92px 27px',
  gap: '24px',
})

const Content = styled('div', {
  padding: '130px 100px 148px',
  display: 'flex',
  gap: '66px',
  flex: 1,
})

const Locations = styled('div', {
  borderTop: '1px solid #D0CECE',
  flex: 1,
})

const Location = styled('div', {
  borderBottom: '1px solid #D0CECE',
  display: 'flex',
  flex: 1,
})

const Number = styled('div', {
  flexShrink: 0,
  // borderRight: '4px dashed #D0CECE',
  backgroundImage: 'linear-gradient(to bottom, #D0CECE 33%, transparent 33%)',
  backgroundPosition: 'right top',
  backgroundRepeat: 'repeat-y',
  backgroundSize: '4px 12px',
  padding: '0px 20px',
  width: '80px',
})

const Text = styled('div', {
  minWidth: '0px',
  padding: '0px 32px',
})

const Logo = styled(CalgaryLogo, {
  position: 'absolute',
  height: '55px',
  bottom: '49px',
  right: '96px',
})
