import { useState, useEffect } from 'react';
import random from 'lodash/random';
import { isEqual } from 'lodash';

export const arrayMove = (inputArray, fromIndex, toIndex) => {
  const inputArrayCopy = [...inputArray];
  const element = inputArrayCopy[fromIndex];
  inputArrayCopy.splice(fromIndex, 1);
  inputArrayCopy.splice(toIndex, 0, element);
  return inputArrayCopy;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    windowWidth: width,
    windowHeight: height,
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const isColliding = (el1, el2) => {
  if (!el1 || !el2) return false;

  const rect1 = el1.getBoundingClientRect();
  const rect2 = el2.getBoundingClientRect();

  const overlap = !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );

  return overlap;
};

export const caseInsensitiveCompare = (string1, string2) => {
  if (!string1 || !string2) return false;
  return (
    string1.localeCompare(string2, undefined, {
      sensitivity: 'base',
    }) === 0
  );
};

export const caseInsensitiveFindInArray = (
  searchArray,
  objectIndex,
  findString
) => {
  return searchArray.find(searchArrayElement => {
    return caseInsensitiveCompare(searchArrayElement[objectIndex], findString);
  });
};

export const objectMap = ({ obj, newValuefn = v => v, newKeyFn = k => k }) => {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v], i) => [
      newKeyFn(k, v, i),
      newValuefn(v, k, i),
    ])
  );
};

export const getPlural = (num, stringLabel) => {
  return `${num} ${stringLabel}${num === 1 ? '' : 's'}`;
};

export const findByKeyOrFirstItem = (data, key, value) => {
  return data.find((entry) => entry[key] === value) || data[0];
};

export const wait = (min, max = min) => {
  return new Promise((resolve) => {
    setTimeout(resolve, random(min, max));
  });
};

export const getSearchParam = (key, fallback, parse) => {
  const params = new URLSearchParams(location.search);
  if (!params.has(key)) return fallback;
  const value = params.get(key);
  return parse ? parse(value) : value;
};

export const valueOf = (value, data) => {
  return typeof value === 'function' ? value(data) : value;
};

// Prevent props that are actually the same from rerendering by using Lodash.isEqual
export const arePropsEqualLodash = (props, nextProps) => {
  const propEntries = Object.entries(props);
  if (propEntries.length !== Object.keys(nextProps).length) {
    return false;
  }
  return !propEntries.find(([key, value]) => !(key in nextProps) || !isEqual(value, nextProps[key]));
};

export const isBrokerInBuildout = () => {
  return true;
}

export const isSurveyBuildout = (user, survey) => {
  // Dev hotkey override
  if (user.isOptedInToBdpBuildoutOverride !== undefined) {
    return user.isOptedInToBdpBuildoutOverride;
  }
  return !!survey.opted_in_to_bdp_buildout;
}

export const mod = (n, m) => {
  return ((n % m) + m) % m;
}

export const removeExtension = (fileName) => {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
}

export const getExtension = (fileName) => {
  const periodIndex = fileName.lastIndexOf('.');
  return periodIndex ? fileName.substring(periodIndex + 1) || '' : '';
}
