import React, { useState } from 'react'
import { useMessage } from '~/libraries/messaging.react'
import { Upload } from '~/pdfs/patterns/Upload'
import { apiUrl } from '~/support/apiUrl'
import { upload } from '~/support/upload'
import { useSend } from '~/support/useSend'

export const UploadSetting = (props) => {
  const [status, setStatus] = useState('idle')
  const send = useSend()

  useMessage('Upload', async ({ files }) => {
    try {
      setStatus('loading')

      const data = { survey_id: props.survey }
      const url = apiUrl('/survey_export_custom_cover_photos/')
      const response = await upload(url, files[0], data)

      await new Promise((resolve) => {
        setTimeout(resolve, 3000)
      })

      const image = {
        url: response.data.file.thumbnail_image_url,
        name: response.data.file.name,
      }

      send('UploadSucceeded', image)
      send('AddSettings', { [props.id]: image })
      setStatus('idle')
    } catch (error) {
      send('UploadFailed', error)
      setStatus('error')
    } finally {
      send('UploadSettled')
    }
  })

  useMessage('ClearUpload', () => {
    send('RemoveSettings', [props.id])
  })

  return (
    <Upload
      id={props.id}
      name={props.name}
      placeholder={props.placeholder}
      loading={status === 'loading'}
    />
  )
}
