import React, { useState } from 'react';
import orderBy from 'lodash/orderBy';
import { withStyles } from '@material-ui/core/styles';

// import { PROJECT } from '~/legacy/consts';
import { SortByTextField, getNewToOldLabel } from './SortByTextField';

const PROJECT_STUFF_SORT_OPTION_LABELS = {
  CREATED: 'Created',
  UPDATED: 'Updated',
};

export const PROJECT_STUFF_SORTS = {
  // TODO: Add back after tours
  // SURVEYS_FIRST: {
  //   id: 0,
  //   getLabel: () => 'Surveys First',
  // },
  // TOURS_FIRST: {
  //   id: 1,
  //   getLabel: () => 'Tours First',
  // },
  DATE_CREATED_DESCENDING: {
    id: 2,
    getLabel: () => getNewToOldLabel(PROJECT_STUFF_SORT_OPTION_LABELS.CREATED),
  },
  DATE_CREATED_ASCENDING: {
    id: 3,
    getLabel: () =>
      getNewToOldLabel(PROJECT_STUFF_SORT_OPTION_LABELS.CREATED, true),
  },
  DATE_UPDATED_DESCENDING: {
    id: 4,
    getLabel: () => getNewToOldLabel(PROJECT_STUFF_SORT_OPTION_LABELS.UPDATED),
  },
  DATE_UPDATED_ASCENDIG: {
    id: 5,
    getLabel: () =>
      getNewToOldLabel(PROJECT_STUFF_SORT_OPTION_LABELS.UPDATED, true),
  },
};

export const DEFAULT_PROJECT_STUFF_SORT_OPTIONS = Object.fromEntries(
  Object.values(PROJECT_STUFF_SORTS).map((sortValue) => [
    sortValue.id,
    sortValue.getLabel(),
  ])
);

export const sortProjectCollections = (projectStuffs, sortOrder) => {
  switch (sortOrder) {
    // TODO: Add back after tours
    // default:
    // case PROJECT_STUFF_SORTS.SURVEYS_FIRST.id:
    //   return orderBy(
    //     projectStuffs,
    //     [
    //       projectStuff =>
    //         projectStuff.type === PROJECT.STUFF_TYPES.SURVEY_TYPE ? 0 : 1,
    //     ],
    //     'asc'
    //   );
    // case PROJECT_STUFF_SORTS.TOURS_FIRST.id:
    //   return orderBy(
    //     projectStuffs,
    //     [
    //       projectStuff =>
    //         projectStuff.type === PROJECT.STUFF_TYPES.TOUR_TYPE ? 0 : 1,
    //     ],
    //     'asc'
    //   );
    default:
    case PROJECT_STUFF_SORTS.DATE_CREATED_DESCENDING.id:
      return orderBy(
        projectStuffs,
        (projectStuff) => new Date(projectStuff.created_at),
        ['desc']
      );
    case PROJECT_STUFF_SORTS.DATE_CREATED_ASCENDING.id:
      return orderBy(
        projectStuffs,
        (projectStuff) => new Date(projectStuff.created_at),
        ['asc']
      );
    case PROJECT_STUFF_SORTS.DATE_UPDATED_DESCENDING.id:
      return orderBy(
        projectStuffs,
        (projectStuff) => new Date(projectStuff.updated_at),
        ['desc']
      );
    case PROJECT_STUFF_SORTS.DATE_UPDATED_ASCENDIG.id:
      return orderBy(
        projectStuffs,
        (projectStuff) => new Date(projectStuff.updated_at),
        ['asc']
      );
  }
};

export const ProjectSortByTextField = withStyles({
  root: {},
})(
  ({
    classes,
    selectedValue,
    setSelectedValue,
    options = DEFAULT_PROJECT_STUFF_SORT_OPTIONS,
    defaultValue = PROJECT_STUFF_SORTS.DATE_UPDATED_DESCENDING,
    // TODO: Add back after tours
    // defaultValue = PROJECT_STUFF_SORTS.TOURS_FIRST,
  }) => (
    <SortByTextField
      className={classes.root}
      options={options}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      defaultValue={defaultValue}
    />
  )
);

export const useProjectSortByTextField = ({
  componentProps,
  defaultSort = PROJECT_STUFF_SORTS.DATE_UPDATED_DESCENDING.id,
  // TODO: Add back after tours
  // defaultSort = PROJECT_STUFF_SORTS.TOURS_FIRST.id,
}) => {
  const [selectedSort, setSelectedSort] = useState(defaultSort);
  const component = (
    <ProjectSortByTextField
      selectedValue={selectedSort}
      setSelectedValue={setSelectedSort}
      {...componentProps}
    />
  );

  return {
    selectedSort,
    setSelectedSort,
    ProjectSortByTextFieldComponent: component,
  };
};
