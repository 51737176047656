export const ENTER_USER = 1;
export const ENTER_PASSWORD = 2;
export const CREATE_PASSWORD = 3;
export const REQUEST_MAGIC_LINK = 4;
export const MAGIC_LINK_SENT = 5;
export const FORGOT_PASSWORD = 6;
export const RESET_PASSWORD_SENT = 7;
export const RESET_PASSWORD = 8;
export const RESET_PASSWORD_SUCCESS = 9;
export const CREATE_PASSWORD_SENT = 10;
