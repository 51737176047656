import * as Sentry from '@sentry/browser'

export const captureSentryMessage = ({
  message,
  level = 'info',
  tags = {},
  extras = {},
}) => {
  if (message) {
    Sentry.withScope((scope) => {
      Object.entries(tags).forEach(([key, value]) => {
        scope.setTag(key, value)
      })
      Object.entries(extras).forEach(([key, value]) => {
        scope.setExtra(key, value)
      })
      Sentry.captureMessage(message, level)
    })
  }
}

export const captureSentryMessageWarning = ({
  message,
  tags = {},
  extras = {},
}) => {
  captureSentryMessage({
    message,
    level: 'warning',
    tags,
    extras,
  })
}

export const captureSentryMessageError = ({
  message,
  tags = {},
  extras = {},
}) => {
  captureSentryMessage({ message, level: 'error', tags, extras })
}

export const captureSentryExceptionWarning = (exception) => {
  Sentry.withScope((scope) => {
    scope.setLevel('warning')
    Sentry.captureException(exception)
  })
}

export const captureSentryExceptionError = (exception) => {
  Sentry.withScope((scope) => {
    scope.setLevel('error')
    Sentry.captureException(exception)
  })
}
