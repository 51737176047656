import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import _ from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  CardImage,
  FloorplanIcon,
  ImageGalleryModal,
  Link,
  Modal,
  MODALS,
  OverflowTooltip,
  PhotosButton,
  Typography,
} from '~/legacy/components'
import { IMAGE_UPLOAD_MODAL_TAB_KEYS } from '~/legacy/components/modals'
import {
  getListingPrimaryName,
  isBroker,
  IsPreviewContext,
} from '~/legacy/utils'
import { useBuildingDetailPageLayout } from '~/legacy/utils/hooks'
import { px } from '~/legacy/utils/layoutUtils'
import { Markdown } from './themeComponents'

const FIELD_HOVER_VERTICAL_PADDING_PX = 2
const FIELD_HOVER_HORIZONTAL_PADDING_PX = 4
const FIELDS_VERTICAL_GAP_PX = 8 - FIELD_HOVER_VERTICAL_PADDING_PX * 2
const FIELDS_HORIZONTAL_GAP_PX = 48 - FIELD_HOVER_HORIZONTAL_PADDING_PX * 2

const useStyles = makeStyles({
  root: {
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    minHeight: '168px',
    display: 'flex',
    padding: '24px',
  },
  heroContainer: {
    position: 'relative',
    height: '120px',
    width: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#e0e0e0',
    cursor: (props) => !props.isPlaceholder && 'pointer',
  },
  heroImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  dataSection: {
    marginLeft: (props) =>
      props.showingHeroImage && px(32 - FIELD_HOVER_HORIZONTAL_PADDING_PX),
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-line',
  },
  metadataProperties: {
    display: 'grid',
    marginTop: '16px',
    gap: px(FIELDS_VERTICAL_GAP_PX, FIELDS_HORIZONTAL_GAP_PX),
  },
  metadataPropertiesStage1: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  },
  metadataPropertiesStage2: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  },
  metadataPropertiesStage3: {
    gridTemplateColumns: 'repeat(2, minmax(155px, 1fr))',
  },
  metadataPropertiesStage5: {
    gridTemplateColumns: '1fr',
  },
  metadataPropertiesStage6: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  },
  metadataPropertiesStage7: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(172px, 1fr))',
  },
  propertyTitle: {
    marginRight: '8px',
  },
  placeholderText: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  placeHolderBody: {
    margin: '12px 0 4px 0',
  },
  link: {
    cursor: 'pointer',
  },
  overflowTooltipRoot: {
    padding: px(
      FIELD_HOVER_VERTICAL_PADDING_PX,
      FIELD_HOVER_HORIZONTAL_PADDING_PX
    ),
  },
})

const MemoMarkdown = React.memo(Markdown)

export default function SpaceCard({
  className,
  space,
  isPlaceholder,
  updateListing,
  createSurveyListing,
}) {
  const isUserBroker = useSelector((s) => isBroker(s.user))
  const classes = useStyles({
    isPlaceholder,
    showingHeroImage: isUserBroker || space.hero_image,
  })
  const { isPreview } = React.useContext(IsPreviewContext)
  const metadata = !isPlaceholder && _.get(space, 'metadata', [])
  const isUserNonPreviewBroker = isUserBroker && !isPreview

  const [isViewingImages, setIsViewingImages] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)

  // The layout stages of BDP. Choose classes based on the stage
  const { stage1, stage2, stage3, stage4, stage5, stage6, stage7 } =
    useBuildingDetailPageLayout()

  let metadataPropertiesClass = ''
  if (stage1) {
    metadataPropertiesClass = classes.metadataPropertiesStage1
  } else if (stage2) {
    metadataPropertiesClass = classes.metadataPropertiesStage2
  } else if (stage3 || stage4) {
    metadataPropertiesClass = classes.metadataPropertiesStage3
  } else if (stage5) {
    metadataPropertiesClass = classes.metadataPropertiesStage5
  } else if (stage6) {
    metadataPropertiesClass = classes.metadataPropertiesStage6
  } else if (stage7) {
    metadataPropertiesClass = classes.metadataPropertiesStage7
  } else {
    metadataPropertiesClass = classes.metadataPropertiesStage7
  }

  const onPhotoClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (isUserNonPreviewBroker) {
      setShowUploadModal(true)
    } else {
      setIsViewingImages(true)
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      {(isUserNonPreviewBroker || space.hero_image) && (
        <div className={classes.heroContainer} onClick={onPhotoClick}>
          {!isPlaceholder && space.hero_image && (
            <>
              <CardImage
                imageFile={space.hero_image}
                ImgProps={{
                  alt: 'space hero',
                  className: classes.heroImage,
                }}
              />
              <PhotosButton
                imageCount={space.images.length + space.floorplan_photos.length}
                isUserBroker={isUserNonPreviewBroker}
              />
            </>
          )}
          {(isPlaceholder || (isUserBroker && !space.hero_image)) && (
            <>
              <FloorplanIcon />
              {!isPlaceholder && (
                <PhotosButton
                  imageCount={
                    space.images.length + space.floorplan_photos.length
                  }
                  isUserBroker={isUserNonPreviewBroker}
                />
              )}
            </>
          )}
        </div>
      )}
      <div className={classes.dataSection}>
        {!isPlaceholder && (
          <>
            {!!space.address2 && (
              <Typography
                variant="pageTitle"
                style={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  marginBottom: '12px',
                  marginLeft: px(FIELD_HOVER_HORIZONTAL_PADDING_PX),
                }}
              >
                {space.address2}
              </Typography>
            )}

            {!!space.description && (
              <div
                style={{ marginLeft: px(FIELD_HOVER_HORIZONTAL_PADDING_PX) }}
              >
                <MemoMarkdown>{space.description}</MemoMarkdown>
              </div>
            )}
            <div
              className={clsx(
                classes.metadataProperties,
                metadataPropertiesClass
              )}
            >
              {metadata.map(
                (m) =>
                  m.formattedValue && (
                    <OverflowTooltip
                      key={m.id || m.name}
                      title={`${m.name}: ${m.formattedValue}`}
                      classes={{ root: classes.overflowTooltipRoot }}
                    >
                      <Typography
                        className={classes.propertyTitle}
                        variant="bodyBold"
                        component="span"
                      >
                        {`${m.name}:`}
                      </Typography>
                      <Typography variant="bodyText" component="span">
                        {m.formattedValue}
                      </Typography>
                    </OverflowTooltip>
                  )
              )}
            </div>
          </>
        )}
        {isPlaceholder && (
          <div className={classes.placeholderText}>
            <Typography
              variant="pageTitle"
              style={{ fontSize: '18px', lineHeight: '24px' }}
            >
              Add a Space to This Building
            </Typography>
            <Typography className={classes.placeHolderBody} variant="bodyText">
              Tailor what you show with custom fields that reflect your Tenant’s
              needs.
            </Typography>
            <Link className={classes.link} onClick={createSurveyListing}>
              <Typography variant="bodyBold">Add a Space</Typography>
            </Link>
          </div>
        )}
      </div>
      {!isPlaceholder && (
        <>
          <ImageGalleryModal
            title={space.address2}
            imageLists={[
              { label: 'Space', images: space.images },
              { label: 'Floor Plan', images: space.floorplan_photos },
            ]}
            ModalComponentProps={{
              open: isViewingImages,
              onClose: () => setIsViewingImages(false),
            }}
          />
          <Modal
            content={MODALS.IMAGE_UPLOAD}
            childProps={{
              parentObjects: {
                listing: space,
              },
              objectName: getListingPrimaryName(space),
              noPadding: true,
              setListing: async (newListingData) => {
                const newSpacePartial = {
                  images: newListingData.images,
                  floorplan_photos: newListingData.floorplan_photos,
                }
                await updateListing(space.id, newSpacePartial)
              },
              tabsToShow: [
                IMAGE_UPLOAD_MODAL_TAB_KEYS.FLOORPLAN,
                IMAGE_UPLOAD_MODAL_TAB_KEYS.SPACE,
              ],
            }}
            open={showUploadModal}
            onClose={() => setShowUploadModal(false)}
          />
        </>
      )}
    </div>
  )
}
