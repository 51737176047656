import { SnackbarUtils } from '~/legacy/utils';

// The default way to handle a mutate. Don't revalidate by default, log to sentry on revalidate error
export const defaultMutate = async (
  mutate = async () => {},
  defaultApiErrorMessage = 'Error updating, please try again.',
  {
    mutator = async () => {},
    options = {},
    errorMessage = defaultApiErrorMessage,
  }
) => {
  const OPTIONS = {
    revalidate: false,
  };

  try {
    return await mutate(mutator, { ...OPTIONS, ...options });
  } catch (mutateError) {
    if (errorMessage) {
      SnackbarUtils.error(errorMessage);
    }
    throw mutateError;
  }
};
// Bind the default mutate with the SWR mutate and default error message
export const defaultMutateBinder =
  (mutate, defaultApiErrorMessage) =>
  async (args = {}) =>
    defaultMutate(mutate, defaultApiErrorMessage, args);

// The default way to handle a mutate optimistically (update locally before remote).
export const defaultMutateOptimistic = async (
  defaultMutateLocal = async () => {},
  defaultApiErrorMessage = 'Error updating, please try again.',
  {
    newObject = {},
    mutator = async () => {},
    options = {},
    errorMessage = defaultApiErrorMessage,
  }
) => {
  const OPTIONS = {
    optimisticData: newObject,
    rollbackOnError: true,
    populateCache: true,
  };
  return defaultMutateLocal({
    mutator,
    options: { ...OPTIONS, ...options },
    errorMessage,
  });
};
// Bind the default optimistic mutate with the default mutate and default error message
export const defaultMutateOptimisticBinder =
  (defaultMutateLocal, defaultApiErrorMessage) =>
  async (args = {}) =>
    defaultMutateOptimistic(defaultMutateLocal, defaultApiErrorMessage, args);
