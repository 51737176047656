import React, { Fragment } from 'react'
import { styled } from '~/libraries/styled'
import { SpaceName, SpaceNotes } from '~/objects/fields'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { conjunction } from '~/support/conjunction'
import { findFieldAndFormat } from '~/support/findFieldAndFormat'
import { isLike } from '~/support/isLike'
import { buildLocationName } from '../../leaseup/support/buildLocationName'
import { buildSpaceName } from '../../leaseup/support/buildSpaceName'
import { convertFieldsToList } from '../../leaseup/support/convertFieldsToList'
import { Font } from '../patterns/Font'
import CalgaryLogo from '../patterns/CalgaryLogo'
import { Photo } from '~/pdfs/patterns/Photo'
import { image } from '~/pdfs/support/image'

export const CalgarySpace = definePageTemplate({
  id: '8fa6e2b4-af10-4c69-bcc6-03cdd9807f8e',

  name: 'Space Details',

  props: (pdf, space) => ({
    photo: image(space.floorplans[0], { width: 941 * 2 }),
    name: conjunction([
      buildSpaceName(space),
      buildLocationName(space.building.location),
    ]),
    notes: findFieldAndFormat(space.fields, SpaceNotes),
    fields: convertFieldsToList(
      space.fields.filter((field) => {
        return !isLike(field, SpaceNotes) && !isLike(field, SpaceName)
      })
    ),
  }),

  Page: (props) => (
    <Page>
      <Header>
        <Font format="78/94/300" align="center" grow truncate>
          {props.name}
        </Font>
      </Header>
      <Content>
        <Column>
          {props.fields.length > 0 && (
            <Fields>
              {props.fields.map((field) => (
                <Fragment key={field.id}>
                  <FieldName>
                    <Font format="29/59/400" color="#FF0000" nowrap>
                      {field.name}
                    </Font>
                  </FieldName>
                  <FieldValue>
                    <Font format="29/59/300" truncate>
                      {field.value}
                    </Font>
                  </FieldValue>
                </Fragment>
              ))}
            </Fields>
          )}
        </Column>
        <Column>
          {props.photo && (
            <Floorplan
              src={props.photo}
              width="100%"
              aspect={575 / 941}
              fit="contain"
            />
          )}
          {props.notes && (
            <div>
              <Font
                format="33/61/400"
                background="#E7E6E6"
                color="#FF0000"
                padding="0px 30px"
              >
                Notes
              </Font>
              <Font format="33/40/300" background="#F3F3F4" padding="20px 30px">
                {props.notes}
              </Font>
            </div>
          )}
        </Column>
      </Content>

      <Logo />
    </Page>
  ),
})

const Page = styled('div', {
  flexDirection: 'column',
  position: 'absolute',
  display: 'flex',
  inset: '0px',
  overflow: 'hidden',
})

const Header = styled('div', {
  backgroundColor: '#E7E6E6',
  flexShrink: 0,
  minWidth: '0px',
  display: 'flex',
  marginTop: '68px',
  padding: '12px 100px 27px',
})

const Content = styled('div', {
  gridTemplateColumns: '1fr 941px',
  padding: '130px 100px 0px',
  display: 'grid',
  gap: '63px',
})

const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '63px',
})

const Floorplan = styled(Photo, {
  border: '4px solid #595959',
})

const Fields = styled('div', {
  gridTemplateColumns: 'min-content 1fr',
  gridTemplateRows: 'min-content',
  alignContent: 'flex-start',
  borderTop: '1px solid #D0CECE',
  display: 'grid',
})

const FieldName = styled('div', {
  borderBottom: '1px solid #D0CECE',
  backgroundImage: 'linear-gradient(to bottom, #D0CECE 33%, transparent 33%)',
  backgroundPosition: 'right top',
  backgroundRepeat: 'repeat-y',
  backgroundSize: '4px 12px',
  padding: '0px 20px',
  minWidth: '240px',
})

const FieldValue = styled('div', {
  minWidth: '0px',
  padding: '0px 32px',
  borderBottom: '1px solid #D0CECE',
})

const Logo = styled(CalgaryLogo, {
  position: 'absolute',
  height: '55px',
  bottom: '49px',
  right: '96px',
})
