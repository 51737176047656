import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from 'rest-fetcher-redux';

export default function Feedback() {
  const user = useSelector(s => s.user);
  const [keyState, setKeyState] = useState({
    loaded: false,
    key: '',
    slug: '',
  });

  useEffect(() => {
    if (user.id) {
      Api.getProductBoardToken({ id: user.id }).then(res => {
        setKeyState({
          loaded: true,
          key: res.data.key,
          slug: res.data.slug,
        });
      });
    }
  }, [user]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {keyState.loaded && keyState.key && keyState.slug && (
        <iframe
          title="feedback"
          style={{ width: '100%', height: '100%' }}
          src={`https://portal.productboard.com/${keyState.slug}?token=${keyState.key}&hide_header=1`}
          frameBorder="0"
        />
      )}
    </div>
  );
}
