import ReactMarkdown from 'markdown-to-jsx'
import React from 'react'

import { Link, Linkify, Typography } from '~/legacy/components'

const getTextVariantMap = (forPdf) => ({
  normal: forPdf ? 'textTiny' : 'textNormal',
  bold: forPdf ? 'boldTiny' : 'boldText',
})

// We linkify simple URLs so the user doesn't have to use markdown to make urls clickable
const MarkdownParagraph = ({ children, forPdf }) => {
  const textVariantMap = getTextVariantMap(forPdf)
  return (
    <Linkify component={Link}>
      <Typography variant={textVariantMap.normal}>{children}</Typography>
    </Linkify>
  )
}

// Works friendly with pre-Linkified text
const MarkdownLinkWrapper = ({ children, ...props }) => {
  const firstChild = children ? children[0] : null
  const { href } = props

  // If we've already linkify'd the URL, just pull out of Linkify what we need and make a link
  let linkChildren = children
  if (typeof firstChild !== 'string' && href) {
    linkChildren = [href]
  }

  return <Link {...props}>{linkChildren}</Link>
}

// Markdown is turned into jsx, we control the style here.
// Override most markdown functionality to normal text for now
export const getDefaultOptions = ({ forPdf = false }) => {
  const textVariantMap = getTextVariantMap(forPdf)
  const normalText = {
    component: (_props) => (
      <Typography
        variant={textVariantMap.normal}
        component="pre"
        style={{ whiteSpace: 'pre-wrap' }}
        {..._props}
      />
    ),
  }

  return {
    // If there's only one line of text, wrap it. Otherwise it just sits in a span...
    forceWrapper: true,
    wrapper: normalText.component,
    overrides: {
      h1: normalText,
      h2: normalText,
      h3: normalText,
      h4: normalText,
      h5: normalText,
      h6: normalText,
      strong: {
        component: (_props) => (
          <Typography
            variant={textVariantMap.bold}
            component="strong"
            {..._props}
          />
        ),
      },
      em: {
        component: (_props) => (
          <Typography
            variant={textVariantMap.normal}
            component="em"
            {..._props}
          />
        ),
      },
      p: {
        component: MarkdownParagraph,
        props: { forPdf },
      },
      a: { component: MarkdownLinkWrapper },
      li: {
        component: (_props) => (
          <Typography
            variant={textVariantMap.normal}
            component="li"
            {..._props}
          />
        ),
      },
    },
  }
}

export const Markdown = ({ children, forPdf, ...props }) => {
  const replaced = children
    // Disable certain markdown functionality
    .replaceAll('#', '\\#')
    // Make adding new lines more intuitive for users. Basically match JSX / text newlines with markdown newlines
    .replaceAll(/(([^\s].)|(.[^s]))[\n]/gm, '$1  \n')
    .replaceAll(/\n$/gm, '\n<br>\n')
  return (
    <ReactMarkdown options={getDefaultOptions({ forPdf })} {...props}>
      {replaced}
    </ReactMarkdown>
  )
}
