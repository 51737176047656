import React, { useState } from 'react';
import clsx from 'clsx';
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  TextChevronUp,
  TextChevronDown,
  MenuSelectedItemIcon,
  Loading,
  Typography,
} from '~/legacy/components';

const getColor = (color, theme) => {
  switch (color) {
    case 'primary':
      return theme.palette.primary.main;
    case 'black':
      return '#111111';
    default:
      return theme.palette.primary.main;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props) => getColor(props.color, theme),
    height: '36px',
    margin: 'auto 0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  menu: {
    width: (props) => props.width || '',
  },
  optionLabel: {
    lineHeight: (props) => props.showSelectedOptionIcon && '24px',
  },
  optionIcon: {
    color: '#666666',
    marginLeft: '20px',
  },
  loadingSpinner: {
    marginLeft: 'auto',
  },
  hidden: {
    visibility: 'hidden',
  },
  menuItemRoot: {
    justifyContent: 'space-between',
  },
}));

function TextSelectField({
  className,
  label,
  color = 'primary',
  showLabel = true,
  showSelectedOption = true,
  showSelectedOptionIcon = false,
  onChange = () => {},
  options,
  setSelectedValue = () => {},
  selectedValue,
  defaultValue,
  width,
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  transformOrigin = { vertical: 'top', horizontal: 'center' },
}) {
  const classes = useStyles({ color, showSelectedOptionIcon, width });
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);

  const getDisplay = () => {
    let display = `${showLabel ? `${label} ` : ''}`;
    if (showSelectedOption) {
      display = `${display} ${
        options[selectedValue !== undefined ? selectedValue : defaultValue]
      } `;
    }
    return display;
  };

  return (
    <>
      <Typography
        className={clsx(classes.root, className)}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant="boldText"
      >
        {getDisplay()}
        {anchorEl && <TextChevronUp />}
        {!anchorEl && (
          <TextChevronDown style={{ color: getColor(color, theme) }} />
        )}
      </Typography>
      <Menu
        classes={{
          paper: classes.menu,
        }}
        disableAutoFocusItem
        elevation={2}
        anchorEl={anchorEl}
        keepMounted
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {Object.entries(options).map(([index, optionLabel]) => {
          const indexInt = parseInt(index, 10);
          return (
            <MenuItem
              key={optionLabel}
              onClick={() => {
                const promise = onChange(indexInt);
                if (promise) {
                  setLoading(true);
                  setLoadingIndex(indexInt);
                  promise
                    .then(() => {
                      setLoading(false);
                      setLoadingIndex(null);
                      setAnchorEl(null);
                      setSelectedValue(indexInt);
                    })
                    .catch(() => {
                      setLoading(false);
                      setLoadingIndex(null);
                    });
                } else {
                  setAnchorEl(null);
                  setSelectedValue(indexInt);
                }
              }}
              className={classes.menuItemRoot}
            >
              <Typography className={classes.optionLabel} variant="h3">
                {optionLabel}
              </Typography>
              {showSelectedOptionIcon && !loading && (
                <MenuSelectedItemIcon
                  className={clsx(
                    classes.optionIcon,
                    indexInt !== selectedValue ? classes.hidden : ''
                  )}
                />
              )}
              {showSelectedOptionIcon && loading && (
                <Loading
                  isLoading
                  size={24}
                  className={clsx(
                    classes.loadingSpinner,
                    indexInt !== loadingIndex ? classes.hidden : ''
                  )}
                />
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default TextSelectField;
