export default {
  verifyAddresses: {
    url: '/addresses/verify/',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
};
