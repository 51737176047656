import React from 'react'
import BuildingDetailPage from './BuildingDetailPage'
import BuildingDetailPageNew from './BuildingDetailPageNew'
import { useSurveySelector } from '~/legacy/utils'

export default function BuildingDetailPageWrapper({ match }) {
  const { survey } = useSurveySelector(match.params.id, 'bdp', false)
  const optedInSurvey = survey?.opted_in_new_custom_fields
  console.log('survey opted_in_new_custom_fields:', optedInSurvey)

  return optedInSurvey ? (
    <BuildingDetailPageNew match={match} />
  ) : (
    <BuildingDetailPage match={match} />
  )
}
