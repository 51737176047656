import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Api from 'rest-fetcher-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, File } from '~/legacy/components';
import { getBuildingPrimaryName, getListingPrimaryName } from '~/legacy/utils';
import { ModalTitle } from '~/legacy/components/modals';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    height: (props) => {
      if (props.loading || props.selectedObject.id || !props.objects.length) {
        return '350px';
      }
      return '580px';
    },
    width: '550px',
    paddingTop: '4px',
  },
  badNews: {
    margin: 'auto',
  },
  badNewsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  body: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    padding: (props) =>
      !props.selectedObject.id && props.objects.length && '0 12px',
    marginBottom: (props) => props.isLoading && '76px',
  },
  buttons: {
    margin: (props) =>
      props.objects.length ? 'auto 0 16px auto' : 'auto 0 0 auto',
  },
  cancelButton: {
    marginRight: '12px',
  },
  lastObject: {
    borderBottom: '1px solid #e0e0e0',
  },
  heroPhoto: {
    height: '48px',
    width: '48px',
    marginRight: '8px',
  },
  selectedObjectConfirmText: {
    marginBottom: '20px',
  },
  selectedObjectContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  customFields: {
    color: '#666666',
  },
  loader: {
    margin: 'auto',
  },
  object: {
    cursor: 'pointer',
    minHeight: '70px',
    width: '100%',
    borderTop: '1px solid #e0e0e0',
    '&:last-child': {
      borderBottom: '1px solid #e0e0e0',
    },
    display: 'flex',
    alignItems: 'center',
  },
  objects: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: (props) =>
      !props.isLoading && !props.objects.length ? '20px' : '35px',
  },
  selectedObject: {
    cursor: 'default',
  },
  title: {
    marginTop: '4px',
  },
});

const AddFieldsFromAnotherModal = React.forwardRef(
  ({ updateCustomFields, onClose, parentObjectIds, surveyId, type }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [objects, setObjects] = useState([]);
    const [selectedObject, setSelectedObject] = useState({});

    const classes = useStyles({ isLoading, selectedObject, objects });

    useEffect(() => {
      setIsLoading(true);
      if (type === 'listing') {
        Api.getSurveyListings({ id: surveyId }).then((data) => {
          setObjects(
            data.survey_listings.filter(
              (sl) =>
                !parentObjectIds.includes(sl.listing.id) &&
                sl.listing.custom_fields.length > 0
            )
          );
          setIsLoading(false);
        });
      } else {
        Api.getSurveyBuildings({ id: surveyId }).then((data) => {
          setObjects(
            data.survey_buildings.filter(
              (sb) =>
                !parentObjectIds.includes(sb.building.id) &&
                sb.building.custom_fields.length > 0
            )
          );
          setIsLoading(false);
        });
      }
    }, []);

    return (
      <div className={classes.content} ref={ref} tabIndex={-1}>
        <ModalTitle
          onClose={onClose}
          title={
            selectedObject.id
              ? 'Add Fields'
              : `Add Fields From Another ${type[0].toUpperCase()}${type.slice(
                  1
                )}`
          }
        />
        <div className={classes.body}>
          {isLoading && <CircularProgress className={classes.loader} />}

          {!isLoading && !selectedObject.id && (
            <div className={classes.objects}>
              {objects.map(
                (obj, index) =>
                  obj[type].custom_fields.length > 0 && (
                    <div
                      key={obj.id}
                      className={clsx(
                        classes.object,
                        index === objects.length - 1 && classes.lastObject
                      )}
                      onClick={() => setSelectedObject(obj)}
                    >
                      <div className={classes.heroPhoto}>
                        <File
                          file={
                            (type === 'listing'
                              ? obj.listing.hero_photo
                              : obj.building.images[0]) || {}
                          }
                          skipUploading
                          showAsThumbnail
                          useMissingBuildingImage
                        />
                      </div>
                      <div className={classes.textContainer}>
                        <Typography variant="body1">
                          {type === 'listing'
                            ? getListingPrimaryName(obj.listing)
                            : getBuildingPrimaryName(obj.building)}
                        </Typography>
                        <Typography
                          className={classes.customFields}
                          variant="body2"
                        >
                          {obj[type].custom_fields
                            .map((cf) => cf.name)
                            .join(', ')}
                        </Typography>
                      </div>
                    </div>
                  )
              )}
              {objects.length === 0 && (
                <div className={classes.badNewsContainer}>
                  <Typography className={classes.badNews} variant="body1">
                    {`No other ${type}s in this survey have custom fields associated with them.`}
                  </Typography>
                  <div className={classes.buttons}>
                    <Button
                      color="secondary"
                      className={classes.cancelButton}
                      onClick={() => onClose()}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {!isLoading && selectedObject.id && (
            <div className={classes.selectedObjectContainer}>
              <Typography
                className={classes.selectedObjectConfirmText}
                variant="body1"
              >
                {`Are you sure you want to add
                ${selectedObject[type].custom_fields.length}
                ${
                  selectedObject[type].custom_fields.length > 1
                    ? 'fields'
                    : 'field'
                }
                from
                ${
                  type === 'listing'
                    ? getListingPrimaryName(selectedObject.listing)
                    : getBuildingPrimaryName(selectedObject.building)
                }
                to this ${type}?
              `}
              </Typography>
              <div
                className={clsx(
                  classes.object,
                  classes.lastObject,
                  classes.selectedObject
                )}
              >
                <div className={classes.heroPhoto}>
                  <File
                    file={
                      (type === 'listing'
                        ? selectedObject.listing.hero_photo
                        : selectedObject.building.images[0]) || {}
                    }
                    skipUploading
                    showAsThumbnail
                    useMissingBuildingImage
                  />
                </div>
                <div className={classes.textContainer}>
                  <Typography variant="body1">
                    {type === 'listing'
                      ? selectedObject.listing.building.address
                      : selectedObject.building.address}
                  </Typography>
                  <Typography className={classes.customFields} variant="body2">
                    {selectedObject[type].custom_fields
                      .map((cf) => cf.name)
                      .join(', ')}
                  </Typography>
                </div>
              </div>
              <div className={classes.buttons}>
                <Button
                  color="secondary"
                  className={classes.cancelButton}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    if (type === 'listing') {
                      Api.addListingCustomFieldsFromListing({
                        body: {
                          destination_listing_ids: parentObjectIds,
                          source_listing_id: selectedObject.listing.id,
                        },
                      }).then(updateCustomFields);
                    } else {
                      Api.addBuildingCustomFieldsFromBuilding({
                        body: {
                          destination_building_ids: parentObjectIds,
                          source_building_id: selectedObject.building.id,
                        },
                      }).then(updateCustomFields);
                    }
                    onClose();
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default AddFieldsFromAnotherModal;
