import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useEffect, useState } from 'react'

import {
  BASE_ICON_STYLES,
  EditIcon,
  MenuItem,
  TextField,
  Typography,
} from '~/legacy/components'
import { SnackbarUtils } from '~/legacy/utils'
import ActionModal from '../modals/ActionModal'

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
  textInputFieldContainer: {
    width: '100%',
    marginTop: '10px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  commentMenuItem: {
    width: '186px',
  },
}))

// A modal to edit a comment
export const EditCommentMenuItemModal = ({
  comment,
  updateComment,
  open,
  onClose,
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const hasErrors = !!(errors && Object.keys(errors).length)

  const [newCommentText, setNewCommentText] = useState(
    (comment && comment.text) || undefined
  )

  // Validate the entered profile data
  const validate = useCallback(() => {
    const newErrors = {}
    if (!newCommentText) {
      newErrors.newCommentText = 'You must enter a comment'
    }
    setErrors(newErrors)
    return newErrors
  }, [newCommentText])

  // When the comment changes, or the  modal is closed then repoened, reset the user state
  useEffect(() => {
    if (comment && open) {
      setNewCommentText((comment && comment.text) || undefined)
    }
  }, [comment, open])

  // If there are already errors and the user changes the comment info, re-validate. ie: The user is trying to fix
  //    the existing errors.
  useEffect(() => {
    if (hasErrors) {
      validate()
    }
  }, [hasErrors, newCommentText, validate])

  const closeModal = () => {
    onClose()
  }

  // Update the comment
  const updateCommentApi = async () => {
    setIsLoading(true)

    // Early escape if any validation of fields issue
    const newErrors = validate()
    if (Object.values(newErrors).length) {
      setIsLoading(false)
      return
    }

    try {
      await updateComment({ ...comment, text: newCommentText }, closeModal)
      SnackbarUtils.success('Comment Successfully Updated.')
    } catch {
      // supress
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ActionModal
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onConfirm={updateCommentApi}
      title="Edit Your Comment"
      confirmButtonLabel="Save"
      disableAction={hasErrors || isLoading}
      loading={isLoading}
    >
      <div className={classes.modalContent}>
        <div className={classes.textInputFieldContainer}>
          <TextField
            autoFocus
            multiline
            rows={10}
            placeholder="Enter a comment"
            value={newCommentText || ''}
            onChange={(event) => setNewCommentText(event.target.value)}
            helperText={errors.newCommentText || null}
            error={!!errors.newCommentText}
          />
        </div>
      </div>
    </ActionModal>
  )
}

// Menu item to edit a comment
export const EditCommentMenuItem = React.forwardRef(
  ({ openModal, ...props }, ref) => {
    const classes = useStyles()

    return [
      <MenuItem
        key="edit-comment-menu-item"
        ref={ref}
        classes={{ menuItemRoot: classes.commentMenuItem }}
        onClick={openModal}
        {...props}
      >
        <EditIcon className={classes.icon} />
        <Typography variant="menuItemText" className={classes.text}>
          Edit
        </Typography>
      </MenuItem>,
    ]
  }
)

// Bundle the modal and the menu item together for maximum convenience
export const useEditCommentMenuItem = ({
  comment,
  updateComment,
  handleMenuClose,
}) => {
  const [open, setOpen] = React.useState(false)

  const EditCommentMenuItemComponent = (
    <EditCommentMenuItem openModal={() => setOpen(true)} />
  )

  const EditCommentMenuItemModalComponent = (
    <EditCommentMenuItemModal
      key="edit-comment-modal"
      comment={comment}
      updateComment={updateComment}
      open={open}
      onClose={() => {
        setOpen(false)
        handleMenuClose()
      }}
    />
  )

  return {
    EditCommentMenuItemComponent,
    EditCommentMenuItemModalComponent,
  }
}
