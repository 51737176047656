import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import Api from 'rest-fetcher-redux';
import { Button } from '~/legacy/components';
import { toast } from '~/legacy/utils/notifications';
import { useSegment } from '~/legacy/utils/hooks';
import ModalTitle from './ModalTitle';

const useStyles = makeStyles({
  modalContent: {
    background: 'white',
    outline: 'none',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
    height: '580px',
    marginTop: '4px',
    marginBottom: '16px',
  },
  actionButton: {
    width: '140px',
    height: '44px',
  },
  saveButton: {
    marginLeft: '12px',
  },
  textField: {
    height: '100%',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
});

function ComparisonShareModal({ onClose, survey }) {
  const classes = useStyles();

  const [message, setMessage] = useState('');
  const [creatingNewMessage, setCreatingNewMessage] = useState(false);
  const [messageInputError, setMessageInputError] = useState(null);

  const segment = useSegment();
  const theme = useTheme();

  const createNewMessage = () => {
    // Create a new message by sending it through to the backend
    if (message && !creatingNewMessage) {
      setMessageInputError(null);
      setCreatingNewMessage(true);
      Api.createSurveyComparisonComment({
        body: {
          text: message,
          survey_id: survey.id,
          comparison_url: window.location.pathname + window.location.search,
          colors: theme.palette.primary,
        },
      })
        .then((results) => {
          if (results) {
            // Clear the message fields
            setMessage('');
            segment.trackCreateSurveyComparisonComment({
              survey: survey.id,
              hasText: true,
            });
            toast('Comparison shared!');
            onClose();
          }
        })
        .catch(() => {
          setMessageInputError(
            'There was an error sharing the comparison, please try again'
          );
        })
        .finally(() => {
          setCreatingNewMessage(false);
        });
    } else {
      setMessageInputError('Please include a message...');
    }
  };

  return (
    <div className={classes.modalContent}>
      <ModalTitle onClose={onClose} title="Share Comparison to Survey" />
      <TextField
        helperText={messageInputError}
        error={!!messageInputError}
        onChange={(event) => setMessage(event.target.value)}
        value={message}
        className={classes.textField}
        autoFocus
        id="outlined-multiline-static"
        label="Message"
        multiline
        margin="dense"
        rows={4}
        variant="outlined"
        placeholder="Add a message..."
        inputProps={{
          style: {
            height: '100%',
          },
        }}
      />

      <div style={{ display: 'flex', marginLeft: 'auto', marginTop: '24px' }}>
        <Button
          color="secondary"
          onClick={() => {
            onClose();
          }}
          className={classes.actionButton}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            createNewMessage();
          }}
          className={clsx(classes.actionButton, classes.saveButton)}
          disabled={creatingNewMessage}
          loading={creatingNewMessage}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default ComparisonShareModal;
