import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { DialogActions } from '@material-ui/core';
import Api from 'rest-fetcher-redux';
import { Button, TextInput } from '~/legacy/components';
import { ModalTitle } from '~/legacy/components/modals';
import { LEASE_PROJECT_BY_ID } from '~/legacy/consts';
import { useSegment } from '~/legacy/utils/hooks';

const useStyles = makeStyles({
  actions: {
    paddingRight: 0,
  },
  content: {
    backgroundColor: 'white',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    display: 'flex',
    marginBottom: 30,
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  imageContainer: {
    width: 200,
    height: 132,
    border: '1px solid #E8E8E8',
    display: 'flex',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 250,
    marginRight: 30,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginBottom: '50px',
  },
});

const NewSurveyModal = React.forwardRef(({ onClose }, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const segment = useSegment();

  const createSurvey = () => {
    setNameError('');
    Api.createProject({
      body: {
        name,
        // TODO: why does this exist
        active_search: {
          name: 'hardcoded',
          search: {
            is_sqft_price: true,
          },
        },
      },
    }).then((res) => {
      if (Array.isArray(res.name)) {
        setNameError(res.name[0]);
      } else {
        Api.getSurveys();
        history.push(LEASE_PROJECT_BY_ID.replace(':id', res.id));
        onClose();
        segment.trackCreateSurvey(res.id, res.name);
      }
    });
  };

  return (
    <div className={classes.content} ref={ref} tabIndex={-1}>
      <ModalTitle onClose={onClose} title="Add a New Survey" />
      <div className={classes.formContainer}>
        <div className={classes.inputContainer}>
          <TextInput
            errorText={nameError}
            label="Survey Name"
            onChange={(event) => setName(event.target.value)}
            required
            value={name}
          />
        </div>
      </div>
      <DialogActions className={classes.actions}>
        <Button color="primary" disabled={!name} onClick={createSurvey}>
          Add Survey
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </div>
  );
});

export default NewSurveyModal;
