import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'block',
    width: '100%',
  },
  svgText: {
    fill: '#4a4a4a',
    border: '#4a4a4a',
    fontSize: props => props.fontSize,
    fontFamily: 'ProximaNova-SemiBold',
    lineHeight: '1.5',
    letterSpacing: '0em',
  },
  stroke: {
    fillOpacity: 0,
    stroke: '#4a4a4a',
    strokeWidth: 2.5,
    strokeOpacity: 1,
    opacity: 1,
  },
});

function BikeScoreSvg({ bikeScore, height, fontSize, x, y }) {
  const classes = useStyles({ bikeScore, height, fontSize });

  return (
    <svg
      viewBox="0 0.003 75.875 76.5"
      width={62}
      height={height}
      preserveAspectRatio="xMidYMid meet"
      className={classes.root}
    >
      <defs>
        <path
          d="M40.24 7.25c-.19-2.55-.32-4.14-.37-4.78-.06-.83-.75-1.47-1.59-1.47h-3.01c-.97 0-1.79.74-1.88 1.71-.06.61-.21 2.12-.44 4.54l-3.07.62c-.18-.39-.34-.78-.51-1.16-.17-.38-.39-.82-.68-1.31"
          id="prefix__a"
        />
        <path
          d="M50.32 10.38l1.29-4.62c.22-.8-.21-1.64-.99-1.93-.89-.32-1.99-.72-2.83-1.03-.92-.33-1.94.09-2.36.96-.26.55-.92 1.92-1.97 4.12l-3.22-.63-.18-3.12"
          id="prefix__b"
        />
        <path
          d="M58.67 16.64c1.49-2.08 2.42-3.38 2.79-3.9.48-.67.37-1.61-.27-2.14-.73-.61-1.62-1.36-2.31-1.94-.75-.63-1.85-.58-2.54.1-.44.43-1.52 1.49-3.26 3.19l-2.84-1.57.64-2.24"
          id="prefix__c"
        />
        <path
          d="M64.61 25.39c2.11-1.45 3.43-2.35 3.95-2.71.69-.47.9-1.39.48-2.11-.47-.82-1.05-1.83-1.5-2.61a1.91 1.91 0 00-2.43-.78l-4.15 1.89-2.24-2.6 1.4-1.86"
          id="prefix__d"
        />
        <path
          d="M65.73 45.66c2.55.25 4.14.4 4.77.47.83.08 1.58-.49 1.73-1.31.16-.94.37-2.09.52-2.97a1.89 1.89 0 00-1.36-2.15c-.58-.17-2.05-.57-4.39-1.23l-.16-3.2 1.92-.51"
          id="prefix__e"
        />
        <path
          d="M61.1 55.21c2.31 1.1 3.75 1.79 4.33 2.07.75.36 1.65.08 2.07-.64.47-.82 1.05-1.83 1.5-2.61.49-.85.26-1.92-.54-2.49-.49-.35-1.73-1.24-3.71-2.65l.98-3.24 2.39.23"
          id="prefix__f"
        />
        <path
          d="M53.2 62.49c1.79 1.83 2.91 2.97 3.35 3.43.59.59 1.53.64 2.17.1.72-.61 1.62-1.36 2.3-1.94.76-.63.92-1.72.39-2.55-.32-.49-1.11-1.74-2.39-3.73l2.02-2.51 2.46 1.05"
          id="prefix__g"
        />
        <path
          d="M43.61 66.61c1.06 2.33 1.72 3.79 1.99 4.37.34.75 1.21 1.12 1.99.84.89-.33 1.99-.73 2.83-1.03.92-.34 1.43-1.31 1.19-2.26-.15-.59-.53-2.06-1.14-4.41l2.73-1.53 1.7 1.54"
          id="prefix__h"
        />
        <path
          d="M33.2 67.25c.19 2.55.32 4.15.37 4.78.06.83.75 1.47 1.59 1.47h3.01c.97 0 1.79-.74 1.88-1.71.06-.6.21-2.12.44-4.54l3.18-.45.93 1.93"
          id="prefix__i"
        />
        <path
          d="M66.9 35.27c2.48-.64 4.02-1.04 4.64-1.2.81-.21 1.32-1 1.17-1.82-.16-.93-.36-2.08-.52-2.97a1.883 1.883 0 00-2.01-1.55c-.61.04-2.12.16-4.55.35l-1.02-2.69 1.52-1.1"
          id="prefix__j"
        />
        <path
          d="M22.88 64.13c-.68 2.46-1.11 4-1.28 4.62-.23.8.21 1.64.99 1.92.89.32 1.98.72 2.83 1.03.91.33 1.93-.08 2.35-.96.27-.55.92-1.92 1.97-4.12l3.46.41.22 2.52"
          id="prefix__k"
        />
        <path
          d="M14.68 57.8c-1.49 2.08-2.42 3.38-2.79 3.9-.48.68-.37 1.62.27 2.15.73.61 1.62 1.36 2.31 1.94a1.9 1.9 0 002.54-.1c.44-.43 1.52-1.49 3.26-3.2l2.74 1.44-.71 2.34"
          id="prefix__l"
        />
        <path
          d="M9.25 48.97c-2.2 1.46-3.57 2.37-4.12 2.73-.69.46-.9 1.38-.49 2.1.48.81 1.06 1.83 1.51 2.6.49.85 1.53 1.18 2.42.78.56-.25 1.94-.88 4.15-1.89l1.95 2.51-.29.62-.71.72"
          id="prefix__m"
        />
        <path
          d="M6.74 38.59c-.36.09-.86.22-1.48.38-3.06.79-4.11 1.06-3.16.81-.81.21-1.32 1-1.17 1.82.16.93.37 2.08.52 2.97a1.9 1.9 0 002.01 1.56c.61-.05 2.13-.17 4.55-.36l1.19 3.38-1.64.97"
          id="prefix__n"
        />
        <path
          d="M7.95 28.08c-2.54-.25-4.13-.4-4.77-.46a1.57 1.57 0 00-1.72 1.3c-.17.94-.37 2.09-.53 2.97-.17.96.42 1.89 1.36 2.15.59.17 2.05.57 4.39 1.23v3.32l-2.28.65"
          id="prefix__o"
        />
        <path
          d="M12.53 18.87c-.72-.34-2.11-1.03-4.17-2.05-.74-.37-1.64-.1-2.06.62-.47.82-1.06 1.83-1.5 2.61-.49.84-.26 1.92.53 2.49.5.35 1.74 1.24 3.72 2.65l-1.1 2.99-2.2-.29"
          id="prefix__p"
        />
        <path
          d="M29.74 7.69c-1.06-2.33-1.72-3.78-1.98-4.36a1.595 1.595 0 00-2-.84c-.89.32-1.99.72-2.83 1.03a1.8 1.8 0 00-1.1 2.23c.19.59.66 2.07 1.4 4.44l-2.96 1.92-1.63-1.65"
          id="prefix__q"
        />
        <path
          d="M20.27 12.11c-1.8-1.86-2.92-3.03-3.37-3.49-.58-.6-1.52-.65-2.15-.12-.73.61-1.62 1.36-2.31 1.94a1.89 1.89 0 00-.34 2.52c.34.5 1.2 1.76 2.58 3.76l-1.99 2.27-1.85-.94"
          id="prefix__r"
        />
      </defs>
      <use href="#prefix__a" className={classes.stroke} />
      <use href="#prefix__b" className={classes.stroke} />
      <use href="#prefix__c" className={classes.stroke} />
      <use href="#prefix__d" className={classes.stroke} />
      <use href="#prefix__e" className={classes.stroke} />
      <use href="#prefix__f" className={classes.stroke} />
      <use href="#prefix__g" className={classes.stroke} />
      <use href="#prefix__h" className={classes.stroke} />
      <use href="#prefix__i" className={classes.stroke} />
      <use href="#prefix__j" className={classes.stroke} />
      <use href="#prefix__k" className={classes.stroke} />
      <use href="#prefix__l" className={classes.stroke} />
      <use href="#prefix__m" className={classes.stroke} />
      <use href="#prefix__n" className={classes.stroke} />
      <use href="#prefix__o" className={classes.stroke} />
      <use href="#prefix__p" className={classes.stroke} />
      <use href="#prefix__q" className={classes.stroke} />
      <use href="#prefix__r" className={classes.stroke} />
      <text
        x={x}
        y={y}
        dominantBaseline="middle"
        textAnchor="middle"
        className={classes.svgText}
      >
        {bikeScore}
      </text>
    </svg>
  );
}
BikeScoreSvg.propTypes = {
  bikeScore: PropTypes.number,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
};
BikeScoreSvg.defaultProps = {
  bikeScore: 0,
  fontSize: '26px',
  height: '62px',
  x: '47%',
  y: '50%',
};

export default BikeScoreSvg;
