import { skins } from '~/legacy/consts/skins'
import { getSubdomain } from './getSubdomain'

const leaseup =
  'https://leaseup-production.s3.us-east-2.amazonaws.com/company_logos/leaseup_logo_v2.png'

export const getBrandLogo = (subdomain = getSubdomain()) => {
  const skin = skins[subdomain]
  return skin?.logoUrl ?? leaseup
}
