import React from 'react';
import orderBy from 'lodash/orderBy';

import { getUserName, objectMap } from '~/legacy/utils';
import { TextSelectField } from '~/legacy/components';

/*
Reusable util functions to sort and display lavel
*/
export const getAToZLabel = (name, zToA = false) =>
  `${name}: ${zToA ? 'Z-A' : 'A-Z'}`;

export const getDescendingLabel = (name, ascending = false) =>
  `${name}: ${ascending ? 'Ascending' : 'Descending'}`;

export const getNewToOldLabel = (name, oldToNew = false) =>
  `${name}: ${oldToNew ? 'Old to New' : 'New to Old'}`;

const SURVEYS_SORT_OPTION_LABELS = {
  NAME: 'Name',
  CLIENT: 'Client',
  OWNER: 'Owner',
  DATE_UPDATED: 'Date Updated',
  DATE_CREATED: 'Date Created',
};

export const SURVEY_SORTS = {
  NAME_A_TO_Z: {
    id: 0,
    getLabel: () => getAToZLabel(SURVEYS_SORT_OPTION_LABELS.NAME),
    sort: (surveys) => orderBy(surveys, [(survey) => survey.name], 'asc'),
  },
  NAME_Z_TO_A: {
    id: 1,
    getLabel: () => getAToZLabel(SURVEYS_SORT_OPTION_LABELS.NAME, true),
    sort: (surveys) => orderBy(surveys, [(survey) => survey.name], 'desc'),
  },
  OWNER_A_TO_Z: {
    id: 2,
    getLabel: () => getAToZLabel(SURVEYS_SORT_OPTION_LABELS.OWNER),
    sort: (surveys) =>
      orderBy(surveys, [(survey) => getUserName(survey.owner)], 'asc'),
  },
  OWNER_Z_TO_A: {
    id: 3,
    getLabel: () => getAToZLabel(SURVEYS_SORT_OPTION_LABELS.OWNER, true),
    sort: (surveys) =>
      orderBy(surveys, [(survey) => getUserName(survey.owner)], 'desc'),
  },
  DATE_UPDATED_DESCENDING: {
    id: 4,
    getLabel: () => getNewToOldLabel(SURVEYS_SORT_OPTION_LABELS.DATE_UPDATED),
    sort: (surveys) =>
      orderBy(surveys, (survey) => new Date(survey.updated_at), 'desc'),
  },
  DATE_UPDATED_ASCENDING: {
    id: 5,
    getLabel: () =>
      getNewToOldLabel(SURVEYS_SORT_OPTION_LABELS.DATE_UPDATED, true),
    sort: (surveys) =>
      orderBy(surveys, (survey) => new Date(survey.updated_at), 'asc'),
  },
  DATE_CREATED_DESCENDING: {
    id: 6,
    getLabel: () => getNewToOldLabel(SURVEYS_SORT_OPTION_LABELS.DATE_CREATED),
    sort: (surveys) =>
      orderBy(surveys, (survey) => new Date(survey.created_at), 'desc'),
  },
  DATE_CREATED_ASCENDING: {
    id: 7,
    getLabel: () =>
      getNewToOldLabel(SURVEYS_SORT_OPTION_LABELS.DATE_CREATED, true),
    sort: (surveys) =>
      orderBy(surveys, (survey) => new Date(survey.created_at), 'asc'),
  },
};

// {0: { id, getLabel, sort, ...}, {}, ...}
const SURVEY_SORTS_LOOKUP = objectMap({
  obj: SURVEY_SORTS,
  newKeyFn: (surveySortName, surveySort) => surveySort.id,
});

export const DEFAULT_SURVEY_SORT_OPTIONS = Object.fromEntries(
  Object.values(SURVEY_SORTS).map((sortValue) => [
    sortValue.id,
    sortValue.getLabel(),
  ])
);

export const sortSurveys = (surveys, surveySortId) => {
  if (surveySortId || surveySortId === 0) {
    const sort = SURVEY_SORTS_LOOKUP[surveySortId];
    if (sort && sort.sort) {
      return sort.sort(surveys);
    }
  }
  return surveys;
};

/*
User Sorting
TODO: at some point probably move these to separate user class
*/
const USER_SORT_OPTION_LABELS = {
  NAME: 'Name',
  ROLE: 'Role',
  LAST_ACTIVE: 'Activity',
  DATE_JOINED: 'Joined',
};

export const USER_SORTS = {
  NAME_A_TO_Z: {
    id: 0,
    getLabel: () => getAToZLabel(USER_SORT_OPTION_LABELS.NAME),
  },
  NAME_Z_TO_A: {
    id: 1,
    getLabel: () => getAToZLabel(USER_SORT_OPTION_LABELS.NAME, true),
  },
  ROLE_DESCENDING: {
    id: 4,
    getLabel: () => getDescendingLabel(USER_SORT_OPTION_LABELS.ROLE),
  },
  ROLE_ASCENDING: {
    id: 5,
    getLabel: () => getDescendingLabel(USER_SORT_OPTION_LABELS.ROLE, true),
  },
  LAST_ACTIVE_DESCENDING: {
    id: 6,
    getLabel: () => getNewToOldLabel(USER_SORT_OPTION_LABELS.LAST_ACTIVE),
  },
  LAST_ACTIVE_ASCENDING: {
    id: 7,
    getLabel: () => getNewToOldLabel(USER_SORT_OPTION_LABELS.LAST_ACTIVE, true),
  },
  DATE_JOINED_DESCENDING: {
    id: 8,
    getLabel: () => getNewToOldLabel(USER_SORT_OPTION_LABELS.DATE_JOINED),
  },
  DATE_JOINED_ASCENDING: {
    id: 9,
    getLabel: () => getNewToOldLabel(USER_SORT_OPTION_LABELS.DATE_JOINED, true),
  },
};

export const DEFAULT_USER_SORT_OPTIONS = Object.fromEntries(
  Object.values(USER_SORTS).map((sortValue) => [
    sortValue.id,
    sortValue.getLabel(),
  ])
);

export const sortUsers = (users, sortOrder) => {
  switch (sortOrder) {
    default:
    case USER_SORTS.NAME_A_TO_Z.id:
      return orderBy(users, [(user) => getUserName(user)], 'asc');
    case USER_SORTS.NAME_Z_TO_A.id:
      return orderBy(users, [(user) => getUserName(user)], 'desc');
    case USER_SORTS.ROLE_DESCENDING.id:
      return orderBy(users, ['user_role'], ['desc']);
    case USER_SORTS.ROLE_ASCENDING.id:
      return orderBy(users, ['user_role'], ['asc']);
    case USER_SORTS.DATE_JOINED_DESCENDING.id:
      return orderBy(users, (user) => new Date(user.date_joined), ['desc']);
    case USER_SORTS.DATE_JOINED_ASCENDING.id:
      return orderBy(users, (user) => new Date(user.date_joined), ['asc']);
  }
};

// Generalized reusable Sort By component. Can prepare options and inputs like above and not worry about styling.
export const SortByTextField = ({ options, ...props }) => {
  return (
    <TextSelectField
      label="Sort By"
      options={options}
      showSelectedOptionIcon
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  );
};
