import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core'
import { formatMoney } from '~/legacy/utils'

const useStyles = makeStyles((theme) => {
  const pdf = (a, b) => (theme.isPdf ? a : b)
  const scale = (value) => pdf((value * 4) / 3, value)
  const px = (value) => `${scale(value)}px`
  const columns = [188, 72, 48, 70, 74, 74, 74].map(px)

  return {
    container: {
      boxSizing: 'border-box',
      padding: px(24),
      height: pdf('100vh', '100%'),
      width: pdf('100vw', '100%'),
    },
    table: {
      gridTemplateColumns: `${columns.join(' ')} 1fr`,
      gridTemplateRows: px(58),
      gridAutoRows: px(63),
      background: '#E0E0E0',
      display: 'grid',
      gap: '1px',
    },
    cell: {
      letterSpacing: px(0.5),
      alignItems: 'center',
      background: '#FFFFFF',
      fontSize: px(8),
      padding: px(7),
      display: 'flex',
      color: '#111111',
      gap: px(10),
    },
    attr: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    header: {
      background: '#F9F9F9',
      color: '#666666',
    },
    name: {
      fontWeight: 'bold',
    },
    image: {
      marginLeft: px(-7),
      objectFit: 'cover',
      background: '#C4C4C4',
      maxWidth: px(74),
      border: 'none',
      height: '100%',
      width: '100%',
    },
  }
})

function ComparePageLayout1(props) {
  const { selectedListingIndex = 0, settings, surveyListings } = props
  const { showImages, showFullAddress } = settings
  const pageOffset = selectedListingIndex * 8

  const styles = useStyles()
  const classes = (...names) =>
    names.map((name) => styles[name] || name).join(' ')
  const cell = classes('cell')
  const attr = classes(cell, 'attr')
  const header = classes(attr, 'header')

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={header}>BUILDING</div>
        <div className={header}>CITY</div>
        <div className={header}>SUITE</div>
        <div className={header}>AVAILABLE (SQFT)</div>
        <div className={header}>LEASE RATE (PSF)</div>
        <div className={header}>LEASE TYPE</div>
        <div className={header}>ESTIMATED MONTHLY LEASE RATE</div>
        <div className={header}>NOTES</div>

        {surveyListings.slice(pageOffset, pageOffset + 8).map(({ listing }) => {
          const { id, lease_type, size, sqft_price } = listing
          const { card_image_url } = listing.hero_photo || {}
          const { city, address, address2, state, zipcode } =
            listing.building || {}

          return (
            <Fragment key={id}>
              <div className={cell}>
                {showImages && (
                  <img className={styles.image} src={card_image_url} alt="" />
                )}
                <div>
                  <div className={styles.name}>Building Name</div>
                  {showFullAddress && (
                    <>
                      <div>
                        {address} 
                        {' '}
                        {address2}
                      </div>
                      <div>
                        {city}
,
                        {state} 
                        {' '}
                        {zipcode}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={attr}>{city}</div>
              <div className={attr}>{null}</div>
              <div className={attr}>{size}</div>
              <div className={attr}>{formatMoney(listing.sqft_price)}</div>
              <div className={attr}>{lease_type}</div>
              <div className={attr}>
                {size && sqft_price && formatMoney(size * sqft_price)}
              </div>
              <div className={cell}>{null}</div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default ComparePageLayout1
