import React from 'react';
import { Tabs as MuiTabs, Tab, Grow, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Typography } from '~/legacy/components';

const Tabs = withStyles({
  tabsFlexContainer: {
    borderBottom: '1px solid #e0e0e0',
  },
  tabRoot: {
    padding: '0 12px',
    minWidth: 'unset',
    color: '#111',
    opacity: 1,
  },
  indicator: {
    marginTop: '-1px',
  },
  tabHeaderText: {
    lineHeight: '21px',
  },
})(({ className, selectedTab, setSelectedTab, tabLabels, classes }) => {
  return (
    <Grow in>
      <MuiTabs
        action={(ref) => ref && ref.updateIndicator()}
        indicatorColor="primary"
        className={clsx(classes.tabs, className)}
        classes={{
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.indicator,
        }}
        value={selectedTab}
      >
        {tabLabels.map((label, index) => (
          <Tab
            key={label}
            classes={{
              root: classes.tabRoot,
            }}
            label={
              <Typography
                variant="boldText"
                className={clsx(classes.tabHeaderText)}
              >
                {label}
              </Typography>
            }
            onClick={() => setSelectedTab(index)}
          />
        ))}
      </MuiTabs>
    </Grow>
  );
});

export default Tabs;
