import React, { useState, useEffect, memo } from 'react';
import Api from 'rest-fetcher-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import Fuse from 'fuse.js';
import * as Sentry from '@sentry/browser';
import {
  fetchPlacesResults,
  getSearchAddress,
  parseGoogleAddressValues,
} from '~/legacy/utils';
import { BUILDING_TYPE, LISTING_TYPE } from '~/legacy/consts';
import { BuildingIcon } from '~/legacy/components/svgs';

const geocoder = new google.maps.Geocoder();
const TOP_FUSE_RESULTS = 20;
const TOP_FUSE_CATEGORY_RESULTS = { [LISTING_TYPE]: null, [BUILDING_TYPE]: 2 };

const LISTING_GROUP_COPY = 'Listings';
const BUILDING_GROUP_COPY = 'Buildings';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: '8px',
  },
}));

// Format a leaseup building for the Fuse search object
const formatBuildingForFuseResult = (building) => {
  let displayAddress = building.address || '';
  if (building.type === LISTING_TYPE && building.space_name) {
    displayAddress = `${building.address} ${building.space_name}`;
  }
  return { ...building, displayAddress };
};

// Format a Fuse search result to match out unified Option interface. Both Fuse and Google places meet this
const formatFuseResult = (item, searchedInputValue) => {
  const parts = [];

  // For each matched address, *manually* build the hilighted/non-hilighted
  //   portions of the string for display
  const index = searchedInputValue
    ? item.displayAddress
        .toLocaleLowerCase()
        .indexOf(searchedInputValue.toLocaleLowerCase())
    : -1;
  if (index !== -1) {
    // Example:
    //   Address: "333 Great River Road"
    //   User Typed: "Great River"
    const matchStart = index;
    const matchEnd = Math.min(
      item.displayAddress.length,
      index + searchedInputValue.length
    );
    // ie: "333 "
    let matchingText = item.displayAddress.substring(0, matchStart);
    if (matchingText.length > 0) {
      parts.push({
        text: matchingText,
        highlight: false,
        key: parts.length,
      });
    }
    // ie: "Great River"
    matchingText = item.displayAddress.substring(matchStart, matchEnd);
    if (matchingText.length > 0) {
      parts.push({
        text: matchingText,
        highlight: true,
        key: parts.length,
      });
    }
    // ie: " Road"
    matchingText = item.displayAddress.substring(
      matchEnd,
      item.displayAddress.length
    );
    if (matchingText.length > 0) {
      parts.push({
        text: matchingText,
        highlight: false,
        key: parts.length,
      });
    }
  } else {
    parts.push({
      text: item.displayAddress,
      highlight: false,
      key: parts.length,
    });
  }
  let secondaryText = `${item.city}, ${item.state}`;
  let description = `${item.displayAddress}, ${item.city}, ${item.state} ${item.zipcode}`;
  if (item.country) {
    description += `, ${item.country}`;
    secondaryText += `, ${item.country}`;
  }

  const result = {
    isFuseMatch: true,
    id: item.id,
    type: item.type,
    description,
    structured_formatting: {
      secondary_text: secondaryText,
    },
    parts,
    // We can just easily set these for the Fuse match, no need to parse complexity.
    displayAddress: item.displayAddress,
    address: item.address,
    city: item.city,
    state: item.state,
    zipcode: item.zipcode,
    country: item.country,
  };
  return result;
};

function AddressAutocomplete({
  error,
  helperText,
  required,
  setAddressValues,
  controlledValue = {}, // { address, city, zipcode, state, id, type, isFuseMatch,  }
  label,
  textFieldProps = {},
  fetchFreshBuildingData = true,
  // If true, specify the following 3 parameters to control selecting the building
  showExistingBuildings = false,
  excludeExistingSurveyBuildings = false,
  setBuildingValues = () => {},
  overrideBuildingValues = () => {},
  // If true, specify the following 2 parameters. Selecting a listing will forward the user
  //   to the Add Listing page.
  // TODO: this functionality is sort of hacked in via boolean checking
  showExistingListings = false,
  surveyId,
  addExistingListingToSurvey,
  // for edit bulk
  setIsFetchingPlaces = () => {},
  autoFocus = true,
  preOnChange = () => {},
  ...props
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  // Option representing the selected option
  const [displayOption, setDisplayOption] = useState(null);
  // The Google Maps options from autocomplete service
  const [gmapsOptions, setGmapsOptions] = useState([]);
  // The Fuse options from the user's buildings
  const [fuseOptions, setFuseOptions] = useState([]);
  // The building set and Fuse searcher on them
  const [fuse, setFuse] = useState(null);
  // Whether or not the user previously selected an existing building
  const [
    wasExistingBuildingPreviosulySelected,
    setWasExistingBuildingPreviouslySelected,
  ] = useState(false);

  useEffect(() => {
    // If we want to show existing building/listing options, get them together from the server
    //   and prepare the Fuse search set.
    if (showExistingBuildings || showExistingListings) {
      Api.getAvailableAddresses({
        GET: {
          survey_id: surveyId || null,
          show_existing_buildings: showExistingBuildings,
          show_existing_listings: showExistingListings,
          exclude_existing_survey_buildings: excludeExistingSurveyBuildings,
        },
      }).then((results) => {
        // Format the data for the fuse search. We want to append listing space names
        //   onto the address so that it is viewable/searchable.
        // ie: 333 Washington Street Unit 1
        const fuseData = results.data.map((value) =>
          formatBuildingForFuseResult(value)
        );
        const fuseSettings = {
          shouldSort: true,
          threshold: 0.0, // Exact string matches
          ignoreLocation: true, // Matches regardless of location in string
          keys: [
            {
              name: 'displayAddress',
              weight: 2, // Address matches are more important
            },
            'city',
            'state',
            'zipcode',
          ],
        };
        setFuse(new Fuse(fuseData, fuseSettings));
      });
    }
  }, [showExistingBuildings, showExistingListings]);

  useEffect(() => {
    let active = true;

    // If the user hasn't typed anything, no options
    if (
      !inputValue ||
      (displayOption && inputValue === displayOption.description)
    ) {
      setGmapsOptions([]);
      setFuseOptions([]);
      return undefined;
    }

    // If they have, get the google autocomplete options
    setIsFetchingPlaces(true);
    fetchPlacesResults({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = results;
        }
        setGmapsOptions(newOptions);
      }
      setIsFetchingPlaces(false);
    });

    // If we are showing existing buildings, then Fuse search them to get the
    //   top TOP_FUSE_RESULTS results and format them to look mostly like Google
    //    autcomplete service results for more uniform displaying
    if (
      (showExistingBuildings || showExistingListings) &&
      fuse &&
      inputValue.trim().length
    ) {
      const numFound = { [LISTING_TYPE]: 0, [BUILDING_TYPE]: 0 };
      let results = fuse
        .search(inputValue)
        .slice(0, TOP_FUSE_RESULTS)
        .filter((fuseMatch) => {
          // Ensure that we show some (and a limited number of) matched buildings and listings
          if (
            TOP_FUSE_CATEGORY_RESULTS[fuseMatch.item.type] == null ||
            numFound[fuseMatch.item.type] <
              TOP_FUSE_CATEGORY_RESULTS[fuseMatch.item.type]
          ) {
            numFound[fuseMatch.item.type]++;
            return true;
          }
          return false;
        });
      if (results) {
        results = results.sort((a, b) => {
          return b.item.type - a.item.type;
        });
      }

      const formattedResults = [];
      results.forEach(({ item }) => {
        formattedResults.push(formatFuseResult(item, inputValue));
      });

      setFuseOptions(formattedResults);
    }

    return () => {
      active = false;
    };
  }, [inputValue, showExistingBuildings, fuse]);

  const clearBuildingAddress = () => {
    if (wasExistingBuildingPreviosulySelected) {
      // If a building was previously selected, clear out all the building fields
      overrideBuildingValues({ type: BUILDING_TYPE });
      setWasExistingBuildingPreviouslySelected(false);
    } else {
      // Else they are just clearing the building address, so just clear that
      setAddressValues({
        address: null,
        city: null,
        state: null,
        zipcode: null,
        country: null,
      });
    }
    setInputValue(null);
    setDisplayOption(null);
  };

  useEffect(() => {
    // Whenever the value changes (JUST a string representing the address), we
    //   need to select a display option.
    if (controlledValue && controlledValue.address) {
      // If the controlled value is a fuse match and a building, then we have all the info we need, just set the
      //   display option.
      if (
        controlledValue.isFuseMatch === true &&
        controlledValue.id &&
        controlledValue.type === BUILDING_TYPE
      ) {
        const formattedBuilding = formatBuildingForFuseResult(controlledValue);
        const newValue = formatFuseResult(formattedBuilding, inputValue);
        // const newValue = { ...controlledValue, description: getSearchAddress(controlledValue), secondaryText };
        setWasExistingBuildingPreviouslySelected(true);
        setDisplayOption(newValue);
      } else {
        // Else, we search the string in Google autocomplete, create an option out of the
        //   result set, and then select that option.
        const searchAddress = getSearchAddress(controlledValue);
        // this is broken because I removed the detail from address... for non fuse matches this is important! / necessary...
        // Need like, a search address rather than description. That's the best way to do this.
        setIsFetchingPlaces(true);
        fetchPlacesResults({ input: searchAddress }, (results) => {
          if (results && results.length) {
            const newValue = results[0];
            if (controlledValue.id && controlledValue.type === BUILDING_TYPE) {
              setWasExistingBuildingPreviouslySelected(true);
              newValue.isFuseMatch = true;
            }
            setDisplayOption(newValue);
          } else {
            // If we didn't find any matches for the provided address, then clear out
            //   the building address
            clearBuildingAddress();
          }
          setIsFetchingPlaces(false);
        });
      }
    } else {
      // we don't want the buttons disabled even if we don't get an address
      setIsFetchingPlaces(false);
    }
  }, [
    controlledValue.address,
    controlledValue.city,
    controlledValue.state,
    controlledValue.zipcode,
    controlledValue.type,
    setDisplayOption,
  ]);

  return (
    <Autocomplete
      ListboxProps={{ style: { maxHeight: '350px' } }}
      id="google-map-demo"
      groupBy={(option) => {
        if (showExistingBuildings || showExistingListings) {
          return option.isFuseMatch && option.type === LISTING_TYPE
            ? LISTING_GROUP_COPY
            : BUILDING_GROUP_COPY;
        }
        return '';
      }}
      getOptionLabel={(option) =>
        option.isFuseMatch
          ? `${option.description}   (existing building)`
          : option.description
      }
      filterOptions={(x) => x}
      options={
        displayOption
          ? [displayOption, ...fuseOptions, ...gmapsOptions]
          : [...fuseOptions, ...gmapsOptions]
      }
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={displayOption}
      onChange={(event, newValue) => {
        preOnChange(event, newValue);
        // Basically, when the user selects an option:
        //   If it was a Google option, use the place id to get the complete address info from
        //     the Google geocode api, and set the controlled address with the results.
        //   If it was an existing building, then we already have all the address info we need.
        //     This is because the info in our DB was saved from a previous google geocode search
        //     in the past, and we saved everything we needed.
        //   In either case, setting the controlled address will cause the component controlledValue
        //     to update and thus trigger the effect to hit the autocomplete api, create an option, and
        //     select the option.
        if (newValue) {
          if (!newValue.isFuseMatch) {
            const wasExistingBuildingPreviosulySelectedNow =
              wasExistingBuildingPreviosulySelected;
            // If its a google match, then use the place id to get the geocoded address data.
            //   We need to do this because autocomplete doesn't give back all the address info
            //   we need.
            geocoder.geocode(
              { placeId: newValue.place_id },
              (results, status) => {
                if (!['OK', 'ZERO_RESULTS'].includes(status)) {
                  Sentry.captureMessage('Unexpected Geocoder API Status', {
                    tags: {
                      apiStatus: status,
                    },
                  });
                }
                const newAddressValues = parseGoogleAddressValues(
                  results[0].address_components,
                  results[0].geometry
                );
                // Clear out the building values when de-selecting an existing building
                setWasExistingBuildingPreviouslySelected(false);
                if (wasExistingBuildingPreviosulySelectedNow) {
                  overrideBuildingValues({
                    ...newAddressValues,
                    type: BUILDING_TYPE,
                  });
                } else {
                  setAddressValues(newAddressValues);
                }
              }
            );
          } else {
            // If we are showing existing listings, we have special handling
            // If its a building, go to the create listing in a building page
            if (showExistingListings && newValue.type !== BUILDING_TYPE) {
              // Fire away adding the listing to the survey and close the modal
              addExistingListingToSurvey(newValue.id);
              return;
            }

            setWasExistingBuildingPreviouslySelected(true);
            // The address values of the selected option
            const newAddressValues = {
              address: newValue.address,
              city: newValue.city,
              state: newValue.state,
              zipcode: newValue.zipcode,
              country: newValue.country,
            };
            // The values from the selected option for display. We already have these so we set them here,
            //   no need to refetch them after selecting the option.
            const displayValues = {
              isFuseMatch: newValue.isFuseMatch,
              type: newValue.type,
              description: newValue.description,
              id: newValue.id,
            };

            // If we want to fetch other data about the building, get that here.
            // TODO: this API call could be eliminated if we put all the info we need in the fuse results, not worth
            //   right now as we are refactoring LDP / edit page. Keep in mind.
            if (fetchFreshBuildingData) {
              Api.getBuildingDetails({
                id: newValue.id,
              }).then((result) => {
                if (
                  result &&
                  result.data &&
                  result.data.constructor === Object
                ) {
                  // Update the building values, THEN the address values. Otherwise we double trigger
                  //   a state update change if both of these setters are modifying the controlledValue, which
                  //   in some cases it is. We are a bit overloaded here...
                  setBuildingValues(
                    {
                      ...result.data,
                      ...newAddressValues,
                      ...displayValues,
                    },
                    () => {
                      setAddressValues({ ...newAddressValues });
                    }
                  );
                }
              });
            } else {
              // Otherwise, just update the building values with what we already have.
              setBuildingValues({
                ...newAddressValues,
                ...displayValues,
              });
            }
          }
        } else {
          // No text in the search bar - clear out the address values (hitting the x button)
          clearBuildingAddress();
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          error={error}
          helperText={helperText}
          required={required}
          label={label === undefined ? 'Enter an address' : label}
          variant="outlined"
          fullWidth
          autoFocus={autoFocus}
        />
      )}
      renderOption={(option) => {
        if (!option) {
          return '';
        }
        let parts;
        if (option.isFuseMatch) {
          // We previously did the match hilighting for Fuse matches
          ({ parts } = option);
        } else {
          // Get the matched portion of the autocomplete search
          let matches = [];
          // If we get the matched substring from Google use that
          if (option.structured_formatting.main_text_matched_substrings) {
            matches = option.structured_formatting.main_text_matched_substrings;
          } else if (option.structured_formatting.main_text) {
            // Sometimes the match across parts doesn't work for whatever reason, so lets force a match so it looks nice
            matches = [
              {
                offset: 0,
                length: option.structured_formatting.main_text.length,
              },
            ];
          }
          parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
        }

        // Get the icon to show for the option
        let LocationIcon;
        if (option.isFuseMatch) {
          if (option.type === BUILDING_TYPE) {
            LocationIcon = BuildingIcon;
          } else {
            LocationIcon = BusinessIcon;
          }
        } else {
          LocationIcon = LocationOnIcon;
        }

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part) => (
                <span
                  key={part.key ? `${part.key}-${part.text}` : part.text}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
      {...props}
    />
  );
}

export default memo(AddressAutocomplete);
