import { chunk } from 'lodash'
import { count } from '~/support/count'
import { defineTemplate } from '~/pdfs/support/defineTemplate'
import { CresaCover } from './pages/CresaCover'
import { CresaFloorplan } from './pages/CresaFloorplan'
import { CresaItenerary } from './pages/CresaItenerary'
import { CresaIteneraryDivider } from './pages/CresaIteneraryDivider'
import { CresaMap } from './pages/CresaMap'
import { CresaMapDivider } from './pages/CresaMapDivider'
import { CresaProperty } from './pages/CresaProperty'
import { CresaPropertyDivider } from './pages/CresaPropertyDivider'
import { CresaSummary } from './pages/CresaSummary'
import { CresaSummaryDivider } from './pages/CresaSummaryDivider'
import { CresaToC } from './pages/CresaToC'

export const Cresa = defineTemplate({
  id: '8a63b769-7320-41af-bdc8-343cb2326ec9',

  name: 'Cresa',

  pages: [
    CresaCover,
    CresaToC,
    CresaIteneraryDivider,
    CresaItenerary,
    CresaMapDivider,
    CresaMap,
    CresaSummaryDivider,
    CresaSummary,
    CresaPropertyDivider,
    CresaProperty,
    CresaFloorplan,
  ],

  stats: ({ data, pages }) => {
    return [
      count('Building', data.buildings.length),
      count('Space', data.spaces.length),
      count('Page', pages.length),
    ].join(', ')
  },

  build: (pdf) => {
    const buildings = pdf.data.buildings
      .filter((building) => {
        return building.spaces.length > 0
      })
      .map((building, order) => {
        return { ...building, order }
      })

    const spaces = buildings.flatMap((building) => {
      building.spaces = building.spaces.map((space) => {
        return { ...space, building }
      })

      return building.spaces
    })

    pdf.data = {
      ...pdf.data,
      buildings,
      spaces,
    }

    const summaries = chunk(spaces, pdf.settings.spacesPerPage).map(
      (spaces, index) => ({
        id: `${CresaSummary.id}.${index}`,
        props: CresaSummary.props(pdf, spaces),
        template: CresaSummary,
      })
    )

    const properties = buildings.map((building) => [
      {
        id: `${CresaProperty.id}.${building.id}`,
        props: CresaProperty.props(pdf, building),
        template: CresaProperty,
      },
      building.spaces
        .filter((space) => space.floorplans.length > 0)
        .map((space) => ({
          id: `${CresaFloorplan.id}.${space.id}`,
          props: CresaFloorplan.props(pdf, space),
          template: CresaFloorplan,
        })),
    ])

    return [
      { template: CresaCover, props: CresaCover.props(pdf) },
      { template: CresaToC },
      { template: CresaIteneraryDivider },
      { template: CresaItenerary, props: CresaItenerary.props(pdf) },
      { template: CresaMapDivider },
      { template: CresaMap, props: CresaMap.props(pdf) },
      { template: CresaSummaryDivider },
      summaries,
      { template: CresaPropertyDivider },
      properties,
    ].flat(3)
  },

  settings: {
    state: {
      name: '',
      location: null,
      spacesPerPage: 10,
      marketId: 'urban',
      typeId: 'survey',
      date: '',
    },
  },
})
