import React, { useMemo, useState } from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Inspector } from '~/pdfs/patterns/Inspector'
import { PdfCanvas } from '~/pdfs/patterns/PdfCanvas'
import { PdfHierarchy } from '~/pdfs/patterns/PdfHierarchy'
// import { Button } from '~/legacy/components'
import { useMessage } from '~/libraries/messaging.react'
import { useSend } from '~/support/useSend'
import { valueOf } from '~/support/valueOf'
import { usePdf } from '~/support/usePdf'
import { usePagination } from '~/support/usePagination'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '0px',
    width: '100%',
  },
  header: {
    height: '66px',
    width: '100%',
    borderBottom: '1px solid #e0e0e0',
    padding: '0 32px',
    display: 'flex',
    alignItems: 'center',
  },
  stats: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  button: {
    marginLeft: '20px',
    height: '36px',
    width: '120px',
  },
  editor: {
    display: 'flex',
    flex: 1,
    minHeight: '0px',
    width: '100%',
  },
  hierarchy: {
    borderRight: '1px solid #e0e0e0',
    width: '250px',
    flexShrink: 0,
  },
  canvas: {
    flexGrow: 1,
  },
  inspector: {
    borderLeft: '1px solid #e0e0e0',
    width: '320px',
    flexShrink: 0,
  },
  link: {
    textDecoration: 'none',
  },
})

export const PdfEditor = ({ children, templates, data }) => {
  const [exporting, setExporting] = useState(false)
  const { state: pdf, isValidating } = usePdf(templates, data)
  const pages = useMemo(() => pdf.template.build(pdf), [pdf])
  const pagination = usePagination(pages.map((page) => page.id))
  const classes = useStyles()
  const send = useSend()

  useMessage('SelectPageType', (data) => {
    const page = pages.find((page) => page.template.id === data.pageId)
    if (page) send('Goto', page.id)
  })

  useMessage('PdfExported', () => {
    setExporting(false)
  })

  if (isValidating) {
    return null
  }

  const page = pages.find((page) => {
    return page.id === pagination.current
  })

  if (!page) {
    return null
  }

  const { Page, Settings } = page.template

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {children}

        <Typography className={classes.stats} variant="h3">
          {valueOf(pdf.template.stats, { ...pdf, pages })}
        </Typography>

        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disableElevation
          onClick={() => {
            if (exporting) return
            setExporting(true)
            send('ExportPdf', { pdf, pages })
          }}
        >
          {exporting ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            'Export PDF'
          )}
        </Button>
      </div>

      <div className={classes.editor}>
        <PdfHierarchy
          className={classes.hierarchy}
          templates={templates}
          template={pdf.template}
          selection={page.template}
          all={pdf.template.pages}
          active={pdf.template.pages.filter((page) => {
            return !pdf.excluded.includes(page)
          })}
        />

        <PdfCanvas
          className={classes.canvas}
          index={pagination.index}
          size={pagination.size}
          format={pdf.template.format}
          landscape={pdf.template.landscape}
          width={pdf.template.width}
          height={pdf.template.height}
        >
          <Page pages={pages} {...page.props} />
        </PdfCanvas>

        <Inspector className={classes.inspector}>
          <Settings pages={pages} {...page.props} />
        </Inspector>
      </div>
    </div>
  )
}
