import React from 'react';
import {
  DeleteSurveyMenuItem,
  useAdminDeleteSurveysModal,
} from '~/legacy/components';

export const useAdminDeleteSurveyMenuItem = ({
  survey,
  onDeleteSurveys = () => {},
  handleMenuClose = () => {},
}) => {
  // Get the modal for deleting the survey
  const {
    AdminDeleteSurveysModalComponent: AdminDeleteSurveyModalComponent,
    setSurveysToDelete,
  } = useAdminDeleteSurveysModal({
    onClose: handleMenuClose,
    onDeleteSurveys,
  });

  // Get the menu item for deleting the survey
  const AdminDeleteSurveyMenuItemComponent = (
    <AdminDeleteSurveyMenuItem
      openModal={() => {
        setSurveysToDelete(survey ? [survey] : []);
      }}
    />
  );

  return {
    AdminDeleteSurveyMenuItemComponent,
    AdminDeleteSurveyModalComponent,
  };
};

export const AdminDeleteSurveyMenuItem = React.forwardRef(
  ({ openModal, ...props }, ref) => {
    return (
      <>
        <DeleteSurveyMenuItem
          ref={ref}
          openDeleteSurveyModal={() => openModal(true)}
          {...props}
        />
      </>
    );
  }
);
