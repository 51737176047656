import React from 'react'
import { makeStyles } from '@material-ui/core'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { findFieldAndFormat } from '~/support/findFieldAndFormat'
import * as Fields from '../CresaFields'
import { CresaPage } from '../patterns/CresaPage'

const useStyles = makeStyles({
  title: {
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '18px',
    color: '#001E5A',
    paddingTop: '15px',
  },
  subtitle: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '18px',
    color: '#001E5A',
    paddingTop: '6px',
  },
  photo: {
    width: '100%',
    height: '500px',
    objectFit: 'scale-down',
  },
  space: {
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '12px',
    color: '#001E5A',
    paddingTop: '30px',
  },
  spaces: {
    gridTemplateColumns: '1fr 3fr 3fr 3fr 3fr',
    paddingTop: '8px',
    display: 'grid',
    paddingBottom: '22px',
  },
  head: {
    background: '#001E5A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 5px 4px',
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '10px',
    color: '#FFFFFF',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '9px',
    lineHeight: '10px',
    color: '#111111',
  },
})

export const CresaFloorplan = definePageTemplate({
  id: '460685d3-3bb9-4d4a-a5fb-3ce879ad0ed4',

  name: 'Floor Plan',

  props: (_, space) => ({
    site: space.building.order + 1,
    photo: space.floorplans[0].compressed,
    name: ['name', 'address']
      .map((key) => space.building.location[key])
      .filter(Boolean)
      .join(', '),
    subMarket: findFieldAndFormat(space.building.fields, Fields.SubMarket),
    city: ['city', 'state']
      .map((key) => space.building.location[key])
      .filter(Boolean)
      .join(', '),
    floor: findFieldAndFormat(space.fields, Fields.Floor),
    size: findFieldAndFormat(space.fields, Fields.Area),
    rent: findFieldAndFormat(space.fields, Fields.Price),
    type: findFieldAndFormat(space.fields, Fields.LeaseType),
    available: findFieldAndFormat(space.fields, Fields.Available),
  }),

  Page: (props) => {
    const classes = useStyles()
    return (
      <CresaPage title={`Site ${props.site}`}>
        <div className={classes.title}>{props.name}</div>
        <div className={classes.subtitle}>
          {[props.subMarket, props.city].filter(Boolean).join(' | ')}
        </div>
        <div className={classes.space}>Available Space</div>
        <div className={classes.spaces}>
          <div className={classes.head}>Floor</div>
          <div className={classes.head}>Rentable SF</div>
          <div className={classes.head}>Base Rent</div>
          <div className={classes.head}>Lease Type</div>
          <div className={classes.head}>Available</div>
          <div className={classes.field}>{props.floor}</div>
          <div className={classes.field}>{props.size}</div>
          <div className={classes.field}>{props.rent}</div>
          <div className={classes.field}>{props.type}</div>
          <div className={classes.field}>{props.available}</div>
        </div>
        <img className={classes.photo} src={props.photo} alt={props.name} />
      </CresaPage>
    )
  },
})
