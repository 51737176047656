export const BUILDING_TYPE = 1;
export const LISTING_TYPE = 2;

export const DESCRIPTION_LISTING_DETAILS_VIEW = 1;
export const KEY_VALUE_LISTING_DETAILS_VIEW = 3;

export const SURVEY_LISTING_STATUSES = {
  TOUR_REQUESTED: 'TOUR_REQUESTED',
  DECLINED: 'DECLINED',
  SHARED: 'SHARED',
};

export const SURVEY_LISTING_STATUS_CHANGES = {
  TOUR_CANCELED: 'TOUR_CANCELED',
};

export const BUILDING_INTEREST = {
  NEUTRAL: 0,
  NOT_INTERESTED: 1,
  FAVORITED: 2,
};

export const BUILDING_INTEREST_LOOKUP = {
  0: 'Neutral',
  1: 'Not Interested',
  2: 'Favorited',
};
