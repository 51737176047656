import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { PublicHeader } from '~/legacy/components';

const useStyles = makeStyles({
  link: {
    fontStyle: 'italic',
  },
  padding: {
    paddingTop: '40px',
  },
});

function MissingSubdomainContent() {
  const classes = useStyles();
  const queryParams = new URLSearchParams(useLocation().search);
  const subDomain = queryParams.get('sub_domain');
  return (
    <div>
      <div className={classes.padding}>
        <Typography variant="h1" component="div">
          <Typography variant="h1" className={classes.link} display="inline">
            {subDomain}
            .leaseup.co
          </Typography>
          {' '}
          does not exist
        </Typography>
      </div>

      <div className={classes.padding}>
        <Typography variant="h2">
          <Link href="/login">Click here</Link>
          {' to go to the main login page'}
        </Typography>
      </div>
    </div>
  );
}

function MissingSubdomain() {
  return <PublicHeader contentComponent={<MissingSubdomainContent />} />;
}

export default memo(MissingSubdomain);
