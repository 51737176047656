import { useEffect, useState } from 'react'
import { clamp } from '~/libraries/math'
import { useMessage } from '~/libraries/messaging.react'

export const usePagination = (items = []) => {
  const [value, setValue] = useState(() => items[0])
  const index = items.indexOf(value)

  const paginate = (offset) => {
    setValue(items[clamp(index + offset, 0, items.length - 1)])
  }

  useMessage('Goto', setValue)
  useMessage('GotoNext', () => paginate(1))
  useMessage('GotoPrevious', () => paginate(-1))
  useEffect(() => {
    if (index === -1) setValue(items[0])
  }, [index, items, setValue])

  return {
    current: value,
    index,
    size: items.length,
  }
}
