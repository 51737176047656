import React, { memo } from 'react';
import Api from 'rest-fetcher-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  SnackbarUtils,
  BULK_IMPORT_HELPERS,
  BULK_IMPORT_CONSTANTS,
} from '~/legacy/utils';
import { InlineEditableValue } from '~/legacy/components';

const useStyles = makeStyles({
  clearInputIcon: {
    height: '16px',
    cursor: 'pointer',
  },
  iconContainer: {
    minWidth: '32px',
    marginLeft: '7px',
    marginRight: '-2px',
    display: 'flex',
  },
  iconButtonContainer: {
    width: '32px',
    marginRight: '2px',
    marginLeft: '2px',
    display: 'flex',
  },
  input: {
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '5px 10px',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '5px 10px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0',
    },
  },
  label: {
    marginRight: '8px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '70%',
  },
  name: {
    textAlign: 'right',
    minWidth: 140,
    marginRight: 10,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '44px',
    width: '100%',
  },
  trash: {
    marginRight: 4,
  },
  units: {
    minWidth: 'fit-content',
    marginLeft: 4,
  },
  editingContainer: {
    minWidth: '150px',
  },
});

const DefaultInlineEditableValue = ({ viewOnly, ...props }) => {
  const classes = useStyles();
  return (
    <InlineEditableValue
      classesIn={{
        editingContainer: classes.editingContainer,
      }}
      styleOverrides={{
        hoverBackgroundColor: '#f9f9f9',
      }}
      viewOnly={viewOnly}
      placeholder="Enter a value"
      onClick={() => {}}
      {...props}
    />
  );
};

const StringFieldValue = ({
  customField,
  value,
  updateValueApi,
  updateValueApiCallback,
  viewOnly,
}) => {
  return (
    <DefaultInlineEditableValue
      type="text"
      viewOnly={viewOnly}
      fieldDataTypeId={customField.custom_field.data_type}
      value={value}
      updateValueApi={(newerValue) => updateValueApi(newerValue)}
      updateValueApiCallback={(data) =>
        updateValueApiCallback(data, 'Error saving value')
      }
    />
  );
};

// The cell displayed on the table to the user for numeric types
const NumericFieldValue = ({
  customField,
  value,
  viewOnly,
  errorString,
  updateValueApi,
  updateValueApiCallback,
}) => {
  return (
    <DefaultInlineEditableValue
      type="number"
      viewOnly={viewOnly}
      fieldDataTypeId={customField.custom_field.data_type}
      value={value}
      updateValueApi={(newerValue) => updateValueApi(newerValue)}
      updateValueApiCallback={(data) =>
        updateValueApiCallback(data, errorString)
      }
    />
  );
};

const DateFieldValue = ({
  customField,
  value,
  viewOnly,
  updateValueApi,
  updateValueApiCallback,
  errorString,
}) => {
  const noTimeRawValue = BULK_IMPORT_HELPERS.prepareDate(value);
  return (
    <DefaultInlineEditableValue
      type="date"
      viewOnly={viewOnly}
      fieldDataTypeId={customField.custom_field.data_type}
      value={noTimeRawValue}
      updateValueApi={updateValueApi}
      updateValueApiCallback={(data) =>
        updateValueApiCallback(data, errorString)
      }
    />
  );
};

const ListSemicolonFieldValue = ({
  customField,
  value,
  viewOnly,
  errorString,
  updateValueApi,
  updateValueApiCallback,
}) => {
  return (
    <DefaultInlineEditableValue
      type="text"
      viewOnly={viewOnly}
      fieldDataTypeId={customField.custom_field.data_type}
      value={value}
      // User is entering in a string, we transform that into a split array
      prepareNewValue={(newerValue) => {
        return BULK_IMPORT_HELPERS.splitListString(newerValue);
      }}
      // Join the array value into a string for display
      formatDisplayValue={(valueToDisplay) => {
        return valueToDisplay ? valueToDisplay.join(', ') : valueToDisplay;
      }}
      // Before updating the valie via api, trim/clean up the list
      updateValueApi={(newerValue) =>
        updateValueApi(BULK_IMPORT_HELPERS.prepareList(newerValue))
      }
      updateValueApiCallback={(data) =>
        updateValueApiCallback(data, errorString)
      }
    />
  );
};

// Update the custom field value/type via the API
const updateCustomField = (
  updateEndpoint,
  objectId,
  customField,
  customFieldDataTypeId,
  newValue
) => {
  return Api[updateEndpoint]({
    id: objectId,
    body: {
      ...customField,
      custom_field_id: customField.id,
      data_type: customFieldDataTypeId,
      value: newValue,
    },
  });
};

function Edit({
  customField = {},
  viewOnly = false,
  objectId,
  onSubmit = () => {},
  type,
}) {
  const classes = useStyles({ viewOnly });
  const newValue = customField.custom_field.value || '';

  let updateEndpoint = '';
  let deleteEndpoint = '';
  if (type === 'building') {
    updateEndpoint = 'updateBuildingCustomField';
    deleteEndpoint = 'deleteBuildingCustomField';
  } else if (type === 'listing') {
    updateEndpoint = 'updateListingCustomField';
    deleteEndpoint = 'deleteListingCustomField';
  }
  const customFieldDataType = customField.custom_field.data_type;

  const updateValueApi = (newerValue) => {
    if (newerValue) {
      const newestValue = newerValue;
      // Update the custom field via api
      return updateCustomField(
        updateEndpoint,
        objectId,
        customField,
        customFieldDataType,
        newestValue
      );
    }
    return Api[deleteEndpoint]({
      id: objectId,
      body: {
        custom_field_id: customField.id,
      },
    });
  };

  const updateValueApiCallback = (data, errorString = 'Error') => {
    if (data) {
      // If the update was successful, handle the response (save the new state to upstream objects)
      onSubmit(data.data, data.data.delete);
    } else {
      SnackbarUtils.error(errorString);
    }
  };

  let fieldComponent;

  if (
    customFieldDataType === BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id
  ) {
    fieldComponent = (
      <NumericFieldValue
        customField={customField}
        value={newValue}
        viewOnly={viewOnly}
        errorString="Error saving value, expects a number"
        updateValueApi={updateValueApi}
        updateValueApiCallback={updateValueApiCallback}
      />
    );
  } else if (
    customFieldDataType === BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id
  ) {
    fieldComponent = (
      <DateFieldValue
        customField={customField}
        value={newValue}
        viewOnly={viewOnly}
        errorString="Error saving value, expects a date"
        updateValueApi={updateValueApi}
        updateValueApiCallback={updateValueApiCallback}
      />
    );
  } else if (
    customFieldDataType === BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id
  ) {
    fieldComponent = (
      <ListSemicolonFieldValue
        customField={customField}
        value={newValue}
        viewOnly={viewOnly}
        errorString="Error saving value, expects a comma separated list"
        updateValueApi={updateValueApi}
        updateValueApiCallback={updateValueApiCallback}
      />
    );
  } else {
    fieldComponent = (
      <StringFieldValue
        customField={customField}
        value={newValue}
        viewOnly={viewOnly}
        updateValueApi={updateValueApi}
        updateValueApiCallback={updateValueApiCallback}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="h3">
        {`${customField.name}:`}
      </Typography>
      {fieldComponent}
    </div>
  );
}

export default memo(Edit);
