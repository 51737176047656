import useSWR from 'swr'
import { useFetch } from '~/support/useFetch'

const fallback = {
  name: 'Guest',
  initials: 'G',
  role: 'Client',
  flags: [],
}

export const useMe = () => {
  const fetch = useFetch()

  return useSWR('/api/me', async () => {
    const response = await fetch('/api/me')
    console.log({ response })
    return response ?? fallback
  })
}
