import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Api from 'rest-fetcher-redux'
import { Loading, PublicHeader } from '~/legacy/components'
import { LEASE_LOGIN, LEASE_ZENDESK } from '~/legacy/consts'
import { fillLocalStorage } from '~/legacy/utils'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 60px',
    overflow: 'hidden',
    position: 'absolute',
    top: '25%',
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: '20px',
    maxWidth: '250px',
  },
})

const EXPIRED_TOKEN_ERROR = 'b722fb17-d0a1-44a0-a4aa-63138ff845a7'
const INVALID_TOKEN_ERROR = '53b74789-25fd-4b15-8afa-3c35fb53b3c8'

/**
 * Proceses a magic link and then takes appropriate action, which primarily includes
 * - redeedming a valid ML token, logging the tenant in, and redirecting them
 * - redirecting already authenticated users
 * - sending users to the login page / homepage if the ML is invalid/redeemed, they don't have access,
 *     or an error has occured
 */
function MagicLinkContent() {
  const classes = useStyles()
  const queryParams = new URLSearchParams(useLocation().search)
  const magicLinkToken = queryParams.get('token')
  const zendeskRedirect = queryParams.get('zendesk_redirect')

  const loggedIn = useSelector((s) => s.user.loggedIn)
  const userType = useSelector((s) => s.user.userType)

  const [redirect, setRedirect] = useState()
  const [madeMagicLinkTokenRequest, setMadeMagicLinkTokenRequest] =
    useState(false)

  useEffect(() => {
    // If the user isn't logged in and we have a ML token, then let's try to redeem it
    // This means authenticating the user and redirecting them, if the ML hasn't been redeemed yet
    const redirect2 = '/home'

    if (!madeMagicLinkTokenRequest) {
      if (!loggedIn && magicLinkToken) {
        setMadeMagicLinkTokenRequest(true)
        Api.redeemMagicLinkToken({
          GET: {
            token: magicLinkToken,
          },
        }).then((res) => {
          if (res && res.api_error) {
            if (res.api_error.error_code === INVALID_TOKEN_ERROR) {
              // Token was invalid (doesn't exist)
              setRedirect(LEASE_LOGIN)
            } else if (res.api_error.error_code === EXPIRED_TOKEN_ERROR) {
              // Token is expired
              setRedirect(`${LEASE_LOGIN}?reason=expired`)
            }
          } else if (res && res.access && res.refresh) {
            // Successfully used the ML token and authed the user - log them in and redirect
            fillLocalStorage(res.access, res.refresh, res.skin)

            if (zendeskRedirect) {
              setRedirect(`${LEASE_ZENDESK}?return_to=${zendeskRedirect}`)
            } else {
              setRedirect(res.redirect ? res.redirect : redirect2)
            }
          } else {
            // Some sort of other error on the backend - nothing we can do
            setRedirect(LEASE_LOGIN)
          }
        })
      } else if (loggedIn && userType && magicLinkToken) {
        // Get the ML token so that we may redirect the user
        setMadeMagicLinkTokenRequest(true)
        Api.getMagicLinkTokenByToken({ GET: { token: magicLinkToken } }).then(
          (res) => {
            if (res && res.redirect) {
              setRedirect(res.redirect)
            } else {
              // Some sort of error occured, send them to homepage
              setRedirect(redirect2)
            }
          }
        )
      }
    }
  }, [madeMagicLinkTokenRequest, loggedIn, userType, magicLinkToken])

  if (redirect) {
    window.location.replace(redirect.replace('/next/', '/'))
  } else if (!magicLinkToken) {
    // If there isn't an ML token, essentially a 404. Go to login
    setRedirect(LEASE_LOGIN)
  }

  return (
    <Paper className={classes.loginContainer} elevation={5}>
      <div className={classes.content}>
        <div>
          <Loading isLoading size={40} />
        </div>
      </div>
    </Paper>
  )
}

function MagicLink() {
  return <PublicHeader contentComponent={<MagicLinkContent />} />
}

export default MagicLink
