import React from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_ICON_STYLES } from './ButtonUtils';

const useStyles = makeStyles(theme => ({
  ...BASE_ICON_STYLES(theme),
}));

const AddFromAnotherMenuItem = React.forwardRef(
  ({ onClick, type, ...props }, ref) => {
    const classes = useStyles();

    return (
      <>
        <MenuItem
          ref={ref}
          className={classes.menuItemRoot}
          onClick={() => {
            onClick();
          }}
          {...props}
        >
          <Typography className={classes.text}>
            {`Add Fields From Another ${type[0].toUpperCase()}${type.slice(1)}`}
          </Typography>
        </MenuItem>
      </>
    );
  }
);

export default AddFromAnotherMenuItem;
