import React from 'react'
import { mapboxUrl } from '~/support/mapboxUrl'
import { useMapboxPins } from '~/support/useMapboxPins'
import { Photo } from './Photo'

export const Mapbox = (props) => {
  const pins = useMapboxPins(props.coordinates)
  const url = mapboxUrl(
    Math.round(props.width / (props.scale ?? 1)),
    Math.round(props.height / (props.scale ?? 1)),
    pins.join(',')
  )
  return <Photo src={url} width={props.width} height={props.height} alt="map" />
}
