import { createElement } from 'react'
import { Settings } from '~/pdfs/patterns/Settings'

export const definePageTemplate = (config) => {
  const id = config.id || config.name
  const name = config.name || id

  const EmptyPage = () => {
    return createElement('div', null, name)
  }

  const template = {
    id,
    name,
    props: config.props,
    Page: config.Page || EmptyPage,
    Settings: config.Settings || Settings,
  }

  return template
}
