import { first, prop, styled } from '~/legacy/utils/styleHelpers';
import { Section } from './Section';

const keys = [
  'rounded',
  'circle',
  'bg',
  'w',
  'width',
  'h',
  'height',
  'circle',
  'square',
];

export const Skeleton = styled(Section, keys, {
  borderRadius: first(['rounded', 'circle']),
  background: prop('bg', '#E0E0E0'),
  animation: '$skeleton 1.5s ease-in-out 0.5s infinite',
  width: first(['w', 'width', 'circle', 'square']),
  height: first(['h', 'height', 'circle', 'square']),
  '@global': {
    '@keyframes skeleton': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0.4 },
      '100%': { opacity: 1 },
    },
  },
});
