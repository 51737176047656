import React from 'react';
import clsx from 'clsx';
import { Typography, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BULK_IMPORT_CONSTANTS } from '~/legacy/utils';
import { MenuSelectedItemIcon } from '~/legacy/components';

const MENU_ITEM_HORIZ_PADDING_PX = 12;
const useHeaderCellStyles = makeStyles({
  fieldHeaderMenuSection: {
    color: '#666',
    paddingTop: '12px',
    paddingBottom: '6px',
  },
  menuItem: {
    paddingLeft: `${MENU_ITEM_HORIZ_PADDING_PX}px`,
    paddingRight: `${MENU_ITEM_HORIZ_PADDING_PX}px`,
    alignItems: 'center',
  },
  menuItemIcon: {
    marginRight: '12px',
    color: '#111',
  },
  selectedItem: {
    marginLeft: 'auto',
    color: '#666',
  },
  menuSectionTitle: {
    lineHeight: '12px',
    fontFamily: 'Inter-Medium',
  },
});

const FieldDataTypeMenuSection = ({
  onClick,
  fieldDataTypeId,
  classesIn = {},
  sectionLabel = 'Data Type',
}) => {
  const classes = useHeaderCellStyles();
  return [
    <div
      key="fieldDataType"
      className={clsx(
        classes.fieldHeaderMenuSection,
        classesIn.fieldHeaderMenuSection,
        classes.menuItem
      )}
    >
      <Typography variant="h3" className={classes.menuSectionTitle}>
        {sectionLabel}
      </Typography>
    </div>,
    BULK_IMPORT_CONSTANTS.USER_SELECTABLE_FIELD_DATA_TYPES_ORDERED.map(
      (selectableFieldDataType) => {
        const FieldIconComponent = selectableFieldDataType.icon;
        return (
          <MenuItem
            className={classes.menuItem}
            disableGutters
            key={selectableFieldDataType.id}
            onClick={() => onClick(selectableFieldDataType)}
          >
            <FieldIconComponent className={classes.menuItemIcon} />
            <Typography color="textPrimary" variant="h3">
              {selectableFieldDataType.name}
            </Typography>
            {selectableFieldDataType.id === fieldDataTypeId && (
              <MenuSelectedItemIcon className={classes.selectedItem} />
            )}
          </MenuItem>
        );
      }
    ),
  ];
};

export default FieldDataTypeMenuSection;
