import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles({
  footer: {
    minHeight: '50px',
    maxHeight: '50px',
    backgroundColor: '#111111',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    padding: '0 32px',
    marginTop: 'auto',
  },
  footerLink: {
    margin: '0 12px',
    '&:hover': {
      color: '#ffffff',
    },
  },
  footerLinksContainer: {
    display: 'flex',
    marginLeft: 'auto',
  },
})

export default function Footer() {
  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <Typography variant="body1">
        &copy;
        {`${new Date().getFullYear()} LeaseUp All Rights Reserved`}
      </Typography>
      <div className={classes.footerLinksContainer}>
        <Link
          className={classes.footerLink}
          color="secondary"
          href="https://leaseup.co/terms-conditions/"
          underline="none"
          variant="body1"
        >
          Terms &amp; Conditions
        </Link>
        <Link
          className={classes.footerLink}
          color="secondary"
          href="https://leaseup.co/privacy-policy/"
          underline="none"
          variant="body1"
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  )
}
