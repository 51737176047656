import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from 'rest-fetcher-redux';
import { Typography, makeStyles } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

import { SquareCheckbox } from '~/legacy/components';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  section: {
    marginBottom: '40px',
  },
  sectionLabel: {
    color: '#666666',
  },
  checkboxRow: {
    width: '100%',
    height: '54px',
    paddingLeft: '6px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e0e0e0',
  },
  checkbox: {
    color: '#111111',
    marginRight: 15 - 7, // account for padding
  },
});

export default function NotificationsTab() {
  const classes = useStyles();
  const user = useSelector((s) => s.user);
  const [notificationPreferences, setNotificationPreferences] = useState({});
  const [checkboxesDisabled, setCheckboxesDisabled] = useState(false);

  useMemo(() => {
    if (user.id) {
      Api.getNotificationSubscriptions({ id: user.id }).then((res) => {
        setNotificationPreferences(res.data);
      });
    }
  }, [user.id]);

  const sections = useMemo(
    () => [
      {
        label: 'Survey Activity',
        options: [
          {
            label: 'Comment Notification',
            fieldName: 'survey_comments',
            checked: notificationPreferences.survey_comments,
          },
          {
            label: 'Favorite Notification',
            fieldName: 'survey_listing_favorites',
            checked: notificationPreferences.survey_listing_favorites,
          },
          {
            label: 'Decline Notification',
            fieldName: 'survey_listing_declines',
            checked: notificationPreferences.survey_listing_declines,
          },
        ],
      },
      {
        label: 'Product Updates',
        options: [
          {
            label: 'New Feature Announcements',
            fieldName: 'new_feature_announcements',
            checked: notificationPreferences.new_feature_announcements,
          },
          {
            label: 'Requests for Feedback',
            fieldName: 'feedback_requests',
            checked: notificationPreferences.feedback_requests,
          },
        ],
      },
    ],
    [notificationPreferences]
  );

  const toggleOption = (option) => {
    setCheckboxesDisabled(true);
    const newPreferences = {
      ...notificationPreferences,
      [option.fieldName]: !option.checked,
    };
    Api.updateNotificationSubscriptions({
      id: user.id,
      body: { ...newPreferences },
    }).then((res) => {
      setCheckboxesDisabled(false);
      setNotificationPreferences(res.data);
    });
  };

  if (isEmpty(notificationPreferences)) {
    return null;
  }

  return (
    <div className={classes.container}>
      {sections.map((section) => (
        <div key={section.label} className={classes.section}>
          <Typography className={classes.sectionLabel} variant="h5">
            {section.label}
          </Typography>
          {section.options.map((option) => (
            <div key={option.label} className={classes.checkboxRow}>
              <SquareCheckbox
                disabled={checkboxesDisabled}
                className={classes.checkbox}
                checked={option.checked}
                onClick={() => toggleOption(option)}
              />
              <Typography variant="h3">{option.label}</Typography>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
