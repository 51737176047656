export const API_ACTIONS = {
  CLEAN_IMAGE_UPLOAD_STATE: 'clean_image_upload_state',
  CLEAN_BLUEPRINT_UPLOAD_STATE: 'clean_blueprint_upload_state',
  REMOVE_IMAGE_FROM_UPLOAD_STATE: 'remove_image_upload_state',
  REMOVE_IMAGE_FROM_BLUEPRINT_STATE: 'remove_blueprint_upload_state',
  ADD_AMENITY: 'add_custom_amenity',
  CLEAN_SAVED_SEARCH_RAW_DATA: 'clean_saved_search_row_data',
  PROJECTS_REFRESH: 'projects_refresh',
};

export const actionCleanImageUpload = () => ({
  type: API_ACTIONS.CLEAN_IMAGE_UPLOAD_STATE,
});
export const actionCleanSavedSerchRawData = () => ({
  type: API_ACTIONS.CLEAN_SAVED_SEARCH_RAW_DATA,
});

export const actionRemoveFromImageUpload = payload => ({
  type: API_ACTIONS.REMOVE_IMAGE_FROM_UPLOAD_STATE,
  payload,
});

export const actionCleanBlueprintUpload = () => ({
  type: API_ACTIONS.CLEAN_BLUEPRINT_UPLOAD_STATE,
});

export const actionRemoveFromBlueprintUpload = payload => ({
  type: API_ACTIONS.REMOVE_IMAGE_FROM_BLUEPRINT_STATE,
  payload,
});

export const actionAddCustomAmenity = payload => ({
  type: API_ACTIONS.ADD_AMENITY,
  payload,
});

export const actionRefreshProjectReferences = payload => ({
  type: API_ACTIONS.PROJECTS_REFRESH,
  payload,
});
