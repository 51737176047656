import { useEffect, useReducer } from 'react'

const inisialState = {
  left: true,
  right: false,
  top: true,
  bottom: false,
}

const scroll = (_, element) => {
  const isDocument = element === document
  const target = isDocument ? element.documentElement : element

  const width = target.scrollWidth - 1
  const height = target.scrollHeight - 1

  const left = target.scrollLeft
  const top =
    isDocument && !target.scrollTop ? document.body.scrollTop : target.scrollTop

  return {
    left: left <= 0,
    right: left + target.clientWidth >= width,
    top: top <= 0,
    bottom: top + target.clientHeight >= height,
  }
}

export function useScroll(element) {
  const [state, dispatch] = useReducer(scroll, inisialState)

  useEffect(() => {
    if (element) {
      const update = () => dispatch(element)
      window.addEventListener('resize', update)
      element.addEventListener('scroll', update, {
        capture: false,
        passive: true,
      })
      update()
    }
  }, [dispatch, element])

  return state
}
