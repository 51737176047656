import { Ok, Err, BadData, BadStatus, BadRequest } from './types'

export async function getJson(path) {
  try {
    const response = await fetch(path, {
      headers: { 'Content-Type': 'application/json' },
    })

    if (!response.ok) {
      return Err(BadStatus(response.status))
    }

    try {
      const json = await response.json()
      return Err.is(json) || Ok.is(json) ? json : Ok(json)
    } catch {
      return Err(BadData())
    }
  } catch {
    return Err(BadRequest())
  }
}
