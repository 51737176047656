import React, { forwardRef } from 'react'
import { MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { DeselectedCheckboxIcon } from '~/legacy/components'
import { BASE_ICON_STYLES } from './ButtonUtils'

const useStyles = makeStyles(BASE_ICON_STYLES)

export const QuickMenuItem = forwardRef(
  ({ children, className, icon, label, ...props }, ref) => {
    const Icon = icon ?? DeselectedCheckboxIcon
    const classes = useStyles()
    return (
      <MenuItem
        ref={ref}
        className={clsx(classes.menuItemRoot, className)}
        {...props}
      >
        <Icon />
        <Typography className={classes.text}>
          {label}
          {children}
        </Typography>
      </MenuItem>
    )
  }
)
