import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  DefaultMenu,
  useMenuAnchor,
  MenuItem,
  Typography,
} from '~/legacy/components';
import { BASE_ICON_STYLES } from '~/legacy/components/buttons/ButtonUtils';
import { useAddExistingSpacesToBuildingMenuItem } from './AddExistingSpacesToBuildingMenuItem';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
  menuRoot: {
    width: '227px',
    marginTop: '4px',
  },
}));

export const useAddSpaceToBuildingMenu = ({
  addNewSpace = async () => {},
  copySpacesFromSurvey = async () => {},
}) => {
  const classes = useStyles();
  // Get the common menu anchor controls
  const { anchorMenuEl, setAnchorMenuEl, handleMenuClose } = useMenuAnchor();

  // Get the selected details from the anchor
  const selectedSurveyBuilding =
    anchorMenuEl && anchorMenuEl.surveyBuilding
      ? anchorMenuEl.surveyBuilding
      : null;
  const selectedSurveyId =
    anchorMenuEl && anchorMenuEl.surveyId ? anchorMenuEl.surveyId : null;

  const AddNewSpaceMenuItemComponent = (
    <MenuItem
      onClick={async () => {
        addNewSpace(selectedSurveyBuilding.id);
        handleMenuClose();
      }}
      classes={{ menuItemRoot: classes.menuItemRoot }}
    >
      <Typography className={classes.text}>Add New Space</Typography>
    </MenuItem>
  );

  // Get the Menu Item
  const {
    AddExistingSpacesToBuildingMenuItemComponent,
    AddExistingSpacesToBuildingModalComponent,
  } = useAddExistingSpacesToBuildingMenuItem({
    surveyBuilding: selectedSurveyBuilding,
    surveyId: selectedSurveyId,
    copySpacesFromSurvey,
    handleMenuClose,
  });

  // Get the Menu
  const AddSpaceToBuildingMenuComponent = (
    <DefaultMenu
      classes={{
        paper: classes.menuRoot,
      }}
      anchorMenuEl={anchorMenuEl}
      handleMenuClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {AddNewSpaceMenuItemComponent}
      {AddExistingSpacesToBuildingMenuItemComponent}
    </DefaultMenu>
  );

  return {
    setAnchorMenuEl,
    AddSpaceToBuildingMenuComponent,
    AddExistingSpacesToBuildingModalComponent,
  };
};
