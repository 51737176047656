import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import { Grid, Typography, Tooltip, Fade } from '@material-ui/core';
import Api from 'rest-fetcher-redux';
import lodash from 'lodash';
import { isUserTenantOrBrokerPreview, isTenant } from '~/legacy/utils';
import { TextInput } from '~/legacy/components';

const NOTES_SAVE_WAIT_TIME = 2000;

const useStyles = makeStyles({
  yourNotesInput: {
    marginTop: '12px',
  },
  flex: {
    display: 'inline-flex',
  },
  savedCheckMark: {
    marginLeft: '10px',
  },
  savedText: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '5px',
  },
  savedContainer: {
    display: 'inline-flex',
    marginLeft: '10px',
  },
});

// A survey listing note managed by tenants and viewable by brokers.
function SurveyListingNote({ surveyListingId, surveyListingNotesDefault }) {
  const classes = useStyles();
  const user = useSelector((s) => s.user);
  const isPreviewTenantView = useSelector(
    (state) => state.pages.previewTenantView.isPreviewTenantView
  );
  // Is the broker viewing this listing as a tenant? If so, we'll show the
  //   tenant view and effectively disable buttons/actions
  const isTenantOrBrokerPreview = isUserTenantOrBrokerPreview(
    user.userType,
    isPreviewTenantView
  );
  const [surveyListingNotes, setSurveyListingNotes] = useState(
    surveyListingNotesDefault
  );
  const [savedTime, setSavedTime] = useState(null);

  // Create a ref to the surveyListingNotes state - we need this because
  //   the notes input is a controlled component, and we need this state
  //   to be there as well as in the delayed save. A direct reference to the state
  //   in the delayed save would be stale
  const notesRef = useRef(surveyListingNotes);
  useEffect(() => {
    notesRef.current = surveyListingNotes;
  }, [surveyListingNotes]);

  // Save the survey notes if the user is a tenant
  const saveSurveyNotes = () => {
    if (isTenant(user) && !isPreviewTenantView) {
      Api.updateSurveyNotes({
        id: surveyListingId,
        body: { notes: notesRef.current },
      }).then(() => {
        setSavedTime(new Date().toLocaleTimeString());
      });
    }
  };
  // A callback to save save the survey notes after a timeout of inactivity on the notes text area
  const delayedSurveyNotesSave = useCallback(
    lodash.debounce(() => saveSurveyNotes(), NOTES_SAVE_WAIT_TIME),
    []
  );

  const intialValue = isPreviewTenantView
    ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    : surveyListingNotes;

  // When the survey notes text area is updated
  const onSurveyNotesChange = (event) => {
    setSurveyListingNotes(event.target.value);
    delayedSurveyNotesSave();
  };

  return (
    <Grid item xs={12}>
      <div>
        <div className={classes.flex}>
          <Tooltip
            title="take notes to share with your broker"
            placement="top-start"
            className={classes.title}
          >
            <Typography variant="h2">Your Notes</Typography>
          </Tooltip>
          <Fade in={!!savedTime} timeout={800}>
            <div className={classes.savedContainer}>
              <CloudDoneIcon className={classes.savedCheckMark} />
              <Typography className={classes.savedText} variant="body1">
                {` saved at ${savedTime}`}
              </Typography>
            </div>
          </Fade>
        </div>
        <TextInput
          className={classes.yourNotesInput}
          multiline
          rows={5}
          rowsMax={20}
          placeholder="take notes to share with your broker"
          fullWidth
          onChange={(event) => onSurveyNotesChange(event)}
          value={intialValue}
          disabled={!isTenantOrBrokerPreview}
        />
      </div>
    </Grid>
  );
}
SurveyListingNote.propTypes = {
  surveyListingId: PropTypes.number.isRequired,
  surveyListingNotesDefault: PropTypes.string,
};
SurveyListingNote.defaultProps = {
  surveyListingNotesDefault: '',
};

export default SurveyListingNote;
