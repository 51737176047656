import { GoogleMap } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'
import { BuildingMapMarker } from './index'
import { roadMapStyles, satelliteMapStyles } from './Map/GoogleMaps/style'

export default function SurveyBuildingsMap({
  isUserInBuildout = false,
  surveyListings,
  surveyBuildings,
  hoveredBuildingId,
  surveyId,
  activeMapMarker,
  setActiveMapMarker = () => {},
  primarySurveyBuildingId,
  navigateToListing = false,
  children,
}) {
  const [map, setMap] = useState(false)
  const [mapStyles, setMapStyles] = useState(roadMapStyles)
  const [zIndexes, setZIndexes] = useState({})
  // Keep track of the building coordinates so that we can tell if any coordinates have been updated
  const [buildingsCoordinates, setBuildingsCoordinates] = useState({})

  // Check if any building coordinates have changed, rather than any building detail, to prevent the entire map / map drawer from
  // resetting and rerendering any time an irrelevant listing detail changes (ie: survey listing status)
  useEffect(() => {
    const newBuildingsCoordinates = {}
    let dataChanged = false
    const thereAreBuildings = surveyBuildings && surveyBuildings.length
    if (surveyBuildings) {
      surveyBuildings.forEach((surveyBuilding) => {
        const { building } = surveyBuilding
        const currentBuildingCoordinate = buildingsCoordinates[building.id]
        const newBuildingCoordinates = {
          latitude: building.geo.latitude,
          longitude: building.geo.longitude,
        }
        if (
          !dataChanged &&
          (!currentBuildingCoordinate ||
            newBuildingCoordinates.latitude !==
              currentBuildingCoordinate.latitude ||
            newBuildingCoordinates.longitude !==
              currentBuildingCoordinate.longitude)
        ) {
          dataChanged = true
        }
        newBuildingsCoordinates[building.id] = newBuildingCoordinates
      })
    }
    if (!thereAreBuildings || dataChanged) {
      setBuildingsCoordinates(newBuildingsCoordinates)
    }
  }, [surveyBuildings])

  // Only reset the bounds if building coordinates have changed
  useEffect(() => {
    if (map) {
      const bounds = new google.maps.LatLngBounds()
      if (
        buildingsCoordinates &&
        Object.keys(buildingsCoordinates).length > 0
      ) {
        Object.values(buildingsCoordinates)
          .filter(
            (buildingCoordinates) =>
              buildingCoordinates.latitude && buildingCoordinates.longitude
          )
          .forEach((buildingCoordinates) => {
            bounds.extend(
              new google.maps.LatLng(
                buildingCoordinates.latitude,
                buildingCoordinates.longitude
              )
            )
          })
      } else {
        // If there are no listings and pins to show, then show the US by fitting bounds of corners of bounding box
        bounds.extend(new google.maps.LatLng(24.9493, -66.9326))
        bounds.extend(new google.maps.LatLng(49.5904, -125.0011))
      }
      map.fitBounds(bounds)
    }
  }, [buildingsCoordinates, map])

  const clearActiveMapMarker = () => {
    setActiveMapMarker(null)
  }

  return (
    <GoogleMap
      onDragEnd={() => {
        // Go through the active map marker, and if its no longer visible or within 150 bx buffer, close it
        if (activeMapMarker && surveyBuildings && surveyBuildings.length > 0) {
          const mapBounds = map.getBounds()
          const markerBuilding = surveyBuildings.find(
            (surveyBuilding) =>
              surveyBuilding.building.id === activeMapMarker.buildingId
          ).building
          if (markerBuilding) {
            // Get the size of a pixel in LatLng speak
            const pixelSize = 2 ** -map.getZoom()
            // Get the LatLng distance of the border
            const border = pixelSize * 150

            // Calculate a bounding border box for the map marker
            const newNorthEast = new google.maps.LatLng(
              markerBuilding.geo.latitude + border,
              markerBuilding.geo.longitude + border
            )
            const newSouthWest = new google.maps.LatLng(
              markerBuilding.geo.latitude - border,
              markerBuilding.geo.longitude - border
            )
            const markerBoundsWithBorder = new google.maps.LatLngBounds(
              newSouthWest,
              newNorthEast
            )

            // Now check if the map bounds intersect with the marker bounds, effectively checking if the map marker
            //   is within a threshold of the map's edge. If the marker is out of bounds, hide it
            if (!mapBounds.intersects(markerBoundsWithBorder)) {
              setActiveMapMarker(null)
            }
          }
        }
      }}
      mapContainerStyle={{
        height: '100%',
        width: '100%',
      }}
      onLoad={setMap}
      onMapTypeIdChanged={() => {
        if (map) {
          if (map.mapTypeId === 'roadmap') {
            setMapStyles(roadMapStyles)
          } else {
            setMapStyles(satelliteMapStyles)
          }
        }
      }}
      options={{
        fullscreenControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT,
        },
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        styles: mapStyles,
      }}
      onClick={clearActiveMapMarker}
      onZoomChanged={clearActiveMapMarker}
    >
      {surveyBuildings &&
        surveyBuildings.length > 0 &&
        surveyBuildings.map((surveyBuilding) => {
          const { building } = surveyBuilding
          if (!(building.geo.latitude, building.geo.longitude)) {
            return null
          }

          const isPrimarySurveyBuilding =
            primarySurveyBuildingId === surveyBuilding.id

          const isActiveMarker = !!(
            activeMapMarker && building.id === activeMapMarker.buildingId
          )

          let listing = null
          if (navigateToListing && !isUserInBuildout) {
            const foundSurveyListing = surveyListings.find(
              (sl) => sl.listing.building.id === building.id
            )
            if (foundSurveyListing) {
              ;({ listing } = foundSurveyListing)
            }
          }

          return (
            <BuildingMapMarker
              map={map}
              key={building.id}
              listing={isUserInBuildout ? null : listing}
              building={building}
              spacesAvailable={surveyBuilding.spaces_available}
              isHovered={building.id === hoveredBuildingId}
              isActive={isActiveMarker}
              zIndexes={zIndexes}
              setZIndexes={setZIndexes}
              surveyId={surveyId}
              surveyBuildingId={surveyBuilding.id}
              setActiveMapMarker={setActiveMapMarker}
              isPrimarySurveyBuilding={isPrimarySurveyBuilding}
              isHighlightedOverride={
                primarySurveyBuildingId ? !isPrimarySurveyBuilding : null
              }
              windowAboveMarker={
                !!(isActiveMarker && activeMapMarker.showWindowAboveMarker)
              }
              isUserInBuildout={isUserInBuildout}
            />
          )
        })}
      {children}
    </GoogleMap>
  )
}
