import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

import {
  BASE_ICON_STYLES,
  ConfirmModalComponent,
  DeleteIcon,
  MenuItem,
  Typography,
} from '~/legacy/components'
import { SnackbarUtils } from '~/legacy/utils'

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
  textInputFieldContainer: {
    width: '100%',
    marginTop: '10px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  commentMenuItem: {
    width: '186px',
  },
  optionTextContainer: {
    marginBottom: '64px',
  },
}))

// A modal to delete a comment
export const DeleteCommentMenuItemModal = ({
  deleteComment,
  open,
  onClose,
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => {
    onClose()
  }

  // Update the comment
  const deleteCommentApi = async () => {
    setIsLoading(true)

    try {
      await deleteComment(closeModal)
      SnackbarUtils.success('Comment Successfully Deleted.')
    } catch {
      // supress
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ConfirmModalComponent
      classes={{ optionTextContainer: classes.optionTextContainer }}
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onConfirm={deleteCommentApi}
      title="Delete Comment"
      confirmButtonLabel="Delete"
      disableAction={isLoading}
      loading={isLoading}
      text="Are you sure you want to delete this comment? This cannot be undone."
    />
  )
}

// Menu item to edit a comment
export const DeleteCommentMenuItem = React.forwardRef(
  ({ openModal, ...props }, ref) => {
    const classes = useStyles()

    return [
      <MenuItem
        key="delete-comment-menu-item"
        ref={ref}
        classes={{ menuItemRoot: classes.commentMenuItem }}
        onClick={openModal}
        {...props}
      >
        <DeleteIcon className={classes.icon} />
        <Typography variant="menuItemText" className={classes.text}>
          Delete
        </Typography>
      </MenuItem>,
    ]
  }
)

// Bundle the modal and the menu item together for maximum convenience
export const useDeleteCommentMenuItem = ({
  deleteComment,
  handleMenuClose,
}) => {
  const [open, setOpen] = React.useState(false)

  const DeleteCommentMenuItemComponent = (
    <DeleteCommentMenuItem openModal={() => setOpen(true)} />
  )

  const DeleteCommentMenuItemModalComponent = (
    <DeleteCommentMenuItemModal
      key="delete-comment-modal"
      deleteComment={deleteComment}
      open={open}
      onClose={() => {
        setOpen(false)
        handleMenuClose()
      }}
    />
  )

  return {
    DeleteCommentMenuItemComponent,
    DeleteCommentMenuItemModalComponent,
  }
}
