import { useRef } from 'react'
import { useMessage } from '~/libraries/messaging.react'

export function useOverlayReactor() {
  const overlays = useRef(new Set())

  useMessage('OverlayWasShown', ({ id }) => {
    overlays.current.add(id)
    update()
  })

  useMessage('OverlayWasHidden', ({ id }) => {
    overlays.current.delete(id)
    update()
  })

  function update() {
    // const intercom = window.Intercom
    // if (intercom) {
    //   intercom('update', {
    //     hide_default_launcher: overlays.current.size > 0,
    //   })
    // }
  }
}
