import React from 'react'
import { intersection } from 'lodash'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { CresaDivider } from '../patterns/CresaDivider'
import { CresaItenerary } from './CresaItenerary'
import { CresaMap } from './CresaMap'
import { CresaSummary } from './CresaSummary'
import { CresaProperty } from './CresaProperty'
import { CresaFloorplan } from './CresaFloorplan'

const templates = [
  CresaItenerary,
  CresaMap,
  CresaSummary,
  CresaProperty,
  CresaFloorplan,
]

export const CresaToC = definePageTemplate({
  id: 'dae7502d-10d9-49e1-ba9d-4638d76bf0be',

  name: 'Table of Contents',

  Page: (props) => {
    const pages = props.pages.map((page) => page.template)
    const sections = intersection(templates, pages)

    return (
      <CresaDivider
        title="Table of Contents"
        items={sections.map((template) => template.name)}
      />
    )
  },
})
