import React, { useEffect } from 'react';
import Api from 'rest-fetcher-redux';
import { Fade, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CollapseWrapper,
  SurveyListingStatusUpdateActivityItem,
  ShareSurveyActivityItem,
  SurveyListingCommentActivityItem,
  ListingActivityCommentInput,
  ActivityContext,
} from '~/legacy/components';
import { useListingActivityWebSocket } from '~/legacy/utils/webSocketUtils';
import {
  BROKER_SHARE_SURVEY,
  SURVEY_STATUS_UPDATE,
  SURVEY_LISTING_COMMENT,
} from '~/legacy/consts';

const useStyles = makeStyles({
  activityContainer: {
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
  },
  activitiesContainer: {
    paddingTop: '20px',
    overflowY: 'hidden',
  },
});

function ListingActivityFeed({ listing, surveyId, setHasMoreActivity }) {
  const classes = useStyles();
  const context = React.useContext(ActivityContext);
  const {
    currentDate,
    activities,
    setActivities,
    setNewActivityMessage,
    isLoadingFeed,
    setIsLoadingFeed,
    show,
  } = context;

  // The websocket for our activity feed
  const { lastJsonMessage } = useListingActivityWebSocket({
    listingId: listing.id,
    surveyId,
  });

  useEffect(() => {
    if (listing.id) {
      // Get the activity feed items from the server
      const params = { id: listing.id };
      if (surveyId) {
        params.GET = { survey_id: surveyId };
      }
      Api.getListingActivity(params)
        .then((results) => {
          if (results) {
            setActivities(
              results.map((result) => ({ ...result, isNew: false }))
            );
          }
        })
        .finally(() => setIsLoadingFeed(false));
    }
  }, [listing.id, surveyId]);

  useEffect(() => {
    // Update the activities with the new activity from the websocket
    setNewActivityMessage(lastJsonMessage);
  }, [lastJsonMessage]);

  // Enure the activity feed right hand column matches the left listing details column height
  const activitiesContainerRef = React.createRef();

  useEffect(() => {
    // Set whether or not the activities in the activity container exceed their container
    if (activitiesContainerRef && activitiesContainerRef.current) {
      setHasMoreActivity(
        activitiesContainerRef.current.scrollHeight >
          activitiesContainerRef.current.clientHeight
      );
    }
  }, [activitiesContainerRef]);

  if (isLoadingFeed) {
    return <LinearProgress size={40} />;
  }

  return (
    <Fade in={show}>
      <div className={classes.activityContainer}>
        {!!surveyId && (
          <ListingActivityCommentInput
            listingId={listing.id}
            surveyId={surveyId}
            setNewActivityMessage={setNewActivityMessage}
          />
        )}
        <div
          className={classes.activitiesContainer}
          ref={activitiesContainerRef}
        >
          {activities.map((activity) => (
            <CollapseWrapper
              includeCollapse={activity.isNew}
              key={`wrapper-${activity.uuid}`}
            >
              <div style={{ paddingBottom: '25px' }}>
                {activity.type === BROKER_SHARE_SURVEY && (
                  <ShareSurveyActivityItem
                    activity={activity}
                    currentDate={currentDate}
                  />
                )}
                {activity.type === SURVEY_STATUS_UPDATE && (
                  <SurveyListingStatusUpdateActivityItem
                    activity={activity}
                    listing={listing}
                    currentDate={currentDate}
                    surveyId={surveyId}
                  />
                )}
                {activity.type === SURVEY_LISTING_COMMENT && (
                  <SurveyListingCommentActivityItem
                    activity={activity}
                    currentDate={currentDate}
                    setNewActivityMessage={setNewActivityMessage}
                  />
                )}
              </div>
            </CollapseWrapper>
          ))}
        </div>
      </div>
    </Fade>
  );
}

export default ListingActivityFeed;
