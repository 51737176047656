/* eslint-disable no-nested-ternary */
import { chunk } from 'lodash'
import { createElement, Fragment, useMemo } from 'react'

export const useTable = (table, data) => {
  return useMemo(() => {
    const header = table.columns.map((column, index) => {
      return createElement(
        Fragment,
        { key: `header-${column.id}` },
        column.header
          ? column.header(column, index)
          : table.header
          ? table.header(column, index)
          : column.name ?? column.id
      )
    })

    const page = (index, count = 20) => {
      const start = index * count * table.columns.length
      const end = start + count * table.columns.length
      return rows.slice(start, end)
    }

    const pages = (count = 20) => {
      const pages = chunk(rows, count * table.columns.length)
      return pages.map((rows, index) => ({
        id: `page-${index}`,
        rows,
        header,
      }))
    }

    const rows = data.flatMap((row, index) => {
      return table.columns.map((column) => {
        return createElement(
          Fragment,
          { key: `row-${row.id}-${column.id}` },
          column.row
            ? column.row(row, column, index)
            : table.row
            ? table.row(row, column, index)
            : data[column.key]
        )
      })
    })

    const style = {
      display: 'grid',
      gridTemplateColumns: table.columns
        .map((column) => column.size ?? '1fr')
        .join(' '),
    }

    return { header, page, pages, rows, style }
  }, [table, data])
}
