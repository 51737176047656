import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmModalTitle } from '~/legacy/components/modals';
import ModalComponent from '../ModalComponent';

const contentModalStyles = {
  content: {
    backgroundColor: 'white',
    outline: 'none',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
};

/**
 * A modal with a title and content. Provide your own content
 */
function ContentModal({
  onClose,
  onAfterClose = () => {},
  title,
  children,
  classes,
  ModalComponentProps = {},
  ConfirmModalTitleProps = {},
}) {
  return (
    <ModalComponent onAfterClose={onAfterClose} {...ModalComponentProps}>
      <div className={classes.content} tabIndex={-1}>
        <ConfirmModalTitle
          onClose={onClose}
          title={title}
          {...ConfirmModalTitleProps}
        />
        {children}
      </div>
    </ModalComponent>
  );
}

export default withStyles(contentModalStyles)(ContentModal);
