import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { SquareIconButton, NotInterestedIcon } from '~/legacy/components';

export const NotInterestedBuildingButton = withStyles({
  iconButton: {
    '&:hover': {
      backgroundColor: '#f3f3f3',
    },
    notInterested: {
      color: '#999',
    },
  },
})(
  ({
    classes,
    isNotInterested = false,
    isFavorited = false,
    onClick,
    ...props
  }) => {
    return (
      <SquareIconButton
        className={classes.iconButton}
        showAsDisabled={isFavorited}
        key="not-interested-icon"
        tooltipText={
          isNotInterested ? 'Remove Not Interested' : 'Not Interested'
        }
        onClick={onClick}
        {...props}
      >
        <NotInterestedIcon
          className={isNotInterested ? classes.notInterested : ''}
        />
      </SquareIconButton>
    );
  }
);
