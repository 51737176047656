import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '~/legacy/components';
import ModalTitle from './ModalTitle';

const useStyles = makeStyles({
  modalContent: {
    background: 'white',
    outline: 'none',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
    height: '250px',
  },
  buttons: {
    margin: 'auto 0 16px auto',
  },
  cancelButton: {
    marginRight: '12px',
  },
});

function ConfirmDeleteSurveyModal({ onClose }) {
  const classes = useStyles();

  return (
    <div className={classes.modalContent}>
      <ModalTitle onClose={() => onClose(false)} title="Are you sure?" />
      <Typography variant="body1">
        {`
          Are you sure you want to delete this survey?
          It cannot be undone and will no longer be accessible.
          No listing information will be deleted.
        `}
      </Typography>
      <div className={classes.buttons}>
        <Button
          className={classes.cancelButton}
          color="secondary"
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button color="primary" onClick={() => onClose(true)}>
          Delete
        </Button>
      </div>
    </div>
  );
}

export default ConfirmDeleteSurveyModal;
