import React from 'react'
import { useHistory } from 'react-router-dom'
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { Typography, TextLink } from '~/legacy/components'

export const Breadcrumbs = withStyles({
  separator: {
    marginTop: '2px',
  },
})(({ classes, pieces }) => {
  const history = useHistory()
  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      separator={
        <NavigateNextIcon className={classes.separator} fontSize="small" />
      }
    >
      {pieces.map((piece, index) => {
        // Make the last breadcrumb not a clickable link
        return index === pieces.length - 1 || !piece.path ? (
          <Typography color="textPrimary" key={piece.name} variant="h3">
            {piece.name || ''}
          </Typography>
        ) : (
          <TextLink
            color="inherit"
            key={piece.name}
            onClick={() => {
              if (piece.replace) location.pathname = piece.path
              else history.push(piece.path)
            }}
            variant="h3"
          >
            {piece.name || ''}
          </TextLink>
        )
      })}
    </MuiBreadcrumbs>
  )
})
