export default {
  redeemMagicLinkToken: {
    url: '/magiclink/validate/',
    options: {
      method: 'GET',
    },
  },
  getMagicLinkTokenByToken: {
    url: '/magic_link/by_token/',
    options: {
      method: 'GET',
    },
  },
  sendMagicLinkEmail: {
    url: '/magiclink/send/',
    options: {
      method: 'POST',
    },
  },
};
