import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Collapse } from '@material-ui/core';
import { previewTenantViewActions } from '~/legacy/store';
import { LEASE_SURVEY_VIEW_LISTING } from '~/legacy/consts';
import { Button } from '~/legacy/components';
import { isBrokerUserType } from '~/legacy/utils';

const useStyles = makeStyles({
  stickyNavBarTail: {
    background: 'rgba(0, 0, 0, 0.6)',
  },
  stickyNavBarTailButton: {
    background: '#F9F9F9',
    borderRadius: '4px',
    color: '#3B4144',
    marginLeft: '6px',
  },
  grow: {
    flexGrow: 1,
  },
});

// TODO: Better way to set which page we are on without having to calculate it backwards
function isPathListingPage(path) {
  const listingPagePattern = LEASE_SURVEY_VIEW_LISTING.replace(
    /:survey_id/,
    '[0-9]*'
  ).replace(/:listing_id/, '[0-9]*');
  const found = path.pathname.match(listingPagePattern);
  return found && found.length > 0;
}

/**
 * An opaque tail below the top nav
 * TODO: This is currently only leveraged on the listings page, and thus contains listing page state. Probably
 *   a better place to put this downstream closer to the listings page versus the nav bar itself.
 */
function TopNavTail({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isPreviewTenantView = useSelector(
    (state) => state.pages.previewTenantView.isPreviewTenantView
  );
  const userType = useSelector((s) => s.user.userType);

  // Control showing/hiding the listing page nav bar dropdown only when on the listings page
  useEffect(() => {
    if (!isPathListingPage(location)) {
      dispatch(previewTenantViewActions.setPreviewTenantView(false));
    }
  }, [location, dispatch]);

  return (
    <Collapse in={isBrokerUserType(userType) && isPreviewTenantView}>
      <AppBar position="sticky" className={classes.stickyNavBarTail}>
        <Toolbar>
          <Typography>
            This is what the listing looks like to your client
          </Typography>
          <div className={classes.grow} />
          <Button
            color="inherit"
            className={classes.stickyNavBarTailButton}
            onClick={() => {
              dispatch(previewTenantViewActions.setPreviewTenantView(false));
            }}
          >
            Exit Preview
          </Button>
        </Toolbar>
      </AppBar>
    </Collapse>
  );
}

export default TopNavTail;
