import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Typography } from '~/legacy/components';

export const TextChip = withStyles({
  root: {},
  deleteIcon: {},
})(({ label, classes, ...props }) => {
  return (
    <MuiChip
      classes={classes}
      label={<Typography variant="chip">{label}</Typography>}
      {...props}
    />
  );
});

export const IconChip = withStyles({
  root: {},
  label: {},
})(({ icon, classes, ...props }) => {
  return <MuiChip classes={classes} label={icon} {...props} />;
});
