import React from 'react'
import { format } from 'date-fns'
import { styled } from '~/libraries/styled'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { conjunction } from '~/support/conjunction'
import { mediaUrl } from '~/support/mediaUrl'
import { Settings } from '~/pdfs/patterns/Settings'
import { Group } from '~/pdfs/patterns/Group'
import { TextSetting } from '~/pdfs/patterns/TextSetting'
import { Font } from '../patterns/Font'
import CalgaryLogo from '../patterns/CalgaryLogo'

export const CalgaryCover = definePageTemplate({
  id: '11266f3f-83e5-4fd8-9c9e-f04dd57a92b1',

  name: 'Cover',

  props: (pdf, areas) => ({
    name: pdf.settings.name,
    title: pdf.data.name,
    subtitle: conjunction(
      areas.map((area) => area.name),
      'and'
    ),
    date: pdf.settings.date,
  }),

  Page: (props) => (
    <Page>
      <Content>
        <Font format="78/94/300">{props.name}</Font>
        <Divider />
        <Font format="78/94/300" color="#E30613">
          {props.title}
        </Font>
        <Font format="43/91/300" color="#E30613" grow>
          {props.subtitle}
        </Font>
        <Font format="56/94/700" color="#626468">
          {props.date || format(new Date(), 'MMMM uuuu')}
        </Font>
        <Font format="40/91/300" color="#626468">
          Prepared by JLL Industrial
        </Font>
        <Logo />
      </Content>
    </Page>
  ),

  Settings: (props) => (
    <Settings>
      <Group label="Title">
        <TextSetting
          id="name"
          value={props.name}
          placeholder="Add Title"
          nullable
        />
      </Group>
      <Group label="Date">
        <TextSetting
          id="date"
          value={props.date}
          placeholder="Add Date"
          nullable
        />
      </Group>
    </Settings>
  ),
})

const Page = styled('div', {
  backgroundImage: `url(${mediaUrl('/pdf/Calgary.jpg')})`,
  backgroundSize: 'cover',
  position: 'absolute',
  inset: '0px',
  overflow: 'hidden',
})

const Content = styled('div', {
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  padding: '85px 98px 51px 92px',
  position: 'absolute',
  bottom: '275px',
  right: '258px',
  left: '792px',
  top: '275px',
})

const Divider = styled('div', {
  backgroundColor: '#000000',
  marginTop: '71px',
  marginBottom: '40px',
  width: '227px',
  height: '13px',
})

const Logo = styled(CalgaryLogo, {
  position: 'absolute',
  height: '116px',
  bottom: '95px',
  right: '92px',
})
