export const clamp = (value, min, max) => {
  return Math.max(min, Math.min(max, value))
}

export const addVector2 = (a, b) => {
  return createVector2(a.x + b.x, a.y + b.y)
}

export const createVector2 = (x = 1, y = 1) => {
  return { x, y }
}

export const flipVector2 = (size) => {
  return createVector2(size.y, size.x)
}

export const padVector2 = (size, padding = 0) => {
  return createVector2(size.x + padding * 2, size.y + padding * 2)
}

export const scaleVector2 = (size, scale = 1) => {
  return createVector2(size.x * scale, size.y * scale)
}

export const scaleToFit = (outer, inner) => {
  return Math.min(outer.y / inner.y, outer.x / inner.x)
}
