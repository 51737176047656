import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Api from 'rest-fetcher-redux';

import { Loading } from '~/legacy/components';
import { LEASE_LOGIN } from '~/legacy/consts';

export default function Zendesk() {
  const user = useSelector((s) => s.user);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const zendeskLink = queryParams.get('return_to');

    if (user.loggedIn) {
      Api.redirectToZendeskUrl({
        GET: {
          zendesk_link: zendeskLink,
        },
      }).then((res) => {
        window.location = res.zendeskUrl;
      });
    } else {
      // bounce to login with a special return to param
      window.location.replace(`${LEASE_LOGIN}?zendesk_redirect=${zendeskLink}`);
    }
  }, []);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loading isLoading size={40} />
    </div>
  );
}
