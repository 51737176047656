import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { differenceInCalendarDays, format } from 'date-fns';
import { BULK_IMPORT_HELPERS } from '~/legacy/utils';
import { InlineEditableValue } from '~/legacy/components';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '42px',
  },
  label: {
    marginRight: '4px',
  },
  value: {
    cursor: (props) => (props.isTenantOrPreview ? '' : 'pointer'),
    flex: 1,
    paddingLeft: '5px',
  },
  valueInput: {
    flex: 1,
    marginTop: '1px',
    marginLeft: '5px',
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '5px 10px',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '5px 10px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0',
    },
  },
  valueHovered: {
    backgroundColor: '#f9f9f9',
  },
});

export default function EditableAttribute({
  displayValue,
  isTenantOrPreview,
  label,
  options,
  placeholder,
  selectValue,
  type,
  fieldDataType,
  updateRootObject,
  value,
  value_key,
}) {
  const classes = useStyles({ isTenantOrPreview });
  const additionalProps = {};

  // If this is a date, format the display value to be "immediately" if its in the past
  if (type === 'date') {
    additionalProps.formatDisplayValue = (inputValue) => {
      if (inputValue) {
        const days = differenceInCalendarDays(inputValue, new Date());
        if (days >= 1) {
          return format(inputValue, 'MM-dd-yyyy');
        }
      }
      return 'Immediately';
    };
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.label} variant="h3">
        {`${label}:`}
      </Typography>
      {isTenantOrPreview ? (
        <Typography variant="body1">{displayValue || value}</Typography>
      ) : (
        <InlineEditableValue
          classesIn={{
            value: classes.value,
            valueHovered: classes.valueHovered,
            valueInput: classes.valueInput,
          }}
          onClick={() => {}}
          options={options}
          placeholder={placeholder}
          selectValue={selectValue}
          type={type}
          fieldDataTypeId={fieldDataType.id}
          updateValueApi={(newerValue) => {
            return updateRootObject({
              [value_key]: newerValue || null,
            });
          }}
          value={
            type === 'date'
              ? BULK_IMPORT_HELPERS.prepareDate(value, 'MM-dd-yyyy')
              : value
          }
          {...additionalProps}
        />
      )}
    </div>
  );
}
