/* eslint-disable react/jsx-one-expression-per-line */
import { Collapse, lighten, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CloseIcon, TextLink, Typography } from '~/legacy/components'
import { surveyApi } from '~/legacy/fetchApi'
import { updateSurvey } from '~/legacy/store/actions/viewSurvey'
import {
  isBroker,
  SnackbarUtils,
  useBrokerInBdpBuildout,
  useSurveyInBdpBuildout,
} from '~/legacy/utils'
import { openLink } from '../utils/openLink'
import { ConfirmModalComponent } from './modalComponents'
import { Link } from './themeComponents'

export const FtuxSdpBanner = withStyles((theme) => ({
  root: {
    position: 'sticky',
    width: '100%',
    top: '0px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    zIndex: 100,
  },
  contentRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 32px 0 32px',
    height: '60px',
  },
  collapseWrapperInner: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  previewText: {
    lineHeight: '24px',
  },
  learnMore: {
    display: 'flex',
    alignItems: 'center',
  },
  viewSupport: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    marginRight: '12px',
  },
  closeIcon: {
    cursor: 'pointer',
  },
}))(({ classes, survey }) => {
  const dispatch = useDispatch()
  const user = useSelector((s) => s.user)
  const isBrokerInBdpBuildout = useBrokerInBdpBuildout()
  const isSurveyUpgradedToBuildout = useSurveyInBdpBuildout(survey)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)

  const onUpdateSurvey = () => {
    setLoading(true)
    const partial = { opted_in_to_bdp_buildout: true }
    dispatch(updateSurvey(partial))
    surveyApi
      .updatePartial({ surveyId: survey.id, partial })
      .then(() => {
        SnackbarUtils.success('Survey successfully updated!')
      })
      .finally(() => {
        setModalOpen(false)
        setLoading(false)
      })
  }

  const closeModal = () => setModalOpen(false)

  return (
    <div className={classes.root}>
      <Collapse
        in={
          open &&
          user &&
          isBroker(user) &&
          isBrokerInBdpBuildout &&
          !isSurveyUpgradedToBuildout
        }
        classes={{ wrapperInner: classes.collapseWrapperInner }}
      >
        <div className={classes.contentRoot}>
          <div className={classes.learnMore}>
            <Typography className={classes.previewText} variant="bodyBold">
              This is a legacy survey. To see building pages, update to the new
              version.
            </Typography>
          </div>
          <div className={classes.viewSupport}>
            <TextLink onClick={() => setModalOpen(true)}>Update</TextLink>
          </div>
          <CloseIcon
            onClick={() => setOpen(false)}
            className={classes.closeIcon}
          />
        </div>
      </Collapse>
      <ConfirmModalComponent
        ModalComponentProps={{
          open: !!modalOpen,
          onClose: closeModal,
        }}
        onClose={closeModal}
        onConfirm={onUpdateSurvey}
        title="Update"
        text={
          <Typography component="span">
            Are you sure you want to update the legacy survey {survey.name} to
            the new version? Listing pages will be converted to building pages.
            None of your data will be deleted, but may need to be reformatted.
            <Typography component="span" variant="link">
              <Link
                onClick={openLink(
                  'https://leaseuphelp.zendesk.com/hc/en-us/articles/6008915206299-Convert-Listings-to-Buildings'
                )}
              >
                Learn more
              </Link>
            </Typography>
          </Typography>
        }
        confirmButtonLabel="Update"
        loading={loading}
      />
    </div>
  )
})
