import React, { useReducer, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Api from 'rest-fetcher-redux';
import { CircularProgress, Container, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ViewListingBody, SurveyBreadcrumb } from '~/legacy/components';
import { MOCK_SURVEY_LISTING_ID } from '~/legacy/consts';
import ViewListingHeadline from './ViewListingHeadline';
import SurveyListingPaginator from './SurveyListingPaginator';
import {
  isUserTenantOrBrokerPreview,
  isTenantUserType,
  isBrokerUserType,
} from '../../../utils/userHelpers';

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    paddingBottom: '24px',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: '16px',
    },
  },
  rootListing: {
    overflow: 'hidden',
    paddingTop: '24px',
    [theme.breakpoints.only('xs')]: {
      paddingTop: '16px',
    },
  },
  root: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  invisible: {
    minHeight: '0px',
  },
}));

function ViewListing({ match }) {
  const classes = useStyles();
  const isAnonymous = useSelector((s) => s.user.isAnonymous);
  const userType = useSelector((s) => s.user.userType);
  const requestedId = match && match.params && match.params.listing_id;
  const requestedSurveyId = match && match.params && match.params.survey_id;
  const isPreviewTenantView = useSelector(
    (state) => state.pages.previewTenantView.isPreviewTenantView
  );
  const [requestedSurvey, setRequestedSurvey] = useState(null);
  // Is the broker viewing this listing as a tenant? If so, we'll show the
  //   tenant view and effectively disable buttons/actions
  const isTenantOrBrokerPreview = isUserTenantOrBrokerPreview(
    userType,
    isPreviewTenantView
  );
  // Need to use a reducer here as the state updates are happening in an async API call, and need to
  //   happen together atomically.
  const [state, setState] = useReducer(
    (dataState, newDataState) => ({ ...dataState, ...newDataState }),
    { listing: null, surveyListing: null, surveyListings: null }
  );
  // Whether or not we have started to fetch the listing/survey listing data for the page. Prevents us from
  //   making multiple requests before one finishes.
  const [dataFetched, setDataFetched] = useState(false);
  const [siblingListings, setSiblingListings] = useState([]);

  const fetchSiblingListings = (listing) => {
    const params = {
      building_id: listing.building.id,
      exclude_id: listing.id,
    };
    if (requestedSurveyId) {
      params.survey_id = requestedSurveyId;
    }

    Api.getBuildingListings(params).then((building_listings) => {
      setSiblingListings(building_listings);
    });
  };

  // if we are a tenant and we don't have a listing or a survey listing, need to load them.
  if (
    (isAnonymous || (userType && isTenantUserType(userType))) &&
    !dataFetched &&
    (!state.listing ||
      !state.listing.id ||
      state.listing.id.toString() !== requestedId ||
      !state.surveyListing)
  ) {
    setDataFetched(true);
    Api.getSurveyListing({
      id: requestedId,
      GET: { survey_id: requestedSurveyId },
    }).then((result) => {
      if (result) {
        setState({ listing: result.listing, surveyListing: result });
        fetchSiblingListings(result.listing);
      }
    });
  }

  // If we are a broker and we don't have a listing or (don't have CL and haven't tried to load it), need to load them.
  if (
    userType &&
    isBrokerUserType(userType) &&
    !dataFetched &&
    (!state.listing ||
      !state.listing.id ||
      state.listing.id.toString() !== requestedId ||
      !state.surveyListing)
  ) {
    setDataFetched(true);
    Api.getListing({
      id: requestedId,
      include_survey_listings: true,
    }).then((result) => {
      if (result) {
        setState({ listing: result, surveyListings: result.survey_listings });
        fetchSiblingListings(result);
      }
    });
  }

  useEffect(() => {
    // TODO: we get listing information off of the survey? But we also already get all of the survey listings? Lots of duplicate info
    if (requestedSurveyId) {
      Api.getProject({
        id: requestedSurveyId,
        GET: { include_listings: true },
      }).then((result) => {
        setRequestedSurvey(result);
      });
    }
  }, [requestedSurveyId]);

  // If the user isn't logged in or the data is still loading (listing or survey)
  if (
    (!userType && !isAnonymous) ||
    ((isTenantUserType(userType) || isAnonymous) &&
      (!state.listing || !state.surveyListing)) ||
    (isBrokerUserType(userType) && !state.listing) ||
    (requestedSurveyId && !requestedSurvey)
  ) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container className={classes.root}>
      <Toolbar id="back-to-top-anchor" className={classes.invisible} />
      <Container className={classes.rootListing}>
        <div className={classes.breadcrumb}>
          {!requestedSurveyId && (
            <SurveyBreadcrumb
              buildingId={state.listing.building.id}
              buildingName={state.listing.building.name}
              buildingAddress={state.listing.building.address}
              listingAddress2={state.listing.address2}
              listingSize={state.listing.size}
              listingSqftPrice={state.listing.sqft_price}
            />
          )}
          {requestedSurveyId && (
            <SurveyListingPaginator
              listingId={state.listing.id}
              survey={requestedSurvey}
            />
          )}
        </div>
        <ViewListingHeadline
          isTenantOrBrokerPreview={isTenantOrBrokerPreview}
          listing={state.listing}
          requestedSurveyId={requestedSurveyId}
          updateBuilding={(newBuildingData) =>
            setState({
              listing: {
                ...state.listing,
                building: {
                  ...newBuildingData,
                },
              },
            })
          }
          setListing={(newListingData) => {
            setState({ listing: newListingData });
            const listing = newListingData;
            const { building } = newListingData;
            Api.updateListing({
              id: state.listing.id,
              body: {
                listing,
                building,
              },
            });
          }}
        />
        {/* If we are previewing as the broker, then mock the survey listing */}
        {isTenantOrBrokerPreview ? (
          <ViewListingBody
            listing={state.listing}
            surveyListing={
              isTenantUserType(userType)
                ? state.surveyListing
                : { id: MOCK_SURVEY_LISTING_ID, photos: [] }
            }
            siblingListings={siblingListings}
            fetchSiblingListings={fetchSiblingListings}
            requestedSurveyId={requestedSurveyId}
          />
        ) : (
          <ViewListingBody
            listing={state.listing}
            surveyListings={state.surveyListings}
            setListing={(newListingData) => {
              setState({ listing: newListingData });
              const listing = newListingData;
              const { building } = newListingData;
              return Api.updateListing({
                id: state.listing.id,
                body: {
                  listing,
                  building,
                },
              });
            }}
            siblingListings={siblingListings}
            fetchSiblingListings={fetchSiblingListings}
            requestedSurveyId={requestedSurveyId}
          />
        )}
      </Container>
    </Container>
  );
}

export default ViewListing;
