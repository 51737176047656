import React, { useState } from 'react'
import { Button, Menu, MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  PlusIcon,
  TableClearIcon,
  MenuSelectedItemIcon,
} from '~/legacy/components'
import { useSend } from '~/support/useSend'
import { Font } from '~/components/Font'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    minHeight: '0px',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  tree: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  menu: {
    borderTop: '1px solid #e0e0e0',
    height: '56px',
  },
  popover: {
    width: '226px',
    left: '12px !important',
  },
  item: {
    display: 'flex',
    height: '38px',
    gap: '8px',
  },
  label: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    color: '#666666',
    flexShrink: 0,
    minWidth: '16px',
  },
})

export const PdfHierarchy = ({
  className,
  active,
  all,
  selection,
  templates,
  template,
}) => {
  const [anchor, setAnchor] = useState(null)
  const classes = useStyles()
  const send = useSend()

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.content}>
        {templates.length > 1 && (
          <div>
            <Font format="12/12/500" color="#666666" padding="0px 0px 12px">
              Templates
            </Font>
            <div className={classes.tree}>
              {templates.map((item) => (
                <Item
                  key={item.id}
                  selected={item.id === template.id}
                  onClick={() =>
                    send('SelectTemplate', { templateId: item.id })
                  }
                >
                  {item.name}
                </Item>
              ))}
            </div>
          </div>
        )}
        <div>
          <Font format="12/12/500" color="#666666" padding="0px 0px 12px">
            Pages
          </Font>
          <div className={classes.tree}>
            {active.map((template) => (
              <Item
                key={template.id}
                editable={active.length > 1}
                selected={template === selection}
                onClear={() => send('RemovePageType', { pageId: template.id })}
                onClick={() => send('SelectPageType', { pageId: template.id })}
              >
                {template.name}
              </Item>
            ))}
          </div>
        </div>
      </div>

      <Button
        className={classes.menu}
        variant="text"
        size="small"
        startIcon={<PlusIcon />}
        onClick={(event) => setAnchor(event.currentTarget)}
      >
        <Typography variant="h3">Add Page</Typography>
      </Button>

      <Menu
        classes={{ paper: classes.popover }}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        getContentAnchorEl={null}
        elevation={2}
        onClose={() => setAnchor(null)}
        open={Boolean(anchor)}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transitionDuration={0}
      >
        {all.map((template) => (
          <MenuItem
            key={template.id}
            className={classes.item}
            onClick={() => {
              send('AddPageType', { pageId: template.id })
              setAnchor(null)
            }}
          >
            <Typography className={classes.label} variant="h3">
              {template.name}
            </Typography>
            <div className={classes.icon}>
              {active.includes(template) && <MenuSelectedItemIcon />}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const useItemStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '0px',
    height: '52px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    padding: '16px 10px 16px 16px',
    cursor: 'pointer',
    transition: theme.transitions.create('border-color'),
    '&:hover': {
      borderColor: '#666666',
    },
  },
  selected: {
    borderColor: theme.palette.primary.main,
    transition: 'none',
    cursor: 'default',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexGrow: 1,
  },
  icon: {
    color: '#e0e0e0',
    cursor: 'pointer',
    flexShrink: 0,
    '&:hover': {
      color: '#666666',
    },
  },
}))

const Item = ({
  children,
  className,
  selected,
  editable,
  onClear,
  onClick,
}) => {
  const classes = useItemStyles()
  return (
    <div
      className={clsx(
        classes.container,
        selected && classes.selected,
        className
      )}
      onClick={onClick}
    >
      <Typography className={classes.label} variant="h3">
        {children}
      </Typography>
      {editable && (
        <TableClearIcon
          className={classes.icon}
          onClick={(event) => {
            if (onClear) onClear()
            event.preventDefault()
            event.stopPropagation()
          }}
        />
      )}
    </div>
  )
}
