import { isBrokerAdminByTypeAndRole } from '~/legacy/utils';

const MS_IN_MINUTE = 60 * 1000;
const MS_IN_HALF_HOUR = 30 * MS_IN_MINUTE;
const MS_IN_DAY = 24 * 60 * MS_IN_MINUTE;

// Get the string to display on the activity message representing when it occured.
// We use some nice human readable text like "just now" instead of absolute dates
export const getEventDateDisplayString = (
  eventDate,
  currentDate = new Date()
) => {
  const today = currentDate;
  const todayAsDate = new Date(today);
  const eventDateAsDate = new Date(eventDate);

  // Diff in ms between now and the event
  const diffInMs = Math.abs(today - eventDate);

  let numDaysAgo;
  let isToday = false;
  let justNow = false;
  // If the event happened recently
  if (diffInMs < MS_IN_HALF_HOUR) {
    numDaysAgo = 0;
    justNow = true;
  } else if (todayAsDate.getDate() === eventDateAsDate.getDate()) {
    isToday = true;
  } else {
    // Otherwise, calculate the number of days since the event occured
    // Clear out the hours for day comparisons
    const eventDateClearedHours = eventDateAsDate.setHours(0, 0, 0, 0);
    const todayDateClearedHours = todayAsDate.setHours(0, 0, 0, 0);

    // Diff in ms between now and the event
    numDaysAgo = Math.round(
      Math.abs(todayDateClearedHours - eventDateClearedHours) / MS_IN_DAY
    );
    justNow = false;
  }

  // Pick the best display string
  let timeString = '';
  if (justNow) {
    timeString = 'Just Now';
  } else if (isToday) {
    timeString = 'Today';
  } else if (numDaysAgo === 0) {
    timeString;
    timeString = eventDateAsDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  } else if (numDaysAgo === 1) {
    timeString = 'Yesterday';
  } else {
    timeString = `${numDaysAgo} Days Ago`;
  }
  return timeString;
};

// Get the user's name for display on the activity feed
export const getUserName = (user) => {
  let name = user.email;

  if (user.first_name && user.last_name) {
    name = `${user.first_name} ${user.last_name}`;
  } else if (user.username) {
    name = user.username;
  }
  return name;
};

// Format the date to a string for the date tooltip
export const formatTooltipDate = (date) => {
  return date.toLocaleString('en-US', {
    weekday: 'long',
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

// Get the name of the activity author
export const getAuthorName = (activity) => {
  const userName = getUserName(activity.author);
  if (activity.author.company && activity.author.company.name) {
    return `${userName}, ${activity.author.company.name}`;
  }
  return userName;
};

export const userOwnsActivityItem = (activity, user) => {
  if (
    user.is_admin ||
    isBrokerAdminByTypeAndRole(user.userType, user.user_role) ||
    (activity.author && activity.author.id === user.id)
  ) {
    return true;
  }
  return false;
};
