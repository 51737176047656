import React from 'react'
import { useParams } from 'react-router'
import { emptyProjectDetail, fullProjectDetail } from '~/legacy/fixtures'
import { Project as Layout } from './views/Project'

const projects = [fullProjectDetail, emptyProjectDetail]

export const Project = () => {
  const params = useParams()
  const project = projects[Number(params.project_id)]
  if (!project) return null
  return <Layout {...project} />
}
