import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarUtils } from '~/legacy/utils';
import { EditIcon, Typography, MenuItem, TextField } from '~/legacy/components';
import { BASE_ICON_STYLES } from '~/legacy/components/buttons/ButtonUtils';
import ActionModal from '../modals/ActionModal';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
  textInputFieldContainer: {
    width: '100%',
    marginTop: '10px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

// A modal to edit a project
export const EditProjectMenuItemModal = ({
  project,
  updateProject,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const hasErrors = !!(errors && Object.keys(errors).length);

  const [newProjectName, setNewProjectName] = useState(
    (project && project.name) || undefined
  );

  // Validate the entered project data
  const validate = useCallback(() => {
    const newErrors = {};
    if (!newProjectName) {
      newErrors.newProjectName = 'You must provide a project name';
    }
    setErrors(newErrors);
    return newErrors;
  }, [newProjectName]);

  // When the project changes, or the  modal is closed then repoened, reset the user state
  useEffect(() => {
    if (project && open) {
      setNewProjectName((project && project.name) || undefined);
    }
  }, [project, open]);

  // If there are already errors and the user changes the project info, re-validate. ie: The user is trying to fix
  //    the existing errors.
  useEffect(() => {
    if (hasErrors) {
      validate();
    }
  }, [hasErrors, newProjectName, validate]);

  const closeModal = () => {
    onClose();
  };

  // Update the project
  const updateProjectApi = async () => {
    setIsLoading(true);

    // Early escape if any validation of fields issue
    const newErrors = validate();
    if (Object.values(newErrors).length) {
      setIsLoading(false);
      return;
    }

    try {
      await updateProject({ name: newProjectName });
      SnackbarUtils.success('Project Successfully Updated.');
      closeModal();
    } catch {
      // supress
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ActionModal
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onConfirm={updateProjectApi}
      title="Edit Project"
      confirmButtonLabel="Save"
      disableAction={hasErrors || isLoading}
      loading={isLoading}
    >
      <div className={classes.modalContent}>
        <div className={classes.textInputFieldContainer}>
          <TextField
            label="Name"
            placeholder="Enter a name"
            value={newProjectName || ''}
            onChange={(event) => setNewProjectName(event.target.value)}
            helperText={errors.newProjectName || null}
            error={!!errors.newProjectName}
          />
        </div>
      </div>
    </ActionModal>
  );
};

// Menu item to edit a project
export const EditProjectMenuItem = React.forwardRef(
  ({ openModal, ...props }, ref) => {
    const classes = useStyles();

    return [
      <MenuItem
        key="edit-project-menu-item"
        ref={ref}
        className={classes.menuItemRoot}
        onClick={openModal}
        {...props}
      >
        <EditIcon className={classes.icon} />
        <Typography className={classes.text}> Edit Project </Typography>
      </MenuItem>,
    ];
  }
);

// Bundle the modal and the menu item together for maximum convenience
export const useEditProjectMenuItem = ({
  project,
  updateProject,
  handleMenuClose,
}) => {
  const [open, setOpen] = React.useState(false);

  const EditProjectMenuItemComponent = (
    <EditProjectMenuItem openModal={() => setOpen(true)} />
  );

  const EditProjectMenuItemModalComponent = (
    <EditProjectMenuItemModal
      key="edit-project-modal"
      project={project}
      updateProject={updateProject}
      open={open}
      onClose={() => {
        setOpen(false);
        handleMenuClose();
      }}
    />
  );

  return {
    EditProjectMenuItemComponent,
    EditProjectMenuItemModalComponent,
  };
};
