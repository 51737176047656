import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { PickList } from '~/pdfs/patterns/PickList'
import { Settings } from '~/pdfs/patterns/Settings'
import { Group } from '~/pdfs/patterns/Group'
import { Text } from '~/pdfs/patterns/Text'
import { useSend } from '~/support/useSend'
import { useFieldWithDelay as useFieldDelay } from '~/support/useFieldDelay'
import * as Markets from '../CresaMarkets'
import * as Types from '../CresaTypes'
import { findMarket } from '../support/findMarket'
import { findType } from '../support/findType'
import { Upload } from '~/pdfs/patterns/Upload'
import { useMessage } from '~/libraries/messaging.react'
import { apiUrl } from '~/support/apiUrl'
import { upload } from '~/support/upload'

const useStyles = makeStyles({
  photo: {
    height: '522px',
    background: '#666666',
    width: '100%',
    objectFit: 'cover',
  },
  title: {
    fontFamily: 'Gilroy',
    background: '#001E5A',
    padding: '25px 36px 19px',
    color: '#ffffff',
    fontSize: '50px',
    lineHeight: '50px',
    fontWeight: 700,
    display: 'inline-block',
    marginTop: '-48px',
  },
  subtitle: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '30px',
    color: '#001E5A',
    margin: '24px 36px',
  },
  date: {
    position: 'absolute',
    left: '36px',
    top: '743px',
    fontFamily: "'Gilroy'",
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#001E5A',
  },
  logo: {
    position: 'absolute',
    width: '210px',
    height: '60px',
    bottom: '36px',
    right: '36px',
    objectFit: 'scale-down',
    objectPosition: 'bottom right',
  },
})

export const CresaCover = definePageTemplate({
  id: 'c0664088-42a9-4279-9ab3-08cfbb3dcb20',

  name: 'Cover',

  props: ({ data: survey, settings }) => ({
    id: survey.id,
    name: settings.name || survey.name,
    title: findType(settings.typeId).name,
    photo: findMarket(settings.marketId).photo,
    date: settings.date,
    marketId: settings.marketId,
    typeId: settings.typeId,
    logo: settings.logo,
    logoName: settings.logoName,
  }),

  Page: (props) => {
    const classes = useStyles()

    return (
      <div>
        <img alt={props.name} className={classes.photo} src={props.photo} />
        <div className={classes.title}>{props.title}</div>
        <div className={classes.subtitle}>{props.name}</div>
        <div className={classes.date}>
          {props.date || format(new Date(), 'MMMM uuuu')}
        </div>
        {props.logo && (
          <img className={classes.logo} src={props.logo} alt="logo" />
        )}
      </div>
    )
  },

  Settings: (props) => {
    const [uploading, setUploading] = useState(false)
    const send = useSend()

    useMessage('Upload', async ({ files }) => {
      try {
        setUploading(true)
        const data = { survey_id: props.id }
        const url = apiUrl('/survey_export_custom_cover_photos/')
        const response = await upload(url, files[0], data)

        await new Promise((resolve) => {
          setTimeout(resolve, 3000)
        })

        send('AddSettings', {
          logo: response.data.file.thumbnail_image_url,
          logoName: response.data.file.name,
        })
      } catch (error) {
        //
      } finally {
        setUploading(false)
      }
    })

    useMessage('ClearUpload', () => {
      send('RemoveSettings', ['logo', 'logoName'])
    })

    return (
      <Settings>
        <PickList
          label="Market"
          items={[
            Markets.Urban,
            Markets.Suburban,
            Markets.Industrial,
            Markets.Science,
          ]}
          value={props.marketId}
          onChange={(marketId) => {
            send('AddSettings', { marketId })
          }}
        />

        <PickList
          label="Type"
          items={[Types.Survey, Types.Tour]}
          value={props.typeId}
          onChange={(typeId) => {
            send('AddSettings', { typeId })
          }}
        />

        <Group label="Name">
          <Text
            {...useFieldDelay(props.name, (name) => {
              send('AddSettings', { name })
            })}
            placeholder="Add Name"
            nullable
          />
        </Group>

        <Group label="Date">
          <Text
            {...useFieldDelay(props.date, (date) => {
              send('AddSettings', { date })
            })}
            placeholder="Add Date"
            nullable
          />
        </Group>

        <Group label="Logo">
          <Upload
            id={props.id}
            placeholder="Upload Image"
            loading={uploading}
            name={props.logoName}
          />
        </Group>
      </Settings>
    )
  },
})
