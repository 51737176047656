import { Icon } from '@material-ui/core';
import React from 'react';
import { Avatar, Skeleton, Typography } from '~/legacy/components';
import { styled } from '~/legacy/utils/styleHelpers';

export const Recent = ({
  name,
  kind,
  icon,
  logo,
  company,
  onSelect,
  ...props
}) => {
  const handleSelect = () => {
    if (onSelect) onSelect();
  };

  return (
    <Container onClick={handleSelect} {...props}>
      <Details>
        <Name>{name}</Name>
        <Text>{kind}</Text>
      </Details>
      <Icon>{icon}</Icon>
      <Company>
        <Avatar image={logo} alt={company} text={company[0]} size="tiny" />
        <Text>{company}</Text>
      </Company>
    </Container>
  );
};

export const DocumentSkeleton = (props) => (
  <Container {...props}>
    <Details>
      <Skeleton top={4} width="80%" height={14} rounded={2} />
      <Skeleton top={8} width="60%" height={14} rounded={2} />
    </Details>
    <Skeleton circle={24} />
    <Company>
      <Skeleton circle={24} />
      <Skeleton width="50%" height={14} rounded={2} />
    </Company>
  </Container>
);

const Container = styled('div', {
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: '1fr auto',
  borderRadius: 4,
  minWidth: 0,
  display: 'grid',
  padding: '11px 11px 15px',
  border: '1px solid #E0E0E0',
  cursor: 'default',
  height: 118,
  gap: '8px',
});

const Details = styled('div', {
  minWidth: 0,
});

const Text = styled(Typography, {
  color: '#666666',
});

Text.defaultProps = {
  ellipsis: true,
  variant: 'bodyText',
};

const Name = styled(Text, {
  fontWeight: 600,
  color: '#111111',
});

const Company = styled('div', {
  gridColumn: '1 / span 2',
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
});
