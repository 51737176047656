import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExportSurveySettingsAndLayout,
  SquareCheckbox,
} from '~/legacy/components';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxContainer: {
    borderBottom: '1px solid #e0e0e0',
    marginBottom: 32,
    alignItems: 'center',
    display: 'flex',
    padding: '10px 0',
    cursor: 'pointer',
    width: '100%',
  },
  checkbox: {
    marginLeft: -6,
    marginRight: 8,
    color: '#111111',
  },
  text: {
    color: '#666666',
    marginBottom: -2,
  },
});

function ComparePageSettings(props) {
  const { settings, updateSettings } = props;
  const { showImages, showFullAddress } = settings;
  const classes = useStyles();

  const toggleSetting = (newSetting) =>
    updateSettings({
      ...settings,
      ...newSetting,
    });

  const checkboxes = [
    {
      text: 'Images',
      label: 'Show Images',
      value: showImages,
      toggle: () => toggleSetting({ showImages: !showImages }),
    },
    {
      text: 'Address',
      label: 'Show Full Address',
      value: showFullAddress,
      toggle: () => toggleSetting({ showFullAddress: !showFullAddress }),
    },
  ];

  return (
    <ExportSurveySettingsAndLayout {...props}>
      <div className={classes.container}>
        {checkboxes.map((checkbox) => (
          <Fragment key={checkbox.label}>
            <Typography className={classes.text} variant="h5">
              {checkbox.text}
            </Typography>
            <div
              className={classes.checkboxContainer}
              onClick={checkbox.toggle}
            >
              <SquareCheckbox
                className={classes.checkbox}
                checked={checkbox.value}
                onClick={checkbox.toggle}
              />
              <Typography variant="h3">{checkbox.label}</Typography>
            </div>
          </Fragment>
        ))}
      </div>
    </ExportSurveySettingsAndLayout>
  );
}

export default ComparePageSettings;
