import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { SquareIconButton, TextButton } from '~/legacy/components'
import { CompareIcon } from '~/legacy/components/svgs'
import { LEASE_SURVEY_COMPARE_SPACES } from '~/legacy/consts'
import { openLink } from '~/legacy/utils/openLink'

const LABEL_TEXT = 'Compare Spaces'

const CompareSpacesButton = withStyles({
  iconButton: {},
})(
  ({
    classes,
    surveyId,
    spaceIds,
    textButton = false,
    onClick = () => {},
    ...props
  }) => {
    const handleClick = (event) => {
      onClick(event)
      openLink(
        `${LEASE_SURVEY_COMPARE_SPACES.replace(
          ':survey_id',
          surveyId
        )}?listings=${spaceIds.join('&listings=')}`
      )(event)
    }

    if (textButton) {
      return (
        <TextButton
          startIcon={<CompareIcon />}
          disabled={!spaceIds || !spaceIds.length}
          onClick={handleClick}
          {...props}
        >
          {LABEL_TEXT}
        </TextButton>
      )
    }

    return (
      <SquareIconButton
        tooltipText={LABEL_TEXT}
        className={classes.iconButton}
        onClick={handleClick}
        {...props}
      >
        <CompareIcon />
      </SquareIconButton>
    )
  }
)

export default CompareSpacesButton
