const ACTIONS = {
  SET_LISTINGS: 'view_listing->set_listings',
  RESET: 'view_listing->reset',
};

const initialState = {
  listings: [],
  initialized: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_LISTINGS: {
      return {
        ...state,
        initialized: true,
        listings: action.payload,
      };
    }
    case ACTIONS.RESET: {
      return {
        ...state,
        initialized: false,
        listings: [],
      };
    }
    default:
      return state;
  }
}

export const viewListingActions = {
  setListings: payload => ({ type: ACTIONS.SET_LISTINGS, payload }),
  reset: () => ({ type: ACTIONS.RESET }),
};
