const PROGRESS = {
  DRAFT: {
    name: 'Draft',
    id: 0,
    progressIndex: 1,
  },
  SEARCH: {
    name: 'Search',
    id: 10,
    progressIndex: 2,
  },
  PROPOSAL: {
    name: 'Proposal',
    id: 20,
    progressIndex: 3,
  },
  SIGNED: {
    name: 'Signed',
    id: 30,
    progressIndex: 4,
  },
};

const PROJECT_VALUES = Object.values(PROGRESS);
const PROGRESS_MAP = Object.fromEntries(PROJECT_VALUES.map((p) => [p.id, p]));

export const PROJECT = {
  STUFF_TYPES: {
    SPACE_TYPE: 1,
    BUILDING_TYPE: 2,
    SURVEY_TYPE: 3,
    TOUR_TYPE: 4,
  },
  PROGRESS,
  PROGRESS_MAP,
  PROGRESS_STATES_LENGTH: PROJECT_VALUES.length,
};

// Legacy
export const SURVEY_NAMING = 'Survey';
