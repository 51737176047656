import React, { memo } from 'react'
import { FormControl, TextField } from '@material-ui/core'

function TextInput(props) {
  const {
    className = '',
    errorText,
    fullWidth = true,
    label,
    onChange,
    required = false,
    type = 'text',
    value = '',
    FormControlProps = {},
    ...other
  } = props
  return (
    <FormControl
      className={className}
      fullWidth={fullWidth}
      variant="outlined"
      {...FormControlProps}
    >
      <TextField
        error={!!errorText || other.error}
        helperText={errorText}
        label={label}
        onKeyPress={(e) => {
          // FF you are a special breed for this one...
          const event = e || window.event
          if (type === 'number') {
            const charCode =
              typeof event.which === 'undefined' ? event.keyCode : event.which
            const charStr = String.fromCharCode(charCode)

            if (!charStr.match(/^[0-9.]+$/)) {
              event.preventDefault()
            }
          }
          onChange(event)
        }}
        onChange={onChange}
        required={required}
        type={type}
        value={value || ''}
        variant="outlined"
        {...other}
      />
    </FormControl>
  )
}

export default memo(TextInput)
