import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  EditIcon,
  MenuItem,
  TextField,
  Typography,
  UserAvatar,
  UserRoleSelect,
} from '~/legacy/components'
import { userTypes } from '~/legacy/consts'
import { updateUserData } from '~/legacy/store'
import {
  formatUserObject,
  getUserName,
  getUserRedirectForHomepage,
  isBrokerAdmin,
  isBrokerAdminRole,
  isEmailAddress,
  parseUserName,
  SnackbarUtils,
} from '~/legacy/utils'
import { useApiHelper } from '~/legacy/utils/hooks'
import ActionModal from '../modals/ActionModal'
import { BASE_ICON_STYLES } from './ButtonUtils'

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
  textInputFieldContainer: {
    width: '100%',
    marginTop: '28px',
  },
  imageButtonsContainer: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarDivider: {
    color: '#e0e0e0',
    margin: '4px',
    cursor: 'default',
  },
  avatarContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

// A modal to edit a user's profile
export const EditProfileMenuItemModal = ({
  user,
  editingSelf,
  anotherAdminExists = true,
  updateUser,
  open,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const apiHelper = useApiHelper()
  const history = useHistory()
  const adminUser = useSelector((s) => s.user)

  const getNewUserRole = (userLocal) =>
    userLocal &&
    userLocal.user_type !== undefined &&
    userLocal.user_role !== undefined
      ? userLocal.user_role
      : undefined

  // State to hold the new user details entered
  const [newUserName, setNewUserName] = useState(
    (user && getUserName(user)) || undefined
  )
  const [newUserEmail, setNewUserEmail] = useState(
    (user && user.email) || undefined
  )
  const [newUserAvatar, setNewUserAvatar] = useState(
    (user && user.user_image_avatar) || undefined
  )
  const [newUserRole, setNewUserRole] = useState(getNewUserRole(user))
  const avatarUploadRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const hasErrors = !!(errors && Object.keys(errors).length)

  // Validate the entered profile data
  const validate = useCallback(() => {
    const newErrors = {}
    if (!newUserEmail || !isEmailAddress(newUserEmail)) {
      newErrors.newUserEmail = 'You must provide a valid email address'
    }
    if (!newUserRole && newUserRole !== 0) {
      newErrors.newUserRole = 'You must choose a role'
    }
    setErrors(newErrors)
    return newErrors
  }, [newUserEmail, newUserRole])

  // When the user changes, or the  modal is closed then repoened, reset the user state
  useEffect(() => {
    if (user && open) {
      const userName = getUserName(user)
      setNewUserName(userName)
      setNewUserEmail(user.email)
      setNewUserAvatar(user.user_avatar_image)
      setNewUserRole(getNewUserRole(user))
    }
  }, [user, open])

  // If there are already errors and the user changes the user info, re-validate. ie: The user is trying to fix
  //    the existing errors.
  useEffect(() => {
    if (hasErrors) {
      validate()
    }
  }, [hasErrors, newUserRole, newUserEmail, validate])

  const closeModal = () => {
    onClose()
  }

  // Disable changing the role if this is the only admin in the firm.
  const roleDisabled = !anotherAdminExists && isBrokerAdmin(user)
  let rolesErrorText = ''
  if (roleDisabled) {
    rolesErrorText =
      "There are no other admins. There must be another admin before you can change this user's role"
  } else if (errors.newUserRole) {
    rolesErrorText = errors.newUserRole
  }

  // API call to update the user info
  const updateUserApi = () => {
    setIsLoading(true)

    // Early escape if any validation of fields issue
    const newErrors = validate()
    if (Object.values(newErrors).length) {
      setIsLoading(false)
      return
    }

    // User data formatted for endpiont
    const userData = {
      ...parseUserName(newUserName),
      username: newUserEmail,
      email: newUserEmail,
      user_role: newUserRole,
    }
    // The call to update all of the user details, minus the photo
    const updateUserApiCall = () => {
      apiHelper
        .updateUserData(userData, user.id)
        .then((res) => {
          if (res.data) {
            if (res.data.message) {
              // there was an error
              SnackbarUtils.error(res.data.message)
            } else {
              const formattedUserData = formatUserObject(res.data)
              // Update the user with fresh details
              updateUser({ ...user, ...res.data })
              // If editing yourself, update the user data in redux
              // If the user changed themselves to non-admin, bounce to homepage
              if (editingSelf) {
                SnackbarUtils.success('Your profile successfully updated')
                if (!isBrokerAdminRole(formattedUserData.userRole)) {
                  history.push(getUserRedirectForHomepage(adminUser.user_type))
                }
                dispatch(updateUserData(formattedUserData, adminUser.id))
              } else {
                SnackbarUtils.success('User profile successfully updated')
              }
              closeModal()
            }
          } else {
            SnackbarUtils.error('Error updating the user')
            closeModal()
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    const uploadingNewFile = !!(newUserAvatar && newUserAvatar.newFile)
    if (uploadingNewFile) {
      // Upload the user's new photo
      apiHelper
        .uploadUserAvatar({
          file: newUserAvatar.newFile,
          userId: user.id,
        })
        .then((res) => {
          // Then update the rest of the user info
          updateUserApiCall()
          if (!res.success) {
            SnackbarUtils.error('Unable to upload user photo')
          }
        })
    } else if (
      !uploadingNewFile &&
      user.user_avatar_image &&
      user.user_avatar_image.id
    ) {
      // If the user has a photo and its being removed, remove the user's photo.
      apiHelper
        .removeUserAvatar({ fileId: user.user_avatar_image.id })
        .then((res) => {
          updateUserApiCall()
          if (!res.ok) {
            SnackbarUtils.error('Unable to edit user photo')
          }
        })
    } else {
      // Otherwise, just update the other user details
      updateUserApiCall()
    }
  }

  return (
    <ActionModal
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onConfirm={updateUserApi}
      title="Edit Profile"
      confirmButtonLabel="Save"
      disableAction={hasErrors || isLoading}
      loading={isLoading}
    >
      <div className={classes.avatarContent}>
        <UserAvatar
          className={classes.avatar}
          user={user}
          size="large"
          fileOverride={newUserAvatar}
        />
        <div className={classes.imageButtonsContainer}>
          <Link
            onClick={() => avatarUploadRef.current.click()}
            underline="none"
          >
            <input
              ref={avatarUploadRef}
              accept="image/jpeg,image/png,image/webp"
              type="file"
              hidden
              onChange={(event) => {
                const newFile = event.target.files[0]
                const fileUrl = URL.createObjectURL(newFile)
                setNewUserAvatar({
                  newFile,
                  url: fileUrl,
                  thumbnail_image_url: fileUrl,
                })
              }}
            />
            <Typography color="primary" variant="boldText">
              Upload
            </Typography>
          </Link>
          <Typography className={classes.avatarDivider} variant="body1">
            |
          </Typography>
          <Link onClick={() => setNewUserAvatar(null)} underline="none">
            <Typography color="primary" variant="boldText">
              Remove
            </Typography>
          </Link>
        </div>
      </div>
      <div>
        <div className={classes.textInputFieldContainer}>
          <TextField
            label="Name"
            placeholder="Enter a name"
            value={newUserName || ''}
            onChange={(event) => setNewUserName(event.target.value)}
          />
        </div>
        <div className={classes.textInputFieldContainer}>
          <TextField
            label="Email"
            placeholder="Enter an email address"
            value={newUserEmail || ''}
            onChange={(event) => setNewUserEmail(event.target.value)}
            helperText={errors.newUserEmail || null}
            error={!!errors.newUserEmail}
          />
        </div>
        <div className={classes.textInputFieldContainer}>
          <UserRoleSelect
            value={newUserRole}
            userType={user ? user.user_type : userTypes.tenantBroker}
            onChange={(event) => setNewUserRole(event.target.value)}
            errorText={rolesErrorText}
            disabled={!!roleDisabled}
          />
        </div>
      </div>
    </ActionModal>
  )
}

// Menu item to edit a user's profile
export const EditProfileMenuItem = React.forwardRef(
  ({ openModal, ...props }, ref) => {
    const classes = useStyles()

    return [
      <MenuItem
        key="edit-profile-menu-item"
        ref={ref}
        className={classes.menuItemRoot}
        onClick={openModal}
        {...props}
      >
        <EditIcon className={classes.icon} />
        <Typography className={classes.text}> Edit Profile </Typography>
      </MenuItem>,
    ]
  }
)

// Bundle the modal and the menu item together for maximum convenience
export const useEditProfileMenuItem = ({
  user,
  editingSelf,
  anotherAdminExists,
  updateUser,
  handleMenuClose,
}) => {
  const [open, setOpen] = React.useState(false)

  const EditProfileMenuItemComponent = (
    <EditProfileMenuItem openModal={() => setOpen(true)} />
  )

  const EditProfileMenuItemConfirmModalComponent = (
    <EditProfileMenuItemModal
      key="edit-profile-modal"
      user={user}
      editingSelf={editingSelf}
      updateUser={updateUser}
      anotherAdminExists={anotherAdminExists}
      open={open}
      onClose={() => {
        setOpen(false)
        handleMenuClose()
      }}
    />
  )

  return {
    EditProfileMenuItemComponent,
    EditProfileMenuItemConfirmModalComponent,
  }
}
