import React from 'react'
import { prop, styled, when, percent } from '~/libraries/styled'

export const Photo = ({ fit, src, ...props }) => (
  <Container
    style={{
      backgroundImage: `url(${src})`,
      backgroundSize: fit ?? 'cover',
    }}
    {...props}
  />
)

export const Container = styled('div', ['aspect', 'grow', 'height', 'width'], {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  flexGrow: when('grow', 1),
  flexShrink: 0,
  height: prop('height'),
  width: prop('width'),
  paddingTop: (props) => {
    return props.aspect && percent(props.aspect)
  },
})
