import React from 'react'
import { makeStyles } from '@material-ui/core'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { useMapboxPins } from '~/support/useMapboxPins'
import { CresaPage } from '../patterns/CresaPage'
import { Settings } from '~/pdfs/patterns/Settings'
import { Group } from '~/pdfs/patterns/Group'
import { Location } from '~/pdfs/patterns/Location'
import { useSend } from '~/support/useSend'
import { mapboxUrl } from '~/support/mapboxUrl'

const useStyles = makeStyles({
  map: {
    width: '612px',
    height: '449px',
    padding: '15px 0px',
    marginLeft: '-36px',
  },

  location: {
    display: 'flex',
    gap: '6px',
    padding: '8px 0px',
    alignItems: 'center',
  },

  current: {
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '12px',
    color: '#001E5A',
  },

  title: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    color: '#001E5A',
    marginLeft: '6px',
  },

  sites: {
    padding: '10px 0px',
    borderTop: '2px solid #001E5A;',
    columns: 2,
    columnFill: 'auto',
    height: '220px',
  },

  site: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  },

  marker: {
    width: '12px',
    height: '12px',
    background: '#0056DA',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '7px',
    lineHeight: '8px',
    color: '#FFFFFF',
  },

  address: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '8px',
    lineHeight: '15px',
    color: '#000000',
  },
})

export const CresaMap = definePageTemplate({
  id: '86fe6448-7ff1-4a3e-be8d-d03a7507a7c5',

  name: 'Map of Properties',

  props: ({ data: survey, settings }) => {
    const buildings = survey.buildings.filter((building) => {
      return building.spaces.length > 0
    })

    return {
      location: settings.location,
      locations: buildings.map((building, index) => ({
        id: building.id,
        label: index + 1,
        coordinates: building.location.coordinates,
        name: ['name', 'address', 'city', 'state']
          .map((key) => building.location[key])
          .filter(Boolean)
          .join(', '),
      })),
    }
  },

  Page: (props) => {
    const classes = useStyles()
    const pins = useMapboxPins(
      props.locations.map((location) => {
        return location.coordinates
      })
    )

    if (props.location) {
      const [lon, lat] = props.location.coordinates
      pins.push(
        encodeURIComponent(
          `url-https://leaseup-production.s3.us-east-2.amazonaws.com/Star.png(${lon},${lat})`
        )
      )
    }

    return (
      <CresaPage title="Map of Available Properties">
        <img
          className={classes.map}
          src={mapboxUrl(612, 449, pins.join(','))}
          alt="map"
        />

        {props.location && (
          <div className={classes.location}>
            <Star />
            <div>
              <span className={classes.current}>Current Location:</span>
              <span className={classes.title}>
                {props.location.description}
              </span>
            </div>
          </div>
        )}
        <div className={classes.sites}>
          {props.locations.map((location) => (
            <div key={location.id} className={classes.site}>
              <div className={classes.marker}>{location.label}</div>
              <div className={classes.address}>{location.name}</div>
            </div>
          ))}
        </div>
      </CresaPage>
    )
  },

  Settings: (props) => {
    const send = useSend()

    return (
      <Settings>
        <Group label="Client Current Location">
          <Location
            placeholder="Address"
            value={props.location}
            onChange={(location) => {
              send('AddSettings', { location })
            }}
          />
        </Group>
      </Settings>
    )
  },
})

const Star = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7_1445)">
      <path
        d="M7.17007 6.9L1.07007 7.06L5.91007 10.78L4.18007 16.65L9.21007 13.18L14.2401 16.65L12.5001 10.78L17.3501 7.06L11.2401 6.9L9.21007 1.13L7.17007 6.9Z"
        fill="#FFB600"
      />
      <path
        d="M9.21007 1.13L7.17007 6.9L1.07007 7.06L5.92007 10.78L4.18007 16.65L9.21007 13.18L14.2401 16.65L12.5001 10.78L17.3501 7.06L11.2401 6.9L9.21007 1.13Z"
        stroke="#001E5A"
        strokeWidth="0.75"
      />
    </g>
    <defs>
      <clipPath id="clip0_7_1445">
        <rect width="18.42" height="17.56" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
