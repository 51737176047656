import React, { useMemo } from 'react';

import { getUserInitials, formatUserObject } from '~/legacy/utils';
import { Avatar } from '~/legacy/components';

// "small" or "large" size supported
export default function UserAvatar({
  className,
  size,
  user,
  fileOverride,
  zIndex,
}) {
  const userLocal = user || {};
  const formattedUser = useMemo(() => formatUserObject(userLocal), [userLocal]);

  const imageToDisplay =
    fileOverride !== undefined ? fileOverride : formattedUser.userAvatarImage;

  return (
    <Avatar
      size={size}
      className={className}
      includeBorder
      image={imageToDisplay}
      text={getUserInitials(formattedUser)}
      zIndex={zIndex}
    />
  );
}
