import useSWR from 'swr'
import { useMemo } from 'react'
import { surveyListingCustomFieldApi } from '~/legacy/fetchApi/surveyListingCustomField'
import { defaultMutateBinder, defaultMutateOptimisticBinder } from './selectors'

const DEFAULT_API_ERROR_MESSAGE =
  'Error updating custom field value, please try again.'

export const useSurveyListingCustomFieldSelector = ({
  surveyId,
  buildingId = undefined,
  enabled = true,
}) => {
  const { data, error, mutate } = useSWR(
    enabled && surveyId
      ? `surveys/${surveyId}/buildings/${buildingId}/listing_custom_fields`
      : null,
    async () =>
      surveyListingCustomFieldApi
        .list({ surveyId, buildingId })
        .then(([, response]) => {
          return response
        })
  )

  const { customFieldValues } = useMemo(() => {
    const customFieldValues = data || []

    return {
      customFieldValues,
    }
  }, [data])

  // Bind our mutators with our default settings and error handling
  const mutateSurveyListingCustomFields = defaultMutateBinder(
    mutate,
    DEFAULT_API_ERROR_MESSAGE
  )

  const mutateSurveyListingCustomFieldsOptimistic =
    defaultMutateOptimisticBinder(
      mutateSurveyListingCustomFields,
      DEFAULT_API_ERROR_MESSAGE
    )

  const mutateCreateListingCustomFieldValue = async ({
    surveyId,
    listingId,
    customFieldId,
    newValue,
    optimistic = true,
  }) => {
    const apiMutator = async () =>
      surveyListingCustomFieldApi
        .createFieldValue({
          surveyId,
          listingId,
          customFieldId,
          value: newValue,
        })
        .then(([, response]) => {
          return [...data, response]
        })

    if (optimistic) {
      return mutateSurveyListingCustomFieldsOptimistic({
        newObject: [
          ...data,
          {
            survey_id: surveyId,
            listing_id: listingId,
            value: newValue,
            custom_field: { id: customFieldId },
          },
        ],
        mutator: apiMutator,
      })
    }

    return mutateSurveyListingCustomFields({ mutator: apiMutator })
  }

  const mutateChangeListingCustomFieldValue = async ({
    id,
    newValue,
    optimistic = true,
  }) => {
    const apiMutator = async () =>
      surveyListingCustomFieldApi
        .updateFieldValue({
          surveyListingCustomFieldId: id,
          value: newValue,
        })
        .then(([, response]) => {
          const index = data.findIndex((item) => item.id === response.id)

          return [...data.slice(0, index), response, ...data.slice(index + 1)]
        })

    if (optimistic) {
      const index = data.findIndex((item) => item.id === id)
      const element = data[index]

      const newData = [
        ...data.slice(0, index),
        { ...element, value: newValue },
        ...data.slice(index + 1),
      ]

      return mutateSurveyListingCustomFieldsOptimistic({
        newObject: newData,
        mutator: apiMutator,
      })
    }

    return mutateSurveyListingCustomFields({ mutator: apiMutator })
  }

  return {
    customFieldValues,
    error,
    isLoading: !data,
    mutateChangeListingCustomFieldValue,
    mutateCreateListingCustomFieldValue,
  }
}
