import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Link } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  UserAvatar,
  UserRoleSelect,
} from '~/legacy/components'
import { BROKER_ROLES, userTypes } from '~/legacy/consts'
import { useLogoUrl, isEmailAddress, SnackbarUtils } from '~/legacy/utils'
import { useApiHelper } from '~/legacy/utils/hooks'

import ActionModal from '../modals/ActionModal'

const useStyles = makeStyles({
  textInputFieldContainer: {
    width: '100%',
    marginTop: '28px',
  },
  imageButtonsContainer: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarDivider: {
    color: '#e0e0e0',
    margin: '4px',
    cursor: 'default',
  },
  avatarContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const AddBrokerToFirmModal = ({
  firmUsers,
  updateUser,
  open,
  onClose,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const apiHelper = useApiHelper()

  // State to hold the new user details entered
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [role, setRole] = useState(BROKER_ROLES.BASE)
  const [userAvatar, setUserAvatar] = useState()
  const avatarUploadRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [emailError, setEmailError] = useState(null)
  const [roleError, setRoleError] = useState(null)

  const hasErrors = emailError || roleError
  const firmUserEmails = React.useMemo(
    () => new Set(firmUsers ? firmUsers.map((firmUser) => firmUser.email) : []),
    [firmUsers]
  )

  const validate = useCallback(() => {
    let newEmailError = null
    if (!email || !isEmailAddress(email)) {
      newEmailError = 'You must provide a valid email address.'
    } else if (firmUserEmails.has(email)) {
      newEmailError =
        'A user with this email address already exists, please try another email address.'
    }
    const newRoleError = !role && role !== 0 ? 'You must choose a role' : null
    setEmailError(newEmailError)
    setRoleError(newRoleError)
    return !newEmailError && !newRoleError
  }, [email, role, firmUserEmails])

  useEffect(() => {
    if (hasErrors) {
      validate()
    }
  }, [hasErrors, email, role, validate])

  const logoUrl = useLogoUrl()

  const closeModal = () => {
    onClose()
  }

  const clearFields = () => {
    setFirstName(null)
    setLastName(null)
    setEmail(null)
    setRole(BROKER_ROLES.BASE)
    setUserAvatar(null)
  }

  const addUserApi = () => {
    if (!validate()) return

    setIsLoading(true)
    // Create the unconfirmed broker user
    apiHelper
      .createBroker(
        {
          email,
          first_name: firstName || '',
          last_name: lastName || '',
          user_role: role,
        },
        {
          // TODO: Why the hell is all of this here? pdf?
          color: theme.palette.primary.main,
          color_hover: theme.palette.primary.dark,
          logo_url: logoUrl,
        },
        userAvatar ? userAvatar.newFile : null
      )
      .then(([response, responseData]) => {
        if (response && response.ok) {
          SnackbarUtils.success('User successfully invited.')
          clearFields()
          updateUser(responseData.data)
          onClose()
        } else if (responseData.data === 'User already exists') {
          SnackbarUtils.warning(
            'A user with this email address already exists, please try another email address.'
          )
        } else {
          SnackbarUtils.error(
            'There was an issue inviting the user, please try again.'
          )
        }
      })
      .catch(() => {
        SnackbarUtils.error(
          'There was an issue inviting the user, please try again.'
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ActionModal
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onConfirm={addUserApi}
      title="Add User"
      confirmButtonLabel="Add"
      disableAction={!!(hasErrors || isLoading)}
      loading={isLoading}
    >
      <div className={classes.avatarContent}>
        <UserAvatar
          className={classes.avatar}
          user={{ firstName, lastName }}
          size="large"
          fileOverride={userAvatar}
        />
        <div className={classes.imageButtonsContainer}>
          <Link
            onClick={() => avatarUploadRef.current.click()}
            underline="none"
          >
            <input
              ref={avatarUploadRef}
              accept="image/jpeg,image/png,image/webp"
              type="file"
              hidden
              onChange={(event) => {
                const newFile = event.target.files[0]
                const fileUrl = URL.createObjectURL(newFile)
                setUserAvatar({
                  newFile,
                  url: fileUrl,
                  thumbnail_image_url: fileUrl,
                })
              }}
            />
            <Typography color="primary" variant="boldText">
              Upload
            </Typography>
          </Link>
          <Typography className={classes.avatarDivider} variant="body1">
            |
          </Typography>
          <Link onClick={() => setUserAvatar(null)} underline="none">
            <Typography color="primary" variant="boldText">
              Remove
            </Typography>
          </Link>
        </div>
      </div>
      <div>
        <div className={classes.textInputFieldContainer}>
          <TextField
            label="First Name"
            value={firstName || ''}
            onChange={(event) => setFirstName(event.target.value)}
          />
        </div>
        <div className={classes.textInputFieldContainer}>
          <TextField
            label="Last Name"
            value={lastName || ''}
            onChange={(event) => setLastName(event.target.value)}
          />
        </div>
        <div className={classes.textInputFieldContainer}>
          <TextField
            label="Email"
            value={email || ''}
            onChange={(event) => setEmail(event.target.value)}
            helperText={emailError}
            error={!!emailError}
          />
        </div>
        <div className={classes.textInputFieldContainer}>
          <UserRoleSelect
            value={role}
            userType={userTypes.tenantBroker}
            onChange={(event) => setRole(event.target.value)}
          />
        </div>
      </div>
    </ActionModal>
  )
}

export const useAddBrokerToFirmModal = ({ firmUsers, updateUser }) => {
  const [open, setOpen] = useState(false)

  const AddBrokerToFirmModalComponent = (
    <AddBrokerToFirmModal
      key="add-broker-to-firm-modal"
      firmUsers={firmUsers}
      updateUser={updateUser}
      open={open}
      onClose={() => setOpen(false)}
    />
  )

  return {
    setOpen,
    AddBrokerToFirmModalComponent,
  }
}
