import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultListingImageIcon } from '~/legacy/components';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#e0e0e0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'unset',
  },
});

export default function DefaultListingImage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DefaultListingImageIcon />
    </div>
  );
}
