import React from 'react';
import { styled } from '~/legacy/utils/styleHelpers';

export const Scroll = ({ children, ...props }) => (
  <Container {...props}>
    <Content>{children}</Content>
  </Container>
);

const Container = styled('div', {
  position: 'relative',
  flex: 1,
});

const Content = styled('div', {
  overflowY: 'auto',
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,
});
