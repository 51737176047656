import React from 'react'
import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import chunk from 'lodash/chunk'
import get from 'lodash/get'
import clsx from 'clsx'
import { Database } from '../settings/Database'
import { findColumns, findTemplate, mapSurveyBuildingColumn } from './templates'

const baseurl = {
  development: 'http://localhost:6333',
  staging: 'https://staging.leaseup.co',
  production: 'https://app.leaseup.co',
}[process.env.NODE_ENV || 'production']

const sentence = (...words) => {
  return words.flat().filter(Boolean).map(String).join(' ')
}

const useStyles = makeStyles((theme) => {
  const pdf = (a, b) => (theme.isPdf ? a : b)
  const scale = (value) => pdf((value * 17) / 11, value)
  const px = (value) => `${scale(value)}px`

  return {
    container: {
      boxSizing: 'border-box',
      padding: px(25),
      height: pdf('100vh', '100%'),
      width: pdf('100vw', '100%'),
    },
    head: {
      backgroundImage: `url(${baseurl}/static/media/jllPdfHeader.png)`,
      backgroundColor: '#000000',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: px(75),
      position: 'relative',
    },
    title: {
      fontFamily: 'Inter-SemiBold',
      fontSize: px(14),
      lineHeight: px(18),
      color: '#ffffff',
      paddingTop: px(12),
      textAlign: 'center',
    },
    details: {
      fontFamily: 'Inter-Regular',
      fontSize: px(6),
      lineHeight: px(8),
      color: '#ffffff',
      paddingTop: px(6),
      textAlign: 'center',
    },
    legal: {
      fontFamily: 'Inter-Regular',
      fontSize: px(4),
      lineHeight: px(6),
      color: '#ffffff',
      position: 'absolute',
      top: px(10),
      right: px(12),
      width: px(193),
      letterSpacing: px(0.1),
    },
    table: {
      border: '1px solid #111111',
      borderRight: 'none',
    },
    header: {
      padding: px(4),
      display: 'flex',
      fontFamily: 'Inter-Medium',
      fontSize: px(6),
      lineHeight: px(8),
      background: '#999999',
      borderRight: '1px solid #111111',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    cell: {
      padding: px(4),
      display: 'flex',
      borderTop: '1px solid #111111',
      fontFamily: 'Inter-Regular',
      fontSize: px(6),
      lineHeight: px(10),
      borderRight: '1px solid #111111',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      whiteSpace: 'pre',
    },
    left: {
      justifyContent: 'left',
      textAlign: 'left',
      whiteSpace: 'unset',
    },
    attr: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    name: {
      fontWeight: 'bold',
    },
    image: {
      objectFit: 'cover',
      width: px(72),
      border: 'none',
      height: '100%',
      flexShrink: 0,
    },
    foo: {
      flexShrink: 0,
    },
  }
})

function JllTemplatePageContainer(props) {
  const { selectedListingIndex, settings, survey, surveyBuildings } = props
  const template = findTemplate(settings.template)
  const styles = useStyles()

  const broker =
    settings.broker ||
    sentence(get(survey, 'owner.first_name'), get(survey, 'owner.last_name')) ||
    get(survey, 'owner.email')

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <div className={styles.title}>{template.title}</div>
        <div className={styles.details}>
          {settings.client && <div>{`For: ${settings.client}`}</div>}
          <div>Criteria: Toronto Office</div>
          <div>{`By: ${broker}`}</div>
          <div>{`Date: ${format(new Date(), 'MMMM dd, yyyy')}`}</div>
        </div>
        <div className={styles.legal}>
          The information contained in this document/report (including, but not
          limited to, images, charts, assumptions, estimates and projections)
          has been compiled from public or 3rd party sources believed to be
          reliable. In particular, the information contained herein is not
          represented, warranted or guaranteed by JLL. Given the limitations of
          the investigations, enquiries and verification that we have
          undertaken, the reliability and accurateness of this information must
          be treated with a suitable degree of caution and neither JLL nor any
          of their affiliates, employees or independent contractors accept any
          liability or responsibility for the accuracy or completeness of the
          information contained herein and no reliance should be placed on the
          information contained in this document/report.
        </div>
      </div>
      <Database
        className={styles.table}
        columns={findColumns(template.columns)}
        header={(key, column) => (
          <div key={key} className={styles.header}>
            {column.key}
          </div>
        )}
        cell={(key, column, row) => (
          <div
            key={key}
            className={clsx(
              styles.cell,
              column.key === 'Comments' && styles.left,
              column.key === 'Image' && styles.foo
            )}
          >
            {column.key === 'Image' ? (
              <Image
                className={styles.image}
                src={get(row, 'building.hero_photo.thumbnail_image_url')}
              />
            ) : (
              mapSurveyBuildingColumn(column, row)
            )}
          </div>
        )}
        data={
          chunk(surveyBuildings, settings.rowsPerPage)[selectedListingIndex]
        }
      />
    </div>
  )
}

const Image = (props) => {
  const { src, className } = props
  return src ? <img className={className} src={src} alt="" /> : null
}

export default JllTemplatePageContainer
