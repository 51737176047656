import * as Fields from '~/objects/fields'

export { BuildingSize, LeaseType, SpaceNotes } from '~/objects/fields'

export const Area = {
  name: 'Rentable SF',
  type: Fields.Area,
}

export const Available = {
  name: 'Available',
  type: Fields.Text,
}

export const BuildingNotes = {
  ...Fields.BuildingDescription,
  name: 'Building Notes',
}

export const Floor = {
  name: 'Floor',
  type: Fields.Text,
}

export const Landlord = {
  name: 'Landlord',
  type: Fields.Text,
}

export const SubMarket = {
  name: 'Sub Market',
  type: Fields.Text,
}

export const BuildingType = {
  name: 'Building Type',
  type: Fields.Text,
}

export const Floors = {
  name: 'No. of Floors',
  type: Fields.Text,
}

export const YearBuilt = {
  name: 'Year Built',
  type: Fields.Text,
}

export const YearRenovated = {
  name: 'Year Renovated',
  type: Fields.Text,
}

export const Parking = {
  name: 'Parking',
  type: Fields.Text,
}

export const Security = {
  name: 'Security',
  type: Fields.Text,
}

export const Cafe = {
  name: 'Onsite Café',
  type: Fields.Text,
}

export const Conference = {
  name: 'Conference Center',
  type: Fields.Text,
}

export const Gym = {
  name: 'Fitness Center',
  type: Fields.Text,
}

export const Bike = {
  name: 'Bike Storage',
  type: Fields.Text,
}

export const Transit = {
  name: 'Nearest MBTA',
  type: Fields.Text,
}

export const CeilingHeight = {
  name: 'Ceiling Height',
  type: Fields.Text,
}

export const Dock = {
  name: 'Dock Doors',
  type: Fields.Text,
}

export const DriveIn = {
  name: 'Drive-in Doors',
  type: Fields.Text,
}

export const Hvac = {
  name: 'HVAC',
  type: Fields.Text,
}

export const Power = {
  name: 'Power',
  type: Fields.Text,
}

export const NNN = {
  name: 'NNN Expenses',
  type: Fields.Text,
}

export const ClearHeight = {
  name: 'Clear Height',
  type: Fields.Text,
}

export const Occupancy = {
  name: 'Occupancy',
  type: Fields.Text,
}

export const Price = {
  name: 'Base Rent',
  type: Fields.Currency,
}
