import React, { useState } from 'react';
import { Skeleton } from '@material-ui/lab';

function ImageWithSkeleton({ className, src }) {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && <Skeleton className={className} variant="rect" />}
      <img
        className={className}
        style={loaded ? {} : { display: 'none' }}
        src={src}
        onLoad={() => setLoaded(true)}
        alt=""
      />
    </>
  );
}

export default ImageWithSkeleton;
