import { MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import { AddToBuildingIcon } from '~/legacy/components'
import { setAddListingModalState } from '~/legacy/store/actions/viewSurvey'
import { BASE_ICON_STYLES } from './ButtonUtils'

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}))

const AddToBuildingMenuItem = React.forwardRef(
  ({ building, handleMenuClose = () => {}, ...props }, ref) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
      <MenuItem
        ref={ref}
        className={classes.menuItemRoot}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          dispatch(setAddListingModalState({ open: true, building }))
          handleMenuClose()
        }}
        {...props}
      >
        <AddToBuildingIcon className={classes.icon} />
        <Typography className={classes.text}>Add Space to Building</Typography>
      </MenuItem>
    )
  }
)

export default AddToBuildingMenuItem
