/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer } from 'react'
import useSWRImmutable from 'swr/immutable'
import { useEventStore } from '~/libraries/eventstore.react'
import { useDispatcher, useMessages } from '~/libraries/messaging.react'

const replay = Symbol('replay')

class Exception {
  constructor(type, data) {
    this.type = type
    this.data = data
  }
}

export const useProjection = (options, deps) => {
  const eventStore = useEventStore()
  const dispatcher = useDispatcher()

  const [projection, dispatch] = useReducer(
    (current, event) => {
      const state = event.type === replay ? options.state : current.state
      const events = event.type === replay ? event.data : [event]
      const history = event.type === replay ? [] : current.events

      const commit = (type, data = null) => {
        eventStore.write({ type, data, streamId: options.id })
      }

      const reject = (type, data = null) => {
        throw new Exception(type, data)
      }

      return {
        events: [...history, ...events],
        state: events.reduce((state, event) => {
          try {
            const apply = options[event.type]
            if (apply) {
              const result = apply({
                ...event,
                commit,
                dispatch,
                reject,
                state,
              })
              return result ?? state
            }
            return state
          } catch (error) {
            if (error instanceof Exception) dispatcher.send(error)
            else throw error
            return state
          }
        }, state),
      }
    },
    {
      state: options.state,
      events: [],
    }
  )

  useMessages(dispatch)

  const events = useSWRImmutable(options.id, async () => {
    const events = await eventStore.read(options.id)
    dispatch({ type: replay, data: events })
    return events
  })

  useEffect(() => {
    dispatch({ type: replay, data: projection.events })
  }, deps ?? [])

  return { ...projection, dispatch, isValidating: events.isValidating }
}
