import React from 'react'
import { Typography } from '~/legacy/components'
import { styled } from '~/legacy/utils/styleHelpers'

export const Empty = ({ children, icon, ...props }) => (
  <Container {...props}>
    {icon}
    <Text>{children}</Text>
  </Container>
)

const Container = styled(Typography, {
  backgroundColor: '#F3F3F3',
  borderRadius: 4,
  fontWeight: 600,
  paddingTop: 40,
  textAlign: 'center',
  height: 154,
})

Container.defaultProps = {
  variant: 'subSectionTitle',
}

const Text = styled('span', {
  display: 'block',
  marginTop: 2,
})
