import React, { useState } from 'react';
import clsx from 'clsx';
import { Tabs, Tab, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tabs: {
    padding: '0 12px',
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: '#ffffff',
    position: 'sticky',
    width: '100%',
    height: '52px',
  },
  tab: {
    minWidth: 'unset',
  },
  container: {
    padding: '32px',
  },
  layoutsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
  },
  layoutThumb: {
    width: '240px',
    height: '310px',
    padding: '8px',
    marginBottom: '4px',
    border: `1px solid #ffffff`,
  },
  selectedLayoutThumb: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
  },
  layoutThumbGhost: {
    width: '240px',
    height: '155px',
  },
  layoutImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    border: '1px solid #e0e0e0',
    cursor: 'pointer',
  },
}));

function ExportSurveySettingsAndLayout({
  children,
  settings,
  updateSettings,
  layoutThumbs = [],
}) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={selectedTab}
        onChange={(_, newIndex) => setSelectedTab(newIndex)}
        indicatorColor="primary"
      >
        <Tab className={classes.tab} label="Settings" />
        {/* TODO: uncomment for proper release 2 */}
        {/* <Tab className={classes.tab} label="Layout" /> */}
      </Tabs>
      <div className={classes.container}>
        <div hidden={selectedTab !== 0}>{children}</div>
        <div hidden={selectedTab !== 1}>
          <div className={classes.layoutsContainer}>
            {layoutThumbs.map((thumbUrl, index) => (
              <div
                key={thumbUrl}
                id={thumbUrl}
                className={clsx(
                  classes.layoutThumb,
                  settings.layoutIndex === index && classes.selectedLayoutThumb
                )}
              >
                <img
                  src={thumbUrl}
                  className={classes.layoutImage}
                  onClick={() => {
                    updateSettings({
                      ...settings,
                      layoutIndex: index,
                    });
                    if (index === layoutThumbs.length - 1) {
                      const el = document.getElementById('rightBar');
                      el.scrollTo({
                        top: el.scrollHeight,
                        behavior: 'smooth',
                      });
                    } else {
                      document.getElementById(thumbUrl).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      });
                    }
                  }}
                  alt="layout thumbnail"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportSurveySettingsAndLayout;
