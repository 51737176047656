import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, MenuItem } from '~/legacy/components';
import { BASE_ICON_STYLES } from '~/legacy/components/buttons/ButtonUtils';
import { DeleteIcon } from '~/legacy/components/svgs';
import { ConfirmModalComponent } from '~/legacy/components/modalComponents';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
  textInputFieldContainer: {
    width: '100%',
    marginTop: '10px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

// A modal to edit a project
export const DeleteSurveyBuildingMenuItemModal = ({
  surveyBuildingAttachmentId,
  surveyBuildingAttachmentName,
  buildingName,
  deleteSurveyBuildingAttachment,
  open,
  onClose,
}) => {
  const closeModal = () => {
    onClose();
  };

  // Update the project
  const onDeleteConfirm = async () => {
    try {
      closeModal();
      await deleteSurveyBuildingAttachment(surveyBuildingAttachmentId);
    } catch {
      // supress
    }
  };

  return (
    <ConfirmModalComponent
      onClose={closeModal}
      onConfirm={onDeleteConfirm}
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      title="Delete Attachment"
      text={`Are you sure you want to delete ${surveyBuildingAttachmentName} from ${buildingName}? This action can’t be undone.`}
      confirmButtonLabel="Delete"
    />
  );
};

// Menu item to edit a project
export const DeleteSurveyBuildingAttachmentMenuItem = React.forwardRef(
  ({ openModal, ...props }, ref) => {
    const classes = useStyles();

    return [
      <MenuItem
        key="delete-survey-building-attachment-menu-item"
        ref={ref}
        className={classes.menuItemRoot}
        onClick={openModal}
        {...props}
      >
        <DeleteIcon className={classes.icon} />
        <Typography className={classes.text}> Delete Attachment </Typography>
      </MenuItem>,
    ];
  }
);

// Bundle the modal and the menu item together for maximum convenience
export const useDeleteSurveyBuildingAttachmentMenuItem = ({
  surveyBuildingAttachmentId,
  surveyBuildingAttachmentName,
  buildingName,
  deleteSurveyBuildingAttachment,
  handleMenuClose,
}) => {
  const [open, setOpen] = React.useState(false);

  const DeleteSurveyBuildingAttachmentMenuItemComponent = (
    <DeleteSurveyBuildingAttachmentMenuItem openModal={() => setOpen(true)} />
  );

  const DeleteSurveyBuildingAttachmentMenuItemModalComponent = (
    <DeleteSurveyBuildingMenuItemModal
      key="delete-survey-building-menu-modal"
      surveyBuildingAttachmentId={surveyBuildingAttachmentId}
      surveyBuildingAttachmentName={surveyBuildingAttachmentName}
      buildingName={buildingName}
      deleteSurveyBuildingAttachment={deleteSurveyBuildingAttachment}
      open={open}
      onClose={() => {
        setOpen(false);
        handleMenuClose();
      }}
    />
  );

  return {
    DeleteSurveyBuildingAttachmentMenuItemComponent,
    DeleteSurveyBuildingAttachmentMenuItemModalComponent,
  };
};
