import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { SnackbarUtils } from '~/legacy/utils';
import { TextField } from '~/legacy/components';
import ActionModal from '../modals/ActionModal';

export const AddSurveyModal = withStyles({
  textInputFieldContainer: {
    width: '100%',
    marginTop: '10px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '4px',
  },
})(({ classes, addSurvey, open, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [surveyName, setSurveyName] = useState(null);
  const [errors, setErrors] = useState({});

  const hasErrors = !!(errors && Object.keys(errors).length);

  // Validate the entered survey data
  const validate = useCallback(() => {
    const newErrors = {};
    if (!surveyName) {
      newErrors.surveyName = 'You must provide a survey name';
    }
    setErrors(newErrors);
    return newErrors;
  }, [surveyName]);

  useEffect(() => {
    if (!open) {
      setSurveyName(null);
    }
  }, [open]);

  // If there are already errors and the user changes the survey info, re-validate. ie: The user is trying to fix
  //    the existing errors.
  useEffect(() => {
    if (hasErrors) {
      validate();
    }
  }, [hasErrors, surveyName, validate]);

  const closeModal = () => {
    onClose();
  };

  // Create a survey
  const createSurveyApi = async () => {
    setIsLoading(true);

    // Early escape if any validation of fields issue
    const newErrors = validate();
    if (Object.values(newErrors).length) {
      setIsLoading(false);
      return;
    }

    try {
      await addSurvey({ name: surveyName });
      SnackbarUtils.success('Survey Successfully Created.');
      closeModal();
    } catch {
      // supress
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ActionModal
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onConfirm={createSurveyApi}
      title="Add New Survey"
      confirmButtonLabel="Add"
      disableAction={hasErrors || isLoading}
      loading={isLoading}
    >
      <div className={classes.modalContent}>
        <div className={classes.textInputFieldContainer}>
          <TextField
            autoFocus
            label="Survey Name"
            placeholder="Enter a name"
            value={surveyName || ''}
            onChange={(event) => setSurveyName(event.target.value)}
            helperText={errors.surveyName || null}
            error={!!errors.surveyName}
          />
        </div>
      </div>
    </ActionModal>
  );
});

// Bundle the modal and the menu item together for maximum convenience
export const useAddSurveyModal = (addSurvey) => {
  const [open, setOpen] = React.useState(false);

  const AddSurveyModalComponent = (
    <AddSurveyModal
      key="add-survey-modal"
      addSurvey={addSurvey}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    />
  );

  return {
    open,
    setOpen,
    AddSurveyModalComponent,
  };
};
