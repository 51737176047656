import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles({
  container: {
    '& + &': {
      marginTop: '32px',
    },
  },
  label: {
    color: '#666666',
    marginBottom: -2,
  },
})

export const Group = ({ children, className, label }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.container, className)}>
      {label && (
        <Typography className={classes.label} variant="h5">
          {label}
        </Typography>
      )}
      {children}
    </div>
  )
}
