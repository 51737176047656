import { createMuiTheme } from '@material-ui/core'
import { mergeSkin } from './skinUtils'

export const generateSkinnedMuiTheme = (skin) => {
  const mergedSkin = mergeSkin(skin)

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: mergedSkin.brand_color,
        success: '#1E847A',
        warning: 'yellow', // placeholder
        info: 'aqua', // placeholder
        error: '#DD421A',
      },
      secondary: {
        main: '#ffffff',
      },
      text: {
        primary: '#111111',
      },
      background: {
        default: '#ffffff',
      },
      mapPins: {
        // Fallback on brand color for non-active stroke/fill
        stroke: mergedSkin.map_pin_stroke_color || mergedSkin.brand_color,
        fill: mergedSkin.map_pin_fill_color || mergedSkin.brand_color,
        activeStroke: mergedSkin.map_pin_highlighted_stroke_color,
        activeFill: mergedSkin.map_pin_highlighted_fill_color,
      },
    },
    typography: {
      h1: {
        fontFamily: ['Inter-SemiBold'],
        fontSize: 32,
      },
      h2: {
        fontFamily: ['Inter-SemiBold'],
        fontSize: 24,
      },
      h3: {
        fontFamily: ['Inter-SemiBold'],
        fontSize: 14,
        lineHeight: 1.5,
      },
      h5: {
        fontFamily: ['Inter-Medium'],
        fontSize: 12,
        lineHeight: 1,
        letterSpacing: '.2px',
      },
      h6: {
        fontFamily: ['Inter-SemiBold'],
        fontSize: 10,
      },
      body1: {
        fontFamily: ['Inter-Regular'],
        fontSize: 14,
        lineHeight: 1.5,
      },
      body2: {
        fontFamily: ['Inter-Regular'],
        fontSize: 12,
      },
      button: {
        fontFamily: ['Inter-SemiBold'],
        textTransform: 'capitalize',
        letterSpacing: '0.1px',
      },
    },
    custom: {
      secondaryText: {
        color: 'gray',
      },
      iconGray: {
        color: '#707070',
      },
    },
  })

  theme.breakpoints.values.sdpMaxWidth = 1424

  const disabledTextColor = '#808080'
  const disabledBorderColor = '#e0e0e0'
  theme.overrides = {
    MuiButton: {
      contained: {
        '&.Mui-disabled': {
          color: '#666666',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#666666',
        '&.Mui-disabled': {
          color: disabledTextColor,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&.Mui-disabled': {
          color: disabledBorderColor,
        },
      },
    },
    MuiLink: {
      root: {
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
    },
    MuiInput: {
      input: {
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #e0e0e0',
        },
        '&:after': {
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #111111',
        },
        '&.Mui-disabled:before': {
          borderBottom: `1px solid ${disabledBorderColor}`,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          color: disabledTextColor,
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        color: '#666666',
        fontSize: '16px',
        backgroundColor: '#ffffff',
        paddingRight: '5px',
        letterSpacing: '.1px',
        lineHeight: 1,
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          fontFamily: ['Inter-Medium'],
          marginTop: '4px',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: '#666666',
        },
      },
      notchedOutline: {
        borderWidth: '1px !important',
      },
      input: {
        '&::placeholder': {
          lineHeight: 'normal',
        },
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontFamily: ['Inter-SemiBold'],
        fontSize: '10px',
        letterSpacing: '.1px',
      },
    },
    MuiListItem: {
      root: {
        fontFamily: ['Inter-SemiBold'],
        fontSize: 14,
        height: '38px',
      },
      button: {
        '&:hover': {
          backgroundColor: '#f3f3f3',
        },
      },
      gutters: {
        paddingLeft: '20px',
        paddingRight: '12px',
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: ['Inter-SemiBold'],
        fontSize: 14,
      },
    },
  }
  return theme
}
