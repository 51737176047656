import React, { Fragment } from 'react'
import { uniqBy } from 'lodash'
import { Font } from '~/components/Font'
import { Spacer } from '~/components/Spacer'
import { styled } from '~/libraries/styled'
import { CheckboxSetting } from '~/pdfs/patterns/CheckboxSetting'
import { Group } from '~/pdfs/patterns/Group'
import { Photo } from '~/pdfs/patterns/Photo'
import { Settings } from '~/pdfs/patterns/Settings'
import { TextSetting } from '~/pdfs/patterns/TextSetting'
import { UploadSetting } from '~/pdfs/patterns/UploadSetting'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { getBrandLogo } from '~/support/getBrandLogo'
import { LeaseUpContent } from '../patterns/LeaseUpContent'
import { LeaseUpPage } from '../patterns/LeaseUpPage'

export const LeaseUpCover = definePageTemplate({
  id: '542a88e5-991f-4575-8785-100830605d68',

  name: 'Cover',

  props: (pdf) => {
    const users = uniqBy(pdf.data.users, 'id')
    const brokers = users.filter((user) => user.roles.includes('Broker'))
    return {
      id: pdf.data.id,
      company: pdf.data.company,
      title: pdf.data.name,
      subtitle: pdf.settings.subtitle,
      image: pdf.settings['cover.image'],
      logo: getBrandLogo(),
      users: brokers.map((user) => ({
        id: `${user.id}.cover`,
        label: user.name,
        details: user.email,
        value: pdf.settings[`${user.id}.cover`],
      })),
    }
  },

  Page: (props) => {
    const users = props.users.filter((user) => {
      return user.value === true
    })

    if (props.image) {
      return <Photo src={props.image.url} width="100%" height="100%" />
    }

    return (
      <Page>
        <Photo
          src="https://leaseup-production.s3.us-east-2.amazonaws.com/pdf_cover_small.jpg"
          grow
        />

        <LeaseUpContent>
          {users.length > 0 && (
            <Fragment>
              <Font format="10/16/600">{props.company}</Font>
              <Spacer height={20} />
            </Fragment>
          )}

          <Font format="28/28/600">{props.title}</Font>

          {props.subtitle && (
            <Font format="18/22/600" color="#666666">
              {props.subtitle}
            </Font>
          )}

          <Spacer height={20} />

          {users.map((user) => (
            <Broker key={`${user.id}.display`}>
              <Font format="10/16/600">{user.label}</Font>
              <Font format="10/16">{user.details}</Font>
            </Broker>
          ))}

          {users.length === 0 && (
            <Font format="14/21/600">{props.company}</Font>
          )}

          <Logo src={props.logo} />
        </LeaseUpContent>
      </Page>
    )
  },

  Settings: (props) => (
    <Settings>
      <Group label="Replace Cover With Image">
        <UploadSetting
          id="cover.image"
          name={props.image?.name}
          placeholder="Upload Image"
          survey={props.id}
        />
      </Group>

      {!props.image && (
        <Fragment>
          <TextSetting
            id="subtitle"
            label="Subtitle"
            placeholder="Add a subtitle"
            value={props.subtitle}
          />

          <Group label="Brokers">
            {props.users.map((user) => (
              <CheckboxSetting key={`${user.id}.field`} {...user} />
            ))}
          </Group>
        </Fragment>
      )}
    </Settings>
  ),
})

const Page = styled(LeaseUpPage, {
  display: 'flex',
  flexDirection: 'column',
})

const Broker = styled('div', {
  '& + &': {
    marginTop: '14px',
  },
})

const Logo = styled('img', {
  position: 'absolute',
  bottom: '24px',
  right: '24px',
  maxHeight: '24px',
  maxWidth: '192px',
})
