import lodash from 'lodash';
import { DEFAULT_SKIN } from '~/legacy/consts';

export const getSkinS3Object = (subDomain) =>
  `https://${process.env.APP_SKINS_BUCKET}.s3.us-east-2.amazonaws.com/${subDomain}.json`;

export const mergeSkin = (customSkin) => {
  return lodash.mergeWith({}, DEFAULT_SKIN, customSkin, (a, b) =>
    b === null ? a : undefined
  );
};
