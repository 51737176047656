import React, { useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { Typography, makeStyles } from '@material-ui/core';
import { SmallCheckIcon, SmallXIcon } from '~/legacy/components';

const useStyles = makeStyles({
  passwordRequirements: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px',
    width: '100%',
  },
  passwordRequirementsTitle: {
    marginLeft: '14px',
    lineHeight: '21px',
  },
  passwordRequirement: {
    display: 'flex',
    alignItems: 'center',
    height: '21px',
  },
  passwordRequirementLabel: {
    marginLeft: '6px',
  },
  passwordRequirementLabelNoIndicator: {
    marginLeft: '14px',
  },
});

const PasswordRequirement = ({ label, isFulfilled, showIndicator = true }) => {
  const classes = useStyles();
  return (
    <div className={classes.passwordRequirement}>
      {showIndicator && isFulfilled && <SmallCheckIcon />}
      {showIndicator && !isFulfilled && <SmallXIcon />}
      <Typography
        className={clsx(
          showIndicator
            ? classes.passwordRequirementLabel
            : classes.passwordRequirementLabelNoIndicator
        )}
        variant="h5"
      >
        {label}
      </Typography>
    </div>
  );
};

const PasswordRequirements = ({
  className,
  password,
  confirmPassword,
  setButtonDisabled,
}) => {
  const classes = useStyles();

  const requirements = useMemo(
    () => [
      { label: '1 uppercase letter', isFulfilled: /[A-Z]/.test(password) },
      { label: '1 lowercase letter', isFulfilled: /[a-z]/.test(password) },
      { label: '1 number', isFulfilled: /[0-9]/.test(password) },
      { label: '8 total characters', isFulfilled: password.length >= 8 },
      {
        label: 'Passwords match',
        isFulfilled: password.length ? password === confirmPassword : false,
      },
    ],
    [password, confirmPassword]
  );

  useEffect(() => {
    // if there are unmnet requirements and the button is not disabled, disable it
    // if there are NOT unmet requiremets, and the button is disabled, enable it
    setButtonDisabled(requirements.some((req) => !req.isFulfilled));
  }, [requirements]);

  return (
    <div className={clsx(classes.passwordRequirements, className)}>
      <Typography className={classes.passwordRequirementsTitle} variant="h5">
        Password requirements:
      </Typography>
      {requirements.map((requirement) => (
        <PasswordRequirement
          key={requirement.label}
          label={requirement.label}
          isFulfilled={requirement.isFulfilled}
          showIndicator={
            requirement.label === 'Passwords match'
              ? !!(password.length && confirmPassword.length)
              : !!password.length
          }
        />
      ))}
    </div>
  );
};

export default PasswordRequirements;
