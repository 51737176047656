import React, { useState, useCallback } from 'react';

import { useDeleteSurveyBuildingAttachmentMenuItem } from '~/legacy/components';
import { Menu } from './Menu';

export const useSurveyBuildingAttachmentMenu = (
  buildingName,
  deleteSurveyBuildingAttachment = async () => {}
) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState({});
  const selectedSurveyBuildingAttachmentId =
    anchorMenuEl && anchorMenuEl.surveyBuildingAttachmentId
      ? anchorMenuEl.surveyBuildingAttachmentId
      : null;
  const selectedSurveyBuildingAttachmentName =
    anchorMenuEl && anchorMenuEl.surveyBuildingAttachmentName
      ? anchorMenuEl.surveyBuildingAttachmentName
      : null;

  const handleMenuClick = useCallback(
    (event, surveyBuildingAttachmentId, surveyBuildingAttachmentName) => {
      setAnchorMenuEl({
        anchor: event.currentTarget,
        surveyBuildingAttachmentId,
        surveyBuildingAttachmentName,
      });
    },
    []
  );

  const handleMenuClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorMenuEl({ ...anchorMenuEl, anchor: null });
  };

  // Delete survey buidling attachment Menu Item
  const {
    DeleteSurveyBuildingAttachmentMenuItemComponent,
    DeleteSurveyBuildingAttachmentMenuItemModalComponent,
  } = useDeleteSurveyBuildingAttachmentMenuItem({
    surveyBuildingAttachmentId: selectedSurveyBuildingAttachmentId,
    surveyBuildingAttachmentName: selectedSurveyBuildingAttachmentName,
    buildingName,
    deleteSurveyBuildingAttachment,
    handleMenuClose,
  });

  const SurveyBuildingAttachmentMenuComponent = (
    <SurveyBuildingAttachmentMenu
      anchorMenuEl={anchorMenuEl ? anchorMenuEl.anchor : null}
      handleMenuClose={handleMenuClose}
      DeleteSurveyBuildingAttachmentMenuItemComponent={
        DeleteSurveyBuildingAttachmentMenuItemComponent
      }
    />
  );

  return {
    SurveyBuildingAttachmentMenuComponent,
    handleMenuClick,
    DeleteSurveyBuildingAttachmentMenuItemModalComponent,
  };
};

export const SurveyBuildingAttachmentMenu = ({
  anchorMenuEl,
  handleMenuClose,
  DeleteSurveyBuildingAttachmentMenuItemComponent,
}) => {
  return (
    <Menu
      id="more-options-menu"
      anchorEl={anchorMenuEl}
      open={!!anchorMenuEl}
      onClose={handleMenuClose}
      getContentAnchorEl={null}
    >
      {DeleteSurveyBuildingAttachmentMenuItemComponent}
    </Menu>
  );
};
