import React from 'react'
import { EditLocationForm } from './EditLocationForm'
import { QuickDrawer } from './QuickDrawer'

export const EditLocationDrawer = (props) => {
  return (
    <QuickDrawer
      title="Edit building location"
      open={props.open}
      onClose={props.onClose}
    >
      {props.building && (
        <EditLocationForm
          building={props.building}
          onChange={props.onChange}
          padding="32px 24px"
        />
      )}
    </QuickDrawer>
  )
}
