const ACTIONS = {
  SET_BULK_LISTINGS: 'set_bulk_listings',
  SET_CURRENT_BULK_LISTING: 'set_current_bulk_listing',
  UPDATE_LISTING_VALUES: 'update_listing_values',
  OVERRIDE_LISTING_VALUES: 'override_listing_values',
};

const getInitialState = () => ({
  listings: [],
  current: 0,
  formData: {},
});

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.SET_BULK_LISTINGS: {
      return { ...state, listings: action.payload };
    }
    case ACTIONS.SET_CURRENT_BULK_LISTING: {
      return {
        ...state,
        current: action.payload,
        formData: { ...state.listings[action.payload] },
      };
    }
    case ACTIONS.UPDATE_LISTING_VALUES: {
      return {
        ...state,
        listings: state.listings.map((listing, index) => {
          if (index === action.payload.index) {
            return {
              listing: {
                ...listing.listing,
                ...action.payload.newValues.listing,
              },
              building: {
                ...listing.building,
                ...action.payload.newValues.building,
              },
              additional_fields: {
                ...listing.additional_fields,
                ...action.payload.newValues.additional_fields,
              },
            };
          }
          return listing;
        }),
      };
    }
    case ACTIONS.OVERRIDE_LISTING_VALUES: {
      return {
        ...state,
        listings: state.listings.map((listing, index) => {
          if (index === action.payload.index) {
            return {
              listing: action.payload.newValues.listing
                ? { ...action.payload.newValues.listing }
                : { ...listing.listing },
              building: action.payload.newValues.building
                ? { ...action.payload.newValues.building }
                : { ...listing.building },
              additional_fields: action.payload.newValues.additional_fields
                ? { ...action.payload.newValues.additional_fields }
                : { ...listing.additional_fields },
            };
          }
          return listing;
        }),
      };
    }
    default:
      return state;
  }
}

export const bulkListingsActions = {
  setBulkListings: payload => ({
    type: ACTIONS.SET_BULK_LISTINGS,
    payload,
  }),
  setCurrent: payload => ({
    type: ACTIONS.SET_CURRENT_BULK_LISTING,
    payload,
  }),
  updateListingValues: payload => ({
    type: ACTIONS.UPDATE_LISTING_VALUES,
    payload,
  }),
  overrideListingValues: payload => ({
    type: ACTIONS.OVERRIDE_LISTING_VALUES,
    payload,
  }),
};
