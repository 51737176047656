import React, { useState } from 'react'
import { styled } from '~/legacy/utils/styleHelpers'
import {
  SimpleMenu,
  TextChevronDown,
  TextChevronUp,
  Typography,
} from '~/legacy/components'

export const SimpleSelect = ({
  children,
  item,
  label,
  menu,
  options,
  position = 'left',
  onSelect,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (option) => {
    if (onSelect) onSelect(option)
    handleClose()
  }

  return (
    <div {...props}>
      <Button onClick={handleOpen}>
        {label}
        {open ? <TextChevronUp /> : <TextChevronDown />}
      </Button>

      <SimpleMenu
        autoFocus={false}
        anchorEl={anchorEl}
        MenuListProps={menu}
        onClose={handleClose}
        open={open}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: position }}
        transformOrigin={{ vertical: 'top', horizontal: position }}
        elevation={2}
        onSelect={handleSelect}
        options={options}
        item={item}
      >
        {children}
      </SimpleMenu>
    </div>
  )
}

const Button = styled(Typography, (props) => ({
  alignItems: 'center',
  userSelect: 'none',
  display: 'inline-flex',
  cursor: 'pointer',
  height: 36,
  color: props.get('theme.palette.primary.main'),
}))

Button.defaultProps = {
  variant: 'boldText',
}
