import React from 'react'
import {
  withStyles,
  FormControl,
  TextField as MuiTextField,
} from '@material-ui/core'
import clsx from 'clsx'
import { ClearTextInputSvg } from '~/legacy/components'

export const TextField = withStyles({
  container: {
    marginTop: '10px',
  },
  close: {
    cursor: 'pointer',
    color: '#e0e0e0',
    height: '14px',
    width: '14px',
  },
})(({ classes, className, nullable, value, onChange, ...attrs }) => (
  <FormControl className={clsx(classes.container, className)} fullWidth>
    <MuiTextField
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: nullable && value && (
          <ClearTextInputSvg
            className={classes.close}
            onClick={(event) => {
              event.stopPropagation()
              onChange('')
            }}
          />
        ),
      }}
      {...attrs}
    />
  </FormControl>
))
