import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Select, Button } from '~/legacy/components';
import { ModalTitle } from '~/legacy/components/modals';

import { useApiHelper } from '~/legacy/utils/hooks';
import { SURVEY_NAMING } from '~/legacy/consts';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    paddingBottom: 10,
    outline: 'none',
    overflowY: 'hidden',
    width: '400px',
  },
  buttonsContainer: {
    marginTop: '30px',
  },
  button: {
    marginRight: '20px',
  },
  select: {
    marginTop: '12px',
  },
});

function AddToProjectModal({ onClose, listingId, address }) {
  const classes = useStyles();
  // The selected project id and controlled state of the project dropdown
  const [selectedProjectId, setSelectedProjectId] = useState('');
  // Error text for the dropdown
  const [errorText, setErrorText] = useState('');

  const projects = useSelector((s) => {
    return s.api.getSurveys.data ? s.api.getSurveys.data.data : null;
  });
  const apiHelper = useApiHelper();

  // Sort the projects alphabetically and prepare them in dropdown format
  const sortedProjects = [...projects].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const projectOptions = sortedProjects.map((value) => {
    return { label: value.name, value: value.id };
  });

  // Assign the listing to the project (the client)
  const handleSubmit = () => {
    if (listingId && selectedProjectId) {
      apiHelper.assignListingToProject(selectedProjectId, listingId);
      onClose();
    } else {
      setErrorText(`You must select a ${SURVEY_NAMING.toLowerCase()}`);
    }
  };

  return (
    <div className={classes.content}>
      <ModalTitle onClose={onClose} title="Add Space To Survey" />

      <Typography variant="body1">
        Please select a 
        {' '}
        {SURVEY_NAMING.toLowerCase()}
        {' for '}
        <Typography variant="h3" component="span">
          {address}
        </Typography>
      </Typography>

      {/* Project (client) dropdown */}
      <Select
        value={selectedProjectId}
        options={projectOptions}
        onChange={(event) => setSelectedProjectId(event.target.value)}
        errorText={errorText}
        className={classes.select}
        label={SURVEY_NAMING}
        required
      />

      {/* Submit and cancel buttons */}
      {/* TODO: generalize? */}
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          color="primary"
          onClick={() => handleSubmit()}
          disabled={!selectedProjectId}
        >
          Submit
        </Button>
        <Button className={classes.button} onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
AddToProjectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  listingId: PropTypes.number,
  address: PropTypes.string.isRequired,
};
AddToProjectModal.defaultProps = {
  listingId: null,
};

export default AddToProjectModal;
