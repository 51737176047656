import React from 'react';
import { Breadcrumbs } from '~/legacy/components';

/**
 * Build the breadcrumb for the Project flow
 * ie:
 *   DEFAULT
 *     {client_name} > {project_name}
 */
export const ProjectBreadcrumb = ({
  projectName = null,
  clientCompanyName = null,
}) => {
  const pieces = [];

  // {client_name} > {project_name}
  // TODO: Client Path
  pieces.push({ path: 'TODO', name: clientCompanyName });
  pieces.push({
    name: projectName,
  });

  return <Breadcrumbs pieces={pieces} />;
};
