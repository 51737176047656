import React, { useMemo, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { updateUserData } from '~/legacy/store';
import { useApiHelper } from '~/legacy/utils/hooks';
import {
  formatPhoneNumber,
  validatePhoneNumber,
  parseUserName,
} from '~/legacy/utils';
import { toast } from '~/legacy/utils/notifications';
import {
  UserAvatar,
  ModalEditableField,
  Link,
  Typography,
} from '~/legacy/components';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '48px',
  },
  avatar: {
    alignSelf: 'center',
  },
  avatarDivider: {
    color: '#e0e0e0',
    margin: '4px',
    cursor: 'default',
  },
  avatarLinksContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
  },
  photoTitle: {
    color: '#666666',
    marginBottom: '16px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  formField: {
    marginBottom: '32px',
    width: '475px',
  },
});

export default function ProfileTab() {
  const classes = useStyles();
  const apiHelper = useApiHelper();
  const dispatch = useDispatch();
  const user = useSelector((s) => s.user);
  const avatarUploadRef = useRef(null);

  const fields = useMemo(
    () => [
      {
        label: 'Name',
        modalSubtitle: 'Enter your new name and password to save.',
        value: `${user.firstName || ''} ${user.lastName || ''}`,
        format: parseUserName,
      },
      {
        label: 'Email',
        modalSubtitle: 'Enter your new email and password to save.',
        value: user.email,
        format: (text) => ({ email: text, username: text }),
      },
      {
        label: 'Password',
        modalSubtitle:
          'Enter your current password and new password to change. Please make your new password at least 8 characters.',
        type: 'password',
        value: '•••••••••••••••',
      },
      {
        label: 'Phone',
        inputLabel: 'Phone Number',
        modalSubtitle: 'Enter your new phone number and password to save.',
        type: 'number',
        value: formatPhoneNumber(user.phone),
        format: (text) => ({ phone: text }),
        validate: validatePhoneNumber,
      },
    ],
    [user]
  );

  return (
    <div className={classes.container}>
      <div className={classes.avatarContainer}>
        <Typography className={classes.photoTitle} variant="h5">
          Photo
        </Typography>
        <UserAvatar className={classes.avatar} user={user} size="large" />
        <div className={classes.avatarLinksContainer}>
          <Link onClick={() => avatarUploadRef.current.click()}>
            <input
              ref={avatarUploadRef}
              accept="image/jpeg,image/png,image/webp"
              type="file"
              hidden
              onChange={(event) => {
                apiHelper
                  .uploadUserAvatar({
                    file: event.target.files[0],
                    userId: user.id,
                  })
                  .then((res) => {
                    if (res.success) {
                      dispatch(
                        updateUserData({
                          userAvatarImage: res.file,
                        })
                      );
                    } else {
                      toast('Unable to upload photo.', {
                        appearance: 'error',
                      });
                    }
                  });
              }}
            />
            <Typography className={classes.uploadPhoto} variant="link">
              Upload
            </Typography>
          </Link>
          <Typography className={classes.avatarDivider} variant="body1">
            |
          </Typography>
          <Link
            onClick={() => {
              if (user.userAvatarImage && user.userAvatarImage.id) {
                apiHelper
                  .removeUserAvatar({ fileId: user.userAvatarImage.id })
                  .then((res) => {
                    if (res.ok) {
                      dispatch(
                        updateUserData({
                          userAvatarImage: null,
                        })
                      );
                    } else {
                      toast('Unable to remove photo.', {
                        appearance: 'error',
                      });
                    }
                  });
              }
            }}
          >
            <Typography variant="link">Remove</Typography>
          </Link>
        </div>
      </div>
      <div className={classes.formContainer}>
        {fields.map((field) => (
          <ModalEditableField
            key={field.label}
            className={classes.formField}
            label={field.label}
            modalSubtitle={field.modalSubtitle}
            type={field.type}
            value={field.value}
            format={field.format}
            validate={field.validate}
          />
        ))}
      </div>
    </div>
  );
}
