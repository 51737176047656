import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  scoreContainer: {
    display: 'block',
    width: '100%',
    margin: '5px 0 5px 0',
  },
  textContainer: {
    display: 'block',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});

function ScoreBadge({
  scoreName,
  scoreSvg,
  titleVariant,
  description,
  helpLink,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link href={helpLink} underline="none" color="inherit">
        <div className={classes.titleContainer}>
          <Typography align="center" variant={titleVariant}>
            {scoreName}
          </Typography>
          <Typography variant="body1">&reg;</Typography>
        </div>
        <div className={classes.scoreContainer}>{scoreSvg}</div>
        {description && (
          <div className={classes.textContainer}>
            <Typography variant="body1">{description}</Typography>
          </div>
        )}
      </Link>
    </div>
  );
}
ScoreBadge.propTypes = {
  scoreName: PropTypes.string.isRequired,
  scoreSvg: PropTypes.element.isRequired,
  titleVariant: PropTypes.string,
  description: PropTypes.string,
  helpLink: PropTypes.string.isRequired,
};
ScoreBadge.defaultProps = {
  titleVariant: 'h2',
  description: null,
};

export default ScoreBadge;
