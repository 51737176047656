import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import React, { useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { hot } from 'react-hot-loader'
import { Provider as ReduxProvider } from 'react-redux'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { Firm } from '~/services/Firm'
import { FirmFromSubdomain } from '~/services/providers/FirmFromSubdomain'
import { useUserHeartbeat } from './utils/hooks/useUserHeartbeat'

import {
  captureSentryExceptionError,
  init,
  IsTenantPreviewContextProvider,
  SkinContext,
} from '~/legacy/utils'

import { GeneralEncounteredError, SkinnedFavicon } from '~/legacy/components'
import { useOverlayReactor } from '~/support/useOverlayReactor'
import AppRouter from './AppRouter'
import { useSkinnedTheme } from './utils/hooks/useSkinnedTheme'
import { SnackbarUtilsConfigurator } from './utils/snackbarUtils.jsx'

posthog.init(process.env.APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.APP_PUBLIC_POSTHOG_HOST,
  person_profiles: 'identified_only',
  capture_pageview: false,
  disable_session_recording: process.env.DEV,
})

const App = () => (
  <PostHogProvider client={posthog}>
    <FirmFromSubdomain>
      <Something />
    </FirmFromSubdomain>
  </PostHogProvider>
)

const Something = () => {
  const { store } = init()

  if (location.pathname === '/dashboard' || location.pathname === '/surveys') {
    location.href = '/home'
  }

  return (
    <ReduxProvider store={store}>
      <ThemeController />
    </ReduxProvider>
  )
}

const useStyles = makeStyles({
  base: (currentTheme) => ({ ...currentTheme.typography.body1 }),
  container: { zIndex: 12000, top: 60 }, // values relative to top nav
  content: { display: 'flex', justifyContent: 'center' },
})

// TODO: should implement this as a provider to simplify... let's make sure this fixes though
const ThemeController = () => {
  const { subdomain: subDomain } = useContext(Firm)
  const { theme, skin, updateSkin } = useSkinnedTheme({ subDomain })
  const classes = useStyles(theme)
  useUserHeartbeat()
  useOverlayReactor()

  if (!theme) return null

  return (
    <ErrorBoundary
      FallbackComponent={() => <GeneralEncounteredError />}
      onError={(error) => captureSentryExceptionError(error)}
    >
      <SkinContext.Provider value={{ skin, updateSkin }}>
        <ThemeProvider theme={theme}>
          <SkinnedFavicon />
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            classes={{
              containerRoot: classes.container,
              contentRoot: classes.base,
              root: classes.content,
            }}
          >
            <SnackbarUtilsConfigurator />
            <IsTenantPreviewContextProvider>
              <AppRouter />
            </IsTenantPreviewContextProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </SkinContext.Provider>
    </ErrorBoundary>
  )
}

export default process.env.DEV ? hot(module)(App) : App
