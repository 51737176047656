import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { File } from '~/legacy/components'
import { Typography } from '~/legacy/components/themeComponents'
import { getBuildingTertiaryName } from '~/legacy/utils'

const useStyles = makeStyles({
  buildingContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F9F9F9',
    width: '100%',
    height: '80px',
    borderRadius: '4px',
  },
  buildingContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    padding: '16px 14px',
  },
  buildingImageContainer: {
    display: 'flex',
    width: '48px',
    height: '48px',
    minWidth: '48px',
    marginRight: '16px',
  },
  buildingAddressContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
})

export const BuildingTitleCard = (props) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.buildingContainer, props.className)}>
      <div className={classes.buildingContent}>
        <div className={classes.buildingImageContainer}>
          <File
            file={props.building.hero_photo || {}}
            skipUploading
            showAsThumbnail
            useMissingBuildingImage
            missingFileStyleOverrides={{
              backgroundColor: '#E0E0E0',
              color: '#666666',
            }}
          />
        </div>
        <div className={classes.buildingAddressContainer}>
          <Typography>{props.building.address}</Typography>
          <Typography>{getBuildingTertiaryName(props.building)}</Typography>
        </div>
      </div>
    </div>
  )
}
