import React from 'react'
import { Group } from './Group'
import { Checkbox } from './Checkbox'

export const PickList = ({ className, items, label, value, onChange }) => (
  <Group className={className} label={label}>
    {items.map((item) => (
      <Checkbox
        key={item.value}
        label={item.label}
        details={item.details}
        value={item.value === value}
        onChange={() => {
          if (item.value !== value) onChange(item.value)
        }}
      />
    ))}
  </Group>
)
