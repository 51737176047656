/* eslint-disable no-nested-ternary */
import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { UploadIcon, ClearTextInputSvg } from '~/legacy/components'
import { useSend } from '~/support/useSend'
import { styled } from '~/libraries/styled'
import { Font } from '~/components/Font'

export const Upload = (props) => {
  const send = useSend()

  return (
    <Container
      htmlFor="upload"
      onClick={(event) => {
        if (props.loading) {
          event.preventDefault()
          event.stopPropagation()
        }
      }}
    >
      <Font format="14/21" color={!props.name && '#666666'} truncate grow>
        {props.name ?? props.placeholder}
      </Font>

      <Icon>
        {props.loading ? (
          <CircularProgress size={16} />
        ) : props.name ? (
          <Clear
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              send('ClearUpload')
            }}
          />
        ) : (
          <UploadIcon />
        )}
      </Icon>

      <Input
        id="upload"
        type="file"
        accept="image/*"
        placeholder={props.placeholder}
        onChange={async (event) => {
          send('Upload', { files: event.target.files })
        }}
      />
    </Container>
  )
}

const Container = styled('label', {
  alignItems: 'center',
  borderBottom: '1px solid #e0e0e0',
  display: 'flex',
  height: '34px',
  marginTop: '10px',
})

const Icon = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '#111111',
  flexShrink: 0,
})

const Clear = styled(ClearTextInputSvg, {
  transform: 'scale(57.5%)',
  color: '#e0e0e0',
  cursor: 'pointer',
  marginRight: '-4px',
})

const Input = styled('input', {
  display: 'none',
})
