import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    padding: '36px',
  },

  title: {
    borderTop: '3px solid #FFB600',
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '14px',
    color: '#001E5A',
    padding: '15px 0',
  },
})

export const CresaPage = ({ children, title }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      {children}
    </div>
  )
}
