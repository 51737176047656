import { useProjection } from '~/support/useProjection'

export const usePdf = (templates, data) => {
  const template = templates[0]

  const pdf = useProjection(
    {
      id: `pdf.${data.id}.${template?.id}`,

      state: {
        settings: template?.settings?.state ?? {},
        excluded: [],
        template,
        history: {},
        data,
      },

      // commands
      AddPageType: ({ state, data, commit, reject }) => {
        const page = state.excluded.find((page) => page.id === data.pageId)
        if (!page) reject('PageTypeWasAlreadyAdded')
        commit('PageTypeWasAdded', data)
      },

      AddSettings: ({ data, commit }) => {
        commit('SettingsWereAdded', data)
      },

      RemovePageType: ({ state, data, commit, reject }) => {
        const page = state.excluded.find((page) => page.id === data.pageId)
        if (page) reject('PageTypeWasNotAdded')
        commit('PageTypeWasRemoved', data)
      },

      RemoveSettings: ({ data, commit }) => {
        commit('SettingsWereRemoved', [data].flat(3).filter(Boolean))
      },

      SelectTemplate: ({ data, state, commit, reject }) => {
        if (state.template.id === data.templateId)
          reject('TemplateWasAlreadySelected')
        commit('TemplateWasSelected', data)
      },

      // events
      PageTypeWasAdded: ({ data, state }) => {
        const excluded = state.excluded.filter(
          (page) => page.id !== data.pageId
        )
        return { ...state, excluded }
      },

      PageTypeWasRemoved: ({ data, state }) => {
        const page = state.template.pages.find(
          (page) => page.id === data.pageId
        )
        return { ...state, excluded: [...state.excluded, page] }
      },

      SettingsWereAdded: ({ data, state }) => {
        return { ...state, settings: { ...state.settings, ...data } }
      },

      SettingsWereRemoved: ({ data, state }) => {
        const settings = { ...state.settings }
        data.forEach((key) => delete settings[key])
        return { ...state, settings }
      },

      TemplateWasSelected: ({ data, state }) => {
        const template = templates.find((template) => {
          return template.id === data.templateId
        })

        if (!template) {
          return state
        }

        return {
          ...state,
          settings:
            state.history[template.id] ?? template.settings?.state ?? {},
          excluded: [],
          template,
          history: {
            ...state.history,
            [state.template.id]: state.settings,
          },
        }
      },
    },
    [data]
  )

  return pdf
}
