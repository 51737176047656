import {
  LEASE_LISTINGS,
  LEASE_VIEW_LISTINGS,
  LEASE_SAVED_LISTINGS,
  LEASE_SAVED_SEARCHES_LISTINGS,
  LEASE_YOUR_LISTINGS,
} from '~/legacy/consts';

import { ACTIONS as MAP_ACTIONS } from '../map';
import { API_ACTIONS } from '../actions';

const ACTIONS = {
  RESET_FILTERS: 'listings->reset_filters',
  SET_FILTER: 'listings->set_filters',
  SET_FILTER_OBJECT: 'listings->set_filters_object',
  SET_SORT: 'listings->set_sort',
  SET_TYPE: 'listings->set_type',
  REFRESH_DATA: 'listings->refresh_data',
  SET_LAST_ROUTE: 'listing->set_last_route',
  SET_LOADING: 'listings->loading',
  SET_MAP_BOUNDS: 'set_map_bounds',
  SET_HOVERED_LISTING: 'set_hovered_lisiting',
};

export const LISTING_TYPES = {
  ALL: LEASE_LISTINGS,
  SAVED: LEASE_SAVED_LISTINGS,
  SAVED_SEARCHES: LEASE_SAVED_SEARCHES_LISTINGS,
  MY_LISTINGS: LEASE_YOUR_LISTINGS,
  ALL_VIEW: LEASE_VIEW_LISTINGS,
};

const getInitialState = () => ({
  type: null,
  filters: {
    isSqFtPrice: true,
  },
  lastRoute: null,
  order: '',
  _timeStamp: 0,
  loading: true,
  rawPoligons: [],
  hoveredListing: null,
});

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.SET_TYPE:
      return { ...state, type: action.payload };
    case ACTIONS.SET_SORT: {
      return { ...state, sort: action.payload, _timeStamp: Date.now() };
    }
    case ACTIONS.SET_FILTER: {
      const filters = { ...state.filters };
      const { name, value } = action;
      filters[name] = value;
      if (name !== 'view_points' && filters.view_points)
        delete filters.view_points;
      const _timeStamp = Date.now();
      return { ...state, filters, _timeStamp };
    }
    case ACTIONS.REFRESH_DATA: {
      return { ...state, _timeStamp: Date.now() };
    }
    case ACTIONS.SET_LAST_ROUTE: {
      return { ...state, lastRoute: action.payload };
    }
    case ACTIONS.SET_FILTER_OBJECT: {
      return { ...state, filters: action.payload };
    }
    case ACTIONS.RESET_FILTERS: {
      const _timeStamp = Date.now();
      const { mine } = state.filters;
      return {
        ...state,
        filters: { mine, isSqFtPrice: true, address: '' },
        _timeStamp,
      };
    }
    case ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case MAP_ACTIONS.SET_CIRCLE: {
      const filters = { ...state.filters };
      const rawPoligons = [...state.rawPoligons];
      let raw;
      let filter;
      Object.keys(action.payload).forEach((key) => {
        if (key !== 'raw') {
          filter = action.payload[key];
          filter.id = key;
        } else {
          raw = action.payload[key];
        }
      });
      if (!filters.circle_area) filters.circle_area = [];
      rawPoligons.push(raw);
      filters.circle_area.push(filter);
      return { ...state, filters, rawPoligons, _timeStamp: Date.now() };
    }
    case MAP_ACTIONS.SET_POLYGON: {
      const filters = { ...state.filters };
      const rawPoligons = [...state.rawPoligons];
      let raw;
      let filter;
      Object.keys(action.payload).forEach((key) => {
        if (key !== 'raw') {
          filter = action.payload[key];
          filter.id = key;
        } else {
          raw = action.payload[key];
        }
      });
      if (!filters.geo_poly) filters.geo_poly = [];
      const exist = filters.geo_poly.findIndex((gp) => gp.id === filter.id);
      if (exist === -1) {
        rawPoligons.push(raw);
        filters.geo_poly.push(filter);
      } else {
        filters.geo_poly[exist] = filter;
        rawPoligons[exist] = raw;
      }
      return { ...state, filters, rawPoligons, _timeStamp: Date.now() };
    }
    case MAP_ACTIONS.DELETE_DRAWING: {
      const filters = { ...state.filters };
      const rawPoligons = [...state.rawPoligons];
      const geoPoly = [...filters.geo_poly];
      const id = action.payload;
      geoPoly.splice(
        geoPoly.findIndex((gp) => gp.id === id),
        1
      );
      rawPoligons.splice(
        rawPoligons.findIndex((gp) => gp.id === id),
        1
      );
      filters.geo_poly = geoPoly;
      return { ...state, filters, rawPoligons, _timeStamp: Date.now() };
    }
    case API_ACTIONS.CLEAN_SAVED_SEARCH_RAW_DATA:
      return { ...state, rawPoligons: [] };
    case MAP_ACTIONS.CLEAR_REVIVE_DATA: {
      const filters = { ...state.filters };
      if (filters._raw_data) delete filters._raw_data;
      if (filters.row_data) delete filters.row_data;
      return { ...state, filters };
    }
    case ACTIONS.SET_MAP_BOUNDS:
      return {
        ...state,
        mapBounds: action.payload,
      };
    case ACTIONS.SET_HOVERED_LISTING:
      return {
        ...state,
        hoveredListing: action.payload,
      };
    default:
      return state;
  }
}

export const listingsActions = {
  resetFilters: () => ({ type: ACTIONS.RESET_FILTERS }),
  setFilters: (name, value) => ({ type: ACTIONS.SET_FILTER, name, value }),
  setFilterObject: (payload) => ({ type: ACTIONS.SET_FILTER_OBJECT, payload }),
  setSort: (payload) => ({ type: ACTIONS.SET_SORT, payload }),
  setType: (payload) => ({ type: ACTIONS.SET_TYPE, payload }),
  refreshData: () => ({ type: ACTIONS.REFRESH_DATA }),
  setLastRoute: (payload) => ({ type: ACTIONS.SET_LAST_ROUTE, payload }),
  setLoading: (payload) => ({ type: ACTIONS.SET_LOADING, payload }),
  setMapBounds: (payload) => ({ type: ACTIONS.SET_MAP_BOUNDS, payload }),
  setHoveredListing: (payload) => ({
    type: ACTIONS.SET_HOVERED_LISTING,
    payload,
  }),
};
