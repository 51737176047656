import { fetchApi } from './rootApi';

const PROJECT_ENDPOINT_ROOT = 'projects/';

export const projectApi = {
  getProject: async ({ projectId }) =>
    fetchApi({endpoint: `${PROJECT_ENDPOINT_ROOT}${projectId}/`}),

  updatePartial: async ({ projectId, project }) =>
    fetchApi({
      endpoint: `${PROJECT_ENDPOINT_ROOT}${projectId}/`,
      fetchArgs: {
        method: 'PATCH',
        body: JSON.stringify(project),
      }
    }),

  delete: async ({ projectId }) =>
    fetchApi({
      endpoint: `${PROJECT_ENDPOINT_ROOT}${projectId}/`,
      fetchArgs: {
        method: 'DELETE',
      }
    }),

  addSurvey: async ({ projectId, survey }) =>
    fetchApi({
      endpoint: `${PROJECT_ENDPOINT_ROOT}${projectId}/add_survey/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify(survey),
      }
    }),

  deleteSurvey: async ({ projectId, surveyId }) =>
    fetchApi({
      endpoint: `${PROJECT_ENDPOINT_ROOT}${projectId}/delete_survey/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({survey_id: surveyId}),
      }
    }),

  duplicateSurvey: async ({ projectId, surveyId }) =>
    fetchApi({
      endpoint: `${PROJECT_ENDPOINT_ROOT}${projectId}/duplicate_survey/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({survey_id: surveyId}),
      }
    }),
};
