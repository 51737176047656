import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  modalContent: {
    background: 'white',
    outline: 'none',
    borderRadius: 7,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: 'auto',
  },
  subTitle: {
    marginBottom: '24px',
  },
  title: {
    marginTop: '24px',
  },
});

function SignUpConfirmModal() {
  const classes = useStyles();
  return (
    <div className={classes.modalContent}>
      <Typography className={classes.title} gutterBottom variant="h1">
        Success!
      </Typography>
      <Typography className={classes.subTitle} variant="body1">
        A representative will reach out as soon as possible to complete the sign
        up process
      </Typography>
    </div>
  );
}

export default SignUpConfirmModal;
