import React from 'react'
import clsx from 'clsx'
import { Tabs, Tab, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    minHeight: '0px',
    display: 'flex',
    flexDirection: 'column',
  },
  tab: {
    minWidth: 'unset',
  },
  tabs: {
    padding: '0 12px',
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: '#ffffff',
    height: '52px',
  },
  content: {
    overflowY: 'auto',
    flexGrow: 1,
  },
})

export const Inspector = ({ children, className }) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.container, className)}>
      <Tabs className={classes.tabs} value={0} indicatorColor="primary">
        <Tab className={classes.tab} label="Settings" />
      </Tabs>
      <div className={classes.content}>{children}</div>
    </div>
  )
}
