export default {
  saveAccountSettings: {
    url: '/useradmin/:id/update_user/',
    options: {
      method: 'POST',
    },
  },
  updateUserPassword: {
    url: '/useradmin/:id/update_user_password/',
    options: {
      method: 'POST',
    },
  },
  getAccountSettings: {
    url: '/useradmin/:id/',
  },
  createSubscription: {
    url: '/subscription/',
    options: {
      method: 'POST',
    },
  },
  getStripeHistory: {
    url: '/subscription/list_payments/',
  },
  cancelSubscription: {
    url: '/subscription/cancel_subscription/',
    options: {
      method: 'POST',
    },
  },
};
