import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Link, Fade } from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import clsx from 'clsx';
import { uploadFile, downloadRemoteFile } from '~/legacy/utils';
import {
  BuildingIcon,
  ExcludeIcon,
  Loading,
  ThumbnailImage,
  PlusIcon,
} from '~/legacy/components';

const LOADING_SPINNER_SIZE_PX = 40;
const ATTACHMENT_CLIP_SIZE_PCT = 30;
const ATTACHMENT_CLIP_SIZE_PAPERCLIP_ONLY_PCT = 60;

const DEFAULT_BORDER_RADIUS = '4px';
const DEFAULT_IMAGE_STYLE = {
  objectFit: 'cover',
  height: '100%',
};
const DEFAULT_MISSING_FILE_STYLE = {
  height: '100%',
};
const DEFAULT_IMAGE_CONTAINER_STYLE = {
  height: '100%',
};

const useStyles = makeStyles((theme) => ({
  file: {
    width: '100%',
  },
  border: {
    border: '1px solid #D8D8D8',
    display: 'flex',
  },
  backgroundColor: {
    backgroundColor: '#F9F9F9',
  },
  relative: {
    position: 'relative',
    display: 'flex',
  },
  text: {
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    padding: '0 5% 0 5%',
  },
  overlay: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
  loading: {
    marginTop: `-${LOADING_SPINNER_SIZE_PX / 2}px`,
    marginLeft: `-${LOADING_SPINNER_SIZE_PX / 2}px`,
  },
  attachmentTextOnly: {
    marginTop: `-${ATTACHMENT_CLIP_SIZE_PCT / 2}%`,
    marginLeft: `-${ATTACHMENT_CLIP_SIZE_PCT / 2}%`,
    width: `${ATTACHMENT_CLIP_SIZE_PCT}%`,
    height: `${ATTACHMENT_CLIP_SIZE_PCT}%`,
  },
  attachmentPaperclipOnly: {
    width: `${ATTACHMENT_CLIP_SIZE_PAPERCLIP_ONLY_PCT}%`,
    height: `${ATTACHMENT_CLIP_SIZE_PAPERCLIP_ONLY_PCT}%`,
    margin: 'auto',
  },
  linkWrapper: {
    textDecoration: 'none',
    '&:focus, &:hover, &:active': {
      color: 'inherit',
    },
    color: '#4a4a4a',
    width: '100%',
    height: '100%',
  },
  cancelButton: {
    position: 'absolute',
    top: '-7px',
    right: '-7px',
    color: '#111',
    cursor: 'pointer',
  },
  missingBuildingImage: {
    display: 'flex',
    margin: 'auto',
  },
  uploadImageIcon: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
  },
}));

// Click on the file to download
function LinkWrapper({ children, file }) {
  const classes = useStyles();
  return (
    <Link
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        downloadRemoteFile(file);
      }}
      href={file.url}
      download={file.name}
      className={classes.linkWrapper}
    >
      {children}
    </Link>
  );
}

// Style the container for the file image
const FileImageContainer = ({
  children,
  setInFocus,
  borderRadius,
  showBorder = true,
  showBackgroundColor = true,
  fileImageContainerStyleOverrides = DEFAULT_IMAGE_CONTAINER_STYLE,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.file,
        classes.fileContainer,
        classes.relative,
        showBorder ? classes.border : '',
        showBackgroundColor ? classes.backgroundColor : ''
      )}
      style={{
        borderRadius,
        ...fileImageContainerStyleOverrides,
      }}
      onMouseEnter={() => setInFocus(true)}
      onMouseLeave={() => setInFocus(false)}
    >
      {children}
    </div>
  );
};

function File({
  file,
  isAttachment = false,
  skipUploading = false,
  // required if not skipUploading
  updateFile = () => {},
  // required if not skipUploading
  deleteFile = () => {},
  // required if not skipUploading
  endpoint = null,
  showAsThumbnail = false,
  borderRadius = DEFAULT_BORDER_RADIUS,
  showCancelButton = true,
  imageStyle = DEFAULT_IMAGE_STYLE,
  // Use a special missing building image when the file is empty
  useMissingBuildingImage = false,
  attachmentOptions = {},
  fileImageContainerStyleOverrides = DEFAULT_IMAGE_CONTAINER_STYLE,
  missingFileStyleOverrides = {},
  buildingIconStyleOverrides = {},
}) {
  const classes = useStyles();
  const fileLocal = file || {};
  const attachmentOptionsLocal = {
    paperclipOnly: false,
    includeLinkWrapper: true,
    ...attachmentOptions,
  };
  const missingFileStyle = {
    ...DEFAULT_MISSING_FILE_STYLE,
    ...missingFileStyleOverrides,
  };

  const isFileUploaded = fileLocal.url || fileLocal.is_uploaded;

  // Whether or not the mouse is focusing on the file
  const [inFocus, setInFocus] = useState(false);

  const getCancelIcon = () => {
    if (!showCancelButton) {
      return null;
    }
    return (
      <ExcludeIcon
        className={classes.cancelButton}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          deleteFile();
        }}
      />
    );
  };

  useEffect(() => {
    // If the file isn't uploaded, upload the file
    if (!skipUploading && !isFileUploaded && !fileLocal.is_uploading) {
      updateFile({ ...file, is_uploaded: false, is_uploading: true });
      uploadFile(file, endpoint)
        .then((result) =>
          updateFile({
            ...result.data.file,
            is_uploaded: true,
            is_uploading: false,
          })
        )
        .catch(() => {
          // TODO: log error here?
          updateFile({
            is_uploaded: true,
            is_uploading: false,
          });
        });
    }
  });

  // If the file is uploaded, show the file
  if (isFileUploaded) {
    // Attachment treatment
    if (isAttachment) {
      const content = (
        <FileImageContainer
          setInFocus={setInFocus}
          showBorder
          showBackgroundColor
          borderRadius={borderRadius}
          fileImageContainerStyleOverrides={fileImageContainerStyleOverrides}
        >
          {!!(!skipUploading && inFocus) && getCancelIcon()}
          <AttachFileOutlinedIcon
            className={clsx(
              attachmentOptionsLocal.paperclipOnly ? '' : classes.overlay,
              attachmentOptionsLocal.paperclipOnly
                ? classes.attachmentPaperclipOnly
                : classes.attachmentTextOnly
            )}
          />
          {!attachmentOptionsLocal.paperclipOnly && (
            <Typography className={classes.text} variant="body2" noWrap>
              {fileLocal.name}
            </Typography>
          )}
        </FileImageContainer>
      );

      if (attachmentOptionsLocal.includeLinkWrapper) {
        return <LinkWrapper file={file}>{content}</LinkWrapper>;
      }
      return content;
    }
    // Uploaded file treatment
    return (
      <FileImageContainer
        setInFocus={setInFocus}
        showBorder={false}
        showBackgroundColor={false}
        borderRadius={0}
        fileImageContainerStyleOverrides={fileImageContainerStyleOverrides}
      >
        {!!(!skipUploading && inFocus) && getCancelIcon()}
        <ThumbnailImage
          imageFile={fileLocal}
          ImgProps={{
            className: clsx(
              classes.file,
              showAsThumbnail ? classes.border : ''
            ),
            style: {
              borderRadius,
              ...imageStyle,
            },
            alt: fileLocal.name,
          }}
        />
      </FileImageContainer>
    );
  }
  // If it's uploading, show loading logic
  if (fileLocal.is_uploading) {
    return (
      <FileImageContainer
        setInFocus={setInFocus}
        showBorder
        showBackgroundColor
        borderRadius={borderRadius}
        fileImageContainerStyleOverrides={fileImageContainerStyleOverrides}
      >
        <Loading
          isLoading
          size={LOADING_SPINNER_SIZE_PX}
          className={clsx(classes.overlay, classes.loading)}
        />
        <Typography className={classes.text} variant="body2">
          {fileLocal.name ? fileLocal.name : 'uploading'}
        </Typography>
      </FileImageContainer>
    );
  }
  // Else, show missing file placeholder
  return (
    <MissingFilePlaceholder
      useMissingBuildingImage={useMissingBuildingImage}
      borderRadius={borderRadius}
      file={fileLocal}
      fileImageContainerStyleOverrides={missingFileStyle}
      buildingIconStyleOverrides={buildingIconStyleOverrides}
    />
  );
}

const MissingFilePlaceholder = ({
  file,
  borderRadius = DEFAULT_BORDER_RADIUS,
  useMissingBuildingImage = true,
  fileImageContainerStyleOverrides = DEFAULT_MISSING_FILE_STYLE,
  buildingIconStyleOverrides = {},
}) => {
  const classes = useStyles();

  return (
    <FileImageContainer
      setInFocus={() => {}}
      showBorder={!useMissingBuildingImage}
      showBackgroundColor
      borderRadius={borderRadius}
      fileImageContainerStyleOverrides={fileImageContainerStyleOverrides}
      buildingIconStyleOverrides={buildingIconStyleOverrides}
    >
      {useMissingBuildingImage ? (
        <BuildingIcon
          className={classes.missingBuildingImage}
          style={{ ...buildingIconStyleOverrides }}
        />
      ) : (
        <Typography className={classes.text} variant="body2">
          {file.name ? file.name : 'missing'}
        </Typography>
      )}
    </FileImageContainer>
  );
};

// Used to ensure the checkbox covered the image;
const Z_INDEX_UNDER = 1;
const Z_INDEX_OVER = Z_INDEX_UNDER + 1;

const NoFileWithUploader = ({
  uploadPhotoModalOpen,
  hovered,
  setHovered,
  onClick = () => {},
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const useMissingBuildingImage = true;
  const showPlusIcon = !!(uploadPhotoModalOpen || hovered);

  const Container = ({ isGreen = false, children }) => {
    const missingFileStyleOverrides = {
      ...DEFAULT_MISSING_FILE_STYLE,
      backgroundColor: isGreen ? theme.palette.primary.main : '#E0E0E0',
      color: '#666666',
    };

    return (
      <FileImageContainer
        setInFocus={() => {}}
        showBorder={!useMissingBuildingImage}
        showBackgroundColor
        borderRadius={DEFAULT_BORDER_RADIUS}
        fileImageContainerStyleOverrides={missingFileStyleOverrides}
      >
        {children}
      </FileImageContainer>
    );
  };

  const FadeInContainer = ({ children }) => {
    // Immediately fade in on first render
    const [fadeIn, setFadeIn] = useState(false);
    useEffect(() => {
      setFadeIn(true);
    }, []);

    return (
      <Fade
        in={fadeIn || uploadPhotoModalOpen}
        style={{ zIndex: Z_INDEX_OVER }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            zIndex: Z_INDEX_OVER,
          }}
        >
          <Container isGreen={showPlusIcon}>{children}</Container>
        </div>
      </Fade>
    );
  };

  const PlusIconComponent = () => (
    <PlusIcon
      className={clsx(classes.missingBuildingImage, classes.uploadImageIcon)}
      onClick={onClick}
    />
  );

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {/* If we are hovering, show the green plus icon over the missing building image */}
      {!!showPlusIcon && !uploadPhotoModalOpen && (
        <FadeInContainer>
          <PlusIconComponent />
        </FadeInContainer>
      )}
      {/* Also ensure that we show if the modal is open too, but no fade in, just show it */}
      {!!showPlusIcon && !!uploadPhotoModalOpen && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            zIndex: Z_INDEX_OVER,
          }}
        >
          <Container isGreen>
            <PlusIconComponent />
          </Container>
        </div>
      )}

      {/* Always show the missing building image */}
      <div
        style={{
          display: 'flex',
          width: '50px',
          height: '50px',
          position: 'absolute',
          zIndex: Z_INDEX_UNDER,
        }}
      >
        <Container isGreen={false}>
          <BuildingIcon
            className={clsx(classes.missingBuildingImage)}
            onClick={onClick}
          />
        </Container>
      </div>
    </div>
  );
};

function MoreFilesPlaceholder({
  extraFilesCount,
  containerClassName,
  borderRadius = '4px',
}) {
  const classes = useStyles();
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        borderRadius,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className={clsx(
        classes.border,
        classes.backgroundColor,
        containerClassName
      )}
    >
      <Typography style={{ color: '#666' }} variant="h3">
        +
        {extraFilesCount}
      </Typography>
    </div>
  );
}

const FILE_CONSTANTS = { DEFAULT_MISSING_FILE_STYLE };

export {
  File,
  MoreFilesPlaceholder,
  MissingFilePlaceholder,
  NoFileWithUploader,
  FILE_CONSTANTS,
};
