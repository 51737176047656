import React from 'react'
import { ModalPortal } from '~/legacy/components/ModalPortal'
import { styled } from '~/legacy/utils/styleHelpers'

export const ModalOverlay = ({ children, onCancel }) => (
  <ModalPortal>
    <Background onClick={onCancel} />
    {children}
  </ModalPortal>
)

const Background = styled('div', {
  backgroundColor: '#000',
  inset: '0px',
  opacity: 0.5,
  position: 'fixed',
  zIndex: 10000000,
})
