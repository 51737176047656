import React, { memo, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  image: {
    width: 40,
    marginLeft: 16,
  },
});

function CompanyLogo({ name, website = '' }) {
  const classes = useStyles();
  const [showLogo, setShowLogo] = useState(false);
  const _website = website ? website.replace(/(^\w+:|^)\/\//, '') : '';

  if (_website) {
    fetch(`https://logo.clearbit.com/${_website}`, {
      method: 'HEAD',
      mode: 'cors',
    }).then(response => {
      setShowLogo(response.ok);
    });
  }

  return (
    <div className={classes.root}>
      <Typography variant="h2">{name}</Typography>
      {showLogo && (
        <img
          className={classes.image}
          src={`//logo.clearbit.com/${_website}`}
          alt={name}
        />
      )}
    </div>
  );
}

export default memo(CompanyLogo);
