import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Modal, MODALS, TextLink, Typography } from '~/legacy/components';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    borderBottom: '1px solid #e0e0e0',
    width: '500px',
    paddingBottom: '6px',
    minHeight: '50px',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '10px',
    color: '#666666',
  },
  editLink: {
    marginTop: 'auto',
    marginLeft: 'auto',
  },
});

function ModalEditableField({
  className,
  label,
  inputLabel,
  modalSubtitle,
  type,
  value,
  format,
  validate,
}) {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.leftSide}>
        <Typography className={classes.label} variant="h5">
          {label}
        </Typography>
        <Typography className={classes.value} variant="body1">
          {value}
        </Typography>
      </div>
      <TextLink className={classes.editLink} onClick={() => setModalOpen(true)}>
        Edit
      </TextLink>
      <Modal
        content={MODALS.ACCOUNT_EDIT_MODAL}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        childProps={{
          label,
          subtitle: modalSubtitle,
          inputLabel: label || inputLabel,
          inputType: type,
          format,
          validate,
        }}
      />
    </div>
  );
}

export default ModalEditableField;
