import {
  Chip as MuiChip,
  Collapse,
  Dialog,
  Fade,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import clsx from 'clsx'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Api from 'rest-fetcher-redux'
import {
  File,
  Linkify,
  MoreFilesPlaceholder,
  UserAvatar,
} from '~/legacy/components'
import {
  LEASE_PROJECT_BY_ID,
  SURVEY_COMMENT,
  SURVEY_COMPARISON_COMMENT,
  SURVEY_LISTING_COMMENT,
  SURVEY_LISTING_STATUSES,
  SURVEY_LISTING_STATUS_CHANGES,
} from '~/legacy/consts'
import {
  getBuildingPrimaryName,
  getBuildingTertiaryName,
  getListingPrimaryName,
  getViewBuildingRoute,
  getViewListingRoute,
  useSurveyInBdpBuildout,
} from '~/legacy/utils'
import { toast } from '~/legacy/utils/notifications'
import {
  formatTooltipDate,
  getAuthorName,
  getEventDateDisplayString,
  getUserName,
  userOwnsActivityItem,
} from '../activity/helpers'

const useStylesShareSurveyActivityItem = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  afterChip: {
    paddingLeft: '10px',
  },
  time: {
    paddingLeft: '10px',
    color: theme.custom.secondaryText.color,
  },
  multilineText: {
    whiteSpace: 'pre-line',
  },
  activityContainer: {
    paddingLeft: '20px',
    overflowWrap: 'anywhere',
    width: '100%',
  },
  declinedStatus: {
    backgroundColor: 'lightgray',
    color: 'black',
  },
  avatar: {
    marginTop: '3px',
    alignSelf: 'flex-start',
  },
  file: {
    width: '150px',
    height: '150px',
    display: 'flex',
    padding: '0 8px 8px 0',
    objectFit: 'cover',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
    paddingTop: '8px',
  },
  moreOptionsIcon: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    marginRight: '8px',
    cursor: 'pointer',
  },
  image: {
    '&> div': {
      cursor: 'zoom-in',
    },
  },
  modalPaper: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '100%',
    backgroundColor: 'unset',
    boxShadow: 'none',
    maxHeight: '80%',
    cursor: 'zoom-out',
  },
  modalContainer: {
    cursor: 'zoom-out',
  },
  clickable: {
    cursor: 'pointer',
  },
  chipPadding: {
    paddingTop: '3px',
  },
}))

const useChipStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: 3,
    padding: '0 4px',
    ...theme.typography.h3,
    cursor: 'inherit',
    '& .MuiChip-labelSmall': {
      lineHeight: '1.43',
      paddingLeft: '4px !important',
      paddingRight: '4px !important',
    },
  },
}))

const COLLAPSE_TIME_MS = 1000

function Chip({ className, ...chipProps }) {
  const classes = useChipStyles()
  return (
    <MuiChip
      className={clsx(classes.chip, className)}
      size="small"
      {...chipProps}
    />
  )
}

// Get the survey listing status copy to be displayed in badge
const getSurveyListingStatusCopy = (status, statusChange) => {
  let copy = ''
  if (statusChange === SURVEY_LISTING_STATUS_CHANGES.TOUR_CANCELED) {
    copy = 'Unfavorited'
  } else if (status === SURVEY_LISTING_STATUSES.TOUR_REQUESTED) {
    copy = 'Favorited'
  } else if (status === SURVEY_LISTING_STATUSES.DECLINED) {
    copy = 'Not Interested'
  } else {
    copy = 'Interested'
  }
  return copy
}

const getSurveyLinkComponent = (survey, history) => {
  return (
    <Link
      onClick={() =>
        history.push(LEASE_PROJECT_BY_ID.replace(':id', survey.id))
      }
    >
      {survey.name}
    </Link>
  )
}

// Get the line item displaying the activity address, and optionaly the survey name
const getActivityListingAndSurvey = (
  activity,
  listing,
  classes,
  includeSurvey,
  history
) => {
  return (
    <Typography variant="body1">
      {includeSurvey ? (
        <>
          {`${getListingPrimaryName(listing)}, part of `}
          {getSurveyLinkComponent(activity.survey_listing.survey, history)}
        </>
      ) : (
        getListingPrimaryName(listing)
      )}
    </Typography>
  )
}

// Conditionally include a show+fade wrapper for the children
function CollapseWrapper({ includeCollapse, children }) {
  const [show, setShow] = useState(false)

  useEffect(() => {
    // Immediately set show to true after the component mounts
    if (!show) {
      setShow(true)
    }
  }, [show])

  if (includeCollapse) {
    return (
      <Collapse in={show} timeout={COLLAPSE_TIME_MS}>
        <Fade in={show} timeout={COLLAPSE_TIME_MS}>
          <div>{children}</div>
        </Fade>
      </Collapse>
    )
  }
  return children
}

function ActivityItem({
  activity,
  children,
  currentDate,
  editable = false,
  setNewActivityMessage = () => {},
}) {
  const classes = useStylesShareSurveyActivityItem()
  const [isHovering, setIsHovering] = useState(false)
  const user = useSelector((s) => s.user)
  const activityDate = new Date(activity.date)

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })

  return (
    <div
      className={classes.row}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <UserAvatar className={classes.avatar} user={activity.author} />
      <div className={classes.activityContainer}>
        <div className={classes.row}>
          {activity.author && (
            <Typography variant="h3">{getAuthorName(activity)}</Typography>
          )}
          <Tooltip title={formatTooltipDate(activityDate)} placement="top">
            <Typography variant="body1" className={classes.time}>
              {`${getEventDateDisplayString(activityDate, currentDate)}`}
            </Typography>
          </Tooltip>
        </div>
        {children}
      </div>
      {editable && userOwnsActivityItem(activity, user) && (
        <>
          <Fade in={isHovering}>
            <MoreHorizIcon
              className={classes.moreOptionsIcon}
              {...bindTrigger(popupState)}
            />
          </Fade>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
          >
            <MenuItem
              onClick={() => {
                popupState.close()
                // TODO: should be significantly simplified
                if (activity.type === SURVEY_LISTING_COMMENT) {
                  Api.deleteSurveyListingComment({
                    id: activity.id,
                    type: activity.type,
                  }).then((result) => {
                    if (result.uuid_to_remove) {
                      setNewActivityMessage(result)
                    } else {
                      toast(result, { appearance: 'danger' })
                    }
                  })
                } else if (activity.type === SURVEY_COMMENT) {
                  Api.deleteSurveyComment({
                    id: activity.id,
                    type: activity.type,
                  }).then((result) => {
                    if (result.uuid_to_remove) {
                      setNewActivityMessage(result)
                    } else {
                      toast(result, { appearance: 'danger' })
                    }
                  })
                } else if (activity.type === SURVEY_COMPARISON_COMMENT) {
                  Api.deleteSurveyComparisonComment({
                    id: activity.id,
                    type: activity.type,
                  }).then((result) => {
                    if (result.uuid_to_remove) {
                      setNewActivityMessage(result)
                    } else {
                      toast(result, { appearance: 'danger' })
                    }
                  })
                }
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  )
}

function ShareSurveyActivityItem({ activity, currentDate, forSdp = false }) {
  const classes = useStylesShareSurveyActivityItem()
  const history = useHistory()

  // Sort of a hack for now but get the survey from the first shared user
  const survey =
    activity && activity.survey_shared_users
      ? activity.survey_shared_users[0].survey
      : null
  const sharedUsers =
    activity && activity.survey_shared_users
      ? activity.survey_shared_users
      : null

  const sharedUserNames = sharedUsers
    .map((sharedUser) => getUserName(sharedUser.shared_user))
    .join(', ')

  return (
    <ActivityItem activity={activity} currentDate={currentDate}>
      <div className={clsx(classes.row, classes.chipPadding)}>
        <Chip
          label={forSdp ? 'Shared Survey' : 'Shared'}
          color="primary"
          size="small"
          variant="outlined"
        />
        <div className={clsx(classes.row, classes.afterChip)}>
          <Typography variant="body1">
            {forSdp ? '' : getSurveyLinkComponent(survey, history)}
            {`${forSdp ? '' : ' '}with ${sharedUserNames}`}
          </Typography>
        </div>
      </div>
      {activity.share_message && <CommentText text={activity.share_message} />}
    </ActivityItem>
  )
}

function StatusUpdateChip({ activity }) {
  const classes = useStylesShareSurveyActivityItem()

  const statusColorClass =
    activity.status === SURVEY_LISTING_STATUSES.DECLINED ||
    activity.status_change === SURVEY_LISTING_STATUS_CHANGES.TOUR_CANCELED
      ? classes.declinedStatus
      : ''

  return (
    <Chip
      label={getSurveyListingStatusCopy(
        activity.status,
        activity.status_change
      )}
      color="primary"
      className={statusColorClass}
      size="small"
    />
  )
}

function CommentText({ text, comparisonUrl = '' }) {
  const classes = useStylesShareSurveyActivityItem()
  return (
    <>
      <Linkify>
        <div>
          <Typography
            display="inline"
            variant="body1"
            className={classes.multilineText}
          >
            {text}
          </Typography>
        </div>
      </Linkify>
      {!!comparisonUrl && (
        <div>
          <Link href={comparisonUrl} variant="h3">
            View Comparison
          </Link>
        </div>
      )}
    </>
  )
}

function SurveyListingStatusUpdateActivityItem({
  activity,
  listing,
  currentDate,
  surveyId,
}) {
  const classes = useStylesShareSurveyActivityItem()
  const history = useHistory()

  return (
    <ActivityItem activity={activity} currentDate={currentDate}>
      <div className={clsx(classes.row, classes.chipPadding)}>
        <StatusUpdateChip activity={activity} />
        <div className={clsx(classes.column, classes.afterChip)}>
          {getActivityListingAndSurvey(
            activity,
            listing,
            classes,
            !surveyId,
            history
          )}
        </div>
      </div>
    </ActivityItem>
  )
}

// TODO: Delete after LDP deprecation
function SurveyListingCommentActivityItem({
  activity,
  currentDate,
  setNewActivityMessage = () => {},
}) {
  const classes = useStylesShareSurveyActivityItem()
  const [imageModalState, setImageModalState] = useState({
    visible: false,
    imageUrl: '',
  })

  return (
    <ActivityItem
      activity={activity}
      setNewActivityMessage={setNewActivityMessage}
      editable
      currentDate={currentDate}
    >
      <div className={clsx(classes.row, classes.chipPadding)}>
        <Chip
          label="Commented"
          color="primary"
          size="small"
          variant="outlined"
        />
      </div>
      {!!activity.text && <CommentText text={activity.text} />}
      <div className={classes.fileContainer}>
        {activity.attachments.map((activityAttachment) => {
          return (
            <div className={classes.file} key={activityAttachment.uuid}>
              <File
                file={activityAttachment}
                skipUploading
                showAsThumbnail
                isAttachment
              />
            </div>
          )
        })}
        {activity.photos.map((activityPhoto) => {
          return (
            <div
              className={clsx(classes.file, classes.image)}
              key={activityPhoto.uuid}
              onClick={() =>
                setImageModalState({
                  visible: true,
                  imageUrl: activityPhoto.raw_image_url,
                })
              }
            >
              <File file={activityPhoto} skipUploading showAsThumbnail />
            </div>
          )
        })}
      </div>
      <Dialog
        classes={{
          container: classes.modalContainer,
          paper: classes.modalPaper,
        }}
        fullWidth
        maxWidth="lg"
        onClick={() => setImageModalState({ visible: false, imageUrl: '' })}
        onClose={() => setImageModalState({ visible: false, imageUrl: '' })}
        open={imageModalState.visible}
        PaperProps={{
          style: { backgroundImage: `url(${imageModalState.imageUrl})` },
        }}
      />
    </ActivityItem>
  )
}

function ActivityCard({
  activity,
  currentDate,
  activityAction,
  onClick,
  heroPhoto,
  children,
}) {
  const classes = useStylesShareSurveyActivityItem()
  return (
    <ActivityItem
      activity={activity}
      editable={false}
      currentDate={currentDate}
    >
      <div className={classes.row} style={{ paddingBottom: '12px' }}>
        <Typography variant="body1">{activityAction}</Typography>
      </div>
      <div className={clsx(classes.row, classes.clickable)} onClick={onClick}>
        <div
          className={classes.row}
          style={{
            width: '100%',
            height: '110px',
            border: '1px solid #D8D8D8',
            borderRadius: '4px',
          }}
        >
          <div
            style={{
              width: '110px',
              height: '100%',
            }}
          >
            <File
              file={heroPhoto}
              skipUploading
              borderRadius="3px 0px 0px 3px"
              useMissingBuildingImage
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '16px',
              width: 'calc(100% - 110px)',
              height: '100%',
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </ActivityItem>
  )
}

// TODO: probs move out to separate file
function SurveyListingStatusUpdateActivityItemCard({ activity, currentDate }) {
  const history = useHistory()
  const isUserInBuildout = useSurveyInBdpBuildout(
    activity.survey_listing.survey
  )
  return (
    <ActivityCard
      activity={activity}
      editable={false}
      currentDate={currentDate}
      activityAction="Changed status on listing"
      onClick={() =>
        history.push(
          isUserInBuildout
            ? getViewBuildingRoute(
                activity.survey_listing.survey.id,
                activity.survey_listing.listing.building.id
              )
            : getViewListingRoute(
                activity.survey_listing.listing.id,
                activity.survey_listing.survey.id
              )
        )
      }
      heroPhoto={activity.survey_listing.listing.hero_photo}
    >
      <div style={{ paddingBottom: '8px' }}>
        <StatusUpdateChip activity={activity} />
      </div>
      <Typography
        variant="h3"
        style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {getListingPrimaryName(activity.survey_listing.listing)}
      </Typography>
      <Typography
        variant="h3"
        style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {getBuildingTertiaryName(activity.survey_listing.listing.building)}
      </Typography>
    </ActivityCard>
  )
}

// Comment on a survey listing
function SurveyListingCommentActivityCard({ activity, ...props }) {
  const itemLabel = 'listing'
  const itemName = getListingPrimaryName(activity.survey_listing.listing)
  const onClickRoute = getViewListingRoute(
    activity.survey_listing.listing.id,
    activity.survey_listing.survey.id
  )

  return (
    <SurveyItemCommentActivityCard
      activity={activity}
      itemLabel={itemLabel}
      itemName={itemName}
      onClickRoute={onClickRoute}
      heroPhoto={activity.survey_listing.listing.hero_photo}
      {...props}
    />
  )
}

// Comment on a survey building
const SurveyBuildingCommentActivityCard = ({ activity, ...props }) => {
  const itemLabel = 'building'
  const itemName = getBuildingPrimaryName(activity.survey_building.building)
  const onClickRoute = getViewBuildingRoute(
    activity.survey_building.survey.id,
    activity.survey_building.building.id
  )

  return (
    <SurveyItemCommentActivityCard
      activity={activity}
      itemLabel={itemLabel}
      itemName={itemName}
      onClickRoute={onClickRoute}
      heroPhoto={activity.survey_building.building.hero_photo}
      {...props}
    />
  )
}

// Comment on an item in a survey, ie a listing/building
const SurveyItemCommentActivityCard = ({
  activity,
  itemLabel,
  itemName,
  onClickRoute,
  heroPhoto,
  ...props
}) => {
  const numPhotos = activity && activity.photos ? activity.photos.length : 0
  const numAttachments =
    activity && activity.attachments ? activity.attachments.length : 0
  const numFiles = numPhotos + numAttachments
  const textLength = activity ? activity.text.length : 0

  if (!textLength && numFiles > 0) {
    return (
      <SurveyItemCommentActivityPhotosCard
        activity={activity}
        itemLabel={itemLabel}
        itemName={itemName}
        onClickRoute={onClickRoute}
        numFiles={numFiles}
        numAttachments={numAttachments}
        heroPhoto={heroPhoto}
        {...props}
      />
    )
  }
  return (
    <SurveyItemCommentActivityTextAndPhotosCard
      activity={activity}
      itemLabel={itemLabel}
      itemName={itemName}
      onClickRoute={onClickRoute}
      numFiles={numFiles}
      numAttachments={numAttachments}
      heroPhoto={heroPhoto}
      {...props}
    />
  )
}

// Comment on an item in a survey with photos, ie a listing/building
function SurveyItemCommentActivityPhotosCard({
  activity,
  currentDate,
  itemLabel,
  itemName,
  onClickRoute,
  numFiles,
  numAttachments,
  heroPhoto,
}) {
  const classes = useStylesShareSurveyActivityItem()
  const history = useHistory()

  const activityActionName = numAttachments > 0 ? 'attachment' : 'photo'
  const attachments = numAttachments
    ? activity.attachments.map((a) => ({ ...a, isAttachment: true }))
    : []

  const MAX_FILES = 5
  let combinedFiles = [...activity.photos, ...attachments]
  const extraFilesCount =
    combinedFiles.length > MAX_FILES ? combinedFiles.length - MAX_FILES : 0
  if (extraFilesCount) {
    combinedFiles = combinedFiles.slice(0, MAX_FILES - 1)
  }

  return (
    <ActivityCard
      activity={activity}
      editable={false}
      currentDate={currentDate}
      activityAction={`Added ${numFiles} ${activityActionName}${
        numFiles > 1 ? 's' : ''
      } to a ${itemLabel}`}
      onClick={() => history.push(onClickRoute)}
      heroPhoto={heroPhoto}
    >
      <div className={classes.row} style={{ paddingBottom: '8px' }}>
        {combinedFiles.map((file) => (
          <div
            style={{ width: '40px', height: '40px', marginRight: '12px' }}
            key={file.id}
          >
            <File
              file={file}
              skipUploading
              useMissingBuildingImage
              isAttachment={file.isAttachment}
              showAsThumbnail
              attachmentOptions={{
                paperclipOnly: true,
                includeLinkWrapper: false,
              }}
            />
          </div>
        ))}
        {!!extraFilesCount && (
          <div style={{ width: '40px', height: '40px' }}>
            <MoreFilesPlaceholder extraFilesCount={extraFilesCount} />
          </div>
        )}
      </div>
      <Typography
        variant="h3"
        style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {itemName}
      </Typography>
    </ActivityCard>
  )
}

// Comment on an item in a survey with text and photos, ie a listing/building
function SurveyItemCommentActivityTextAndPhotosCard({
  activity,
  currentDate,
  itemLabel,
  itemName,
  onClickRoute,
  numFiles,
  numAttachments,
  heroPhoto,
}) {
  const classes = useStylesShareSurveyActivityItem()
  const history = useHistory()
  const MAX_LENGTH = 60

  const textLength = activity.text ? activity.text.length : null

  let commentActionText = ''
  if (numFiles > 0) {
    const activityActionName = numAttachments > 0 ? 'attachment' : 'photo'
    commentActionText = `Commented on a ${itemLabel} and added ${numFiles} ${activityActionName}${
      numFiles > 1 ? 's' : ''
    }`
  } else {
    commentActionText = `Commented on a ${itemLabel}`
  }

  return (
    <ActivityCard
      activity={activity}
      editable={false}
      currentDate={currentDate}
      activityAction={commentActionText}
      onClick={() => history.push(onClickRoute)}
      heroPhoto={heroPhoto}
    >
      <div className={classes.row} style={{ paddingBottom: '8px' }}>
        <Typography
          display="inline"
          variant="body1"
          style={{
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
          }}
        >
          &quot;
          {activity.text.substring(0, MAX_LENGTH)}
          {textLength > MAX_LENGTH ? '...' : ''}
          &quot;
        </Typography>
      </div>
      <Typography
        variant="h3"
        style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {itemName}
      </Typography>
    </ActivityCard>
  )
}

function SurveyCommentActivityItem({
  activity,
  currentDate,
  setNewActivityMessage = () => {},
}) {
  return (
    <ActivityItem
      activity={activity}
      currentDate={currentDate}
      editable
      setNewActivityMessage={setNewActivityMessage}
    >
      {activity.text && <CommentText text={activity.text} />}
    </ActivityItem>
  )
}

function SurveyComparisonCommentActivityItem({
  activity,
  currentDate,
  setNewActivityMessage = () => {},
}) {
  return (
    <ActivityItem
      activity={activity}
      currentDate={currentDate}
      editable
      setNewActivityMessage={setNewActivityMessage}
    >
      {activity.text && (
        <CommentText
          text={activity.text}
          comparisonUrl={activity.comparison_url}
        />
      )}
    </ActivityItem>
  )
}

export {
  CollapseWrapper,
  ShareSurveyActivityItem,
  SurveyListingStatusUpdateActivityItem,
  SurveyListingCommentActivityItem,
  SurveyListingStatusUpdateActivityItemCard,
  SurveyListingCommentActivityCard,
  SurveyCommentActivityItem,
  SurveyComparisonCommentActivityItem,
  SurveyBuildingCommentActivityCard,
}
