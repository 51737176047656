import React from 'react';
import { SquareIconButton, NotInterestedIcon } from '~/legacy/components';
import { SURVEY_LISTING_STATUSES } from '~/legacy/consts';

export default function NotInterestedIconButton({
  surveyListing,
  setSurveyListingToDecline = () => {},
  SquareIconButtonProps = {},
  NotInterestedIconProps = {},
}) {
  return (
    <SquareIconButton
      disabled={surveyListing.status === SURVEY_LISTING_STATUSES.DECLINED}
      showAsDisabled={
        surveyListing.status === SURVEY_LISTING_STATUSES.TOUR_REQUESTED
      }
      onClick={(event) => {
        event.stopPropagation();
        setSurveyListingToDecline(surveyListing);
      }}
      {...SquareIconButtonProps}
    >
      <NotInterestedIcon {...NotInterestedIconProps} />
    </SquareIconButton>
  );
}
