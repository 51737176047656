import { styled as mui } from '@material-ui/core/styles'
import get from 'lodash/get'
import has from 'lodash/has'
import omit from 'lodash/omit'
import { createElement } from 'react'
import { createData } from '~/support/createData'

export const join = (queries) => {
  return queries.map((value) => `(${value})`).join(' and ')
}

export const screen = (...queries) => {
  return `@media only screen and ${join(queries)}`
}

export const breakpoint = (type, value) => {
  return `${type}-width: ${value}px`
}

export const above = (min) => {
  return screen(breakpoint('min', min))
}

export const below = (max) => {
  return screen(breakpoint('max', max))
}

export const between = (min, max) => {
  return screen(breakpoint('min', min), breakpoint('max', max))
}

export const first = (keys) => (props) => {
  return props[keys.find((key) => has(props, key))]
}

export const number = (key, fallback) => (props) => {
  return percent(get(props, key, fallback))
}

export const percent = (value) => {
  return Math.abs(value) <= 1 ? `${value * 100}%` : `${value}px`
}

export const prop = (key, fallback) => (props) => {
  return get(props, key, fallback)
}

export const select = (key, values) => (props) => {
  return values[get(props, key)] || values.default
}

export const unless = (key, a = '', b = '') => {
  return (props) => {
    return get(props, key) ? b : a
  }
}

export const when = (key, a, b) => {
  return unless(key, b, a)
}

export const styled = (Component, props, styles) => {
  if (!styles) {
    return styled(Component, null, props)
  }

  if (props) {
    const keys = [...props, 'children']
    return styled(
      (props) => createElement(Component, omit(props, keys), props.children),
      null,
      styles
    )
  }

  if (typeof styles === 'function') {
    return mui(Component)((props) => {
      return styles(createData(props))
    })
  }

  return mui(Component)(styles)
}
