import React from 'react'
import { styled } from '~/libraries/styled'
import { CheckboxSetting } from '~/pdfs/patterns/CheckboxSetting'
import { Group } from '~/pdfs/patterns/Group'
import { Settings } from '~/pdfs/patterns/Settings'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { findFieldAndFormat } from '~/support/findFieldAndFormat'
import { Size, Text } from '~/objects/fields'
import { Font } from '~/components/Font'
import { Photo } from '~/pdfs/patterns/Photo'
import { LeaseUpHeader } from '../patterns/LeaseUpHeader'
import { LeaseUpPage } from '../patterns/LeaseUpPage'
import { buildAddress } from '../support/buildAddress'
import { sentence } from '~/support/sentence'
import { buildSpaceName } from '../support/buildSpaceName'
import { image } from '~/pdfs/support/image'

export const LeaseUpFloorplan = definePageTemplate({
  id: '6bc95067-c9e9-4f48-80ea-118f4ba014e1',

  name: 'Space Floorplan',

  props: (pdf, space) => {
    const { floor, sqft } = pdf.settings

    const details = [
      floor && buildFloor(space.fields),
      sqft && findFieldAndFormat(space.fields, Size),
    ]

    return {
      photo: image(space.building.images[0], { width: 153 * 2 }),
      floorplan: image(space.floorplans[0], { width: 612 * 2 }),
      name: buildSpaceName(space),
      address: buildAddress(space.building.location),
      details: sentence(details, ' - '),
      floor,
      sqft,
    }
  },

  Page: (props) => (
    <Page>
      <LeaseUpHeader
        photo={props.photo}
        title={props.name}
        subtitle={props.address}
      />

      {props.details && (
        <Font format="14/21/600" padding="8px 0">
          {props.details}
        </Font>
      )}

      {props.floorplan && <Photo src={props.floorplan} fit="contain" grow />}
    </Page>
  ),

  Settings: (props) => (
    <Settings>
      <Group label="Details">
        <CheckboxSetting id="floor" label="Show Floor" value={props.floor} />
        <CheckboxSetting id="sqft" label="Show Sq Ft" value={props.sqft} />
      </Group>
    </Settings>
  ),
})

const buildFloor = (fields) => {
  const value = findFieldAndFormat(fields, { name: 'Floor', type: Text })
  return value && `Floor ${value}`
}

const Page = styled(LeaseUpPage, {
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
})
