import React from 'react';
import clsx from 'clsx';
import { DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ModalDialog } from '~/legacy/components';

const modalComponentStyles = {
  modal: {},
  dialogContent: {
    '&:first-child': {
      padding: '24px',
    },
    width: '548px',
    minHeight: '231px',
    display: 'flex',
  },
};

// TODO: Cutover existing modal to use this component so they share style
function ModalComponent({
  fullScreen = false,
  open,
  // On request to close modal
  onClose,
  // After the modal close transition completes
  onAfterClose = () => {},
  disableBackDropClickClose = false,
  classesIn = {},
  DialogProps = {},
  children,
  classes,
}) {
  return (
    <ModalDialog
      fullScreen={fullScreen}
      className={clsx(classes.modal)}
      open={open}
      disableBackDropClickClose={disableBackDropClickClose}
      onClose={onClose}
      onAfterClose={onAfterClose}
      {...DialogProps}
    >
      <DialogContent
        className={clsx(classes.dialogContent, classesIn.dialogContent)}
      >
        {children}
      </DialogContent>
    </ModalDialog>
  );
}

export default withStyles(modalComponentStyles)(ModalComponent);
