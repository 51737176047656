import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import merge from 'lodash/merge';

import { BULK_IMPORT_CONSTANTS } from '~/legacy/utils';
import {
  EditableCell,
  TableContentCell,
  TableRow,
} from '../../tableComponents';

const useStyles = makeStyles({
  tableCell: {
    paddingLeft: '12px !important',
    paddingRight: '12px !important',
  },
  nameCell: {
    display: 'inline-flex',
    height: '56px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const AddCustomFieldTableRow = ({
  objectIds,
  createCustomFields,
  editableCellClasses,
}) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [valueMap, setValueMap] = useState(
    merge(...objectIds.map((id) => ({ [id]: '' })))
  );

  useEffect(() => {
    if (name) {
      createCustomFields({ name, valueMap });
    }
  }, [name]);

  const updateValueMap = (newData) =>
    setValueMap({
      ...valueMap,
      ...newData,
    });
  const IconClass = BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.icon;

  return (
    <TableRow>
      <TableContentCell classes={{ tableCell: classes.tableCell }}>
        <div className={classes.nameCell}>
          <IconClass />
          <EditableCell
            value={name}
            updateValue={setName}
            updateValueApi={(newValue) =>
              new Promise((resolve) => {
                setName(newValue);
                resolve(newValue);
              })
            }
            classesIn={editableCellClasses}
            textContentVariant="bodyBold"
            placeholder="Add Name"
          />
        </div>
      </TableContentCell>
      {objectIds.map((id) => (
        <TableContentCell key={id} classes={{ tableCell: classes.tableCell }}>
          <EditableCell
            value={valueMap[id]}
            updateValue={(newValue) => updateValueMap({ [id]: newValue })}
            updateValueApi={(newValue) =>
              new Promise((resolve) => {
                updateValueMap({ [id]: newValue });
                resolve(newValue);
              })
            }
            classesIn={editableCellClasses}
          />
        </TableContentCell>
      ))}
    </TableRow>
  );
};
