import Api from 'rest-fetcher-redux'
import posthog from 'posthog-js'
import {
  getTokensFromLocalStorage,
  deleteTokens,
  setupAuthorizationHeaders,
} from '~/legacy/utils'
import { USER_SUBSCRIPTION_STATUSES } from '~/legacy/consts'

const ACTIONS = {
  LOGOUT: 'LOGOUT',
  UPDATE_SUBSCRIPTION_STATUS: 'UPDATE_SUBSCRIPTION_STATUS',
  BILLING_IS_LOADING: 'BILLING_IS_LOADING',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
}

function initialize() {
  const tokens = getTokensFromLocalStorage()
  const loggedIn = !!tokens

  return {
    loggedIn,
    isAnonymous: false,
    tokens,
    id: null,
    username: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    userPermissions: null,
    groups: null,
    userType: null,
    user_role: null,
    company: null,
    image: null,
    expiryDate: null,
    isAttempting: false,
    subscriptionStatus: null,
  }
}

const initialState = initialize()

export default function user(state = initialState, action) {
  switch (action.type) {
    case `${Api.basePrefix}confirmBrokerEmail_success`: {
      if (action.payload.data.access && action.payload.data.refresh) {
        return {
          ...state,
          loggedIn: true,
          tokens: action.payload.data,
        }
      }

      return { ...state }
    }
    case `${Api.basePrefix}postConfirmInvite_success`: {
      return {
        ...state,
        loggedIn: true,
        tokens: action.payload.data,
      }
    }
    case `${Api.basePrefix}confirmInviteLink_success`: {
      return {
        ...state,
        loggedIn: true,
        tokens: action.payload.data,
      }
    }
    case `${Api.basePrefix}login_success`: {
      return {
        ...state,
        loggedIn: true,
        tokens: action.payload.data,
      }
    }
    case `${Api.basePrefix}registerUser_success`: {
      return {
        ...state,
        loggedIn: true,
        tokens: action.payload.data,
      }
    }
    case `${Api.basePrefix}init_success`: {
      const { user_and_rights: userData } = action.payload.data
      return {
        ...state,
        id: userData.id,
        username: userData.username,
        firstName: userData.first_name,
        lastName: userData.last_name,
        email: userData.email,
        phone: userData.phone,
        userPermissions: userData.user_permissions,
        groups: userData.groups,
        isAdmin: userData.is_admin,
        isOptedInToBdpBuildout: userData.is_opted_in_to_bdp_buildout,
        showFtuxBdpBanner: userData.show_ftux_bdp_banner,
        showFtuxBdpModal: userData.show_ftux_bdp_modal,
        userType: userData.user_type,
        userRole: userData.user_role,
        user_role: userData.user_role,
        company: userData.company,
        userAvatarImage: userData.user_avatar_image,
        expiryDate: userData.account_expiry_date,
        isAttempting: !!userData.is_attempting,
        subscriptionStatus:
          userData.subscription_status || USER_SUBSCRIPTION_STATUSES.FREE,
        confirmed: userData.confirmed,
      }
    }
    case ACTIONS.UPDATE_SUBSCRIPTION_STATUS: {
      const newData = {
        isAttempting: !!action.payload.is_attempting,
        expiryDate: action.payload.account_expiry_date,
        subscriptionStatus:
          action.payload.subscription_status || USER_SUBSCRIPTION_STATUSES.FREE,
      }

      return { ...state, ...newData }
    }
    case ACTIONS.BILLING_IS_LOADING: {
      return { ...state, isAttempting: action.payload.isAttempting }
    }
    case ACTIONS.LOGOUT:
      posthog.reset()
      deleteTokens()
      setupAuthorizationHeaders()
      return {
        ...initialize(),
        setRedirectUrl: action.payload && action.payload.setRedirectUrl,
      }
    case ACTIONS.UPDATE_USER_DATA: {
      return {
        ...state,
        ...action.data,
      }
    }
    default:
      return state
  }
}

export const actionUserLogout = (setRedirectUrl) => ({
  type: ACTIONS.LOGOUT,
  payload: {
    setRedirectUrl: !!setRedirectUrl,
  },
})

export const updateUserData = (data) => ({
  type: ACTIONS.UPDATE_USER_DATA,
  data,
})

export const actionUserUpdateSubscriptionStatus = (payload) => ({
  type: ACTIONS.UPDATE_SUBSCRIPTION_STATUS,
  payload,
})

export const actionUserUpdateBillingLoading = (isAttempting) => ({
  type: ACTIONS.BILLING_IS_LOADING,
  payload: {
    isAttempting,
  },
})
