import React from 'react';
import { Typography } from '~/legacy/components';
import { styled } from '~/legacy/utils/styleHelpers';

export const SimpleMenuItem = ({ children, option, ...props }) => {
  return (
    <Container {...props}>
      {option.before}
      {option.label && <Label>{option.label}</Label>}
      {children}
      {option.after}
    </Container>
  );
};

// maybe add default gap?
const Container = styled('div', {
  display: 'flex',
});

const Label = styled(Typography, {
  marginRight: 10,
  flex: 1,
});

Label.defaultProps = {
  variant: 'h3',
};
