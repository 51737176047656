import chunk from 'lodash/chunk'
import { count } from '~/support/count'
import { defineTemplate } from '~/pdfs/support/defineTemplate'
import { JLLCompare } from './pages/JLLCompare'

export const JLL = defineTemplate({
  id: 'ce696f59-ce45-43b8-b1fb-4f9a797804ef',

  name: 'Toronto',

  format: 'legal',

  landscape: true,

  pages: [JLLCompare],

  stats: ({ data, pages }) => {
    return [
      count('Building', data.buildings.length),
      count('Page', pages.length),
    ].join(', ')
  },

  build: (pdf) => {
    return chunk(pdf.data.buildings, pdf.settings.buildingsPerPage).map(
      (buildings, index) => ({
        id: `${JLLCompare.id}.${index}`,
        props: JLLCompare.props(pdf, buildings),
        template: JLLCompare,
      })
    )
  },

  settings: {
    state: {
      buildingsPerPage: 10,
      templateId: 0,
      broker: '',
      client: '',
    },
  },
})
