// @ts-check
import {
  Container,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from '@material-ui/core'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import React, { useEffect, useMemo, useState, Fragment } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'
import { useParams, useLocation, useHistory } from 'react-router'
import { format } from 'date-fns'
import {
  Breadcrumbs,
  InlineEditableValue,
  Loading,
  Typography,
} from '~/legacy/components'
import {
  setSurveyBuildings,
  setSurveyListings,
} from '~/legacy/store/actions/viewSurvey'
import {
  isBroker,
  useSurveyBuildingCustomFieldSelector,
  SnackbarUtils,
} from '~/legacy/utils'
import {
  BULK_IMPORT_CONSTANTS,
  getDataTypeByDisplayName,
  BULK_IMPORT_HELPERS,
  getModelFieldNameByDisplayName,
} from '~/legacy/utils/bulkImportUtils'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'
import { useSurveyListingCustomFieldSelector } from '~/legacy/utils/hooks/useSurveyListingCustomFieldSelector'
import {
  EditableDateCell,
  EditableMultilineStringCell,
  EditableNumberCell,
  EditableStringCell,
} from '~/legacy/components/tableComponents'
import { buildingApi, listingApi } from '~/legacy/fetchApi'
import { LEASE_DASHBOARD, LEASE_LOGIN, LEASE_PROJECTS } from '~/legacy/consts'

const BUILDING_RESERVED_FIELDS = ['Address', 'Building Notes', 'Amenities']
const SPACE_RESERVED_FIELDS = ['Space Name', 'Space Notes']

export default function BulkEditSurvey() {
  const { id: surveyId } = useParams()
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const selectedTab = queryParams.get('tab') || 'buildings'

  const classes = useStyles()
  const [isSurveyListingsLoading, setIsSurveyListingsLoading] = useState(false)
  const handleError = useErrorHandler()
  const dispatch = useDispatch()
  const user = useSelector((store) => store.user)

  const ref = React.createRef()
  const [isSticky, setIsSticky] = useState(false)

  const surveyBuildingsRedux = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  )

  const surveyListingsRedux = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  )

  useEffect(() => {
    const cachedRef = ref.current
    if (!cachedRef) {
      return () => {}
    }
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1)
      },
      { threshold: [1] }
    )
    observer.observe(cachedRef)

    return () => observer.unobserve(cachedRef)
  }, [ref])

  // FIXME: See if I can move this to use SWR instead of Redux
  // Also see if we can avoid using `redirectNoPermissionUser` that was copied from the BrokerSurvey
  useEffect(() => {
    // Ensure only one redirect goes off. Multiple messes with URL
    let redirected = false
    const redirectNoPermissionUser = () => {
      if (!redirected) {
        redirected = true
        let redirectUrl = ''
        if (user && user.loggedIn) {
          redirectUrl = isBroker(user) ? LEASE_PROJECTS : LEASE_DASHBOARD
        } else {
          // User an existing redirect param if one exists
          let redirect = ''
          const existingRedirect = queryParams.get('redirect')
          if (existingRedirect) {
            redirect = existingRedirect
          } else {
            redirect = `${encodeURIComponent(
              location.pathname
            )}${encodeURIComponent(location.search || '')}`
          }
          redirectUrl = `${LEASE_LOGIN}?redirect=${redirect}`
        }
        history.push(redirectUrl)
      }
    }

    const fetchSurveyListings = (redirectNoPermissionUser) => {
      setIsSurveyListingsLoading(true)
      return Api.getSurveyListings({ id: surveyId }).then((results) => {
        if (results && isBadAuthResponseMessage(results.detail)) {
          redirectNoPermissionUser()
        } else if (!results) {
          handleError(new Error('Error loading survey listings.'))
        } else {
          dispatch(setSurveyListings(results.survey_listings))
          dispatch(setSurveyBuildings(results.survey_buildings))
          setIsSurveyListingsLoading(false)
        }
      })
    }

    dispatch(setSurveyListings([]))
    fetchSurveyListings(redirectNoPermissionUser)
  }, [surveyId, dispatch, handleError])

  const {
    survey,
    buildingCustomFields,
    listingCustomFields,
    isLoading: isCustomFieldsLoading,
  } = useSurveyCustomFieldsSelector({ surveyId, enabled: Boolean(surveyId) })

  const {
    customFieldValues: buildingValues,
    isLoading: isBuildingValuesLoading,
    mutateChangeBuildingCustomFieldValue: updateBuildingCustomField,
    mutateCreateBuildingCustomFieldValue: createBuildingCustomField,
  } = useSurveyBuildingCustomFieldSelector({
    surveyId,
    enabled: Boolean(surveyId),
  })

  const {
    customFieldValues: listingValues,
    isLoading: isListingValuesLoading,
    mutateChangeListingCustomFieldValue: updateListingCustomField,
    mutateCreateListingCustomFieldValue: createListingCustomField,
  } = useSurveyListingCustomFieldSelector({
    surveyId,
    enabled: Boolean(surveyId),
  })

  const project = survey?.project
  const company = survey?.company

  const buildingColumns = useMemo(() => {
    const fieldType = BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING
    const totalFields =
      BUILDING_RESERVED_FIELDS.length + buildingCustomFields.length

    const allFields = BUILDING_RESERVED_FIELDS.map((field, index) => {
      const fieldDataType = getDataTypeByDisplayName(field)
      const modelName = getModelFieldNameByDisplayName(field)

      const isAmenities = modelName === 'amenities'

      return {
        displayName: field,
        // Put amenities as the last column
        index: isAmenities ? totalFields - 1 : index,
        fieldType,
        fieldDataType,
        reserved: true,
        modelName,
      }
    }).concat(
      buildingCustomFields.map((field, index) => {
        const fieldDataType = BULK_IMPORT_HELPERS.getFieldDataTypeById(
          field.data_type
        )
        const newIndex = index + BUILDING_RESERVED_FIELDS.length - 1

        return {
          displayName: field.label,
          index: newIndex,
          fieldType,
          fieldDataType,
          reserved: false,
          field,
          modelName: null,
        }
      })
    )

    return allFields.sort((a, b) => a.index - b.index)
  }, [buildingCustomFields])

  const spaceColumns = useMemo(() => {
    return SPACE_RESERVED_FIELDS.map((field, index) => {
      const fieldType = BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE
      const fieldDataType = getDataTypeByDisplayName(field)
      const modelName = getModelFieldNameByDisplayName(field)

      return {
        displayName: field,
        index,
        fieldType,
        fieldDataType,
        reserved: true,
        modelName,
      }
    }).concat(
      listingCustomFields.map((field, index) => {
        const fieldType = BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE
        const fieldDataType = BULK_IMPORT_HELPERS.getFieldDataTypeById(
          field.data_type
        )
        const newIndex = index + SPACE_RESERVED_FIELDS.length

        return {
          displayName: field.label,
          index: newIndex,
          fieldType,
          fieldDataType,
          reserved: false,
          field,
          modelName: null,
        }
      })
    )
  }, [listingCustomFields])

  const handleBuildingValueChange = ({
    entityId: buildingId,
    header,
    cell,
    value,
  }) => {
    const isCustomField = !header.reserved
    const isCreatingNewValue = !cell.id

    if (isCustomField) {
      if (isCreatingNewValue) {
        createBuildingCustomField({
          surveyId,
          buildingId,
          customFieldId: header.field.id,
          newValue: value,
        })
      } else {
        updateBuildingCustomField({
          id: cell.id,
          newValue: value,
        })
      }
    }

    if (!isCustomField) {
      if (header.modelName === 'address') {
        const addressValues = value.GOOGLE_ADDRESS

        // Copying the buildings to prevent the address being updated even when the request fails
        // I think this happens because `Api.updateBuildingAddress` already handles some redux state by itself
        const beforeEditBuildings = [...surveyBuildingsRedux]

        if (addressValues.address) {
          Api.updateBuildingAddress({
            id: buildingId,
            body: {
              ...addressValues,
            },
          }).then((res) => {
            const { error } = res.data

            if (error) {
              SnackbarUtils.error(error)
              dispatch(setSurveyBuildings(beforeEditBuildings))
            } else {
              const udpatedBuildings = surveyBuildingsRedux.map((sb) => {
                if (sb.building.id === buildingId) {
                  return {
                    ...sb,
                    building: {
                      ...sb.building,
                      ...res.data,
                    },
                  }
                }
                return { ...sb }
              })
              dispatch(setSurveyBuildings(udpatedBuildings))
            }
          })
        }

        return
      }

      const updatedBuilding = {
        [header.modelName]: value,
      }

      buildingApi
        .updatePartial({
          buildingId,
          partial: updatedBuilding,
        })
        .then(([, responseBuilding]) => {
          const udpatedBuildings = surveyBuildingsRedux.map((sb) => {
            if (sb.building.id === buildingId) {
              return {
                ...sb,
                building: {
                  ...sb.building,
                  ...responseBuilding,
                },
              }
            }
            return { ...sb }
          })
          dispatch(setSurveyBuildings(udpatedBuildings))
        })
        .catch((error) => {
          console.error(error)
          SnackbarUtils.error(`Error updating ${header.displayName}`)
          dispatch(setSurveyBuildings(surveyBuildingsRedux))
        })
    }
  }

  const handleListingValueChange = ({
    entityId: listingId,
    header,
    cell,
    value,
  }) => {
    const isCustomField = !header.reserved
    const isCreatingNewValue = !cell.id

    if (isCustomField) {
      if (isCreatingNewValue) {
        createListingCustomField({
          surveyId,
          listingId,
          customFieldId: header.field.id,
          newValue: value,
        })
      } else {
        updateListingCustomField({
          id: cell.id,
          newValue: value,
        })
      }
    }

    if (!isCustomField) {
      const updatedListing = {
        [header.modelName]: value,
      }

      listingApi
        .updatePartial({
          listingId,
          partial: updatedListing,
        })
        .then(([, responseListing]) => {
          const newListings = surveyListingsRedux.map((sl) => {
            if (sl.listing.id === listingId) {
              return {
                ...sl,
                listing: {
                  ...sl.listing,
                  ...responseListing,
                },
              }
            }
            return { ...sl }
          })
          dispatch(setSurveyListings(newListings))
        })
        .catch((error) => {
          console.error(error)
          SnackbarUtils.error(`Error updating ${header.displayName}`)
          dispatch(setSurveyListings(surveyListingsRedux))
        })
    }
  }

  if (
    isSurveyListingsLoading ||
    isCustomFieldsLoading ||
    isBuildingValuesLoading ||
    isListingValuesLoading
  ) {
    return (
      <div className={classes.loadingContainer}>
        <Loading isLoading size={40} />
      </div>
    )
  }

  const breadcrumbs = [
    company?.name
      ? {
          name: company.name,
          path: '/',
        }
      : null,
    project?.id
      ? {
          name: project.name,
          path: `/projects/${project.id}`,
          replace: true,
        }
      : {
          name: 'Surveys',
          path: isBroker(user) ? '/surveys' : '/dashboard',
        },
    {
      name: survey.name,
      path: `/surveys/view/${surveyId}`,
      replace: true,
    },
    { name: 'Bulk Edit' },
  ].filter(Boolean)

  return (
    <Container maxWidth={false} classes={{ root: classes.root }}>
      <div className={clsx(classes.breadcrumb, classes.maxWidth)}>
        <Breadcrumbs pieces={breadcrumbs} />
      </div>

      <div className={classes.topBar}>
        <Typography variant="h1">Bulk Edit</Typography>
      </div>

      <div
        className={clsx(classes.tabsContainer, isSticky && classes.tabsSticky)}
        ref={ref}
      >
        <Tabs
          className={classes.tabs}
          classes={{ flexContainer: classes.tabsFlexContainer }}
          value={selectedTab}
          onChange={(_, value) =>
            history.replace({
              pathname: location.pathname,
              search: `?tab=${value}`,
            })
          }
          indicatorColor="primary"
        >
          <Tab className={classes.tab} value="buildings" label="Buildings" />
          <Tab className={classes.tab} value="spaces" label="Spaces" />
        </Tabs>
      </div>

      {selectedTab === 'buildings' ? (
        <BuildingsTab
          buildingColumns={buildingColumns}
          buildingValues={buildingValues}
          surveyBuildings={surveyBuildingsRedux}
          onValueChange={handleBuildingValueChange}
        />
      ) : null}

      {selectedTab === 'spaces' ? (
        <SpacesTab
          spaceColumns={spaceColumns}
          surveyListings={surveyListingsRedux}
          listingValues={listingValues}
          onValueChange={handleListingValueChange}
        />
      ) : null}
    </Container>
  )
}

function BuildingsTab({
  buildingColumns,
  surveyBuildings,
  buildingValues,
  onValueChange,
}) {
  const classes = useStyles()
  const headerClasses = useHeaderCellStyles()
  const headers = buildingColumns

  const buildingCountString =
    surveyBuildings.length === 1
      ? '1 Building'
      : `${surveyBuildings.length} Buildings`

  const valuesByBuilding = groupBy(buildingValues, 'building_id')

  const data = surveyBuildings.map(({ building }) => {
    const values = []

    buildingColumns.forEach((column) => {
      const addressValue =
        column.reserved && column.modelName === 'address'
          ? {
              address: building.address,
              city: building.city,
              state: building.state,
              zip: building.zipcode,
              latitude: building.latitude,
              longitude: building.longitude,
            }
          : null

      const modelValue = column.reserved
        ? addressValue || building[column.modelName]
        : null
      const customFieldValue = !column.reserved
        ? valuesByBuilding[building.id]?.find(
            (item) => item.custom_field.id === column.field.id
          )
        : null

      values.push({
        id: column.reserved ? building.id : customFieldValue?.id,
        value: column.reserved ? modelValue : customFieldValue?.value,
      })
    })

    return {
      ...building,
      values,
    }
  })

  return (
    <>
      <div className={clsx(classes.counter)}>
        <div className={clsx(classes.maxWidth, classes.flexRow)}>
          <Typography className={classes.buildingsCount} variant="boldText">
            {buildingCountString}
          </Typography>
        </div>
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((header) => {
                // For the top left / right headers, make the corners rounded...
                const borderClasses = []
                const { index } = header
                const numColumns = headers.length

                if (index === 0 && index === numColumns - 1) {
                  borderClasses.push(headerClasses.rightSide)
                  borderClasses.push(headerClasses.bothCorners)
                } else {
                  if (index === 0) {
                    borderClasses.push(headerClasses.topLeftCorner)
                  }
                  if (index === numColumns - 1) {
                    borderClasses.push(headerClasses.rightSide)
                    borderClasses.push(headerClasses.topRightCorner)
                  } else {
                    borderClasses.push(headerClasses.notRightSide)
                  }
                }

                return (
                  <TableHeaderCell
                    key={header.index}
                    header={header}
                    borderClasses={borderClasses}
                  />
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((building, rowIndex) => {
              const rowCount = data.length
              const borderClass =
                rowIndex === rowCount - 1
                  ? classes.bottomBorderedCell
                  : classes.borderedCell

              return (
                <TableRow key={building.id} data-id={building.id}>
                  {building.values.map((cell, cellIndex) => {
                    const cellCount = building.values.length
                    const header = headers[cellIndex]
                    const DisplayCell =
                      cellComponentMap[header.fieldDataType.id] ?? StringCell

                    const isDescription =
                      header.displayName === 'Building Notes'

                    // Bottom left/right corners need radius
                    let cornerBorderClass = ''
                    // bottom row
                    if (rowIndex === rowCount - 1) {
                      if (cellIndex === 0) {
                        // first cell in row
                        cornerBorderClass = classes.bottomLeftCorner
                      } else if (cellIndex === cellCount - 1) {
                        // last in row
                        cornerBorderClass = classes.bottomRightCorner
                      }
                    }
                    const rightBorderClass =
                      cellIndex === cellCount - 1
                        ? classes.rightSide
                        : classes.notRightSide

                    const stickyClass =
                      header.index === 0 ? classes.stickyColumn : ''

                    return (
                      <TableCell
                        key={`${header.displayName}-${building.id}`}
                        data-id={`${header.displayName}-${building.id}`}
                        classes={{ root: classes.tableCell }}
                        className={clsx(
                          classes.fieldValueCell,
                          borderClass,
                          rightBorderClass,
                          cornerBorderClass,
                          stickyClass,
                          isDescription && classes.fieldValueCellNotes
                        )}
                        style={{ borderLeft: header.index === 1 ? 'none' : '' }}
                      >
                        <Tooltip
                          title={cell?.error || ''}
                          disableFocusListener
                          disableTouchListener
                          classes={{
                            tooltipPlacementBottom:
                              classes.tooltipPlacementBottom,
                          }}
                        >
                          <div
                            className={classes.fieldValueCellValueContainer}
                            style={{
                              backgroundColor: cell?.error
                                ? 'rgba(221, 66, 26, .15)'
                                : '',
                              color: cell?.error ? '#DD421A' : '',
                            }}
                          >
                            <DisplayCell
                              cell={cell || {}}
                              header={header}
                              onChange={(_, value) => {
                                onValueChange({
                                  entityId: building.id,
                                  header,
                                  cell,
                                  value,
                                })
                              }}
                            />
                          </div>
                        </Tooltip>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

function SpacesTab({
  spaceColumns,
  surveyListings,
  listingValues,
  onValueChange,
}) {
  const classes = useStyles()
  const headerClasses = useHeaderCellStyles()
  const headers = spaceColumns

  const listingCountString =
    surveyListings.length === 1 ? '1 Space' : `${surveyListings.length} Spaces`
  const listingsByBuilding = groupBy(surveyListings, 'listing.building.id')
  const valuesByListing = groupBy(listingValues, 'listing_id')

  const data = Object.keys(listingsByBuilding).map((buildingId) => {
    const { building } = listingsByBuilding[buildingId][0].listing
    const listings = listingsByBuilding[buildingId]

    const mappedListings = listings.map(({ listing }) => {
      const values = []

      spaceColumns.forEach((column) => {
        const modelValue = column.reserved ? listing[column.modelName] : null
        const customFieldValue = !column.reserved
          ? valuesByListing[listing.id]?.find(
              (item) => item.custom_field.id === column.field.id
            )
          : null

        values.push({
          id: column.reserved ? listing.id : customFieldValue?.id,
          value: column.reserved ? modelValue : customFieldValue?.value,
        })
      })

      return {
        ...listing,
        values,
      }
    })

    return { ...building, listings: mappedListings }
  })

  return (
    <>
      <div className={clsx(classes.counter)}>
        <div className={clsx(classes.maxWidth, classes.flexRow)}>
          <Typography className={classes.spacesCount} variant="boldText">
            {listingCountString}
          </Typography>
        </div>
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((header) => {
                // For the top left / right headers, make the corners rounded...
                const borderClasses = [classes.bottomBorderedCell]
                const { index } = header
                const numColumns = headers.length

                if (index === 0 && index === numColumns - 1) {
                  borderClasses.push(headerClasses.rightSide)
                  borderClasses.push(headerClasses.bothCorners)
                } else {
                  if (index === 0) {
                    borderClasses.push(headerClasses.topLeftCorner)
                    borderClasses.push(headerClasses.bottomLeftCorner)
                  }
                  if (index === numColumns - 1) {
                    borderClasses.push(headerClasses.rightSide)
                    borderClasses.push(headerClasses.topRightCorner)
                    borderClasses.push(headerClasses.bottomRightCorner)
                  } else {
                    borderClasses.push(headerClasses.notRightSide)
                  }
                }

                return (
                  <TableHeaderCell
                    key={header.index}
                    header={header}
                    borderClasses={borderClasses}
                  />
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((building) => {
              return (
                <Fragment key={building.id}>
                  <TableRow>
                    <TableCell
                      classes={{ root: classes.tableCell }}
                      className={clsx(
                        classes.stickyColumn,
                        classes.tableRowBuilding
                      )}
                    >
                      <Typography
                        variant="boldText"
                        className={classes.buildingAddress}
                      >
                        {building.address}
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={spaceColumns.length - 1}
                      classes={{ root: classes.tableCell }}
                    />
                  </TableRow>

                  {building.listings.map((listing, linstingIndex) => (
                    <TableRow key={listing.id} data-id={listing.id}>
                      {listing.values.map((cell, cellIndex) => {
                        const listingCount = building.listings.length
                        const cellCount = listing.values.length

                        const header = headers[cellIndex]
                        const DisplayCell =
                          cellComponentMap[header.fieldDataType.id] ??
                          StringCell

                        const isSpaceNotes =
                          header.displayName === 'Space Notes'

                        const borderClass =
                          linstingIndex === listingCount - 1
                            ? classes.bottomBorderedCell
                            : classes.borderedCell

                        // Border radius for all corners
                        const cornerBorderClasses = []

                        // top row
                        if (linstingIndex === 0) {
                          if (cellIndex === 0) {
                            // first cell in row
                            cornerBorderClasses.push(classes.topLeftCorner)
                          } else if (cellIndex === cellCount - 1) {
                            // last in row
                            cornerBorderClasses.push(classes.topRightCorner)
                          }
                        }
                        // bottom row
                        if (linstingIndex === listingCount - 1) {
                          if (cellIndex === 0) {
                            // first cell in row
                            cornerBorderClasses.push(classes.bottomLeftCorner)
                          } else if (cellIndex === cellCount - 1) {
                            // last in row
                            cornerBorderClasses.push(classes.bottomRightCorner)
                          }
                        }

                        const rightBorderClass =
                          cellIndex === cellCount - 1
                            ? classes.rightSide
                            : classes.notRightSide

                        const stickyClass =
                          header.index === 0 ? classes.stickyColumn : ''

                        return (
                          <TableCell
                            key={`${header.displayName}-${listing.id}`}
                            data-id={`${header.displayName}-${listing.id}`}
                            classes={{ root: classes.tableCell }}
                            className={clsx(
                              classes.fieldValueCell,
                              borderClass,
                              rightBorderClass,
                              stickyClass,
                              ...cornerBorderClasses,
                              isSpaceNotes && classes.fieldValueCellNotes
                            )}
                            style={{
                              borderLeft: header.index === 1 ? 'none' : '',
                            }}
                          >
                            <Tooltip
                              title={cell?.error || ''}
                              disableFocusListener
                              disableTouchListener
                              classes={{
                                tooltipPlacementBottom:
                                  classes.tooltipPlacementBottom,
                              }}
                            >
                              <div
                                className={classes.fieldValueCellValueContainer}
                                style={{
                                  backgroundColor: cell?.error
                                    ? 'rgba(221, 66, 26, .15)'
                                    : '',
                                  color: cell?.error ? '#DD421A' : '',
                                }}
                              >
                                <DisplayCell
                                  cell={cell || {}}
                                  header={header}
                                  onChange={(_, value) => {
                                    onValueChange({
                                      entityId: listing.id,
                                      header,
                                      cell,
                                      value,
                                    })
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

function TableHeaderCell({ header, borderClasses = [] }) {
  const classes = useHeaderCellStyles({ newHeader: header })
  const IconComponent = header.fieldDataType.icon
  const sticklyClass = header.index === 0 ? classes.stickyColumn : ''

  return (
    <TableCell
      align="center"
      classes={{ root: classes.tableCell }}
      className={clsx(
        classes.borderedCell,
        classes.headerCell,
        sticklyClass,
        ...borderClasses
      )}
      style={{ borderLeft: header.index === 1 ? 'none' : '' }}
    >
      <Tooltip title="" disableFocusListener disableTouchListener>
        <div className={classes.headerNameContainer}>
          <IconComponent className={classes.headerIcon} />
          <Typography
            noWrap
            variant="body2"
            style={{ letterSpacing: '.4px', fontFamily: 'Inter-Medium' }}
          >
            {header.displayName.toUpperCase()}
          </Typography>
        </div>
      </Tooltip>
    </TableCell>
  )
}

// The cell displayed on the table to the user for the Address
const AddressFieldCell = ({ cell, header, onChange }) => {
  const addressValue = {
    GOOGLE_ADDRESS: { ...cell.value },
    ...cell.value.GOOGLE_AUTOCOMPLETE_RESULTS,
  }

  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell)}
      hoverOnContainer
      placeholder="Enter a value"
      onClick={() => {}}
      type="address"
      fieldDataTypeId={header.fieldDataType.id}
      updateValueApiCallback={onChange}
      value={addressValue || null}
      formatDisplayValue={(newValue) => {
        return newValue
          ? {
              address: newValue.GOOGLE_ADDRESS.address,
              state: newValue.GOOGLE_ADDRESS.state,
              city: newValue.GOOGLE_ADDRESS.city,
              zipcode: newValue.GOOGLE_ADDRESS.zipcode,
            }
          : null
      }}
      prepareNewValue={(newValue) => {
        return {
          ...newValue.GOOGLE_AUTOCOMPLETE_RESULTS,
          GOOGLE_ADDRESS: { ...newValue.GOOGLE_ADDRESS },
        }
      }}
    />
  )
}

// The cell displayed on the table to the user for numeric types
const NumericFieldCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <EditableNumberCell
      value={rawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue || null : undefined}
      formatDisplayValue={() =>
        !cell.error
          ? BULK_IMPORT_HELPERS.getNumberValueForDisplay(
              rawValue,
              header.fieldDataType.id
            )
          : rawValue
      }
    />
  )
}

// Date cells
const DateFieldCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  const noTimeRawValue =
    cell.error || !rawValue
      ? rawValue
      : BULK_IMPORT_HELPERS.prepareDate(rawValue)

  return (
    <EditableDateCell
      value={noTimeRawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? noTimeRawValue : undefined}
      formatDisplayValue={(valueToFormat) =>
        valueToFormat && !cell.error
          ? format(valueToFormat, 'MMM d, y')
          : valueToFormat
      }
    />
  )
}

const ListSemicolonCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell, true)}
      hoverOnContainer
      placeholder="Enter a value"
      autoFocus={false}
      onClick={() => {}}
      type="multi-select"
      fieldDataTypeId={header.fieldDataType.id}
      options={[]}
      value={rawValue}
      displayValueOverride={cell.error ? rawValue : undefined}
      updateValueApiCallback={onChange}
    />
  )
}

const StringCell = ({
  cell,
  header,
  onChange,
  CellClass = EditableStringCell,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <CellClass
      value={rawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue : undefined}
      field={{ label: header.displayName }}
    />
  )
}

const MultilineStringCell = (props) => (
  <StringCell CellClass={EditableMultilineStringCell} {...props} />
)

const cellComponentMap = {
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.ADDRESS.id]: AddressFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id]: DateFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id]: ListSemicolonCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.MULTILINE_STRING.id]:
    MultilineStringCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id]: StringCell,
}

const defaultStyleOverrides = (cell, isSemicolon) => {
  const defaultOverrides = {
    container: {
      padding: '8px',
      cursor: isSemicolon ? '' : 'pointer',
    },
  }
  if (cell.error) {
    defaultOverrides.hoverBackgroundColor = 'rgba(221, 66, 26, .15)'
  }
  return defaultOverrides
}

const isBadAuthResponseMessage = (responseMessage) => {
  return [
    'You do not have permission to perform this action.',
    'Authentication credentials were not provided.',
  ].includes(responseMessage)
}

const BORDERED_CELL = {
  borderLeft: '1px solid #E0E0E0',
  borderTop: '1px solid #E0E0E0',
  borderBottom: 0,
}

const useHeaderCellStyles = makeStyles({
  headerIcon: {
    marginRight: '8px',
  },
  tableCell: {
    borderBottom: 'none',
  },
  headerCell: {
    backgroundColor: '#F9F9F9',
    padding: '6px 12px 6px 24px',
    height: '48px',
    maxHeight: '48px',
  },
  borderedCell: BORDERED_CELL,
  rightSide: {
    borderRight: '1px solid #E0E0E0',
  },
  notRightSide: {
    borderRight: 0,
  },
  headerNameContainer: {
    color: '#666',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  chevronDown: {
    color: '#666',
  },
  bottomLeftCorner: {
    borderBottomLeftRadius: '4px',
  },
  bottomRightCorner: {
    borderBottomRightRadius: '4px',
  },
  topLeftCorner: {
    borderTopLeftRadius: '4px',
  },
  topRightCorner: {
    borderTopRightRadius: '4px',
  },
  bothCorners: {
    borderRadius: '4px 4px 0 0 ',
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    borderRight: '2px solid #E0E0E0',
  },
})

const useStyles = makeStyles({
  root: {
    padding: '0 32px 32px 32px',
    height: 'fit-content',
  },
  topBar: {
    padding: '24px 0 16px 0',
  },
  tabs: {
    height: '100%',
  },
  tabsFlexContainer: {
    height: '100%',
  },
  tabsContainer: {
    position: 'sticky',
    top: '-1px',
    zIndex: 3,
    background: '#fff',
    height: '54px',
    boxShadow:
      'inset 0 0 0 #e0e0e0, inset 0 -1px 0 #e0e0e0, inset 0 0 0 #e0e0e0, inset 0 0 0 #e0e0e0',
  },
  tab: {
    minWidth: 'unset',
    opacity: 1,
  },
  loadingContainer: {
    marginTop: '50px',
  },
  counter: {
    display: 'flex',
    height: '68px',
  },
  tabsSticky: {
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-1px',
      left: '-32px',
      right: '-32px',
      height: '1px',
      backgroundColor: '#e0e0e0',
    },
  },
  maxWidth: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  buildingsCount: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '16px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  spacesCount: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '16px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  tableContainer: {
    flex: 'none',
    overflowY: 'visible',
    overflowX: 'auto',
  },
  table: {
    borderCollapse: 'separate',
  },
  tableCell: {
    borderBottom: 'none',
  },
  fieldValueCell: {
    width: '100px',
    padding: '12px 16px 12px 16px',
    height: '80px',
  },
  fieldValueCellNotes: {
    maxWidth: '344px',
  },
  fieldValueCellValueContainer: {
    maxHeight: '56px', // 80 - 12 - 12 padding
    display: 'flex',
    alignItems: 'center',
  },
  tableRowBuilding: {
    height: '84px',
    paddingTop: '36px',
    paddingBottom: '20px',
    paddingLeft: 0,
    whiteSpace: 'nowrap',
    border: 'none !important',
  },
  buildingAddress: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '28px',
  },
  tooltipPlacementBottom: {
    margin: '8px 0',
  },
  breadcrumb: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '44px', // button height
  },
  borderedCell: BORDERED_CELL,
  bottomBorderedCell: {
    borderLeft: '1px solid #E0E0E0',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
  },
  bottomLeftCorner: {
    borderBottomLeftRadius: '4px',
  },
  bottomRightCorner: {
    borderBottomRightRadius: '4px',
  },
  topLeftCorner: {
    borderTopLeftRadius: '4px',
  },
  topRightCorner: {
    borderTopRightRadius: '4px',
  },
  rightSide: {
    borderRight: '1px solid #E0E0E0',
  },
  notRightSide: {
    borderRight: 0,
  },
  stickyRow: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: '#F9F9F9',
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    borderRight: '2px solid #E0E0E0',
    backgroundColor: '#fff',
  },
})
