import React from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  SurveyBuildingsMap,
  TABLE_WINDOW_MIN_WIDTH_PX,
} from '~/legacy/components';

const useStyles = makeStyles({
  drawer: {
    paddingTop: '60px',
    width: '33%',
    maxWidth: `calc(100vw - ${TABLE_WINDOW_MIN_WIDTH_PX}px)`,
  },
  hideButton: {
    backgroundColor: '#ffffff',
    border: '1px solid #d8d8d8',
    position: 'absolute',
    top: '77px', // 60 for the header, 17 for padding
    right: '17px',
    height: '36px',
    padding: '0 16px',
  },
});

export default function MapDrawer({
  isUserInBuildout = false,
  open,
  setOpen,
  surveyListings,
  surveyBuildings,
  hoveredBuildingId,
  surveyId,
  activeMapMarker,
  setActiveMapMarker,
}) {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.drawer,
      }}
      open={open}
      variant="persistent"
    >
      <SurveyBuildingsMap
        isUserInBuildout={isUserInBuildout}
        navigateToListing
        surveyListings={surveyListings}
        surveyBuildings={surveyBuildings}
        hoveredBuildingId={hoveredBuildingId}
        surveyId={surveyId}
        activeMapMarker={activeMapMarker}
        setActiveMapMarker={setActiveMapMarker}
      />
      <Button
        className={classes.hideButton}
        onClick={() => {
          setActiveMapMarker(null);
          setOpen(false);
        }}
        shrinkButton
      >
        Hide Map
      </Button>
    </Drawer>
  );
}
