import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@material-ui/core';
import clsx from 'clsx';
import { Typography, MenuSelectedItemIcon } from '~/legacy/components';

const useStyles = makeStyles({
  root: {
    '& .MuiFormLabel-root.Mui-error': {
      color: '#666',
    },
  },
  selectedItem: {
    marginLeft: 'auto',
    color: '#666',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&.Mui-selected': {
      backgroundColor: '#F3F3F3',
    },
  },
});

function Select({
  classes = {},
  className = '',
  errorText,
  label,
  onChange,
  options,
  required = false,
  showNone = true,
  value = undefined,
  selectParams = {},
}) {
  const classesLocal = useStyles();
  const labelId = `${label}-input-label`;
  const optionsMap = {};
  options.forEach((option) => {
    optionsMap[option.value] = option.label;
  });

  return (
    <FormControl
      className={clsx(classesLocal.root, className)}
      error={!!errorText}
      fullWidth
      required={required}
      variant="outlined"
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect
        classes={classes}
        label={label}
        labelId={labelId}
        onChange={onChange}
        value={value !== undefined && value !== null ? value : ''}
        renderValue={(renderValue) => {
          return <Typography>{optionsMap[renderValue] || ''}</Typography>;
        }}
        {...selectParams}
      >
        {!required && showNone && <MenuItem value="">- none -</MenuItem>}
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classesLocal.menuItem}
          >
            <Typography variant="boldText">{option.label}</Typography>
            {!!(option.value === value) && (
              <MenuSelectedItemIcon className={classesLocal.selectedItem} />
            )}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}

export default memo(Select);
