import { useCallback } from 'react'
import { useAuthTokens } from './useAuthTokens'

export const useFetch = () => {
  const [tokens] = useAuthTokens()
  return useCallback(
    async (url, init = {}) => {
      const method = init.method ?? 'POST'
      const headers = new Headers(init.headers)
      const body =
        method !== 'GET' ? JSON.stringify(init.body ?? null) : undefined
      if (tokens.access) headers.set('Authorization', `Bearer ${tokens.access}`)
      const response = await fetch(url, { ...init, body, headers, method })
      if (response.ok) return response.json()
      return null
    },
    [tokens]
  )
}
