export const createIndexedDB = async (name, migrations = []) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(name, migrations.length)
    request.onerror = (error) => reject(error)
    request.onsuccess = () => resolve(request.result)
    request.onupgradeneeded = (event) => {
      migrations.slice(event.oldVersion).forEach((migrate) => {
        migrate(request.result)
      })
    }
  })
}

export const getWhereEqual = async (db, table, key, value) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([table], 'readonly')
    transaction.onerror = (error) => reject(error)
    transaction.oncomplete = () => resolve(results)

    const results = []
    const range = IDBKeyRange.only(value)
    const cursor = transaction.objectStore(table).index(key).openCursor(range)
    cursor.onsuccess = () => {
      if (!cursor.result) return
      results.push(cursor.result.value)
      cursor.result.continue()
    }
  })
}

export const createMany = async (db, table, data) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([table], 'readwrite')
    transaction.onerror = (error) => reject(error)
    transaction.oncomplete = () => resolve()

    const store = transaction.objectStore(table)
    data.forEach((data) => store.add(data))
  })
}
