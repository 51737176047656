import { useEffect, useRef, useState } from 'react'
import { useChanges } from './useChange'

export const useFieldWithDelay = (value, setValue, delay = 500) => {
  const [input, setInput] = useState(value ?? '')
  const id = useRef(null)

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => clearTimeout(id.current)
  }, [])

  useChanges(value, setInput)

  return {
    value: input,
    onChange: (event) => {
      const { value } = event.target
      clearTimeout(id.current)
      id.current = setTimeout(() => setValue(value), delay)
      setInput(value)
    },
    onReset: () => {
      setValue('')
    },
  }
}
