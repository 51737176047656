import React, { useState } from 'react';
import { Menu } from '@material-ui/core';
import {
  useAdminDeleteSurveyMenuItem,
  useTransferSurveyOwnershipMenuItem,
} from '~/legacy/components';

export const useAdminSurveyMenu = ({
  brokers,
  onDeleteSurveys = () => {},
  onTransferSurveys = () => {},
}) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState({});
  const selectedSurvey =
    anchorMenuEl && anchorMenuEl.survey ? anchorMenuEl.survey : {};

  const handleMenuClick = React.useCallback((event, survey) => {
    setAnchorMenuEl({ anchor: event.currentTarget, survey });
  }, []);

  const handleMenuClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorMenuEl({ ...anchorMenuEl, anchor: null });
  };

  const {
    AdminDeleteSurveyMenuItemComponent,
    AdminDeleteSurveyModalComponent,
  } = useAdminDeleteSurveyMenuItem({
    survey: { name: selectedSurvey.name, id: selectedSurvey.id },
    onDeleteSurveys,
    handleMenuClose,
  });

  const {
    TransferSurveyOwnershipMenuItemComponent,
    TransferSurveyOwnershipModalComponent,
  } = useTransferSurveyOwnershipMenuItem({
    brokers,
    survey: {
      name: selectedSurvey.name,
      id: selectedSurvey.id,
      owner: selectedSurvey.owner,
    },
    onTransferSurveys,
    handleMenuClose,
  });

  const AdminSurveyMenuComponent = (
    <AdminSurveyMenu
      anchorMenuEl={anchorMenuEl ? anchorMenuEl.anchor : null}
      handleMenuClose={handleMenuClose}
      DeleteSurveyMenuItemComponent={AdminDeleteSurveyMenuItemComponent}
      TransferSurveyOwnershipMenuItemComponent={
        TransferSurveyOwnershipMenuItemComponent
      }
    />
  );

  return {
    AdminSurveyMenuComponent,
    handleMenuClick,
    AdminDeleteSurveyModalComponent,
    TransferSurveyOwnershipModalComponent,
  };
};

export const AdminSurveyMenu = ({
  anchorMenuEl,
  handleMenuClose,
  DeleteSurveyMenuItemComponent,
  TransferSurveyOwnershipMenuItemComponent,
}) => {
  return (
    <Menu
      id="more-options-menu"
      anchorEl={anchorMenuEl}
      open={!!anchorMenuEl}
      onClose={handleMenuClose}
      elevation={2}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
    >
      {TransferSurveyOwnershipMenuItemComponent}
      {DeleteSurveyMenuItemComponent}
    </Menu>
  );
};
