export const RECOMMENDED_SORT = 0;

export const PRICE_LOW_TO_HIGH = 1;
export const PRICE_HIGH_TO_LOW = 2;

export const SIZE_SMALL_TO_LARGE = 3;
export const SIZE_LARGE_TO_SMALL = 4;

export const UPDATED_NEW_TO_OLD = 5;
export const UPDATED_OLD_TO_NEW = 6;

export const DATE_ADDED_NEW_TO_OLD_SORT = 7;
export const DATE_ADDED_OLD_TO_NEW_SORT = 8;
