import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

// TODO: How to share these across score SVGs
const useStyles = makeStyles({
  root: {
    display: 'block',
    width: '100%',
  },
  svgText: {
    fill: '#4a4a4a',
    border: '#4a4a4a',
    fontSize: props => props.fontSize,
    fontFamily: 'ProximaNova-SemiBold',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0em',
  },
  stroke: {
    fillOpacity: 0,
    stroke: '#4a4a4a',
    fill: '#fff',
    strokeWidth: 20,
    strokeOpacity: 1,
    opacity: 1,
  },
});

function WalkScoreSvg({ walkscore, height, fontSize }) {
  const classes = useStyles({ height, fontSize, walkscore });

  return (
    <svg
      viewBox="2 2.543 620 650"
      width={60}
      height={height}
      className={classes.root}
    >
      <defs>
        <path
          d="M575.58 12.54c20.12 0 36.42 16.31 36.42 36.42v421.46c0 28.79-23.34 52.12-52.12 52.12h-95.31c-20.65 0-40.46 8.19-55.08 22.78-13 12.96-45.49 45.37-97.49 97.22-56.99-57.3-92.61-93.11-106.85-107.43-8-8.04-18.88-12.57-30.22-12.57H55.97c-24.29 0-43.97-19.68-43.97-43.96V53.28c0-22.5 18.24-40.74 40.74-40.74h522.84z"
          id="walkscore-outline"
        />
      </defs>
      <use href="#walkscore-outline" fill="#fff" fillOpacity={0} />
      <use href="#walkscore-outline" className={classes.stroke} />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        className={classes.svgText}
      >
        {walkscore}
      </text>
    </svg>
  );
}
WalkScoreSvg.propTypes = {
  walkscore: PropTypes.number,
  fontSize: PropTypes.string,
  height: PropTypes.string,
};
WalkScoreSvg.defaultProps = {
  walkscore: 0,
  fontSize: '224px',
  height: '62px',
};

export default WalkScoreSvg;
