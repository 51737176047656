import {
  actionUserUpdateSubscriptionStatus,
  actionUserUpdateBillingLoading,
} from '~/legacy/store';
import { USER_SUBSCRIPTION_STATUSES } from '~/legacy/consts';
import { toast } from '~/legacy/utils/notifications';

const subscriptionMiddleware = (store) => (next) => (action) => {
  if (action && action.type && action.type.includes('createSubscription')) {
    const {
      data,
      subscription_status: subscriptionStatus,
      is_attempting: isAttempting,
    } = action.payload.data || {};
    if (subscriptionStatus === USER_SUBSCRIPTION_STATUSES.PAID) {
      store.dispatch(actionUserUpdateSubscriptionStatus(action.payload.data));
      toast('Subscribed successfully', { appearance: 'success' });
    } else if (isAttempting) {
      toast('Please, wait few moments', { appearance: 'warning' });
    } else if (
      typeof data === 'string' &&
      data.toLowerCase().includes('error')
    ) {
      store.dispatch(actionUserUpdateBillingLoading(false));
      toast('Please, try again! Something went wrong', {
        appearance: 'error',
      });
    }
  }

  next(action);
};

export default subscriptionMiddleware;
