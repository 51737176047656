import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PublicHeader } from '~/legacy/components';
import { RESET_PASSWORD } from '~/legacy/consts';

const useStyles = makeStyles({
  resetPasswordContainer: {
    position: 'absolute',
    top: '20%',
    padding: '40px 60px',
  },
});

const SetPasswordContent = ({ SetPasswordModalClass }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.resetPasswordContainer} elevation={0}>
      <SetPasswordModalClass
        allowClose={false}
        initialWindow={RESET_PASSWORD}
      />
    </Paper>
  );
};

const SetPassword = ({ SetPasswordModalClass }) => {
  return (
    <PublicHeader
      contentComponent={
        <SetPasswordContent SetPasswordModalClass={SetPasswordModalClass} />
      }
    />
  );
};

export default SetPassword;
