import { BUILDING_INTEREST_LOOKUP } from '~/legacy/consts';
import {
  formatId,
  getDataTypeNameByDataTypeId,
  SEGMENT_PARAM_NAMES,
} from '~/legacy/utils';
import { fetchApi } from './rootApi';

const BUILDING_ENDPOINT_ROOT = 'buildings/';

export const buildingApi = {
  updatePartial: async ({ buildingId, partial }) =>
    fetchApi({
      endpoint: `${BUILDING_ENDPOINT_ROOT}${buildingId}/`,
      fetchArgs: {
        method: 'PATCH',
        body: JSON.stringify(partial),
      },
      trackingArgs: {
        name: 'Update Building Partial',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
        },
      },
    }),

  createCustomField: async ({
    buildingId,
    name = '',
    value = '',
    dataType = 1,
  }) =>
    fetchApi({
      endpoint: `${BUILDING_ENDPOINT_ROOT}${buildingId}/add_custom_field/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ name, value, data_type: dataType }),
      },
      trackingArgs: {
        name: 'Create Building Custom Field',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
          [SEGMENT_PARAM_NAMES.FIELD_NAME]: name,
          [SEGMENT_PARAM_NAMES.FIELD_DATA_TYPE]:
            getDataTypeNameByDataTypeId(dataType),
        },
      },
    }),

  updateCustomField: async ({ buildingId, buildingCustomField }) =>
    fetchApi({
      endpoint: `${BUILDING_ENDPOINT_ROOT}${buildingId}/update_custom_field/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          name: buildingCustomField.name,
          value: buildingCustomField.custom_field.value,
          data_type: buildingCustomField.custom_field.data_type,
          custom_field_id: buildingCustomField.id,
        }),
      },
      trackingArgs: {
        name: 'Update Building Custom Field',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
          [SEGMENT_PARAM_NAMES.BUILDING_CUSTOM_FIELD_ID]: formatId(
            buildingCustomField.id
          ),
          [SEGMENT_PARAM_NAMES.FIELD_NAME]: name,
          [SEGMENT_PARAM_NAMES.FIELD_DATA_TYPE]: getDataTypeNameByDataTypeId(
            buildingCustomField.custom_field.data_type
          ),
        },
      },
    }),

  deleteCustomField: async ({ buildingId, buildingCustomFieldId }) =>
    fetchApi({
      endpoint: `${BUILDING_ENDPOINT_ROOT}${buildingId}/delete_custom_field/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          custom_field_id: buildingCustomFieldId,
        }),
      },
      trackingArgs: {
        name: 'Delete Building Custom Field',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
          [SEGMENT_PARAM_NAMES.BUILDING_CUSTOM_FIELD_ID]: formatId(
            buildingCustomFieldId
          ),
        },
      },
    }),

  // TODO: move this to surveyBuilding.js?
  // TODO: also do we even call this anymore?
  updateInterest: async ({ buildingId, surveyId, newInterest }) =>
    fetchApi({
      endpoint: `${BUILDING_ENDPOINT_ROOT}${buildingId}/update_interest/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ survey_id: surveyId, interest: newInterest }),
      },
      trackingArgs: {
        name: 'Update Survey Building Interest',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_ID]: formatId(buildingId),
          [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
          [SEGMENT_PARAM_NAMES.SURVEY_BUILDING_INTEREST]:
            BUILDING_INTEREST_LOOKUP[newInterest],
        },
      },
    }),
};
