import React, { useState } from 'react';
import Api from 'rest-fetcher-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Button, AddressAutocomplete } from '~/legacy/components';
import { ModalTitle } from '~/legacy/components/modals';
import ModalComponent from '../ModalComponent';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    outline: 'none',
    overflowY: 'hidden',
    width: '548px',
  },
  modalTitle: {
    marginBottom: '32px',
  },
  buttons: {
    marginTop: '48px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: '12px',
  },
});

const EditBuildingAddressModal = ({
  ModalComponentProps = {
    onClose: () => {},
    onAfterClose: () => {},
  },
  building,
  updateBuilding,
}) => {
  const classes = useStyles();
  const [newAddressValues, setNewAddressValues] = useState(building);
  const [errorText, setErrorText] = useState('');

  return (
    <ModalComponent {...ModalComponentProps}>
      <div className={classes.content} tabIndex={-1}>
        <ModalTitle
          classNames={{ container: classes.modalTitle }}
          onClose={ModalComponentProps.onClose}
          title="Edit Building Address"
        />
        <AddressAutocomplete
          label="Address"
          error={!!errorText}
          helperText={errorText}
          fetchFreshBuildingData={false}
          controlledValue={newAddressValues}
          setAddressValues={(newValues) => {
            setErrorText('');
            setNewAddressValues(newValues);
          }}
        />
        <div className={classes.buttons}>
          <Button color="secondary" onClick={ModalComponentProps.onClose}>
            Cancel
          </Button>
          <Button
            disabled={newAddressValues.address === null}
            className={classes.saveButton}
            color="primary"
            onClick={() => {
              Api.updateBuildingAddress({
                id: building.id,
                body: {
                  ...newAddressValues,
                },
              }).then((res) => {
                if (res.data.error) {
                  setErrorText(res.data.error);
                } else {
                  updateBuilding(res.data);
                  ModalComponentProps.onClose();
                }
              });
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default EditBuildingAddressModal;
