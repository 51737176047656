import Api from 'rest-fetcher-redux';
import {
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_SKIN,
  LOCAL_STORAGE_SDP,
  LOCAL_STORAGE_CUSTOM_SKIN,
} from '~/legacy/consts';
import { setupAuthorizationHeaders } from '~/legacy/utils';

const setWithExpiry = (key, value, ttlMs) => {
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttlMs,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return { value: null, expired: false };
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    localStorage.removeItem(key);
    return { value: item.value, expired: true };
  }
  return { value: item.value, expired: false };
};

function storeTokensToLocalStorage(accessToken, refreshToken) {
  const tokens = {
    access: accessToken,
    refresh: refreshToken,
  };

  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(tokens));
  setCookie('access_token', tokens.access);
}

function deleteTokens() {
  deleteCookie('access_token');
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  localStorage.removeItem(LOCAL_STORAGE_SKIN);
}

function getTokensFromLocalStorage() {
  let tokens = null;
  try {
    tokens = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    tokens = JSON.parse(tokens);

    if (!tokens || !tokens.access || !tokens.refresh) {
      throw new Error();
    }

    // Backfill cookie if it doesn't exist
    setCookie('access_token', tokens.access);
  } catch (error) {
    deleteTokens();
    tokens = null;
  }

  return tokens;
}

const fillLocalStorage = (accessToken, refreshToken, skin) => {
  storeTokensToLocalStorage(accessToken, refreshToken);
  if (skin) {
    localStorage.setItem(LOCAL_STORAGE_SKIN, skin);
  }
};

const setAuthLocalStorage = (
  accessToken,
  refreshToken,
  skin,
  reloadIfSkin = true
) => {
  fillLocalStorage(accessToken, refreshToken, skin);

  if (skin && reloadIfSkin) {
    // TODO: figure out how to cache bust styles?
    location.reload();
  }

  setupAuthorizationHeaders();

  Api.init();
};

const getSdpViewLocalStorage = () => {
  return localStorage.getItem(LOCAL_STORAGE_SDP);
};

const setSdpViewLocalStorage = (view) => {
  localStorage.setItem(LOCAL_STORAGE_SDP, view);
};


function getLocalStorage(key, fallbackValue) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch {
    return fallbackValue;
  }
}

function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function setCookie(key, value, ttlMs = 1000 * 60 * 60 * 24 * 14) {
  const expires = new Date(new Date().getTime() + ttlMs).toUTCString();
  document.cookie = `${key}=${value}; expires=${expires}; path=/; samesite=strict; secure;`;
}

function deleteCookie(key) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; samesite=strict; secure;`;
}

const getCustomSkin = () => {
  return getWithExpiry(LOCAL_STORAGE_CUSTOM_SKIN);
};

// 60 minutes
const LOCAL_STORAGE_CUSTOM_SKIN_EXP_TIME_MS = 60 * 60 * 1000;
const setCustomSkin = (skin) => {
  return setWithExpiry(
    LOCAL_STORAGE_CUSTOM_SKIN,
    skin,
    LOCAL_STORAGE_CUSTOM_SKIN_EXP_TIME_MS
  );
};

export {
  setWithExpiry,
  getWithExpiry,
  getTokensFromLocalStorage,
  storeTokensToLocalStorage,
  deleteTokens,
  setAuthLocalStorage,
  fillLocalStorage,
  getSdpViewLocalStorage,
  setSdpViewLocalStorage,
  getLocalStorage,
  setLocalStorage,
  getCustomSkin,
  setCustomSkin,
};
