import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'block',
    width: '100%',
  },
  svgText: {
    fill: '#4a4a4a',
    border: '#4a4a4a',
    fontSize: props => props.fontSize,
    fontFamily: 'ProximaNova-SemiBold',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0em',
  },
  stroke: {
    fillOpacity: 0,
    stroke: '#4a4a4a',
    strokeWidth: 20,
    strokeOpacity: 1,
    opacity: 1,
  },
});

function TransitScoreSvg({ transitScore, height, fontSize }) {
  const classes = useStyles({ height, fontSize, transitScore });

  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      viewBox="4 3 620 704"
      width="60"
      height={height}
      className={classes.root}
    >
      <defs>
        <path
          d="M111.96 619.21C111.96 646.54 111.96 663.63 111.96 670.47C111.96 685.84 124.43 698.31 139.8 698.31C150.23 698.31 149.19 698.31 158.5 698.31C176.89 698.31 191.75 683.29 191.55 664.9C191.49 658.81 191.33 643.58 191.07 619.21L428.38 619.21C428.38 643.57 428.38 658.81 428.38 664.9C428.38 683.35 443.34 698.31 461.79 698.31C470.93 698.31 464.93 698.31 474.07 698.31C492.52 698.31 507.48 683.35 507.48 664.9C507.48 658.81 507.48 643.57 507.48 619.21C544.36 619.21 567.41 619.21 576.63 619.21C598.14 619.21 615.25 601.16 614.1 579.69C608.59 476.84 594.19 207.65 588.69 104.76C587.43 81.25 571.4 61.12 548.77 54.64C501.13 40.98 514.28 44.76 496.21 39.58C374.87 4.8 246.23 4.7 124.84 39.28C106.44 44.52 121.62 40.19 74.38 53.65C49.62 60.7 32.22 82.89 31.27 108.62C27.53 210.74 17.92 472.87 14.18 574.72C13.29 599.02 32.75 619.21 57.07 619.21C64.39 619.21 82.68 619.21 111.96 619.21Z"
          id="transitscore-outline"
        />
      </defs>
      <g>
        <g>
          <g>
            <use href="#transitscore-outline" className={classes.stroke} />
          </g>
        </g>
      </g>
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        className={classes.svgText}
      >
        {transitScore}
      </text>
    </svg>
  );
}
TransitScoreSvg.propTypes = {
  transitScore: PropTypes.number,
  fontSize: PropTypes.string,
  height: PropTypes.string,
};
TransitScoreSvg.defaultProps = {
  transitScore: 0,
  fontSize: '224px',
  height: '62px',
};

export default TransitScoreSvg;
