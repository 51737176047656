import { Collapse, lighten, withStyles } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'

import { CloseIcon, TextLink, Typography } from '~/legacy/components'
import { updateUserData } from '~/legacy/store'
import { isBroker, IsPreviewContext } from '~/legacy/utils'
import { openLink } from '../utils/openLink'

export const FtuxBdpBanner = withStyles((theme) => ({
  root: {
    position: 'sticky',
    width: '100%',
    top: '0px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    zIndex: 100,
  },
  contentRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 32px 0 32px',
    height: '60px',
  },
  collapseWrapperInner: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  previewText: {
    lineHeight: '24px',
  },
  learnMore: {
    display: 'flex',
    alignItems: 'center',
  },
  viewSupport: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    marginRight: '12px',
  },
  closeIcon: {
    cursor: 'pointer',
  },
}))(({ classes }) => {
  const dispatch = useDispatch()
  const user = useSelector((s) => s.user)
  const { isPreview } = React.useContext(IsPreviewContext)

  // Update the local user data (closese the modal), and then update via api
  const onCloseBanner = () => {
    dispatch(updateUserData({ showFtuxBdpBanner: false }))
    Api.saveAccountSettings({
      id: user.id,
      body: {
        show_ftux_bdp_banner: false,
      },
    })
  }

  return (
    <div className={classes.root}>
      <Collapse
        in={user && isBroker(user) && !isPreview && user.showFtuxBdpBanner}
        classes={{ wrapperInner: classes.collapseWrapperInner }}
      >
        <div className={classes.contentRoot}>
          <div className={classes.learnMore}>
            <Typography className={classes.previewText} variant="bodyBold">
              Learn more about building pages
            </Typography>
          </div>
          <div className={classes.viewSupport}>
            <TextLink
              onClick={openLink(
                'https://leaseuphelp.zendesk.com/hc/en-us/sections/5763503462811-Building-Page'
              )}
            >
              View support pages
            </TextLink>
          </div>
          <CloseIcon onClick={onCloseBanner} className={classes.closeIcon} />
        </div>
      </Collapse>
    </div>
  )
})
