import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { padVector2, scaleToFit } from '~/libraries/math'
import { createElementSize } from '~/support/createElementSize'
import { useMessage } from '~/libraries/messaging.react'
import { useSend } from '~/support/useSend'
import { useZoom } from '~/support/useZoom'

const useStyles = makeStyles({
  container: {
    background: '#f9f9f9',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  viewport: {
    position: 'relative',
    height: (props) => `${props.size.y * props.scale + props.padding * 2}px`,
    minHeight: '100%',
    minWidth: '100%',
    width: (props) => `${props.size.x * props.scale + props.padding * 2}px`,
    transition: 'all .1s cubic-bezier(0.0, 0, 0.2, 1)',
  },
  artboard: {
    backgroundColor: '#ffffff',
    height: (props) => `${props.size.y}px`,
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: (props) => `translate(-50%, -50%) scale(${props.scale})`,
    width: (props) => `${props.size.x}px`,
    transition: 'all .1s cubic-bezier(0.0, 0, 0.2, 1)',
  },
})

export const Canvas = ({ className, children, zoom, size, padding }) => {
  const [container, setContainer] = useState()
  const classes = useStyles({
    padding,
    scale: useZoom(zoom),
    size,
  })

  const send = useSend()
 
  useMessage('ZoomToFit', () => {
    const outer = createElementSize(container)
    send('ZoomTo', scaleToFit(padVector2(outer, -padding), size))
  })

  useEffect(() => {
    if (container) send('ZoomToFit')
  }, [container, send])

  return (
    <div ref={setContainer} className={clsx(classes.container, className)}>
      <div className={classes.viewport}>
        <Box className={classes.artboard} boxShadow={3}>
          {children}
        </Box>
      </div>
    </div>
  )
}
