import React, { useState } from 'react';
import Api from 'rest-fetcher-redux';
import { toast } from '~/legacy/utils/notifications';
import { Modal, MODALS } from '~/legacy/components';
import { DeleteSurveyMenuItem } from './DeleteSurveyMenuItem';

const SDPDeleteSurveyMenuItem = React.forwardRef(
  (
    {
      surveyId,
      handleMenuClose = () => {},
      onSurveyDelete = () => {},
      ...props
    },
    ref
  ) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    return (
      <>
        <DeleteSurveyMenuItem
          ref={ref}
          onClick={() => {
            setShowConfirmModal(true);
          }}
          {...props}
        />
        <Modal
          content={MODALS.CONFIRM_DELETE_SURVEY}
          onClose={(confirmed) => {
            if (confirmed === true) {
              Api.trashProject({
                id: surveyId,
              }).then(() => {
                setShowConfirmModal(false);
                handleMenuClose();
                onSurveyDelete();
                toast('Survey deleted!', { appearance: 'success' });
              });
            } else {
              setShowConfirmModal(false);
              handleMenuClose();
            }
          }}
          open={showConfirmModal}
        />
      </>
    );
  }
);

export default SDPDeleteSurveyMenuItem;
