export const Survey = {
  value: 'survey',
  label: 'Survey',
  name: 'Market Survey',
}

export const Tour = {
  value: 'tour',
  label: 'Tour',
  name: 'Market Tour',
}
