import React, { useEffect, useState } from 'react';
import Api from 'rest-fetcher-redux';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSegment } from '~/legacy/utils/hooks';
import { Loading, FileUploadWrapper, File } from '~/legacy/components';

const useStyles = makeStyles({
  messageInputContainer: {
    paddingTop: '5px',
  },
  activityMessageInput: {
    paddingRight: '5px',
  },
  adornment: {
    marginRight: '-9px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  image: {
    width: '100px',
    height: '100px',
    padding: '5px',
  },
  filesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
    paddingTop: '5px',
  },
});

function ListingActivityCommentInput({
  listingId,
  surveyId,
  setNewActivityMessage,
}) {
  const classes = useStyles();

  // New message content for the activity feed, allowing a user to create a new message
  const [newMessage, setNewMessage] = useState('');
  const NEW_MESSAGE_FILES_EMPTY_STATE = {};
  const [newMessageAttachments, setNewMessageAttachments] = useState(
    NEW_MESSAGE_FILES_EMPTY_STATE
  );
  const [newMessagePhotos, setNewMessagePhotos] = useState(
    NEW_MESSAGE_FILES_EMPTY_STATE
  );
  // Whether or not we are currently creating a new message
  const [creatingNewMessage, setCreatingNewMessage] = useState(false);
  // Whether or not there's missing required info for the new message input
  const [newMessageInputError, setNewMessageInputError] = useState(false);

  // Whether or not the user has added enough info for the a new message
  const requiredInputForNewMessage = !!(
    newMessage ||
    Object.keys(newMessageAttachments).length > 0 ||
    Object.keys(newMessagePhotos).length > 0
  );
  const segment = useSegment();

  const theme = useTheme();
  const createNewMessage = () => {
    // Create a new message by sending it through to the backend
    if (requiredInputForNewMessage) {
      setCreatingNewMessage(true);
      Api.createSurveyListingComment({
        body: {
          text: newMessage,
          survey_id: surveyId,
          listing_id: listingId,
          images: newMessagePhotos ? Object.values(newMessagePhotos) : null,
          attachments: newMessageAttachments
            ? Object.values(newMessageAttachments)
            : null,
          colors: theme.palette.primary,
        },
      })
        .then((results) => {
          if (results) {
            // Clear the message fields
            setNewMessage('');
            setNewMessageAttachments(NEW_MESSAGE_FILES_EMPTY_STATE);
            setNewMessagePhotos(NEW_MESSAGE_FILES_EMPTY_STATE);
            setNewActivityMessage(results);
            segment.trackCreateSurveyListingComment({
              surveyId,
              listingId,
              hasText: !!newMessage,
              numImages: newMessagePhotos
                ? Object.keys(newMessagePhotos).length
                : 0,
              numAttachments: newMessageAttachments
                ? Object.keys(newMessageAttachments).length
                : 0,
            });
          }
        })
        .finally(() => {
          setCreatingNewMessage(false);
        });
    } else {
      setNewMessageInputError(true);
    }
  };

  useEffect(() => {
    // Whenever the user enters text / photo / attachment, lets clear the error text
    if (requiredInputForNewMessage) {
      setNewMessageInputError(false);
    }
  }, [requiredInputForNewMessage]);

  const removeKeyFromDict = (dict, removeKey) => {
    // Filter out the file to delete
    const newAttachments = {};
    Object.entries(dict).forEach(([key, entry]) => {
      if (key !== removeKey) {
        newAttachments[key] = entry;
      }
    });
    return newAttachments;
  };

  const handleDeleteAttachment = (fileKey) =>
    setNewMessageAttachments((currentFiles) =>
      removeKeyFromDict(currentFiles, fileKey)
    );
  const handleDeletePhoto = (fileKey) =>
    setNewMessagePhotos((currentFiles) =>
      removeKeyFromDict(currentFiles, fileKey)
    );

  return (
    <div className={classes.messageInputContainer}>
      <FormControl variant="filled" fullWidth>
        <InputLabel htmlFor="send-message" variant="outlined">
          Add a comment
        </InputLabel>
        <OutlinedInput
          id="send-message"
          label="Add a comment"
          placeholder="Enter a comment..."
          type="text"
          multiline
          className={classes.activityMessageInput}
          value={newMessage}
          onChange={(event) => setNewMessage(event.target.value)}
          disabled={creatingNewMessage}
          error={newMessageInputError}
          endAdornment={
            <InputAdornment position="end">
              <FileUploadWrapper
                className={classes.adornment}
                edge="end"
                disabled={creatingNewMessage}
                setFiles={(newFiles) => {
                  const newAttachments = {};
                  const newPhotos = {};
                  Object.entries(newFiles).forEach(([uuid, file]) => {
                    if (['image/jpeg', 'image/png'].includes(file.type)) {
                      newPhotos[uuid] = file;
                    } else {
                      newAttachments[uuid] = file;
                    }
                  });
                  setNewMessageAttachments((p) => ({
                    ...p,
                    ...newAttachments,
                  }));
                  setNewMessagePhotos((p) => ({ ...p, ...newPhotos }));
                }}
              >
                <AttachFileOutlinedIcon />
              </FileUploadWrapper>
              <Button
                color="primary"
                variant="text"
                disabled={creatingNewMessage}
                onClick={createNewMessage}
              >
                Send
              </Button>
              <Loading
                size={24}
                isLoading={creatingNewMessage}
                className={classes.buttonProgress}
              />
            </InputAdornment>
          }
        />
        <div className={classes.filesContainer}>
          {newMessageInputError && (
            <FormHelperText
              error
              id="send-message-error"
              key="send-message-error"
            >
              You must include a message, attachment, or photo
            </FormHelperText>
          )}
          {Object.entries(newMessageAttachments).map(([key, file]) => {
            return (
              <div className={classes.image} key={`uploaded-${key}`}>
                <File
                  endpoint="survey_listing_comment_attachments"
                  file={file}
                  updateFile={(newFile) =>
                    setNewMessageAttachments((p) => ({
                      ...p,
                      [key]: { ...newFile },
                    }))
                  }
                  deleteFile={() => handleDeleteAttachment(key)}
                  showAsThumbnail
                  isAttachment
                />
              </div>
            );
          })}
          {Object.entries(newMessagePhotos).map(([key, file]) => {
            return (
              <div className={classes.image} key={`uploaded-${key}`}>
                <File
                  endpoint="survey_listing_comment_photos"
                  file={file}
                  updateFile={(newFile) =>
                    setNewMessagePhotos((p) => ({
                      ...p,
                      [key]: { ...newFile },
                    }))
                  }
                  showAsThumbnail
                  deleteFile={() => handleDeletePhoto(key)}
                />
              </div>
            );
          })}
        </div>
      </FormControl>
    </div>
  );
}

export default ListingActivityCommentInput;
