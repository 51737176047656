import React from 'react'
import BrokerSurvey from './BrokerSurvey'
import BrokerSurveyNew from './BrokerSurveyNew'
import { useSurveySelector } from '~/legacy/utils'

export default function BrokerSurveyWrapper({ match, history }) {
  const { survey } = useSurveySelector(match.params.id, 'bdp', false)
  const optedInSurvey = survey?.opted_in_new_custom_fields
  console.log('survey opted_in_new_custom_fields:', optedInSurvey)

  return optedInSurvey ? (
    <BrokerSurveyNew match={match} history={history} />
  ) : (
    <BrokerSurvey match={match} history={history} />
  )
}
