import React from 'react'
import { FormControl, TextField, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { ClearTextInputSvg } from '~/legacy/components'

const useStyles = makeStyles({
  container: {
    marginTop: '10px',
  },
  close: {
    cursor: 'pointer',
    color: '#e0e0e0',
    height: '14px',
    width: '14px',
  },
})

export const Text = ({
  className,
  nullable,
  value,
  onChange,
  onReset,
  ...attrs
}) => {
  const classes = useStyles()
  return (
    <FormControl className={clsx(classes.container, className)} fullWidth>
      <TextField
        value={value}
        onChange={onChange}
        InputProps={{
          endAdornment: nullable && value && (
            <ClearTextInputSvg
              className={classes.close}
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                onReset()
              }}
            />
          ),
        }}
        {...attrs}
      />
    </FormControl>
  )
}
