import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import Api from 'rest-fetcher-redux';

import {
  errorMiddleware,
  projectMiddleware,
  subscriptionMiddleware,
} from './middleware';

import misc from './misc';
import data from './data';
import user from './user';
import pages from './pages';
import storeImage from './storeImage';
import map from './map';

const rootReducer = combineReducers({
  misc,
  data,
  user,
  pages,
  storeImage,
  map,
  api: Api.getReducer(),
});
const middleware = [errorMiddleware, projectMiddleware, subscriptionMiddleware];
const devStoreEnhancer = [];

if (process.env.DEV) {
  // If the user has the "Redux DevTools" browser extension installed, use that.
  // Otherwise, hook up the in-page DevTools UI component.
  const debugEnhancer =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f;
  devStoreEnhancer.push(debugEnhancer);
}

const storeEnhancers = [applyMiddleware(...middleware), ...devStoreEnhancer];

let globalStore = null;

export default (initialState = {}) => {
  if (globalStore) {
    return globalStore;
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(...storeEnhancers)
  );

  if (module.hot && process.env.DEV) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept(rootReducer, () => {
      try {
        const nextReducer = rootReducer;
        store.replaceReducer(nextReducer);
      } catch (error) {
        console.error(`==> Reducer hot reloading error ${error}`);
      }
    });
  }

  globalStore = store;
  return store;
};
