export function match(input, cases) {
  if (!cases) {
    return (value) => match(value, input)
  }

  if (typeof input === 'object') {
    const { type } = input
    const value = input[type]
    if (type in cases) return cases[type](value)
    if ('*' in cases) return cases['*'](value, type)
  }

  return undefined
}
