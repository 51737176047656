const BASE_ICON_STYLES = () => {
  return {
    menuItemRoot: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingRight: '24px',
      paddingLeft: '12px',
      '&:hover': {
        backgroundColor: '#F3F3F3',
      },
    },
    text: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginRight: 'auto',
      paddingLeft: '8px',
      // TODO: better home for these
      fontFamily: ['Inter-SemiBold'],
      letterSpacing: '0.1px',
    },
    icon: {
      width: '24px',
      height: '24px',
      fill: '#111 !important',
      color: '#111 !important',
    },
  };
};

export { BASE_ICON_STYLES };
