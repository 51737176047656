import React, { memo } from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Logo } from '~/legacy/components'
import { useIsLeaseupLogo } from '~/legacy/utils'

const useStyles = makeStyles({
  fakeTopNav: {
    width: '100%',
    background: '#ffffff',
    borderBottom: '1px solid #e8e8e8',
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    paddingLeft: '32px',
  },
  fakeTopNavLogoContainer: {
    display: 'flex',
    height: '100%',
    width: (props) => (props.isLeaseUpLogo ? '120px' : '158px'),
    overflow: 'hidden',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

function PublicHeader({ contentComponent }) {
  const isLeaseUpLogo = useIsLeaseupLogo()
  const classes = useStyles({ isLeaseUpLogo })

  return (
    <div className={classes.root}>
      <Paper className={classes.fakeTopNav} elevation={0}>
        <div className={classes.fakeTopNavLogoContainer}>
          <Logo />
        </div>
      </Paper>
      {contentComponent}
    </div>
  )
}

export default memo(PublicHeader)
