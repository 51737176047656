import { toast } from '~/legacy/utils/notifications';
import { mapActions } from '~/legacy/store';

export default {
  getSavedSearches: {
    url: '/savedsearches/',
    options: {
      method: 'GET',
    },
  },
  getSavedSearch: {
    url: '/savedsearches/:id/',
    options: {
      method: 'GET',
    },
    transformer: (_data) => {
      const data = { ..._data };
      if (data.search) {
        if (data.search.geo_poly) delete data.search.geo_poly;
        if (data.search.circle_area) delete data.search.circle_area;
        if (data.search.row_data) {
          data.search._raw_data = data.search.row_data;
          delete data.search.row_data;
        }
      }
      return data;
    },
    postfetch: [
      (obj) => {
        const {
          dispatch,
          data: {
            // eslint-disable-next-line
            search: { _raw_data: row_data },
          },
        } = obj;
        // eslint-disable-next-line
        if (row_data && row_data.rawPoligons) {
          dispatch(mapActions.reviveFromRawData(row_data.rawPoligons));
        }
      },
    ],
    changeOnAction: {
      clean_saved_search_row_data: (state) => {
        const data = { ...state.data };
        data.row_data = [];
        return { ...state, data };
      },
    },
  },
  createSearch: {
    url: '/savedsearches/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () =>
        toast('Search has been successfuly created', { appearance: 'success' }),
    ],
  },
  updateSearch: {
    url: '/savedsearches/:id/',
    options: {
      method: 'PUT',
    },
    onSuccess: [
      () =>
        toast('Search has been updated successfully', {
          appearance: 'success',
        }),
    ],
  },
  patchSearch: {
    url: '/savedsearches/:id/',
    options: {
      method: 'PATCH',
    },
    onSuccess: [
      () =>
        toast('Search has been saved successfully', { appearance: 'success' }),
    ],
  },
  deleteSearch: {
    url: '/savedsearches/:id/',
    options: {
      method: 'DELETE',
    },
    onSuccess: [() => toast('Search Deleted', { appearance: 'success' })],
  },
};
