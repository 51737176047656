import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import { LEASE_ZENDESK } from '~/legacy/consts'
import { actionLocationChanged } from '~/legacy/store'
import { useTrackPageView } from '~/legacy/utils/hooks'

function PublicRouteWithTracking({
  location,
  path,
  trackingPageViewName,
  computedMatch,
  ...props
}) {
  // Wrap the public route with tracking so we ensure we only submit this event when the user isn't logged in
  //   and would already hit PrivateRoute tracking
  useTrackPageView({
    eventName: trackingPageViewName,
    url: location.pathname,
    path,
    computedMatch,
  })

  return (
    <Route
      path={path}
      computedMatch={computedMatch}
      trackingPageViewName={trackingPageViewName}
      {...props}
    />
  )
}

function PublicRoute({
  allowMobile,
  location,
  skipLoginRedirect,
  path,
  computedMatch,
  trackingPageViewName,
  ...props
}) {
  const loggedIn = useSelector((s) => s.user.loggedIn)
  const lastLocation = useSelector((store) => store.misc.location)
  const dispatch = useDispatch()

  if (!lastLocation || lastLocation.pathname !== location.pathname) {
    dispatch(actionLocationChanged(location))
  }

  if (loggedIn && !skipLoginRedirect) {
    const url = new URL(window.location.href)

    if (url.searchParams.has('redirect')) {
      const uri = url.searchParams.get('redirect')
      const redirect = decodeURIComponent(uri)
      window.location = redirect.replace(/^\/next\//, '/')
      return null
    }

    if (url.searchParams.has('zendesk_redirect')) {
      const uri = url.searchParams.get('zendesk_redirect')
      const redirect = decodeURIComponent(uri)
      window.location = `${LEASE_ZENDESK}?return_to=${redirect}`
      return null
    }

    window.location = '/home'
    return null
  }

  const metaViewport = document.getElementsByName('viewport')[0]
  if (allowMobile) {
    metaViewport.content =
      'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no'
  } else {
    metaViewport.content = 'width=device-width, initial-scale=0'
  }
  return (
    <PublicRouteWithTracking
      location={location}
      path={path}
      computedMatch={computedMatch}
      trackingPageViewName={trackingPageViewName}
      {...props}
    />
  )
}

export default memo(PublicRoute)
