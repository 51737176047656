import React, { useContext } from 'react';

import Favicon from 'react-favicon';
import { SkinContext } from '~/legacy/utils';

export const SkinnedFavicon = () => {
  const { skin } = useContext(SkinContext);
  if (skin && skin.favicon && skin.favicon.raw_image_url) {
    return <Favicon url={skin.favicon.raw_image_url} />;
  }
  return '';
};
