import React from 'react'
import { Menu } from '@material-ui/core'
import { SDPDeleteSurveyMenuItem, LinkIconSvg } from '~/legacy/components'
import { QuickMenuItem } from '../buttons/QuickMenuItem'
import { copyToClipboard } from '~/support/copyToClipboard'
import { createSurveyUrl } from '~/support/createSurveyUrl'

export default function OldSurveyMenu({
  surveyId,
  handleMenuClose = () => {},
  onSurveyDelete = () => {},
  ...menuProps
}) {
  return (
    <Menu {...menuProps} onClick={(e) => e.stopPropagation()}>
      <QuickMenuItem
        icon={LinkIconSvg}
        label="Copy Link"
        onClick={async () => {
          await copyToClipboard(createSurveyUrl(surveyId))
          handleMenuClose()
        }}
      />
      <SDPDeleteSurveyMenuItem
        surveyId={surveyId}
        handleMenuClose={handleMenuClose}
        onSurveyDelete={onSurveyDelete}
      />
    </Menu>
  )
}
