import Api from 'rest-fetcher-redux'
import lodash from 'lodash'
import { Analytics } from '~/legacy/utils/hooks'

import { ApiError, LoggableError } from '~/legacy/utils'

export const GET_DEFAULT_AUTH = () => Api.baseOptions.headers.Authorization

// Import / init ordering makes us have to access API after it's been initialized, so
// using a function here to ensure that
const GET_DEFAULT_HEADERS = (skipAuth) => {
  const headers = {
    'Content-Type': 'application/json',
  }
  if (!skipAuth) {
    headers.Authorization = GET_DEFAULT_AUTH()
  }

  return { method: 'GET', headers }
}

export const fetchApi = async ({
  endpoint,
  fetchArgs = {},
  jsonResponse = true,
  logError = true,
  skipAuth = false,
  trackingArgs,
}) => {
  const fetchArgsMerged = lodash.merge(
    {},
    GET_DEFAULT_HEADERS(skipAuth),
    fetchArgs
  )
  const fetchPromise = fetch(
    `${process.env.APP_API_URL}/${endpoint}`,
    fetchArgsMerged
  )

  if (trackingArgs) {
    Analytics.track(trackingArgs.name, trackingArgs.metadata)
  }

  // Default handle of json response
  if (jsonResponse) {
    return fetchPromise
      .then((response) =>
        Promise.all([
          Promise.resolve(response),
          response.ok ? response.json() : response.text(),
        ])
      )
      .catch((e) => {
        // Adding this to figure out if we need to update how we handle errors sent to sentry
        console.log('fetchApi', {
          endpoint,
          fetchArgs,
          jsonResponse,
          logError,
          skipAuth,
          trackingArgs,
        })
        const error = new LoggableError(e)
        if (logError) {
          error.logToSentry()
        }
        throw error
      })
      .then(([response, repsonseBody]) => {
        // If there's an error, log to sentry and throw an ApiError. Fetch does not create an error
        //   by default for non-200 level so we make our own
        if (!response || !response.ok) {
          console.log('fetchApi response', response)
          const error = new ApiError(response)
          if (logError) {
            error.logToSentry({ fetchArgs: fetchArgsMerged })
          }
          throw error
        } else {
          return [response, repsonseBody]
        }
      })
  }

  return fetchPromise
}
