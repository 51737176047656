import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useImage } from 'react-image';

// Final fallback image to show if we cannot find any images
function ImageErrorFallback({ ImgProps = {} }) {
  return (
    <img src="/static/media/brokenImage.png" alt="missing" {...ImgProps} />
  );
}

// Fallback if we cannot find any images at the new URL scheme. We fall back to the legacy bucket.
// TODO: Remove after successful roleout
function LegacyImageFallback({ imageFile, ImgProps = {} }) {
  const sourceList = [imageFile ? imageFile.url : ''];
  return (
    <SuspendedBoundariedMissingImage imageFile={imageFile} ImgProps={ImgProps}>
      <ImageComponent
        imageFile={imageFile}
        sourceListStart={sourceList}
        ImgProps={ImgProps}
      />
    </SuspendedBoundariedMissingImage>
  );
}

// Display an image with fallbacks to legacy/missing image asset as well as loading state
// TODO: remove after successful roleout
export function SuspendedBoundariedImage({
  children,
  imageFile,
  ImgProps = {},
}) {
  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <LegacyImageFallback
          error={error}
          imageFile={imageFile}
          ImgProps={ImgProps}
        />
      )}
      onError={(error) => {
        // TODO: remove after confident in rollout to new s3 buckets
        Sentry.captureMessage('Image not found, falling back to Legacy URL', {
          tags: {
            error: error.message,
            imageUuid: imageFile ? imageFile.uuid : '',
          },
        });
      }}
    >
      <Suspense fallback={<div />}>{children}</Suspense>
    </ErrorBoundary>
  );
}

// Display an image with fallback to missing image asset as well as loading state
export function SuspendedBoundariedMissingImage({
  children,
  imageFile,
  ImgProps = {},
}) {
  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <ImageErrorFallback error={error} ImgProps={ImgProps} />
      )}
      onError={(error) => {
        Sentry.captureMessage(
          'Image not found, falling back to missing image asset',
          {
            tags: {
              error: error.message,
              imageUuid: imageFile ? imageFile.uuid : '',
            },
          }
        );
      }}
    >
      <Suspense fallback={<div />}>{children}</Suspense>
    </ErrorBoundary>
  );
}

export const ImageComponent = withStyles({
  root: {},
})(({ classes, imageFile, sourceListStart = [], ImgProps = {} }) => {
  const sourceList = [
    ...sourceListStart,
    imageFile.raw_image_url, // The raw image
  ];
  const { src } = useImage({ srcList: sourceList });
  return <img className={classes.root} src={src} alt="" {...ImgProps} />;
});

export function ThumbnailImage({ imageFile, ImgProps = {} }) {
  const sourceList = [imageFile.thumbnail_image_url];
  return (
    <SuspendedBoundariedImage imageFile={imageFile} ImgProps={ImgProps}>
      <ImageComponent
        imageFile={imageFile}
        sourceListStart={sourceList}
        ImgProps={ImgProps}
        classes={ImgProps.classes || {}}
      />
    </SuspendedBoundariedImage>
  );
}

export function SourceImage({ imageFile, ImgProps = {} }) {
  const sourceList = [imageFile.source_image_url];
  return (
    <SuspendedBoundariedImage imageFile={imageFile} ImgProps={ImgProps}>
      <ImageComponent
        imageFile={imageFile}
        sourceListStart={sourceList}
        ImgProps={ImgProps}
      />
    </SuspendedBoundariedImage>
  );
}

export function CompressedSourceImage({ imageFile, ImgProps = {} }) {
  const sourceList = [imageFile.compressed_source_image_url];
  return (
    <SuspendedBoundariedImage imageFile={imageFile} ImgProps={ImgProps}>
      <ImageComponent
        imageFile={imageFile}
        sourceListStart={sourceList}
        ImgProps={ImgProps}
      />
    </SuspendedBoundariedImage>
  );
}

export function CardImage({ imageFile, ImgProps = {} }) {
  const sourceList = [imageFile.card_image_url];
  return (
    <SuspendedBoundariedImage imageFile={imageFile} ImgProps={ImgProps}>
      <ImageComponent
        imageFile={imageFile}
        sourceListStart={sourceList}
        ImgProps={ImgProps}
      />
    </SuspendedBoundariedImage>
  );
}

// for use in places where we cant use Suspense
export function SimpleThumbnailImage({ imageFile, ImgProps = {} }) {
  return (
    <img
      alt=""
      src={imageFile.thumbnail_image_url}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = imageFile.raw_image_url;
      }}
      {...ImgProps}
    />
  );
}

export function SimpleCardImage({ imageFile, ImgProps = {} }) {
  return (
    <img
      alt=""
      src={imageFile.card_image_url}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = imageFile.raw_image_url;
      }}
      {...ImgProps}
    />
  );
}

export function SimpleCompressedSourceImage({ imageFile, ImgProps = {} }) {
  return (
    <img
      alt=""
      src={imageFile.compressed_source_image_url}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = imageFile.raw_image_url;
      }}
      {...ImgProps}
    />
  );
}

export function RawImage({ imageFile, ImgProps = {} }) {
  return (
    <SuspendedBoundariedImage imageFile={imageFile} ImgProps={ImgProps}>
      <ImageComponent
        imageFile={imageFile}
        ImgProps={ImgProps}
        classes={ImgProps.classes || {}}
      />
    </SuspendedBoundariedImage>
  );
}
