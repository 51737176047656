import React from 'react';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  DeselectedCheckboxIcon,
  IndeterminateCheckboxIcon,
  SelectedCheckboxIcon,
} from '~/legacy/components';

const useStyles = makeStyles({
  // TODO make common
  buttonRoot: {
    transition: '0.3s',
    '&:hover': {
      backgroundColor: (props) =>
        props.showHover ? '#E0E0E0 !important' : 'unset !important',
      transition: '200ms',
    },
  },
  checkbox: {
    borderRadius: '4px',
    // childSize = 24
    // 24 + (2 * 2) = 28, otherwise not centered
    padding: '2px',
    width: '28px',
    height: '28px',
  },
});

export default function SquareCheckbox({
  className,
  selectedCheckboxIconClasses = '',
  deSelectedCheckboxIconClasses = '',
  indeterminateCheckboxIconClasses = '',
  showHover = true,
  ...checkboxProps
}) {
  const classes = useStyles({ showHover });

  return (
    <Checkbox
      color="default"
      edge="start"
      tabIndex={-1}
      disableRipple
      className={clsx(classes.checkbox, className)}
      checkedIcon={
        <SelectedCheckboxIcon className={selectedCheckboxIconClasses} />
      }
      icon={
        <DeselectedCheckboxIcon className={deSelectedCheckboxIconClasses} />
      }
      indeterminateIcon={
        <IndeterminateCheckboxIcon
          className={indeterminateCheckboxIconClasses}
        />
      }
      classes={{ root: classes.buttonRoot }}
      {...checkboxProps}
    />
  );
}
