import { useTheme, useMediaQuery } from '@material-ui/core';


export const useBuildingDetailPageLayout = () => {
  const theme = useTheme();

  const stage1 = useMediaQuery(theme.breakpoints.up(1820));
  const stage2 = useMediaQuery(theme.breakpoints.up(1440));
  const stage3 = useMediaQuery(theme.breakpoints.up(1332));
  const stage4 = useMediaQuery(theme.breakpoints.up(1192));
  const stage5 = useMediaQuery(theme.breakpoints.up(1101));
  const stage6 = useMediaQuery(theme.breakpoints.up(1004));
  const stage7 = useMediaQuery(theme.breakpoints.up(770));

  // There can only be one
  const largeScreenLayout = !!stage5;
  const mediumScreenLayout = !!(!largeScreenLayout && (stage6 || stage7));
  const smallScreenLayout = !!(!largeScreenLayout && !mediumScreenLayout);

  return {
    stage1,
    stage2,
    stage3,
    stage4,
    stage5,
    stage6,
    stage7,
    largeScreenLayout,
    mediumScreenLayout,
    smallScreenLayout,
  }
};

export const useBuildingDetailPagePhotoModalLayout = () => {
  const theme = useTheme();

  const stage1 = useMediaQuery(theme.breakpoints.up(990));

  return {
    stage1,
  }
};
