import React from 'react';
import { bindMenu } from 'material-ui-popup-state/hooks';
import { Menu, useRemoveBuildingFromSurveyMenuItem } from '~/legacy/components';

export const BdpBuildingMenu = ({
  menuState,
  RemoveBuildingFromSurveyMenuItemComponent,
}) => {
  return (
    <Menu {...bindMenu(menuState)} getContentAnchorEl={null}>
      {RemoveBuildingFromSurveyMenuItemComponent}
    </Menu>
  );
};

export const useBdpBuildingMenu = ({
  menuState,
  surveyId,
  surveyName,
  buildingId,
  redirect,
}) => {
  const {
    RemoveBuildingFromSurveyMenuItemComponent,
    ConfirmRemoveBuildingFromSurveyModalComponent,
  } = useRemoveBuildingFromSurveyMenuItem({
    surveyId,
    surveyName,
    buildingId,
    handleMenuClose: () => menuState.close(),
    redirect,
    label: 'Remove From Survey',
  });

  const BdpBuildingMenuComponent = (
    <BdpBuildingMenu
      menuState={menuState}
      RemoveBuildingFromSurveyMenuItemComponent={
        RemoveBuildingFromSurveyMenuItemComponent
      }
    />
  );

  return {
    BdpBuildingMenuComponent,
    ConfirmRemoveBuildingFromSurveyModalComponent,
  };
};
