export const createDispatcher = () => {
  const subscribers = new Set()

  const dispatcher = {
    send: (data) => {
      subscribers.forEach((callback) => callback(data))
    },

    subscribe: (callback) => {
      subscribers.add(callback)
      return () => subscribers.delete(callback)
    },
  }

  return dispatcher
}
