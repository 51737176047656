import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  SquareIconButton,
  FavoriteIconFilled,
  FavoriteIconOutlined,
} from '~/legacy/components';

export const FavoriteBuildingButton = withStyles({
  iconButton: {
    '&:hover': {
      backgroundColor: '#f3f3f3',
    },
  },
})(
  ({
    classes,
    isFavorited = false,
    isNotInterested = false,
    onClick,
    ...props
  }) => {
    return (
      <SquareIconButton
        className={classes.iconButton}
        showAsDisabled={isNotInterested}
        key="favorite-icon"
        tooltipText={isFavorited ? 'Remove from Favorites' : 'Add to Favorites'}
        onClick={onClick}
        {...props}
      >
        {isFavorited ? <FavoriteIconFilled /> : <FavoriteIconOutlined />}
      </SquareIconButton>
    );
  }
);
