import { Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { InfoBox } from '@react-google-maps/api'
import clsx from 'clsx'
import React, { useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import {
  MapMarker,
  MapMarkerIcon,
  SpaceIcon,
  ThumbnailImage,
} from '~/legacy/components'
import {
  getBuildingTertiaryName,
  getListingCardNameByParam,
  getPlural,
  getStaticImage,
  getViewBuildingRoute,
  getViewListingRoute,
} from '~/legacy/utils'
import { openLink } from '../utils/openLink'

const useStyles = makeStyles({
  image: {
    width: '120px',
    objectFit: 'cover',
  },
  text: {
    lineHeight: '21px',
  },
  numSpacesText: {
    paddingLeft: '4px',
  },
  spaceIcon: {
    marginLeft: '-4px',
  },
  markerInfoContainer: {
    borderRadius: '4px',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    width: '120px',
  },
  infoWindowDetails: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    padding: '12px 16px 16px 16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  spacesAvailable: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '8px',
  },
})

const MAP_MARKER_WIDTH_LARGE_PX = 350
const MAP_MARKER_WIDTH_SMALL_PX = 232
const MAP_MARKER_HEIGHT_PX = 120
const BuildingMapMarker = ({
  map,
  listing,
  building,
  spacesAvailable,
  isHovered,
  isActive,
  zIndexes,
  setZIndexes,
  surveyId,
  setActiveMapMarker,
  isPrimarySurveyBuilding,
  isHighlightedOverride,
  windowAboveMarker = false,
  isUserInBuildout = false,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [isMarkerHovered, setIsMarkerHovered] = useState(false)
  const isHoveredOrActive = !!(isMarkerHovered || isHovered || isActive)

  const handleClick = openLink(
    listing
      ? getViewListingRoute(listing.id, surveyId)
      : getViewBuildingRoute(surveyId, building.id)
  )

  const pixelOffset = windowAboveMarker ? -50 : 115

  const largeCard = useMediaQuery(theme.breakpoints.up('lg'))
  const mapMarkerWidth = largeCard
    ? MAP_MARKER_WIDTH_LARGE_PX
    : MAP_MARKER_WIDTH_SMALL_PX

  let zIndex = isHoveredOrActive ? 9999 : zIndexes[building.id]
  if (isPrimarySurveyBuilding !== null && isPrimarySurveyBuilding) {
    // make sure the primary survey building is on top, but not
    // higher than the hovered zIndex
    zIndex = 999
  }
  const isHighlighted =
    isHighlightedOverride !== null ? isHighlightedOverride : isHoveredOrActive

  return (
    <MapMarker
      onBlur={() => setIsMarkerHovered(false)}
      onFocus={() => setIsMarkerHovered(true)}
      onMouseOut={() => setIsMarkerHovered(false)}
      onMouseOver={() => setIsMarkerHovered(true)}
      isHighlighted={isHighlighted}
      icon={`data:image/svg+xml,${escape(
        ReactDOMServer.renderToStaticMarkup(
          <MapMarkerIcon isActive={isHighlighted} theme={theme} />
        )
      )}`}
      onLoad={(marker) =>
        setZIndexes({ ...zIndexes, [building.id]: marker.zIndex })
      }
      position={{
        lat: building.geo.latitude,
        lng: building.geo.longitude,
      }}
      zIndex={zIndex}
      onClick={() => {
        // Set this marker as active, and check whether or not the info window should be rendered above or below the marker
        // Get the south west, extract the lat, subtract the lat x from the marker point x, and divide by the pixelsize, see if less than threshold
        const mapBounds = map.getBounds()
        const pixelSize = 2 ** -map.getZoom()
        const mapBoundsBottomLat = mapBounds.getSouthWest().lat()
        const mapMarkerBottomLat = building.geo.latitude
        const latDistanceBetween = mapMarkerBottomLat - mapBoundsBottomLat
        const pixelDistanceBetween = latDistanceBetween / pixelSize
        setActiveMapMarker({
          buildingId: building.id,
          showWindowAboveMarker:
            pixelDistanceBetween < MAP_MARKER_HEIGHT_PX + 32,
        })
      }}
    >
      {isActive && (
        <InfoBox
          onCloseClick={() => setActiveMapMarker(null)}
          position={{
            lat: building.geo.latitude,
            lng: building.geo.longitude,
          }}
          options={{
            alignBottom: true,
            pixelOffset: new google.maps.Size(
              -(mapMarkerWidth / 2),
              pixelOffset
            ),
            infoBoxClearance: new google.maps.Size(32, 32 + 55),
            boxStyle: {
              width: `${mapMarkerWidth}px`,
              height: `${MAP_MARKER_HEIGHT_PX}px`,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
              borderRadius: '4px',
            },
            closeBoxURL: ``,
            enableEventPropagation: false,
          }}
          onDomReady={() => {
            // Annoyingly we have to add click handler after the marker renders
            const counterButton = document.getElementById(
              `map-marker-info-container-${building.id}`
            )
            counterButton.addEventListener('click', handleClick)
          }}
          onClick={handleClick}
        >
          <div
            id={`map-marker-info-container-${building.id}`}
            className={classes.markerInfoContainer}
            style={{
              width: `${mapMarkerWidth}px`,
              height: `${MAP_MARKER_HEIGHT_PX}px`,
            }}
          >
            {largeCard && (
              <div className={classes.imageContainer}>
                <ThumbnailImage
                  imageFile={
                    building.hero_photo ||
                    getStaticImage('PlaceholderBuildingPhotoCardUI.png')
                  }
                  ImgProps={{
                    className: classes.image,
                  }}
                />
              </div>
            )}
            <div className={classes.infoWindowDetails}>
              <Typography variant="h3" noWrap className={classes.text}>
                {getListingCardNameByParam(
                  building.name,
                  null,
                  isUserInBuildout
                )}
              </Typography>
              <Typography variant="body1" noWrap className={classes.text}>
                {building.address}
              </Typography>
              <Typography variant="body1" noWrap className={classes.text}>
                {getBuildingTertiaryName(building)}
              </Typography>
              <div style={{ flex: 1 }} />
              <div className={classes.spacesAvailable}>
                <SpaceIcon className={classes.spaceIcon} />
                <Typography
                  variant="h3"
                  noWrap
                  className={clsx(classes.text, classes.numSpacesText)}
                >
                  {`${getPlural(spacesAvailable, 'Space')} Available`}
                </Typography>
              </div>
            </div>
          </div>
        </InfoBox>
      )}
    </MapMarker>
  )
}
export default React.memo(BuildingMapMarker)
