import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SquareIconButton } from '~/legacy/components';

const getColor = (theme, showPrimary) => {
  return showPrimary ? theme.palette.primary.main : '#666';
};
const getHoverColor = (theme, showPrimary) => {
  return showPrimary ? theme.palette.primary.dark : '#666';
};

const useStyles = makeStyles((theme) => ({
  properColor: {
    border: (props) => `1px solid ${getColor(theme, props.showPrimary)}`,
    '& svg': {
      color: (props) => getColor(theme, props.showPrimary),
      '&:hover': {
        color: (props) => getHoverColor(theme, props.showPrimary),
      },
    },
  },
}));

export default function SquareIconButtonPrimaryBorder({
  showPrimary = false,
  className = '',
  children,
  ...iconButtonProps
}) {
  const classes = useStyles({ showPrimary });

  return (
    <SquareIconButton
      className={clsx(classes.properColor, className)}
      {...iconButtonProps}
    >
      {children}
    </SquareIconButton>
  );
}
