import range from 'lodash/range'
import React from 'react'
import {
  Avatar,
  Skeleton,
  Spacer,
  TextChip,
  Typography,
} from '~/legacy/components'
import { styled } from '~/legacy/utils/styleHelpers'

export const Project = ({
  name,
  logo,
  company,
  status,
  steps,
  tags,
  onSelect,
  ...props
}) => {
  const handleSelect = () => {
    if (onSelect) onSelect()
  }

  return (
    <Container onClick={handleSelect} {...props}>
      <Context>
        <Name>{name}</Name>

        <Spacer />

        <Status>{status}</Status>

        <Steps>
          {range(steps[1]).map((step) => (
            <Step key={step} complete={step < steps[0]} />
          ))}
        </Steps>
      </Context>

      <Company>
        <Avatar image={logo} size="tiny" />
        {company}
      </Company>

      <Tags>
        {tags.map((tag) => (
          <Tag key={tag} label={tag} />
        ))}
      </Tags>
    </Container>
  )
}

export const ProjectSkeleton = ({ ...props }) => (
  <Container {...props}>
    <Context>
      <Skeleton height={22} width="40%" rounded={2} />
      <Spacer />
      <Skeleton height={12} width={120} rounded={2} />
    </Context>
    <Company>
      <Skeleton circle={24} />
      <Skeleton height={12} width="10%" rounded={2} />
    </Company>
    <Tags>
      <Skeleton height={32} width={80} rounded={16} />
      <Skeleton height={32} width={80} rounded={16} />
    </Tags>
  </Container>
)

const Container = styled('div', {
  borderRadius: 4,
  border: '1px solid #E0E0E0',
  height: 154,
  padding: 22,
  cursor: 'default',
})

const Context = styled('div', {
  display: 'flex',
  gap: '16px',
})

const Name = styled(Typography, {
  fontWeight: 600,
  color: '#111111',
})

Name.defaultProps = {
  variant: 'sectionTitle',
}

const Company = styled(Typography, {
  alignItems: 'center',
  marginTop: 8,
  display: 'flex',
  gap: '8px',
})

Company.defaultProps = {
  variant: 'subSectionTitle',
}

const Status = styled(Typography, {
  textTransform: 'uppercase',
})

Status.defaultProps = {
  variant: 'tableHeader',
}

const Steps = styled('div', {
  paddingRight: 2,
  paddingTop: 2,
  display: 'flex',
  gap: '4px',
})

const Step = styled('div', ['complete'], (props) => ({
  backgroundColor: props.when(
    'complete',
    props.get('theme.palette.primary.main'),
    '#E0E0E0'
  ),
  borderRadius: 8,
  height: 8,
  width: 8,
}))

const Tags = styled('div', {
  marginTop: 20,
  display: 'flex',
  gap: '8px',
})

const Tag = styled(TextChip, {
  backgroundColor: '#F3F3F3',
})
