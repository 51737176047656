import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { SimpleCompressedSourceImage } from '~/legacy/components'
import { useLogoUrl } from '~/legacy/utils'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  replacementImageContainer: {
    height: '100%',
    width: '100%',
  },
  replacementImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  coverImageContainer: {
    flex: 1,
    overflow: 'hidden',
    width: '100%',
  },
  coverImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 20px',
    borderBottom: `8px solid ${theme.palette.primary.main}`,
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  companyName: {
    margin: (props) =>
      props.brokers.length > 0 ? ' 0 0 24px 0' : '24px 0 0 0',
  },
  title: {
    fontSize: '28px',
  },
  subtitle: {
    fontSize: '18px',
    color: '#666666',
  },
  brokerCard: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    '&:not(first-child)': {
      marginTop: '14px',
    },
  },
  companyLogo: {
    maxWidth: '90px',
    maxHeight: '18px',
    objectFit: 'contain',
    alignSelf: 'flex-end',
    marginBottom: '-2px',
  },
}))

function CoverPageLayout1({ companyName, settings, surveyName }) {
  const { brokers, subtitle, replacementImage } = settings

  const classes = useStyles({ brokers })
  const logoUrl = useLogoUrl()

  return (
    <div className={classes.container}>
      {replacementImage.id && (
        <div className={classes.replacementImageContainer}>
          <SimpleCompressedSourceImage
            imageFile={replacementImage}
            ImgProps={{
              alt: 'cover',
              className: classes.replacementImage,
            }}
          />
        </div>
      )}
      {!replacementImage.id && (
        <>
          <div className={classes.coverImageContainer}>
            <img
              className={classes.coverImage}
              src="https://leaseup-production.s3.us-east-2.amazonaws.com/pdf_cover_small.jpg"
              alt="hero"
            />
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.textContent}>
              {brokers.length > 0 && (
                <Typography className={classes.companyName} variant="h3">
                  {companyName}
                </Typography>
              )}
              <Typography className={classes.title} variant="h2">
                {surveyName}
              </Typography>
              <Typography className={classes.subtitle} variant="h2">
                {subtitle}
              </Typography>
              {brokers.length === 0 && (
                <Typography className={classes.companyName} variant="h3">
                  {companyName}
                </Typography>
              )}
              <div>
                {brokers.map((broker) => (
                  <div key={broker.email} className={classes.brokerCard}>
                    <Typography variant="h3">{`${broker.first_name} ${broker.last_name}`}</Typography>
                    <Typography variant="body1">{broker.email}</Typography>
                  </div>
                ))}
              </div>
            </div>
            <img
              className={classes.companyLogo}
              src={logoUrl}
              alt="company logo"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default CoverPageLayout1
