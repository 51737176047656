import { Menu } from '@material-ui/core'
import React from 'react'
import {
  AddressIcon,
  AddToBuildingMenuItem,
  RemoveBuildingFromSurveyMenuItem,
} from '~/legacy/components'
import { useDispatcher } from '~/libraries/messaging.react'
import { QuickMenuItem } from '../buttons/QuickMenuItem'

export default function SurveyBuildingMenu({
  building,
  surveyId,
  handleMenuClose = () => {},
  ...menuProps
}) {
  const dispatcher = useDispatcher()

  return (
    <Menu {...menuProps}>
      <AddToBuildingMenuItem
        building={building}
        handleMenuClose={handleMenuClose}
      />
      <QuickMenuItem
        icon={AddressIcon}
        label="Edit Building Location"
        onClick={() => {
          handleMenuClose()
          dispatcher.send({
            type: 'EditBuildingLocation',
            data: { building },
          })
        }}
      />
      <RemoveBuildingFromSurveyMenuItem
        surveyId={surveyId}
        buildingId={building.id}
        handleMenuClose={handleMenuClose}
      />
    </Menu>
  )
}
