import React from 'react'
import { useSelector } from 'react-redux'

import { Drawer, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  ActivityProvider,
  AnonymousActivityCta,
  CloseIcon,
  SquareIconButton,
  SurveyActivityFeed,
} from '~/legacy/components'
import { useOverlay } from '~/support/useOverlay'

const drawerWidth = 'max(480px, calc(100vw - 1376px))'
const useStyles = makeStyles({
  drawer: {
    width: drawerWidth,
  },
  activityDrawerRoot: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #E0E0E0',
    padding: '15px 16px 16px 24px',
  },
  activityTitle: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: 18,
    lineHeight: '24px',
    display: 'flex',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  icon: {
    marginLeft: 'auto',
  },
  closeIcon: {
    color: '#111111',
  },
})

export default function ActivityDrawer({
  open,
  setOpen,
  surveyId,
  setAnonymousLoginModalOpen,
}) {
  const classes = useStyles()
  const user = useSelector((s) => s.user)

  useOverlay('activity-drawer', open)

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.drawer,
      }}
      open={open}
      variant="persistent"
    >
      <div className={classes.activityDrawerRoot}>
        <Typography variant="h2" className={classes.activityTitle}>
          Activity
        </Typography>
        <div className={classes.icon}>
          <SquareIconButton onClick={() => setOpen(false)}>
            <CloseIcon className={classes.closeIcon} />
          </SquareIconButton>
        </div>
      </div>
      {user.isAnonymous ? (
        <div onClick={() => setOpen(false)}>
          <AnonymousActivityCta
            setModalOpen={setAnonymousLoginModalOpen}
            withBackground={false}
          />
        </div>
      ) : (
        <ActivityProvider>
          <SurveyActivityFeed
            surveyId={surveyId}
            setHasMoreActivity={() => {}}
          />
        </ActivityProvider>
      )}
    </Drawer>
  )
}
