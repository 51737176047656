import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { LEASE_ROOT } from '~/legacy/consts'
import { useLogoUrl } from '~/legacy/utils'

const useStyles = makeStyles({
  imageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    padding: (props) => !props.forLogin && '6px 0',
  },
})

function Logo({ className, forLogin = false }) {
  const classes = useStyles({ forLogin })
  const logoUrl = useLogoUrl()

  return (
    <a href={LEASE_ROOT}>
      <div className={clsx(classes.imageContainer, className)}>
        <img className={classes.image} src={logoUrl} alt="logo" />
      </div>
    </a>
  )
}

export default Logo
