import { makeStyles, Tooltip, Typography } from '@material-ui/core'
import React, { useMemo, useState } from 'react'

import { HelpIcon, SquareIconButton, Tabs } from '~/legacy/components'

import NotificationsTab from './NotificationsTab'
import ProfileTab from './ProfileTab'

const useStyles = makeStyles({
  root: {
    width: '640px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '70px',
  },
  pageTitle: {
    marginBottom: '28px',
  },
  tabsContainer: {
    position: 'relative',
  },
  tabs: {
    marginBottom: '40px',
  },
  helpIcon: {
    position: 'absolute',
    right: 0,
    top: '10px',
  },
})

function Account() {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(
    location.hash === '#notifications' ? 1 : 0
  )

  useMemo(() => {
    switch (selectedTab) {
      case 0:
        location.hash = 'profile'
        break
      case 1:
        location.hash = 'notifications'
        break
      default:
    }
  }, [selectedTab])

  return (
    <div className={classes.root}>
      <Typography className={classes.pageTitle} variant="h1">
        Settings
      </Typography>
      <div className={classes.tabsContainer}>
        <Tabs
          className={classes.tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabLabels={['Profile', 'Notifications']}
        />
        <Tooltip className={classes.helpIcon} title="Contact Support">
          <a href="mailto:help@leaseup.co" style={{ color: "inherit", textDecoration: "none" }}>
            <SquareIconButton>
              <HelpIcon />
            </SquareIconButton>
          </a>
        </Tooltip>
      </div>
      {selectedTab === 0 && <ProfileTab />}
      {selectedTab === 1 && <NotificationsTab />}
    </div>
  )
}

export default Account
