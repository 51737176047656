import React, { Fragment } from 'react'
import { Settings } from '~/pdfs/patterns/Settings'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { LeaseUpPage } from '../patterns/LeaseUpPage'
import { buildAddress } from '../support/buildAddress'
import { buildBuildingName } from '../support/buildBuildingName'
import { styled } from '~/libraries/styled'
import { LeaseUpHeader } from '../patterns/LeaseUpHeader'
import { Group } from '~/pdfs/patterns/Group'
import { CheckboxSetting } from '~/pdfs/patterns/CheckboxSetting'
import { Font } from '~/components/Font'
import { convertFieldsToList } from '../support/convertFieldsToList'
import { Photo } from '~/pdfs/patterns/Photo'
import { findFieldAndFormat } from '~/support/findFieldAndFormat'
import { BuildingDescription, Price, Size } from '~/objects/fields'
import { isLike } from '~/support/isLike'
import { conjunction } from '~/support/conjunction'
import { Walkscore } from '~/pdfs/patterns/Walkscore'
import { buildSpaceName } from '../support/buildSpaceName'
import { image } from '~/pdfs/support/image'

export const LeaseUpBuilding = definePageTemplate({
  id: '94c9c648-db16-4cfd-9516-4eb7d4e5ed14',

  name: 'Building Details',

  props: (pdf, building) => ({
    photo: image(building.images[0], { width: 306 * 2 }),
    name: buildBuildingName(building),
    address: buildAddress(building.location),
    amenities: building.amenities,
    description: findFieldAndFormat(building.fields, BuildingDescription),
    fields: convertFieldsToList(
      building.fields.filter((field) => {
        return !isLike(field, BuildingDescription)
      })
    ),
    walkscores: building.walkscores,
    spaces: building.spaces.map((space) => ({
      id: space.id,
      name: buildSpaceName(space),
      size: findFieldAndFormat(space.fields, Size),
      price: findFieldAndFormat(space.fields, Price),
    })),
    flags: {
      photo: pdf.settings['building.photo'] ?? true,
      description: pdf.settings['building.description'] ?? true,
      amenities: pdf.settings['building.amenities'] ?? true,
      transport: pdf.settings['building.transport'] ?? true,
    },
  }),

  Page: (props) => (
    <Page>
      <LeaseUpHeader title={props.name} subtitle={props.address} />
      <Content>
        <Column>
          {props.flags.photo && props.photo && (
            <Photo src={props.photo} aspect={3 / 4} />
          )}

          {props.flags.description && props.description && (
            <div>
              <Header>
                <Font format="8/8/500" color="#666666" upper>
                  Building Notes
                </Font>
              </Header>
              <Font format="10/16" padding="8px 12px 0px">
                {props.description}
              </Font>
            </div>
          )}

          {props.spaces.length > 0 && (
            <div>
              <Header>
                <Font format="8/8/500" color="#666666" upper>
                  Available Space
                </Font>
              </Header>
              <Spaces>
                {props.spaces.map((space, index) => (
                  <Fragment key={space.id}>
                    <Field>
                      <Font format="10/16/600" truncate>
                        {space.name || `Space ${index + 1}`}
                      </Font>
                    </Field>
                    <Field>
                      <Font format="10/16" nowrap>
                        {space.size}
                      </Font>
                    </Field>
                    <Field>
                      <Font format="10/16" nowrap>
                        {space.price}
                      </Font>
                    </Field>
                  </Fragment>
                ))}
              </Spaces>
            </div>
          )}

          {props.flags.transport && (
            <div>
              <Scores>
                {props.walkscores.map((walkscore) => (
                  <Score key={walkscore.type} {...walkscore} />
                ))}
              </Scores>
            </div>
          )}
        </Column>

        <div>
          {props.fields.length > 0 && (
            <div>
              <Header>
                <Font format="8/8/500" color="#666666" upper>
                  Building Details
                </Font>
              </Header>
              {props.fields.map((field) => (
                <Field key={field.id}>
                  <Font format="10/16/600" nowrap>{`${field.name}:`}</Font>
                  <Font format="10/16">{field.value}</Font>
                </Field>
              ))}
            </div>
          )}

          {props.flags.amenities && props.amenities.length > 0 && (
            <Amenities>
              <Font format="10/16/600" inline>
                Amenities:&nbsp;
              </Font>
              <Font format="10/16" inline>
                {conjunction(props.amenities)}
              </Font>
            </Amenities>
          )}
        </div>
      </Content>
    </Page>
  ),

  Settings: (props) => (
    <Settings>
      <Group label="Details">
        <CheckboxSetting
          id="building.photo"
          label="Show Image"
          value={props.flags.photo}
        />
        <CheckboxSetting
          id="building.description"
          label="Show Notes"
          value={props.flags.description}
        />
        <CheckboxSetting
          id="building.transport"
          label="Show Transportation Scores"
          value={props.flags.transport}
        />
        <CheckboxSetting
          id="building.amenities"
          label="Show Amenities"
          value={props.flags.amenities}
        />
      </Group>
    </Settings>
  ),
})

const Page = styled(LeaseUpPage, {
  padding: '24px',
})

const Content = styled('div', {
  gridTemplateColumns: '1fr 1fr',
  marginTop: '24px',
  display: 'grid',
  gap: '16px',
})

const Column = styled('div', {
  flexDirection: 'column',
  display: 'flex',
  gap: '24px',
})

const Spaces = styled('div', {
  gridTemplateColumns: '1fr min-content min-content',
  display: 'grid',
})

const Field = styled('div', {
  borderBottom: '1px solid #e0e0e0',
  display: 'flex',
  padding: '8px 12px',
  gap: '4px',
})

const Header = styled('div', {
  flexShrink: 0,
  backgroundColor: '#f9f9f9',
  borderBottom: '1px solid #e0e0e0',
  padding: '12px',
})

const Amenities = styled('div', {
  padding: '8px 12px',
})

const Scores = styled('div', {
  gridTemplateColumns: '1fr 1fr 1fr',
  display: 'grid',
  gap: '6px',
})

const Score = styled(Walkscore, {
  flex: '1 0',
})
