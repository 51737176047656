import React from 'react';
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LEASE_CLIENT_LISTINGS } from '~/legacy/consts';

const useStyles = makeStyles((theme) => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

function MapButton({ className, iconClassName, surveyId }) {
  const classes = useStyles();
  return (
    <Tooltip title="SurveyBuildingsMap View" placement="top">
      <IconButton
        className={`${className} ${classes.icon}`}
        onClick={(event) => {
          event.stopPropagation();
          window.location = LEASE_CLIENT_LISTINGS.replace(':id', surveyId);
        }}
      >
        <Icon className={`fal fa-map ${iconClassName}`} />
      </IconButton>
    </Tooltip>
  );
}

export default MapButton;
