import React, { useState, useCallback } from 'react';

import {
  useDeleteSurveyMenuItem,
  useDuplicateSurveyMenuItem,
} from '~/legacy/components';
import { Menu } from './Menu';

// TODO: Generalize / abstract menu creation and item addition. Will do as part of Survey adding on PDP
export const useSurveyMenu = (
  deleteSurvey = async () => {},
  duplicateSurvey = async () => {}
) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState({});
  const selectedSurveyId =
    anchorMenuEl && anchorMenuEl.surveyId ? anchorMenuEl.surveyId : {};
  const selectedSurveyName =
    anchorMenuEl && anchorMenuEl.surveyName ? anchorMenuEl.surveyName : {};

  const handleMenuClick = useCallback((event, surveyId, surveyName) => {
    setAnchorMenuEl({ anchor: event.currentTarget, surveyId, surveyName });
  }, []);

  const handleMenuClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorMenuEl({ ...anchorMenuEl, anchor: null });
  };

  // Duplicate Survey Menu Item
  const DuplicateSurveyMenuItemComponent = useDuplicateSurveyMenuItem({
    duplicateSurvey: async () =>
      duplicateSurvey({ id: selectedSurveyId, name: selectedSurveyName }),
    handleMenuClose,
  });

  // Delete Survey Menu Item
  const { DeleteSurveyMenuItemComponent, DeleteSurveyMenuItemModalComponent } =
    useDeleteSurveyMenuItem({
      surveyName: selectedSurveyName,
      deleteSurvey: async () => deleteSurvey(selectedSurveyId),
      handleMenuClose,
    });

  const SurveyMenuComponent = (
    <SurveyMenu
      anchorMenuEl={anchorMenuEl ? anchorMenuEl.anchor : null}
      handleMenuClose={handleMenuClose}
      DuplicateSurveyMenuItemComponent={DuplicateSurveyMenuItemComponent}
      DeleteSurveyMenuItemComponent={DeleteSurveyMenuItemComponent}
    />
  );

  return {
    SurveyMenuComponent,
    handleMenuClick,
    DeleteSurveyMenuItemModalComponent,
  };
};

export const SurveyMenu = ({
  anchorMenuEl,
  handleMenuClose,
  DuplicateSurveyMenuItemComponent,
  DeleteSurveyMenuItemComponent,
}) => {
  return (
    <Menu
      id="more-options-menu"
      anchorEl={anchorMenuEl}
      open={!!anchorMenuEl}
      onClose={handleMenuClose}
      getContentAnchorEl={null}
    >
      {DuplicateSurveyMenuItemComponent}
      {DeleteSurveyMenuItemComponent}
    </Menu>
  );
};
