import React from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTransferSurveyOwnershipModal, SwapIcon } from '~/legacy/components';
import { BASE_ICON_STYLES } from './ButtonUtils';

export const useTransferSurveyOwnershipMenuItem = ({
  brokers,
  survey,
  onTransferSurveys = () => {},
  handleMenuClose = () => {},
}) => {
  // Get the modal for transfering survey ownership
  const { setOpen, TransferSurveyOwnershipModalComponent, setTransferSurvey } =
    useTransferSurveyOwnershipModal({
      onClose: handleMenuClose,
      onTransferSurveys,
      brokers,
    });

  // Get the menu item for deleting the survey
  const TransferSurveyOwnershipMenuItemComponent = (
    <TransferSurveyOwnershipMenuItem
      openModal={() => {
        setOpen(true);
        setTransferSurvey(survey);
      }}
    />
  );

  return {
    TransferSurveyOwnershipMenuItemComponent,
    TransferSurveyOwnershipModalComponent,
  };
};

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}));

export const TransferSurveyOwnershipMenuItem = React.forwardRef(
  ({ openModal, ...props }, ref) => {
    const classes = useStyles();
    return (
      <>
        <MenuItem
          ref={ref}
          className={classes.menuItemRoot}
          onClick={openModal}
          {...props}
        >
          <SwapIcon className={classes.icon} />
          <Typography className={classes.text}>Transfer Ownership</Typography>
        </MenuItem>
      </>
    );
  }
);
