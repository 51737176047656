import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExportSurveySettingsAndLayout,
  SquareCheckbox,
} from '~/legacy/components';

const useStyles = makeStyles({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '60px',
    cursor: 'pointer',
  },
  checkbox: {
    marginLeft: 0,
    marginRight: '12px',
    color: '#111111',
  },
});

function ListingFloorplanPageSettings(props) {
  const { settings, updateSettings } = props;
  const { showImageLabel } = settings;
  const classes = useStyles();

  const toggleLabel = () =>
    updateSettings({
      ...settings,
      showImageLabel: !showImageLabel,
    });
  return (
    <ExportSurveySettingsAndLayout {...props}>
      <div onClick={toggleLabel} className={classes.checkboxContainer}>
        <SquareCheckbox
          className={classes.checkbox}
          checked={showImageLabel}
          onClick={toggleLabel}
        />
        <Typography variant="h3">Floor - SqFt Label</Typography>
      </div>
    </ExportSurveySettingsAndLayout>
  );
}

export default ListingFloorplanPageSettings;
