import React from 'react'
import { styled } from '~/legacy/utils/styleHelpers'

export const ActivityDivider = ({ children }) => {
  return (
    <Container>
      <Line />
      {children}
      <Line />
    </Container>
  )
}

const Container = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  marginBottom: '24px',
  marginTop: '24px',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '12px',
  letterSpacing: '0.2px',
  textAlign: 'center',
  color: '#666',
})

const Line = styled('span', {
  backgroundColor: '#e0e0e0',
  height: '1px',
  flex: 1,
})
