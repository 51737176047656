import React from 'react';

export default function BikeScoreIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="5" cy="15" r="4.25" stroke="#111111" strokeWidth="1.5" />
      <circle cx="19" cy="15" r="4.25" stroke="#111111" strokeWidth="1.5" />
      <rect x="5" y="14.25" width="10" height="1.5" fill="#111111" />
      <rect x="10" y="10.25" width="8" height="1.5" fill="#111111" />
      <rect x="7" y="7.25" width="5" height="1.5" fill="#111111" />
      <path
        d="M12 5.75H14.6615V4.25H12V5.75ZM14.4294 5.59285L18.3036 15.2785L19.6964 14.7215L15.8221 5.03576L14.4294 5.59285ZM14.6615 5.75C14.5593 5.75 14.4673 5.68776 14.4294 5.59285L15.8221 5.03576C15.6323 4.56119 15.1726 4.25 14.6615 4.25V5.75Z"
        fill="#111111"
      />
      <path d="M9.5 8L12 15" stroke="#111111" strokeWidth="1.5" />
    </svg>
  )
}
