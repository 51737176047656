import { useMemo } from 'react'
import { useFetch } from './useFetch'
import { useMe } from './useMe'

export const useFlags = () => {
  const { data, mutate, isValidating } = useMe()
  const fetch = useFetch()
  return useMemo(() => {
    const flags = data ? data.flags.map((flag) => flag.toLowerCase()) : []
    return {
      isLoading: isValidating,
      has: (flag) => flags.includes(flag.toLowerCase()),
      complete: async (flag) => {
        await fetch('/api/ftux', { body: flag })
        mutate(console.log)
      },
    }
  }, [data, fetch, isValidating, mutate])
}
