import React, { useRef } from 'react'
import { GoogleMap } from '@react-google-maps/api'
import { useTheme } from '@material-ui/core'
import { styled } from '~/libraries/styled'
import { roadMapStyles } from '~/legacy/components/Map/GoogleMaps/style'
import { MapMarkerIcon2 } from '~/legacy/components/MapMarkerIcon2'

const defaultPosition = {
  lat: 42.3601,
  lng: -71.0589,
}

export const MapInput = (props) => {
  const theme = useTheme()
  const map = useRef(null)

  return (
    <Container className={props.className}>
      <GoogleMap
        center={props.center ?? defaultPosition}
        zoom={props.zoom ?? 17}
        mapContainerStyle={{
          height: '100%',
          width: '100%',
        }}
        options={{
          fullscreenControl: false,
          // mapTypeControl: false,
          streetViewControl: false,
          styles: roadMapStyles,
          ...props.options,
        }}
        onLoad={(value) => {
          map.current = value
        }}
        onCenterChanged={() => {
          if (map.current) {
            const position = map.current.getCenter().toJSON()
            props.onChange?.(position)
          }
        }}
      />
      <MapMarkerIcon2
        theme={theme}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-100%)',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      />
    </Container>
  )
}

const Container = styled('div', {
  position: 'relative',
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  height: '400px',
  overflow: 'hidden',
})
