import React from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { File, CameraIcon, CameraIcon2 } from '~/legacy/components';

const useDropzoneStyles = makeStyles((theme) => ({
  dropzone: {
    color: '#666',
    cursor: 'pointer',
    backgroundColor: '#E0E0E0',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
  },
  dropzoneLarge: {
    flex: 1,
    margin: '24px',
    alignItems: 'center',
  },
  mask: {
    cursor: 'pointer',
    position: 'absolute',
    borderRadius: '4px',
    outline: '0px solid transparent',
    color: 'transparent',
  },
  wrapperLarge: {
    position: 'relative',
    display: 'flex',
    flex: 1,
  },
  maskLarge: {
    top: '24px',
    right: '24px',
    bottom: '24px',
    left: '24px',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
    '& > *': {
      marginBottom: '6px',
    },
  },
  textLarge: {
    ...theme.typography.h3,
    fontSize: '18px',
  },
  dropzoneSmall: {
    height: '150px',
    width: '150px',
    border: '1px solid #E0E0E0',
    marginTop: '24px',
    marginRight: '24px',
    padding: '12px',
    alignItems: 'start',
  },
  wrapperSmall: {
    position: 'relative',
  },
  maskSmall: {
    top: '24px',
    right: '24px',
    bottom: '0',
    left: '0',
  },
  textSmall: {
    ...theme.typography.h3,
    fontSize: '12px',
  },
  camera2Icon: {
    color: '#666',
  },
  dragText: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cameraIcon2Container: {
    display: 'flex',
    marginTop: 'auto',
  },
}));

const DEFAULT_ACCEPTED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/webp',
  'application/pdf',
];

const ImageDropzone = ({
  isSmall,
  onFileDrop,
  acceptedFileTypes = DEFAULT_ACCEPTED_FILE_TYPES,
  overrideClasses = {},
  headerText = 'Upload Photos',
}) => {
  const classes = useDropzoneStyles();

  const {
    getRootProps,
    getInputProps,
    open: openDropzoneDialog,
  } = useDropzone({
    accept: acceptedFileTypes.join(','),
    noClick: true,
    noKeyboard: true,
    onDrop: onFileDrop,
  });

  return (
    <div
      className={clsx(isSmall ? classes.wrapperSmall : classes.wrapperLarge)}
      onDragOver={(ev) => {
        /* eslint-disable no-param-reassign */
        ev.preventDefault();
        ev.dataTransfer.dropEffect = 'copy';
        /* eslint-enable no-param-reassign */
      }}
      onDrop={(ev) => {
        // based on https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
        ev.preventDefault();

        let droppedFiles = [];

        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          ev.dataTransfer.items.forEach((item) => {
            // If dropped items aren't files, reject them
            if (item.kind === 'file') {
              const file = item.getAsFile();
              droppedFiles.push(file);
            }
          });
        } else {
          // Use DataTransfer interface to access the file(s)
          droppedFiles = droppedFiles.concat(ev.dataTransfer.files);
        }

        const acceptedFiles = [];
        droppedFiles.forEach((f) => {
          if (acceptedFileTypes.includes(f.type)) {
            acceptedFiles.push(f);
          }
        });

        onFileDrop(acceptedFiles);
      }}
    >
      <div
        className={clsx(
          classes.mask,
          isSmall ? classes.maskSmall : classes.maskLarge
        )}
        contentEditable
        onClick={openDropzoneDialog}
        onPaste={(event) => {
          event.preventDefault();

          const filesToUpload = [];
          const filesIn = Array.from(event.clipboardData.files);
          filesIn.forEach((fileIn) => {
            // rename file
            try {
              // some browser dont support this so
              // we catch and use a blob
              filesToUpload.push(new File([fileIn], uuidv4()));
            } catch (e) {
              // If we reach this point a new File could not be constructed
              const myBlob = new Blob([fileIn]);
              myBlob.lastModified = new Date();
              myBlob.name = uuidv4();
              filesToUpload.push(myBlob);
            }
          });
          onFileDrop(filesToUpload);
        }}
        onKeyPress={(e) => e.preventDefault()}
      />
      <div
        className={clsx(
          classes.dropzone,
          isSmall ? classes.dropzoneSmall : classes.dropzoneLarge,
          overrideClasses.dropzoneRoot
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isSmall ? (
          <div className={classes.dragText}>
            <Typography className={classes.textSmall} variant="h3">
              Drag and drop, click to browse, or copy and paste your photos
              here.
            </Typography>
            <div className={classes.cameraIcon2Container}>
              <CameraIcon2 className={classes.camera2Icon} />
            </div>
          </div>
        ) : (
          <div className={classes.textWrapper}>
            <CameraIcon className={overrideClasses.cameraIcon} />
            {!!headerText && (
              <Typography
                align="center"
                className={classes.textLarge}
                variant="h3"
              >
                {headerText}
              </Typography>
            )}
            <Typography align="center" variant="h3">
              Drag and drop, click to browse,
              <br />
              or copy and paste your photos here.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageDropzone;
