import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { format } from 'date-fns'
import lodash from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import Api from 'rest-fetcher-redux'

import {
  DEFAULT_SURVEY_SORT_OPTIONS,
  DeleteIcon,
  PageStickyNavBar,
  SortByTextField,
  sortSurveys,
  SURVEY_SORTS,
  TextButton,
  Typography,
  useAdminDeleteSurveysModal,
  useAdminSurveyMenu,
} from '~/legacy/components'
import {
  TableCellText,
  TableCheckboxCell,
  TableContentCell,
  TableHeaderCell,
  TableHeaderRow,
  TableHeaderText,
  TableMoreOptionsCell,
  TableRow,
} from '~/legacy/components/tableComponents'
import { getAdminUserDisplayName, getViewSurveyRoute } from '~/legacy/utils'
import { openLink } from '../utils/openLink'

const AdminSurveyRowItems = React.memo(({ survey, handleMenuClick }) => {
  return [
    <TableContentCell key="survey-name">
      <TableCellText>{survey.name}</TableCellText>
    </TableContentCell>,
    <TableContentCell key="survey-owner">
      <TableCellText>{getAdminUserDisplayName(survey.owner)}</TableCellText>
    </TableContentCell>,
    <TableContentCell key="survey-updated-at">
      <TableCellText>
        {survey.updated_at
          ? format(Date.parse(survey.updated_at), 'MM/dd/yyyy')
          : ''}
      </TableCellText>
    </TableContentCell>,
    <TableContentCell key="survey-created_at">
      <TableCellText>
        {survey.created_at
          ? format(Date.parse(survey.created_at), 'MM/dd/yyyy')
          : ''}
      </TableCellText>
    </TableContentCell>,
    <TableMoreOptionsCell
      key="survey-more-options"
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        handleMenuClick(event, survey)
      }}
    />,
  ]
})

const AdminSurveyRow = withStyles({
  tableRow: {
    '&$hoverBlock:hover': {
      backgroundColor: '#F9F9F9',
      cursor: 'pointer',
    },
  },
  hoverBlock: {},
})(({ survey, checked, checkSurvey, handleMenuClick, classes }) => {
  return (
    <TableRow
      hover
      classes={{ row: classes.tableRow }}
      TableRowClasses={{ hover: classes.hoverBlock }}
      onClick={openLink(getViewSurveyRoute(survey.id))}
    >
      <TableCheckboxCell
        CheckboxProps={{
          checked,
          onClick: (event) => {
            event.stopPropagation()
            event.preventDefault()
            checkSurvey(survey)
          },
        }}
      />
      <AdminSurveyRowItems survey={survey} handleMenuClick={handleMenuClick} />
    </TableRow>
  )
})

export const AdminSurveysTab = withStyles({
  table: {
    borderRadius: '4px',
    borderCollapse: 'separate',
    border: '1px solid #E0E0E0',
    tableLayout: 'fixed',

    // Table border radiuses
    '& tr:first-child th:first-child': {
      borderTopLeftRadius: '4px',
    },
    '& tr:first-child th:last-child': {
      borderTopRightRadius: '4px',
    },
    '& tr:last-child td:first-child': {
      borderBottomLeftRadius: '4px',
    },
    '& tr:last-child td:last-child': {
      borderBottomRightRadius: '4px',
    },
  },
  pageHorizontalPadding: {
    paddingLeft: '80px',
    paddingRight: '80px',
  },
  navSort: {
    marginLeft: '16px',
  },
  deleteSurveysButton: {
    height: '100%',
    padding: '0px 8px 0px 8px',
    justifyContent: 'space-between',
  },
  deleteSurveysButtonText: {
    marginLeft: '4px',
  },
  checkedUserActionsContainer: {
    marginLeft: 'auto',
    height: '36px',
  },
  surveysCount: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  tabContainer: {
    height: 'fit-content',
    width: '100%',
  },
  visible: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '80px',
  },
  hidden: {
    display: 'none',
  },
})(
  React.memo(({ adminUser, brokersInCompany, selected = false, classes }) => {
    const [surveys, setSurveys] = useState([])
    const [checkedSurveys, setCheckedSurveys] = useState(new Set())
    const [selectedSort, setSelectedSort] = useState(
      SURVEY_SORTS.OWNER_A_TO_Z.id
    )
    const [isLoadingSurveys, setIsLoadingSurveys] = useState(true)
    const numSurveys = surveys ? surveys.length : 0
    const someCheckedSurveys = checkedSurveys.size > 0
    const allSurveysChecked = !!(
      surveys &&
      surveys.length > 0 &&
      checkedSurveys.size === surveys.length
    )

    // The surveys to show on the page but sorted
    const sortedSurveys = useMemo(() => {
      return sortSurveys(surveys ?? [], selectedSort).filter((survey) => {
        return survey.name !== 'Project Survey'
      })
    }, [surveys, selectedSort])

    console.log({ sortedSurveys })

    const removeSurveys = (surveysToRemove) => {
      if (
        surveysToRemove &&
        surveysToRemove.length > 0 &&
        surveys &&
        surveys.length
      ) {
        const surveysToRemoveSet = new Set(
          surveysToRemove.map((surveyToRemove) => surveyToRemove.id)
        )
        setSurveys(
          surveys.filter((survey) => !surveysToRemoveSet.has(survey.id))
        )
      }
    }

    const refreshSurveys = React.useCallback(() => {
      const promise = Api.getSurveysAdmin()
      promise.then((result) => {
        if (result && result.data) {
          setSurveys(result.data)
        }
      })
      return promise
    }, [])

    // Make sure to refresh the surveys whenever the admin user changes
    useEffect(() => {
      if (adminUser && adminUser.id)
        refreshSurveys().finally(() => setIsLoadingSurveys(false))
    }, [adminUser, refreshSurveys])

    // Get the menu and associated components for taking action on a survey
    const {
      handleMenuClick,
      AdminSurveyMenuComponent,
      AdminDeleteSurveyModalComponent,
      TransferSurveyOwnershipModalComponent,
    } = useAdminSurveyMenu({
      brokers: brokersInCompany,
      onDeleteSurveys: (deletedSurveys) => removeSurveys(deletedSurveys),
      onTransferSurveys: (updatedSurveys) => {
        if (updatedSurveys && surveys && surveys.length) {
          const merge = lodash.map(surveys, (item) => {
            const foundElement = lodash.find(updatedSurveys, { id: item.id })
            return foundElement ? lodash.merge({}, item, foundElement) : item
          })
          setSurveys(merge)
        }
      },
    })

    // Get the modal for deleting the checked survey
    const { AdminDeleteSurveysModalComponent, setSurveysToDelete } =
      useAdminDeleteSurveysModal({
        onDeleteSurveys: (deletedSurveys) => removeSurveys(deletedSurveys),
      })

    const checkSurvey = React.useCallback((survey) => {
      setCheckedSurveys((currentCheckedSurveys) => {
        const newSet = new Set(currentCheckedSurveys)
        currentCheckedSurveys.has(survey.id)
          ? newSet.delete(survey.id)
          : newSet.add(survey.id)
        return newSet
      })
    }, [])

    if (isLoadingSurveys && selected) {
      return (
        <div
          className={clsx(
            classes.pageHorizontalPadding,
            classes.loadingContainer
          )}
        >
          <CircularProgress size={40} />
        </div>
      )
    }

    return (
      <div
        className={clsx(
          classes.tabContainer,
          selected ? classes.visible : classes.hidden
        )}
      >
        <PageStickyNavBar
          classes={{ root: clsx(classes.pageHorizontalPadding) }}
        >
          <Typography variant="boldText" className={classes.surveysCount}>
            {`${numSurveys} Survey${numSurveys === 1 ? '' : 's'}`}
          </Typography>
          <SortByTextField
            className={classes.navSort}
            options={DEFAULT_SURVEY_SORT_OPTIONS}
            selectedValue={selectedSort}
            setSelectedValue={setSelectedSort}
            defaultValue={SURVEY_SORTS.OWNER_A_TO_Z.id}
          />
          {!!someCheckedSurveys && (
            <div className={classes.checkedUserActionsContainer}>
              <TextButton
                fullWidth
                classesIn={{ button: classes.deleteSurveysButton }}
                onClick={() => {
                  setSurveysToDelete(
                    surveys
                      .filter((survey) => checkedSurveys.has(survey.id))
                      .map((checkedSurvey) => ({
                        id: checkedSurvey.id,
                        name: checkedSurvey.name,
                      }))
                  )
                }}
              >
                <DeleteIcon />
                <Typography
                  variant="boldText"
                  noWrap
                  className={classes.deleteSurveysButtonText}
                >
                  Delete Surveys
                </Typography>
              </TextButton>
            </div>
          )}
        </PageStickyNavBar>
        <div
          className={clsx(
            classes.tableContainer,
            classes.pageHorizontalPadding
          )}
        >
          <TableContainer>
            <Table size="small" className={classes.table}>
              <colgroup>
                <col style={{ width: '52px', minWidth: '52px' }} />
                <col style={{ width: '40%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '60px', minWidth: '60px' }} />
              </colgroup>
              <TableHead>
                <TableHeaderRow>
                  <TableCheckboxCell
                    isHeader
                    CheckboxProps={{
                      checked: allSurveysChecked,
                      indeterminate: !!(
                        !allSurveysChecked && checkedSurveys.size
                      ),
                      onClick: () => {
                        let newSet
                        if (allSurveysChecked) {
                          newSet = new Set()
                        } else {
                          newSet = new Set(surveys.map((user) => user.id))
                        }
                        setCheckedSurveys(newSet)
                      },
                    }}
                  />
                  <TableHeaderCell>
                    <TableHeaderText>NAME</TableHeaderText>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <TableHeaderText>OWNER</TableHeaderText>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <TableHeaderText>DATE UPDATED</TableHeaderText>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <TableHeaderText>DATE CREATED</TableHeaderText>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <TableHeaderText />
                  </TableHeaderCell>
                </TableHeaderRow>
              </TableHead>
              <TableBody>
                {!!(sortedSurveys && sortedSurveys.length) &&
                  sortedSurveys.map((survey) => (
                    <AdminSurveyRow
                      key={survey.id}
                      survey={survey}
                      checked={!!checkedSurveys.has(survey.id)}
                      checkSurvey={checkSurvey}
                      handleMenuClick={handleMenuClick}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {AdminSurveyMenuComponent}
        {AdminDeleteSurveyModalComponent}
        {AdminDeleteSurveysModalComponent}
        {TransferSurveyOwnershipModalComponent}
      </div>
    )
  })
)
