const ACTIONS = {
  IMAGE_UPLOAD_START: 'IMAGE_UPLOAD_START',
  IMAGE_UPLOAD_COMPLETE: 'IMAGE_UPLOAD_COMPLETE',
  IMAGE_UPLOAD_ERROR: 'IMAGE_UPLAOD_ERROR',
  SET_UPLOADED_IMAGES: 'SET_UPLOADED_IMAGES',
};

const initialState = {
  storedImages: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.IMAGE_UPLOAD_START:
      return {
        ...state,
        storedImages: { ...state.storedImages, ...action.payload },
      };
    case ACTIONS.IMAGE_UPLOAD_COMPLETE:
      return {
        ...state,
        storedImages: { ...state.storedImages, ...action.payload },
      };
    case ACTIONS.IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        storedImages: action.payload,
      };
    case ACTIONS.SET_UPLOADED_IMAGES:
      return {
        ...state,
        storedImages: action.payload,
      };
    default:
      return state;
  }
}

export const startUpload = payload => ({
  type: ACTIONS.IMAGE_UPLOAD_START,
  payload,
});
export const finishUpload = payload => ({
  type: ACTIONS.IMAGE_UPLOAD_COMPLETE,
  payload,
});
export const uploadError = payload => ({
  type: ACTIONS.IMAGE_UPLOAD_ERROR,
  payload,
});
export const setUploadedImages = payload => ({
  type: ACTIONS.SET_UPLOADED_IMAGES,
  payload,
});
