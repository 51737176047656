import React from 'react';

import { SdpMapMarker } from '~/legacy/components';

const MapMarkerIcon = ({ isActive = false, theme = {} }) => {
  return (
    <SdpMapMarker
      fill={
        isActive ? theme.palette.mapPins.activeFill : theme.palette.mapPins.fill
      }
      stroke={
        isActive
          ? theme.palette.mapPins.activeStroke
          : theme.palette.mapPins.stroke
      }
    />
  );
};

export default MapMarkerIcon;
