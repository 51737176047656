import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Divider,
  Typography,
} from '@material-ui/core';
import { Button, TextInput } from '~/legacy/components';
import { ModalTitle } from '~/legacy/components/modals';
import { setSurveyListingStatus } from '~/legacy/store/actions/surveyListingsActions';
import { toast } from '~/legacy/utils/notifications';
import { useApiHelper } from '~/legacy/utils/hooks';

// eslint-disable-next-line
import { SURVEY_LISTING_STATUSES } from '~/legacy/consts';

const useStyles = makeStyles({
  declineListingSurvey: {
    width: 'calc(100vw - 64px)',
    maxWidth: '550px',
    paddingBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    /* For whatever reason this is required downstream to take effect */
    marginBottom: '20px !important',
  },
  buttonsContainer: {
    marginTop: '16px',
    maxWidth: '550px',
    minWidth: '300px',
    display: 'flex',
  },
  button: {
    marginRight: '8px',
  },
  listItem: {
    height: '40px',
  },
  listItemIcon: {
    minWidth: '10px',
  },
  textFieldTitleContainer: {
    marginTop: '10px',
  },
  textField: {
    marginTop: '5px',
  },
});

/**
 * A modal to decline a client listing. Will update the data.clientlisting redux store by default.
 * Submit behavior can be overriden via prop.
 *
 * onClose: called when closing the modal
 * address: listing address displayed in the modal
 * clientLIstingId (optional): the client listing id, required if not providing onSubmitOverride.
 * onSubmitOverride (optional): function used when submitting the modal, overriding default behavior
 */
function DeclineListingModal({
  onClose,
  address,
  clientListingId,
  onSubmitOverride,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const checkboxText = {
    size: 'Size',
    price: 'Price',
    location: 'Location',
    amenities: 'Amenities',
    space_features: 'Space Features',
    neighborhood: 'Neighborhood',
    lease_length: 'Lease Length',
  };
  // Effective set with boolean value of whether or not checked
  const checkedInit = {
    size: false,
    price: false,
    location: false,
    amenities: false,
    space_features: false,
    neighborhood: false,
    lease_length: false,
  };
  const [checked, setChecked] = useState(checkedInit);
  const [otherText, setOtherText] = useState('');
  const apiHelper = useApiHelper();

  // The default action for submitting the listing decline.
  const onSubmitDefaultAction = () => {
    const newStatus = SURVEY_LISTING_STATUSES.DECLINED;
    apiHelper
      .updateSurveyListingStatus({
        surveyListingId: clientListingId,
        newStatus,
        colors: theme.palette.primary,
        statusChange: newStatus,
      })
      .then(() => {
        dispatch(setSurveyListingStatus(clientListingId, newStatus));
        onClose();
        toast('Feedback submitted!', { appearance: 'success' });
      });
  };
  const onSubmit = !onSubmitOverride ? onSubmitDefaultAction : onSubmitOverride;

  const handleToggle = (key) => {
    // Toggle checked status when user clicks a checkbox
    setChecked({ ...checked, [key]: !checked[key] });
  };

  const handleSubmit = () => {
    // Submit the survey status change with reasons
    onSubmit({ ...checked, other: otherText });
  };

  return (
    <div
      className={classes.declineListingSurvey}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <ModalTitle
        classNames={{ container: classes.container }}
        onClose={onClose}
        title={address ? `Feedback for ${address}` : 'Feedback'}
      />
      <Divider />

      {/* Checkbox list of survey reasons */}
      <List className={classes.root}>
        {Object.entries(checkboxText).map((entry) => {
          const key = entry[0];
          const value = entry[1];
          const labelId = `checkbox-list-label-${key}`;

          return (
            <ListItem
              className={classes.listItem}
              key={key}
              role={undefined}
              dense
              button
              onClick={() => handleToggle(key)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  color="primary"
                  edge="start"
                  checked={checked[key]}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={<Typography variant="body1">{value}</Typography>}
                disableTypography
              />
            </ListItem>
          );
        })}
      </List>

      {/* Other survey feedback box title and input */}
      <div className={classes.textFieldTitleContainer}>
        <Typography variant="body1">Other</Typography>
      </div>
      <TextInput
        className={classes.textField}
        multiline
        rows={4}
        placeholder="Any other notes you would like to share about this space"
        fullWidth
        onChange={(event) => {
          setOtherText(event.target.value);
        }}
        value={otherText}
      />

      {/* Submit and cancel buttons */}
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          color="primary"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button className={classes.button} onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
DeclineListingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  clientListingId: PropTypes.number,
  onSubmitOverride: PropTypes.func,
};
DeclineListingModal.defaultProps = {
  clientListingId: null,
  onSubmitOverride: null,
};

export default DeclineListingModal;
