import { toast } from '~/legacy/utils/notifications';

export default {
  postConfirmInvite: {
    url: '/useradmin/confirm_invite/',
    options: {
      method: 'POST',
      credentials: 'omit',
    },
  },
  confirmInviteLink: {
    url: '/useradmin/confirm_invite_link/',
    options: {
      credentials: 'omit',
      method: 'POST',
    },
  },
  inviteUsersToProject: {
    url: '/useradmin/users_invite/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () =>
        toast('Successfully invited', {
          appearance: 'success',
        }),
    ],
  },
};
