import React from 'react';

export default function SdpMapMarker({ fill, stroke }) {
  return (
    <svg width="33px" height="40px" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1779_3638)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1743 12C28.1743 5.37258 22.7627 0 16.0872 0C9.41161 0 4 5.37258 4 12C4 21.6 12.0581 29.3333 16.0872 32C20.1162 29.3333 28.1743 21.6 28.1743 12Z"
          fill={fill}
          stroke={stroke}
        />
        <ellipse cx="16.0876" cy="12" rx="4.02906" ry="4" fill="white" />
      </g>
      <defs>
        <filter id="filter0_d_1779_3638" x="0" y="0" width="32.1758" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1779_3638" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1779_3638" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
