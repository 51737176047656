import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';

const TextField = ({ forceLabelAsLabel = false, ...props }) => {
  const moreProps = {};

  // Keep the Label title up top even though not focused
  if (forceLabelAsLabel) {
    moreProps.InputLabelProps = { shrink: true };
  }
  return (
    <MuiTextField fullWidth open variant="outlined" {...moreProps} {...props} />
  );
};

export default TextField;
