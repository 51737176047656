import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { findFieldAndFormat } from '~/support/findFieldAndFormat'
import * as Fields from '../CresaFields'
import { CresaPage } from '../patterns/CresaPage'
import { findMarket } from '../support/findMarket'
import { mediaUrl } from '~/support/mediaUrl'
import { appUrl } from '~/support/appUrl'

const useStyles = makeStyles({
  title: {
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '18px',
    color: '#001E5A',
    paddingTop: '15px',
  },
  subtitle: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '18px',
    color: '#001E5A',
    paddingTop: '6px',
  },
  details: {
    display: 'flex',
    gap: '12px',
    paddingTop: '30px',
    minHeight: '237px',
    alignItems: 'flex-start',
  },
  photo: {
    width: '270px',
    height: '250px',
    background: '#D9D9D9',
    objectFit: 'cover',
    flexShrink: 0,
  },
  fields: {
    gridTemplateColumns: '1fr 1fr',
    display: 'grid',
    gap: '10px 24px',
  },
  key: {
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '10px',
    color: '#000000',
  },
  value: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '10px',
    color: '#000000',
  },
  space: {
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '12px',
    color: '#001E5A',
    paddingTop: '22px',
  },
  spaces: {
    gridTemplateColumns: '1fr 3fr 3fr 3fr 3fr',
    gridAutoRows: 'auto',
    alignContent: 'start',
    paddingTop: '8px',
    display: 'grid',
    minHeight: '130px',
  },
  head: {
    background: '#001E5A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '10px',
    color: '#FFFFFF',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '9px',
    lineHeight: '10px',
    color: '#111111',
  },
  notes: {
    paddingTop: '15px',
    gridTemplateColumns: '1fr 1fr',
    display: 'grid',
    gap: '12px',
  },
  list: {
    paddingLeft: '12px',
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '9px',
    lineHeight: '10px',
    color: '#111111',
  },
  item: {
    marginBottom: '10px',
  },
  heading: {
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '12px',
    color: '#001E5A',
  },
  link: {
    position: 'absolute',
    bottom: '20px',
    left: '36px',
    width: '110px',
    height: '20px',
    background: `url(${mediaUrl('/pdf/CresaViewMoreDetails.png')})`,
    backgroundColor: '#001E5A',
    display: 'block',
    backgroundSize: 'cover',
  },
  cta: {
    pointerEvents: 'none',
    position: 'absolute',
    bottom: '20px',
    left: '36px',
  },
})

export const CresaProperty = definePageTemplate({
  id: '2643f71b-a687-4303-8db2-b23fb6077dd5',

  name: 'Property Information',

  props: ({ settings }, building) => ({
    id: building.id,
    site: building.order + 1,
    photo: building.images[0]?.compressed,
    name: ['name', 'address']
      .map((key) => building.location[key])
      .filter(Boolean)
      .join(', '),
    subMarket: findFieldAndFormat(building.fields, Fields.SubMarket),
    city: ['city', 'state']
      .map((key) => building.location[key])
      .filter(Boolean)
      .join(', '),
    fields: findMarket(settings.marketId).fields.map((field) => ({
      id: field.id,
      name: field.name,
      value: findFieldAndFormat(building.fields, field),
    })),
    spaces: building.spaces.map((space) => ({
      id: space.id,
      floor: findFieldAndFormat(space.fields, Fields.Floor),
      size: findFieldAndFormat(space.fields, Fields.Area),
      rent: findFieldAndFormat(space.fields, Fields.Price),
      type: findFieldAndFormat(space.fields, Fields.LeaseType),
      available: findFieldAndFormat(space.fields, Fields.Available),
    })),
    link: appUrl(`/surveys/view/${building.survey.id}`),
    buildingNotes: findFieldAndFormat(building.fields, Fields.BuildingNotes)
      .split('\n')
      .filter(Boolean)
      .slice(0, 6),
    spaceNotes: building.spaces
      .flatMap((space) => {
        return findFieldAndFormat(space.fields, Fields.SpaceNotes)
      })
      .filter(Boolean)
      .slice(0, 6),
  }),

  Page: (props) => {
    const classes = useStyles()
    return (
      <CresaPage title={`Site ${props.site}`}>
        <div className={classes.title}>{props.name}</div>
        <div className={classes.subtitle}>
          {[props.subMarket, props.city].filter(Boolean).join(' | ')}
        </div>
        <div className={classes.details}>
          {props.photo && (
            <img className={classes.photo} src={props.photo} alt={props.name} />
          )}
          <div className={classes.fields}>
            {props.fields.map((field) => (
              <Fragment key={field.id}>
                <div className={classes.key}>{field.name}</div>
                <div className={classes.value}>{field.value}</div>
              </Fragment>
            ))}
          </div>
        </div>

        <div className={classes.space}>Available Space</div>
        <div className={classes.spaces}>
          <div className={classes.head}>Floor</div>
          <div className={classes.head}>Rentable SF</div>
          <div className={classes.head}>Base Rent</div>
          <div className={classes.head}>Lease Type</div>
          <div className={classes.head}>Available</div>
          {props.spaces.map((space) => (
            <Fragment key={space.id}>
              <div className={classes.field}>{space.floor}</div>
              <div className={classes.field}>{space.size}</div>
              <div className={classes.field}>{space.rent}</div>
              <div className={classes.field}>{space.type}</div>
              <div className={classes.field}>{space.available}</div>
            </Fragment>
          ))}
        </div>

        <div className={classes.notes}>
          <div>
            <div className={classes.heading}>Building Notes</div>
            <ul className={classes.list}>
              {props.buildingNotes.filter(Boolean).map((note) => (
                <li key={note} className={classes.item}>
                  {note}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <div className={classes.heading}>Space Notes</div>
            <ul className={classes.list}>
              {props.spaceNotes.filter(Boolean).map((note) => (
                <li key={note} className={classes.item}>
                  {note}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <a className={classes.link} href={props.link}>
          {/* View more details */}
        </a>
      </CresaPage>
    )
  },
})
