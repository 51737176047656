import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import {
  SquareIconButton,
  FavoriteIconOutlined,
  FavoriteIconFilled,
} from '~/legacy/components';
import { SURVEY_LISTING_STATUSES } from '~/legacy/consts';
import { useApiHelper } from '~/legacy/utils/hooks';
import { setSurveyListings } from '~/legacy/store/actions/viewSurvey';
import { toast } from '~/legacy/utils/notifications';

export default function ScheduleTourIconButton({
  surveyListing,
  allSurveyListings,
  SquareIconButtonProps = {},
  ScheduleTourCheckedIconProps = {},
  ScheduleTourIconProps = {},
}) {
  const apiHelper = useApiHelper();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  return (
    <SquareIconButton
      loading={loading}
      disabled={surveyListing.status === SURVEY_LISTING_STATUSES.TOUR_REQUESTED}
      showAsDisabled={surveyListing.status === SURVEY_LISTING_STATUSES.DECLINED}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        setLoading(true);
        const newStatus = SURVEY_LISTING_STATUSES.TOUR_REQUESTED;
        apiHelper
          .updateSurveyListingStatus({
            surveyListingId: surveyListing.id,
            newStatus,
            colors: theme.palette.primary,
            statusChange: newStatus,
          })
          .then(() => {
            const newSurveyListings = [];
            allSurveyListings.forEach((surveyListingLocal) => {
              if (surveyListingLocal.id === surveyListing.id) {
                newSurveyListings.push({
                  ...surveyListingLocal,
                  status: newStatus,
                });
              } else {
                newSurveyListings.push(surveyListingLocal);
              }
            });
            dispatch(setSurveyListings(newSurveyListings));
            setLoading(false);
            toast('Listing favorited!', {
              appearance: 'success',
            });
          });
      }}
      {...SquareIconButtonProps}
    >
      {surveyListing.status === SURVEY_LISTING_STATUSES.TOUR_REQUESTED ? (
        <FavoriteIconFilled {...ScheduleTourCheckedIconProps} />
      ) : (
        <FavoriteIconOutlined {...ScheduleTourIconProps} />
      )}
    </SquareIconButton>
  );
}
