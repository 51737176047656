import { mediaUrl } from '~/support/mediaUrl'
import * as Fields from './CresaFields'

export const Urban = {
  value: 'urban',
  label: 'Urban',
  photo: mediaUrl('/pdf/CresaUrban.jpg'),
  fields: [
    Fields.BuildingSize,
    Fields.BuildingType,
    Fields.Floors,
    Fields.YearBuilt,
    Fields.YearRenovated,
    Fields.Parking,
    Fields.Security,
    Fields.Cafe,
    Fields.Conference,
    Fields.Gym,
    Fields.Bike,
    Fields.Transit,
  ],
}

export const Suburban = {
  value: 'suburban',
  label: 'Suburban',
  photo: mediaUrl('/pdf/CresaSuburban.jpg'),
  fields: [
    Fields.BuildingSize,
    Fields.BuildingType,
    Fields.Floors,
    Fields.YearBuilt,
    Fields.YearRenovated,
    Fields.Landlord,
    Fields.Parking,
    Fields.Cafe,
    Fields.Conference,
    Fields.Gym,
  ],
}

export const Industrial = {
  value: 'industrial',
  label: 'Industrial',
  photo: mediaUrl('/pdf/CresaIndustrial.jpg'),
  fields: [
    Fields.BuildingSize,
    Fields.BuildingType,
    Fields.Floors,
    Fields.YearBuilt,
    Fields.YearRenovated,
    Fields.Landlord,
    Fields.Parking,
    Fields.CeilingHeight,
    Fields.Dock,
    Fields.DriveIn,
    Fields.Hvac,
    Fields.Power,
    Fields.NNN,
  ],
}

export const Science = {
  value: 'science',
  label: 'Life Science',
  photo: mediaUrl('/pdf/CresaScience.jpg'),
  fields: [
    Fields.BuildingSize,
    Fields.BuildingType,
    Fields.Landlord,
    Fields.Parking,
    Fields.Power,
    Fields.ClearHeight,
    Fields.Occupancy,
    Fields.Dock,
    Fields.DriveIn,
    Fields.Transit,
  ],
}
