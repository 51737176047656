import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  Button,
  TransparentScrollOverlay,
  SquareCheckbox,
  File,
} from '~/legacy/components';
import { getListingPrimaryName, getBuildingTertiaryName } from '~/legacy/utils';
import { toast } from '~/legacy/utils/notifications';

import ModalTitle from './ModalTitle';

const useStyles = makeStyles({
  modalContent: {
    background: 'white',
    outline: 'none',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
    height: '580px',
    marginTop: '4px',
    marginBottom: '16px',
  },
  saveButton: {
    width: '150px',
    height: '44px',
    marginLeft: 'auto',
  },
  row: {
    height: '72px',
    width: '100%',
    borderTop: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: '1px solid #E0E0E0',
    },
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxContainer: {
    marginLeft: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
  blackBackground: {
    color: '#111',
  },
  image: {
    width: '50px',
    height: '50px',
  },
  itemSpace: {
    marginLeft: '12px',
  },
  saveButtonContainer: {
    width: '100%',
    display: 'flex',
  },
});

const ListingRow = ({ listing, checked, updateSelectedListings }) => {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.checkboxContainer}>
        <SquareCheckbox
          selectedCheckboxIconClasses={classes.blackBackground}
          checked={checked}
          onClick={() => updateSelectedListings(!checked)}
        />
      </div>
      <div className={clsx(classes.image, classes.itemSpace)}>
        <File
          file={listing.hero_photo || {}}
          skipUploading
          showAsThumbnail
          useMissingBuildingImage
          missingFileStyleOverrides={{ backgroundColor: '#E0E0E0' }}
          buildingIconStyleOverrides={{ color: '#666666' }}
        />
      </div>
      <div className={clsx(classes.nameContainer, classes.itemSpace)}>
        <Typography variant="body1">
          {getListingPrimaryName(listing, true)}
        </Typography>
        <Typography variant="body1">
          {getBuildingTertiaryName(listing.building)}
        </Typography>
      </div>
    </div>
  );
};

function ComparisonTableAddMoreListingsModal({
  onClose,
  allListingsInSurvey,
  selectedListingsSet,
  setSelectedListingsSet,
}) {
  const classes = useStyles();

  const [modalSelectedListingsSet, setModalSelectedListingsSet] =
    useState(selectedListingsSet);

  useEffect(() => {
    setModalSelectedListingsSet(selectedListingsSet);
  }, [selectedListingsSet]);

  return (
    <div className={classes.modalContent}>
      <ModalTitle onClose={onClose} title="Add More Spaces" />
      <TransparentScrollOverlay
        paddingPx="0"
        hideScroll
        gradientHeightPx={60}
        bottomPaddingPx={40}
      >
        {allListingsInSurvey.map((listing) => (
          <ListingRow
            listing={listing}
            key={listing.id}
            checked={
              (modalSelectedListingsSet &&
                modalSelectedListingsSet.has(listing.id)) ||
              false
            }
            updateSelectedListings={(isAdding) => {
              const newSet = new Set(modalSelectedListingsSet);
              if (isAdding) {
                newSet.add(listing.id);
              } else {
                newSet.delete(listing.id);
              }
              setModalSelectedListingsSet(newSet);
            }}
          />
        ))}
      </TransparentScrollOverlay>
      <div className={classes.saveButtonContainer}>
        <Button
          color="primary"
          onClick={() => {
            setSelectedListingsSet(modalSelectedListingsSet);
            onClose();
            toast('Table settings applied!');
          }}
          className={classes.saveButton}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default ComparisonTableAddMoreListingsModal;
