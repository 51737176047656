import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Api from 'rest-fetcher-redux';
import {
  AdminSurveysTab,
  AdminThemeTab,
  AdminUsersTab,
  Tabs,
  Typography,
} from '~/legacy/components';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    padding: '0 0 0 0',
    marginRight: 0,
    marginLeft: 0,
    overflowY: 'scroll',
  },
  pageHorizontalPadding: {
    paddingLeft: '80px',
    paddingRight: '80px',
  },
  headerContainer: {
    paddingTop: '70px',
  },
  tabsContainer: {
    paddingTop: '40px',
  },
  hidden: {
    display: 'none',
  },
  visible: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const TABS = {
  USERS_TAB: {
    id: 0,
    urlParam: 'users',
    label: 'Users',
  },
  SURVEYS_TAB: {
    id: 1,
    urlParam: 'surveys',
    label: 'Surveys',
  },
  THEME_TAB: {
    id: 2,
    urlParam: 'theme',
    label: 'Theme',
  },
};
const TAB_LABELS = Object.values(TABS).map((value) => value.label);
const TABS_LOOKUP_BY_URL_PARAM = Object.fromEntries(
  Object.values(TABS).map((value) => [value.urlParam, value])
);
const TABS_LOOKUP_BY_ID = Object.fromEntries(
  Object.values(TABS).map((value) => [value.id, value])
);
const TAB_PARAM = 'tab';

const Admin = () => {
  const classes = useStyles();

  const adminUser = useSelector((s) => s.user);

  // Get the desired tab from the URL
  const queryParams = new URLSearchParams(useLocation().search);
  const userSelectedTabString = queryParams.get(TAB_PARAM);
  const userSelectedTab = TABS_LOOKUP_BY_URL_PARAM[userSelectedTabString];
  const userSelectedTabId = userSelectedTab ? userSelectedTab.id : null;
  const [tabValue, setTabValue] = useState(
    userSelectedTabId || TABS.USERS_TAB.id
  );

  const [brokersInCompany, setBrokersInCompany] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);

  // There can only be one
  const onUsersTab = tabValue === TABS.USERS_TAB.id;
  const onSurveysTab = !onUsersTab && tabValue === TABS.SURVEYS_TAB.id;
  const onThemeTab =
    !onUsersTab && !onSurveysTab && tabValue === TABS.THEME_TAB.id;

  // Update the page with the users in the admin users company
  const refreshUsers = React.useCallback(() => {
    const promise = Api.getUsersInMyCompany({ id: adminUser.id });
    promise.then((result) => {
      if (result && result.data) {
        setBrokersInCompany(result.data);
      }
    });
    return promise;
  }, [adminUser]);

  // Change the specified tab when changing between tabs
  useEffect(() => {
    const tabValueData = TABS_LOOKUP_BY_ID[tabValue];
    if (tabValueData) {
      window.history.replaceState(
        null,
        null,
        `?${TAB_PARAM}=${tabValueData.urlParam}`
      );
    }
  }, [tabValue]);

  // Make sure to refresh the users whenever the admin user changes
  useEffect(() => {
    if (adminUser && adminUser.id)
      refreshUsers().finally(() => setIsLoadingUsers(false));
  }, [adminUser, refreshUsers]);

  return (
    <Container key="admin-container" className={classes.root}>
      <div
        className={clsx(classes.headerContainer, classes.pageHorizontalPadding)}
      >
        <Typography variant="pageTitle"> Admin </Typography>
      </div>
      <div
        className={clsx(classes.tabsContainer, classes.pageHorizontalPadding)}
      >
        <div>
          <Tabs
            selectedTab={tabValue}
            setSelectedTab={(newValue) => setTabValue(newValue)}
            indicatorColor="primary"
            variant="standard"
            tabLabels={TAB_LABELS}
          />
        </div>
      </div>
      <AdminUsersTab
        adminUser={adminUser}
        users={brokersInCompany}
        setUsers={setBrokersInCompany}
        isLoadingUsers={isLoadingUsers}
        selected={!!onUsersTab}
      />
      <AdminSurveysTab
        adminUser={adminUser}
        brokersInCompany={brokersInCompany}
        selected={!!onSurveysTab}
      />
      <AdminThemeTab adminUser={adminUser} selected={!!onThemeTab} />
    </Container>
  );
};

export default Admin;
