import React from 'react'
import { Photo } from '~/pdfs/patterns/Photo'
import { styled } from '~/libraries/styled'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { LeaseUpHeader } from '../patterns/LeaseUpHeader'
import { LeaseUpPage } from '../patterns/LeaseUpPage'
import { buildBuildingName } from '../support/buildBuildingName'
import { buildAddress } from '../support/buildAddress'
import { image } from '~/pdfs/support/image'

export const LeaseUpBuildingDivider = definePageTemplate({
  id: '2cbde801-02e7-44b6-92ed-10bba4ca39b8',

  name: 'Building Divider',

  props: (_, building) => ({
    photo: image(building.images[0], { width: 612 * 2 }),
    name: buildBuildingName(building),
    address: buildAddress(building.location),
  }),

  Page: (props) => (
    <Page>
      <LeaseUpHeader title={props.name} subtitle={props.address} />
      {props.photo && <Photo src={props.photo} grow />}
    </Page>
  ),
})

const Page = styled(LeaseUpPage, {
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
})
