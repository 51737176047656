import React from 'react';
import { useSelector } from 'react-redux';
import { lighten, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextChip } from '../Chip';

const useStyles = makeStyles(theme => ({
  amenity: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
  },
  deleteIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  }
}));

function Amenities({
  amenities = [],
  textFieldProps = {},
  autocompleteProps = {},
  updateAmenities,
}) {
  const classes = useStyles();
  const predefinedAmenities = useSelector(s => s.api.init.data.amenities) || [];

  return (
    <Autocomplete
      freeSolo
      multiple
      onChange={(event, newAmenitiesArray) => {
        updateAmenities(newAmenitiesArray);
      }}
      options={predefinedAmenities.map(amenity => amenity.name)}
      renderInput={params => (
        <TextField
          {...params}
          placeholder="Amenities"
          variant="outlined"
          {...textFieldProps}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <TextChip
            key={option}
            classes={{ root: classes.amenity, deleteIcon: classes.deleteIcon }}
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      value={amenities || []}
      {...autocompleteProps}
    />
  );
}

export default Amenities;
