import useSWR from 'swr'

const autoComplete = new google.maps.places.AutocompleteService()

export const useSearchMaps = (search = '') => {
  return useSWR(search, {
    fallbackData: [],
    fetcher: async () => {
      if (search.length < 2) return []
      const results = await autoComplete.getPlacePredictions({ input: search })
      return results.predictions
    },
  })
}
