import React from 'react';
import { Menu } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditListingMenuItem,
  useRemoveSpaceFromSurveyMenuItem,
} from '~/legacy/components';
import { removeListingsFromSurvey } from '../../utils';

export default function SurveyListingMenu({
  listingId,
  surveyId,
  surveyListing,
  setSelectedSurveyListings = () => {},
  isBdpBuildout = false,
  handleMenuClose = () => {},
  ...menuProps
}) {
  const dispatch = useDispatch();
  const surveyListings = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  );
  const surveyBuildings = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  );
  const surveyListingsSetToRemove = new Set(
    surveyListing ? [surveyListing.id] : []
  );
  const { RemoveSpaceFromSurveyMenuItemComponent } =
    useRemoveSpaceFromSurveyMenuItem({
      label: `Remove Space${
        surveyListingsSetToRemove && surveyListingsSetToRemove.size > 1
          ? 's'
          : ''
      }`,
      disabled: !surveyListing,
      handleMenuClose,
      onClickOverride: (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (surveyListingsSetToRemove.size) {
          removeListingsFromSurvey(
            surveyId,
            surveyListings,
            surveyBuildings,
            surveyListingsSetToRemove,
            dispatch,
            setSelectedSurveyListings,
            isBdpBuildout ? [] : null
          );
        }
        handleMenuClose(e);
      },
    });
  return (
    <Menu {...menuProps}>
      <EditListingMenuItem
        listingId={listingId}
        surveyId={surveyId}
        buildingId={surveyListing.listing.building.id}
        isBdpBuildout={isBdpBuildout}
      />
      {RemoveSpaceFromSurveyMenuItemComponent}
    </Menu>
  );
}
