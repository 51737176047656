import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isBrokerInBuildout, isSurveyBuildout } from '~/legacy/utils';

export const useIsSurveyUserInBdpBuildout = (user, survey) => {
  const [inBuildout, setInBuildout] = useState(isSurveyBuildout(user, survey));
  useEffect(() => {
    setInBuildout(isSurveyBuildout(user, survey));
  }, [user, survey]);

  return inBuildout;
};

export const useSurveyInBdpBuildout = (survey) => {
  const user = useSelector((store) => store.user);
  return isSurveyBuildout(user, survey);
};

export const useBrokerInBdpBuildout = () => {
  const user = useSelector((store) => store.user);
  return isBrokerInBuildout(user);
};
