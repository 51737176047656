import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import clsx from 'clsx';
import {
  Container,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { LeftArrowIcon, RightArrowIcon } from '~/legacy/components';
import { CompressedSourceImage } from '../Images';

const imagePadding = 100;
const useStyles = makeStyles((theme) => ({
  arrow: {
    cursor: 'pointer',
    padding: 0,
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  },
  closeIcon: {
    cursor: 'pointer',
    marginLeft: 'auto',
    color: '#111111',
  },
  content: {
    backgroundColor: 'white',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100vw',
  },
  flexContainer: {
    height: '100%',
  },
  headerContainer: {
    boxShadow:
      'inset 0 0 0 #e0e0e0, inset 0 -1px 0 #e0e0e0, inset 0 0 0 #e0e0e0, inset 0 0 0 #e0e0e0',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '12px',
  },
  imageDiv: {
    display: 'flex',
    backgroundOrigin: 'content-box',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: () => (isMobile ? '100%' : 'calc(100vh - 100px - 50px)'),
    padding: () =>
      isMobile
        ? 0
        : `${imagePadding / 2}px ${imagePadding}px 0 ${imagePadding}px`,
  },
  image: {
    objectFit: 'contain',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  imageLegend: {
    height: `${imagePadding}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  leftArrow: {
    marginRight: 'auto',
  },
  rightArrow: {
    marginLeft: 'auto',
  },
  tabs: {
    height: '60px',
    marginLeft: 'auto',
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  mobileImageLegend: {
    height: `${imagePadding}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
  },
  swiper: {
    width: '100%',
    marginTop: 'auto',
    '&> div': {
      alignItems: 'center',
    },
  },
}));

const tabsData = [
  {
    endpoint: 'building_photos',
    imagesKey: 'images',
    label: 'Building Photos',
    parentObject: 'building',
  },
  {
    endpoint: 'listing_photos',
    imagesKey: 'images',
    label: 'Space Photos',
    parentObject: 'listing',
  },
  {
    endpoint: 'listing_floorplans',
    imagesKey: 'floorplan_photos',
    label: 'Floorplans',
    parentObject: 'listing',
  },
  {
    endpoint: 'building_neighborhood_photos',
    imagesKey: 'neighborhood_photos',
    label: 'Neighborhood',
    parentObject: 'building',
  },
];

export default function ImageGalleryModalOld({
  onClose,
  parentObjects,
  initialSelectedIndex,
  initialSelectedListKey,
}) {
  const classes = useStyles({ isMobile });

  const [selectedState, setSelectedState] = useState({
    indexesMap: tabsData
      .map((tabData) => {
        const key = `${tabData.parentObject}_${tabData.imagesKey}`;
        return {
          [key]: key === initialSelectedListKey ? initialSelectedIndex : 0,
        };
      })
      .reduce((indexesMapAccumulator, indexesMap) => ({
        ...indexesMapAccumulator,
        ...indexesMap,
      })),
    selectedListKey: initialSelectedListKey,
    selectedTab: tabsData.findIndex(
      (tabData) =>
        `${tabData.parentObject}_${tabData.imagesKey}` ===
        initialSelectedListKey
    ),
  });

  const imageLists = tabsData
    .map((tabData) => {
      const images =
        parentObjects[tabData.parentObject][tabData.imagesKey] || [];
      return {
        [`${tabData.parentObject}_${tabData.imagesKey}`]: images,
      };
    })
    .reduce((imagesMapAccumulator, imagesMap) => ({
      ...imagesMapAccumulator,
      ...imagesMap,
    }));

  const incrementIndex = () => {
    let newIndex = selectedState.indexesMap[selectedState.selectedListKey] + 1;
    if (newIndex >= imageLists[selectedState.selectedListKey].length) {
      newIndex = 0;
    }
    setSelectedState({
      ...selectedState,
      indexesMap: {
        ...selectedState.indexesMap,
        [selectedState.selectedListKey]: newIndex,
      },
    });
  };
  const decrementIndex = () => {
    let newIndex = selectedState.indexesMap[selectedState.selectedListKey] - 1;
    if (newIndex < 0) {
      newIndex = imageLists[selectedState.selectedListKey].length - 1;
    }
    setSelectedState({
      ...selectedState,
      indexesMap: {
        ...selectedState.indexesMap,
        [selectedState.selectedListKey]: newIndex,
      },
    });
  };

  // without this, the indicator in the tabs component gets stuck
  // in the wrong position
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);

  return (
    <div className={classes.content}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <Tabs
            className={classes.tabs}
            classes={{
              flexContainer: classes.flexContainer,
              indicator: classes.indicator,
            }}
            onChange={(_, newValue) =>
              setSelectedState({
                ...selectedState,
                selectedListKey: `${tabsData[newValue].parentObject}_${tabsData[newValue].imagesKey}`,
                selectedTab: newValue,
              })
            }
            scrollButtons="off"
            value={selectedState.selectedTab}
            variant="scrollable"
          >
            {tabsData.map((tabData) => (
              <Tab
                disabled={
                  !imageLists[`${tabData.parentObject}_${tabData.imagesKey}`]
                    .length
                }
                key={tabData.label}
                label={tabData.label}
              />
            ))}
          </Tabs>
          <CloseIcon className={classes.closeIcon} onClick={onClose} />
        </div>
      </div>
      <Container className={classes.body}>
        {isMobile ? (
          <div className={classes.mobileContainer}>
            <Swiper
              className={classes.swiper}
              spaceBetween={16}
              onSlideChange={(swiper) =>
                setSelectedState({
                  ...selectedState,
                  indexesMap: {
                    ...selectedState.indexesMap,
                    [selectedState.selectedListKey]: swiper.activeIndex,
                  },
                })
              }
            >
              {imageLists[selectedState.selectedListKey].map((imageFile) => (
                <SwiperSlide key={imageFile.id}>
                  <div className={classes.imageContainer}>
                    <div className={classes.imageDiv}>
                      <CompressedSourceImage
                        imageFile={imageFile}
                        ImgProps={{ className: classes.image }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Typography className={classes.mobileImageLegend} variant="body1">
              {`${
                selectedState.indexesMap[selectedState.selectedListKey] + 1
              } / ${imageLists[selectedState.selectedListKey].length}`}
            </Typography>
          </div>
        ) : (
          <>
            <IconButton
              className={clsx(classes.leftArrow, classes.arrow)}
              disabled={imageLists[selectedState.selectedListKey].length <= 1}
              onClick={decrementIndex}
            >
              <LeftArrowIcon />
            </IconButton>
            <div className={classes.imageContainer}>
              <div className={classes.imageDiv}>
                <CompressedSourceImage
                  imageFile={
                    imageLists[selectedState.selectedListKey][
                      selectedState.indexesMap[selectedState.selectedListKey]
                    ]
                  }
                  ImgProps={{ className: classes.image }}
                />
              </div>
              <Typography className={classes.imageLegend} variant="body1">
                {`${
                  selectedState.indexesMap[selectedState.selectedListKey] + 1
                } / ${imageLists[selectedState.selectedListKey].length}`}
              </Typography>
            </div>
            <IconButton
              className={clsx(classes.rightArrow, classes.arrow)}
              disabled={imageLists[selectedState.selectedListKey].length <= 1}
              onClick={incrementIndex}
            >
              <RightArrowIcon />
            </IconButton>
          </>
        )}
      </Container>
    </div>
  );
}
