// Restricts evaluation to only simple math. Should be safe?
export const equation = /^[\d\s\-+*/().]+$/

export function evaluate(math) {
  if (!equation.test(math)) return null
  try {
    return Function(`"use strict";return 1 * (${math})`)() // eslint-disable-line
  } catch {
    return null
  }
}
