import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { format } from 'date-fns'
import lodash from 'lodash'
import React, { useMemo, useState } from 'react'
import {
  SurveyIcon,
  TransparentScrollOverlay,
  Typography,
} from '~/legacy/components'
import { getViewSurveyRoute } from '~/legacy/utils'
import { openLink } from '~/legacy/utils/openLink'
import MenuItem from '../MenuItem'
import ContentModal from '../modals/ContentModal'
import { BASE_ICON_STYLES } from './ButtonUtils'

const useStyles = makeStyles((theme) => ({
  ...lodash.merge(BASE_ICON_STYLES(theme), {
    icon: {
      fill: 'none',
      color: '#111',
    },
    modalContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      height: '53px',
      alignItems: 'center',
      borderBottom: '1px solid #E0E0E0',
    },
    headerRow: {
      top: '-9px',
      marginTop: '-9px',
      position: 'sticky',
      background: '#FFF',
      zIndex: 2,
      height: '44px',
    },
    column: {
      display: 'flex',
      margin: '0 14px 0 14px',
    },
    leftColumn: {
      marginRight: 'auto',
    },
    rightColumn: {
      marginLeft: 'auto',
    },
    dialogContent: {
      height: '578px',
    },
    link: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
      color: '#111',
    },
  }),
}))

export const ViewUserOwnedSurveysMenuItemModal = ({
  surveys,
  open,
  onClose,
}) => {
  const classes = useStyles()
  const orderedSurveys = useMemo(
    () => lodash.orderBy(surveys, 'updated_at', 'desc'),
    [surveys]
  )
  return (
    <ContentModal
      ModalComponentProps={{
        open: !!open,
        onClose,
        classes: { dialogContent: classes.dialogContent },
      }}
      classes={{ content: classes.modalContent }}
      onClose={onClose}
      title="View Surveys"
    >
      <TransparentScrollOverlay
        paddingPx="0"
        hideScroll
        gradientHeightPx={120}
        bottomPaddingPx={80}
      >
        <div className={clsx(classes.headerRow, classes.tableRow)}>
          <div className={clsx(classes.column, classes.leftColumn)}>
            <Typography variant="tableHeader">SURVEY NAME</Typography>
          </div>
          <div className={clsx(classes.column, classes.rightColumn)}>
            <Typography variant="tableHeader">DATE UPDATED</Typography>
          </div>
        </div>

        {orderedSurveys.map((survey) => {
          return (
            <div key={survey.id} className={classes.tableRow}>
              <div className={clsx(classes.column, classes.leftColumn)}>
                <Link
                  href="#"
                  onClick={openLink(getViewSurveyRoute(survey.id))}
                  underline="none"
                  className={classes.link}
                >
                  <Typography variant="body1">{survey.name}</Typography>
                </Link>
              </div>
              <div className={clsx(classes.column, classes.rightColumn)}>
                <Typography variant="body1">
                  {format(Date.parse(survey.updated_at), 'MM/dd/yyyy')}
                </Typography>
              </div>
            </div>
          )
        })}
      </TransparentScrollOverlay>
    </ContentModal>
  )
}

export const ViewSurveysMenuItem = React.forwardRef(
  ({ disabled, openModal, ...props }, ref) => {
    const classes = useStyles()
    const tooltip = disabled ? "this user doesn't have any surveys" : null

    return (
      <MenuItem
        ref={ref}
        onClick={openModal}
        disabled={disabled}
        tooltip={tooltip}
        {...props}
      >
        <SurveyIcon className={classes.icon} />
        <Typography className={classes.text}> View Surveys </Typography>
      </MenuItem>
    )
  }
)

// Bundle the modal and the menu item together for maximum convenience
export const useViewSurveyMenuItem = ({
  surveys,
  disabled,
  handleMenuClose,
}) => {
  const [open, setOpen] = useState(false)

  const ViewSurveysMenuItemComponent = (
    <ViewSurveysMenuItem disabled={disabled} openModal={() => setOpen(true)} />
  )

  const ViewUserOwnedSurveysMenuItemModalComponent = (
    <ViewUserOwnedSurveysMenuItemModal
      key="view-user-owned-surveys-modal"
      surveys={surveys}
      open={open}
      onClose={() => {
        setOpen(false)
        handleMenuClose()
      }}
    />
  )

  return {
    ViewSurveysMenuItemComponent,
    ViewUserOwnedSurveysMenuItemModalComponent,
  }
}
