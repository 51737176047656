import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const TOP_OFFSET_PX = 1;
const DEFAULT_NAV_BAR_VERTICAL_HEIGHT_PX = 68;
const NAV_BAR_BOTTOM_BORDER_HEIGHT_PX = 1;
const NAV_BAR_BOTTOM_BORDER_Z_INDEX = 1;
const NAV_BAR_BOTTOM_BORDER_HIDER_Z_INDEX = NAV_BAR_BOTTOM_BORDER_Z_INDEX + 1;
const NAV_BAR_Z_INDEX = NAV_BAR_BOTTOM_BORDER_HIDER_Z_INDEX + 1;

const usePageStickyNavBarClasses = makeStyles(() => ({
  bottomBorder: {
    top: props => `${props.navBarHeight}px`,
  },
  root: {
    height: props => `${props.navBarHeight + 1}px`,
    minHeight: props => `${props.navBarHeight + 1}px`,
  },
  content: {
    marginTop: `${TOP_OFFSET_PX}px`,
    height: props => `${props.navBarHeight}px`,
    minHeight: props => `${props.navBarHeight}px`,
  },
}));

/*
NOTE: place "overflowY: 'scroll'" in parent container
*/
const PageStickyNavBar = withStyles({
  root: {
    position: 'sticky',
    top: -TOP_OFFSET_PX,
    display: 'flex',
    flexDirection: 'row',
    zIndex: NAV_BAR_Z_INDEX,
    background: 'white',
    width: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  bottomBorderBase: {
    width: '100%',
    height: `${NAV_BAR_BOTTOM_BORDER_HEIGHT_PX}px`,
    minHeight: `${NAV_BAR_BOTTOM_BORDER_HEIGHT_PX}px`,
  },
  bottomBorder: {
    position: 'sticky',
    backgroundColor: '#E0E0E0',
    zIndex: NAV_BAR_BOTTOM_BORDER_Z_INDEX,
  },
  bottomBorderHider: {
    position: 'sticky',
    backgroundColor: 'white',
    marginTop: `${-NAV_BAR_BOTTOM_BORDER_HEIGHT_PX}px`,
    zIndex: NAV_BAR_BOTTOM_BORDER_HIDER_Z_INDEX,
  },
  twoAxis: {
    left: 0,
  },
})(
  ({
    navBarHeight = DEFAULT_NAV_BAR_VERTICAL_HEIGHT_PX,
    children,
    classes,
    twoAxis = false,
  }) => {
    const classesLocal = usePageStickyNavBarClasses({ navBarHeight });
    return [
      <div
        key="sticky-nav-content"
        className={clsx(classes.root, twoAxis && classes.twoAxis, classesLocal.root)}
      >
        <div className={clsx(classes.content, classesLocal.content)}>
          {children}
        </div>
      </div>,
      // A bottom border for the nav bar
      <div
        key="sticky-nav-bottom-border"
        className={clsx(
          classes.bottomBorderBase,
          classes.bottomBorder,
          twoAxis && classes.twoAxis,
          classesLocal.bottomBorder,
        )}
      />,
      // Hide the bottom border by putting a white border over it that scrolls past, leaving the underlying border above
      <div
        key="sticky-nav-bottom-border-hide"
        className={clsx(classes.bottomBorderBase, classes.bottomBorderHider, twoAxis && classes.twoAxis)}
      />,
    ];
  }
);

export default PageStickyNavBar;
