import React from 'react'
import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import clsx from 'clsx'
import { Group } from '~/pdfs/patterns/Group'
import { PickList } from '~/pdfs/patterns/PickList'
import { Settings } from '~/pdfs/patterns/Settings'
import { Text } from '~/pdfs/patterns/Text'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { useRangeField } from '~/support/useRangeField'
import { useSend } from '~/support/useSend'
import {
  templates,
  findColumns,
  // findTemplate,
  mapSurveyBuildingColumn,
} from '~/pdfs/templates/jll/support/templates'
import { useFieldWithDelay } from '~/support/useFieldDelay'
import { Database } from '~/pdfs/templates/jll/patterns/Database'

const baseurl = {
  development: 'http://localhost:6333',
  staging: 'https://staging.leaseup.co',
  production: 'https://app.leaseup.co',
}[process.env.NODE_ENV || 'production']

const useStyles = makeStyles((theme) => {
  const pdf = (a, b) => (theme.isPdf ? a : b)
  const scale = (value) => pdf((value * 17) / 11, value)
  const px = (value) => `${scale(value)}px`

  return {
    container: {
      boxSizing: 'border-box',
      padding: px(25),
      height: pdf('100vh', '100%'),
      width: pdf('100vw', '100%'),
    },
    head: {
      // backgroundImage: `url(https://app.leaseup.co/static/media/jllPdfHeader.png)`,
      backgroundColor: '#000000',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: px(75),
      position: 'relative',
    },
    logo: {
      height: px(74),
      position: 'absolute',
      top: '1px',
      left: '1px',
    },
    title: {
      fontFamily: 'Inter-SemiBold',
      fontSize: px(14),
      lineHeight: px(18),
      color: '#ffffff',
      paddingTop: px(12),
      textAlign: 'center',
    },
    details: {
      fontFamily: 'Inter-Regular',
      fontSize: px(6),
      lineHeight: px(8),
      color: '#ffffff',
      paddingTop: px(6),
      textAlign: 'center',
    },
    legal: {
      fontFamily: 'Inter-Regular',
      fontSize: px(4),
      lineHeight: px(6),
      color: '#ffffff',
      position: 'absolute',
      top: px(10),
      right: px(12),
      width: px(193),
      letterSpacing: px(0.1),
    },
    table: {
      border: '1px solid #111111',
      borderRight: 'none',
    },
    header: {
      padding: px(4),
      display: 'flex',
      fontFamily: 'Inter-Medium',
      fontSize: px(6),
      lineHeight: px(8),
      background: '#999999',
      borderRight: '1px solid #111111',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    cell: {
      padding: px(4),
      display: 'flex',
      borderTop: '1px solid #111111',
      fontFamily: 'Inter-Regular',
      fontSize: px(6),
      lineHeight: px(10),
      borderRight: '1px solid #111111',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      whiteSpace: 'pre',
    },
    left: {
      justifyContent: 'left',
      textAlign: 'left',
      whiteSpace: 'unset',
    },
    attr: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    name: {
      fontWeight: 'bold',
    },
    image: {
      objectFit: 'cover',
      width: px(72),
      border: 'none',
      height: '100%',
      flexShrink: 0,
    },
    foo: {
      flexShrink: 0,
    },
  }
})

export const JLLCompare = definePageTemplate({
  id: 'c0664088-42a9-4279-9ab3-08cfbb3dcb20',

  name: 'Compare',

  props: ({ data, settings }, buildings) => ({
    limit: settings.buildingsPerPage,
    templateId: settings.templateId,
    broker: settings.broker,
    client: settings.client,
    owner: settings.broker || data.users[0].name,
    buildings,
  }),

  Page: (props) => {
    const styles = useStyles()
    const template = templates[props.templateId]

    return (
      <div className={styles.container}>
        <div className={styles.head}>
          <img
            className={styles.logo}
            src={`${baseurl}/static/media/jllPdfHeader.png`}
            alt=""
          />
          <div className={styles.title}>{template.title}</div>
          <div className={styles.details}>
            {props.client && <div>{`For: ${props.client}`}</div>}
            <div>Criteria: Toronto Office</div>
            <div>{`By: ${props.owner}`}</div>
            <div>{`Date: ${format(new Date(), 'MMMM dd, yyyy')}`}</div>
          </div>
          <div className={styles.legal}>
            The information contained in this document/report (including, but
            not limited to, images, charts, assumptions, estimates and
            projections) has been compiled from public or 3rd party sources
            believed to be reliable. In particular, the information contained
            herein is not represented, warranted or guaranteed by JLL. Given the
            limitations of the investigations, enquiries and verification that
            we have undertaken, the reliability and accurateness of this
            information must be treated with a suitable degree of caution and
            neither JLL nor any of their affiliates, employees or independent
            contractors accept any liability or responsibility for the accuracy
            or completeness of the information contained herein and no reliance
            should be placed on the information contained in this
            document/report.
          </div>
        </div>
        <Database
          className={styles.table}
          columns={findColumns(template.columns)}
          header={(key, column) => (
            <div key={key} className={styles.header}>
              {column.key}
            </div>
          )}
          cell={(key, column, row) => (
            <div
              key={key}
              className={clsx(
                styles.cell,
                column.key === 'Comments' && styles.left,
                column.key === 'Image' && styles.foo
              )}
            >
              {column.key === 'Image' ? (
                <Image
                  className={styles.image}
                  src={row.images[0]?.thumbnail}
                />
              ) : (
                mapSurveyBuildingColumn(column, row)
              )}
            </div>
          )}
          data={props.buildings}
        />
      </div>
    )
  },

  Settings: (props) => {
    const send = useSend()

    const limit = useRangeField(props.limit, 1, 10, (value) => {
      send('AddSettings', { buildingsPerPage: value })
    })

    return (
      <Settings>
        <PickList
          label="Template"
          items={templates.map((template) => ({
            value: template.id,
            label: template.label,
          }))}
          value={props.templateId}
          onChange={(templateId) => {
            send('AddSettings', { templateId })
          }}
        />

        <Group label="Buildings Per Page">
          <Text {...limit} />
        </Group>

        <Group label="Client">
          <Text
            {...useFieldWithDelay(props.client, (client) => {
              send('AddSettings', { client })
            })}
            placeholder="Add Client"
            nullable
          />
        </Group>

        <Group label="Broker">
          <Text
            {...useFieldWithDelay(props.broker, (broker) => {
              send('AddSettings', { broker })
            })}
            placeholder="Add Broker"
            nullable
          />
        </Group>
      </Settings>
    )
  },
})

const Image = (props) => {
  const { src, className } = props
  return src ? <img className={className} src={src} alt="" /> : null
}
