import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExportSurveySettingsAndLayout,
  SquareCheckbox,
} from '~/legacy/components';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '60px',
    borderBottom: '1px solid #e0e0e0',
    '&:last-child': {
      border: 'none',
    },
    cursor: 'pointer',
  },
  checkbox: {
    marginLeft: 0,
    marginRight: '12px',
    color: '#111111',
  },
});

function ListingDetailPageSettings(props) {
  const { settings, updateSettings } = props;
  const { showListingDescription } = settings;
  const classes = useStyles();

  const toggleSetting = (newSetting) =>
    updateSettings({
      ...settings,
      ...newSetting,
    });
  const checkboxes = [
    {
      label: 'Listing Description',
      value: showListingDescription,
      toggle: () =>
        toggleSetting({ showListingDescription: !showListingDescription }),
    },
  ];

  return (
    <ExportSurveySettingsAndLayout {...props}>
      <div className={classes.container}>
        {checkboxes.map((checkbox) => (
          <div
            key={checkbox.label}
            className={classes.checkboxContainer}
            onClick={checkbox.toggle}
          >
            <SquareCheckbox
              className={classes.checkbox}
              checked={checkbox.value}
              onClick={checkbox.toggle}
            />
            <Typography variant="h3">{checkbox.label}</Typography>
          </div>
        ))}
      </div>
    </ExportSurveySettingsAndLayout>
  );
}

export default ListingDetailPageSettings;
