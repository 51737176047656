import { createId, createImageFile } from '~/legacy/utils'

export const recentDocuments = [
  {
    id: createId(),
    name: 'Apr 12, 2022',
    kind: 'Tour',
    company: 'Unified Logistics',
    logo: createImageFile('/prototype/Logo3.png'),
  },
  {
    id: createId(),
    name: 'Downtown ATX',
    kind: 'Survey',
    company: 'Insight Technologies',
    logo: createImageFile('/prototype/Logo2.png'),
  },
  {
    id: createId(),
    name: 'Welcome Package',
    kind: 'Document',
    company: 'AV Engineering',
    logo: createImageFile('/prototype/Logo1.png'),
  },
  {
    id: createId(),
    name: 'IT Lease',
    kind: 'Document',
    company: 'Insight Technologies',
    logo: createImageFile('/prototype/Logo2.png'),
  },
]
