import * as Markets from '../CresaMarkets'

const markets = [
  Markets.Urban,
  Markets.Suburban,
  Markets.Industrial,
  Markets.Science,
]

export const findMarket = (value) => {
  return markets.find((market) => market.value === value)
}
