import { Collapse, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import {
  getSurveyListingTableScrollToId,
  STICKY_BAR_HEIGHT_PX,
} from '~/legacy/components'
import { openLink } from '../../utils/openLink'
import {
  getViewBuildingRoute,
  getViewListingRoute,
} from '../../utils/routeUtils'
import SurveyListingRowData from './SurveyListingRowData'
import {
  getTotalNumberColumns,
  SURVEY_LISTING_TABLE_STYLES,
} from './SurveyListingTableComponents'

/**
 * This class represents one non-nested row in the survey listing table. This means
 * either:
 * 1. An individual survey listing that shares a building with no other survey listing
 *   in this survey
 * 2. A Building row that represents a group of survey listings for the same building
 *
 * You can click and expand the building row to see the individual listing rows nested beneath.
 * This class renders both cases and uses `isBuilding` to handle.
 *
 * This component is technically a row in a table. We make a row-spanning td, and handle
 *   the notion of rows on our own as styled divs. This is primarily so that the non-nested
 *   and nested rows can be dragged together as a unit, as well as the nested rows collapsed
 */
function NonNestedSurveyListingRow(props) {
  const {
    isUserInBuildout,
    history,
    surveyId,
    draggableProvided,
    isDragging,
    selectedSurveyListings,
    setSelectedSurveyListings,
    classes,
    building = {},
    surveyBuilding = {},
    surveyListings = [],
    tableIndex,
    handleDragEnd,
    setSurveyListingToDecline,
    uploadBuildingHeroPhotoModalData,
    setUploadBuildingHeroPhotoModalData,
    collapsedBuildings = {},
    setCollapsedBuildings = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    isCondensed = false,
    isBroker = false,
    isActive = false,
  } = props
  const isBuilding = true
  const tableRowClicksEnabled = useSelector(
    (store) => store.pages.viewSurvey.tableRowClicksEnabled
  )
  let rowColor = ''
  if (isDragging) rowColor = 'rgba(245,245,245, 0.75)'
  if (isActive) rowColor = '#f9f9f9'
  return (
    // The whole row is one table cell, with our own divs representing visual rows
    <TableRow
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      style={{
        ...draggableProvided.draggableProps.style,
        background: rowColor,
      }}
      className={clsx(
        draggableProvided.draggableProps.className,
        classes.rowBorder
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={(event) => {
        if (isActive) {
          event.stopPropagation()
          event.preventDefault()
        }
      }}
    >
      <TableCell
        colSpan={getTotalNumberColumns(isCondensed, isBroker)}
        className={`${classes.pointer} ${classes.rowTableCell}`}
      >
        <div
          id={getSurveyListingTableScrollToId(building.id)}
          style={{
            position: 'relative',
            top: `-${STICKY_BAR_HEIGHT_PX + 8}px`,
            left: 0,
          }}
        />
        {/* Render the non-nested row */}
        <SurveyListingRowData
          containerProps={{
            className: clsx(
              classes.divRow,
              classes.divRowPadding,
              classes.hover
            ),
          }}
          rowClick={(event) => {
            if (tableRowClicksEnabled) {
              if (isUserInBuildout) {
                openLink(getViewBuildingRoute(surveyId, building.id))(event)
              } else {
                setCollapsedBuildings(
                  building.id,
                  !collapsedBuildings[building.id]
                )
              }
            }
          }}
          isBuilding={isBuilding}
          history={history}
          collapsedBuildings={collapsedBuildings}
          setCollapsedBuildings={setCollapsedBuildings}
          surveyId={surveyId}
          draggableProvided={draggableProvided}
          isDragging={isDragging}
          selectedSurveyListings={selectedSurveyListings}
          setSelectedSurveyListings={setSelectedSurveyListings}
          classes={classes}
          building={building}
          surveyBuilding={surveyBuilding}
          surveyListings={surveyListings}
          isCondensed={isCondensed}
          isBroker={isBroker}
          setSurveyListingToDecline={setSurveyListingToDecline}
          uploadBuildingHeroPhotoModalData={uploadBuildingHeroPhotoModalData}
          setUploadBuildingHeroPhotoModalData={
            setUploadBuildingHeroPhotoModalData
          }
          isBdpBuildout={isUserInBuildout}
        />

        <DragDropContext
          onDragEnd={(result) => {
            handleDragEnd(result, tableIndex)
          }}
        >
          {/* A new droppable zone for the survey listings in this building */}
          <Droppable droppableId="droppable-2" direction="vertical">
            {(droppableProvidedSub) => {
              return (
                // This div represents a row for a nested survey listing
                <div
                  className={`${droppableProvidedSub.droppableProps.classes} ${classes.divColumn}`}
                  ref={droppableProvidedSub.innerRef}
                  {...droppableProvidedSub.droppableProps}
                >
                  <Collapse in={!collapsedBuildings[building.id]} timeout={300}>
                    {surveyListings.map((surveyListing, index) => (
                      <Draggable
                        key={surveyListing.id}
                        draggableId={surveyListing.id.toString()}
                        index={index}
                      >
                        {(draggableProvidedNested, snapshotNested) => {
                          const isEitherDragging =
                            isDragging || snapshotNested.isDragging
                          return (
                            // This holds all of the table cell data in the nested survey listing row. We preserve styling when drag/dropped
                            <SurveyListingRowData
                              containerProps={{
                                ref: draggableProvidedNested.innerRef,
                                ...draggableProvidedNested.draggableProps,
                                className: clsx(
                                  classes.divRow,
                                  classes.hover,
                                  classes.nestedDivRowPadding,
                                  draggableProvidedNested.draggableProps
                                    .className
                                ),
                                style: {
                                  ...draggableProvidedNested.draggableProps
                                    .style,
                                  background: isEitherDragging
                                    ? 'rgba(245,245,245, 0.75)'
                                    : '',
                                },
                              }}
                              rowClick={(event) => {
                                if (tableRowClicksEnabled) {
                                  if (isUserInBuildout) {
                                    openLink(
                                      getViewBuildingRoute(
                                        surveyId,
                                        building.id
                                      )
                                    )(event)
                                  } else {
                                    history.push(
                                      getViewListingRoute(
                                        surveyListing.listing.id,
                                        surveyId
                                      )
                                    )
                                  }
                                }
                              }}
                              isNested
                              isFirstNested={index === 0}
                              isLastNested={index === surveyListings.length - 1}
                              surveyId={surveyId}
                              collapsedBuildings={collapsedBuildings}
                              setCollapsedBuildings={setCollapsedBuildings}
                              key={surveyListing.id}
                              isDragging={isEitherDragging}
                              draggableProvided={draggableProvidedNested}
                              selectedSurveyListings={selectedSurveyListings}
                              setSelectedSurveyListings={
                                setSelectedSurveyListings
                              }
                              surveyListings={[surveyListing]}
                              building={building}
                              isCondensed={isCondensed}
                              isBroker={isBroker}
                              setSurveyListingToDecline={
                                setSurveyListingToDecline
                              }
                              isBdpBuildout={isUserInBuildout}
                            />
                          )
                        }}
                      </Draggable>
                    ))}
                  </Collapse>
                  {droppableProvidedSub.placeholder}
                </div>
              )
            }}
          </Droppable>
        </DragDropContext>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(SURVEY_LISTING_TABLE_STYLES, { withTheme: true })(
  NonNestedSurveyListingRow
)
