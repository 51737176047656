import React from 'react';
import PropTypes from 'prop-types';
import { ModalTitleGeneric } from '~/legacy/components/modals';

function ModalTitle({ onClose, title, classNames }) {
  return (
    <ModalTitleGeneric
      classNames={classNames}
      onClose={onClose}
      title={title}
    />
  );
}
ModalTitle.propTypes = {
  classNames: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
ModalTitle.defaultProps = {
  classNames: {},
};
export default ModalTitle;
