import React from 'react'
import { prop, styled, when } from '~/libraries/styled'

export const Photo = ({ alt, fit, src, ...props }) => {
  return (
    <Container {...props}>
      <Image alt={alt} fit={fit} src={src} />
    </Container>
  )
}

export const Container = styled('div', ['grow', 'height', 'width'], {
  flexGrow: when('grow', 1),
  flexShrink: 0,
  height: prop('height'),
  position: 'relative',
  width: prop('width'),
})

export const Image = styled('img', ['fit'], {
  position: 'absolute',
  inset: '0px',
  boxSizing: 'border-box',
  padding: '0px',
  border: 'none',
  margin: 'auto',
  display: 'block',
  width: '0px',
  height: '0px',
  minWidth: '100%',
  maxWidth: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  lineHeight: '0px',
  objectFit: prop('fit'),
})
