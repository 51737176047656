import useSWR from 'swr'
import { recentDocuments } from '~/legacy/fixtures'
import { getSearchParam, wait } from '~/legacy/utils'

const count = getSearchParam('docs', 4, Number)

export const useRecents = (id) => {
  return useSWR(`/user/${id}/recents`, {
    suspense: true,
    fetcher: async () => {
      await wait(100, 500)
      return recentDocuments.slice(0, count)
    },
  })
}
