import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import { Button, ThumbnailImage } from '~/legacy/components'
import { useLogoUrl } from '~/legacy/utils'
import { MISSING_IMAGE_MODEL } from '~/legacy/consts'

const useStyles = makeStyles({
  footer: {
    clear: 'both',
    marginTop: '10px',
    textAlign: 'center',
    width: '100%',
    color: '#999999',
    paddingTop: '10px',
  },
  appleLink: {
    color: '#999999',
  },
  delimiter: {
    padding: '0 12px',
  },
  emailText: {
    color: '#3B4144',
    fontFamily: "'helvetica neue', helvetica, arial, sans-serif",
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'normal',
    margin: '0',
    marginBottom: '15px',
    backgroundColor: '#fff',
  },
  bodyText: {
    whiteSpace: 'pre-wrap',
  },
  centeredContainer: {
    textAlign: 'center',
    width: '100%',
  },
  emailH3: {
    color: '#3B4144',
    fontFamily: "'helvetica neue', helvetica, arial, sans-serif",
    fontWeight: '400',
    lineHeight: '1.4',
    margin: '0',
    marginBottom: '20px',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  imagesContainer: {
    width: 'fit-content',
    margin: 'auto',
    marginBottom: '64px',
  },
  imageContainer: {
    display: 'flex',
  },
  image: {
    width: '114px',
    height: '75px',
    objectFit: 'cover',
    marginTop: '6px',
    marginRight: '6px',
    cursor: 'pointer',
  },
})

export default function SurveyEmailPreview({
  brokerage,
  brokerEmail,
  brokerFirstName,
  brokerLastName,
  surveyName,
  listings,
  emailBody,
}) {
  const classes = useStyles()
  const logoUrl = useLogoUrl()
  const brokerName =
    brokerFirstName && brokerLastName
      ? `${brokerFirstName} ${brokerLastName}`
      : brokerEmail
  return (
    <div className={classes.centeredContainer}>
      <div className={`${classes.centeredContainer} ${classes.logoContainer}`}>
        <img width="178px" src={logoUrl} alt="Logo" />
      </div>

      <div className={classes.centeredContainer}>
        <h3 className={classes.emailH3}>
          <b>{`${brokerName}, ${brokerage}`}</b>
          <div>has shared</div>
          <Link underline="none" href="">
            {surveyName}
          </Link>
        </h3>
      </div>

      <div className={classes.centeredContainer}>
        <pre className={`${classes.emailText} ${classes.bodyText}`}>
          {emailBody}
        </pre>
      </div>

      <div className={classes.imagesContainer}>
        <div className={classes.imageContainer}>
          {listings.slice(0, 3).map((listing) => {
            if (listing.images.length) {
              return (
                <ThumbnailImage
                  imageFile={listing.images[0]}
                  ImgProps={{
                    className: classes.image,
                    alt: listing.id,
                  }}
                />
              )
            }
            return (
              <ThumbnailImage
                imageFile={MISSING_IMAGE_MODEL}
                ImgProps={{
                  className: classes.image,
                  alt: listing.id,
                }}
              />
            )
          })}
        </div>
        <div className={classes.imageContainer}>
          {listings.slice(3, 6).map((listing) => {
            if (listing.images.length) {
              return (
                <ThumbnailImage
                  imageFile={listing.images[0]}
                  ImgProps={{
                    className: classes.image,
                    alt: listing.id,
                  }}
                />
              )
            }
            return (
              <ThumbnailImage
                imageFile={MISSING_IMAGE_MODEL}
                ImgProps={{
                  className: classes.image,
                  alt: listing.id,
                }}
              />
            )
          })}
        </div>
      </div>

      <div className={classes.centeredContainer}>
        <Button color="primary">View Survey</Button>
      </div>

      <div className={`${classes.centeredContainer} ${classes.footer}`}>
        <div className={classes.centeredContainer}>
          <span className={classes.appleLink}>
            {`Copyright © ${new Date().getFullYear()} `}
            <a className={classes.appleLink}>LeaseUp Inc</a>
            {' All Rights Reserved.'}
          </span>
        </div>
        <div className={classes.centeredContainer}>
          <a className={classes.appleLink}>help@leaseup.co</a>
          <span className={classes.delimiter}>|</span>
          <a className={classes.appleLink}>hello@leaseup.co</a>
        </div>
      </div>
    </div>
  )
}
