import { formatField } from '~/support/formatField'

export const convertFieldsToList = (fields) => {
  return fields
    .map((field) => ({
      id: field.id,
      name: field.name,
      value: formatField(field),
    }))
    .filter((field) => {
      return Boolean(field.value)
    })
}
