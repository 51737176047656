import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ClearTextInputSvg(props) {
  return (
    <SvgIcon
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.50016 1.08325C3.50475 1.08325 1.0835 3.5045 1.0835 6.49992C1.0835 9.49534 3.50475 11.9166 6.50016 11.9166C9.49558 11.9166 11.9168 9.49534 11.9168 6.49992C11.9168 3.5045 9.49558 1.08325 6.50016 1.08325ZM9.2085 8.4445L8.44475 9.20825L6.50016 7.26367L4.55558 9.20825L3.79183 8.4445L5.73641 6.49992L3.79183 4.55534L4.55558 3.79159L6.50016 5.73617L8.44475 3.79159L9.2085 4.55534L7.26391 6.49992L9.2085 8.4445Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
