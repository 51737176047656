
export const px = (...args) => {
  let output = '';
  for (let i = 0; i < args.length; i++) {
    const pxString = args[i];
    if (pxString !== null && pxString !== undefined) {
      if (output.length) {
        output += ' ';
      }
      output += `${pxString}px`;
    }
  }
  return output;
};
