import React, { useEffect } from 'react';
import Api from 'rest-fetcher-redux';
import { Fade, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CollapseWrapper,
  SurveyListingStatusUpdateActivityItemCard,
  SurveyListingCommentActivityCard,
  SurveyCommentActivityItem,
  SurveyComparisonCommentActivityItem,
  ShareSurveyActivityItem,
  SurveyActivityCommentInput,
  ActivityContext,
  TransparentScrollOverlay,
  SurveyBuildingCommentActivityCard,
} from '~/legacy/components';
import { useSurveyActivityWebSocket } from '~/legacy/utils/webSocketUtils';
import {
  BROKER_SHARE_SURVEY,
  SURVEY_STATUS_UPDATE,
  SURVEY_LISTING_COMMENT,
  SURVEY_BUILDING_COMMENT,
  SURVEY_COMMENT,
  SURVEY_COMPARISON_COMMENT,
} from '~/legacy/consts';

const ACTIVITY_CONTAINER_PADDING_PX = 24;
const GRADIENT_HEIGHT_PX = 100;

const useStyles = makeStyles({
  activityContainer: {
    maxHeight: 'inherit',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    paddingBottom: `${ACTIVITY_CONTAINER_PADDING_PX - 5}px`,
  },
  activitiesContainer: {
    paddingTop: '20px',
    paddingLeft: `${ACTIVITY_CONTAINER_PADDING_PX}px`,
    paddingRight: `${ACTIVITY_CONTAINER_PADDING_PX}px`,
    height: 'fit-content',
  },
  commentInput: {
    display: 'flex',
    paddingLeft: `${ACTIVITY_CONTAINER_PADDING_PX}px`,
    paddingRight: `${ACTIVITY_CONTAINER_PADDING_PX}px`,
    width: '100%',
    marginTop: 'auto',
  },
});

function SurveyActivityFeed({ surveyId }) {
  const classes = useStyles();
  const context = React.useContext(ActivityContext);
  const {
    currentDate,
    activities,
    setActivities,
    setNewActivityMessage,
    isLoadingFeed,
    setIsLoadingFeed,
    show,
  } = context;

  // The websocket for our activity feed
  const { lastJsonMessage } = useSurveyActivityWebSocket({
    surveyId,
  });

  useEffect(() => {
    if (surveyId) {
      // Get the activity feed items from the server
      Api.getSurveyActivity({ id: surveyId })
        .then((results) => {
          if (results) {
            setActivities(
              results.map((result) => ({ ...result, isNew: false }))
            );
          }
        })
        .finally(() => setIsLoadingFeed(false));
    }
  }, [surveyId]);

  useEffect(() => {
    // Update the activities with the new activity from the websocket
    setNewActivityMessage(lastJsonMessage);
  }, [lastJsonMessage]);

  if (isLoadingFeed) {
    return <LinearProgress size={40} />;
  }

  return (
    <Fade in={show}>
      <div className={classes.activityContainer}>
        <TransparentScrollOverlay gradientHeightPx={GRADIENT_HEIGHT_PX}>
          <div className={classes.activitiesContainer}>
            {activities.map((activity) => (
              <CollapseWrapper
                includeCollapse={activity.isNew}
                key={`wrapper-${activity.uuid}`}
              >
                <div style={{ paddingBottom: '32px' }}>
                  {activity.type === SURVEY_STATUS_UPDATE && (
                    <SurveyListingStatusUpdateActivityItemCard
                      activity={activity}
                      currentDate={currentDate}
                      surveyId={surveyId}
                    />
                  )}
                  {activity.type === SURVEY_LISTING_COMMENT && (
                    <SurveyListingCommentActivityCard
                      activity={activity}
                      currentDate={currentDate}
                    />
                  )}
                  {activity.type === SURVEY_BUILDING_COMMENT && (
                    <SurveyBuildingCommentActivityCard
                      activity={activity}
                      currentDate={currentDate}
                    />
                  )}
                  {activity.type === SURVEY_COMMENT && (
                    <SurveyCommentActivityItem
                      activity={activity}
                      currentDate={currentDate}
                      setNewActivityMessage={setNewActivityMessage}
                    />
                  )}
                  {activity.type === BROKER_SHARE_SURVEY && (
                    <ShareSurveyActivityItem
                      activity={activity}
                      currentDate={currentDate}
                      forSdp
                    />
                  )}
                  {activity.type === SURVEY_COMPARISON_COMMENT && (
                    <SurveyComparisonCommentActivityItem
                      activity={activity}
                      currentDate={currentDate}
                      setNewActivityMessage={setNewActivityMessage}
                    />
                  )}
                </div>
              </CollapseWrapper>
            ))}
          </div>
        </TransparentScrollOverlay>
        <div className={classes.commentInput}>
          <SurveyActivityCommentInput
            surveyId={surveyId}
            setNewActivityMessage={setNewActivityMessage}
          />
        </div>
      </div>
    </Fade>
  );
}

export default SurveyActivityFeed;
