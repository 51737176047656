import { fetchApi } from './rootApi'

const API_ROOT = 'survey_building_custom_fields'

// TODO: add tracking for each of these

export const surveyBuildingCustomFieldApi = {
  list: async ({ surveyId, buildingId }) => {
    const queryParams = new URLSearchParams({ surveyId })
    if (buildingId) {
      queryParams.append('buildingId', buildingId)
    }

    return fetchApi({
      endpoint: `${API_ROOT}/?${queryParams}`,
      fetchArgs: {
        method: 'GET',
      },
    })
  },

  createFieldValue: async ({
    surveyId,
    buildingId,
    customFieldId,
    value = '',
  }) =>
    fetchApi({
      endpoint: `${API_ROOT}/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          surveyId,
          buildingId,
          customFieldId,
          value,
        }),
      },
    }),

  updateFieldValue: async ({ surveyBuildingCustomFieldId, value }) =>
    fetchApi({
      endpoint: `${API_ROOT}/${surveyBuildingCustomFieldId}/change_field_value/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ new_value: value }),
      },
    }),
}
