import React, { Fragment } from 'react'
import { styled } from '~/libraries/styled'
import { BuildingDescription, Price, Size } from '~/objects/fields'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { conjunction } from '~/support/conjunction'
import { findFieldAndFormat } from '~/support/findFieldAndFormat'
import { isLike } from '~/support/isLike'
import { buildLocationName } from '../../leaseup/support/buildLocationName'
import { buildSpaceName } from '../../leaseup/support/buildSpaceName'
import { convertFieldsToList } from '../../leaseup/support/convertFieldsToList'
import { Area } from '../CalgaryFields'
import { Font } from '../patterns/Font'
import CalgaryLogo from '../patterns/CalgaryLogo'
import { Photo } from '~/pdfs/patterns/Photo'
import { image } from '~/pdfs/support/image'
import { useTable } from '~/support/useTable'

export const CalgaryBuilding = definePageTemplate({
  id: 'd484de38-304c-4683-b95b-01746a6ec3b9',

  name: 'Building Details',

  props: (pdf, building) => ({
    order: building.order + 1,
    photo: image(building.images[0], { width: 941 * 2 }),
    name: conjunction([
      buildLocationName(building.location),
      findFieldAndFormat(building.fields, Area),
    ]),
    description: findFieldAndFormat(building.fields, BuildingDescription),
    fields: convertFieldsToList(
      building.fields
        .filter((field) => {
          // TODO: isLike field
          return !isLike(field, BuildingDescription) && field.name !== 'Area'
        })
        .slice(0, 16 - building.spaces.length)
    ),
    spaces: building.spaces.map((space) => ({
      id: space.id,
      name: buildSpaceName(space),
      size: findFieldAndFormat(space.fields, Size),
      price: findFieldAndFormat(space.fields, Price),
    })),
  }),

  Page: (props) => {
    const table = useTable(Table, props.spaces)
    return (
      <Page>
        <Header>
          <Font format="78/94/400">{`${props.order}.`}</Font>
          <Font format="78/94/300" align="center" grow truncate>
            {props.name}
          </Font>
        </Header>
        <Content>
          <Column>
            {props.fields.length > 0 && (
              <Fields>
                {props.fields.map((field) => (
                  <Fragment key={field.id}>
                    <FieldName>
                      <Font format="29/59/400" color="#FF0000" nowrap>
                        {field.name}
                      </Font>
                    </FieldName>
                    <FieldValue>
                      <Font format="29/59/300" truncate>
                        {field.value}
                      </Font>
                    </FieldValue>
                  </Fragment>
                ))}
              </Fields>
            )}

            {props.spaces.length > 0 && (
              <div style={table.style}>
                {table.header}
                {table.rows}
              </div>
            )}
          </Column>
          <Column>
            {props.photo && (
              <Photo src={props.photo} width="100%" aspect={575 / 941} />
            )}
            {props.description && (
              <div>
                <Font
                  format="33/61/400"
                  background="#E7E6E6"
                  color="#FF0000"
                  padding="0px 30px"
                >
                  Notes
                </Font>
                <Font
                  format="33/40/300"
                  background="#F3F3F4"
                  padding="20px 30px"
                >
                  {props.description}
                </Font>
              </div>
            )}
          </Column>
        </Content>

        <Logo />
      </Page>
    )
  },
})

const Page = styled('div', {
  flexDirection: 'column',
  position: 'absolute',
  display: 'flex',
  inset: '0px',
  overflow: 'hidden',
})

const Header = styled('div', {
  backgroundColor: '#D9D5C2',
  flexShrink: 0,
  minWidth: '0px',
  display: 'flex',
  marginTop: '68px',
  padding: '12px 100px 27px 130px',
  gap: '10px',
})

const Content = styled('div', {
  gridTemplateColumns: '1fr 941px',
  padding: '130px 100px 0px',
  display: 'grid',
  gap: '63px',
})

const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '63px',
})

const Fields = styled('div', {
  gridTemplateColumns: 'min-content 1fr',
  gridTemplateRows: 'min-content',
  alignContent: 'flex-start',
  borderTop: '1px solid #D0CECE',
  display: 'grid',
})

const FieldName = styled('div', {
  borderBottom: '1px solid #D0CECE',
  backgroundImage: 'linear-gradient(to bottom, #D0CECE 33%, transparent 33%)',
  backgroundPosition: 'right top',
  backgroundRepeat: 'repeat-y',
  backgroundSize: '4px 12px',
  padding: '0px 20px',
  minWidth: '240px',
})

const FieldValue = styled('div', {
  minWidth: '0px',
  padding: '0px 32px',
  borderBottom: '1px solid #D0CECE',
})

const Logo = styled(CalgaryLogo, {
  position: 'absolute',
  height: '55px',
  bottom: '49px',
  right: '96px',
})

const Cell = styled('div', {
  padding: '0px 30px',
  minWidth: '0px',
  borderBottom: '1px solid #D0CECE',
})

const Table = {
  columns: [
    { id: 'Space Name', key: 'name', size: '1.25fr' },
    { id: 'Sqft Available', key: 'size', size: '1fr' },
    { id: 'Price / Sqft', key: 'price', size: '1fr' },
  ],

  header: (column) => (
    <Cell>
      <Font format="33/65/400" color="#FF0000" nowrap>
        {column.id}
      </Font>
    </Cell>
  ),

  row: (data, column) => (
    <Cell>
      <Font format="33/65/300" truncate>
        {data[column.key]}
      </Font>
    </Cell>
  ),
}
