import Api from 'rest-fetcher-redux';
import { API_ACTIONS } from '~/legacy/store';

const projectMiddleware = (store) => (next) => (action) => {
  if (action.type === API_ACTIONS.PROJECTS_REFRESH) {
    const state = store.getState();
    const { api } = state;
    const { listing_id: listingId, project_id: projectId } = action.payload;

    if (Array.isArray(api.getSurveys.data.data)) {
      Api.getSurveys();
    }

    if (projectId && api.getProject.data.id === projectId) {
      Api.getProject({ id: projectId });
    }

    if (listingId && api.getListing.data.id === listingId) {
      Api.getListing({ id: listingId });
    }
  }

  next(action);
};

export default projectMiddleware;
