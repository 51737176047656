import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { Typography } from './themeComponents';

const TooltipV2 = withStyles({
  tooltip: {
    minHeight: '22px',
    display: 'flex',
    paddingTop: '6px',
    paddingBottom: '6px',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.6)',
  },
  title: {},
})
(({ classes, children, title, ...props }) => {
  const titleComponent = title && typeof(title) === 'string' ? (
    <Typography variant='tooltip' className={classes.title}>
      {title}
    </Typography>
  ) : title;
  return (
    <MuiTooltip classes={{tooltip: classes.tooltip}} placement="top" enterDelay={1000} title={titleComponent} {...props}>
      {children}
    </MuiTooltip>
  );
});

export default TooltipV2
