import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { SimpleCompressedSourceImage } from '~/legacy/components';
import { numberWithCommas } from '~/legacy/utils';

import { ListingHeader } from '../Headers';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    padding: '8px 24px 0',
  },
  flooplanImageContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderBottom: `8px solid ${theme.palette.primary.main}`,
  },
  floorplanImage: {
    objectFit: 'contain',
    maxHeight: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
  },
}));

function ListingFloorplanPageLayout1({ listing, listingOrder, settings }) {
  const { showImageLabel } = settings;
  const classes = useStyles();

  const sqftLabel = listing.size
    ? `${numberWithCommas(listing.size)} SqFt`
    : '';

  let floorLabel = '';
  const floorCustomField = listing.custom_fields.find(
    (cf) => cf.name === 'Floor'
  );
  if (floorCustomField) {
    floorLabel = `${floorCustomField.custom_field.value.replace(
      /floors?/gi,
      ''
    )} Floor`;
  }

  let label = '';
  if (sqftLabel && floorCustomField) {
    label = `${floorLabel} - ${sqftLabel}`;
  } else if (sqftLabel || floorLabel) {
    label = sqftLabel || floorLabel;
  }

  return (
    <div className={classes.container}>
      <ListingHeader listing={listing} index={listingOrder} />
      {listing.floorplan_photos[0] && showImageLabel && label && (
        <Typography className={classes.label} variant="h3">
          {label}
        </Typography>
      )}
      <div className={classes.flooplanImageContainer}>
        {listing.floorplan_photos[0] && (
          <SimpleCompressedSourceImage
            imageFile={listing.floorplan_photos[0]}
            ImgProps={{
              alt: 'floorplan',
              className: classes.floorplanImage,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ListingFloorplanPageLayout1;
