import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const MockDraggableProvider = React.createContext({ draggableProps: {} });
const MockDroppableSnapshotProvider = React.createContext({});

const DraggableWrapper = ({ isUserBroker, buildingRow, index, children }) => {
  if (isUserBroker) {
    return (
      <Draggable
        key={buildingRow.uuid}
        draggableId={buildingRow.uuid}
        index={index}
      >
        {children}
      </Draggable>
    );
  }
  return (
    <MockDraggableProvider.Consumer>
      {draggableProvided => (
        <MockDroppableSnapshotProvider.Consumer>
          {snapshot => children(draggableProvided, snapshot)}
        </MockDroppableSnapshotProvider.Consumer>
      )}
    </MockDraggableProvider.Consumer>
  );
};

export default DraggableWrapper;
