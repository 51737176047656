/* eslint-disable react/no-array-index-key */
import React, { Children } from 'react'
import {
  makeStyles,
  StylesProvider,
  ThemeProvider,
  useTheme,
  createGenerateClassName,
} from '@material-ui/core/styles'

const useStyles = makeStyles({
  page: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
})

export const Pdf = (props) => {
  const classes = useStyles()
  const theme = useTheme()

  const generateClassName = createGenerateClassName({
    productionPrefix: 'c',
    seed: 'pdf',
  })

  return (
    <ThemeProvider theme={props.theme ?? theme}>
      <StylesProvider generateClassName={generateClassName}>
        {Children.map(props.children, (child, index) => (
          <div key={`page-${index}`} className={classes.page}>
            {child}
          </div>
        ))}
      </StylesProvider>
    </ThemeProvider>
  )
}
