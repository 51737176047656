import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Api from 'rest-fetcher-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Button } from '~/legacy/components';
import { ModalTitle } from '~/legacy/components/modals';
import { useApiHelper } from '~/legacy/utils/hooks';
import { LEASE_PROJECT_BY_ID, LEASE_LISTINGS } from '~/legacy/consts';
import {
  setSurveyListings,
  setSurveyBuildings,
} from '~/legacy/store/actions/viewSurvey';

const useStyles = makeStyles({
  rootContainer: {
    width: 'calc(100vw - 64px)',
    maxWidth: '548px',
    height: '245px',
    paddingBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    /* For whatever reason this is required downstream to take effect */
    marginBottom: '20px !important',
  },
  buttonsContainer: {
    marginTop: 'auto',
    maxWidth: '548px',
    minWidth: '300px',
    display: 'flex',
    marginLeft: 'auto',
  },
  cancelButton: {
    marginLeft: 'auto',
  },
  deleteButton: {
    marginLeft: '12px',
  },
});

export default function ConfirmDeleteListingModal({
  onClose,
  listingId,
  buildingId,
  surveyId,
}) {
  const classes = useStyles();
  const apiHelper = useApiHelper();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchSurveyListings = (callback) => {
    return Api.getSurveyListings({ id: surveyId }).then((results) => {
      dispatch(setSurveyListings(results.survey_listings));
      dispatch(setSurveyBuildings(results.survey_buildings));
      callback();
    });
  };

  return (
    <div className={classes.rootContainer}>
      <ModalTitle
        classNames={{ container: classes.container }}
        onClose={onClose}
        title="Are you sure?"
      />
      <Typography>
        Are you sure you want to delete this space and remove it from all
        surveys?
      </Typography>
      <Typography>
        It cannot be undone and will no longer be accessible. This will not
        delete any building information.
      </Typography>
      {/* Submit and cancel buttons */}
      <div className={classes.buttonsContainer}>
        <Button className={classes.cancelButton} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={classes.deleteButton}
          color="primary"
          disabled={isLoading}
          onClick={(e) => {
            setIsLoading(true);
            apiHelper
              .deleteListingSimple({ listingId, buildingId })
              .then((result) => {
                onClose(e);
                // success
                if (result.data) {
                  if (surveyId) {
                    fetchSurveyListings(() =>
                      history.push(LEASE_PROJECT_BY_ID.replace(':id', surveyId))
                    );
                  } else {
                    history.push(LEASE_LISTINGS);
                  }
                } else {
                  setIsLoading(false);
                }
              });
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}
