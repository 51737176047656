import React from 'react'
import { InlineEditableValue } from '~/legacy/components'
import { BULK_IMPORT_CONSTANTS, BULK_IMPORT_HELPERS } from '~/legacy/utils'

const EditableCellBase = ({
  placeholder = 'Add Value',
  fieldType,
  ...props
}) => (
  <InlineEditableValue
    classesIn={{}}
    hoverOnContainer
    onClick={() => {}}
    fieldDataTypeId={fieldType}
    placeholder={placeholder}
    {...props}
  />
)

export const EditableStringCell = (props) => {
  return <EditableCellBase type="text" {...props} />
}

export const EditableMultilineStringCell = (props) => {
  return <EditableCellBase type="multiline-text" {...props} />
}

export const EditableNumberCell = (props) => (
  <EditableCellBase
    type="number"
    formatDisplayValue={() => {
      return BULK_IMPORT_HELPERS.getNumberValueForDisplay(
        props.value,
        props.fieldType
      )
    }}
    prepareNewValue={(valueToPrepare) => {
      if (!valueToPrepare && valueToPrepare !== 0) {
        return null
      }
      return Number(valueToPrepare)
    }}
    {...props}
  />
)

export const EditableDateCell = (props) => {
  return <EditableCellBase type="date" {...props} />
}

export const EditableMultiSelectCell = (props) => {
  return <EditableCellBase type="multi-select" {...props} />
}

// For Convenience
export const EditableCell = ({
  value,
  updateValue,
  fieldType,
  placeholder,
  styleOverrides,
  ...other
}) => {
  if (
    [
      BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id,
      BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id,
      BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD.id,
    ].includes(fieldType)
  ) {
    return (
      <EditableNumberCell
        value={value}
        updateValue={updateValue}
        placeholder={placeholder}
        styleOverrides={styleOverrides}
        fieldType={fieldType}
        {...other}
      />
    )
  }
  if (fieldType === BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id) {
    return (
      <EditableDateCell
        value={value}
        updateValue={updateValue}
        placeholder={placeholder}
        styleOverrides={styleOverrides}
        fieldType={fieldType}
        {...other}
      />
    )
  }
  if (
    fieldType === BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.MULTILINE_STRING.id
  ) {
    return (
      <EditableMultilineStringCell
        value={value}
        updateValue={updateValue}
        placeholder={placeholder}
        styleOverrides={styleOverrides}
        fieldType={fieldType}
        {...other}
      />
    )
  }

  if (fieldType === BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id) {
    return (
      <EditableMultiSelectCell
        value={value}
        updateValue={updateValue}
        placeholder={placeholder}
        styleOverrides={styleOverrides}
        fieldType={fieldType}
        options={[]}
        {...other}
      />
    )
  }

  return (
    <EditableStringCell
      value={value}
      updateValue={updateValue}
      placeholder={placeholder}
      styleOverrides={styleOverrides}
      fieldType={fieldType}
      {...other}
    />
  )
}
