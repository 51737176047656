import { defineTemplate } from '~/pdfs/support/defineTemplate'
import { LeaseUpBuilding } from './pages/LeaseUpBuilding'
import { LeaseUpCover } from './pages/LeaseUpCover'
import { LeaseUpFloorplan } from './pages/LeaseUpFloorplan'
import { LeaseUpMap } from './pages/LeaseUpMap'
import { LeaseUpPhotos } from './pages/LeaseUpPhotos'
import { LeaseUpBuildingDivider } from './pages/LeaseUpBuildingDivider'
import { LeaseUpSpace } from './pages/LeaseUpSpace'
import { count } from '~/support/count'
import { conjunction } from '~/support/conjunction'

const createEmptySpace = (building) => ({
  id: `empty-space-${building.id}`,
  fields: [],
  images: [],
  floorplans: [],
  building,
  order: 0,
})

export const LeaseUp = defineTemplate({
  id: 'fd6abdda-a66b-4148-859e-eb843a618161',

  name: 'LeaseUp',

  pages: [
    LeaseUpCover,
    LeaseUpMap,
    LeaseUpBuildingDivider,
    LeaseUpBuilding,
    LeaseUpSpace,
    LeaseUpFloorplan,
    LeaseUpPhotos,
  ],

  stats: ({ data, pages }) => {
    return conjunction([
      count('Building', data.buildings.length),
      count('Space', data.spaces.length),
      count('Page', pages.length),
    ])
  },

  build: (pdf) => {
    return [
      { template: LeaseUpCover, props: LeaseUpCover.props(pdf) },
      { template: LeaseUpMap, props: LeaseUpMap.props(pdf) },
      pdf.data.buildings.flatMap((building) => [
        {
          id: `${LeaseUpBuildingDivider.id}.${building.id}`,
          template: LeaseUpBuildingDivider,
          props: LeaseUpBuildingDivider.props(pdf, building),
        },
        {
          id: `${LeaseUpBuilding.id}.${building.id}`,
          template: LeaseUpBuilding,
          props: LeaseUpBuilding.props(pdf, building),
        },
        (building.spaces.length
          ? building.spaces
          : [createEmptySpace(building)]
        )
          .flatMap((space) => [
            {
              id: `${LeaseUpSpace.id}.${space.id}`,
              template: LeaseUpSpace,
              props: LeaseUpSpace.props(pdf, space),
            },
            {
              id: `${LeaseUpFloorplan.id}.${space.id}`,
              template: LeaseUpFloorplan,
              props: LeaseUpFloorplan.props(pdf, space),
            },
            {
              id: `${LeaseUpPhotos.id}.${space.id}`,
              template: LeaseUpPhotos,
              props: LeaseUpPhotos.props(pdf, space),
            },
          ])
          .filter(Boolean),
      ]),
    ].flat(4)
  },

  settings: {
    state: {
      map: true,
      sqft: true,
      floor: true,
    },
  },
})
