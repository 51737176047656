import React, { useRef, useState, useMemo } from 'react';
import clsx from 'clsx';
import update from 'immutability-helper';
import isEmpty from 'lodash/isEmpty';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  File,
  ExcludeIcon,
  ImageDropzone,
  Typography,
  Tabs,
} from '~/legacy/components';
import { uploadFilesFromPdf } from '~/legacy/utils';

const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true;
  }
  return false;
};
const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingRight: 0,
  },
  closeIcon: {
    cursor: 'pointer',
    margin: '0 24px 0 auto',
    color: '#111111',
  },
  content: {
    backgroundColor: 'white',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '95vw',
    maxWidth: '1280px',
    minHeight: '540px',
  },
  flexContainer: {
    height: '100%',
    justifyContent: 'center',
  },
  footer: {
    padding: '0 24px 24px 24px',
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    boxShadow:
      'inset 0 0 0 #e0e0e0, inset 0 -1px 0 #e0e0e0, inset 0 0 0 #e0e0e0, inset 0 0 0 #e0e0e0',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: '150px',
    width: '150px',
    cursor: 'move',
    border: '1px solid #d8d8d8',
    borderRadius: '4px',
    backgroundColor: '#d8d8d8',
    '& img': {
      objectFit: 'cover',
      borderRadius: '4px',
    },
  },
  imageWrapper: {
    position: 'relative',
    marginTop: '24px',
    marginRight: '24px',
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 0 24px 24px',
    overflow: 'auto',
  },
  imageDelete: {
    cursor: 'pointer',
    position: 'absolute',
    top: '-7px',
    right: '-7px',
    color: '#4A4A4A',
  },
  imageDeleteDragging: {
    display: 'none',
  },
  imageDragging: {
    background: '#ffffff',
    '& img': {
      display: 'none',
    },
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  saveButton: {
    marginLeft: '12px',
  },
  tabs: {
    height: '60px',
    flex: 1,
  },
  nameTitle: {
    display: 'flex',
    marginLeft: '24px',
    flex: 1,
  },
  closeIconContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'end',
  },
}));

export const IMAGE_UPLOAD_MODAL_TAB_KEYS = {
  BUILDING: 1,
  SPACE: 2,
  FLOORPLAN: 3,
  NEIGHBORHOOD: 4,
};

const TABS_DATA = [
  {
    key: IMAGE_UPLOAD_MODAL_TAB_KEYS.BUILDING,
    endpoint: 'building_photos',
    imagesKey: 'images',
    label: 'Building',
    parentObject: 'building',
    emptyStateText: 'Upload Building Photos',
  },
  {
    key: IMAGE_UPLOAD_MODAL_TAB_KEYS.SPACE,
    endpoint: 'listing_photos',
    imagesKey: 'images',
    label: 'Space',
    parentObject: 'listing',
    emptyStateText: 'Upload Space Photos',
  },
  {
    key: IMAGE_UPLOAD_MODAL_TAB_KEYS.FLOORPLAN,
    endpoint: 'listing_floorplans',
    imagesKey: 'floorplan_photos',
    label: 'Floor Plan',
    parentObject: 'listing',
    emptyStateText: 'Upload Floorplan Photos',
  },
  {
    key: IMAGE_UPLOAD_MODAL_TAB_KEYS.NEIGHBORHOOD,
    endpoint: 'building_neighborhood_photos',
    imagesKey: 'neighborhood_photos',
    label: 'Neighborhood',
    parentObject: 'building',
    emptyStateText: 'Upload Neighborhood Photos',
  },
];
// By default show all tabs
const DEFAULT_TABS = [
  IMAGE_UPLOAD_MODAL_TAB_KEYS.BUILDING,
  IMAGE_UPLOAD_MODAL_TAB_KEYS.SPACE,
  IMAGE_UPLOAD_MODAL_TAB_KEYS.FLOORPLAN,
  IMAGE_UPLOAD_MODAL_TAB_KEYS.NEIGHBORHOOD,
];

const DraggableImage = ({ children, index, moveImage, removeImage }) => {
  const classes = useStyles();
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'image',
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveImage(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      // eslint-disable-next-line
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'image', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => setMouseIsDown(false),
  });

  drag(drop(ref));
  return (
    <div className={classes.imageWrapper}>
      <div
        className={clsx(classes.image, isDragging && classes.imageDragging)}
        onMouseDown={() => setMouseIsDown(true)}
        onMouseUp={() => setMouseIsDown(false)}
        ref={ref}
      >
        {children}
      </div>
      {!mouseIsDown && (
        <ExcludeIcon
          className={clsx(
            classes.imageDelete,
            isDragging && classes.imageDeleteDragging
          )}
          onClick={() => removeImage(index)}
        />
      )}
    </div>
  );
};

export function ImageUploadModal({
  onClose,
  objectName,
  parentObjects,
  setListing,
  tabsToShow = DEFAULT_TABS,
}) {
  const classes = useStyles();
  const setListingAsync = async (...args) => setListing(...args);
  // Filter the tabs to the requested tabs
  const tabsData = useMemo(
    () =>
      tabsToShow.map((tabKey) =>
        TABS_DATA.find((tabData) => tabData.key === tabKey)
      ),
    [tabsToShow]
  );

  const [selectedTab, setSelectedTab] = useState(0);
  const [imageLists, setImageLists] = useState(
    // retrieve and merge the image maps from the parent objects
    // returns { building_photos: {uuid: {}, ...}, listing_photos: {...}, ... }
    () =>
      tabsData
        .map((tabData) => {
          if (parentObjects[tabData.parentObject]) {
            const images =
              parentObjects[tabData.parentObject][tabData.imagesKey] || [];
            const newImages = [];
            images.forEach((image) =>
              newImages.push({
                uuid: image.uuid,
                data: image,
              })
            );
            return {
              [`${tabData.parentObject}_${tabData.imagesKey}`]: newImages,
            };
          }
          return null;
        })
        .reduce((imagesMapAccumulator, imagesMap) => ({
          ...imagesMapAccumulator,
          ...imagesMap,
        }))
  );
  const selectedTabData = tabsData[selectedTab];
  const selectedImagesKey = `${selectedTabData.parentObject}_${selectedTabData.imagesKey}`;
  const selectedImageList = imageLists[selectedImagesKey];

  const onFileDrop = (filesIn) => {
    const newFiles = [];
    filesIn.forEach((fileIn) => {
      if (fileIn.type === 'application/pdf') {
        const newId = uuidv4();
        uploadFilesFromPdf(
          fileIn,
          selectedTabData.endpoint,
          (singleUploadResult) => {
            setImageLists((oldLists) => ({
              ...oldLists,
              [selectedImagesKey]: oldLists[selectedImagesKey]
                .filter((i) => i.uuid !== newId)
                .concat({
                  uuid: singleUploadResult.data.file.uuid,
                  data: singleUploadResult.data.file,
                }),
            }));
          }
        );
        newFiles.push({
          uuid: newId,
          skipUpload: true,
          data: fileIn,
        });
      } else {
        newFiles.push({
          uuid: uuidv4(),
          data: fileIn,
        });
      }
    });
    setImageLists({
      ...imageLists,
      [selectedImagesKey]: selectedImageList.concat(newFiles),
    });
  };

  const saveAndClose = async () => {
    const listingSafe =
      parentObjects && parentObjects.listing ? parentObjects.listing : {};
    const buildingSafe =
      parentObjects && parentObjects.building ? parentObjects.building : {};

    await setListingAsync({
      ...listingSafe,
      images: Object.values(imageLists.listing_images || {}).map(
        (item) => item.data
      ),
      floorplan_photos: Object.values(
        imageLists.listing_floorplan_photos || {}
      ).map((item) => item.data),
      building: {
        ...buildingSafe,
        images: Object.values(imageLists.building_images || {}).map(
          (item) => item.data
        ),
        neighborhood_photos: Object.values(
          imageLists.building_neighborhood_photos || {}
        ).map((item) => item.data),
      },
    });
    onClose();
  };

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <Typography variant="boldText" className={classes.nameTitle}>
          {objectName}
        </Typography>
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabLabels={tabsData.map((tabData) => tabData.label)}
          className={classes.tabs}
          classes={{
            tabsFlexContainer: classes.flexContainer,
            indicator: classes.indicator,
          }}
        />
        <div className={classes.closeIconContainer}>
          <CloseIcon className={classes.closeIcon} onClick={saveAndClose} />
        </div>
      </div>
      {!isEmpty(selectedImageList) && (
        <DndProvider backend={backendForDND}>
          <div className={classes.imagesContainer}>
            {selectedImageList.map((image, index) => (
              <DraggableImage
                index={index}
                key={image.uuid}
                moveImage={(dragIndex, hoverIndex) =>
                  setImageLists({
                    ...imageLists,
                    [selectedImagesKey]: update(selectedImageList, {
                      $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, selectedImageList[dragIndex]],
                      ],
                    }),
                  })
                }
                removeImage={(clickIndex) =>
                  setImageLists({
                    ...imageLists,
                    [selectedImagesKey]: update(selectedImageList, {
                      $splice: [[clickIndex, 1]],
                    }),
                  })
                }
              >
                <File
                  endpoint={selectedTabData.endpoint}
                  file={image.data}
                  skipUploading={image.skipUpload || false}
                  updateFile={(newFileData) =>
                    setImageLists((oldLists) => ({
                      ...oldLists,
                      [selectedImagesKey]: oldLists[selectedImagesKey]
                        .filter((i) => i.uuid !== image.uuid)
                        .concat([
                          {
                            uuid: image.uuid,
                            data: newFileData,
                          },
                        ]),
                    }))
                  }
                  showCancelButton={false}
                />
              </DraggableImage>
            ))}
            <ImageDropzone isSmall onFileDrop={onFileDrop} />
          </div>
        </DndProvider>
      )}
      {isEmpty(selectedImageList) && (
        <ImageDropzone
          onFileDrop={onFileDrop}
          headerText={selectedTabData.emptyStateText}
        />
      )}
      <div className={classes.footer}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          className={classes.saveButton}
          color="primary"
          onClick={saveAndClose}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
