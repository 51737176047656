import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { format } from 'date-fns'
import lodash, { isEqual } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import {
  CalendarIcon,
  CardImage,
  Link,
  MoreIcon,
  SpaceIcon,
  SquareIconButton,
  SurveyIcon,
  Typography,
} from '~/legacy/components'
import { getPlural, getViewProjectSurveyRoute } from '~/legacy/utils'
import { openLink } from '~/legacy/utils/openLink'

const IMAGE_WIDTH_PX = 160
const IMAGE_HEIGHT_PX = 118
const IMAGE_MARGIN_PX = 12
const IMAGE_BORDER_RADIUS_PX = 4
const DIVIDER_COLOR = '#E0E0E0'

const COMMON_PROJECT_STUFF_STYLES = {
  root: {
    width: '100%',
    height: '234px',
    borderRadius: '4px',
  },
}

// Prevent props that are actually the same from rerendering by using Lodash.isEqual
const areEqualLodash = (props, nextProps) => {
  const propEntries = Object.entries(props)
  if (propEntries.length !== Object.keys(nextProps).length) {
    return false
  }
  return !propEntries.find(
    ([key, value]) => !(key in nextProps) || !isEqual(value, nextProps[key])
  )
}

export const ProjectCollectionCard = withStyles(
  lodash.merge({}, COMMON_PROJECT_STUFF_STYLES, {
    root: {
      border: `1px solid ${DIVIDER_COLOR}`,
      padding: '24px',
      cursor: 'pointer',
    },
    imageContainer: {
      width: `${IMAGE_WIDTH_PX}px`,
      height: `${IMAGE_HEIGHT_PX}px`,
      position: 'relative',
      marginRight: `${IMAGE_MARGIN_PX}px`,
      '&:last-child': {
        marginRight: 0,
      },
    },
    textDivider: {
      color: `${DIVIDER_COLOR}`,
      paddingLeft: '6px',
      paddingRight: '6px',
    },
    image: {
      width: `${IMAGE_WIDTH_PX}px`,
      height: '118px',
      borderRadius: `${IMAGE_BORDER_RADIUS_PX}px`,
    },
    spaces: {
      marginLeft: '4px',
    },
    imageOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: `${IMAGE_BORDER_RADIUS_PX}px`,
    },
    imageOverlayOpaque: {
      backgroundColor: '#111',
      opacity: 0.5,
    },
    topRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    numberSpaces: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 'auto',
      alignItems: 'center',
    },
    lastUpdatedAt: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '6px',
    },
    imagesContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '18px',
    },
    nameAndIcon: {
      display: 'flex',
      flexDirection: 'row',
    },
    name: {
      marginLeft: '6px',
    },
    moreIconButton: {
      marginLeft: '20px',
    },
  })
)(
  React.memo(
    ({
      classes,
      collectionId,
      name,
      updatedAt,
      cardTypeLabel,
      cardTypeIcon,
      listings,
      forwardLink,
      openMenu = () => {},
    }) => {
      const imagesRef = useRef(null)
      const [numImagesToDisplay, setNumImagesToDisplay] = useState(0)
      const numListings = listings ? listings.length : 0
      const numHiddenImages = Math.max(numListings - numImagesToDisplay, 0)

      // Set the max number of images we can dislay given the width of the page
      useEffect(() => {
        const currentRef = imagesRef.current
        const observer = new ResizeObserver(() => {
          const newNumImagesToDisplay = Math.min(
            Math.floor(
              (currentRef.offsetWidth + IMAGE_MARGIN_PX) /
                (IMAGE_MARGIN_PX + IMAGE_WIDTH_PX)
            ),
            numListings
          )
          setNumImagesToDisplay(newNumImagesToDisplay)
        })
        observer.observe(currentRef)
        return () => currentRef && observer.unobserve(currentRef)
      }, [numListings, numImagesToDisplay])

      return (
        <div className={classes.root} onClick={openLink(forwardLink)}>
          {/* Top row: Name, icon, num spaces */}
          <div className={classes.topRow}>
            {/* Name and icon */}
            <div className={classes.nameAndIcon}>
              {cardTypeIcon}
              <Typography variant="sectionTitle" className={classes.name}>
                {name}
              </Typography>
            </div>
            {/* Number of spaces */}
            <div className={classes.numberSpaces}>
              <SpaceIcon />
              <Typography variant="boldText" className={classes.spaces}>
                {getPlural(numListings, 'Space')}
              </Typography>
              <SquareIconButton
                className={classes.moreIconButton}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  openMenu(event, collectionId, name)
                }}
              >
                <MoreIcon />
              </SquareIconButton>
            </div>
          </div>

          {/* Last updated at */}
          <div className={classes.lastUpdatedAt}>
            <Typography variant="subSectionTitle">{cardTypeLabel}</Typography>
            <Typography
              variant="subSectionTitle"
              className={classes.textDivider}
            >
              |
            </Typography>
            <Typography variant="subSectionTitle">
              {updatedAt
                ? `Last Updated: ${format(
                    Date.parse(updatedAt),
                    'MMM do, yyyy'
                  )}`
                : ''}
            </Typography>
          </div>

          {/* Images */}
          <div ref={imagesRef} className={classes.imagesContainer}>
            {listings &&
              listings.slice(0, numImagesToDisplay).map((listing, index) => {
                return (
                  <div key={listing.id} className={classes.imageContainer}>
                    <CardImage
                      imageFile={listing.hero_image}
                      ImgProps={{
                        className: classes.image,
                      }}
                    />
                    {!!(
                      numHiddenImages > 0 && index === numImagesToDisplay - 1
                    ) && (
                      <>
                        <div
                          className={clsx(
                            classes.imageOverlay,
                            classes.imageOverlayOpaque
                          )}
                        />
                        <div className={classes.imageOverlay}>
                          <Typography variant="imageOverlayText">
                            {`+${numHiddenImages} More`}
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      )
      // Prevent cards from rerendering when props aren't really changing...
    },
    areEqualLodash
  )
)

export const ProjectSurveyCard = withStyles({
  icon: {
    color: '#111',
  },
})(({ classes = {}, projectId, survey = {}, ...props }) => {
  const iconComponent = React.useMemo(
    () => <SurveyIcon className={classes.icon} />,
    [classes]
  )
  return (
    <ProjectCollectionCard
      key={`project-collection-card-${survey.id}`}
      collectionId={survey.id}
      name={survey.name}
      updatedAt={survey.updated_at}
      cardTypeLabel="Survey"
      cardTypeIcon={iconComponent}
      listings={survey.listings}
      images={survey.images}
      forwardLink={getViewProjectSurveyRoute(projectId, survey.id)}
      {...props}
    />
  )
})

export const ProjectTourCard = ({ tour, ...props }) => {
  const iconComponent = React.useMemo(() => <CalendarIcon />, [])
  return (
    <ProjectCollectionCard
      collectionId={tour.id}
      name={tour.name}
      updatedAt={tour.updated_at}
      cardTypeLabel="Tour"
      cardTypeIcon={iconComponent}
      listings={tour.listings}
      images={tour.images}
      {...props}
    />
  )
}

const TEXT_COLOR = '#666'
export const EmptyStateProjectCollectionCard = withStyles(
  lodash.merge({}, COMMON_PROJECT_STUFF_STYLES, {
    root: {
      backgroundColor: '#F3F3F3',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    titleText: {
      fontSize: '18px',
      lineHeight: '24px',
      color: TEXT_COLOR,
      marginTop: '8px',
    },
    subTitleText: {
      fontSize: '14px',
      lineHeight: '21px',
      color: TEXT_COLOR,
    },
    subSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '6px',
    },
    surveyIcon: {
      color: '#666',
    },
  })
)(({ classes, onClick = async () => {} }) => {
  return (
    <div className={classes.root}>
      <SurveyIcon className={classes.surveyIcon} />
      <Typography variant="boldText" className={classes.titleText}>
        Add a Survey to This Project
      </Typography>
      <div className={classes.subSection}>
        <Typography variant="boldText" className={classes.subTitleText}>
          Most projects begin with a survey.
        </Typography>
        <Typography variant="boldText" className={classes.subTitleText}>
          {'To get started, '}
          <Link onClick={onClick}>create a survey</Link>
          <span>.</span>
        </Typography>
      </div>
    </div>
  )
})
