import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import chunk from 'lodash/chunk';
import orderBy from 'lodash/orderBy';
import { SimpleCardImage } from '~/legacy/components';
import { Markdown } from '~/legacy/components/themeComponents';
import {
  BULK_IMPORT_CONSTANTS,
  formatListingForDisplayProper,
} from '~/legacy/utils';

import { ListingHeader } from '../Headers';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topSection: {
    display: 'flex',
    minHeight: '250px',
    margin: '24px',
  },
  heroImageContainer: {
    minWidth: '274px',
    minHeight: '250px',
    maxWidth: '274px',
    maxHeight: '250px',
    marginRight: '12px',
  },
  heroImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
    flex: 7,
    overflow: 'hidden',
    borderBottom: `8px solid ${theme.palette.primary.main}`,
  },
  addressContainer: {
    backgroundColor: '#f9f9f9',
    borderBottom: '2px solid #e0e0e0',
    padding: '24px 12px',
  },
  address: {
    marginBottom: '4px',
  },
  multiSelectFieldContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  multiSelectValue: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
    margin: '8px 8px 0 0',
  },
  metadataKey: {
    marginRight: '4px',
    fontSize: '10px',
    whiteSpace: 'nowrap',
  },
  metadataValue: {
    fontSize: '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  metadataBlocksContainer: {
    borderTop: '1px solid #e0e0e0',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  metadataRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    minHeight: '20px',
    maxHeight: '32px',
  },
  metadataBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    width: 'calc(50% - 6px)',
    borderBottom: '1px solid #e0e0e0',
    height: '100%',
  },
}));

function ListingDetailPageLayout1({
  listing,
  listingOrder,
  settings,
  listingFieldOrder,
}) {
  const classes = useStyles();
  const { showListingDescription } = settings;

  const metadata = formatListingForDisplayProper({ space: listing });
  const sortedListingMetadata = orderBy(
    metadata.filter((m) => m.formattedValue),
    (field) => {
      const fieldOrderData = listingFieldOrder.find(
        (fo) => fo.field_id === field.id
      );
      return fieldOrderData ? fieldOrderData.order : Number.MAX_SAFE_INTEGER;
    }
  );
  const metadataRows = chunk(sortedListingMetadata, 2);
  const spaceNotes =
    listing[BULK_IMPORT_CONSTANTS.FIELDS.SPACE_NOTES.modelFieldName];
  return (
    <div className={classes.container}>
      <ListingHeader listing={listing} index={listingOrder} />
      <div className={classes.topSection}>
        {listing.listing_only_hero_photo && (
          <div className={classes.heroImageContainer}>
            <SimpleCardImage
              imageFile={listing.listing_only_hero_photo}
              ImgProps={{
                alt: 'primary',
                className: classes.heroImage,
              }}
            />
          </div>
        )}
        {showListingDescription && spaceNotes && (
          <Markdown forPdf>
            {listing[BULK_IMPORT_CONSTANTS.FIELDS.SPACE_NOTES.modelFieldName]}
          </Markdown>
        )}
      </div>
      <div className={classes.dataContainer}>
        <div className={classes.metadataBlocksContainer}>
          {metadataRows.map((row) => (
            <div
              key={row.map((f) => f.name).join()}
              className={classes.metadataRow}
            >
              {row.map(
                (field) =>
                  field.formattedValue && (
                    <div key={field.name} className={classes.metadataBlock}>
                      <Typography className={classes.metadataKey} variant="h3">
                        {`${field.name}:`}
                      </Typography>
                      <Typography
                        className={classes.metadataValue}
                        variant="body1"
                      >
                        {`${field.formattedValue}`}
                      </Typography>
                    </div>
                  )
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListingDetailPageLayout1;
