import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function NestedItemIcon(props) {
  return (
    <SvgIcon
      width="18"
      height="9"
      viewBox="0 0 18 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0H0V8V9H1H18V8L1 8V0Z"
        fill="#E0E0E0"
      />
    </SvgIcon>
  );
}
