import React, { useState } from 'react';
import Api from 'rest-fetcher-redux';
import { Hidden, Link, Slide, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RESET_PASSWORD, RESET_PASSWORD_SUCCESS } from '~/legacy/consts';
import { Button, TextInput, PasswordRequirements } from '~/legacy/components';
import { ModalTitle } from '~/legacy/components/modals';

const useStyles = makeStyles({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '248px',
  },
  modalTitle: {
    margin: 0,
    marginLeft: 'auto',
  },
  button: {
    marginTop: '20px',
    width: '250px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '250px',
  },
  confirmPasswordInput: {
    marginTop: '20px',
  },
  passwordRequirements: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px',
    width: '100%',
  },
  passwordRequirementsTitle: {
    marginLeft: '14px',
    lineHeight: '21px',
  },
  passwordRequirement: {
    display: 'flex',
    alignItems: 'center',
    height: '21px',
  },
  passwordRequirementLabel: {
    marginLeft: '6px',
  },
  passwordRequirementLabelNoIndicator: {
    marginLeft: '14px',
  },
  logoContainer: {
    maxWidth: (props) => (props.isLeaseUpLogo ? '120px' : '158px'),
    maxHeight: '72px',
    display: 'flex',
    marginTop: (props) => props.allowClose && '-12px',
  },
  logo: {
    justifyContent: 'center',
  },
  subTitle: {
    marginTop: '20px',
    marginBottom: '20px',
    maxWidth: '250px',
  },
});

const SetPasswordModal = ({
  allowClose = true,
  onClose,
  confirmUser = false,
  actionLabel,
  actionButtonLabel,
  successModalTitle,
  successModalText,
}) => {
  const classes = useStyles({ allowClose, isLeaseUpLogo: !localStorage.skin });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [currentWindow, setCurrentWindow] = useState(RESET_PASSWORD);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const resetPassword = () => {
    if (!password && !confirmPassword) {
      setErrorText('Please enter a new password');
    } else if (password !== confirmPassword) {
      setErrorText('Passwords do not match.');
    } else {
      setErrorText('');
      Api.changePassword({
        body: {
          password,
          confirm_user: confirmUser,
        },
      }).then((res) => {
        if (res.data === 'success') {
          setCurrentWindow(RESET_PASSWORD_SUCCESS);
        }
      });
    }
  };

  return (
    <div className={classes.modalContent}>
      {allowClose && (
        <ModalTitle
          classNames={{
            container: classes.modalTitle,
          }}
          title=""
          onClose={onClose}
        />
      )}
      <Hidden xlDown={currentWindow !== RESET_PASSWORD}>
        <div className={classes.content}>
          <Typography align="center" variant="h2">
            {actionLabel}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            className={classes.subTitle}
          >
            Enter a new password
          </Typography>
          <TextInput
            autoFocus
            className={classes.input}
            label="New Password"
            name="new password"
            onChange={(event) => setPassword(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                resetPassword();
              }
            }}
            type="password"
            value={password}
          />
          <TextInput
            className={classes.confirmPasswordInput}
            errorText={errorText}
            label="Re-enter New Password"
            name="re-enter new password"
            onChange={(event) => setConfirmPassword(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                resetPassword();
              }
            }}
            type="password"
            value={confirmPassword}
          />
          <PasswordRequirements
            password={password}
            confirmPassword={confirmPassword}
            setButtonDisabled={setButtonDisabled}
          />
          <Button
            className={classes.button}
            color="primary"
            disabled={buttonDisabled}
            onClick={resetPassword}
          >
            {actionButtonLabel}
          </Button>
        </div>
      </Hidden>
      <Hidden xlDown={currentWindow !== RESET_PASSWORD_SUCCESS}>
        <Slide
          direction="left"
          in={currentWindow === RESET_PASSWORD_SUCCESS}
          mountOnEnter
        >
          <div className={classes.content}>
            <Typography align="center" variant="h2">
              {successModalTitle}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              className={classes.subTitle}
            >
              {successModalText}
            </Typography>
            <Link
              onClick={() => {
                window.location = '/';
              }}
            >
              Go back to Home
            </Link>
          </div>
        </Slide>
      </Hidden>
    </div>
  );
};

export default SetPasswordModal;
