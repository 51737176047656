import lodash from 'lodash';

export const userTypes = {
  tenant: 'Tenant',
  tenantBroker: 'TenantBroker',
  admin: 'Admin',
  landlordBroker: 'LandLordBroker',
};

export const BROKER_ROLES = {
  BASE: 0,
  ADMIN: 10,
};

// The defined user roles for each user type. These are select option definitions
export const USER_ROLE_OPTIONS_LOOKUP = {
  [userTypes.tenantBroker]: {
    [BROKER_ROLES.ADMIN]: {
      value: BROKER_ROLES.ADMIN,
      label: 'Admin',
      order: 0,
    },
    [BROKER_ROLES.BASE]: {
      value: BROKER_ROLES.BASE,
      label: 'Broker',
      order: 1,
    },
  },
};

// Build a list of options for each user role/type
export const USER_ROLE_OPTIONS = {
  [userTypes.tenantBroker]: lodash.sortBy(
    [...Object.values(USER_ROLE_OPTIONS_LOOKUP[userTypes.tenantBroker])],
    'order',
    'asc'
  ),
};

export const USER_SUBSCRIPTION_STATUSES = {
  TRIAL: 'trial',
  PAID: 'paid',
  FREE: 'free',
};
