import {
  formatId,
  getDataTypeNameByDataTypeId,
  SEGMENT_PARAM_NAMES,
} from '~/legacy/utils';
import { fetchApi } from './rootApi';

const LISTING_ENDPOINT_ROOT = 'listings/';

export const listingApi = {
  updatePartial: async ({ listingId, partial }) =>
    fetchApi({
      endpoint: `${LISTING_ENDPOINT_ROOT}${listingId}/`,
      fetchArgs: {
        method: 'PATCH',
        body: JSON.stringify(partial),
      },
      trackingArgs: {
        name: 'Update Listing Partial',
        metadata: {
          [SEGMENT_PARAM_NAMES.LISTING_ID]: formatId(listingId),
        },
      },
    }),

  createCustomField: async ({
    listingId,
    name = '',
    value = '',
    dataType = 1,
  }) =>
    fetchApi({
      endpoint: `${LISTING_ENDPOINT_ROOT}${listingId}/add_custom_field/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ name, value, data_type: dataType }),
      },
      trackingArgs: {
        name: 'Create Listing Custom Field',
        metadata: {
          [SEGMENT_PARAM_NAMES.LISTING_ID]: formatId(listingId),
          [SEGMENT_PARAM_NAMES.FIELD_NAME]: name,
          [SEGMENT_PARAM_NAMES.FIELD_DATA_TYPE]:
            getDataTypeNameByDataTypeId(dataType),
        },
      },
    }),

  createCustomFields: async ({
    listingIds,
    name = '',
    valueMap = {},
    dataType = 1,
  }) =>
    fetchApi({
      endpoint: `${LISTING_ENDPOINT_ROOT}add_custom_field_to_all/`,
      fetchArgs: {
        method: 'PUT',
        body: JSON.stringify({
          ids: listingIds,
          name,
          valueMap,
          data_type: dataType,
        }),
      },
      trackingArgs: {
        name: 'Create Custom Field On Listings',
        metadata: {
          [SEGMENT_PARAM_NAMES.LISTING_IDS]: listingIds.map((id) =>
            formatId(id)
          ),
          [SEGMENT_PARAM_NAMES.FIELD_NAME]: name,
          [SEGMENT_PARAM_NAMES.FIELD_DATA_TYPE]:
            getDataTypeNameByDataTypeId(dataType),
        },
      },
    }),

  updateCustomField: async ({ listingId, listingCustomField }) =>
    fetchApi({
      endpoint: `${LISTING_ENDPOINT_ROOT}${listingId}/update_custom_field/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          name: listingCustomField.name,
          value: listingCustomField.custom_field.value,
          data_type: listingCustomField.custom_field.data_type,
          custom_field_id: listingCustomField.id,
        }),
      },
      trackingArgs: {
        name: 'Update Listing Custom Field',
        metadata: {
          [SEGMENT_PARAM_NAMES.LISTING_ID]: formatId(listingId),
          [SEGMENT_PARAM_NAMES.LISTING_CUSTOM_FIELD_ID]: formatId(
            listingCustomField.id
          ),
          [SEGMENT_PARAM_NAMES.FIELD_NAME]: name,
          [SEGMENT_PARAM_NAMES.FIELD_DATA_TYPE]: getDataTypeNameByDataTypeId(
            listingCustomField.custom_field.data_type
          ),
        },
      },
    }),

  deleteCustomField: async ({ listingId, listingCustomFieldId }) =>
    fetchApi({
      endpoint: `${LISTING_ENDPOINT_ROOT}${listingId}/delete_custom_field/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          custom_field_id: listingCustomFieldId,
        }),
      },
      trackingArgs: {
        name: 'Delete Listing Custom Field',
        metadata: {
          [SEGMENT_PARAM_NAMES.LISTING_ID]: formatId(listingId),
          [SEGMENT_PARAM_NAMES.LISTING_CUSTOM_FIELD_ID]:
            formatId(listingCustomFieldId),
        },
      },
    }),

  changeCustomFieldDataType: async ({
    listingIds,
    name = '',
    valueMap = {},
    dataType,
  }) =>
    fetchApi({
      endpoint: `${LISTING_ENDPOINT_ROOT}change_custom_fields_data_type/`,
      fetchArgs: {
        method: 'PUT',
        body: JSON.stringify({
          ids: listingIds,
          name,
          value_map: valueMap,
          data_type: dataType,
        }),
      },
      trackingArgs: {
        name: 'Change Custom Field Data Type On Listings',
        metadata: {
          [SEGMENT_PARAM_NAMES.LISTING_IDS]: listingIds.map((id) =>
            formatId(id)
          ),
          [SEGMENT_PARAM_NAMES.FIELD_NAME]: name,
          [SEGMENT_PARAM_NAMES.FIELD_DATA_TYPE]:
            getDataTypeNameByDataTypeId(dataType),
        },
      },
    }),
};
