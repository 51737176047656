import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useEditorStyles = makeStyles({
  container: {
    padding: '32px',
  },
  empty: {
    color: '#666666',
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '22p',
  },
})

export const Settings = ({ children, className }) => {
  const classes = useEditorStyles()
  return (
    <div className={clsx(classes.container, className)}>
      {children || <div className={classes.empty}>No Settings</div>}
    </div>
  )
}
