import React from 'react'
import { Font } from '~/components/Font'
import {
  WalkScoreIcon,
  BikeScoreIcon,
  TransitScoreIcon,
} from '~/legacy/components'
import { styled } from '~/libraries/styled'

const presets = {
  bike: {
    name: 'Bike Score',
    icon: BikeScoreIcon,
  },
  transit: {
    name: 'Transit Score',
    icon: TransitScoreIcon,
  },
  walk: {
    name: 'Walk Score',
    icon: WalkScoreIcon,
  },
}

export const Walkscore = (props) => {
  const preset = presets[props.preset ?? props.type] ?? props
  const Icon = preset.icon ?? WalkScoreIcon

  return (
    <Container className={props.className}>
      <Font format="6/6/600">
        {preset.name}
        <span style={{ padding: '1px' }}>&reg;</span>
      </Font>
      <Font format="7/7" grow>
        {props.description}
      </Font>
      <Details>
        <Icon style={{ flexShrink: 0, height: '12px', width: '12px' }} />
        <Font format="10/12/600">{props.score}</Font>
      </Details>
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '2px',
  border: '1px solid #e0e0e0',
  padding: '6px',
  height: '50px',
  gap: '2px',
})

const Details = styled('div', {
  justifyContent: 'space-between',
  display: 'flex',
})
