import { setListingLoading } from './prefetch';

export default {
  updateSurveyListingStatus: {
    url: '/survey_listings/:id/update_status/',
    options: {
      method: 'POST',
    },
  },
  updateSurveyListingStatuses: {
    url: '/survey_listings/update_statuses/',
    options: {
      method: 'POST',
    },
  },
  getMySurveyListings: {
    url: '/survey_listings/mine/',
    options: {
      method: 'GET',
    },
    changeOnAction: {
      '@@INIT': store => ({ ...store, data: { items: [], pagination: {} } }),
    },
    prefetch: [
      ({ dispatch }) => {
        dispatch(setListingLoading(true));
      },
    ],
    postfetch: [
      ({ dispatch }) => {
        dispatch(setListingLoading(false));
      },
    ],
  },
  updateSurveyNotes: {
    url: '/survey_listings/:id/update_client_notes/',
    options: {
      method: 'POST',
    },
  },
  deleteTenantPhoto: {
    url: '/survey_listings/:id/delete_tenant_photo/',
    options: {
      method: 'POST',
    },
  },
};
