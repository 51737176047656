import React, { useCallback, useEffect, useState } from 'react';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';
import { withStyles, styled, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import lodash from 'lodash';
import { surveyBuildingCommentApi } from '~/legacy/fetchApi';
import { prepareFiles, IsPreviewContext } from '~/legacy/utils';
import {
  Loading,
  CameraIcon2,
  SquareIconButton,
  TextLink,
  File,
} from '~/legacy/components';

// Wrap the children with an input that allows for selecting files for upload
const UploadFileButton = ({ setFiles, icon }) => {
  const inputRef = React.useRef(null);
  const { isPreview } = React.useContext(IsPreviewContext);

  const handleInputClick = useCallback(
    () => (!isPreview && inputRef.current ? inputRef.current.click() : null),
    [isPreview, inputRef]
  );
  return (
    <SquareIconButton onClick={handleInputClick}>
      {icon}
      <input
        ref={inputRef}
        accept="image/jpeg,image/png,image/webp"
        type="file"
        hidden
        multiple
        onChange={(event) => {
          setFiles(prepareFiles(event.target.files));
        }}
      />
    </SquareIconButton>
  );
};

const ImageContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignSelf: 'start',
  marginBottom: '-8px',
});

const NEW_COMMENT_PHOTOS_EMPTY_STATE = {};
// Sort of a hack but we do this to prevent the comment text from going past the buttons.
const MARGIN_BETWEEN_TEXT_AND_BUTTONS_PX = 47;
const BUTTONS_WIDTH_PX = 56;
export const BuildingActivityCommentInput = withStyles({
  root: {
    paddingTop: '24px',
  },
  activityMessageInput: {
    height: 'fit-content',
    paddingRight: '24px',
    paddingLeft: '27px',
    paddingBottom: '20px',
  },
  activityMessageInputHasImages: {
    flexDirection: 'column',
  },
  adornment: {
    marginRight: '-9px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  image: {
    height: '84px',
    width: '84px',
    marginRight: '8px',
    marginBottom: '8px',
  },
  filesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
    paddingTop: '5px',
  },
  inputNoImages: {
    alignSelf: 'start',
    paddingBottom: '0px',
    marginRight: `${MARGIN_BETWEEN_TEXT_AND_BUTTONS_PX}px`,
  },
  inputwithImages: {
    alignSelf: 'center',
    paddingBottom: '16px',
    marginRight: `${MARGIN_BETWEEN_TEXT_AND_BUTTONS_PX + BUTTONS_WIDTH_PX}px`,
    width: `calc(100% - ${
      MARGIN_BETWEEN_TEXT_AND_BUTTONS_PX + BUTTONS_WIDTH_PX
    }px)`,
  },
  cameraIcon: {
    color: '#111',
  },
  addText: {
    marginLeft: '20px',
  },
})(
  ({
    classes,
    surveyId,
    buildingId,
    surveyBuildingId,
    setNewActivityMessage,
  }) => {
    // New comment content for the activity feed, allowing a user to create a new comment
    const theme = useTheme();
    const { isPreview } = React.useContext(IsPreviewContext);
    const [newComment, setNewComment] = useState('');
    const [newCommentPhotos, setNewCommentPhotos] = useState(
      NEW_COMMENT_PHOTOS_EMPTY_STATE
    );
    // Whether or not we are currently creating a new comment
    const [creatingNewComment, setCreatingNewComment] = useState(false);
    // Whether or not there's missing required info for the new comment input
    const [newCommentInputError, setNewCommentInputError] = useState(false);

    // Whether or not the user has added enough info for the a new message
    const requiredInputForNewMessage = !!(
      newComment || Object.keys(newCommentPhotos).length > 0
    );

    const createNewMessage = () => {
      // Create a new message by sending it through to the backend
      if (requiredInputForNewMessage) {
        setCreatingNewComment(true);
        surveyBuildingCommentApi
          .createComment(surveyId, buildingId, {
            text: newComment,
            survey_building: surveyBuildingId,
            photos: newCommentPhotos
              ? Object.values(newCommentPhotos).map((v) => v.id)
              : null,
            colors: theme.palette.primary,
          })
          .then(([, commentResponse]) => {
            if (commentResponse) {
              // Clear the message fields
              setNewComment('');
              setNewCommentPhotos(NEW_COMMENT_PHOTOS_EMPTY_STATE);
              setNewActivityMessage(commentResponse);
            }
          })
          .finally(() => {
            setCreatingNewComment(false);
          });
      } else {
        setNewCommentInputError(true);
      }
    };

    useEffect(() => {
      // Whenever the user enters text / photo / attachment, lets clear the error text
      if (requiredInputForNewMessage) {
        setNewCommentInputError(false);
      }
    }, [requiredInputForNewMessage]);

    // TODO
    const handleDeletePhoto = (fileKey) =>
      setNewCommentPhotos((currentFiles) =>
        lodash.omit(currentFiles, [fileKey])
      );

    const newCommentPhotosEntries = newCommentPhotos
      ? Object.entries(newCommentPhotos)
      : [];
    const hasImages =
      newCommentPhotosEntries && newCommentPhotosEntries.length > 0;

    return (
      <div className={classes.root}>
        <FormControl variant="filled" fullWidth>
          <OutlinedInput
            id="add-comment"
            placeholder="Add a comment"
            type="text"
            multiline
            className={clsx(
              classes.activityMessageInput,
              hasImages ? classes.activityMessageInputHasImages : ''
            )}
            classes={{
              input: hasImages
                ? classes.inputwithImages
                : classes.inputNoImages,
            }}
            value={newComment}
            onChange={(event) => setNewComment(event.target.value)}
            disabled={creatingNewComment}
            error={newCommentInputError}
            endAdornment={
              <InputAdornment
                position="start"
                // Unfortunately the easiest way to override these styles as the classes applied by Mui are hard to predict / override
                style={{
                  alignItems: hasImages ? 'end' : '',
                  alignSelf: hasImages ? 'start' : '',
                  height: hasImages ? '100%' : '',
                  maxHeight: hasImages ? '100%' : '',
                  width: hasImages ? '100%' : '',
                  marginTop: hasImages ? '0' : 'auto',
                  marginRight: hasImages ? '0' : '',
                  marginBottom: hasImages ? '0' : '6px',
                }}
              >
                {!!hasImages && (
                  <ImageContainer>
                    {newCommentPhotosEntries.map(([key, file]) => {
                      return (
                        <div className={classes.image} key={`uploaded-${key}`}>
                          <File
                            endpoint="survey_building_comment_photos"
                            file={file}
                            updateFile={(newFile) =>
                              setNewCommentPhotos((p) => ({
                                ...p,
                                [key]: { ...newFile },
                              }))
                            }
                            showAsThumbnail
                            deleteFile={() => handleDeletePhoto(key)}
                          />
                        </div>
                      );
                    })}
                  </ImageContainer>
                )}
                <div
                  style={{
                    display: 'flex',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    marginBottom: hasImages ? '-8px' : '',
                  }}
                >
                  <UploadFileButton
                    className={classes.adornment}
                    icon={<CameraIcon2 className={classes.cameraIcon} />}
                    edge="end"
                    disabled={creatingNewComment}
                    setFiles={(newFiles) => {
                      const newPhotos = {};
                      Object.entries(newFiles).forEach(([uuid, file]) => {
                        if (
                          ['image/jpeg', 'image/png', 'image/webp'].includes(
                            file.type
                          )
                        ) {
                          newPhotos[uuid] = file;
                        }
                      });
                      setNewCommentPhotos((p) => ({ ...p, ...newPhotos }));
                    }}
                  />
                  <TextLink
                    color="primary"
                    disabled={creatingNewComment}
                    onClick={!isPreview ? createNewMessage : () => {}}
                    className={classes.addText}
                  >
                    Add
                  </TextLink>
                  <Loading
                    size={24}
                    isLoading={creatingNewComment}
                    className={classes.buttonProgress}
                  />
                </div>
              </InputAdornment>
            }
          />
          {!!newCommentInputError && (
            <FormHelperText
              error
              id="send-message-error"
              key="send-message-error"
            >
              You must include a message, attachment, or photo
            </FormHelperText>
          )}
        </FormControl>
      </div>
    );
  }
);
