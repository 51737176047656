import { toast } from '~/legacy/utils/notifications';

const succesfulCopyToast = (skipToast) => {
  if (!skipToast) {
    toast('Successfuly copied', {
      appearance: 'success',
    });
  }
};

const failedCopyToast = (skipToast) => {
  if (!skipToast) {
    toast('Something went wrong.', {
      appearance: 'warning',
    });
  }
};

const copyTextToClipboard = (text, skipToast = false) => {
  if (!navigator.clipboard) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        succesfulCopyToast(skipToast);
      }
    } catch (err) {
      failedCopyToast(skipToast);
    }
    document.body.removeChild(textArea);

    return;
  }
  navigator.clipboard
    .writeText(text)
    .then(() => succesfulCopyToast(skipToast))
    .catch(() => failedCopyToast(skipToast));
};

export default copyTextToClipboard;
