import { createVector2, scaleVector2 } from './math'

// const pages = {
//   tabloid: createPageSize(11, 17),
//   letter: createPageSize(8.5, 11),
// }

export const createPageSize = (x = 8.5, y = 11) => {
  return scaleVector2(createVector2(x, y), 72)
}

// export const createStandardPageSize = (name, landscape) => {
//   const size = pages[name] || pages.letter
//   return landscape ? flipVector2(size) : size
// }
