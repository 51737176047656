import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { UploadIcon } from '~/legacy/components/svgs'
import { useApiHelper } from '~/legacy/utils/hooks'
import { uploadFile } from '~/legacy/utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  iconContainer: {
    height: '48px',
    width: '48px',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    marginRight: theme.spacing(2),
  },
  icon: {
    color: '#666666',
  },
  input: {
    display: 'none',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}))

const FileExtractorInput = ({
  onExtracted,
  isUserInBuildout,
  surveyId,
  setParentLoading = () => {},
  setUploadedCsvFile = () => {},
}) => {
  const apiHelper = useApiHelper()
  const classes = useStyles()

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    handleSubmit(selectedFile).catch((error) => {
      console.error('Error processing property data:', error)
    })
  }

  const handleSubmit = async (selectedFile) => {
    if (!selectedFile) {
      console.error('No file selected')
      return
    }

    setParentLoading(true)

    // Upload the CSV file to S3 (via webapp)
    uploadFile(
      selectedFile,
      'bulk_import_pdf_file',
      { survey_id: surveyId },
      true
    ).then((result) => {
      if (result && result.data && result.data.file) {
        // FIXME: I'm reusing the same state, but would be better to have a separate variable
        // This comes from BulkImportHooks.jsx
        setUploadedCsvFile(result.data.file)
      }
    })

    try {
      // Upload the file
      const uploadResult = await apiHelper.uploadPropertyAsset(selectedFile)
      const { filename } = uploadResult

      // Extract data from the uploaded file
      const extractedData = await apiHelper.extractPropertyData({ filename })
      onExtracted(extractedData)
    } catch (error) {
      console.error('Error processing property data:', error)
    } finally {
      setParentLoading(false)
    }
  }

  const openFileDialog = () => {
    document.getElementById('file-input').click()
  }

  return (
    <div className={classes.root}>
      <div className={classes.iconContainer} onClick={openFileDialog}>
        <UploadIcon className={classes.icon} />
      </div>
      <div className={classes.content}>
        <Typography variant="body1">
          Extract 
          {' '}
          {isUserInBuildout ? 'building' : 'space'}
          {' '}
data from an image
          or PDF.
        </Typography>
        <input
          accept=".pdf,.jpg,.jpeg,.png"
          className={classes.input}
          id="file-input"
          type="file"
          onChange={handleFileChange}
        />
      </div>
    </div>
  )
}

export default FileExtractorInput
