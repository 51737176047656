import React, { useCallback, useState } from 'react';
import { bindMenu } from 'material-ui-popup-state/hooks';
import { InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import {
  ClearTextInputSvg,
  useRemoveSpaceFromSurveyMenuItem,
} from '~/legacy/components';
import { Menu } from '~/legacy/components/menus';
import { TextField } from '~/legacy/components/themeComponents';

const StyledMenu = withStyles({
  paper: {
    width: '240px',
    marginRight: '22px',
  },
})(Menu);

const StyledTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#e0e0e0', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#666', // Solid underline on hover
    },
  },
})(TextField);

const EditSpaceMenu = withStyles({
  textFieldContainer: {
    padding: '12px 14px',
  },
  clearTextInputIcon: {
    height: '16px',
    cursor: 'pointer',
    marginRight: '-5px',
    color: '#e0e0e0',
  },
})(
  ({
    classes,
    menuState,
    spaceName,
    updateSpaceName,
    RemoveSpaceFromSurveyMenuItemComponent,
  }) => {
    const [name, setName] = useState(spaceName || '');

    const debouncedUpdateSpaceData = useCallback(
      debounce(
        (newData, updateSpaceNameLocal) => updateSpaceNameLocal(newData),
        1000
      ),
      []
    );

    const _updateSpaceName = (newName) => {
      setName(newName);
      debouncedUpdateSpaceData(newName, updateSpaceName);
    };

    return (
      <StyledMenu
        {...bindMenu(menuState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <div
          className={classes.textFieldContainer}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <StyledTextField
            value={name}
            label="Space Name"
            placeholder="Add Space Name"
            onChange={(e) => _updateSpaceName(e.target.value)}
            variant="standard"
            forceLabelAsLabel
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ClearTextInputSvg
                    className={classes.clearTextInputIcon}
                    onClick={() => {
                      setName('');
                    }}
                    onMouseDown={(event) => event.preventDefault()}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {RemoveSpaceFromSurveyMenuItemComponent}
      </StyledMenu>
    );
  }
);

export const useEditSpaceMenu = ({
  menuState,
  surveyListingId,
  spaceName,
  updateSpaceName,
  deleteSurveyListing,
  buildingName,
}) => {
  const {
    RemoveSpaceFromSurveyMenuItemComponent,
    ConfirmRemoveSpaceFromSurveyModalComponent,
  } = useRemoveSpaceFromSurveyMenuItem({
    surveyListingId,
    spaceName,
    buildingName,
    deleteSurveyListing,
    handleMenuClose: () => menuState.close(),
  });

  const EditSpaceMenuComponent = (
    <EditSpaceMenu
      menuState={menuState}
      spaceName={spaceName}
      updateSpaceName={updateSpaceName}
      RemoveSpaceFromSurveyMenuItemComponent={
        RemoveSpaceFromSurveyMenuItemComponent
      }
    />
  );

  return {
    EditSpaceMenuComponent,
    ConfirmRemoveSpaceFromSurveyModalComponent,
  };
};
