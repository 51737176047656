import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  CloseIcon,
  SimpleInputSelect,
  Spacer,
  SquareIconButton,
  TextInput,
} from '~/legacy/components'
import { ModalOverlay } from '~/legacy/components/ModalOverlay'
import { emptyProjectDetail } from '~/legacy/fixtures'
import { styled } from '~/legacy/utils/styleHelpers'

export const CreateProject = ({ onCancel }) => {
  const [name, setName] = useState('')
  const [client, setClient] = useState('')
  const [logo, setLogo] = useState('/prototype/Logo1.png')
  const history = useHistory()

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  return (
    <ModalOverlay onCancel={onCancel}>
      <Container>
        <Header>
          <Title>Create project</Title>
          <SquareIconButton>
            <CloseIcon onClick={onCancel} />
          </SquareIconButton>
        </Header>
        <Spacer height={32} />
        <TextInput label="Name" value={name} onChange={handleNameChange} />
        <Spacer height={28} />
        <SimpleInputSelect
          label="Client Company"
          options={[
            { value: 'AV Engineering', image: '/prototype/Logo1.png' },
            { value: 'Insight Technologies', image: '/prototype/Logo2.png' },
            { value: 'Unified Logistics', image: '/prototype/Logo3.png' },
          ]}
          onChange={(event) => setClient(event.target.value)}
          onCreate={() => console.log('create')}
          onSelect={(option) => {
            console.log(option)
            setLogo(option.image)
            setClient(option.value)
          }}
          value={client}
        >
          Create Client Company
        </SimpleInputSelect>
        <Spacer height={40} />
        <Footer>
          <Button outlined onClick={onCancel}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              emptyProjectDetail.name = name
              emptyProjectDetail.company = client
              emptyProjectDetail.profiles = [
                { image: '/prototype/Profile1.png' },
                { image: logo },
              ]
              history.push('/preview/projects/1')
            }}
          >
            Create
          </Button>
        </Footer>
      </Container>
    </ModalOverlay>
  )
}

const Container = styled('div', {
  background: 'white',
  borderRadius: '6px',
  padding: '24px',
  color: '#111',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  boxShadow: '0px 0px 50px 10px rgba(0, 0, 0, 0.25)',
  minWidth: '548px',
  zIndex: 10000001,
})

const Header = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Footer = styled('div', {
  display: 'flex',
  gap: '12px',
  justifyContent: 'flex-end',
})

const Title = styled('div', {
  fontFamily: 'Inter',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '28px',
})

const Button = styled('div', ['outlined'], (props) => {
  const color = props.get('theme.palette.primary.main')
  return {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '44px',
    letterSpacing: '0.1px',
    textAlign: 'center',
    minWidth: '144px',
    color: props.when('outlined', color, 'white'),
    backgroundColor: props.when('outlined', 'white', color),
    borderRadius: '4px',
    border: props.when('outlined', `1px solid ${color}`),
    cursor: 'pointer',
    userSelect: 'none',
  }
})
