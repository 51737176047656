import React  from 'react';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core';
import Table from '../Table';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mapContainer: {
    height: props => `${props.mapHeight}px`,
    width: props => `${props.mapWidth}px`,
    marginTop: '24px',
    marginLeft: '24px',
    paddingBottom: 0,
  },
  map: {
    objectFit: 'cover',
    height: '100%',
  },
  table: {
    height: props => `min(100%, ${props.tableHeight}px)`,
  },
});

const getMapHeight = listings => {
  const { length } = listings;
  let height = 0;
  if (length <= 10) {
    height = 420;
  } else if (length <= 20) {
    height = 370;
  } else if (length <= 27) {
    height = 250;
  } else if (length <= 31) {
    height = 170;
  }

  return height;
};

function MapAndBuildingsPageLayout1({ buildings, buildingsTableHeaders, buildingsTableData, settings }) {
  const mapWidth = 564;
  const mapHeight = getMapHeight(buildings);
  const tableHeight = (buildings.length + 1) * 32;
  const classes = useStyles({
    mapHeight,
    mapWidth,
    tableHeight,
  });

  const theme = useTheme();

  const mapBoxUrlBase =
    'https://api.mapbox.com/styles/v1/mapbox/light-v9/static';
  const pins = [];
  const color = theme.palette.primary.main.substring(1);
  buildings.forEach((building, index) => {
    pins.push(
      `pin-s-${index + 1}+${color}(${building.longitude},${
        building.latitude
      })`
    );
  });
  const mapBoxUrl = `${mapBoxUrlBase}/${pins.join(
    ','
  )}/auto/${mapWidth}x${mapHeight}@2x?access_token=pk.eyJ1IjoiaW5wZW5kaW8iLCJhIjoiY2pwZ3EydXFoMDJucTN2cXd1eHU4Mnl3MiJ9.pP_95bkUD0V92Ye-GzeGGw`;

  return (
    <div className={classes.container}>
      {settings.showMap && (
        <div className={classes.mapContainer}>
          <img className={classes.map} src={mapBoxUrl} alt="map" />
        </div>
      )}
      <Table
        headers={buildingsTableHeaders}
        data={buildingsTableData}
        classes={{
          table: classes.table,
        }}
      />
    </div>
  );
}

export default MapAndBuildingsPageLayout1;
