import React, { memo, useCallback } from 'react';
import { IconButton } from '@material-ui/core';

import { prepareFiles } from '~/legacy/utils';
import { SquareIconButton } from './buttons';
import { TextLink } from './themeComponents';

export const HiddenInputFileUpload = React.forwardRef(({ setFiles }, ref) => {
  return (
    <input
      ref={ref}
      type="file"
      hidden
      multiple
      onChange={(event) => {
        setFiles(prepareFiles(event.target.files));
      }}
    />
  );
});

// Wrap the children with an input that allows for selecting files for upload
const FileUploadWrapperRaw = ({ children, setFiles, ...otherProps }) => {
  return (
    <div>
      <IconButton variant="contained" component="label" {...otherProps}>
        {children}
        <HiddenInputFileUpload setFiles={setFiles} />
      </IconButton>
    </div>
  );
};
export const FileUploadWrapper = memo(FileUploadWrapperRaw);

const useUploadInput = () => {
  const inputRef = React.useRef(null);
  const handleInputClick = useCallback(
    () => (inputRef.current ? inputRef.current.click() : null),
    [inputRef]
  );
  return { inputRef, handleInputClick };
};

// Wrap the children with an input that allows for selecting files for upload
const SquareIconFileUploadWrapperRaw = ({
  children,
  setFiles,
  ...otherProps
}) => {
  const { inputRef, handleInputClick } = useUploadInput();
  return (
    <div>
      <SquareIconButton onClick={handleInputClick} {...otherProps}>
        {children}
        <HiddenInputFileUpload setFiles={setFiles} ref={inputRef} />
      </SquareIconButton>
    </div>
  );
};
export const SquareIconFileUploadWrapper = memo(SquareIconFileUploadWrapperRaw);

const TextLinkFileUploadWrapperRaw = ({ children, setFiles }) => {
  const { inputRef, handleInputClick } = useUploadInput();
  return (
    <TextLink onClick={handleInputClick}>
      {children}
      <HiddenInputFileUpload setFiles={setFiles} ref={inputRef} />
    </TextLink>
  );
};
export const TextLinkFileUploadWrapper = memo(TextLinkFileUploadWrapperRaw);
