import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Button } from '~/legacy/components';
import { ConfirmModalTitle } from '~/legacy/components/modals';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    paddingTop: '4px',
    outline: 'none',
    overflowY: 'hidden',
  },
  actionButton: {
    marginLeft: '12px',
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  optionTextContainer: {
    marginBottom: '40px',
  },
});

/**
 * A confirm modal
 *
 * @param {element} text - Text element to display in the modal. Note that this
 *   will be nested inside of a Typography, so be sure to properly mark
 *   nested Typography's with a component prop as to avoid improper HTML nesting warnings.
 */
function ConfirmModal({
  onClose,
  onConfirm,
  title,
  text,
  confirmButtonLabel,
  loading = false,
}) {
  const classes = useStyles();

  return (
    <div className={classes.content} tabIndex={-1}>
      <ConfirmModalTitle onClose={onClose} title={title} />

      <div className={classes.optionTextContainer}>
        <Typography variant="body1" component="span">
          {text}
        </Typography>
      </div>

      <div className={classes.optionsContainer}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={classes.actionButton}
          color="primary"
          onClick={onConfirm}
          loading={loading}
          disabled={loading}
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
}
ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default ConfirmModal;
