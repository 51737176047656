export const ACTIONS = {
  TOGGLE_INTEREST_POINTS_LAYERS: '(.)toggle_interest_points_layers(.)',
  ZOOM_IN: '(.)map_zoom_in(.)',
  ZOOM_OUT: '(.)map_zoom_out(.)',
  SET_ZOOM: '(.)map_set_zoom(.)',
  SET_DRAWING: '(.)map_set_drawing(.)',
  SET_POLYGON: '(.)map_set_polygon(.)',
  SET_CIRCLE: '(.)map_set_circle(.)',
  DELETE_DRAWING: '(.)map_delete_drawing(.)',
  SET_POINTS: '(.)map_set_points(.)',
  ADD_POINT: '(.)map_add_points(.)',
  REMOVE_POINT_BY_INDEX: '(.)map_set_points(.)',
  SET_BOUNDS: '(.)map_set_bounds(.)',
  TOGGLE_3D: '(.)map_toggle_3d(.)',
  REVIVE_FROM_RAW_DATA: '(.)map_revive_from_raw_data(.)',
  CLEAR_REVIVE_DATA: '(.)map_clear_revive_data(.)',
};

const initialState = {
  interestPoints: [],
  zoom: 11,
  items: [],
  drawing: null,
  poligons: {},
  circles: {},
  points: [],
  bounds: {},
  pitch: 0,
  bearing: 0,
  revive: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_INTEREST_POINTS_LAYERS: {
      const interestPoints = [...state.interestPoints];
      const index = interestPoints.indexOf(action.payload);
      if (index === -1) interestPoints.push(action.payload);
      else interestPoints.splice(index, 1);
      return { ...state, interestPoints };
    }
    case ACTIONS.ZOOM_IN:
      return { ...state, zoom: state.zoom + 1 };
    case ACTIONS.ZOOM_OUT:
      return { ...state, zoom: state.zoom - 1 };
    case ACTIONS.SET_ZOOM:
      return { ...state, zoom: action.payload };
    case ACTIONS.SET_DRAWING:
      return { ...state, drawing: action.payload };
    case ACTIONS.SET_POLYGON:
      return {
        ...state,
        drawing: null,
        poligons: { ...state.poligons, ...action.payload },
      };
    case ACTIONS.SET_CIRCLE:
      return {
        ...state,
        drawing: null,
        circles: { ...state.circles, ...action.payload },
      };
    case ACTIONS.DELETE_DRAWING: {
      const { circles, poligons } = { ...state };
      const id = action.payload;
      if (circles[id]) delete circles[id];
      else if (poligons[id]) delete poligons[id];
      return {
        ...state,
        circles,
        poligons,
        drawing: null,
      };
    }
    case ACTIONS.SET_POINTS:
      return { ...state, points: action.payload };
    case ACTIONS.SET_BOUNDS:
      return { ...state, bounds: action.payload };
    case ACTIONS.ADD_POINT: {
      const points = [...state.points];
      points.push(action.payload);
      return { ...state, points };
    }
    case ACTIONS.REMOVE_POINT_BY_INDEX: {
      const { points } = { ...state };
      points.splice(action.payload, 1);
      return { ...state, points };
    }
    case ACTIONS.TOGGLE_3D: {
      const { pitch, bearing } = state;
      if (pitch > 0 || bearing > 0)
        return { ...state, bearing: 0, pitch: 0, zoom: 11 };
      return { ...state, bearing: 60, pitch: 60, zoom: 15 };
    }
    case ACTIONS.REVIVE_FROM_RAW_DATA: {
      const revive = [...state.revive];
      action.payload.forEach(rp => {
        if (rp) revive.push(rp);
      });
      return { ...state, revive };
    }
    case ACTIONS.CLEAR_REVIVE_DATA:
      return { ...state, revive: [] };
    default:
      return state;
  }
}

export const mapActions = {
  toggleInterestPoint: payload => ({
    type: ACTIONS.TOGGLE_INTEREST_POINTS_LAYERS,
    payload,
  }),
  zoomIn: () => ({ type: ACTIONS.ZOOM_IN }),
  zoomOut: () => ({ type: ACTIONS.ZOOM_OUT }),
  setZoom: payload => ({ type: ACTIONS.SET_ZOOM, payload }),
  setDrawing: payload => ({ type: ACTIONS.SET_DRAWING, payload }),
  setPolygon: payload => ({ type: ACTIONS.SET_POLYGON, payload }),
  setCircle: payload => ({ type: ACTIONS.SET_CIRCLE, payload }),
  deleteDrawing: payload => ({ type: ACTIONS.DELETE_DRAWING, payload }),
  setPoints: payload => ({ type: ACTIONS.SET_POINTS, payload }),
  addPoint: payload => ({ type: ACTIONS.ADD_POINT, payload }),
  removePointByIndex: payload => ({
    type: ACTIONS.REMOVE_POINT_BY_INDEX,
    payload,
  }),
  setBounds: payload => ({ type: ACTIONS.SET_BOUNDS, payload }),
  toggle3D: () => ({ type: ACTIONS.TOGGLE_3D }),
  reviveFromRawData: payload => ({
    type: ACTIONS.REVIVE_FROM_RAW_DATA,
    payload,
  }),
  clearRevive: () => ({ type: ACTIONS.CLEAR_REVIVE_DATA }),
};
