import { InputAdornment, Link, ThemeProvider } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { GoogleMap } from '@react-google-maps/api';
import clsx from 'clsx';
import lodash from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserData } from '~/legacy/store';
import {
  generateSkinnedMuiTheme,
  SkinContext,
  SnackbarUtils,
} from '~/legacy/utils';
import { useApiHelper } from '~/legacy/utils/hooks';
import {
  AutocompleteChip,
  Button,
  MapMarker,
  RawImage,
  TextField,
  TextLink,
  Typography,
} from '~/legacy/components';
import { roadMapStyles } from './Map/GoogleMaps/style';

// TODO: Get from the broker company location when implemented
const CENTER = {
  lat: 42.3601,
  lng: -71.0589,
};

const getMapLatLngRange = (map) => {
  const mapBounds = map.getBounds();
  const southWest = mapBounds.getSouthWest();
  const northEast = mapBounds.getNorthEast();
  const latMin = southWest.lat();
  const latMax = northEast.lat();
  const lngMin = southWest.lng();
  const lngMax = northEast.lng();
  const latRange = latMax - latMin;
  const lngRange = lngMax - lngMin;
  return {
    latRange,
    lngRange,
  };
};

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

const getRandomDimInMapBounds = ({
  center,
  range,
  percentMin = 0.1,
  percentMax = 0.7,
}) => {
  return (
    center +
    (range / 2) *
      getRandomArbitrary(percentMin, percentMax) *
      (Math.random() < 0.5 ? -1 : 1)
  );
};

const getRandomPointInMapBounds = ({
  latCenter,
  latRange,
  lngCenter,
  lngRange,
}) => {
  return new google.maps.LatLng(
    getRandomDimInMapBounds({ center: latCenter, range: latRange }),
    getRandomDimInMapBounds({ center: lngCenter, range: lngRange })
  );
};

const isHexColorValid = (colorString) => {
  const re = new RegExp('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$');
  return colorString.match(re);
};

const validateColor = (colorString) => {
  return !colorString || isHexColorValid(colorString);
};

const prepareToSaveString = (stringVal) => {
  return stringVal ? stringVal.trim() : '';
};

const sanitizeColor = (colorString) => {
  return colorString.trim().toUpperCase();
};

const requireNotEmpty = (stringVal) => {
  return !!stringVal;
};

const passthrough = (v) => v;

const isSkinFieldDisabled = (company) => {
  return !!company.is_leaseup_company;
};

const getColorAdornment = (color) => {
  return (
    <InputAdornment position="start">
      <div
        style={{
          width: '16px',
          height: '16px',
          borderRadius: '4px',
          backgroundColor: color,
        }}
      />
    </InputAdornment>
  );
};

// The string fields of the company
const COMPANY_STRING_FIELDS = [
  {
    field: 'name',
    label: 'Brokerage Name',
    errorMessage: 'Please enter a name.',
    validate: requireNotEmpty,
  },
  {
    field: 'domain',
    label: 'Website',
    errorMessage: '',
    validate: passthrough,
  },
];
const COMPANY_FIELDS_SET = new Set(
  COMPANY_STRING_FIELDS.map((field) => field.field)
);

// The skin colors for company
const SKIN_COLOR_FIELDS = [
  { field: 'brand_color', label: 'Brand Color' },
  { field: 'map_pin_fill_color', label: 'Map Pin Color' },
  { field: 'map_pin_stroke_color', label: 'Map Pin Stroke Color' },
  {
    field: 'map_pin_highlighted_fill_color',
    label: 'Highlighted Map Pin Color',
  },
  {
    field: 'map_pin_highlighted_stroke_color',
    label: 'Highlighted Map Pin Stroke Color',
  },
];
const SKIN_COLOR_FIELDS_SET = new Set(
  SKIN_COLOR_FIELDS.map((field) => field.field)
);

const updateDataDraft = ({
  field,
  newValue,
  hasError,
  setNewDraft,
  currentDraft,
  setNewDraftErrors,
  currentDraftErrors,
}) => {
  const newError = {};
  if (hasError !== undefined) {
    newError.error = hasError;
  }
  setNewDraft({
    ...currentDraft,
    [field]: newValue || null,
  });
  // Set an error on this draft field
  setNewDraftErrors(
    lodash.merge({}, currentDraftErrors, { [field]: newError })
  );
};

const updateData = (
  field,
  newData,
  setNewData,
  upateDraftData,
  currentData
) => {
  const newDataFinal = newData || null;
  setNewData({
    ...currentData,
    [field]: newDataFinal,
  });
  // Clear the error on this draft field completely
  upateDraftData({
    field,
    newValue: newDataFinal,
    hasError: false,
  });
};

// Get only the fields we want to save from the company
const getDefaultNewCompanyFields = (company) => {
  return Object.fromEntries(
    Object.entries(company).filter(([key]) => COMPANY_FIELDS_SET.has(key))
  );
};
// Get only the fields we want to save from the company skin colors
const getDefaultNewSkinColorFields = (companySkin) => {
  return Object.fromEntries(
    Object.entries(companySkin).filter(([key]) =>
      SKIN_COLOR_FIELDS_SET.has(key)
    )
  );
};

const SectionTitle = withStyles({
  sectionTitle: {
    marginTop: '40px',
    marginBottom: '20px',
  },
})(({ children, classes }) => {
  return (
    <Typography variant="sectionTitle" className={classes.sectionTitle}>
      {children}
    </Typography>
  );
});

// A Google map showing a location and preview pins, to demonstrate colors
const NUMBER_PREVIEW_MARKERS = 5;
const AdminThemedGoogleMap = React.memo(() => {
  // The map and preview markers
  const [map, setMap] = useState(null);
  const [previewMarkers, setPreviewMarkers] = useState([]);

  return (
    <GoogleMap
      center={CENTER}
      clickableIcons={false}
      mapContainerStyle={{
        height: '380px',
        width: '100%',
        overflow: 'hidden',
      }}
      options={{
        disableDoubleClickZoom: true,
        scrollwheel: false,
        fullscreenControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        styles: roadMapStyles,
        draggable: false,
      }}
      zoom={15}
      onLoad={(newMap) => {
        setMap(newMap);
      }}
      onIdle={() => {
        // When the map is prepared, lay down the sample markers. We plot 5 random
        if (map) {
          const { latRange, lngRange } = getMapLatLngRange(map);
          setPreviewMarkers(
            Array.from({ length: NUMBER_PREVIEW_MARKERS }, (element, index) => {
              return {
                point: getRandomPointInMapBounds({
                  latCenter: CENTER.lat,
                  latRange,
                  lngCenter: CENTER.lng,
                  lngRange,
                }),
                id: index,
              };
            })
          );
        }
      }}
    >
      <MapMarker
        isHighlighted
        position={{
          lat: CENTER.lat,
          lng: CENTER.lng,
        }}
      />
      {previewMarkers.map((marker) => (
        <MapMarker
          key={marker.id}
          position={{
            lat: marker.point.lat(),
            lng: marker.point.lng(),
          }}
        />
      ))}
    </GoogleMap>
  );
});

const ThemeTextField = withStyles({
  textField: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
})(({ classes, ...props }) => (
  <TextField className={classes.textField} {...props} />
));

// An editable input for a company text field
const CompanyFieldTextField = React.memo(
  ({
    fieldKey,
    triggeredSave,
    newCompanyFieldsDraftErrors,
    label,
    newCompanyFieldsDraft,
    updateField,
    updateFieldDraft,
    errorMessage,
    validate = (v) => v,
  }) => {
    const hasError =
      triggeredSave &&
      newCompanyFieldsDraftErrors[fieldKey] &&
      newCompanyFieldsDraftErrors[fieldKey].error;
    const draftValue = newCompanyFieldsDraft[fieldKey];

    return (
      <ThemeTextField
        key={fieldKey}
        error={!!hasError}
        helperText={hasError ? errorMessage : ''}
        label={label}
        value={draftValue || ''}
        onChange={(event) => {
          const newFieldValue = event.target.value || null;
          if (validate(newFieldValue)) {
            updateField(fieldKey, newFieldValue);
          } else {
            updateFieldDraft({
              field: fieldKey,
              newValue: newFieldValue,
              hasError: true,
            });
          }
        }}
      />
    );
  }
);

// An input for an editable company skin color
const SkinColorTextField = React.memo(
  ({
    skinFieldKey,
    label,
    triggeredSave,
    draftErrors,
    newSkinColorsDraft,
    updateSkin,
    updateSkinDraft,
    company,
  }) => {
    const disabled = !!isSkinFieldDisabled(company);
    const hasError =
      triggeredSave &&
      draftErrors[skinFieldKey] &&
      draftErrors[skinFieldKey].error;
    const draftValue = newSkinColorsDraft[skinFieldKey];

    return (
      <ThemeTextField
        key={skinFieldKey}
        error={!!hasError}
        helperText={hasError ? 'Please enter a valid HEX color.' : ''}
        disabled={!!disabled}
        label={label}
        value={draftValue || ''}
        onChange={(event) => {
          const newSkinValue = sanitizeColor(event.target.value || '') || null;
          if (validateColor(newSkinValue)) {
            updateSkin(skinFieldKey, newSkinValue);
          } else {
            updateSkinDraft({
              field: skinFieldKey,
              newValue: newSkinValue,
              hasError: true,
            });
          }
        }}
        InputProps={{
          startAdornment: getColorAdornment(draftValue),
        }}
      />
    );
  }
);

export const AdminThemeTab = withStyles({
  pageHorizontalPadding: {
    paddingLeft: '80px',
    paddingRight: '80px',
  },
  pageContentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  fieldsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  saveButton: {
    width: '100px',
    marginTop: '26px',
  },
  previewContainer: {
    width: '60%',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  previeContentContainer: {
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
  },
  previewAssetRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 0 24px 0',
    alignItems: 'center',
    overflow: 'auto',
  },
  previewItem: {
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewButton: {
    width: '140px',
  },
  infoContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '20px',
  },
  tabContainer: {
    height: 'fit-content',
    width: '100%',
  },
  visible: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '80px',
  },
  hidden: {
    display: 'none',
  },
  faviconImage: {
    width: '100%',
    height: '100%',
  },
})(
  React.memo(({ adminUser, selected = false, classes }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const apiHelper = useApiHelper();
    const { updateSkin: updateGlobalSkin } = useContext(SkinContext);

    const company = adminUser && adminUser.company ? adminUser.company : {};
    const companySkin = company.skin || {};

    // Validated copmany fields to be saved
    const [newCompanyFields, setNewCompanyFields] = useState(
      getDefaultNewCompanyFields(company)
    );
    // Company fields the user is modifying and visible on the page. Unvalidated.
    const [newCompanyFieldsDraft, setNewCompanyFieldsDraft] = useState({
      ...newCompanyFields,
    });
    // Errors in the draft fields
    const [newCompanyFieldsDraftErrors, setNewCompanyFieldsDraftErrors] =
      useState({});

    // Validated skin fields to be saved
    const [newSkinColors, setNewSkinColors] = useState({
      ...getDefaultNewSkinColorFields(companySkin),
    });
    const [newSkinFavicon, setNewSkinFavicon] = useState(
      companySkin.favicon || null
    );
    // Skin fields the user is modifying and visible on the page. Unvalidated
    const [newSkinColorsDraft, setNewSkinColorsDraft] = useState({
      ...newSkinColors,
    });
    // Errors in the draft fields
    const [newSkinDraftErrors, setNewSkinDraftErrors] = useState({});

    // New local theme from the modified skin
    const [newTheme, setNewTheme] = useState(theme);

    // The favicon for the skin
    const avatarUploadRef = useRef(null);
    const [isLoadingFavicon, setIsLoadingFavicon] = useState(false);
    const [hasErrorSavingFavicon, setHasErrorSavingFavicon] = useState(false);

    const [saving, setSaving] = useState(false);
    const [triggeredSave, setTriggeredSave] = useState(false);

    // If there are any errors with the input fields, colors, and favicon
    const hasAnyErrors = useMemo(() => {
      return (
        Object.values(newCompanyFieldsDraftErrors).find(
          (value) => value.error
        ) || Object.values(newSkinDraftErrors).find((value) => value.error)
      );
    }, [newCompanyFieldsDraftErrors, newSkinDraftErrors]);

    // Whether or not any of the field errors are being displayed to the user
    const showingAnyErrors = triggeredSave && hasAnyErrors;

    // Create a new version of the theme with the new skin. This is a local theme
    useEffect(() => {
      setNewTheme(generateSkinnedMuiTheme(newSkinColors));
    }, [newSkinColors]);

    // Refresh the fields when the company is updated
    useEffect(() => {
      const newData = getDefaultNewCompanyFields(company);
      setNewCompanyFields(newData);
      setNewCompanyFieldsDraft(newData);
    }, [company]);

    useEffect(() => {
      if (triggeredSave && !showingAnyErrors) {
        setTriggeredSave(false);
      }
    }, [triggeredSave, showingAnyErrors]);

    // Update the field draft
    const updateFieldDraft = ({ field, newValue, hasError }) => {
      updateDataDraft({
        field,
        newValue,
        hasError,
        setNewDraft: setNewCompanyFieldsDraft,
        currentDraft: newCompanyFieldsDraft,
        setNewDraftErrors: setNewCompanyFieldsDraftErrors,
        currentDraftErrors: newCompanyFieldsDraftErrors,
      });
    };

    // Update the skin draft
    const updateSkinDraft = ({ field, newValue, hasError }) => {
      updateDataDraft({
        field,
        newValue,
        hasError,
        setNewDraft: setNewSkinColorsDraft,
        currentDraft: newSkinColorsDraft,
        setNewDraftErrors: setNewSkinDraftErrors,
        currentDraftErrors: newSkinDraftErrors,
      });
    };

    // Update the local copy of the fields. We also reset the draft.
    const updateField = (field, newValue) => {
      updateData(
        field,
        newValue,
        setNewCompanyFields,
        updateFieldDraft,
        newCompanyFields
      );
    };

    // Update the local copy of the skin. We also reset the draft.
    const updateSkin = (field, newColor) => {
      updateData(
        field,
        newColor,
        setNewSkinColors,
        updateSkinDraft,
        newSkinColors
      );
    };

    // Save the company
    const onSave = () => {
      setTriggeredSave(true);
      if (!hasAnyErrors) {
        setSaving(true);
        apiHelper
          .updateCompany({
            companyId: company.id,
            companyDetails: {
              ...Object.fromEntries(
                Object.entries(newCompanyFields).map(([key, value]) => [
                  key,
                  prepareToSaveString(value),
                ])
              ),
              skin: {
                ...newSkinColors,
                favicon: newSkinFavicon,
              },
            },
          })
          .then(([response, data]) => {
            if (response.ok && data) {
              // Set the skin (context)
              updateGlobalSkin(data.skin);
              // Update the company
              dispatch(
                updateUserData({ company: lodash.merge({}, company, data) })
              );
              SnackbarUtils.success(
                'Succesfully updated brokerage. Please allow up to an hour for new favicon and colors to take effect for other users.',
                { autoHideDuration: 8000 }
              );
            }
          })
          .finally(() => setSaving(false));
      }
    };

    const getCompanyFieldTextField = (
      fieldKey,
      label,
      errorMessage,
      validate = (v) => v
    ) => {
      return (
        <CompanyFieldTextField
          key={fieldKey}
          fieldKey={fieldKey}
          triggeredSave={triggeredSave}
          newCompanyFieldsDraftErrors={newCompanyFieldsDraftErrors}
          label={label}
          updateField={updateField}
          updateFieldDraft={updateFieldDraft}
          updateSkinDraft={updateSkinDraft}
          errorMessage={errorMessage}
          newCompanyFieldsDraft={newCompanyFieldsDraft}
          validate={validate}
        />
      );
    };

    const getSkinColorTextFieldComponent = (skinFieldKey, label) => {
      return (
        <SkinColorTextField
          key={skinFieldKey}
          skinFieldKey={skinFieldKey}
          label={label}
          triggeredSave={triggeredSave}
          draftErrors={newSkinDraftErrors}
          newSkinColorsDraft={newSkinColorsDraft}
          updateSkin={updateSkin}
          updateSkinDraft={updateSkinDraft}
          company={company}
        />
      );
    };

    return (
      <div
        className={clsx(
          classes.tabContainer,
          selected ? classes.visible : classes.hidden
        )}
      >
        <div
          className={clsx(
            classes.pageHorizontalPadding,
            classes.pageContentContainer
          )}
        >
          <div className={classes.infoContainer}>
            <SectionTitle> Info </SectionTitle>

            <div className={classes.fieldsContainer}>
              {COMPANY_STRING_FIELDS.map((companyField) =>
                getCompanyFieldTextField(
                  companyField.field,
                  companyField.label,
                  companyField.errorMessage,
                  companyField.validate
                )
              )}
              <Link
                onClick={() => avatarUploadRef.current.click()}
                underline="none"
              >
                <ThemeTextField
                  error={!!hasErrorSavingFavicon}
                  helperText={
                    hasErrorSavingFavicon
                      ? 'There was an error uploading favicon, please try again.'
                      : ''
                  }
                  disabled={
                    !!(isLoadingFavicon || isSkinFieldDisabled(company))
                  }
                  label="Favicon"
                  value={newSkinFavicon ? newSkinFavicon.name : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                          }}
                        >
                          {newSkinFavicon ? (
                            <RawImage
                              imageFile={newSkinFavicon}
                              ImgProps={{
                                classes: {
                                  root: classes.faviconImage,
                                },
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  ref={avatarUploadRef}
                  accept="image/jpeg,image/png,image/webp"
                  type="file"
                  hidden
                  disabled={
                    !!(isLoadingFavicon || isSkinFieldDisabled(company))
                  }
                  onChange={(event) => {
                    setIsLoadingFavicon(true);
                    const newFile = event.target.files[0];
                    if (newFile) {
                      apiHelper
                        .updloadCompanySkinFaviconImage({
                          file: newFile,
                        })
                        .then((res) => {
                          if (res.success && res.file) {
                            setNewSkinFavicon(res.file);
                            setHasErrorSavingFavicon(false);
                          } else {
                            setHasErrorSavingFavicon(true);
                            SnackbarUtils.error('Unable to upload favicon.');
                          }
                        })
                        .catch(() => setHasErrorSavingFavicon(true))
                        .finally(() => setIsLoadingFavicon(false));
                    } else {
                      setIsLoadingFavicon(false);
                    }
                  }}
                />
              </Link>
            </div>

            <SectionTitle> Colors </SectionTitle>

            <div className={classes.fieldsContainer}>
              {SKIN_COLOR_FIELDS.map((colorField) =>
                getSkinColorTextFieldComponent(
                  colorField.field,
                  colorField.label
                )
              )}
            </div>

            <Button
              className={classes.saveButton}
              onClick={onSave}
              color="primary"
              disabled={!!showingAnyErrors || !!saving}
              loading={!!saving}
            >
              Save
            </Button>
          </div>

          <div className={classes.previewContainer}>
            <SectionTitle> Preview </SectionTitle>

            <ThemeProvider theme={newTheme}>
              <div className={classes.previeContentContainer}>
                <AdminThemedGoogleMap />
                <div className={classes.previewAssetRow}>
                  <div className={classes.previewItem}>
                    <Button color="primary" className={classes.previewButton}>
                      Button
                    </Button>
                  </div>

                  <div className={classes.previewItem}>
                    <Button color="secondary" className={classes.previewButton}>
                      Button
                    </Button>
                  </div>

                  <div className={classes.previewItem}>
                    <TextLink> Text Link </TextLink>
                  </div>

                  <div className={classes.previewItem}>
                    <AutocompleteChip
                      className={classes.previewItem}
                      option="Amenity Tag"
                    />
                  </div>

                  <div className={classes.previewItem}>
                    <AutocompleteChip
                      className={classes.previewItem}
                      option="Amenity Tag"
                    />
                  </div>
                </div>
              </div>
            </ThemeProvider>
          </div>
        </div>
      </div>
    );
  })
);
