const ACTIONS = {
  EDIT_KEY_VALUE_STRING: 'addProject_edit_key_value_string',
  SET_EDIT_OBJECT: 'addProject_set_edit_object',
  RESET_STATE: 'addProject_reset_state',
};

const getInitialState = () => {
  return {
    address: '',
    address2: '',
    suite: '',
    suiteType: '',
    city: '',
    zipcode: '',
    state: '',
    propertyType: null,
    buildingSize: '',
    price: '',
    negotiable: false,
    vacancyType: null,
    size: '',
    leaseTerm: '',
    availability: '',
    availabilityDate: '',
    features: [],
    description: '',
    amenities: [],
    image: null,
    companyType: '',
    phone: '',
    active_search: {
      // hard coded name that will not be used but backend needs it
      name: 'hardcoded',
      search: {
        is_sqft_price: true,
      },
    },
    clients: [],
  };
};

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.EDIT_KEY_VALUE_STRING:
      return {
        ...state,
        [action.key]: action.value,
      };
    case ACTIONS.SET_EDIT_OBJECT:
      if (!action.payload) return { ...state };

      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.RESET_STATE:
      return getInitialState();
    default:
      return state;
  }
}

export const addProjectActions = {
  editKeyValueString: (key, value) => ({
    type: ACTIONS.EDIT_KEY_VALUE_STRING,
    key,
    value,
  }),
  setEditObject: payload => ({ type: ACTIONS.SET_EDIT_OBJECT, payload }),
  resetState: payload => ({ type: ACTIONS.RESET_STATE, payload }),
};
