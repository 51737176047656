import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Link, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'

import { useInView } from 'react-intersection-observer'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
  arrayMove,
  LISTING_READ_ONLY_FIELDS as ORIGINAL_LISTING_READ_ONLY_FIELDS,
  LISTING_READ_ONLY_FIELDS_TYPE,
  BUILDING_READ_ONLY_FIELDS_TYPE,
  BULK_IMPORT_HELPERS,
  useSurveyBuildingCustomFieldSelector,
} from '~/legacy/utils'
import {
  TextButton,
  PlusIcon,
  TableSettingsIcon,
  Modal,
  MODALS,
  EmptyCardsViewIcon,
  Button,
  LeftArrowIcon,
  RightArrowIcon,
  CustomIconSmall,
} from '~/legacy/components'

import CompareListingCard from './CompareListingCard'
import useCompareSurveyListingsDimensions from './CompareListingsHooks'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'
import { useSurveyListingCustomFieldSelector } from '~/legacy/utils/hooks/useSurveyListingCustomFieldSelector'

const STICKY_TABLE_TOP_MARGIN_PX = 26
const getButtonTop = (topPaddingPx) => {
  return `calc(50% + ${topPaddingPx / 2}px - ${68 / 2}px)` // 68 is button height
}

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 3,
    background: '#fff',
    position: 'sticky',
    top: '-50px',
    paddingBottom: `${STICKY_TABLE_TOP_MARGIN_PX}px`,
  },
  actionsSticky: {
    borderBottom: '1px solid #e0e0e0',
  },
  compareActionButton: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '20px',
    color: '#111111',
  },
  emptyStateContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    marginTop: '-10px',
  },
  emptyStateItemMargin: {
    marginTop: '12px',
  },
  emptyStateItemMarginBig: {
    marginTop: '24px',
  },
  grayText: {
    color: '#666666',
  },
  pageWidth: {
    width: (props) => `${props.CONTENT_WIDTH}px`,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
  },
  stickyThreshold: {
    position: 'relative',
    width: '100%',
    height: '1px',
    top: '50px',
    visibility: 'hidden',
  },
  leftColumn: {
    width: (props) => `${props.FIELD_LABEL_COLUMN_WIDTH_PX}px`,
    minWidth: (props) => `${props.FIELD_LABEL_COLUMN_WIDTH_PX}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  compareListingContainer: {
    paddingTop: (props) => `${props.TOP_PADDING_PX}px`,
    alignItems: 'center',
    display: 'flex',
    top: 0,
    position: 'sticky',
  },
  pointerContainer: {
    position: 'absolute',
    cursor: 'pointer',
  },
  topRightActionsContainer: {
    width: 'fit-content',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    paddingTop: (props) => `${props.TOP_PADDING_PX}px`,
  },
  fieldLabelContainer: {
    marginTop: (props) => `${props.TABLE_TOP_MARGIN_PX}px`,
    height: '100%',
  },
  fieldLabel: {
    display: 'flex',
    flexDirection: 'column',
    width: (props) => `${props.FIELD_LABEL_COLUMN_WIDTH_PX}px`,
  },
  fieldRowValue: {
    display: 'flex',
    flexDirection: 'row',
    height: (props) => `${props.FIELD_ROW_HEIGHT_PX}px`,
    alignItems: 'center',
  },
  hideBuildingContainer: {
    paddingTop: '30px',
    paddingBottom: '30px',
  },
  arrowButtonTop: {
    top: (props) => getButtonTop(props.TOP_PADDING_PX),
  },
  singeListingPlaceholderFieldText: {
    color: '#666',
  },
})

const useListingFieldLabelStyles = makeStyles({
  labelRoot: {
    display: 'flex',
    flexDirection: 'row',
    height: (props) => `${props.FIELD_ROW_HEIGHT_PX}px`,
    backgroundColor: (props) => (props.index % 2 === 0 ? '#F9F9F9' : ''),
    alignItems: 'center',
    justifyContent: 'left',
    paddingLeft: '20px',
  },
})

const FIELD_ROW_HEIGHT_PX = 80
const TOP_PADDING_PX = 20
const TABLE_TOP_MARGIN_PX = 14
// Modal State
const TABLE_SETTINGS_MODAL = 1
const TABLE_ADD_LISTINGS_MODAL = 2

const ListingFieldLabel = ({ index, label }) => {
  const classes = useListingFieldLabelStyles({ FIELD_ROW_HEIGHT_PX, index })
  return (
    <div className={classes.labelRoot}>
      <Typography variant="h3" style={{ marginLeft: '18px' }}>
        {label}
      </Typography>
    </div>
  )
}

const useListingSwiperStyles = makeStyles({
  swiperRoot: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    paddingTop: (props) => `${props.TOP_PADDING_PX}px`,
  },
  nextButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    marginRight: '-84px',
    top: (props) => getButtonTop(props.TOP_PADDING_PX),
  },
  singleListingCard: {
    width: '100%',
    height: '100%',
    background: '#E0E0E0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#666',
  },
  singleListingCardText: {
    marginLeft: '4px',
  },
})

// Holds the listing cards in a swiper carousel, draggable to reorder
const ListingSwiper = React.forwardRef(
  (
    {
      listingsOrder,
      isEnd,
      survey,
      moveListingCard,
      setRequestedListingIdsSet,
      setIsEnd,
      setIsBeginning,
      SWIPER_MAX_NUMBER_CARDS,
      SWIPER_NUMBER_CARDS,
      SWIPER_PADDING_PX,
      SWIPER_CONTAINER_WIDTH_PX,
      setModalState,
    },
    ref
  ) => {
    const { swiperRef, safeSwiperRef, safeSwiperRef2 } = ref
    const classes = useListingSwiperStyles({ TOP_PADDING_PX })

    return (
      <div className={classes.swiperRoot}>
        <Swiper
          ref={swiperRef}
          slidesPerView={SWIPER_NUMBER_CARDS}
          spaceBetween={SWIPER_PADDING_PX}
          onRealIndexChange={(swiper) => {
            if (!swiper.activeIndex) {
              setIsEnd(false)
              setIsBeginning(true)
            } else if (
              swiper.activeIndex ===
              listingsOrder.length - SWIPER_NUMBER_CARDS
            ) {
              setIsEnd(true)
              setIsBeginning(false)
            } else {
              setIsEnd(false)
              setIsBeginning(false)
            }
          }}
          allowTouchMove={false}
          observer
          observeParents
          width={SWIPER_CONTAINER_WIDTH_PX}
          style={{
            width: `${SWIPER_CONTAINER_WIDTH_PX}px`,
          }}
        >
          {listingsOrder.map((listing, index) => (
            <SwiperSlide key={listing.id}>
              <CompareListingCard
                listing={listing}
                survey={survey}
                index={index}
                moveListingCard={moveListingCard}
                removeListing={() => {
                  setRequestedListingIdsSet(
                    new Set([
                      ...listingsOrder
                        .filter((l) => l.id !== listing.id)
                        .map((l) => l.id),
                    ])
                  )
                }}
              />
            </SwiperSlide>
          ))}
          {!!listingsOrder && listingsOrder.length === 1 && (
            <SwiperSlide key="addMoreListings">
              <div
                className={classes.singleListingCard}
                onClick={() => setModalState(TABLE_ADD_LISTINGS_MODAL)}
              >
                <PlusIcon />
                <Typography
                  variant="h3"
                  className={classes.singleListingCardText}
                >
                  Add Another Space
                </Typography>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <div className={classes.nextButton}>
          {!isEnd && listingsOrder.length > SWIPER_MAX_NUMBER_CARDS && (
            <IconButton
              onClick={() => {
                safeSwiperRef.slideNext()
                safeSwiperRef2.slideNext()
              }}
            >
              <RightArrowIcon />
            </IconButton>
          )}
        </div>
      </div>
    )
  }
)

// Get a key representing a field and tis type (listing / building)
const getFieldKey = (field) => {
  return `${field.type}_${field.label}`
}

const CompareListingFieldValueColumn = ({
  listing,
  listingFields,
  numListings,
  listingIndex,
  isSingleListingPlaceholder = false,
}) => {
  const { CONTENT_WIDTH, SWIPER_PADDING_PX, FIELD_LABEL_COLUMN_WIDTH_PX } =
    useCompareSurveyListingsDimensions(numListings)
  const classes = useStyles({
    CONTENT_WIDTH,
    FIELD_LABEL_COLUMN_WIDTH_PX,
    TOP_PADDING_PX,
    TABLE_TOP_MARGIN_PX,
    FIELD_ROW_HEIGHT_PX,
  })
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {listingFields.map((listingField, index) => (
        // Field Row
        <div
          className={classes.fieldRowValue}
          style={{
            backgroundColor: index % 2 === 0 ? '#F9F9F9' : '',
            paddingLeft: listingIndex !== 0 ? `${SWIPER_PADDING_PX / 2}px` : '',
          }}
          key={listingField.label}
        >
          <Typography
            className={
              isSingleListingPlaceholder
                ? classes.singeListingPlaceholderFieldText
                : ''
            }
          >
            {listingField.getValue(listing) || '--'}
          </Typography>
        </div>
      ))}
    </div>
  )
}

const CompareListings = ({
  isPageReady,
  survey,
  allListingsInSurvey,
  listings,
  building,
  requestedListingIdsSet,
  setRequestedListingIdsSet,
  setListingsOrder,
  setListingFieldsUrlParam,
  setHiddenListingFieldsUrlParam,
  setHideBuildingProperties,
  listingsOrder,
  initialFieldOrdering,
  initialHiddenListingFields,
  hideBuildingProperties,
  isCustomFieldsEnabled,
}) => {
  const {
    CONTENT_WIDTH,
    SWIPER_CONTAINER_WIDTH_PX,
    SWIPER_NUMBER_CARDS,
    SWIPER_MAX_NUMBER_CARDS,
    SWIPER_PADDING_PX,
    FIELD_LABEL_COLUMN_WIDTH_PX,
  } = useCompareSurveyListingsDimensions(listingsOrder.length)
  const classes = useStyles({
    CONTENT_WIDTH,
    FIELD_LABEL_COLUMN_WIDTH_PX,
    TOP_PADDING_PX,
    TABLE_TOP_MARGIN_PX,
    FIELD_ROW_HEIGHT_PX,
  })

  const buildingId = building ? building.id : null

  const LISTING_READ_ONLY_FIELDS = !isCustomFieldsEnabled
    ? ORIGINAL_LISTING_READ_ONLY_FIELDS
    : ORIGINAL_LISTING_READ_ONLY_FIELDS.filter(
        (field) => field.label === 'Amenities'
      )

  const {
    buildingCustomFields: buildingCustomFieldsDefinitions,
    listingCustomFields: listingCustomFieldsDefinitions,
    isLoading: isLoadingCustomFields,
  } = useSurveyCustomFieldsSelector({
    surveyId: survey.id,
    enabled: isCustomFieldsEnabled,
  })

  const {
    customFieldValues: buildingFieldValues,
    isLoading: isLoadingBuildingValues,
  } = useSurveyBuildingCustomFieldSelector({
    surveyId: survey.id,
    buildingId,
    enabled: isCustomFieldsEnabled,
  })
  const {
    customFieldValues: listingFieldValues,
    isLoading: isLoadingListingValues,
  } = useSurveyListingCustomFieldSelector({
    surveyId: survey.id,
    enabled: isCustomFieldsEnabled,
  })

  const isLoadingData =
    isLoadingCustomFields || isLoadingBuildingValues || isLoadingListingValues

  // Set our orderd listings based on the listings
  useEffect(() => {
    if (listings && listings.length > 0) {
      // Use the order from the url to ensure the order sticks when our core set of listings changes
      // The alternative here is to go through and remove all instances of setRequestedListingIdsSet to setListingsOrder...
      //   should probably do this at some point
      const listingsDict = {}
      const newListingsOrder = []
      const newListingsOrderTailEnd = []
      listings.forEach((listing) => {
        listingsDict[listing.id] = listing
      })
      requestedListingIdsSet.forEach((listingId) => {
        if (listingsDict[listingId]) {
          newListingsOrder.push(listingsDict[listingId])
        } else {
          newListingsOrderTailEnd.push(listingsDict[listingId])
        }
      })
      setListingsOrder([...newListingsOrder, ...newListingsOrderTailEnd])
    } else {
      setListingsOrder([])
    }
  }, [listings])

  // Auto hide the building fields if all listings are in a building, and the user hasn't selected to hide the fields already
  useEffect(() => {
    if (hideBuildingProperties === null && buildingId) {
      setHideBuildingProperties(true)
    }
  }, [buildingId])

  // Move a listing card's order on drag
  const moveListingCard = useCallback(
    (dragIndex, hoverIndex) => {
      setListingsOrder(arrayMove(listingsOrder, dragIndex, hoverIndex))
    },
    [listingsOrder]
  )

  // Whether or not the carousel is hitting beginning or end, for showing/hiding arrows
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  // Swiper carousel references
  const swiperRef = useRef(null)
  const swiperRef2 = useRef(null)
  const isSwiperRef = swiperRef && swiperRef.current && swiperRef.current.swiper
  const isSwiperRef2 =
    swiperRef2 && swiperRef2.current && swiperRef2.current.swiper
  const safeSwiperRef = isSwiperRef ? swiperRef.current.swiper : {}
  const safeSwiperRef2 = isSwiperRef2 ? swiperRef2.current.swiper : {}

  // Get the custom fields from listings and put them into a map
  // {field_type: { custom_field_name: {listing_id: custom_field_value, ...}, ...} }
  // ie: {LISTING_READ_ONLY_FIELDS_TYPE: { 'Vacancy Type': { 1435: 'sublet' } } }
  const [listingCustomFields, setListingCustomFields] = useState()
  useEffect(() => {
    if (listings && listings.length > 0 && !isCustomFieldsEnabled) {
      const newListingCustomFields = {
        [LISTING_READ_ONLY_FIELDS_TYPE]: {},
        [BUILDING_READ_ONLY_FIELDS_TYPE]: {},
      }
      listings.forEach((listing) => {
        if (listing.custom_fields && listing.custom_fields.length > 0) {
          listing.custom_fields.forEach((customField) => {
            // Add in the custom field value
            if (
              !newListingCustomFields[LISTING_READ_ONLY_FIELDS_TYPE][
                customField.name
              ]
            ) {
              newListingCustomFields[LISTING_READ_ONLY_FIELDS_TYPE][
                customField.name
              ] = {
                values: {},
                fieldDataType: BULK_IMPORT_HELPERS.getFieldDataTypeById(
                  customField.custom_field.data_type
                ),
              }
            }
            newListingCustomFields[LISTING_READ_ONLY_FIELDS_TYPE][
              customField.name
            ].values[listing.id] = customField.custom_field.value
          })
        }
        if (
          listing.building.custom_fields &&
          listing.building.custom_fields.length > 0
        ) {
          listing.building.custom_fields.forEach((customField) => {
            // Add in the custom field value
            if (
              !newListingCustomFields[BUILDING_READ_ONLY_FIELDS_TYPE][
                customField.name
              ]
            ) {
              newListingCustomFields[BUILDING_READ_ONLY_FIELDS_TYPE][
                customField.name
              ] = {
                values: {},
                fieldDataType: BULK_IMPORT_HELPERS.getFieldDataTypeById(
                  customField.custom_field.data_type
                ),
              }
            }
            newListingCustomFields[BUILDING_READ_ONLY_FIELDS_TYPE][
              customField.name
            ].values[listing.building.id] = customField.custom_field.value
          })
        }
      })
      setListingCustomFields(newListingCustomFields)
    }
  }, [listings, isCustomFieldsEnabled])

  const listingValuesByField = useMemo(
    () => groupBy(listingFieldValues, 'custom_field.id'),
    [listingFieldValues]
  )
  const buildingValuesByField = useMemo(
    () => groupBy(buildingFieldValues, 'custom_field.id'),
    [buildingFieldValues]
  )

  useEffect(() => {
    if (
      listings &&
      listings.length > 0 &&
      isCustomFieldsEnabled &&
      !isLoadingData
    ) {
      const newListingCustomFields = {
        [LISTING_READ_ONLY_FIELDS_TYPE]: {},
        [BUILDING_READ_ONLY_FIELDS_TYPE]: {},
      }
      listings.forEach((listing) => {
        listingCustomFieldsDefinitions.forEach((customField) => {
          const values = listingValuesByField[customField.id] || []
          const fieldDataType = BULK_IMPORT_HELPERS.getFieldDataTypeById(
            customField.data_type
          )
          if (
            !newListingCustomFields[LISTING_READ_ONLY_FIELDS_TYPE][
              customField.label
            ]
          ) {
            newListingCustomFields[LISTING_READ_ONLY_FIELDS_TYPE][
              customField.label
            ] = {
              values: {},
              fieldDataType,
            }
          }

          const value = values.find(
            (value) => value.listing_id === listing.id
          )?.value
          const formattedValue = fieldDataType.formatter(value)

          newListingCustomFields[LISTING_READ_ONLY_FIELDS_TYPE][
            customField.label
          ].values[listing.id] = formattedValue
        })

        buildingCustomFieldsDefinitions.forEach((customField) => {
          const values = buildingValuesByField[customField.id] || []
          const fieldDataType = BULK_IMPORT_HELPERS.getFieldDataTypeById(
            customField.data_type
          )
          if (
            !newListingCustomFields[BUILDING_READ_ONLY_FIELDS_TYPE][
              customField.label
            ]
          ) {
            newListingCustomFields[BUILDING_READ_ONLY_FIELDS_TYPE][
              customField.label
            ] = {
              values: {},
              fieldDataType,
            }
          }

          const value = values.find(
            (value) => value.building_id === listing.building.id
          )?.value
          const formattedValue = fieldDataType.formatter(value)

          newListingCustomFields[BUILDING_READ_ONLY_FIELDS_TYPE][
            customField.label
          ].values[listing.building.id] = formattedValue
        })
      })

      setListingCustomFields(newListingCustomFields)
    }
  }, [
    listings,
    isCustomFieldsEnabled,
    buildingCustomFieldsDefinitions,
    listingCustomFieldsDefinitions,
    listingValuesByField,
    buildingValuesByField,
    isLoadingData,
  ])

  // The listing fields to display. We have our base fields and our custom fields
  const [readOnlyListingFields, setReadOnlyListingFields] = useState()
  const [filteredReadOnlyListingFields, setFilteredReadOnlyListingFields] =
    useState()

  // The ordered, unhidden listing fields to display
  const [orderedReadOnlyListingFields, setOrderedReadOnlyListingFields] =
    useState([])

  // Add the listing custom fields to our set of listing fields
  useEffect(() => {
    if (listingCustomFields) {
      console.log({ listingCustomFields })
      const newReadOnlyListingFields = [...LISTING_READ_ONLY_FIELDS]
      if (listingCustomFields[LISTING_READ_ONLY_FIELDS_TYPE]) {
        // Add the listing custom fields
        Object.entries(
          listingCustomFields[LISTING_READ_ONLY_FIELDS_TYPE]
        ).forEach(([listingFieldKey]) => {
          newReadOnlyListingFields.push({
            label: listingFieldKey,
            iconSvg: CustomIconSmall,
            type: LISTING_READ_ONLY_FIELDS_TYPE,
            getValue: (listing) =>
              listingCustomFields[LISTING_READ_ONLY_FIELDS_TYPE][
                listingFieldKey
              ].values[listing.id],
            hidden: false,
          })
        })
      }
      // Add building custom fields
      if (listingCustomFields[BUILDING_READ_ONLY_FIELDS_TYPE]) {
        Object.entries(
          listingCustomFields[BUILDING_READ_ONLY_FIELDS_TYPE]
        ).forEach(([listingFieldKey]) => {
          newReadOnlyListingFields.push({
            label: listingFieldKey,
            iconSvg: CustomIconSmall,
            type: BUILDING_READ_ONLY_FIELDS_TYPE,
            getValue: (listing) =>
              listingCustomFields[BUILDING_READ_ONLY_FIELDS_TYPE][
                listingFieldKey
              ].values[listing.building.id],
            hidden: false,
          })
        })
      }
      setReadOnlyListingFields(newReadOnlyListingFields)
    }
  }, [listingCustomFields])

  // Preserve field ordering when adding/removing/changing listings
  useEffect(() => {
    if (readOnlyListingFields) {
      const newOrderedReadOnlyListingFields = []
      // Set of all of the listing fields we want to show on the page
      const readOnlyListingFieldsSet = new Set(
        readOnlyListingFields.map((field) => `${field.type}_${field.label}`)
      )

      // Go through our ordered fields and add them if they are in the listing fields
      orderedReadOnlyListingFields.forEach((field) => {
        if (readOnlyListingFieldsSet.has(`${field.type}_${field.label}`)) {
          newOrderedReadOnlyListingFields.push(field)
        }
      })
      const newOrderedReadOnlyListingFieldsLabelSet = new Set(
        newOrderedReadOnlyListingFields.map(
          (field) => `${field.type}_${field.label}`
        )
      )
      // Now append any new fields (custom, etc) to the end
      readOnlyListingFields.forEach((field) => {
        if (
          !newOrderedReadOnlyListingFieldsLabelSet.has(
            `${field.type}_${field.label}`
          )
        ) {
          newOrderedReadOnlyListingFields.push(field)
        }
      })

      // If this is the first time we're setting field ordering, use the URL param ordering
      if (
        !orderedReadOnlyListingFields ||
        orderedReadOnlyListingFields.length === 0
      ) {
        // Put them into new dict in order
        const newNewOrderedReadOnlyListingFields = []

        // A dictionary of the current fields:
        const newOrderedReadOnlyListingFieldsDict = {}
        newOrderedReadOnlyListingFields.forEach((field) => {
          newOrderedReadOnlyListingFieldsDict[`${field.type}_${field.label}`] =
            field
        })

        // Set of the url fields
        const initialFieldOrderingSet = new Set(initialFieldOrdering)

        // Go through the url fields and if we have data for that field, add it.
        initialFieldOrdering.forEach((initialField) => {
          if (initialField in newOrderedReadOnlyListingFieldsDict) {
            newNewOrderedReadOnlyListingFields.push(
              newOrderedReadOnlyListingFieldsDict[initialField]
            )
          }
        })

        // Go through the current data ordered fields and add any that weren't part of the URL
        newOrderedReadOnlyListingFields.forEach((listingField) => {
          const key = `${listingField.type}_${listingField.label}`
          if (!initialFieldOrderingSet.has(key)) {
            newNewOrderedReadOnlyListingFields.push(listingField)
          }
        })

        // Make a set of the hidden fields
        const initialHiddenListingFieldsSet = new Set(
          initialHiddenListingFields
        )
        // Hide the hidden fields
        const newNewNewOrderedReadOnlyListingFields =
          newNewOrderedReadOnlyListingFields.map((listingField) => ({
            ...listingField,
            hidden: initialHiddenListingFieldsSet.has(
              getFieldKey(listingField)
            ),
          }))

        // This should trigger an update of the URL, and ensure that any nefarious things in the URL are cleaned out.
        setOrderedReadOnlyListingFields(newNewNewOrderedReadOnlyListingFields)
      } else {
        setOrderedReadOnlyListingFields(newOrderedReadOnlyListingFields)
      }
    }
  }, [readOnlyListingFields])

  // Update the URL with the ordered fields
  useEffect(() => {
    setListingFieldsUrlParam(
      orderedReadOnlyListingFields.map((lf) => getFieldKey(lf))
    )
    setHiddenListingFieldsUrlParam(
      orderedReadOnlyListingFields
        .filter((lf) => lf.hidden)
        .map((lf) => getFieldKey(lf))
    )
  }, [orderedReadOnlyListingFields])

  // Filter building properties from our set of listing fields if necessary
  useEffect(() => {
    setFilteredReadOnlyListingFields(
      orderedReadOnlyListingFields.filter(
        (l) =>
          (!hideBuildingProperties ||
            (hideBuildingProperties &&
              l.type === LISTING_READ_ONLY_FIELDS_TYPE)) &&
          !l.hidden
      )
    )
  }, [orderedReadOnlyListingFields, hideBuildingProperties])

  // Handle the modal state, there can only be ~one~
  const [modalState, setModalState] = useState(null)
  const SHOW_SETTINGS_MODAL = modalState === TABLE_SETTINGS_MODAL
  const SHOW_ADD_LISTING_MODAL = modalState === TABLE_ADD_LISTINGS_MODAL

  // If the page is loaded, but we don't have any listings, then show empty state
  const noListingsToCompare =
    (isPageReady && !listings) ||
    !listings.length ||
    !listingsOrder ||
    !filteredReadOnlyListingFields

  // Keep track of whether or not we've scrolled to our sticky header, so we can add/remove a bottom border
  const [refst, inView] = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
  })

  return (
    <>
      {/* Hidden div to track when we've scrolled past the sticky */}
      <div ref={refst} className={classes.stickyThreshold} />

      <div
        className={clsx(classes.actions, !inView ? classes.actionsSticky : '')}
      >
        <div
          className={classes.pageWidth}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          {/* Left Column */}
          <div className={classes.leftColumn}>
            <div className={classes.compareListingContainer}>
              <Typography variant="h3">
                {`Comparing ${listingsOrder.length} space${
                  listingsOrder.length === 1 ? '' : 's'
                }`}
              </Typography>
            </div>
            <div
              className={clsx(classes.pointerContainer, classes.arrowButtonTop)}
            >
              {!isBeginning && (
                <IconButton
                  onClick={() => {
                    safeSwiperRef.slidePrev()
                    safeSwiperRef2.slidePrev()
                  }}
                >
                  <LeftArrowIcon />
                </IconButton>
              )}
            </div>
          </div>

          <div className={classes.topRightActionsContainer}>
            <div className={classes.actionButtonContainer}>
              <TextButton
                variant="text"
                className={classes.compareActionButton}
                startIcon={<TableSettingsIcon />}
                onClick={() => setModalState(TABLE_SETTINGS_MODAL)}
              >
                Table Settings
              </TextButton>
              <TextButton
                variant="text"
                className={classes.compareActionButton}
                startIcon={<PlusIcon />}
                onClick={() => setModalState(TABLE_ADD_LISTINGS_MODAL)}
              >
                Add More Spaces
              </TextButton>
            </div>
            {/* )} */}

            {!noListingsToCompare && (
              <ListingSwiper
                listingsOrder={listingsOrder}
                isEnd={isEnd}
                SWIPER_MAX_NUMBER_CARDS={SWIPER_MAX_NUMBER_CARDS}
                survey={survey}
                moveListingCard={moveListingCard}
                setRequestedListingIdsSet={setRequestedListingIdsSet}
                SWIPER_NUMBER_CARDS={SWIPER_NUMBER_CARDS}
                SWIPER_PADDING_PX={SWIPER_PADDING_PX}
                setIsEnd={setIsEnd}
                setIsBeginning={setIsBeginning}
                SWIPER_CONTAINER_WIDTH_PX={SWIPER_CONTAINER_WIDTH_PX}
                ref={{
                  swiperRef,
                  safeSwiperRef,
                  safeSwiperRef2,
                }}
                setModalState={setModalState}
              />
            )}
          </div>
        </div>
      </div>

      {!noListingsToCompare ? (
        <>
          <div className={clsx(classes.pageWidth, classes.fieldLabelContainer)}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderBottom:
                  filteredReadOnlyListingFields.length % 2 === 0
                    ? '1px solid #E0E0E0'
                    : '',
              }}
            >
              {/* Field Name Labels Column */}
              <div className={classes.fieldLabel}>
                {filteredReadOnlyListingFields.map((listingField, index) => {
                  // Field Label Row
                  return (
                    <ListingFieldLabel
                      key={listingField.label}
                      index={index}
                      label={listingField.label}
                    />
                  )
                })}
              </div>

              {/* Listing Data Columns */}
              <Swiper
                ref={swiperRef2}
                slidesPerView={SWIPER_NUMBER_CARDS}
                spaceBetween={0}
                allowTouchMove={false}
                observer
                observeParents
                width={SWIPER_CONTAINER_WIDTH_PX}
                style={{
                  width: `${SWIPER_CONTAINER_WIDTH_PX}px`,
                  margin: 0,
                }}
              >
                {listingsOrder.map((listing, listingIndex) => (
                  // Column Start
                  <SwiperSlide key={listing.id}>
                    <CompareListingFieldValueColumn
                      listing={listing}
                      listingFields={filteredReadOnlyListingFields}
                      numListings={listingsOrder.length}
                      listingIndex={listingIndex}
                    />
                  </SwiperSlide>
                ))}
                {!!listingsOrder && listingsOrder.length === 1 && (
                  <SwiperSlide key="singleListingFields">
                    <CompareListingFieldValueColumn
                      listing={{}}
                      listingFields={filteredReadOnlyListingFields.map(
                        (listingField) => ({
                          ...listingField,
                          getValue: () => listingField.label,
                        })
                      )}
                      numListings={listingsOrder.length}
                      listingIndex={1}
                      isSingleListingPlaceholder
                    />
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          </div>
          <div
            className={clsx(classes.pageWidth, classes.hideBuildingContainer)}
          >
            <Link
              onClick={() => {
                setHideBuildingProperties(!hideBuildingProperties)
              }}
            >
              <Typography variant="h3">
                {`${hideBuildingProperties ? 'Show' : 'Hide'} Building Details`}
              </Typography>
            </Link>
          </div>
        </>
      ) : (
        <div className={classes.emptyStateContainer}>
          <EmptyCardsViewIcon />
          <Typography
            variant="h3"
            className={clsx(classes.grayText, classes.emptyStateItemMargin)}
          >
            Start comparing by adding spaces.
          </Typography>
          <Button
            className={classes.emptyStateItemMarginBig}
            onClick={() => setModalState(TABLE_ADD_LISTINGS_MODAL)}
            color="primary"
          >
            Add Spaces
          </Button>
        </div>
      )}
      <Modal
        content={MODALS.COMPARISON_TABLE_SETTINGS}
        onClose={() => setModalState(null)}
        open={SHOW_SETTINGS_MODAL}
        childProps={{
          orderedReadOnlyListingFields,
          setOrderedReadOnlyListingFields,
        }}
      />
      <Modal
        content={MODALS.COMPARISON_ADD_MORE_LISTINGS}
        onClose={() => setModalState(null)}
        open={SHOW_ADD_LISTING_MODAL}
        childProps={{
          allListingsInSurvey,
          selectedListingsSet: requestedListingIdsSet,
          setSelectedListingsSet: setRequestedListingIdsSet,
        }}
      />
    </>
  )
}

export default CompareListings
