import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import {
  downloadFile,
  downloadRemoteFile,
  downloadResourceToObjectUrl,
  isBroker,
  removeExtension,
  renderPdfWithCurrent,
} from '~/legacy/utils';
import { IsPreviewContext } from '~/legacy/utils/isTenantPreviewContext';
import {
  DocumentIcon,
  SquareIconButton,
  Typography,
  TextLinkFileUploadWrapper,
  ExportIcon,
  MoreIcon,
} from '~/legacy/components';
import { useSurveyBuildingAttachmentMenu } from '~/legacy/components/menus/SurveyBuildingAttachmentMenu';

const BORDER_COLOR = '#E0E0E0';
const useStyles = makeStyles({
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  attachments: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  attachment: {
    height: '48px',
    paddingRight: '13px',
    borderTop: `1px solid ${BORDER_COLOR}`,
    borderLeft: `1px solid ${BORDER_COLOR}`,
    borderRight: `1px solid ${BORDER_COLOR}`,
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      '& div:first-child': {
        borderTopLeftRadius: '3px',
        '& canvas:first-child, img:first-child': {
          borderTopLeftRadius: '3px',
        },
      },
    },
    '&:last-child': {
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      borderBottom: `1px solid ${BORDER_COLOR}`,
      '& div:first-child': {
        borderBottomLeftRadius: '3px',
        '& canvas:first-child, img:first-child': {
          borderBottomLeftRadius: '3px',
          height: '46px',
        },
      },
    },
  },
  attachmentPreview: {
    background: '#F3F3F3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '48px',
    minWidth: '48px',
    marginRight: '16px',
    borderRight: '1px solid #E0E0E0',
  },
  documentIcon: {
    color: '#666',
  },
  name: {
    lineHeight: '22px',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginRight: '24px',
  },
  extensionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  exportContainer: {
    marginLeft: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  moreContainer: {
    marginLeft: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  previewCanvas: {
    height: '47px',
    width: '48px',
    objectFit: 'cover',
  },
});

const AddAnAttachment = ({ addSurveyBuildingAttachments }) => {
  return (
    <TextLinkFileUploadWrapper
      setFiles={(newFiles) => {
        const newPhotos = {};
        Object.entries(newFiles).forEach(([uuid, file]) => {
          newPhotos[uuid] = file;
        });
        addSurveyBuildingAttachments(Object.values(newPhotos));
      }}
    >
      Add an Attachment
    </TextLinkFileUploadWrapper>
  );
};

const ImmutableCanvas = withStyles({ previewCanvas: {} })(
  ({ classes, url }) => {
    const canvasRef = useCallback(
      (currentRef) => {
        if (url && currentRef !== null) {
          renderPdfWithCurrent(url, currentRef);
        }
      },
      [url]
    );

    return (
      <canvas key={url} ref={canvasRef} className={classes.previewCanvas} />
    );
  }
);

const PDF_EXTENSIONS = new Set(['pdf']);
const IMAGE_EXTENSIONS = new Set(['jpg', 'jpeg', 'webp', 'png', 'svg']);
const Attachment = ({
  attachment,
  isUserBroker,
  isPreview,
  handleMenuClick,
}) => {
  const classes = useStyles();

  const isPdf = !!(attachment && PDF_EXTENSIONS.has(attachment.ext));
  const isImage = !!(
    !isPdf &&
    attachment &&
    IMAGE_EXTENSIONS.has(attachment.ext)
  );
  const [imageUrl, setImageUrl] = useState(attachment.url);
  const [loadingError, setLoadingError] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const ac = new AbortController();
    if (isPdf) {
      (async () => {
        // TODO: Flashes because we render local and then remote. Would be cool if this didn't happen.
        // Might be better to just not render local?
        let url = '';
        // Either use the remote image url from leaseup, or use the local one since its still uploading
        if (attachment.rawFile) {
          url = window.URL.createObjectURL(attachment.rawFile);
        } else {
          url = await downloadResourceToObjectUrl(attachment.url, ac);
        }
        if (url) {
          setPdfUrl(url);
        } else if (!ac.signal.aborted) {
          setLoadingError(true);
        }
      })();
    }
    if (isImage) {
      // Either use the remote image url from leaseup, or use the local one since its still uploading
      if (attachment.url) {
        setImageUrl(attachment.url);
      } else if (attachment.rawFile) {
        setImageUrl(window.URL.createObjectURL(attachment.rawFile));
      }
    }
    return () => ac.abort();
  }, [attachment, isPdf, isImage]);

  return (
    <div className={classes.attachment}>
      <div className={classes.attachmentPreview}>
        {!loadingError && isPdf && (
          <ImmutableCanvas
            key={pdfUrl}
            url={pdfUrl}
            classes={{ previewCanvas: classes.previewCanvas }}
          />
        )}
        {!loadingError && isImage && (
          <img
            src={imageUrl}
            alt="attachment"
            className={classes.previewCanvas}
          />
        )}
        {!!(loadingError || (!isPdf && !isImage)) && <AttachFileOutlinedIcon />}
      </div>
      <div className={classes.nameContainer}>
        <Typography variant="boldText" noWrap className={classes.name}>
          {removeExtension(attachment.name || '')}
        </Typography>
      </div>
      <div className={classes.extensionContainer}>
        <div>
          <Typography variant="subSectionTitle">
            {attachment.ext ? attachment.ext.toUpperCase() : ''}
          </Typography>
        </div>
        <div className={classes.exportContainer}>
          <SquareIconButton
            onClick={() => {
              if (attachment.tempId && attachment.rawFile) {
                downloadFile(attachment.name, attachment.rawFile);
              } else {
                downloadRemoteFile(attachment);
              }
            }}
          >
            <ExportIcon />
          </SquareIconButton>
        </div>
        {!!(isUserBroker && !isPreview) && (
          <div className={classes.moreContainer}>
            <SquareIconButton
              onClick={(event) =>
                handleMenuClick(event, attachment.id, attachment.name)
              }
              className={classes.moreOptionsIcon}
              disabled={!attachment.id || attachment.id === -1}
            >
              <MoreIcon />
            </SquareIconButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default function AttachmentsSection({
  className,
  buildingName,
  attachments = [],
  addSurveyBuildingAttachments,
  deleteSurveyBuildingAttachment,
}) {
  const classes = useStyles();
  const isUserBroker = useSelector((s) => isBroker(s.user));
  const { isPreview } = React.useContext(IsPreviewContext);
  const hasAttachments = !!(attachments && attachments.length > 0);

  const {
    SurveyBuildingAttachmentMenuComponent,
    handleMenuClick,
    DeleteSurveyBuildingAttachmentMenuItemModalComponent,
  } = useSurveyBuildingAttachmentMenu(
    buildingName,
    deleteSurveyBuildingAttachment
  );

  return (isUserBroker && !isPreview) || attachments.length ? (
    <div className={className}>
      <div className={classes.titleSection}>
        <Typography variant="pageSubTitle">Attachments</Typography>

        {!!hasAttachments && !!(isUserBroker && !isPreview) && (
          <AddAnAttachment
            addSurveyBuildingAttachments={addSurveyBuildingAttachments}
          />
        )}
      </div>
      <div className={classes.attachments}>
        {!hasAttachments ? (
          <div className={classes.attachment}>
            <div className={classes.attachmentPreview}>
              <DocumentIcon className={classes.documentIcon} />
            </div>
            <AddAnAttachment
              addSurveyBuildingAttachments={addSurveyBuildingAttachments}
            />
          </div>
        ) : (
          attachments.map((attachment) => (
            <Attachment
              key={attachment.tempId || attachment.id}
              attachment={attachment}
              isUserBroker={isUserBroker}
              isPreview={isPreview}
              handleMenuClick={handleMenuClick}
            />
          ))
        )}
      </div>
      {SurveyBuildingAttachmentMenuComponent}
      {DeleteSurveyBuildingAttachmentMenuItemModalComponent}
    </div>
  ) : null;
}
