import React from 'react';
import { SetPasswordModal } from '~/legacy/components/modals';

function ResetPasswordModal({ allowClose = true, onClose }) {
  return (
    <SetPasswordModal
      allowClose={allowClose}
      onClose={onClose}
      actionLabel="Reset Password"
      actionButtonLabel="Reset Password"
      successModalTitle="Password Reset"
      successModalText="Password successfully reset."
    />
  );
}

export default ResetPasswordModal;
