import user from './user';
import account from './account';
import addresses from './addresses';
import listings from './listings';
import searches from './searches';
import surveys from './surveys';
import mapbox from './mapbox';
import invite from './invite';
import surveyListings from './surveyListings';
import buildings from './buildings';
import magicLinkTokens from './magicLinkTokens';
import surveyComments from './surveyComments';
import surveyListingComments from './surveyListingComments';
import zendesk from './zendesk';

export default {
  ...user,
  ...account,
  ...addresses,
  ...listings,
  ...searches,
  ...surveys,
  ...mapbox,
  ...invite,
  ...surveyListings,
  ...buildings,
  ...magicLinkTokens,
  ...surveyListingComments,
  ...surveyComments,
  ...zendesk,
};
