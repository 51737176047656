import React, { useMemo } from 'react';
import findIndex from 'lodash/findIndex';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '~/legacy/components';
import { LEASE_PROJECT_BY_ID } from '~/legacy/consts';
import { getViewListingRoute, sortSdpSurveyListings } from '~/legacy/utils';

const useStyles = makeStyles({
  link: {
    marginLeft: '4px',
  },
  paginator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '8px',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  surveyName: {
    display: 'flex',
  },
});

function SurveyListingPaginator({ listingId, survey }) {
  const classes = useStyles();

  const sortedSurveyListings = useMemo(
    () =>
      sortSdpSurveyListings(survey.survey_buildings, survey.survey_listings),
    [survey]
  );

  const nextListingId = () => {
    const currentIndex = findIndex(
      sortedSurveyListings,
      (sl) => sl.listing.id === listingId
    );
    if (currentIndex + 1 === sortedSurveyListings.length) {
      return sortedSurveyListings[0].listing.id;
    }
    return sortedSurveyListings[currentIndex + 1].listing.id;
  };

  const prevListingId = () => {
    const currentIndex = findIndex(
      sortedSurveyListings,
      (sl) => sl.listing.id === listingId
    );
    if (currentIndex === 0) {
      return sortedSurveyListings[sortedSurveyListings.length - 1].listing.id;
    }
    return sortedSurveyListings[currentIndex - 1].listing.id;
  };

  return (
    <div className={classes.root}>
      <div className={classes.surveyName}>
        <Typography variant="h3">Survey: </Typography>
        <Link
          className={classes.link}
          href={LEASE_PROJECT_BY_ID.replace(':id', survey.id)}
          variant="h3"
        >
          {survey.name}
        </Link>
      </div>
      <div className={classes.paginator}>
        {sortedSurveyListings.length > 1 && (
          <>
            <Button
              onClick={() => {
                window.location = getViewListingRoute(
                  prevListingId(),
                  survey.id
                );
              }}
              startIcon={<ChevronLeftIcon />}
              variant="text"
            >
              Previous Listing
            </Button>
            <Button
              onClick={() => {
                window.location = getViewListingRoute(
                  nextListingId(),
                  survey.id
                );
              }}
              endIcon={<ChevronRightIcon />}
              variant="text"
            >
              Next Listing
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default SurveyListingPaginator;
