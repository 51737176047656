import { Input, InputAdornment, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as Sentry from '@sentry/browser'
import parse from 'autosuggest-highlight/parse'
import React, { useCallback } from 'react'
import { parseGoogleAddressValues } from '~/legacy/utils'
import { useSearchMaps } from '~/support/useLocationSearch'
import { ClearTextInputSvg } from '../svgs'

const geocoder = new google.maps.Geocoder()

const useStyles = makeStyles({
  input: {
    padding: '7px 0 7px',
  },
  clearTextInputIcon: {
    height: '16px',
    cursor: 'pointer',
    marginRight: '-5px',
    display: 'block',
    // color: '#e0e0e0',
  },
})

export default function SimpleAutocomplete({
  onChange,
  placeholder,
  initialValue = null,
  autoFocusAddressInput = false, // Whether or not to autofocus
  ...other
}) {
  const classes = useStyles()
  const [value, setValue] = React.useState(initialValue)
  const [input, setInput] = React.useState('')
  const predictions = useSearchMaps(input, value ? [value] : [])

  // Autofocusing the input for address. It's complex because we only want to autofocus
  //   when the user starts to edit the autocomplete, not just when the autocomplete renders. If we
  //   did that, then all the autocompletes on the page would autoFocus on render/page load.
  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && autoFocusAddressInput) inputElement.focus()
      return inputElement
    },
    [autoFocusAddressInput]
  )

  return (
    <Autocomplete
      id="google-map-demo2"
      style={{ width: '350px' }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description || ''
      }
      getOptionSelected={(option, _value) =>
        option.description === _value.description
      }
      inputValue={input}
      filterOptions={(x) => x}
      options={predictions.data}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        // setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        geocoder.geocode({ placeId: newValue.place_id }, (results, status) => {
          if (!['OK', 'ZERO_RESULTS'].includes(status)) {
            Sentry.captureMessage('Unexpected Geocoder API Status', {
              tags: {
                apiStatus: status,
              },
            })
          }
          const newAddressValues = parseGoogleAddressValues(
            results[0].address_components,
            results[0].geometry
          )

          onChange(results[0].formatted_address, newAddressValues, newValue)
        })
      }}
      onInputChange={(event, newInputValue) => {
        setInput(newInputValue)
      }}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <Input
            {...params.inputProps}
            inputRef={callbackRef}
            classes={{
              input: classes.input,
            }}
            placeholder={placeholder}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <ClearTextInputSvg
                  className={classes.clearTextInputIcon}
                  onClick={() => {
                    console.log('asdf')
                    setInput((value) => {
                      console.log({ value })
                      return ''
                    })
                  }}
                  onMouseDown={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                  }}
                />
              </InputAdornment>
            }
          />
        </div>
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        )

        return (
          <div>
            {parts.map((part) => (
              <Typography
                component="span"
                variant={part.highlight ? 'h3' : 'body1'}
                key={part.text}
              >
                {part.text}
              </Typography>
            ))}
            <Typography component="span" variant="body1">
              {` ${option.structured_formatting.secondary_text}`}
            </Typography>
          </div>
        )
      }}
      {...other}
    />
  )
}
