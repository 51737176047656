import React from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import { SquareIconButton, CloseIcon } from '~/legacy/components'
import { styled } from '~/libraries/styled'
import { Font } from '~/components/Font'

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '480px',
    minHeight: 0,
    width: '100%',
  },
})

export const QuickDrawer = (props) => {
  const classes = useStyles()

  const handleClose = () => {
    props.onClose?.(false)
  }

  return (
    <Drawer
      anchor="right"
      classes={classes}
      open={props.open}
      onClose={handleClose}
    >
      <Header>
        <Font format="18/24/600" grow>
          {props.title}
        </Font>
        <SquareIconButton onClick={handleClose}>
          <Close />
        </SquareIconButton>
      </Header>
      <Content>{props.children}</Content>
    </Drawer>
  )
}

const Header = styled('div', {
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid #E0E0E0',
  padding: '16px 16px 16px 24px',
  marginTop: '-1px',
  flex: 'none',
})

const Close = styled(CloseIcon, {
  color: '#111111',
})

const Content = styled('div', {
  flexGrow: 1,
  overflowY: 'auto',
})
