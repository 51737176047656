import { useCallback } from 'react'
import { useDispatcher } from '~/libraries/messaging.react'

export const useSend = () => {
  const dispatcher = useDispatcher()
  return useCallback(
    (type, data) => dispatcher.send({ type, data }),
    [dispatcher]
  )
}
