import React, { useEffect } from 'react';
import { Fade, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CollapseWrapper, ActivityContext } from '~/legacy/components';

import {
  SURVEY_BUILDING_COMMENT,
  COMMENT_DELETE,
  COMMENT_EDIT,
} from '~/legacy/consts';
import { surveyBuildingApi, surveyBuildingCommentApi } from '~/legacy/fetchApi';
import { useSurveyBuildingActivityWebSocket } from '~/legacy/utils/webSocketUtils';
import { BuildingActivityCommentInput } from './BuildingActivityCommentInput';
import { useCommentMenu } from './CommentMenu';
import { SurveyBuildingCommentActivityItem } from './SurveyBuildingCommentActivityItem';

const PAGE_SIZE = 5;
const EndOfActivity = ({ setNumActivitiesToShow, maxActivities }) => {
  const endOfActivityRef = React.createRef();

  // When the user scrolls to the bottom of the activity feed, "load" another page of activity. It's all preloaded right now we just show more
  useEffect(() => {
    const cachedRef = endOfActivityRef.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio >= 1) {
          setNumActivitiesToShow((prev) =>
            Math.min(prev + PAGE_SIZE, maxActivities)
          );
        }
      },
      { threshold: [1] }
    );

    observer.observe(cachedRef);

    // unmount
    return () => observer.unobserve(cachedRef);
  }, [setNumActivitiesToShow, endOfActivityRef, maxActivities]);

  return <div ref={endOfActivityRef} />;
};

export const BuildingActivityFeed = withStyles({
  activityContainer: {
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
  },
  activitiesContainer: {
    paddingTop: '32px',
    overflowY: 'hidden',
  },
  loading: {
    marginTop: '32px',
  },
})(({ classes, buildingId, surveyId, surveyBuildingId }) => {
  const context = React.useContext(ActivityContext);
  const {
    currentDate,
    activities,
    setActivities,
    setNewActivityMessage,
    isLoadingFeed,
    setIsLoadingFeed,
    show,
  } = context;

  // Control the number of activities we want to show on the page.
  const [numActivitiesToShow, setNumActivitiesToShow] =
    React.useState(PAGE_SIZE);

  // The websocket for our activity feed
  const { lastJsonMessage } = useSurveyBuildingActivityWebSocket({
    buildingId,
    surveyId,
  });

  // Update the activity when we get a new building or survey id
  useEffect(() => {
    setIsLoadingFeed(true);
    if (buildingId && surveyId) {
      surveyBuildingApi
        .getActivity(surveyId, buildingId)
        .then(([, activity]) => {
          if (activity) {
            setActivities(
              activity.map((result) => ({ ...result, isNew: false }))
            );
          }
        })
        .finally(() => {
          return setIsLoadingFeed(false);
        });
    }
  }, [buildingId, surveyId, setActivities, setIsLoadingFeed]);

  const setNewActivityMessageCallback = React.useCallback(
    (newItem) => {
      setNewActivityMessage(newItem);
      if (
        newItem &&
        newItem.type !== COMMENT_DELETE &&
        newItem.type !== COMMENT_EDIT
      ) {
        setNumActivitiesToShow((prev) => prev + 1);
      }
    },
    [setNewActivityMessage]
  );

  useEffect(() => {
    // Update the activities with the new activity from the websocket
    setNewActivityMessageCallback(lastJsonMessage);
  }, [lastJsonMessage, setNewActivityMessageCallback]);

  // The menu and modals to edit/delete a comment
  const {
    CommentMenuComponent,
    handleMenuClick,
    menuCommentUuid,
    EditCommentMenuItemModalComponent,
    DeleteCommentMenuItemModalComponent,
  } = useCommentMenu({
    updateComment: async (newComment, callbackBeforeUpdate) => {
      await surveyBuildingCommentApi.updateComment(newComment.id, {
        text: newComment.text,
      });
      callbackBeforeUpdate();
      return setNewActivityMessage({ ...newComment, edited: Date.now() });
    },
    deleteComment: async (commentId, callbackBeforeUpdate) => {
      const deletedComment = await surveyBuildingCommentApi
        .deleteComment(commentId)
        .then(([, deletedCommentApi]) => deletedCommentApi);
      callbackBeforeUpdate();
      return setNewActivityMessage(deletedComment);
    },
  });

  if (isLoadingFeed) {
    return <LinearProgress size={40} className={classes.loading} />;
  }

  return (
    <div>
      <Fade in={show}>
        <div className={classes.activityContainer}>
          {/* Create a new comment */}
          <BuildingActivityCommentInput
            setNewActivityMessage={setNewActivityMessageCallback}
            surveyId={surveyId}
            buildingId={buildingId}
            surveyBuildingId={surveyBuildingId}
          />

          {/* All of the existing activity comments */}
          <div className={classes.activitiesContainer}>
            {/* TODO: Poor man's factory for choosing the right component per item type. */}
            {/* Filter activity for the current building as a hack after adding pagination. Better implementation would probably be to bubble up the acitvity feed context to the parent/survey level, and filter
            only to the current buildings items. The feed re-fetches every paginate rn which isn't ideal. */}
            {!!activities &&
              activities
                .filter(
                  (activity) =>
                    activity.survey_building.building.id === buildingId
                )
                .slice(0, numActivitiesToShow)
                .map((activity) => (
                  <CollapseWrapper
                    includeCollapse={activity.isNew}
                    key={`wrapper-${activity.uuid}`}
                  >
                    <div>
                      {activity.type === SURVEY_BUILDING_COMMENT && (
                        <SurveyBuildingCommentActivityItem
                          activity={activity}
                          currentDate={currentDate}
                          setNewActivityMessage={setNewActivityMessage}
                          openMenu={handleMenuClick}
                          isMenuOpen={menuCommentUuid === activity.uuid}
                        />
                      )}
                    </div>
                  </CollapseWrapper>
                ))}
          </div>
          <EndOfActivity
            setNumActivitiesToShow={setNumActivitiesToShow}
            maxActivities={activities ? activities.length : 0}
          />
        </div>
      </Fade>
      {CommentMenuComponent}
      {EditCommentMenuItemModalComponent}
      {DeleteCommentMenuItemModalComponent}
    </div>
  );
});
