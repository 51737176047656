import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  scrollableContainer: {
    paddingBottom: props => `${props.bottomPaddingPx}px`,
  },
  transparentOverlay: {
    height: props => `${props.gradientHeightPx}px`,
    minHeight: props => `${props.gradientHeightPx}px`,
    marginTop: props => `-${props.gradientHeightPx}px`,
  },
});

const TransparentScrollOverlay = withStyles({
  hideScroll: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
  },
  scrollableContainer: {
    overflow: 'auto',
  },
  transparentOverlay: {
    width: '100%',
    zIndex: 2,
    position: 'relative',
    display: 'flex',
    backgroundImage:
      'linear-gradient(to bottom, rgba(255,255,255,0), white 100%)',
    pointerEvents: 'none',
  },
})(({
  children,
  classes,
  gradientHeightPx = 100,
  bottomPaddingPx = 100,
  hideScroll = false,
}) => {
  const classesLocal = useStyles({ gradientHeightPx, bottomPaddingPx });
  return (
    <>
      <div
        className={clsx(
          classesLocal.scrollableContainer,
          classes.scrollableContainer,
          hideScroll ? classes.hideScroll : '',
        )}
      >
        {children}
      </div>
      <div className={clsx(classesLocal.transparentOverlay, classes.transparentOverlay)} />
    </>
  );
});

export default TransparentScrollOverlay;
