import useSWR from 'swr'
import { recentProjects } from '~/legacy/fixtures'
import { getSearchParam, wait } from '~/legacy/utils'

const count = getSearchParam('projects', 6, Number)

export const useProjects = (id) => {
  return useSWR(`/user/${id}/projects`, {
    suspense: true,
    fetcher: async () => {
      await wait(100, 500)
      return recentProjects.slice(0, count)
    },
  })
}
