import React, { useCallback, useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useApiHelper } from '~/legacy/utils/hooks'
import { SnackbarUtils, useLogoUrl } from '~/legacy/utils'
import { Typography, UserAutocomplete } from '~/legacy/components'

import ActionModal from '../modals/ActionModal'

const useStyles = makeStyles({
  textInputFieldContainer: {
    width: '100%',
    marginTop: '28px',
  },
  imageButtonsContainer: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarDivider: {
    color: '#e0e0e0',
    margin: '4px',
    cursor: 'default',
  },
  avatarContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  autocompleteContainer: {
    width: '100%',
    marginTop: '28px',
  },
})

export const TransferSurveyOwnershipModal = ({
  open,
  onClose,
  transferSurvey,
  brokers,
  onTransferSurveys = () => {},
}) => {
  const classes = useStyles()
  const apiHelper = useApiHelper()
  const theme = useTheme()

  const [selectedOwner, setSelectedOwner] = useState({})
  const [errorText, setErrorText] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const brokersWithoutCurrentOwner = React.useMemo(() => {
    if (brokers) {
      if (transferSurvey && transferSurvey.owner) {
        return brokers.filter((broker) => broker.id !== transferSurvey.owner.id)
      }
      return brokers
    }
    return []
  }, [brokers, transferSurvey])

  const closeModal = () => {
    onClose()
  }

  const resetModal = () => {
    setSelectedOwner({})
  }

  const validate = useCallback(() => {
    if (!selectedOwner || !selectedOwner.id) {
      const newError = 'Must select a new owner'
      return newError
    }
    return ''
  }, [selectedOwner])

  useEffect(() => {
    if (errorText) {
      const newError = validate()
      if (!newError) {
        setErrorText('')
      } else if (newError !== errorText) {
        setErrorText(newError)
      }
    }
  }, [errorText, selectedOwner, validate])

  const logoUrl = useLogoUrl()

  return (
    <ActionModal
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onAfterClose={resetModal}
      onConfirm={() => {
        const error = validate()
        if (error) {
          setErrorText(error)
        } else if (transferSurvey && selectedOwner && selectedOwner.id) {
          setIsLoading(true)
          apiHelper
            .transferSurveyOwnership({
              surveyIds: [transferSurvey.id],
              transferToUserId: selectedOwner.id,
              logoFields: {
                // TODO: Why the hell is all of this here? pdf?
                color: theme.palette.primary.main,
                color_hover: theme.palette.primary.dark,
                logo_url: logoUrl,
              },
            })
            .then(([response, responseData]) => {
              if (response && response.ok) {
                SnackbarUtils.success('Survey successfully transfered.')
                onTransferSurveys(responseData.data)
                closeModal()
              } else {
                SnackbarUtils.error('Error transfering survey.')
              }
            })
            .catch(() => {
              SnackbarUtils.error('Error transfering survey.')
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      }}
      title="Transfer Ownership"
      confirmButtonLabel="Transfer"
      disableAction={!!(errorText || isLoading)}
      loading={isLoading}
    >
      <div className={classes.avatarContent}>
        <Typography>
          Select which user you want to transfer ownership of this survey to.
        </Typography>
        <div className={classes.autocompleteContainer}>
          <UserAutocomplete
            label="New Owner"
            userOptions={brokersWithoutCurrentOwner}
            selectedUser={selectedOwner}
            setSelectedUser={setSelectedOwner}
            showRole
            errorText={errorText}
            setErrorText={setErrorText}
          />
        </div>
      </div>
    </ActionModal>
  )
}

export const useTransferSurveyOwnershipModal = ({
  brokers,
  onClose = () => {},
  onTransferSurveys = () => {},
}) => {
  const [transferSurvey, setTransferSurvey] = useState({})
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!open) {
      setTransferSurvey({})
    }
  }, [open])

  const TransferSurveyOwnershipModalComponent = (
    <TransferSurveyOwnershipModal
      open={!!open}
      onClose={() => {
        setOpen(false)
        onClose()
      }}
      brokers={brokers}
      transferSurvey={transferSurvey}
      onTransferSurveys={onTransferSurveys}
    />
  )

  return {
    setOpen,
    setTransferSurvey,
    TransferSurveyOwnershipModalComponent,
  }
}
