import { useWindowDimensions } from '~/legacy/utils';

const useCompareSurveyListingsDimensions = (numListings) => {
  const { windowWidth } = useWindowDimensions();
  const PAGE_WIDTH = windowWidth - 180;

  // The width of the swiper card
  const SWIPER_WIDTH_PX = 320;
  // Left/right padding between cards
  const SWIPER_PADDING_PX = 12;
  // Don't show less than one card due to screen resizing
  const SWIPER_MIN_NUMBER_CARDS = 2;
  // The width of the field label column
  const FIELD_LABEL_COLUMN_WIDTH_PX = 240;

  // How much space we have on the screen to fill with cards
  const SPACE_TO_WORK_WITH = PAGE_WIDTH - FIELD_LABEL_COLUMN_WIDTH_PX;
  // Choose the max number of cards based on available space
  const SWIPER_MAX_NUMBER_CARDS = Math.floor(
    (SPACE_TO_WORK_WITH + SWIPER_PADDING_PX) /
      (SWIPER_WIDTH_PX + SWIPER_PADDING_PX)
  );

  // The number of cards to show in the swiper carousel
  const SWIPER_NUMBER_CARDS = Math.max(
    Math.min(SWIPER_MAX_NUMBER_CARDS, numListings),
    SWIPER_MIN_NUMBER_CARDS
  );
  // The width of the swiper container based on how many cards will fit
  const SWIPER_CONTAINER_WIDTH_PX =
    SWIPER_WIDTH_PX * SWIPER_NUMBER_CARDS +
    SWIPER_PADDING_PX * (SWIPER_NUMBER_CARDS - 1);
  // The final width of the content on the screen
  const CONTENT_WIDTH = Math.max(
    SWIPER_CONTAINER_WIDTH_PX + FIELD_LABEL_COLUMN_WIDTH_PX,
    600
  );

  return {
    CONTENT_WIDTH,
    SPACE_TO_WORK_WITH,
    SWIPER_CONTAINER_WIDTH_PX,
    SWIPER_NUMBER_CARDS,
    SWIPER_MAX_NUMBER_CARDS,
    SWIPER_PADDING_PX,
    FIELD_LABEL_COLUMN_WIDTH_PX,
  };
};

export default useCompareSurveyListingsDimensions;
