import React from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteIcon } from '~/legacy/components';
import { BASE_ICON_STYLES } from './ButtonUtils';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}));

const DeleteListingMenuItem = React.forwardRef(
  ({ handleMenuClose, openDeleteListingsModal, ...props }, ref) => {
    const classes = useStyles();

    return (
      <MenuItem
        ref={ref}
        className={classes.menuItemRoot}
        onClick={() => {
          handleMenuClose();
          openDeleteListingsModal();
        }}
        {...props}
      >
        <DeleteIcon className={classes.icon} />
        <Typography className={classes.text}>Delete Listing</Typography>
      </MenuItem>
    );
  }
);

export default DeleteListingMenuItem;
