import React from 'react';
import { SetPasswordModal } from '~/legacy/components/modals';

function CreatePasswordModal({ allowClose = true, onClose }) {
  return (
    <SetPasswordModal
      allowClose={allowClose}
      onClose={onClose}
      confirmUser
      actionLabel="Create Password"
      actionButtonLabel="Create Password"
      successModalTitle="Password Created"
      successModalText="Password successfully created."
    />
  );
}

export default CreatePasswordModal;
