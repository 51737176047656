import React from 'react'
import { Group } from '~/pdfs/patterns/Group'
import { Text } from '~/pdfs/patterns/Text'
import { useFieldWithDelay } from '~/support/useFieldDelay'
import { useSend } from '~/support/useSend'

export const TextSetting = (props) => {
  const send = useSend()

  const value = useFieldWithDelay(props.value, (value) => {
    send('AddSettings', { [props.id]: value })
  })

  return (
    <Group label={props.label}>
      <Text {...value} placeholder={props.placeholder} nullable />
    </Group>
  )
}
