import React from 'react'
import { Button as MuiButton, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const Button = withStyles((theme) => ({
  root: {
    minWidth: (props) => (props.shrinkButton ? '' : '140px'),
    height: (props) => (props.customHeight ? '100%' : '44px'),
  },
  disabled: {
    border: 'none !important',
  },
  containedSecondary: {
    color: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    border: `1px solid ${theme.palette.primary.main}`,
    transition: `
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    `,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  loading: {
    position: 'absolute',
    color: '#111',
  },
  endIcon: {},
}))(
  ({
    classes,
    className,
    color,
    disabled,
    onClick,
    variant = 'contained',
    loading = false,
    children,
    ...other
  }) => {
    // shrinkButton = false, Allow the parent to control the width of the button
    // customHeight = false, Allow the parent to control the height.
    // eslint-disable-next-line no-unused-vars
    const { shrinkButton, customHeight, ...buttonProps } = other
    return (
      <MuiButton
        classes={{
          disabled: classes.disabled,
          containedSecondary: classes.containedSecondary,
          endIcon: classes.endIcon,
        }}
        className={`${classes.root} ${className}`}
        color={color}
        disableElevation
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        {...buttonProps}
      >
        {loading ? (
          <>
            <CircularProgress
              classes={{ root: classes.exportLoader }}
              size={18}
              className={classes.loading}
            />
            {children}
          </>
        ) : (
          children
        )}
      </MuiButton>
    )
  }
)

export default Button
