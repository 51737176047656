import { useEffect } from 'react'
import { useDispatcher } from '~/libraries/messaging.react'

export function useOverlay(id, isActive) {
  const events = useDispatcher()

  useEffect(() => {
    events.send({
      type: isActive ? 'OverlayWasShown' : 'OverlayWasHidden',
      data: { id },
    })
  }, [events, id, isActive])
}
