export const formatToDollar = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const numberWithCommas = nmb => {
  if (!nmb) return null;
  return nmb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatMoney = money => {
  if (!money && (money !== 0 && money !== '0')) return null;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD', // we can omit this to use the system locale

    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(money);
};

export const formatPhoneNumber = raw => {
  const cleaned = (raw || '').replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const validatePhoneNumber = raw => {
  const cleaned = (raw || '').replace(/\D/g, '');
  return cleaned.length === 10 ? true : 'Phone numbers should be 10 digits.';
};
