import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import { SnackbarContent } from 'notistack';
import React, { forwardRef } from 'react';
import { CheckIcon, CloseIcon } from './svgs';

const useStyles = makeStyles({
  layout: {
    display: 'flex',
    height: 48,
  },
  color: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    bgcolor: 'primary.main',
    color: 'white',
    width: 48,
  },
  text: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
});

const icons = {
  success: CheckIcon,
  error: CloseIcon,
};

const Snackbar = forwardRef(({ msg, variant }, ref) => {
  const classes = useStyles();
  const Icon = icons[variant];
  const color = `primary.${variant}`;

  return (
    <SnackbarContent ref={ref}>
      <Card>
        <Box className={classes.layout}>
          <Box className={classes.color} bgcolor={color}>
            {Icon && <Icon />}
          </Box>
          <Box className={classes.text} px={3}>
            <Typography>{msg}</Typography>
          </Box>
        </Box>
      </Card>
    </SnackbarContent>
  );
});

export default Snackbar;
