import { useContext, useEffect, useMemo, useState } from 'react'
import { DEFAULT_SKIN } from '~/legacy/consts'
import {
  captureSentryMessageWarning,
  getCustomSkin,
  setCustomSkin,
} from '~/legacy/utils'
import { useApiHelper } from '~/legacy/utils/hooks'
import { generateSkinnedMuiTheme } from '../materialUITheme'
import { Firm } from '~/services/Firm'

// Make the theme using either the subdomain's custom skin or the default
const { value: LOCAL_STORAGE_SKIN, expired } = getCustomSkin()
const FINAL_SKIN = LOCAL_STORAGE_SKIN || DEFAULT_SKIN
const DEFAULT_THEME = generateSkinnedMuiTheme(FINAL_SKIN)

export const useSkinnedTheme = () => {
  const firm = useContext(Firm)
  const [skin, updateSkin] = useState(firm?.skin || DEFAULT_SKIN)
  const theme = useMemo(() => generateSkinnedMuiTheme(skin), [skin])
  return { skin, theme, updateSkin }
}

export const useSkinnedTheme2 = ({ subDomain }) => {
  const apiHelper = useApiHelper()
  const [theme, setTheme] = useState(DEFAULT_THEME)
  const [skin, setSkin] = useState(FINAL_SKIN)

  // When the skin is updated, generate and set a new skinned theme
  const updateSkin = (newSkin) => {
    setSkin(newSkin)
    setTheme(generateSkinnedMuiTheme(newSkin))
    setCustomSkin(newSkin)
  }

  // S3 > local storage S3 cache > LeaseUp Default
  // If we have a local storage theme, use that (stored for 60 minutes).
  // If there is no local storage theme (expired / never existed), fetch the theme from s3. Set it to local storage.
  useEffect(() => {
    // If we are on a subdomain, and if we don't have a local storage theme (or it's expired), then get the theme from S3 and store it in local storage
    if (subDomain && (!LOCAL_STORAGE_SKIN || expired)) {
      apiHelper.getSkinFromS3({ subDomain }).then(([response, data]) => {
        if (response && response.ok && data) {
          // Update the theme with the new skin
          try {
            updateSkin(data)
          } catch (error) {
            captureSentryMessageWarning({
              message: 'Error applying skin to theme',
              tags: {
                subDomain,
              },
              extras: {
                skin: data,
                error,
              },
            })
          }
        }
      })
    }
  }, [apiHelper, subDomain])

  return {
    theme,
    skin,
    updateSkin,
  }
}
