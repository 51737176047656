import { fetchApi } from './rootApi';
import { formatId, SEGMENT_PARAM_NAMES } from '../utils';

const SURVEY_LISTINGS_ENDPOINT_ROOT = 'survey_listings';

export const surveyListingApi = {
  deleteSurveyListing: async ({ surveyListingId }) =>
    fetchApi({
      endpoint: `${SURVEY_LISTINGS_ENDPOINT_ROOT}/${surveyListingId}/`,
      fetchArgs: {
        method: 'DELETE',
      },
      trackingArgs: {
        name: 'Remove Listing From Survey',
        metadata: {
          [SEGMENT_PARAM_NAMES.SURVEY_LISTING_ID]: formatId(surveyListingId),
        },
      },
      jsonResponse: false,
    }),
};
