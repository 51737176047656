import React from 'react';
import { Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput, Select } from '~/legacy/components';

const editInputBgColor = '#fff';
const editInputShrinkLabelColor = '#000';
const useStyles = makeStyles({
  editableInput: {
    '& .MuiInputBase-root': {
      background: editInputBgColor,
    },
    '& .MuiInputLabel-shrink': {
      color: editInputShrinkLabelColor,
    },
  },
  editableInputInColumn: {
    maxWidth: '100px',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none',
      },
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '5px',
    },

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '5px',
    },
  },
  entry: {
    display: 'flex',
  },
  entryLabel: {
    marginRight: '4px',
  },
  propertyFeatureColumn: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  propertyFeaturesEntry: {
    marginBottom: '8px',
    height: '28px',
  },
  editablePropertyFeatureColumn: {
    flex: 1,
  },
  iconContainer: {
    width: 36,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  icon: {
    fontSize: 18,
  },
  selectInput: {
    '& .MuiInputBase-root': {
      maxWidth: '100px',
    },
  },
});

export function PropertyFeatureText({
  editInLine,
  onEdit,
  propName,
  value,
  displayValue,
  icon,
  label,
  type = null,
}) {
  const classes = useStyles();

  return (
    <PropertyFeature
      displayValue={displayValue}
      editInLine={editInLine}
      icon={icon}
      label={label}
      editableComponent={
        <TextInput
          onChange={(event) =>
            onEdit({
              [propName]: event.target.value,
            })
          }
          type={type}
          value={value}
          size="small"
          className={`${classes.editableInputInColumn} ${classes.editableInput}`}
          fullWidth={false}
          margin="none"
        />
      }
    />
  );
}

export function PropertyFeatureSelect({
  editInLine,
  onEdit,
  propId,
  value,
  displayValue,
  options,
  icon,
  label,
}) {
  const classes = useStyles();

  return (
    <PropertyFeature
      isSelect
      displayValue={displayValue}
      editInLine={editInLine}
      icon={icon}
      label={label}
      editableComponent={
        <Select
          onChange={(event) =>
            onEdit({
              [propId]: event.target.value,
            })
          }
          value={value}
          options={options}
          size="small"
          className={`${classes.editableInputInColumn} ${classes.editableInput}`}
          fullWidth={false}
        />
      }
    />
  );
}

function PropertyFeature({
  displayValue,
  icon,
  label,
  isSelect = false,
  editInLine,
  editableComponent,
}) {
  const classes = useStyles();

  return (
    <div
      key={`feature-${label}}`}
      className={`${classes.entry} ${classes.propertyFeaturesEntry}`}
    >
      <div className={classes.iconContainer}>
        <Icon className={`fal fa-${icon} ${classes.icon}`} />
      </div>
      <div
        className={`${classes.propertyFeatureColumn} ${
          editInLine ? classes.editablePropertyFeatureColumn : ''
        }`}
      >
        <Typography className={classes.entryLabel} variant="h3">
          {`${label}:`}
        </Typography>
      </div>
      <div
        className={`${classes.propertyFeatureColumn} ${
          editInLine ? classes.editablePropertyFeatureColumn : ''
        } ${isSelect ? classes.selectInput : ''}`}
      >
        {editInLine ? (
          <>{editableComponent}</>
        ) : (
          <Typography variant="body1">{displayValue}</Typography>
        )}
      </div>
    </div>
  );
}
