import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Button,
  Modal,
  MODALS,
  DefaultListingImage,
  CompressedSourceImage,
  CameraIcon,
} from '~/legacy/components';
import {
  getListingImagesForDisplay,
  getListingPrimaryName,
} from '~/legacy/utils';

const getGridProps = (imageCount) => {
  switch (imageCount) {
    case 0:
    case 1:
      return {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        gridTemplateAreas: `
          "primary"
        `,
      };
    case 2:
      return {
        gridTemplateColumns: '1.5fr 1fr',
        gridTemplateRows: '1fr',
        gridTemplateAreas: `
          "primary secondary1"
        `,
      };
    case 3:
      return {
        gridTemplateColumns: '1.5fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gridTemplateAreas: `
          "primary secondary1"
          "primary secondary2"
        `,
      };
    case 4:
      return {
        gridTemplateColumns: '3fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gridTemplateAreas: `
          "primary secondary1 secondary2"
          "primary secondary3 secondary3"
        `,
      };
    case 5:
    default:
      return {
        gridTemplateColumns: '3fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gridTemplateAreas: `
          "primary secondary1 secondary2"
          "primary secondary3 secondary4"
        `,
      };
  }
};

const height = 500;
const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    height: '36px',
    top: '24px',
    left: '20px',
    padding: '10px 15px',
  },
  ghostButton: {
    backgroundColor: 'rgb(0, 0, 0, .5)',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgb(0, 0, 0, .7)',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    minWidth: 0,
    minHeight: 0,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  imagesRoot: (props) => ({
    height,
    [theme.breakpoints.only('xs')]: {
      height: '250px',
    },
    display: 'grid',
    gridGap: '8px',
    ...getGridProps(props.imageCount),
  }),
  root: {
    position: 'relative',
  },
}));

export default function ImageGrid({
  isTenantOrBrokerPreview,
  listing,
  setListing,
}) {
  const user = useSelector((s) => s.user);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [galleryState, setGalleryState] = useState({
    open: false,
    selectedIndex: null,
    selectedList: null,
  });
  const [images, setImages] = useState(getListingImagesForDisplay(listing));
  const classes = useStyles({ imageCount: images.length });

  useEffect(() => setImages(getListingImagesForDisplay(listing)), [listing]);

  const findSelectedImageByUuid = (uuid) => {
    let imageList = '';
    let imageIndex = '';

    listing.images.forEach((image, index) => {
      if (image.uuid === uuid) {
        imageList = 'listing_images';
        imageIndex = index;
      }
    });
    if (imageList === '' && imageIndex === '') {
      listing.floorplan_photos.forEach((image, index) => {
        if (image.uuid === uuid) {
          imageList = 'listing_floorplan_photos';
          imageIndex = index;
        }
      });
    }
    if (imageList === '' && imageIndex === '') {
      listing.building.images.forEach((image, index) => {
        if (image.uuid === uuid) {
          imageList = 'building_images';
          imageIndex = index;
        }
      });
    }
    if (imageList === '' && imageIndex === '') {
      listing.building.building_neighborhood_photos.forEach((image, index) => {
        if (image.uuid === uuid) {
          imageList = 'building_neighborhood_photos';
          imageIndex = index;
        }
      });
    }
    return {
      index: imageIndex,
      list: imageList,
    };
  };

  return (
    <div className={classes.root}>
      {!isTenantOrBrokerPreview && !user.isAnonymous && (
        <Button
          className={clsx(classes.button, images.length && classes.ghostButton)}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setShowUploadModal(true);
          }}
          startIcon={<CameraIcon />}
          variant="text"
        >
          Add Photos
        </Button>
      )}
      {(images.length > 0 ||
        (!isTenantOrBrokerPreview && !user.isAnonymous)) && (
        <div className={classes.imagesRoot}>
          {images.length > 0 ? (
            images.map((image, index) => (
              <CompressedSourceImage
                key={image.uuid}
                imageFile={image}
                ImgProps={{
                  alt: 'primary',
                  className: classes.image,
                  style: {
                    gridArea: index === 0 ? 'primary' : `secondary${index}`,
                  },
                  onClick: () => {
                    const selectedImageData = findSelectedImageByUuid(
                      image.uuid
                    );
                    setGalleryState({
                      open: true,
                      selectedIndex: selectedImageData.index,
                      selectedList: selectedImageData.list,
                    });
                  },
                }}
              />
            ))
          ) : (
            <DefaultListingImage className={classes.image} />
          )}
        </div>
      )}
      <Modal
        content={MODALS.IMAGE_UPLOAD}
        childProps={{
          parentObjects: {
            listing,
            building: listing.building,
          },
          objectName: getListingPrimaryName(listing),
          noPadding: true,
          setListing,
        }}
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
      />
      <Modal
        content={MODALS.IMAGE_GALLERY_OLD}
        childProps={{
          parentObjects: {
            listing,
            building: listing.building,
          },
          noPadding: true,
          fullHeight: true,
          initialSelectedIndex: galleryState.selectedIndex,
          initialSelectedListKey: galleryState.selectedList,
        }}
        fullScreen
        open={galleryState.open}
        onClose={() => setGalleryState({ ...galleryState, open: false })}
      />
    </div>
  );
}
