import produce from 'immer'
import { useEffect, useReducer } from 'react'
import { useDispatcher } from '~/libraries/messaging.react'

export const useAggregate = (options) => {
  const dispatcher = useDispatcher()

  const [state, dispatch] = useReducer((state, message) => {
    const callback = options[message.type]
    if (!callback) return state
    return produce(state, (draft) => {
      callback(draft, message.data)
    })
  }, options.state)

  useEffect(() => {
    return dispatcher.subscribe(dispatch)
  }, [dispatcher, dispatch])

  return state
}
