import React, { useState } from 'react'
import { format } from 'date-fns'

import {
  BuildingIcon,
  DocumentIcon,
  MessageIcon,
  MoreIcon,
  ProjectTeamIcon,
  Section,
  Spacer,
  SquareIconButton,
  SurveyIcon,
  TextChevronDown,
} from '~/legacy/components'
import { styled } from '~/legacy/utils/styleHelpers'
import { Activity } from './Activity'
import { Documents } from './Documents'

export const Project = (props) => {
  const { name, company, stages, tags, profiles, items } = props
  const [activity, setActivity] = useState(false)
  const [documents, setDocuments] = useState(false)
  const [notify, setNotify] = useState(items.length > 0)
  const [stage, setStage] = useState(props.stage) // eslint-disable-line

  return (
    <Container>
      <Section style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <div style={{ display: 'flex' }}>
          <Somethiansdf>{company}</Somethiansdf>
          <BreadcrumbIcon />
          <Breadcrumb>{name}</Breadcrumb>
        </div>
        <Spacer />
        <StageStatus>{stage}</StageStatus>
        <Stages>
          {stages.map((key, index) => (
            <Stage
              key={key}
              complete={index <= stages.indexOf(stage)}
              data-stage={key === stage ? key : `Change Status to ${key}`}
              onClick={() => setStage(key)}
            />
          ))}
        </Stages>
        <div />
        <SquareIconButton>
          <ProjectTeamIcon />
        </SquareIconButton>
        <SquareIconButton
          onClick={() => {
            setActivity(true)
            setNotify(false)
          }}
        >
          <MessageIcon />
          {notify && <Sticker />}
        </SquareIconButton>
        <SquareIconButton onClick={() => setDocuments(true)}>
          <DocumentIcon />
        </SquareIconButton>
        <SquareIconButton>
          <MoreIcon />
        </SquareIconButton>
      </Section>

      <Section top={20}>
        <Title>{name}</Title>
      </Section>

      <Section top={20} style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {profiles.map((profile) => (
            <Avatar key={profile.image} src={profile.image} />
          ))}
        </div>

        <div style={{ width: 32 }} />

        <div style={{ display: 'flex', gap: 8 }}>
          {tags.map((tag) => (
            <Pill key={tag}>{tag}</Pill>
          ))}
          <Pill circle>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect x="11.25" y="5" width="1.5" height="14" fill="#111111" />
              <rect
                x="19"
                y="11.25"
                width="1.5"
                height="14"
                transform="rotate(90 19 11.25)"
                fill="#111111"
              />
            </svg>
          </Pill>
        </div>

        <Spacer />
      </Section>

      <Section
        top={36}
        divider
        style={{ display: 'flex', padding: '16px 0px', alignItems: 'center' }}
      >
        <Something>
          {items.length
            ? `${items.length} Survey${items.length > 1 ? 's' : ''}`
            : '0 Items'}
        </Something>
        {items.length > 0 && (
          <BreadcrumbLink
            style={{ marginLeft: 16, display: 'flex', alignItems: 'center' }}
          >
            Sort by Recent
            <TextChevronDown />
          </BreadcrumbLink>
        )}
        <Spacer />
        <Button>
          Add
          <TextChevronDown />
        </Button>
      </Section>

      {!items.length ? (
        <Section>
          <Empty>
            <SurveyIcon />
            <span>Add a Survey to This Project</span>
            <div>
              Most projects begin with a survey. To
              <br />
              get started, create a survey.
            </div>
          </Empty>
        </Section>
      ) : (
        <Section style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          {items.map((item) => (
            <Survey key={item.name}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SurveyIcon />
                <div style={{ width: 6 }} />
                <SurveyTitle>{item.name}</SurveyTitle>
                <Spacer />
                <BuildingIcon />
                <div style={{ width: 4 }} />
                <Something>{`${item.count} Buildings`}</Something>
                <div style={{ width: 20 }} />
                <MoreIcon />
              </div>
              <div style={{ height: 6 }} />
              <Text>
                Survey
                <span>|</span>
                {`Last Updated: ${format(item.updatedAt, 'MMM dd, yyyy')}`}
              </Text>
              <div style={{ height: 18 }} />
              <div style={{ display: 'flex', gap: 12, flexWrap: 'nowrap' }}>
                {item.photos.slice(0, 7).map((photo, index) => (
                  <Photo key={photo}>
                    <img src={photo} alt="" />
                    {index === 6 && item.photos.length > 7 && (
                      <div>{`+${item.photos.length - 7} More`}</div>
                    )}
                  </Photo>
                ))}
              </div>
            </Survey>
          ))}
        </Section>
      )}

      <Spacer height={200} />
      {activity && <Activity onClose={() => setActivity(false)} />}
      {documents && <Documents onClose={() => setDocuments(false)} />}
    </Container>
  )
}

const Container = styled('div', {
  width: '1240px',
  margin: '20px auto',
})

const Breadcrumb = styled('div', {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: '0.1px',
  color: '#111',
})

const Somethiansdf = styled(Breadcrumb, {
  color: '#666',
  cursor: 'pointer',
})

const BreadcrumbLink = styled(Breadcrumb, (props) => ({
  color: props.get('theme.palette.primary.main'),
  cursor: 'pointer',
}))

const BreadcrumbIcon = styled(TextChevronDown, (props) => ({
  color: props.get('theme.palette.primary.main'),
  transform: 'rotate(-90deg)',
}))

const StageStatus = styled('div', {
  textTransform: 'uppercase',
  color: '#666',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '12px',
  letterSpacing: '0.4px',
})

const Stages = styled('div', {
  display: 'flex',
  // gap: '4px',
  margin: '-2px 0px',
})

const Stage = styled('div', ['complete'], (props) => ({
  position: 'relative',
  padding: '10px 2px',
  cursor: 'pointer',
  '&:before': {
    content: '""',
    display: 'block',
    height: '8px',
    width: '8px',
    backgroundColor: props.when(
      'complete',
      props.get('theme.palette.primary.main'),
      '#E0E0E0'
    ),
    borderRadius: '50%',
  },
  '&:after': {
    backgroundColor: props.get('theme.palette.primary.main'),
    color: 'white',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '10px',
    letterSpacing: '0.1px',
    padding: '7px',
    borderRadius: '4px',
    position: 'absolute',
    display: 'none',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    content: 'attr(data-stage)',
    whiteSpace: 'nowrap',
  },
  '&:hover:after': {
    display: 'block',
  },
}))

const Title = styled('div', {
  fontFamily: 'Inter',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
})

const Avatar = styled('img', {
  height: '32px',
  width: '32px',
  borderRadius: '50%',
  border: '1px solid #E0E0E0',
  '& + &': {
    marginRight: '-4px',
  },
})

const Pill = styled('div', ['circle'], (props) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  height: '32px',
  width: props.when('circle', '32px'),
  letterSpacing: '0.1px',
  backgroundColor: '#F3F3F3',
  borderRadius: '16px',
  color: '#111',
  padding: props.when('circle', '', '0 12px'),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Button = styled('div', ['outlined'], (props) => {
  const color = props.get('theme.palette.primary.main')
  return {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '0.1px',
    textAlign: 'center',
    padding: '0 8px 0 16px',
    color: props.when('outlined', color, 'white'),
    backgroundColor: props.when('outlined', 'white', color),
    borderRadius: '4px',
    border: props.when('outlined', `1px solid ${color}`),
    cursor: 'pointer',
    userSelect: 'none',
  }
})

const Something = styled('div', {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '28px',
  letterSpacing: '0.1px',
  color: '#111',
})

const Empty = styled('div', {
  height: '232px',
  borderRadius: '4px',
  color: '#666666',
  backgroundColor: '#F3F3F3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  gap: '8px',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',

  '& span': {
    fontSize: '18px',
    lineHeight: '24px',
  },
})

const Survey = styled('div', {
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  padding: '24px',
})

const SurveyTitle = styled('div', {
  fontFamily: 'Inter',
  fontSize: '22px',
  fontWeight: 600,
  lineHeight: '24px',
  color: '#111',
})

const Text = styled('div', {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0.1px',
  color: '#666666',
  '& span': {
    color: '#E0E0E0',
    margin: '0 6px',
  },
})

const Photo = styled('div', {
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  height: '118px',
  position: 'relative',
  overflow: 'hidden',
  width: '160px',
  // width: '160px',

  '& img': {
    objectFit: 'cover',
    width: '100%',
  },

  '& div': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    inset: '0px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0.1px',
  },
})

const Sticker = styled('div', {
  backgroundColor: '#DD421A',
  width: 8,
  height: 8,
  borderRadius: 4,
  position: 'absolute',
  top: 2,
  right: 2,
})
