import useSWR from 'swr';

import { projectApi } from '~/legacy/fetchApi';
import { PROJECT } from '~/legacy/consts';
import {
  defaultMutateBinder,
  defaultMutateOptimisticBinder,
} from './selectors';

const formatRawProject = (rawProject) => {
  if (!rawProject) {
    return rawProject;
  }

  // LOTS of data massaging
  const projectState =
    PROJECT.PROGRESS_MAP[rawProject.state] || PROJECT.PROGRESS.DRAFT;

  const projectBrokers =
    rawProject && rawProject.brokers ? rawProject.brokers : [];
  const projectTenants =
    rawProject && rawProject.tenants ? rawProject.tenants : [];
  // Mock project_broker_user model here, so we mock the id as well
  const projectOwnerAsBroker = {
    id: -1,
    is_editor: true,
    user: { ...rawProject.owner },
  };
  const projectEditors = [projectOwnerAsBroker, ...projectBrokers];
  const projectUsers = [...projectEditors, ...projectTenants];
  let projectSurveys =
    rawProject && rawProject.surveys ? rawProject.surveys : [];
  const projectTours = rawProject && rawProject.tours ? rawProject.tours : [];

  // TODO: This might be useful in its own formatRawSurvey one day, to use elsewhere
  projectSurveys = projectSurveys.map((projectSurvey) => {
    const surveyBuildings = projectSurvey.buildings || [];
    const surveySpaces = projectSurvey.spaces || [];
    const surveySpaceBuildingIds = new Set([
      ...surveySpaces.map((surveySpace) => surveySpace.building.id),
    ]);
    const surveyListings = [
      ...surveySpaces.map((surveySpace) => ({
        ...surveySpace,
        type: PROJECT.STUFF_TYPES.SPACE_TYPE,
      })),
      ...surveyBuildings
        .filter(
          (surveyBuilding) => !surveySpaceBuildingIds.has(surveyBuilding.id)
        )
        .map((surveyBuilding) => ({
          ...surveyBuilding,
          type: PROJECT.STUFF_TYPES.BUILDING_TYPE,
        })),
    ];
    return {
      ...projectSurvey,
      listings: surveyListings,
    };
  });

  const projectCollections = [
    ...projectSurveys.map((survey) => ({
      ...survey,
      type: PROJECT.STUFF_TYPES.SURVEY_TYPE,
    })),
    ...projectTours.map((tour) => ({
      ...tour,
      type: PROJECT.STUFF_TYPES.TOUR_TYPE,
    })),
  ];

  return {
    ...rawProject,
    state: projectState,
    users: projectUsers,
    editors: projectEditors,
    collections: projectCollections,
  };
};

// Prepare the project by formatting the project from the raw project
const prepareProject = (rawProject) => ({
  raw: rawProject,
  formatted: formatRawProject(rawProject),
});
const prepareProjectResponse = ([, responseProject]) =>
  prepareProject(responseProject);

// Fetch a fresh project from our backend
const fetchProject = async (projectId) => {
  return projectApi.getProject({ projectId }).then(prepareProjectResponse);
};

// Our project in SWR. Single project
export const SWR_PROJECT = 'project';
const DEFAULT_API_ERROR_MESSAGE = 'Error updating project, please try again.';

// data format: {raw: { ...rawProjectFromApi }, formatted: { ...formattedProject } };
export const useSWRProject = (...swrArgs) => useSWR(SWR_PROJECT, ...swrArgs);

// Hook to manage the raw project in SWR as well as format the raw project.
export const useProjectSelector = (projectId) => {
  // TODO: At some point, this should probably be more safely provided with context provider
  // Async wrapper for fetching our project from backend
  const swrArgs = [];
  if (projectId) {
    swrArgs.push(async () => fetchProject(projectId));
  }

  // SWR project
  const { data: swrProject, error, mutate } = useSWRProject(...swrArgs);
  const rawProject = swrProject && swrProject.raw;
  const formattedProject = swrProject && swrProject.formatted;

  // Bind our mutators with our default settings and error handling
  const mutateProject = defaultMutateBinder(mutate, DEFAULT_API_ERROR_MESSAGE);
  const mutateProjectOptimistic = defaultMutateOptimisticBinder(
    mutateProject,
    DEFAULT_API_ERROR_MESSAGE
  );

  // Update the project via api and then mutate the project state. Optionally update the local state with optimistic data before the API call
  // TODO: Further generalize this? Let's start with this for now
  const mutateUpdate = async (newProjectPartial, optimistic = true) => {
    const apiMutator = async (projectLocal) =>
      projectApi
        .updatePartial({
          projectId: projectLocal.raw.id,
          project: newProjectPartial,
        })
        .then(prepareProjectResponse);

    if (optimistic) {
      return mutateProjectOptimistic({
        newObject: prepareProject({ ...rawProject, ...newProjectPartial }),
        mutator: apiMutator,
      });
    }
    return mutateProject({ mutator: apiMutator });
  };

  // Delete the project
  const deleteProject = async (optimistic = true) => {
    const apiMutator = async () =>
      projectApi.delete({ projectId: rawProject.id }).then(() => null);

    if (optimistic) {
      return mutateProjectOptimistic({
        newObject: prepareProject(null),
        mutator: apiMutator,
      });
    }
    return mutateProject({ mutator: apiMutator });
  };

  // Add a survey to the project
  const addSurvey = async (survey, optimistic = true) => {
    const apiMutator = async () =>
      projectApi
        .addSurvey({ projectId: rawProject.id, survey })
        .then(prepareProjectResponse);

    if (optimistic) {
      return mutateProjectOptimistic({
        newObject: prepareProject({
          ...rawProject,
          surveys: [survey, ...rawProject.surveys],
        }),
        mutator: apiMutator,
      });
    }
    return mutateProject({ mutator: apiMutator });
  };

  // Delete a survey from the project
  const deleteSurvey = async (surveyId, optimistic = true) => {
    const apiMutator = async () =>
      projectApi
        .deleteSurvey({ projectId: rawProject.id, surveyId })
        .then(prepareProjectResponse);

    if (optimistic) {
      return mutateProjectOptimistic({
        newObject: prepareProject({
          ...rawProject,
          surveys: rawProject.surveys.filter((s) => s.id !== surveyId),
        }),
        mutator: apiMutator,
      });
    }
    return mutateProject({ mutator: apiMutator });
  };

  // Duplicate a survey from the project
  const duplicateSurvey = async (survey, optimistic = true) => {
    const apiMutator = async () =>
      projectApi
        .duplicateSurvey({ projectId: rawProject.id, surveyId: survey.id })
        .then(prepareProjectResponse);

    if (optimistic) {
      return mutateProjectOptimistic({
        newObject: prepareProject({
          ...rawProject,
          surveys: [
            {
              ...survey,
              name: `Copy of ${survey.name}`,
              id: `${survey.id}-copy`,
            },
            ...rawProject.surveys,
          ],
        }),
        mutator: apiMutator,
      });
    }
    return mutateProject({ mutator: apiMutator });
  };

  return {
    project: formattedProject,
    rawProject,
    mutate,
    mutateUpdate,
    deleteProject,
    addSurvey,
    deleteSurvey,
    duplicateSurvey,
    isLoading: !error && !rawProject && !formattedProject,
    isError: error,
  };
};
