import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { px } from '~/legacy/utils/layoutUtils';
import TooltipV2 from './TooltipV2';

// Show a tooltip only if the child content overflows, like if text ellipsis
export const OverflowTooltip = withStyles({
  root: {
    borderRadius: '4px',
    padding: px(2, 4),
  },
  rootHovered: {
    '&:hover': {
      cursor: 'pointer',
      background: '#F3F3F3',
    },
  },
  tooltip: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  title: {
    overflow: 'auto',
  },
})(({ children, classes, title }) => {
  const childContainerRef = useRef();
  const [isOverFlowed, setIsOverflowed] = useState(false);

  // Unfortunately we have to check if the overflow occurs on hover, a plain use effect / on render doesn't seem to calculate the scroll/client width properly
  const calculateOverflowed = () => {
    const compareReal =
      childContainerRef.current.scrollWidth >
      childContainerRef.current.clientWidth;
    setIsOverflowed(compareReal);
  };

  return (
    <TooltipV2
      title={title}
      interactive
      disableHoverListener={!isOverFlowed}
      placement="bottom"
      classes={{ tooltip: classes.tooltip, title: classes.title }}
      enterDelay={0}
    >
      <div
        ref={childContainerRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'inline',
        }}
        className={clsx(classes.root, isOverFlowed ? classes.rootHovered : '')}
        onMouseEnter={calculateOverflowed}
      >
        {children}
      </div>
    </TooltipV2>
  );
});
