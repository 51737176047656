import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Fade } from '@material-ui/core';
import { withStyles, styled } from '@material-ui/core/styles';

import {
  UserAvatar,
  Linkify,
  Typography,
  TooltipV2 as Tooltip,
  MoreIcon,
  SquareIconButton,
} from '~/legacy/components';
import {
  getEventDateDisplayString,
  formatTooltipDate,
  getAuthorName,
  userOwnsActivityItem,
} from './helpers';

const RowDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
});

const ActivityText = styled(Typography)({
  fontSize: '14px',
  lineHeight: '21px',
  letterSpacing: '0.1px',
});

export const ActivityItem = withStyles((theme) => ({
  activityContainer: {
    paddingLeft: '20px',
    overflowWrap: 'anywhere',
    width: '100%',
  },
  avatar: {
    alignSelf: 'flex-start',
  },
  moreOptionsIcon: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    marginRight: '8px',
  },
  time: {
    paddingLeft: '12px',
    color: theme.custom.secondaryText.color,
  },
  root: {},
}))(
  ({
    classes,
    activity,
    children,
    currentDate,
    editable = false,
    openMenu = () => {},
    isMenuOpen = false,
  }) => {
    const [isHovering, setIsHovering] = useState(false);
    const user = useSelector((s) => s.user);
    const activityDate = new Date(activity.date);

    return (
      <RowDiv
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={classes.root}
      >
        {/* The user's avatar who published the activity */}
        <UserAvatar className={classes.avatar} user={activity.author} />

        {/* The activity content */}
        <div className={classes.activityContainer}>
          <RowDiv>
            {activity.author && (
              <ActivityText variant="boldText">
                {getAuthorName(activity)}
              </ActivityText>
            )}
            <Tooltip title={formatTooltipDate(activityDate)}>
              <ActivityText className={classes.time}>
                {`${getEventDateDisplayString(activityDate, currentDate)}`}
              </ActivityText>
            </Tooltip>
          </RowDiv>
          {children}
        </div>

        {/* If editable, the edit menu */}
        {editable && userOwnsActivityItem(activity, user) && (
          <Fade in={!!isHovering || !!isMenuOpen}>
            <SquareIconButton
              onClick={(event) => openMenu(event, activity)}
              className={classes.moreOptionsIcon}
            >
              <MoreIcon />
            </SquareIconButton>
          </Fade>
        )}
      </RowDiv>
    );
  }
);

const ActivityTextFormattedRootDiv = styled('div')({
  marginTop: '2px',
});

export const ActivityTextFormatted = withStyles({
  multilineText: {
    whiteSpace: 'pre-line',
  },
})(({ classes, children }) => {
  return (
    <Linkify>
      <ActivityTextFormattedRootDiv>
        <ActivityText display="inline" className={classes.multilineText}>
          {children}
        </ActivityText>
      </ActivityTextFormattedRootDiv>
    </Linkify>
  );
});
