import React from 'react';
import {
  InputAdornment,
  makeStyles,
  TextField,
  lighten,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DeleteXIconStandardSize, TextChip } from '~/legacy/components';

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
  },
  chipDeleteIcon: {
    color: theme.palette.primary.main,
  },
  clearIcon: {
    color: '#e0e0e0',
    marginRight: '-19px',
    cursor: 'pointer',
  },
}));

function BuildingAmenities({ amenities = [], updateAmenities }) {
  const classes = useStyles();
  return (
    <Autocomplete
      freeSolo
      multiple
      options={[]}
      onChange={(event, newAmenitiesArray) => {
        updateAmenities(newAmenitiesArray);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: amenities.length > 0 && (
              <InputAdornment className={classes.clearIcon} position="end">
                <DeleteXIconStandardSize onClick={() => updateAmenities([])} />
              </InputAdornment>
            ),
          }}
          placeholder={amenities.length === 0 ? 'Amenities' : ''}
          variant="outlined"
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <TextChip
            classes={{
              root: classes.chip,
              deleteIcon: classes.chipDeleteIcon,
            }}
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      value={amenities || []}
    />
  );
}

export default BuildingAmenities;
