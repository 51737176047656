import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Avatar } from '~/legacy/components';

export const ClientAvatar = withStyles({})(
  ({ classes, className, size, client, zIndex }) => {
    return (
      <Avatar
        size={size}
        className={className}
        classes={classes}
        includeBorder
        image={client ? client.image : null}
        text={client ? client.name.charAt(0) : ''}
        zIndex={zIndex}
      />
    );
  }
);
