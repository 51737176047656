import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import { Marker } from '@react-google-maps/api';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    width: '500px',
  },
}));

/**
 * A clickable google map marker for a listing
 */
function ListingMapMarkerBase({
  listing,
  markerItemActive,
  PopoverContent,
  focused,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  let icon = null;

  if (focused) {
    icon = markerItemActive ? 'markerLarge' : 'markerSecondaryLarge';
  } else {
    icon = markerItemActive ? 'marker' : 'markerSecondary';
  }

  icon = `/static/media/${icon}.png`;

  return (
    <div>
      <Marker
        key={listing.id}
        icon={icon}
        position={{
          lat: listing.building.geo.latitude,
          lng: listing.building.geo.longitude,
        }}
        onClick={() => handleClick(event)}
        onClose={handleClose}
      />
      <Popover
        id={`popover-${listing.id}`}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        {PopoverContent}
      </Popover>
    </div>
  );
}
ListingMapMarkerBase.propTypes = {
  listing: PropTypes.object.isRequired,
  markerItemActive: PropTypes.bool,
  PopoverContent: PropTypes.element.isRequired,
  focused: PropTypes.bool,
};
ListingMapMarkerBase.defaultProps = {
  markerItemActive: true,
  focused: false,
};

export default ListingMapMarkerBase;
