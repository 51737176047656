import { count } from '../../support/count'
import { valueOf } from '../../support/valueOf'

export const defineTemplate = ({
  id = name,
  name = id,
  format,
  landscape,
  width,
  height,
  pages = [],
  settings = {},
  build = [],
  stats = ({ pages }) => {
    return count('Page', pages.length)
  },
}) => {
  return {
    id,
    name,
    format,
    landscape,
    width,
    height,
    pages,
    settings,
    stats,
    build: (pdf) => {
      return valueOf(build, pdf)
        .filter((page) => {
          return !pdf.excluded.includes(page.template)
        })
        .map((page) => {
          return page.id ? page : { id: page.template.id, ...page }
        })
    },
  }
}
