import { toast } from '~/legacy/utils/notifications'

export default {
  getProject: {
    url: '/surveys/:id/',
    options: {
      method: 'GET',
    },
  },
  getSurveys: {
    url: '/surveys/',
    options: {
      method: 'GET',
    },
  },
  getSurveysAdmin: {
    url: '/surveys/list_admin/',
    options: {
      method: 'GET',
    },
  },
  getSurveysWithListings: {
    url: '/surveys/?listingDetails=true',
    options: {
      method: 'GET',
    },
  },
  getArchivedProjects: {
    url: '/surveys/archived/',
    options: {
      method: 'GET',
    },
  },
  getTrashedProjects: {
    url: '/surveys/trashed/',
    options: {
      method: 'GET',
    },
  },
  createProject: {
    url: '/surveys/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () => {
        toast('Client created successfully', { appearance: 'success' })
      },
    ],
  },
  updateProject: {
    url: '/surveys/:id/',
    options: {
      method: 'PATCH',
    },
  },
  // TODO: check if is needed
  markListingTouredOnProject: {
    url: '/surveys/:id/tour/',
    options: {
      method: 'POST',
    },
  },
  unMarkListingTouredOnProject: {
    url: '/surveys/:id/untour/',
    options: {
      method: 'POST',
    },
  },
  trashProject: {
    url: '/surveys/:id/trash/',
    options: {
      method: 'POST',
    },
  },
  trashSurveys: {
    url: '/surveys/trash_surveys/',
    options: {
      method: 'POST',
    },
  },
  unTrashProject: {
    url: '/surveys/:id/untrash/',
    options: {
      method: 'POST',
    },
  },
  removeUserFromSurvey: {
    url: '/surveys/:survey_id/remove_user/',
    options: {
      method: 'POST',
    },
  },
  getDashboardListings: {
    url: '/surveys/dashboard/',
  },
  getTenantInformation: {
    url: '/surveys/:id/from_tenant/',
  },
  getInviteLink: {
    url: '/surveys/:id/invite_link/:type/',
  },
  getCompanyUsers: {
    url: '/surveys/:id/company_users_for_survey/',
  },
  remove_survey_listings: {
    url: '/surveys/:id/remove_survey_listings/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () =>
        toast('Spaces successfully removed from survey!', {
          appearance: 'success',
        }),
    ],
  },
  remove_survey_building: {
    url: '/surveys/:id/remove_survey_building/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () =>
        toast('Building successfully removed from survey!', {
          appearance: 'success',
        }),
    ],
  },
  update_order: {
    url: '/surveys/:id/update_order/',
    options: {
      method: 'POST',
    },
  },
  getSurveyActivity: {
    url: '/surveys/:id/activity/',
    options: {
      method: 'GET',
    },
  },
  getSurveyBuildings: {
    url: '/surveys/:id/survey_buildings/',
    options: {
      method: 'GET',
    },
  },
  getSurveyBuilding: {
    url: '/surveys/:id/survey_building/:building_id/',
    options: {
      method: 'GET',
    },
  },
  exportSurvey: {
    url: '/surveys/:id/export/',
    options: {
      method: 'POST',
    },
  },
}
