export const openLink = (link) => (event) => {
  event.preventDefault()
  event.stopPropagation()

  if (event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1) {
    window.open(link, '_blank', 'noopener,noreferrer')
  } else {
    window.location = link
  }
}
