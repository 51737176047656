import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { StreetViewPanorama } from '@react-google-maps/api';
import { ModalComponent } from '~/legacy/components';
import FullScreenTitle from './FullScreenTitle';
import SurveyBuildingsMap from '../SurveyBuildingsMap';

const useStyles = makeStyles({
  modal: {
    width: '100vw',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:first-child': {
      padding: '0',
    },
  },
  body: {
    width: '100%',
    height: '100%',
    padding: '32px',
    marginTop: '60px', // same size as header
  },
});

// expects [{ label: '', images: [] }, {...}]
export default function BuildingMapModal({
  title,
  primarySurveyBuilding,
  surveyBuildings,
  surveyId,
  ModalComponentProps,
}) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeMapMarker, setActiveMapMarker] = useState(null);

  return (
    <ModalComponent
      classesIn={{ dialogContent: classes.modal }}
      fullScreen
      onClose={() => {
        setSelectedTab(0);
        ModalComponentProps.onClose();
      }}
      {...ModalComponentProps}
    >
      <FullScreenTitle
        title={title}
        onClose={() => ModalComponentProps.onClose()}
        tabLabels={['Map', 'Street View']}
        selectedTab={selectedTab}
        setSelectedTab={(selectedTabIndex) => setSelectedTab(selectedTabIndex)}
      />
      <div className={classes.body}>
        <SurveyBuildingsMap
          primarySurveyBuildingId={primarySurveyBuilding.id}
          surveyBuildings={surveyBuildings}
          surveyId={surveyId}
          activeMapMarker={activeMapMarker}
          setActiveMapMarker={setActiveMapMarker}
        >
          <StreetViewPanorama
            onLoad={(streetViewPanorama) =>
              streetViewPanorama.setPosition({
                lat: primarySurveyBuilding.building.geo.latitude,
                lng: primarySurveyBuilding.building.geo.longitude,
              })
            }
            options={{
              disableDefaultUI: true,
              enableCloseButton: false,
            }}
            visible={selectedTab === 1}
          />
        </SurveyBuildingsMap>
      </div>
    </ModalComponent>
  );
}
