import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { isBroker, IsPreviewContext } from '~/legacy/utils'
import { Link, Typography } from '~/legacy/components'
import SpaceCardNew from '~/legacy/components/SpaceCardNew'
import EditSpacesModalNew from '~/legacy/components/modalComponents/EditSpacesModalNew'
import { useSurveyListingCustomFieldSelector } from '~/legacy/utils/hooks/useSurveyListingCustomFieldSelector'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'
import { formatCustomFieldsForDisplayProperties } from '~/legacy/utils/listingHelpers'

const useStyles = makeStyles({
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spaces: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  spaceCard: {
    '&:not(:last-child)': {
      marginBottom: '16px',
    },
  },
})

export default function SpacesSectionNew({
  className,
  surveySpaces,
  surveyId,
  surveyName,
  surveyBuilding,
  buildingPrimaryName,
  mutate,
  deleteSurveyListing,
  updateListing,
  clearSpacesField,
  createSurveyListing,
  copySpacesFromSurvey,
}) {
  const classes = useStyles()
  const [isEditingSpaces, setIsEditingSpaces] = useState(false)
  const isUserBroker = useSelector((s) => isBroker(s.user))
  const { isPreview } = React.useContext(IsPreviewContext)
  const spaces = surveySpaces.map((sl) => sl.listing)

  const { customFieldValues } = useSurveyListingCustomFieldSelector({
    surveyId,
    buildingId: surveyBuilding.building.id,
  })

  const { mutate: refetch, fieldMap } = useSurveyCustomFieldsSelector({
    surveyId,
    enabled: Boolean(surveyId),
  })

  // FIXME: This is probably a temporary fix. At some point we should handle the custom field definitions
  // and the custom field values in the same hook ???
  const customFieldValuesForDisplay = useMemo(() => {
    return formatCustomFieldsForDisplayProperties(customFieldValues, fieldMap)
  }, [fieldMap, customFieldValues])

  const valuesBySpaceId = _.groupBy(
    customFieldValuesForDisplay,
    'original.listing_id'
  )

  return (
    <div className={className}>
      {spaces.length ? (
        <>
          <div className={classes.titleSection}>
            <Typography variant="pageSubTitle">Spaces</Typography>
            {!!isUserBroker && !isPreview && (
              <Link onClick={() => setIsEditingSpaces(true)}>
                <Typography variant="boldText">Add/Edit Spaces</Typography>
              </Link>
            )}
          </div>
          <div className={classes.spaces}>
            {spaces.map((space) => (
              <SpaceCardNew
                key={space.id}
                className={classes.spaceCard}
                space={space}
                updateListing={updateListing}
                values={valuesBySpaceId[space.id] || []}
              />
            ))}
          </div>
        </>
      ) : (
        <SpaceCardNew
          className={classes.spaceCard}
          createSurveyListing={async () => {
            setIsEditingSpaces(true)
            await createSurveyListing({ surveyBuildingId: surveyBuilding.id })
            refetch()
          }}
          isPlaceholder
        />
      )}
      <EditSpacesModalNew
        fullscreen
        ModalComponentProps={{
          open: isEditingSpaces,
          onClose: () => setIsEditingSpaces(false),
        }}
        buildingName={buildingPrimaryName}
        surveyId={surveyId}
        surveyName={surveyName}
        surveyBuilding={surveyBuilding}
        surveySpaces={surveySpaces}
        mutate={mutate}
        deleteSurveyListing={deleteSurveyListing}
        updateListing={updateListing}
        clearSpacesField={clearSpacesField}
        createSurveyListing={createSurveyListing}
        copySpacesFromSurvey={copySpacesFromSurvey}
      />
    </div>
  )
}
