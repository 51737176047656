import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, MenuItem } from '~/legacy/components';
import { BASE_ICON_STYLES } from '~/legacy/components/buttons/ButtonUtils';
import { CopySpacesModalContent } from '~/legacy/components/modalComponents';
import { ActionModal } from '~/legacy/components/modals';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
  textInputFieldContainer: {
    width: '100%',
    marginTop: '10px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

// A modal to edit a project
export const AddExistingSpacesToBuildingModal = ({
  surveyBuilding,
  otherBuildingSurveys,
  copySpacesFromSurvey,
  open,
  onClose,
}) => {
  const closeModal = () => {
    onClose();
  };

  const [selectedSurveyId, setSelectedSurveyId] = useState(null);

  const onConfirm = async () => {
    try {
      closeModal();
      await copySpacesFromSurvey({ copySpacesFromSurveyId: selectedSurveyId });
    } catch {
      // supress
    }
  };

  return (
    <ActionModal
      onClose={closeModal}
      onConfirm={onConfirm}
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      title="Add Spaces to Building"
      confirmButtonLabel="Add to Building"
    >
      <CopySpacesModalContent
        building={surveyBuilding ? surveyBuilding.building : null}
        buildingSurveys={otherBuildingSurveys}
        surveyIdToCopy={selectedSurveyId}
        setSurveyIdToCopy={setSelectedSurveyId}
      />
    </ActionModal>
  );
};

// Menu item to edit a project
export const AddExistingSpacesToBuildingMenItem = React.forwardRef(
  ({ otherBuildingSurveys, openModal, ...props }, ref) => {
    const classes = useStyles();

    return [
      <MenuItem
        key="add-existing-building-menu-item"
        ref={ref}
        className={classes.menuItemRoot}
        onClick={openModal}
        disabled={!otherBuildingSurveys || !otherBuildingSurveys.length}
        {...props}
      >
        <Typography className={classes.text}> Add Existing Spaces </Typography>
      </MenuItem>,
    ];
  }
);

// Bundle the modal and the menu item together for maximum convenience
export const useAddExistingSpacesToBuildingMenuItem = ({
  surveyBuilding,
  surveyId,
  copySpacesFromSurvey,
  handleMenuClose,
}) => {
  const [open, setOpen] = React.useState(false);

  //  Other surveys for the user that have this building and with spaces
  let otherBuildingSurveys = [];
  const existingBuildingHasOtherSurveys = !!(
    surveyBuilding &&
    surveyBuilding.building.surveys_with_spaces &&
    surveyBuilding.building.surveys_with_spaces.length
  );
  if (existingBuildingHasOtherSurveys) {
    otherBuildingSurveys = surveyBuilding.building.surveys_with_spaces.filter(
      (survey) => survey.id !== surveyId
    );
  }

  const AddExistingSpacesToBuildingMenuItemComponent = (
    <AddExistingSpacesToBuildingMenItem
      otherBuildingSurveys={otherBuildingSurveys}
      openModal={() => setOpen(true)}
    />
  );

  const AddExistingSpacesToBuildingModalComponent = (
    <AddExistingSpacesToBuildingModal
      key="add-to-building-menu-modal"
      surveyBuilding={surveyBuilding}
      otherBuildingSurveys={otherBuildingSurveys}
      copySpacesFromSurvey={copySpacesFromSurvey}
      open={open}
      onClose={() => {
        setOpen(false);
        handleMenuClose();
      }}
    />
  );

  return {
    AddExistingSpacesToBuildingMenuItemComponent,
    AddExistingSpacesToBuildingModalComponent,
  };
};
