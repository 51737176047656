import { format as date } from 'date-fns'

const currency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)
}

const format = (format) => (value, options) => {
  return value == null || value === '' ? '' : format(value, options)
}

const number = (value) => {
  return new Intl.NumberFormat().format(value)
}

export const Area = {
  id: 'b0b5b2c0-3b9a-4b0f-8b1f-5f9f2b0b0b0b',
  name: 'Size in sqft',
  format: format((value) => {
    return `${number(value)} sqft`
  }),
}

export const Currency = {
  id: '0c40e012-03df-4d66-9b31-38b01f0fa491',
  name: 'Currency',
  format: format(currency),
}

export const Date = {
  id: '82141048-29f0-46b7-ad01-d1678b19cbb0',
  name: 'Date',
  format: format((value, options) => {
    try {
      return date(value, options?.format || 'MMM d, yyyy')
    } catch {
      return ''
    }
  }),
}

export const Number = {
  id: '996b6a01-10a0-46a6-a204-88a6812cf5a7',
  name: 'Number',
  format: format(number),
}

export const Text = {
  id: '730682da-23be-4f8d-85b9-115c13d90e40',
  name: 'Text',
  format: format(String),
}

export const BuildingDescription = {
  id: -5,
  key: 'description',
  name: 'Building Description',
  type: Text,
}

export const BuildingSize = {
  id: -3,
  key: 'building_size',
  name: 'Building Size',
  type: Area,
}

export const DateAvailable = {
  id: -3,
  key: 'date_available',
  name: 'Date Available',
  type: Date,
}

export const Floors = {
  id: -2,
  key: 'floors',
  name: 'Floors',
  type: Number,
}

export const LeaseTerm = {
  id: -1,
  key: 'lease_term',
  name: 'Lease Term',
  type: Text,
}

export const LeaseType = {
  id: -2,
  key: 'lease_type',
  name: 'Lease Type',
  type: Text,
}

export const ParkingRatio = {
  id: -4,
  key: 'parking_ratio',
  name: 'Parking Ratio',
  type: Number,
}

export const Price = {
  id: -4,
  key: 'sqft_price',
  name: 'Price / SqFt',
  type: Currency,
}

export const PropertyType = {
  id: -1,
  key: 'property_type',
  name: 'Property Type',
  type: Text,
}

export const Size = {
  id: -5,
  key: 'size',
  name: 'SqFt Available',
  type: Area,
}

export const SpaceName = {
  id: -8,
  key: 'address2',
  name: 'Space Name',
  type: Text,
}

export const SpaceNotes = {
  id: -7,
  key: 'description',
  name: 'Space Notes',
  type: Text,
}

export const VacancyType = {
  id: -6,
  key: 'vacancy_type',
  name: 'Vacancy Type',
  type: Text,
}
