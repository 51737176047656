import React from 'react'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { CresaDivider } from '../patterns/CresaDivider'

export const CresaPropertyDivider = definePageTemplate({
  id: 'b154b58b-d1f4-4536-a972-fdc3744af049',

  name: 'Property Information Divider',

  Page: () => {
    return <CresaDivider title="Property Information" />
  },
})
