/* eslint-disable camelcase */
import { useContext } from 'react'
import { Firm } from '~/services/Firm'
import { getSubdomain } from '~/support/getSubdomain'

export const getSubDomain = () => {
  const url = window.location.hostname
  const matchedUrlParts = url.match(
    /((app[.])|(staging[.])|((?<subDomain>[a-zA-Z0-9-]+)[.])?)((staging)[.])?((leaseup[.]co)|(leaseup-test[.]co)|(localhost))/
  )
  return matchedUrlParts &&
    matchedUrlParts.groups &&
    matchedUrlParts.groups.subDomain
    ? matchedUrlParts.groups.subDomain
    : null
}

export const getSkinString = () => {
  return getSubdomain() || localStorage.skin
}

export const useLogoUrl = () => {
  const data = useLogoDataForPdf()
  return data.logoUrl
}

const assetUrl = (path = '') =>
  `https://leaseup-production.s3.us-east-2.amazonaws.com${path}`

const orgLogoUrl = (path) => assetUrl(`/company_logos${path}`)

const leaseUpLogoUrl = orgLogoUrl('/leaseup_logo_v2.png')

export const useIsLeaseupLogo = () => {
  const data = useLogoDataForPdf()
  return data.logoUrl === leaseUpLogoUrl
}

export const useLogoDataForPdf = () => {
  const firm = useContext(Firm)

  return {
    logoUrl: firm?.skin?.logo_url || leaseUpLogoUrl,
    height: firm.pdfLogoHeight || '45%',
  }
}
