import React from 'react';
import { makeStyles } from '@material-ui/core';
import range from 'lodash/range';
import { SimpleCardImage } from '~/legacy/components';

import { ListingHeader } from '../Headers';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  dataContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    padding: '22px 12px',
    borderBottom: `8px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  oneOrTwoImagesContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  manyImagesContainer: {
    height: (props) => {
      if (props.imageCount <= 4) {
        return '50%';
      }
      if (props.imageCount <= 6) {
        return '75%';
      }
      return '100%';
    },
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: (props) =>
      `repeat(${Math.ceil(props.imageCount / 2)}, 1fr)`,
    gap: '4px',
    overflow: 'hidden',
  },
  oneOrTwoImageContainer: {
    overflow: 'hidden',
    flex: 1,
    padding: '0 12px',
    margin: '4px 0',
  },
  manyImageContainer: {
    overflow: 'hidden',
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: (props) =>
      props.imageCount === 1 || !props.cropImages ? 'contain' : 'cover',
  },
}));

function ListingPhotosPageLayout1({ listing, listingOrder, settings }) {
  const { imageLimit, cropImages } = settings;
  const imageCount = Math.min(imageLimit || 0, listing.images.length);
  const classes = useStyles({ imageCount, cropImages });

  return (
    <div className={classes.container}>
      <ListingHeader listing={listing} index={listingOrder} />
      <div className={classes.dataContainer}>
        <div
          className={
            imageCount <= 2
              ? classes.oneOrTwoImagesContainer
              : classes.manyImagesContainer
          }
        >
          {range(imageCount).map(
            (index) =>
              listing.images[index] && (
                <div
                  key={listing.images[index].id}
                  className={
                    imageCount <= 2
                      ? classes.oneOrTwoImageContainer
                      : classes.manyImageContainer
                  }
                >
                  <SimpleCardImage
                    key={listing.images[index].id}
                    imageFile={listing.images[index]}
                    ImgProps={{
                      alt: 'listing',
                      className: classes.image,
                    }}
                  />
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
}

export default ListingPhotosPageLayout1;
