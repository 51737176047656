import React from 'react'
import { SurveyIcon } from '~/legacy/components'
import { ProjectList } from '../components/ProjectList'
import { useProjects } from '../hooks/useProjects'
import { Empty } from '../components/Empty'

// bug with onAction throwing a console error if not explicit
export const Projects = ({ id, onAction, ...props }) => {
  const { data } = useProjects(id)

  return (
    <ProjectList data={data} onAction={onAction} {...props}>
      <Empty icon={<SurveyIcon />} {...props}>
        Projects facilitate client searches.
        <br />
        To get started, create a project.
      </Empty>
    </ProjectList>
  )
}
