import React from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Link = withStyles({
  root: {
    cursor: 'pointer',
  },
})(({ classes, children, ...props }) => {
  return (
    <MuiLink classes={classes} underline="none" {...props}>
      {children}
    </MuiLink>
  );
});
