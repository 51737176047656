import React from 'react'
import {
  Scroll,
  SquareIconButton,
  CloseIcon,
  Spacer,
  TextInput,
} from '~/legacy/components'
import { styled } from '~/legacy/utils/styleHelpers'
import { ActivityMessage } from '~/legacy/components/ActivityMessage'
import { ActivityDivider } from '~/legacy/components/ActivityDivider'
import { ActivityCard } from '~/legacy/components/ActivityCard'
import { ModalOverlay } from '~/legacy/components/ModalOverlay'

export const Activity = ({ onClose, ...props }) => {
  return (
    <ModalOverlay onClose={onClose}>
      <Container {...props}>
        <Header>
          Activity
          <Spacer />
          <SquareIconButton onClick={onClose}>
            <CloseIcon />
          </SquareIconButton>
        </Header>
        <Scroll>
          <Content>
            <ActivityMessage
              title="Reminder"
              subtitle="10 Min Ago"
              text={
                <span>
                  Unified Logistics hasn&apos;t viewed the survey
                  {' '}
                  <Link>SW Austin</Link>
                  {' '}
since you shared it with them 3 days
                  ago
                </span>
              }
            />

            <ActivityDivider>April 11, 2022</ActivityDivider>

            <ActivityMessage
              avatar={<Avatar src="/prototype/Profile4.png" />}
              title="Grant Finklea, AV Engineering Group"
              subtitle="1 Day Ago"
              text={
                <span>
                  Changed status on a building in
                  <Link> Domain Austin</Link>
                </span>
              }
            >
              <ActivityCard image="/prototype/Message1.png">
                <div>
                  <Chiclet>Not Interested</Chiclet>
                </div>
                <Address>
                  12502 Century Oaks Terrace
                  <br />
                  Austin, TX 78758
                </Address>
              </ActivityCard>
            </ActivityMessage>

            <ActivityMessage
              avatar={<Avatar src="/prototype/Profile4.png" />}
              title="Grant Finklea, AV Engineering Group"
              subtitle="1 Day Ago"
              text={
                <span>
                  Changed status on a building in
                  <Link> Domain Austin</Link>
                </span>
              }
            >
              <ActivityCard image="/prototype/Message2.png">
                &quot;Do you know if the parking lot has electric chargers? I
                can&apos;t see in ...
                <Address>17856 Domain Boulevard</Address>
              </ActivityCard>
            </ActivityMessage>

            <ActivityMessage
              avatar={<Avatar src="/prototype/Profile4.png" />}
              title="Grant Finklea, AV Engineering Group"
              subtitle="1 Day Ago"
              text={
                <span>
                  Changed status on a building in
                  <Link> Domain Austin</Link>
                </span>
              }
            >
              <ActivityCard image="/prototype/Message1.png">
                <div>
                  <Chiclet branded>Favorited</Chiclet>
                </div>
                <Address>
                  12502 Century Oaks Terrace
                  <br />
                  Austin, TX 78758
                </Address>
              </ActivityCard>
            </ActivityMessage>

            <ActivityDivider>April 10, 2022</ActivityDivider>
          </Content>
        </Scroll>
        <Footer>
          <TextInput label="Send a message" />
        </Footer>
      </Container>
    </ModalOverlay>
  )
}

const Container = styled('div', {
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  width: 480,
  zIndex: 10000001,
})

const Header = styled('div', {
  borderBottom: '1px solid #E0E0E0',
  alignItems: 'center',
  display: 'flex',
  height: 60,
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '22px',
  letterSpacing: '0.1px',
  color: '#111111',
  paddingLeft: 32,
  paddingRight: 24,
})

const Footer = styled('div', {
  padding: '16px 24px 24px',
  borderTop: '1px solid #E0E0E0',
})

const Content = styled('div', {
  padding: '32px 24px',
})

const Avatar = styled('img', {
  backgroundColor: 'rgba(30, 132, 122, 0.1)',
  borderRadius: '100%',
  height: '32px',
  outline: '1px solid #e0e0e0',
  outlineOffset: '-1px',
  width: '32px',
  minWidth: '32px',
})

const Chiclet = styled('span', ['branded'], (props) => ({
  borderRadius: '2px',
  padding: '4px 6px',
  backgroundColor: props.when(
    'branded',
    props.get('theme.palette.primary.main'),
    '#f3f3f3'
  ),
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '12px',
  letterSpacing: '0.4px',
  color: props.when('branded', 'white', '#111'),
  textTransform: 'uppercase',
}))

const Address = styled('div', {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: '0.1px',
  color: '#111',
})

const Link = styled('span', (props) => ({
  color: props.get('theme.palette.primary.main'),
  fontWeight: 600,
}))
