import { format } from 'date-fns';
import { ACTIONS as MISC_ACTIONS } from '../misc';

const ACTIONS = {
  EDIT_KEY_VALUE_STRING: 'addListing_edit_key_value_string',
  SET_INITIAL_DATA: 'editListing_set_initial_data',
};

const initialState = {
  name: '',
  building: {
    address: '',
    city: '',
    zipcode: '',
    state: '',
    buildingSize: '',
    building_size: null,
    amenities: [],
    propertyType: null,
    parking_ratio: null,
    floors: null,
  },
  address2: '',
  sqft_price: 0,
  monthly_price: 0,
  negotiable: false,
  vacancyType: null,
  size: '',
  leaseTerm: '',
  availability: '',
  availabilityDate: '',
  date_available: format(new Date(), 'yyyy-MM-dd'),
  lease_term: '',
  lease_type: '',
  features: [],
  description: '',
  images: [],
  status: 'INTERNAL',
  project_id: null,
  attachments: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.EDIT_KEY_VALUE_STRING:
      return {
        ...state,
        [action.key]: action.value,
      };
    case ACTIONS.SET_INITIAL_DATA: {
      const newState = { ...initialState, ...action.payload };
      if (!newState.amenities) {
        newState.amenities = [];
      }
      return newState;
    }
    case MISC_ACTIONS.LOCATION_CHANGED:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

export const addListingActions = {
  editKeyValueString: (key, value) => ({
    type: ACTIONS.EDIT_KEY_VALUE_STRING,
    key,
    value,
  }),
  setInitialData: payload => ({
    type: ACTIONS.SET_INITIAL_DATA,
    payload,
  }),
};
