import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'
import lodash from 'lodash'
import clsx from 'clsx'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  setSurveyBuildings,
  setSurveyListings,
} from '~/legacy/store/actions/viewSurvey'
import {
  ExpandLessIcon,
  ExpandMoreIcon,
  SquareCheckbox,
  Loading,
  DraggableWrapper,
  DraggableTableBodyWrapper,
  DeclineListingModalReal,
  SquareIconButton,
  Modal,
  MODALS,
  getSurveyListingTableScrollToId,
} from '~/legacy/components'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'

import NonNestedSurveyListingRowNew from './NonNestedSurveyListingRowNew'
import QuickEntry from './QuickEntry'
import { SURVEY_LISTING_TABLE_STYLES } from './SurveyListingTableComponents'

const ORDER_SAVE_WAIT_TIME = 1750 // ms

const SurveyListingTableNew = ({
  isUserInBuildout,
  history,
  surveyId,
  surveyBuildingsMap,
  setSurveyBuildingsMap,
  selectedSurveyListings,
  setSelectedSurveyListings,
  isBroker,
  setHoveredBuildingId = () => {},
  isCondensed = false,
  isLoading = false,
  setBackdropOpen,
  setLoadedListingsFromCsv,
  setRawCsvHeaders,
  setBulkImportFieldMatchModalOpen,
  setUploadedCsvFile,
  activeMapMarker,
  ...props
}) => {
  const { classes } = props
  const dispatch = useDispatch()

  const { listingCustomFields } = useSurveyCustomFieldsSelector({
    surveyId,
    enabled: Boolean(surveyId),
  })

  const user = useSelector((store) => store.user)
  const surveyListings = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  )
  const surveyBuildings = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  )
  const tableData = surveyBuildingsMap
  const setTableData = setSurveyBuildingsMap

  const visibleCustomFieldsCount = isCondensed ? 2 : 5
  const missingCustomFieldsCount =
    visibleCustomFieldsCount - listingCustomFields.length
  const missingCustomFieldCols =
    missingCustomFieldsCount > 0
      ? Array(missingCustomFieldsCount)
          .fill(null)
          .map((_, index) => index)
      : []
  const visibleCustomFields = listingCustomFields.slice(
    0,
    visibleCustomFieldsCount
  )

  const [collapsedBuildings, setCollapsedBuildings] = useState({})
  const [canCollapseAll, setCanCollapseAll] = useState(true)
  const [surveyListingToDecline, setSurveyListingToDecline] = useState()
  const [
    uploadBuildingHeroPhotoModalData,
    setUploadBuildingHeroPhotoModalData,
  ] = useState(null)

  const surveyListingsRef = useRef(surveyListings)
  const surveyBuildingsRef = useRef(surveyBuildings)
  useEffect(() => {
    surveyListingsRef.current = surveyListings
    surveyBuildingsRef.current = surveyBuildings
  }, [surveyListings, surveyBuildings])

  // Scroll to the active map marker building
  useEffect(() => {
    if (activeMapMarker) {
      const firstSurveyListingInBuilding = surveyListings.find(
        (surveyListing) =>
          surveyListing.listing.building.id === activeMapMarker.buildingId
      )
      if (firstSurveyListingInBuilding) {
        const elementId = getSurveyListingTableScrollToId(
          firstSurveyListingInBuilding.listing.building.id
        )
        const el = document.getElementById(elementId)
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
    }
  }, [activeMapMarker, tableData])

  // Update the order of the survey listings in the DB via API
  const saveOrder = () => {
    const newOrder = {}
    // Go through each building in order and add to result set
    if (isUserInBuildout) {
      surveyBuildingsRef.current.forEach((sb) => {
        newOrder[sb.building.id] = {
          order: sb.order,
          survey_listings: [],
        }
      })
    }
    // Now go through the survey listing order
    surveyListingsRef.current.forEach((sl) => {
      if (newOrder[sl.listing.building.id]) {
        newOrder[sl.listing.building.id].survey_listings.push(sl.id)
      } else {
        newOrder[sl.listing.building.id] = {
          order: lodash.find(
            surveyBuildingsRef.current,
            (sb) => sb.building.id === sl.listing.building.id
          ).order,
          survey_listings: [sl.id],
        }
      }
    })

    // re-set the order building order here incase we have bad data in the DB - non-consecutive integers,
    //   nulls, duplicates, etc. SurveyListing order gets reset on the back end
    lodash
      .orderBy(Object.keys(newOrder), (b_id) => newOrder[b_id].order)
      .forEach((buildingId, index) => {
        newOrder[buildingId].order = index
      })

    Api.update_order({ id: surveyId, body: { new_order: newOrder } })
  }

  // A callback to save save the survey notes after a timeout of inactivity on the notes text area
  const delayedOrderSave = useCallback(
    lodash.debounce(() => saveOrder(), ORDER_SAVE_WAIT_TIME),
    [surveyId, surveyBuildings]
  )

  // Drag a row in the table - we move the component and lodash a backend update
  // There are two types of drags here
  // 1. Dragging a non nested row (an individual survey listing or a building row)
  // 2. Dragging a survey listing nested under a building row. Indicated by buildingIndex
  const handleDragEnd = (result, buildingIndex) => {
    if (!result.destination) {
      return
    }

    const destinationIndex = result.destination.index
    const sourceIndex = result.source.index
    // Moving to same place
    if (destinationIndex === sourceIndex) {
      return
    }

    // If we are re-organizing survey listings within a building, then the movingData
    //   is just the building's survey listings
    let movingData = tableData
    if (buildingIndex !== undefined) {
      movingData = tableData[buildingIndex].surveyListings
    }

    // Insert the row being moved
    const beingMoved = movingData[sourceIndex]

    // Remove the element being moved
    const beforeSource = movingData.slice(0, sourceIndex)
    const afterSource = movingData.slice(sourceIndex + 1)
    const withoutMoved = [...beforeSource, ...afterSource]
    // Add it back in at the desired index
    const beforeDest = withoutMoved.slice(0, destinationIndex)
    const afterDest = withoutMoved.slice(destinationIndex)
    let withMoved = [...beforeDest, beingMoved, ...afterDest]
    // Set the order prop of each element properly. This is all that's really needed
    //   since we order movingData in useEffect based on order anyway
    withMoved = withMoved.map((element, index) => ({
      ...element,
      order: index,
    }))

    // If for a building's survey listings, swap our modified building row with the proper
    //   nested survey listings order into our list of nonnested rows
    let shadowTableData = [].concat(tableData)
    if (buildingIndex !== undefined) {
      shadowTableData[buildingIndex].surveyListings = withMoved
    } else {
      shadowTableData = withMoved
    }

    // Update the redux store with the new order, then lodash save via API.
    // Looks much cleaner to update the visual state before waiting for the delayed lodash api
    //   call or even the dispatch to update it.
    // TODO: We dispatch AND set the table data here which is redundant. Both result in the same
    //   eventual value for tableData, but waiting for the dispatch causes some visual delay in the table.
    //   To further optimize we could prevent this action from triggering our useEffect.
    //   adds enough of a delay in the state
    // TODO: Could add a flush on this lodash when user exits tab
    setTableData(shadowTableData)

    // Exctact the surveyListings from the building data
    if (buildingIndex !== undefined) {
      tableData.forEach((buildingRow, index) => {
        if (index !== buildingIndex) {
          withMoved = withMoved.concat(buildingRow.surveyListings)
        }
      })
      dispatch(setSurveyListings(withMoved))
    } else {
      const newSurveyBuildings = []
      surveyBuildings.forEach((sb) => {
        newSurveyBuildings.push({
          ...sb,
          order: lodash.find(
            withMoved,
            (row) => row.building.id === sb.building.id
          ).order,
        })
      })
      dispatch(setSurveyBuildings(newSurveyBuildings))
    }

    delayedOrderSave()
  }

  // Set the table checkbox to unchecked/checked/indeterminate
  const checkedSurveyListings = surveyListings.filter(
    (surveyListing) => selectedSurveyListings[surveyListing.id]
  )
  const indeterminate = !!(
    checkedSurveyListings.length &&
    checkedSurveyListings.length !== surveyListings.length
  )
  const checked = checkedSurveyListings.length > 0
  // Check or uncheck all survey listings
  const onCheck = () => {
    const newSelectedSurveyListings = { ...selectedSurveyListings }
    surveyListings.forEach((surveyListing) => {
      newSelectedSurveyListings[surveyListing.id] = !checked
      setSelectedSurveyListings(newSelectedSurveyListings)
    })
  }

  const collapsedCount = useMemo(
    () =>
      Object.keys(collapsedBuildings).filter((key) => collapsedBuildings[key])
        .length,
    [collapsedBuildings]
  )
  const buildingCount = useMemo(
    () =>
      lodash.uniq(surveyListings.map((sl) => sl.listing.building.id)).length,
    [surveyListings]
  )
  useEffect(() => {
    if (collapsedCount === buildingCount) {
      setCanCollapseAll(false)
    } else if (collapsedCount === 0) {
      setCanCollapseAll(true)
    }
  }, [collapsedCount, buildingCount])

  const collapseAll = () => {
    const newCollapsed = {}
    surveyListings.forEach((sl) => {
      newCollapsed[sl.listing.building.id] = true
    })
    setCollapsedBuildings(newCollapsed)
    setCanCollapseAll(false)
  }
  const collapseNone = () => {
    const newCollapsed = {}
    Object.keys(collapsedBuildings).forEach((cb) => {
      newCollapsed[cb] = false
    })
    setCollapsedBuildings(newCollapsed)
    setCanCollapseAll(true)
  }

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table size="small" className={classes.surveyListingsTable}>
          <colgroup>
            {isBroker ? (
              <col className={classes.dragWidth} />
            ) : (
              <col className={classes.leftCheckboxSpaceWidth} />
            )}
            <col className={classes.checkboxWidth} />
            <col className={classes.expandWidth} />
            <col className={classes.heroPhotoWidth} />
            <col className={classes.addressWidth} />
            <col className={classes.otherWidth} />
            <col className={classes.otherWidth} />
            {!isCondensed && (
              <>
                <col className={classes.otherWidth} />
                <col className={classes.otherWidth} />
                {isBroker && <col className={classes.otherWidth} />}
              </>
            )}
            {isBroker ? (
              <col className={classes.moreOptionsWidth} />
            ) : (
              <col className={classes.tenantActionsWidth} />
            )}
          </colgroup>
          <TableHead>
            <TableRow className={clsx(classes.headerRow, classes.rowBorder)}>
              <TableCell
                className={clsx(classes.head, classes.headNoPadding)}
                align="center"
              >
                &nbsp;
              </TableCell>
              <TableCell
                className={clsx(
                  classes.head,
                  classes.checkboxCell,
                  classes.headNoPadding
                )}
                align="center"
              >
                <SquareCheckbox
                  checked={checked}
                  disabled={user.isAnonymous}
                  indeterminate={indeterminate}
                  onClick={onCheck}
                  className={clsx(
                    user.isAnonymous
                      ? classes.checkboxDisabled
                      : classes.checkbox
                  )}
                />
              </TableCell>
              <TableCell
                className={clsx(classes.head, classes.headNoPadding)}
                align="center"
              >
                <SquareIconButton
                  className={classes.black}
                  onClick={() =>
                    canCollapseAll ? collapseAll() : collapseNone()
                  }
                >
                  {canCollapseAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </SquareIconButton>
              </TableCell>
              <TableCell
                className={clsx(classes.head, classes.headNoPadding)}
                align="center"
              />
              <TableCell
                className={clsx(classes.head, classes.tableDataLeft)}
                align="left"
              >
                ADDRESS
              </TableCell>

              {/* Custom fields headers */}
              {visibleCustomFields.map((field) => {
                return (
                  <TableCell
                    className={clsx(classes.head, classes.tableDataLeft)}
                    align="left"
                    key={field.label}
                  >
                    {field.label.toUpperCase()}
                  </TableCell>
                )
              })}

              {/* FIXME: Find a better solution */}
              {listingCustomFields.length < visibleCustomFieldsCount
                ? missingCustomFieldCols.map((item) => (
                  <TableCell
                    className={clsx(classes.head, classes.tableDataLeft)}
                    align="left"
                    key={item}
                  />
                  ))
                : null}

              <TableCell
                className={clsx(classes.head, classes.tableDataLeft)}
                align="left"
              />
            </TableRow>
          </TableHead>
          <DraggableTableBodyWrapper
            handleDragEnd={handleDragEnd}
            isUserBroker={isBroker}
          >
            {(droppableProvided) => {
              return (
                <TableBody
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {tableData.map((buildingRow, index) => {
                    if (isUserInBuildout || buildingRow.surveyListings.length) {
                      return (
                        <DraggableWrapper
                          key={buildingRow.uuid}
                          draggableId={buildingRow.uuid}
                          index={index}
                          isUserBroker={isBroker}
                          buildingRow={buildingRow}
                        >
                          {(draggableProvided, snapshot) => {
                            return (
                              <NonNestedSurveyListingRowNew
                                isUserInBuildout={isUserInBuildout}
                                history={history}
                                key={buildingRow.building.id}
                                building={buildingRow.building}
                                surveyBuilding={buildingRow}
                                surveyListings={buildingRow.surveyListings}
                                surveyId={surveyId}
                                draggableProvided={draggableProvided}
                                isDragging={snapshot.isDragging}
                                selectedSurveyListings={selectedSurveyListings}
                                setSelectedSurveyListings={
                                  setSelectedSurveyListings
                                }
                                handleDragEnd={handleDragEnd}
                                tableIndex={index}
                                collapsedBuildings={collapsedBuildings}
                                setCollapsedBuildings={(buildingId, isOpen) =>
                                  setCollapsedBuildings({
                                    ...collapsedBuildings,
                                    [buildingId]: isOpen,
                                  })
                                }
                                onMouseEnter={() =>
                                  setHoveredBuildingId(buildingRow.building.id)
                                }
                                onMouseLeave={() => setHoveredBuildingId(false)}
                                isCondensed={isCondensed}
                                isBroker={isBroker}
                                setSurveyListingToDecline={
                                  setSurveyListingToDecline
                                }
                                uploadBuildingHeroPhotoModalData={
                                  uploadBuildingHeroPhotoModalData
                                }
                                setUploadBuildingHeroPhotoModalData={
                                  setUploadBuildingHeroPhotoModalData
                                }
                                isActive={
                                  !!(
                                    activeMapMarker &&
                                    activeMapMarker.buildingId ===
                                      buildingRow.building.id
                                  )
                                }
                                isBdpBuildout
                              />
                            )
                          }}
                        </DraggableWrapper>
                      )
                    }
                    // TODO: this should not be possible. backend must not be cleaning up
                    // survey buildings.
                    return null
                  })}
                  {droppableProvided.placeholder}
                </TableBody>
              )
            }}
          </DraggableTableBodyWrapper>
        </Table>
      </TableContainer>
      {!isLoading && isBroker && (
        <QuickEntry
          surveyListings={surveyListings}
          collapsedBuildings={collapsedBuildings}
          setCollapsedBuildings={setCollapsedBuildings}
          surveyId={surveyId}
          isUserInBuildout={isUserInBuildout}
          setBackdropOpen={setBackdropOpen}
          setLoadedListingsFromCsv={setLoadedListingsFromCsv}
          setRawCsvHeaders={setRawCsvHeaders}
          setBulkImportFieldMatchModalOpen={setBulkImportFieldMatchModalOpen}
          setUploadedCsvFile={setUploadedCsvFile}
        />
      )}
      {isLoading && (
        <div className={classes.loadingContainer}>
          <Loading isLoading size={40} className={classes.loading} />
        </div>
      )}
      <DeclineListingModalReal
        surveyListingToDecline={surveyListingToDecline}
        allSurveyListings={surveyListings}
        setSurveyListingToDecline={setSurveyListingToDecline}
      />
      <Modal
        content={MODALS.UPLOAD_BUILDING_HERO_PHOTO}
        open={
          !!(
            uploadBuildingHeroPhotoModalData &&
            uploadBuildingHeroPhotoModalData.building
          )
        }
        onClose={() => setUploadBuildingHeroPhotoModalData(null)}
        childProps={{ uploadBuildingHeroPhotoModalData }}
      />
    </>
  )
}

export default withStyles(SURVEY_LISTING_TABLE_STYLES, { withTheme: true })(
  SurveyListingTableNew
)
