import React from 'react'
import { SquareCheckbox } from '~/legacy/components'
import { styled } from '~/libraries/styled'
import { Font } from '~/components/Font'

export const Checkbox = (props) => (
  <Container
    className={props.className}
    onClick={() => props.onChange?.(!props.value)}
  >
    <Icon checked={props.value} />
    <div>
      <Font format="14/21/600">{props.label}</Font>
      {props.details && <Font format="14/21">{props.details}</Font>}
    </div>
  </Container>
)

const Container = styled('div', {
  borderBottom: '1px solid #e0e0e0',
  display: 'flex',
  padding: '16px 0',
  cursor: 'pointer',
  gap: '14px',
})

const Icon = styled(SquareCheckbox, {
  color: '#111111',
  margin: '-4px',
})
