import { Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { SimpleMenuItem } from './items/SimpleMenuItem';

export const SimpleMenu = ({
  item = SimpleMenuItem,
  options = [],
  children,
  value,
  onSelect,
  ...props
}) => {
  const handleSelect = (option) => () => {
    if (onSelect) onSelect(option);
  };

  const items = options.length
    ? options.map((option) => (
      <MenuItem key={option.value} onClick={handleSelect(option)}>
        {item({ option, selected: option.value === value })}
      </MenuItem>
      ))
    : children;

  return <Menu {...props}>{items}</Menu>;
};
