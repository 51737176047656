import { SnackbarUtils } from './snackbarUtils.jsx';

/* Legacy function for toasts, use SnackbarUtils directly  */
export function toast(msg, config = {}) {
  if (config.appearance === 'danger' || config.appearance === 'error') {
    SnackbarUtils.error(msg);
  } else if (config.appearance === 'warning') {
    SnackbarUtils.warning(msg);
  } else if (config.appearance === 'info') {
    SnackbarUtils.info(msg);
  } else {
    SnackbarUtils.success(msg);
  }
}
