import axios from 'axios'
import pdfjsLib from 'pdfjs-dist/webpack'
import Api from 'rest-fetcher-redux'
import { v4 as uuidv4 } from 'uuid'

import { getTokensFromLocalStorage } from '~/legacy/utils/localStorageHelper'
import { toast } from '~/legacy/utils/notifications'

function downloadFile(filename, fileData) {
  if (!fileData) {
    return
  }

  let name = filename
  if (!name) {
    name = 'LeaseUp.pdf'
  }
  const url = window.URL.createObjectURL(fileData)
  const element = document.createElement('a')
  element.setAttribute('href', url)
  element.setAttribute('download', name)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
  window.URL.revokeObjectURL(url)
}

export { downloadFile }

async function forceDownload(url, filename) {
  const response = await fetch(url)
  const blob = await response.blob()
  const a = document.createElement('a')
  a.download = filename
  a.href = window.URL.createObjectURL(blob)
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a)
  a.click()
  a.remove()
}

// Current blob size limit is around 500MB for browsers
function downloadResource(url, filename) {
  fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    mode: 'cors',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob)
      forceDownload(blobUrl, filename)
    })
    .catch((e) => console.error(e))
}

const uploadFile = (
  file,
  endpoint,
  additionalFields = {},
  skipFailureToast = false
) => {
  const formData = new FormData()
  formData.append('file', file, file.name)
  Object.keys(additionalFields).forEach((key) =>
    formData.append(key, additionalFields[key])
  )

  return axios
    .post(`${process.env.APP_API_URL}/${endpoint}/`, formData, {
      headers: {
        Authorization: Api.baseOptions.headers.Authorization,
      },
    })
    .catch(() =>
      !skipFailureToast
        ? toast(`${file.name} upload failed`, { appearance: 'danger' })
        : null
    )
}

const uploadFiles = (files, endpoint, additionalFields = {}) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file, file.name)
  })
  Object.keys(additionalFields).forEach((key) =>
    formData.append(key, additionalFields[key])
  )

  return axios.post(`${process.env.APP_API_URL}/${endpoint}/`, formData, {
    headers: {
      Authorization: Api.baseOptions.headers.Authorization,
    },
  })
}

export const uploadFilesFromPdf = (pdfFile, endpoint, onUpload) => {
  const reader = new FileReader()

  reader.onload = () => {
    pdfjsLib.getDocument(reader.result).then((pdf) => {
      const { numPages } = pdf._pdfInfo
      for (let pageNumber = 0; pageNumber < numPages; pageNumber++) {
        pdf.getPage(pageNumber + 1).then((page) => {
          page.getOperatorList().then((list) => {
            list.fnArray.forEach((operatorType, operatorIndex) => {
              if (operatorType === pdfjsLib.OPS.paintJpegXObject) {
                const jpegName = list.argsArray[operatorIndex][0]
                const imgTag = page.objs.get(jpegName)
                fetch(imgTag.src)
                  .then((res) => res.blob())
                  .then((blob) => {
                    uploadFile(blob, endpoint).then((result) => {
                      onUpload(result)
                    })
                  })
              }
            })
          })
        })
      }
    })
  }

  reader.readAsArrayBuffer(pdfFile)
}

const prepareFiles = (files) => {
  const newFiles = {}
  files.forEach((fileObj) => {
    newFiles[uuidv4()] = fileObj
  })
  return newFiles
}

const downloadRemoteFile = (file) => {
  const tokens = getTokensFromLocalStorage()
  if (tokens && tokens.access) {
    return fetch(
      `${process.env.APP_API_URL}/files/?file_link=${file.url}&file_name=${file.name}`,
      {
        headers: {
          Authorization: `Bearer ${tokens.access}`,
        },
      }
    ).then((result) => {
      result.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = file.name || file.uuid
        a.click()
        window.URL.revokeObjectURL(url)
      })
    })
  }

  return forceDownload(file.url, file.name || file.uuid)
}

const downloadResourceToObjectUrl = (url, ac = null) => {
  return fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    mode: 'cors',
    signal: ac ? ac.signal : null,
  })
    .then((response) => (response.ok ? response.blob() : null))
    .then((blob) => (blob ? window.URL.createObjectURL(blob) : null))
    .catch(() => null)
}

const renderPdfWithCurrent = async (url, canvas) => {
  const pdf = await pdfjsLib.getDocument(url).promise
  const page = await pdf.getPage(1)
  const viewport = page.getViewport({ scale: 1.5 })

  // Prepare canvas using PDF page dimensions.
  const newCanvas = canvas
  const canvasContext = newCanvas.getContext('2d')
  newCanvas.height = viewport.height
  newCanvas.width = viewport.width

  // Render PDF page into canvas context.
  const renderContext = { canvasContext, viewport }
  page.render(renderContext)
}

const renderPdf = async (url, canvasRef) =>
  renderPdfWithCurrent(url, canvasRef.current)

export {
  downloadRemoteFile,
  downloadResource,
  downloadResourceToObjectUrl,
  prepareFiles,
  renderPdf,
  renderPdfWithCurrent,
  uploadFile,
  uploadFiles,
}

export default downloadFile
