import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Typography, TooltipV2 } from '~/legacy/components';
import { PROJECT } from '~/legacy/consts';
import { useProjectSelector } from '~/legacy/utils/hooks';

export const ProjectProgressCircle = withStyles((theme) => ({
  root: {
    border: '3px solid transparent',
  },
  circle: {
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    backgroundColor: '#E0E0E0',
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
  },
  hovered: {
    cursor: 'pointer',
    borderColor: '#F3F3F3',
    borderRadius: '8px',
  },
}))(
  ({
    classes,
    isHovered = false,
    handleMouseEnter,
    handleMouseLeave,
    completed = false,
    ...props
  }) => {
    return (
      <div
        {...props}
        className={clsx(classes.root, isHovered ? classes.hovered : '')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={clsx(classes.circle, completed ? classes.completed : '')}
        />
      </div>
    );
  }
);

export const ProjectProgress = withStyles({
  projectProgressCircle: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '-2px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  tooltip: {
    margin: '7px',
  },
})(({ classes, projectState }) => {
  const [hoveredProjectStateId, setHoveredProjectStateId] =
    React.useState(null);
  return Object.values(PROJECT.PROGRESS).map((state) => {
    const projectStateId = projectState ? projectState.id : null;
    const { mutateUpdate } = useProjectSelector();

    return (
      <TooltipV2
        key={state.id}
        placement="bottom"
        enterDelay={0}
        title={
          projectState && projectStateId !== state.id
            ? `Change Status to ${state.name}`
            : ''
        }
        classes={{
          tooltip: classes.tooltip,
        }}
      >
        <div>
          <ProjectProgressCircle
            isHovered={
              state.id === hoveredProjectStateId && state.id !== projectStateId
            }
            handleMouseEnter={() => setHoveredProjectStateId(state.id)}
            handleMouseLeave={() => setHoveredProjectStateId(null)}
            classes={{ root: classes.projectProgressCircle }}
            completed={
              projectState
                ? projectState.progressIndex >= state.progressIndex
                : false
            }
            onClick={async () => mutateUpdate({ state: state.id })}
          />
        </div>
      </TooltipV2>
    );
  });
});

export const ProjectState = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressContainer: {
    marginLeft: '16px',
    display: 'flex',
  },
})(({ classes, projectState }) => {
  return (
    <div className={classes.root}>
      <Typography variant="projectState">
        {projectState ? projectState.name.toUpperCase() : ''}
      </Typography>
      <div className={classes.progressContainer}>
        <ProjectProgress projectState={projectState} />
      </div>
    </div>
  );
});
