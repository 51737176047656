import React, { useState, useEffect } from 'react';
import Api from 'rest-fetcher-redux';
import { toast } from '~/legacy/utils/notifications';
import { ConfirmModalComponent } from '~/legacy/components';

export const useAdminDeleteSurveysModal = ({
  onClose = () => {},
  onDeleteSurveys = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [surveysToDelete, setSurveysToDelete] = useState([]);

  // When surveys are set to delete, open the modal
  useEffect(() => {
    if (surveysToDelete && surveysToDelete.length > 0) {
      setOpen(true);
    }
  }, [surveysToDelete]);

  // Just close the modal
  const closeModal = () => {
    setOpen(false);
  };

  // Clear the modal fields
  const resetModal = () => {
    setSurveysToDelete([]);
  };

  const AdminDeleteSurveysModalComponent = (
    <AdminDeleteSurveysModal
      surveys={surveysToDelete}
      open={open}
      onClose={() => {
        closeModal();
        onClose();
      }}
      onAfterClose={resetModal}
      onDeleteSurveys={onDeleteSurveys}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    />
  );

  return {
    closeModal,
    setSurveysToDelete,
    AdminDeleteSurveysModalComponent,
  };
};
export const AdminDeleteSurveysModal = ({
  surveys,
  open,
  onClose,
  onAfterClose = () => {},
  onDeleteSurveys,
  isLoading,
  setIsLoading,
}) => {
  const onlyOneSurvey = surveys && surveys.length === 1;

  return (
    <ConfirmModalComponent
      ModalComponentProps={{
        open: !!open,
        onClose,
      }}
      onAfterClose={onAfterClose}
      onClose={onClose}
      onConfirm={() => {
        setIsLoading(true);
        Api.trashSurveys({
          body: {
            survey_ids: surveys.map((survey) => survey.id),
          },
        })
          .then(() => {
            onClose();
            onDeleteSurveys(surveys);
            toast(`Survey${onlyOneSurvey ? '' : 's'} deleted.`, {
              appearance: 'success',
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
      title="Delete Survey"
      text={`Are you sure you want to delete ${
        onlyOneSurvey ? surveys[0].name : 'the selected surveys'
      }? This action cannot be undone.`}
      confirmButtonLabel="Delete"
      loading={isLoading}
    />
  );
};
