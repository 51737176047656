/* eslint-disable no-param-reassign */
import { useAggregate } from './useAggregate'

export const useZoom = (options = {}) => {
  const zoom = useAggregate({
    state: {
      step: 0.2,
      value: 1,
      min: 0.1,
      max: 10,
      ...options,
    },

    ZoomIn: (state) => {
      state.value = Math.max(state.min, state.value * (1 + state.step))
    },

    ZoomOut: (state) => {
      state.value = Math.min(state.max, state.value * (1 - state.step))
    },

    ZoomTo: (state, data = 1) => {
      state.value = data
    },
  })

  return zoom.value
}
