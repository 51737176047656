import React from 'react';
import { Avatar as MuiAvatar } from '@material-ui/core';
import { lighten, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ThumbnailImage, Typography } from '~/legacy/components';

const BORDER_STYLE = {
  border: '1px solid #E0E0E0',
  borderRadius: '50%',
};
export const Avatar = withStyles((theme) => ({
  base: {
    color: '#111',
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
    '& > img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    zIndex: (props) => props.zIndex,
  },
  tiny: {
    height: '24px',
    width: '24px',
  },
  small: {
    height: '32px',
    width: '32px',
  },
  large: {
    height: '93px',
    width: '93px',
  },
  tinyText: {
    fontSize: '11px',
  },
  largeText: {
    fontSize: '28px',
  },
  textBorder: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    ...BORDER_STYLE,
  },
  imageBorder: {
    '& > img': {
      ...BORDER_STYLE,
    },
  },
}))(({ size, text, image, includeBorder = false, className, classes }) => {
  let sizeClass = classes.small;
  if (size === 'tiny') {
    sizeClass = classes.tiny;
  } else if (size === 'large') {
    sizeClass = classes.large;
  }

  let textSizeClass = '';
  if (textSizeClass === 'tiny') {
    textSizeClass = classes.tinyText;
  } else if (size === 'large') {
    textSizeClass = classes.largeText;
  }

  return (
    <MuiAvatar
      src="broken"
      className={clsx(
        classes.base,
        sizeClass,
        className,
        includeBorder ? classes.imageBorder : ''
      )}
    >
      {image ? (
        <ThumbnailImage imageFile={image} key="image" />
      ) : (
        <div className={clsx(classes.textBorder)}>
          <Typography
            variant="boldText"
            className={textSizeClass}
            align="center"
          >
            {text}
          </Typography>
        </div>
      )}
    </MuiAvatar>
  );
});
