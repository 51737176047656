import { combineReducers } from 'redux';
import addListing from './addListing';
import addProject from './addProject';
import listings from './listings';
import editListing from './editListing';
import viewListing from './viewListing';
import bulkListings from './bulkListings';
import registration from './registration';
import viewSurvey from './viewSurvey';
import previewTenantView from './previewTenantView';

export * from './addListing';
export * from './addProject';
export * from './listings';
export * from './editListing';
export * from './viewListing';
export * from './bulkListings';
export * from './registration';
export * from './previewTenantView';

export default combineReducers({
  addListing,
  addProject,
  listings,
  editListing,
  viewListing,
  bulkListings,
  registration,
  viewSurvey,
  previewTenantView,
});
