import React, { useState, useCallback } from 'react';

import { Menu } from '~/legacy/components';
import { useDeleteCommentMenuItem } from './DeleteCommentMenuItem';
import { useEditCommentMenuItem } from './EditCommentMenuItem';

export const useCommentMenu = ({
  updateComment = () => {},
  deleteComment = () => {},
}) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState({});
  const selectedComment =
    anchorMenuEl && anchorMenuEl.comment ? anchorMenuEl.comment : {};

  const handleMenuClick = useCallback((event, comment) => {
    setAnchorMenuEl({ anchor: event.currentTarget, comment });
  }, []);

  const handleMenuClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorMenuEl({ anchor: null });
  };

  const { EditCommentMenuItemComponent, EditCommentMenuItemModalComponent } =
    useEditCommentMenuItem({
      comment: selectedComment,
      updateComment,
      handleMenuClose,
    });

  const {
    DeleteCommentMenuItemComponent,
    DeleteCommentMenuItemModalComponent,
  } = useDeleteCommentMenuItem({
    deleteComment: async (callbackBeforeUpdate) =>
      deleteComment(selectedComment.id, callbackBeforeUpdate),
    handleMenuClose,
  });

  const CommentMenuComponent = (
    <CommentMenu
      anchorMenuEl={anchorMenuEl ? anchorMenuEl.anchor : null}
      handleMenuClose={handleMenuClose}
      EditCommentMenuItemComponent={EditCommentMenuItemComponent}
      DeleteCommentMenuItemComponent={DeleteCommentMenuItemComponent}
    />
  );

  return {
    CommentMenuComponent,
    handleMenuClick,
    menuCommentUuid: selectedComment.uuid,
    EditCommentMenuItemModalComponent,
    DeleteCommentMenuItemModalComponent,
  };
};

export const CommentMenu = ({
  anchorMenuEl,
  handleMenuClose,
  EditCommentMenuItemComponent,
  DeleteCommentMenuItemComponent,
}) => {
  return (
    <Menu
      id="more-options-menu"
      anchorEl={anchorMenuEl}
      open={!!anchorMenuEl}
      onClose={handleMenuClose}
      getContentAnchorEl={null}
    >
      {EditCommentMenuItemComponent}
      {DeleteCommentMenuItemComponent}
    </Menu>
  );
};
