import React, { useMemo } from 'react';
import { Select } from '~/legacy/components';
import { USER_ROLE_OPTIONS } from '~/legacy/consts';

const UserRoleSelect = ({
  value,
  userType,
  onChange = () => {},
  disabled = false,
  ...props
}) => {
  const options = useMemo(() => USER_ROLE_OPTIONS[userType], [userType]);

  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      selectParams={{
        disabled,
      }}
      label="Role"
      showNone={false}
      {...props}
    />
  );
};

export default UserRoleSelect;
