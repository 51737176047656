import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Link } from '~/legacy/components';

export const TextLink = withStyles({
  root: {},
})(({ classes, children, TypographyProps = {}, ...props }) => {
  return (
    <Link classes={classes} {...props}>
      <Typography variant="link" {...TypographyProps}>
        {children}
      </Typography>
    </Link>
  );
});
