import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import routes from '~/legacy/pages'
import { PublicRoute } from '~/legacy/components'

function PublicLayout({ location }) {
  return (
    <div className="PublicLayout">
      <div className="public-login-wrapper">
        <Switch location={location}>
          {/* Public, non redirect routes */}
          {Object.values(routes)
            .filter((route) => route.isPublic && !route.isRedirect)
            .map(({ component, ...rest }) => (
              <Route
                key={rest.path}
                render={(props) => (
                  <PublicRoute component={component} {...props} {...rest} />
                )}
                {...rest}
              />
            ))}
          {/* Public redirect routes */}
          {Object.values(routes)
            .filter((route) => route.isPublic && route.isRedirect)
            .map(({ component, ...rest }) => (
              <Route key={rest.path} component={component} {...rest} />
            ))}
        </Switch>
      </div>
    </div>
  )
}

PublicLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.object.isRequired,
}

PublicLayout.defaultProps = {}

export default memo(PublicLayout)
