import React from 'react'
import { useSend } from '~/support/useSend'
import { Checkbox } from './Checkbox'

export const CheckboxSetting = (props) => {
  const send = useSend()

  return (
    <Checkbox
      {...props}
      onChange={(selected) => {
        send('AddSettings', {
          [props.id]: selected,
        })
      }}
    />
  )
}
