/**
 * Actions/Reducers for toggling the preview tenant view as a broker
 */

const ACTIONS = {
  SET_PREVIEW_TENANT_VIEW: 'SET_PREVIEW_TENANT_VIEW',
};

// Initialize the preview view to false
const initialState = {
  isPreviewTenantView: false,
};

// Reducer
export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_PREVIEW_TENANT_VIEW: {
      return {
        ...state,
        isPreviewTenantView: action.isPreviewTenantView,
      };
    }
    default:
      return state;
  }
}

// Action Creators
export const previewTenantViewActions = {
  setPreviewTenantView: isPreviewTenantView => ({
    type: ACTIONS.SET_PREVIEW_TENANT_VIEW,
    isPreviewTenantView,
  }),
};
