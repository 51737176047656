import React, { useEffect, useState } from 'react'
import { Firm } from '~/services/Firm'
import { appUrl } from '~/support/appUrl'
import { createCache } from '~/support/createCache'
import { getApiV1 } from '~/support/getApiV1'
import { getSubdomain } from '~/support/getSubdomain'
import { match } from '~/support/match'
import { BadStatus, Err, Ok } from '~/support/types'

const cache = createCache('firm')

export const FirmFromSubdomain = ({ children }) => {
  const subdomain = getSubdomain()

  if (!subdomain) {
    return <>{children}</>
  }

  const value = cache.get(subdomain)

  return value ? (
    <Firm.Provider value={value}>{children}</Firm.Provider>
  ) : (
    <Loader subdomain={subdomain}>{children}</Loader>
  )
}

const Loader = ({ children, subdomain }) => {
  const [value, setValue] = useState(null)

  useEffect(() => {
    getApiV1(`/orgs/${subdomain}`).then(
      match({
        [Ok]: (value) => {
          cache.set(subdomain, 1000 * 10, value)
          console.log(value)
          setValue(value)
        },
        [Err]: match({
          [BadStatus]: (status) => {
            console.log('Bad status', '->', status)
            if (status === 404) {
              location.replace(appUrl(`/missing?sub_domain=${subdomain}`))
            } else {
              setValue({})
            }
          },
          '*': (error, type) => {
            console.log(type, '->', error)
            setValue({})
          },
        }),
      })
    )
  }, [subdomain])

  return value ? <Firm.Provider value={value}>{children}</Firm.Provider> : null
}
