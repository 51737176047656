import {
  SET_SURVEY_LISTINGS_DATA,
  SET_SURVEY_LISTINGS_DATA_STATUS,
} from './actions/surveyListingsActions';
import { SET_BUILDING } from './actions/buildingActions';
import { SET_LISTINGS } from './actions/listingsActions';
import { CLEAR_DATA_STATE } from './actions/dataStateActions';

const BUILDING_STATE_KEY = 'buildingState';
const LISTINGS_STATE_KEY = 'listingsState';
const SURVEY_LISTINGS_STATE_KEY = 'surveyListingsState';
const initialState = {
  [BUILDING_STATE_KEY]: {
    building: null,
    initialized: false,
  },
  [SURVEY_LISTINGS_STATE_KEY]: {
    surveyListings: null,
    listingToSurveyListingMap: null,
  },
  [LISTINGS_STATE_KEY]: {
    listings: null,
    initialized: false,
  },
};
export default function(state = initialState, action) {
  switch (action.type) {
    case CLEAR_DATA_STATE: {
      return { ...initialState };
    }

    /*
    ////////////////////////////////////////////////
    // Survey Listings /////////////////////////////
    ////////////////////////////////////////////////
    */
    case SET_SURVEY_LISTINGS_DATA: {
      const listingToSurveyListingMap = {};
      if (action.surveyListings) {
        Object.entries(action.surveyListings).forEach(
          ([surveyListingId, surveyListing]) => {
            listingToSurveyListingMap[
              parseInt(surveyListing.listing.id, 10)
            ] = parseInt(surveyListingId, 10);
          }
        );
      }

      return {
        ...state,
        [SURVEY_LISTINGS_STATE_KEY]: {
          ...state[SURVEY_LISTINGS_STATE_KEY],
          surveyListings: action.surveyListings,
          listingToSurveyListingMap,
        },
      };
    }
    // TODO: Is there a cleaner way to handle object merging? this is ugly AF
    case SET_SURVEY_LISTINGS_DATA_STATUS:
      return {
        ...state,
        [SURVEY_LISTINGS_STATE_KEY]: {
          ...state[SURVEY_LISTINGS_STATE_KEY],
          surveyListings: {
            ...state[SURVEY_LISTINGS_STATE_KEY].surveyListings,
            [action.id]: {
              ...state[SURVEY_LISTINGS_STATE_KEY].surveyListings[action.id],
              status: action.status,
            },
          },
        },
      };

    /*
    ////////////////////////////////////////////////
    // Buildings ///////////////////////////////////
    ////////////////////////////////////////////////
    */
    case SET_BUILDING: {
      return {
        ...state,
        [BUILDING_STATE_KEY]: {
          ...state[BUILDING_STATE_KEY],
          initialized: true,
          building: action.payload,
        },
      };
    }

    /*
    ////////////////////////////////////////////////
    // LISTINGS ////////////////////////////////////
    ////////////////////////////////////////////////
    */
    case SET_LISTINGS: {
      return {
        ...state,
        [LISTINGS_STATE_KEY]: {
          ...state[LISTINGS_STATE_KEY],
          initialized: true,
          listings: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
