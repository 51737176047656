import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SquareIconButton, CloseIcon } from '~/legacy/components';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '26px',
  },
  icon: {
    padding: 0,
    alignSelf: 'flex-start',
  },
  closeIcon: {
    color: '#111111',
  },
});

function ModalTitleGeneric({ onClose, title, classNames, omitClose }) {
  const styles = useStyles();
  // Override the container/icon default styling with provided styling.
  const classes = {
    container: `${styles.container} ${classNames.container}`,
    icon: `${styles.icon} ${classNames.icon}`,
  };

  return (
    <div className={classes.container}>
      <Typography variant="h2">{title}</Typography>
      {!omitClose && (
        <SquareIconButton aria-label="close" className={classes.icon}>
          <CloseIcon className={classes.closeIcon} onClick={onClose} />
        </SquareIconButton>
      )}
    </div>
  );
}
ModalTitleGeneric.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  classNames: PropTypes.object,
  omitClose: PropTypes.bool,
};
ModalTitleGeneric.defaultProps = {
  onClose: undefined,
  classNames: {},
  omitClose: false,
};

export default ModalTitleGeneric;
