/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react'

export const useEventListener = (type, deps, listener) => {
  const callback = useCallback(listener, deps)
  useEffect(() => {
    window.addEventListener(type, callback)
    return () => window.removeEventListener(type, callback)
  }, [type, callback])
}
