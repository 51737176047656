import React from 'react';
import PropTypes from 'prop-types';
import { TransitScoreSvg, ScoreBadge } from '~/legacy/components';

function TransitScoreBadge({
  transitScore,
  description,
  helpLink,
  titleVariant,
  fontSize,
  height,
}) {
  const transitScoreSvg = (
    <TransitScoreSvg
      transitScore={transitScore}
      fontSize={fontSize}
      height={height}
    />
  );
  return (
    <ScoreBadge
      scoreName="Transit Score"
      scoreSvg={transitScoreSvg}
      titleVariant={titleVariant}
      description={description}
      helpLink={helpLink}
    />
  );
}
TransitScoreBadge.propTypes = {
  transitScore: PropTypes.number.isRequired,
  description: PropTypes.string,
  helpLink: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
  fontSize: PropTypes.string,
  height: PropTypes.string,
};
TransitScoreBadge.defaultProps = {
  description: undefined,
  titleVariant: undefined,
  fontSize: undefined,
  height: undefined,
};

export default TransitScoreBadge;
