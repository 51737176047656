import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTheme } from '@material-ui/core/styles';
import { Marker } from '@react-google-maps/api';
import { MapMarkerIcon } from '~/legacy/components';

const MapMarker = ({ isHighlighted = false, children, ...props }) => {
  const theme = useTheme();
  return (
    <Marker
      icon={`data:image/svg+xml,${escape(
        ReactDOMServer.renderToStaticMarkup(
          <MapMarkerIcon isActive={isHighlighted} theme={theme} />
        )
      )}`}
      {...props}
    >
      {children}
    </Marker>
  );
};

export default MapMarker;
