import React, { useState } from 'react';

export const IsPreviewContext = React.createContext({
  isPreview: false,
});

export const IsTenantPreviewContextProvider = ({children}) => {
  const [isPreview, setIsPreview] = useState(false);
  return (
    <IsPreviewContext.Provider value={{isPreview, setIsPreview}}>
      {children}
    </IsPreviewContext.Provider>
  );
}
