import React, { useState, useEffect } from 'react';
import Api from 'rest-fetcher-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Hidden } from '@material-ui/core';
import { toast } from '~/legacy/utils/notifications';
import {
  AddressAutocomplete,
  Loading,
  TextInput,
  DatePicker,
  Button,
  BuildingTitleCard,
} from '~/legacy/components';
import { getBuildingPrimaryName, getViewListingRoute } from '~/legacy/utils';
import { BUILDING_TYPE } from '~/legacy/consts';
import { useApiHelper } from '~/legacy/utils/hooks';
import ModalTitle from './ModalTitle';

const ITEM_PADDING_PX = 8;
const LEFT_PADDING = 5;

const HALF_ADDRESS_FIELD_PADDING_PX = 14;

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    outline: 'none',
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    height: (props) => `${!props.isUserInBuildout ? '525' : '348'}px`,
  },
  hover: {
    '&:hover:hover': {
      backgroundColor: 'rgb(249, 249, 249) !important',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
    zIndex: 2,
  },
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    backgroundColor: 'rgba(250, 250, 250, 0.6)',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  contentContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  recentBuildingsText: {
    paddingTop: '30px',
    paddingLeft: `${LEFT_PADDING}px`,
  },
  leftPadding: {
    paddingLeft: `${LEFT_PADDING}px`,
  },
  topPadding: {
    paddingTop: `${ITEM_PADDING_PX}px`,
  },
  buildingItemName: {
    padding: `${ITEM_PADDING_PX}px ${LEFT_PADDING}px ${ITEM_PADDING_PX}px ${LEFT_PADDING}px`,
    cursor: 'pointer',
  },
  leftField: {
    paddingRight: `${HALF_ADDRESS_FIELD_PADDING_PX}px`,
    width: '100%',
  },
  rightField: {
    paddingLeft: `${HALF_ADDRESS_FIELD_PADDING_PX}px`,
    width: '100%',
  },
  addressFieldRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '30px',
  },
  topAddressFieldRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '20px',
  },
  rightButton: {
    paddingLeft: '15px',
  },
  buttonRow: {
    displayDirection: 'row',
    display: 'flex',
    width: '100%',
    marginTop: 'auto',
    paddingTop: '30px',
    paddingBottom: '25px',
  },
  firstSubtitle: {
    display: 'flex',
    alignItems: 'center',
  },
  csvReaderInput: {
    display: 'none',
  },
  csvReaderContainer: {
    display: 'flex',
    marginTop: 'auto',
    width: '100%',
    paddingBottom: '15px',
  },
  sampleCsvButton: {
    paddingLeft: '5px',
  },
  checkboxContainer: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customTooltipWidth: {
    width: '200px',
  },
  noUnderline: {
    textDecoration: 'none !important',
  },
  blackBackground: {
    color: '#111',
  },
  tooltipText: {
    fontFamily: 'Inter-Semibold',
    fontSize: '10px',
    lineHeight: '10px',
    letterSpacing: '0.1px',
  },
  spaceNameContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '18px',
  },
  buttonsRow: {
    display: 'flex',
    marginTop: 'auto',
    justifyContent: 'end',
  },
  addSpaceButton: {
    marginLeft: '12px',
  },
  bdpContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    paddingBottom: '16px',
  },
});

const NUMBER_RECENT_BUILDINGS = 8;
const DEFAULT_NEW_LISTING = {};
const DEFAULT_NEW_BUILDING = { type: BUILDING_TYPE, isFuseMatch: true };
const ADDRESS_ERROR_TEXT = 'Address is required';

function AddListingModal({
  onClose,
  fetchSurveyListings,
  surveyId,
  preSelectedBuilding,
  isUserInBuildout = false,
}) {
  const classes = useStyles({ isUserInBuildout });
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [recentBuildings, setRecentBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(
    preSelectedBuilding || DEFAULT_NEW_BUILDING
  );
  const [newListing, setNewListing] = useState({ ...DEFAULT_NEW_LISTING });
  const [addressError, setAddressError] = useState(false);
  const apiHelper = useApiHelper();

  useEffect(() => {
    Api.getRecentBuildings().then((result) => {
      if (result && result.data) {
        setRecentBuildings(result.data);
      }
    });
  }, []);

  const setAddressValue = (field, value) => {
    setNewListing({ ...newListing, [field]: value });
  };
  const setAddressValueFromEvent = (field) => (event) => {
    setAddressValue(field, event.target.value);
  };

  const hasSelectedAddress = !!(selectedBuilding && selectedBuilding.address);

  useEffect(() => {
    if (!hasSelectedAddress) {
      setNewListing({ ...DEFAULT_NEW_LISTING });
    }
  }, [hasSelectedAddress]);

  const closeItAll = () => {
    setIsLoading(false);
    onClose();
  };

  const checkForErrors = () => {
    let hasErrors = false;
    if (!selectedBuilding.address) {
      hasErrors = true;
      setAddressError(true);
    } else {
      hasErrors = false;
      setAddressError(false);
    }
    return hasErrors;
  };

  const saveListing = (forwardToListing = false) => {
    if (!checkForErrors()) {
      setIsLoading(true);
      const listingToSave = {
        ...newListing,
        size: newListing.size ? newListing.size : null,
        sqft_price: newListing.sqft_price ? newListing.sqft_price : null,
        survey_id: surveyId,
      };
      apiHelper
        .addListing({
          listing: listingToSave,
          building: selectedBuilding.id
            ? { id: selectedBuilding.id }
            : { ...selectedBuilding },
          surveyId,
        })
        .then((result) => {
          if (result && result.id) {
            if (forwardToListing) {
              history.push(getViewListingRoute(result.id, surveyId));
            } else {
              // eslint-disable-next-line
              if (surveyId) {
                fetchSurveyListings().finally(() => {
                  toast('Listing added successfully!', {
                    appearance: 'success',
                  });
                  closeItAll();
                });
              } else {
                toast('Listing added successfully!', {
                  appearance: 'success',
                });
                closeItAll();
              }
            }
          } else {
            closeItAll();
          }
        })
        .catch(closeItAll);
    }
  };

  return (
    <div className={classes.content}>
      <Loading
        size={24}
        isLoading={isLoading}
        className={classes.buttonProgress}
      />
      {isUserInBuildout
        ? [
          <ModalTitle
            onClose={onClose}
            title="Add Space to Building"
            key="title"
          />,
          <div key="content" className={classes.bdpContentContainer}>
            <BuildingTitleCard building={selectedBuilding} />
            <div className={classes.spaceNameContainer}>
              <TextInput
                InputProps={{ autoFocus: true }}
                label="Space Name"
                placeholder="ex: Unit 2, Suite 300, 1st Floor..."
                onChange={setAddressValueFromEvent('address2')}
                value={newListing ? newListing.address2 : null}
              />
            </div>
            <div className={classes.buttonsRow}>
              <Button color="secondary" onClick={onClose}>
                  Cancel
              </Button>
              <div className={classes.addSpaceButton}>
                <Button color="primary" onClick={() => saveListing(false)}>
                    Add Space
                </Button>
              </div>
            </div>
          </div>,
          ]
        : [
          <ModalTitle onClose={onClose} title="Add Space" key="title" />,
          <div className={classes.contentContainer} key="content">
            <AddressAutocomplete
              error={!!addressError}
              helperText={addressError ? ADDRESS_ERROR_TEXT : null}
              label="Address"
              showExistingBuildings
              showExistingListings
              className={classes.leftPadding}
              surveyId={surveyId}
              fetchFreshBuildingData={false}
              addExistingListingToSurvey={(listingId) => {
                  setIsLoading(true);

                  // Assign the listing to the survey, refetch the survey listings, close the modal
                  apiHelper
                    .assignListingToProject(surveyId, listingId)
                    .then(() => {
                      fetchSurveyListings().finally(() => closeItAll());
                    })
                    .catch(closeItAll);
                }}
              setAddressValues={(newAddressValues) =>
                  setSelectedBuilding({
                    ...selectedBuilding,
                    ...newAddressValues,
                  })
                }
              setBuildingValues={(newBuildingValues) =>
                  setSelectedBuilding({
                    ...selectedBuilding,
                    ...newBuildingValues,
                  })
                }
              overrideBuildingValues={setSelectedBuilding}
              controlledValue={selectedBuilding}
            />

            <Hidden xlDown={hasSelectedAddress}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.recentBuildingsText}
              >
                  Recent buildings
              </Typography>

              <div className={classes.topPadding}>
                {recentBuildings
                    .slice(0, NUMBER_RECENT_BUILDINGS)
                    .map((building) => (
                      <Typography
                        variant="body1"
                        key={building.id}
                        className={clsx(
                          classes.hover,
                          classes.buildingItemName
                        )}
                        onClick={() =>
                          setSelectedBuilding({
                            ...DEFAULT_NEW_BUILDING,
                            ...building,
                          })
                        }
                      >
                        {getBuildingPrimaryName(building)}
                      </Typography>
                    ))}
              </div>
            </Hidden>

            {!!isLoading && <div className={classes.overlay} />}

            <Hidden xlDown={!hasSelectedAddress}>
              <Typography
                variant="h3"
                className={classes.recentBuildingsText}
              >
                  Optional Details
              </Typography>
              <div className={classes.topAddressFieldRow}>
                <TextInput
                  label="Space Name"
                  placeholder="ex: Unit 2, Suite 300, 1st Floor..."
                  onChange={setAddressValueFromEvent('address2')}
                  value={newListing ? newListing.address2 : null}
                />
              </div>
              <div className={classes.addressFieldRow}>
                <div className={classes.leftField}>
                  <TextInput
                    label="SqFt Available"
                    onChange={setAddressValueFromEvent('size')}
                    type="number"
                    value={newListing ? newListing.size : null}
                  />
                </div>
                <div className={classes.rightField}>
                  <TextInput
                    label="Yearly Price/SqFt"
                    onChange={setAddressValueFromEvent('sqft_price')}
                    type="number"
                    value={newListing ? newListing.sqft_price : null}
                  />
                </div>
              </div>
              <div className={classes.addressFieldRow}>
                <div className={classes.leftField}>
                  <DatePicker
                    label="Availability"
                    onChange={(newAddressValue) =>
                        setAddressValue('date_available', newAddressValue)
                      }
                    value={newListing ? newListing.date_available : null}
                  />
                </div>
                <div className={classes.rightField}>
                  <TextInput
                    label="Lease Term"
                    onChange={setAddressValueFromEvent('lease_term')}
                    value={newListing ? newListing.lease_term : null}
                  />
                </div>
              </div>
              <div className={classes.buttonRow}>
                <div>
                  <Button color="primary" onClick={() => saveListing(false)}>
                    {`Add to ${surveyId ? 'Survey' : 'Building'}`}
                  </Button>
                </div>
                <div className={classes.rightButton}>
                  <Button color="secondary" onClick={() => saveListing(true)}>
                      Add More Details
                  </Button>
                </div>
              </div>
            </Hidden>
          </div>,
          ]}
    </div>
  );
}

export default AddListingModal;
