import React from 'react'
import { styled } from '~/libraries/styled'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { LeaseUpPage } from '../patterns/LeaseUpPage'
import * as Fields from '~/objects/fields'
import { Font } from '~/components/Font'
import { useTable } from '~/support/useTable'
import { count } from '~/support/count'
import { averageFields } from '~/support/averageFields'
import { Settings } from '~/pdfs/patterns/Settings'
import { Group } from '~/pdfs/patterns/Group'
import { CheckboxSetting } from '~/pdfs/patterns/CheckboxSetting'
import { Mapbox } from '~/components/Mapbox'
import { LeaseUpContent } from '../patterns/LeaseUpContent'
import { buildBuildingName } from '../support/buildBuildingName'

export const LeaseUpMap = definePageTemplate({
  id: '7e0f0e2b-1f53-4ee2-a269-3fcb130c7811',

  name: 'Map + Buildings',

  props: (pdf) => {
    return {
      map: pdf.settings.map,
      buildings: pdf.data.buildings.map((building) => {
        const spaces = building.spaces.length
        const sqft = averageFields(building.spaces, Fields.Size)
        const price = averageFields(building.spaces, Fields.Price)

        return {
          id: building.id,
          order: building.order + 1,
          coordinates: building.location.coordinates,
          address: buildBuildingName(building),
          availability: spaces > 0 && count('Space', spaces),
          size: sqft > 0 && Fields.Area.format(Math.round(sqft)),
          price: price > 0 && Fields.Currency.format(price),
        }
      }),
    }
  },

  Page: (props) => {
    const paginate = props.buildings.length > 9 && props.map
    const perPage = paginate ? Math.ceil(props.buildings.length / 2) : 20
    const Table = paginate ? Condensed : Full
    const table = useTable(Table, props.buildings)
    const coordinates = props.buildings.map((location) => {
      return location.coordinates
    })

    return (
      <LeaseUpPage>
        <LeaseUpContent>
          {props.map && (
            <Mapbox coordinates={coordinates} width={564} height={405} />
          )}

          <Pages>
            {table.pages(perPage).map((page) => (
              <Page key={page.id} style={table.style}>
                {page.header}
                {page.rows}
              </Page>
            ))}
          </Pages>
        </LeaseUpContent>
      </LeaseUpPage>
    )
  },

  Settings: (props) => (
    <Settings>
      <Group label="Map">
        <CheckboxSetting id="map" label="Show Map" value={props.map} />
      </Group>
    </Settings>
  ),
})

const Full = {
  columns: [
    { id: '#', key: 'order', size: 'min-content' },
    { id: 'Address', key: 'address', size: '1.75fr' },
    { id: 'Availability', key: 'availability', size: '1.375fr' },
    { id: 'Avg Sqft', key: 'size' },
    { id: 'Avg Price / Sqft', key: 'price' },
  ],

  header: (column) => (
    <Header>
      <Font format="8/32/500" truncate>
        {column.id}
      </Font>
    </Header>
  ),

  row: (data, column) => (
    <Row>
      <Font format="10/32" truncate>
        {data[column.key]}
      </Font>
    </Row>
  ),
}

const Condensed = {
  columns: Full.columns.slice(0, 2),
  header: Full.header,
  row: Full.row,
}

const Row = styled('div', {
  borderBottom: '1px solid #E0E0E0',
  minWidth: '0px',
  padding: '0px 12px',
})

const Header = styled(Row, {
  backgroundColor: '#F9F9F9',
  textTransform: 'uppercase',
  color: '#666666',
})

const Pages = styled('div', {
  alignItems: 'start',
  display: 'flex',
})

const Page = styled('div', {
  flexGrow: '1',
})
