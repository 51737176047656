import React from 'react';
import clsx from 'clsx';
import { Dialog, Grow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const modalComponentStyles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const OpenCloseTransition = React.forwardRef(
  ({ onAfterClose, ...props }, ref) => {
    return (
      <Grow
        ref={ref}
        {...props}
        onExited={node => {
          // Executed AFTER the transition completes.
          onAfterClose();
          if (props && props.onExited) {
            props.onExited(node);
          }
        }}
      />
    );
  }
);

const ModalDialog = ({
  fullScreen = false,
  open,
  // On request to close modal
  onClose,
  // After the modal close transition completes
  onAfterClose = () => {},
  disableBackDropClickClose = false,
  DialogProps = {},
  children,
  classes,
}) => {
  return (
    <Dialog
      id="dialog-backdrop"
      className={clsx(classes.modal)}
      fullScreen={fullScreen}
      maxWidth={false}
      open={open}
      onClose={(event, reason) => {
        if (!disableBackDropClickClose || reason !== 'backdropClick') {
          onClose(event, reason);
        }
      }}
      TransitionComponent={OpenCloseTransition}
      TransitionProps={{ onAfterClose }}
      {...DialogProps}
    >
      {children}
    </Dialog>
  );
}

export default withStyles(modalComponentStyles)(ModalDialog);
