import React from 'react'
import Api from 'rest-fetcher-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import lodash from 'lodash'
import {
  getAdminUserDisplayName,
  useLogoUrl,
  SnackbarUtils,
} from '~/legacy/utils'
import {
  UnlockIcon,
  ConfirmModalComponent,
  MenuItem,
  Typography,
} from '~/legacy/components'
import { BASE_ICON_STYLES } from './ButtonUtils'

const useStyles = makeStyles((theme) => ({
  ...lodash.merge(BASE_ICON_STYLES(theme), {
    icon: {
      fill: 'none',
    },
  }),
}))

export const ResetPasswordMenuItemConfirmModal = ({ user, open, onClose }) => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = React.useState(false)
  const logoUrl = useLogoUrl()

  return (
    <ConfirmModalComponent
      ModalComponentProps={{
        open: !!open,
        onClose,
      }}
      onClose={onClose}
      onConfirm={() => {
        setIsLoading(true)
        Api.resetPasswordEmail({
          body: {
            email: user.email,
            // TODO: Why the hell is all of this here? pdf?
            color: theme.palette.primary.main,
            color_hover: theme.palette.primary.dark,
            logo_url: logoUrl,
          },
        })
          .then(() => {
            SnackbarUtils.success(
              'A link to reset this user’s password has been sent to their email.'
            )
            onClose()
          })
          .finally(() => {
            setIsLoading(false)
          })
      }}
      title="Reset Password"
      text={`Are you sure you want to reset ${
        user ? getAdminUserDisplayName(user) : ''
      }’s password? Clicking “Reset” will send a password reset link to their email.`}
      confirmButtonLabel="Reset"
      loading={isLoading}
    />
  )
}

export const ResetPasswordMenuItem = React.forwardRef(
  ({ openModal, ...props }, ref) => {
    const classes = useStyles()

    return (
      <MenuItem
        key="reset-password-menu-item"
        ref={ref}
        onClick={openModal}
        {...props}
      >
        <UnlockIcon className={classes.icon} />
        <Typography className={classes.text}> Reset Password </Typography>
      </MenuItem>
    )
  }
)

// Bundle the modal and the menu item together for maximum convenience
export const useResetPasswordMenuItem = ({ user, handleMenuClose }) => {
  const [open, setOpen] = React.useState(false)

  const ResetPasswordMenuItemComponent = (
    <ResetPasswordMenuItem openModal={() => setOpen(true)} />
  )

  const ResetPasswordMenuItemConfirmModalComponent = (
    <ResetPasswordMenuItemConfirmModal
      key="reset-password-modal"
      user={user}
      open={open}
      onClose={() => {
        setOpen(false)
        handleMenuClose()
      }}
    />
  )

  return {
    ResetPasswordMenuItemComponent,
    ResetPasswordMenuItemConfirmModalComponent,
  }
}
