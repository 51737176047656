import React, { memo, useEffect } from 'react'
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'
import posthog from 'posthog-js'
import { CenterLayout, PublicLayout } from './layout'
import pages from './pages'

const routes = Object.values(pages)
const publicRoutes = routes.filter((route) => route.isPublic)
const protectedRoutes = routes.filter((route) => route.allowAnonymous)

export default memo(() => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
))

const App = () => {
  const location = useLocation()

  // Track pageviews
  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview', {
        $current_url: window.location.href,
      })
    }
  }, [location])

  return (
    <Switch>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          component={PublicLayout}
        />
      ))}

      {protectedRoutes.map((route) => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          render={({ location, history }) => (
            <CenterLayout
              allowAnonymous
              hideScroll={route.hideScroll}
              location={location}
              history={history}
            />
          )}
        />
      ))}

      <Route component={CenterLayout} />
    </Switch>
  )
}
