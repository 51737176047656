import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    borderBottom: '1px solid #D8D8D8',
    width: '100%',
    paddingBottom: 12,
  },
});

function SectionHeader({ header, subHeader, showCheckmark = false }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ display: 'flex' }}>
        <Typography variant="h2" gutterBottom>
          {header}
        </Typography>
        <Fade in={showCheckmark}>
          <CheckIcon color="primary" style={{ marginLeft: '8px' }} />
        </Fade>
      </div>
      <Typography variant="h3" gutterBottom>
        {subHeader}
      </Typography>
    </div>
  );
}

export default memo(SectionHeader);
