import React from 'react';
import { withStyles, Collapse } from '@material-ui/core';
import clsx from 'clsx';
import { IsPreviewContext } from '~/legacy/utils';
import { Typography, PreviewIcon, TextLink } from '~/legacy/components';

export const TenantPreviewNavBar = withStyles({
  root: {
    position: 'sticky',
    width: '100%',
    top: '0px',
    backgroundColor: '#F9F9F9',
    zIndex: 100,
  },
  contentRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '60px',
    maxWidth: 'unset',
  },
  innerContentRoot: {
    flexGrow: 1,
  },
  collapseWrapperInner: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  flex: {
    display: 'flex',
  },
  previewText: {
    marginLeft: '8px',
    lineHeight: '24px',
  },
  exitPreview: {
    marginLeft: 'auto',
    alignItems: 'center',
  },
  divider: {
    display: 'flex',
  },
})(({ classes }) => {
  const { isPreview, setIsPreview } = React.useContext(IsPreviewContext);
  const Divider = () => <div className={classes.divider} />;

  return (
    <div className={classes.root}>
      <Collapse
        in={isPreview}
        classes={{ wrapperInner: classes.collapseWrapperInner }}
      >
        <div className={classes.contentRoot}>
          <Divider />

          <div className={clsx(classes.flex, classes.innerContentRoot)}>
            <div className={classes.flex}>
              <PreviewIcon />
              <Typography className={classes.previewText} variant="bodyBold">
                You are previewing the page as a client
              </Typography>
            </div>
            <div className={clsx(classes.flex, classes.exitPreview)}>
              <TextLink
                onClick={() => setIsPreview(!isPreview)}
                classes={{ root: classes.textRoot }}
              >
                Exit Preview
              </TextLink>
            </div>
          </div>

          <Divider />
        </div>
      </Collapse>
    </div>
  );
});
