import React from 'react';
import { ConfirmModalComponent } from '~/legacy/components';

// A modal to edit a project
export const DeleteSpaceFieldModal = ({
  fieldName,
  buildingName,
  deleteSpaceField,
  isCustomField,
  isBuildingField = false,
  open,
  onClose,
}) => {
  return (
    <ConfirmModalComponent
      ModalComponentProps={{
        open: !!open,
        onClose,
      }}
      onClose={onClose}
      onConfirm={async () => {
        try {
          onClose();
          await deleteSpaceField();
        } catch {
          // supress
        }
      }}
      title={`${isCustomField ? 'Delete' : 'Clear'} Field`}
      confirmButtonLabel={isCustomField ? 'Delete' : 'Clear'}
      text={`Are you sure you want to ${
        isCustomField ? 'delete' : 'clear'
      } the ${fieldName} ${
        isBuildingField ? '' : 'space'
      }  field from ${buildingName}? This action can’t be undone.`}
    />
  );
};

// Get the delete field modal and its open/closed state
export const useDeleteSpaceFieldModal = (
  fieldName,
  buildingName,
  isCustomField,
  deleteSpaceField,
  isBuildingField,
  onClose
) => {
  const [open, setOpen] = React.useState(false);

  const DeleteSpaceFieldModalComponent = (
    <DeleteSpaceFieldModal
      fieldName={fieldName}
      buildingName={buildingName}
      isCustomField={isCustomField}
      deleteSpaceField={deleteSpaceField}
      isBuildingField={isBuildingField}
      open={open}
      onClose={onClose}
    />
  );

  return {
    open,
    setOpen,
    DeleteSpaceFieldModalComponent,
  };
};
