import React, { useState } from 'react';
import Api from 'rest-fetcher-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, MODALS, TextInput } from '~/legacy/components';

const useStyles = makeStyles({
  button: {
    alignSelf: 'center',
    margin: '12px 0 24px 0',
  },
  input: {
    width: '400px',
    marginBottom: '16px',
  },
  modalContent: {
    background: 'white',
    outline: 'none',
    borderRadius: 7,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: 'auto',
  },
  subTitle: {
    marginBottom: '24px',
  },
  title: {
    marginTop: '24px',
  },
});

function SignUpModal({ setModalContent }) {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [error, setError] = useState('');

  const onSubmit = () => {
    if (!email) {
      setError('Please enter your email.');
    } else {
      Api.sendDemoRequest({
        body: { email },
      }).then(() => setModalContent(MODALS.SIGN_UP_CONFIRM));
    }
  };

  return (
    <div className={classes.modalContent}>
      <Typography className={classes.title} gutterBottom variant="h1">
        Welcome to LeaseUp!
      </Typography>
      <Typography className={classes.subTitle} variant="body1">
        Enter your email below and we will reach out to schedule a demo
      </Typography>
      <TextInput
        className={classes.input}
        error={!!error}
        helperText={error}
        label="Email"
        onChange={(event) => setEmail(event.target.value)}
        type="email"
        value={email}
      />
      <Button className={classes.button} color="primary" onClick={onSubmit}>
        Submit
      </Button>
    </div>
  );
}

export default SignUpModal;
