import React from 'react';
import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    height: '36px',
    width: '36px',
    minWidth: '36px',
    border: `1px solid #f9f9f9`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeButtonWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  button: {
    height: '32px',
    width: '32px',
    minWidth: '32px',
    borderRadius: '50%',
  },
  label: {
    width: 'unset',
  },
}));

export default function CircleIconButton({
  active,
  className,
  wrapperClassName,
  icon,
  onClick,
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.buttonWrapper,
        active && classes.activeButtonWrapper,
        wrapperClassName
      )}
      onClick={onClick}
    >
      <Button
        className={clsx(classes.button, className)}
        classes={{
          label: classes.label,
        }}
      >
        {icon}
      </Button>
    </div>
  );
}
