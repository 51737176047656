import React, { useState } from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarUtils } from '~/legacy/utils';
import { DeleteIcon, ConfirmModalComponent } from '~/legacy/components';
import { BASE_ICON_STYLES } from './ButtonUtils';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}));

export const DeleteSurveyMenuItem = React.forwardRef(
  ({ openDeleteSurveyModal, ...props }, ref) => {
    const classes = useStyles();
    return (
      <>
        <MenuItem
          key="delete-survey-menu-item"
          ref={ref}
          className={classes.menuItemRoot}
          onClick={openDeleteSurveyModal}
          {...props}
        >
          <DeleteIcon className={classes.icon} />
          <Typography className={classes.text}>Delete Survey</Typography>
        </MenuItem>
      </>
    );
  }
);

export const DeleteSurveyMenuItemModal = ({
  surveyName,
  deleteSurvey,
  open,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    onClose();
  };

  // Delete the survey
  const deleteSurveyApi = async () => {
    setIsLoading(true);

    try {
      await deleteSurvey();
      SnackbarUtils.success('Survey Successfully Deleted.');
      closeModal();
    } catch {
      // supress
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmModalComponent
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onConfirm={deleteSurveyApi}
      title="Delete Survey"
      text={`Are you sure you want to delete the ${surveyName} survey? This action can’t be undone.`}
      confirmButtonLabel="Delete"
      loading={isLoading}
    />
  );
};

// Bundle the modal and the menu item together for maximum convenience
export const useDeleteSurveyMenuItem = ({
  surveyName,
  deleteSurvey,
  handleMenuClose,
}) => {
  const [open, setOpen] = React.useState(false);

  const DeleteSurveyMenuItemComponent = (
    <DeleteSurveyMenuItem openDeleteSurveyModal={() => setOpen(true)} />
  );

  const DeleteSurveyMenuItemModalComponent = (
    <DeleteSurveyMenuItemModal
      key="delete-survey-modal"
      surveyName={surveyName}
      deleteSurvey={deleteSurvey}
      open={open}
      onClose={() => {
        setOpen(false);
        handleMenuClose();
      }}
    />
  );

  return {
    DeleteSurveyMenuItemComponent,
    DeleteSurveyMenuItemModalComponent,
  };
};
