import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Group } from '~/pdfs/patterns/Group'
import { Settings } from '~/pdfs/patterns/Settings'
import { Text } from '~/pdfs/patterns/Text'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { findFieldAndFormat } from '~/support/findFieldAndFormat'
import { useRangeField } from '~/support/useRangeField'
import { useSend } from '~/support/useSend'
import * as Fields from '../CresaFields'
import { CresaPage } from '../patterns/CresaPage'

const useStyles = makeStyles({
  container: {
    gridTemplateColumns: 'min-content min-content 1fr 1fr 1fr 1fr 1fr 1fr',
    display: 'grid',
    padding: '15px 0',
  },

  head: {
    background: '#243E8C',
    color: '#ffffff',
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '10px',
    display: 'flex',
    alignItems: 'center',
    height: '22px',
    padding: '0 5px',
    whiteSpace: 'nowrap',
  },

  photo: {
    padding: '2px 0 3px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },

  image: {
    width: '67px !important',
    height: '58px !important',
    background: '#D9D9D9',
    objectFit: 'cover',
  },

  field: {
    borderBottom: '1px solid #595959',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    color: '#000000',
  },

  site: {
    justifyContent: 'center',
  },
})

export const CresaSummary = definePageTemplate({
  id: '4d13275f-e925-4169-83e7-756ea3cc42c6',

  name: 'Summary of Properties',

  props: ({ data: survey, settings }, spaces) => {
    const buildings = survey.buildings.filter((building) => {
      return building.spaces.length > 0
    })

    return {
      limit: settings.spacesPerPage,
      spaces: spaces.map((space) => ({
        id: space.id,
        photo:
          space.images[0]?.thumbnail ?? space.building.images[0]?.thumbnail,
        site: buildings.indexOf(space.building) + 1,
        address: ['address', 'city', 'state']
          .map((key) => space.building.location[key])
          .filter(Boolean)
          .join(', '),
        landlord: findFieldAndFormat(space.building.fields, Fields.Landlord),
        floor: findFieldAndFormat(space.fields, Fields.Floor),
        size: findFieldAndFormat(space.fields, Fields.Area),
        rent: findFieldAndFormat(space.fields, Fields.Price),
        available: findFieldAndFormat(space.fields, Fields.Available),
      })),
    }
  },

  Page: (props) => {
    const classes = useStyles()

    return (
      <CresaPage title="Summary of Available Properties">
        <div className={classes.container}>
          <div />
          <div className={classes.head}>Site</div>
          <div className={classes.head}>Address</div>
          <div className={classes.head}>Landlord</div>
          <div className={classes.head}>Floor</div>
          <div className={classes.head}>Rentable SF</div>
          <div className={classes.head}>Base Rent</div>
          <div className={classes.head}>Available</div>
          {props.spaces.map((space) => (
            <Fragment key={space.id}>
              <div className={classes.photo}>
                {space.photo ? (
                  <img className={classes.image} src={space.photo} alt="" />
                ) : (
                  <div className={classes.image} />
                )}
              </div>
              <div className={clsx(classes.field, classes.site)}>
                {space.site}
              </div>
              <div className={classes.field}>{space.address}</div>
              <div className={classes.field}>{space.landlord}</div>
              <div className={classes.field}>{space.floor}</div>
              <div className={classes.field}>{space.size}</div>
              <div className={classes.field}>{space.rent}</div>
              <div className={classes.field}>{space.available}</div>
            </Fragment>
          ))}
        </div>
      </CresaPage>
    )
  },

  Settings: (props) => {
    const send = useSend()

    const limit = useRangeField(props.limit, 1, 10, (value) => {
      send('AddSettings', { spacesPerPage: value })
    })

    return (
      <Settings>
        <Group label="Properties Per Page">
          <Text {...limit} nullable />
        </Group>
      </Settings>
    )
  },
})
