import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
  ExportSurveySettingsAndLayout,
  SquareCheckbox,
  TextInput,
} from '~/legacy/components';

const useStyles = makeStyles({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '60px',
    borderBottom: '1px solid #e0e0e0',
    '&:last-child': {
      border: 'none',
    },
    cursor: 'pointer',
    marginTop: '30px',
  },
  checkbox: {
    marginLeft: 0,
    marginRight: '12px',
    color: '#111111',
  },
});

function ListingPhotosPageSettings(props) {
  const classes = useStyles();
  const { settings, updateSettings } = props;
  const { imageLimit, cropImages } = settings;

  const toggleCropImages = () =>
    updateSettings({
      ...settings,
      cropImages: !cropImages,
    });

  return (
    <ExportSurveySettingsAndLayout {...props}>
      <TextInput
        label="Image Limit"
        onChange={(event) =>
          updateSettings({
            ...settings,
            imageLimit: event.target.value,
          })
        }
        type="number"
        value={imageLimit}
        variant="standard"
      />
      <div className={classes.checkboxContainer} onClick={toggleCropImages}>
        <SquareCheckbox
          className={classes.checkbox}
          checked={cropImages}
          onClick={toggleCropImages}
        />
        <Typography variant="h3">Fit Images</Typography>
      </div>
    </ExportSurveySettingsAndLayout>
  );
}

export default ListingPhotosPageSettings;
