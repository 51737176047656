import React from 'react'
import { Font } from '~/components/Font'
import { styled } from '~/libraries/styled'
import { Photo } from '~/pdfs/patterns/Photo'

export const LeaseUpHeader = (props) => {
  return (
    <Container>
      {props.photo && <Photo src={props.photo} height="46px" width="46px" />}
      <Text>
        {props.title && (
          <Font format="24/28/600" padding="1px 0" truncate>
            {props.title}
          </Font>
        )}
        {props.subtitle && (
          <Font format="12/16" padding="1px 0" truncate>
            {props.subtitle}
          </Font>
        )}
      </Text>
    </Container>
  )
}

const Container = styled('div', {
  alignItems: 'center',
  backgroundColor: '#F9F9F9',
  borderBottom: '1px solid #E0E0E0',
  display: 'flex',
  flexShrink: 0,
  gap: '12px',
  height: '94px',
  padding: '0 24px',
})

const Text = styled('div', {
  minWidth: '0px',
})
