export const SET_SURVEY = 'SET_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const ADD_SURVEY_LISTING_AND_BUILDING =
  'ADD_SURVEY_LISTING_AND_BUILDING';
export const SET_SURVEY_LISTING = 'SET_SURVEY_LISTING';
export const SET_SURVEY_LISTINGS = 'SET_SURVEY_LISTINGS';
export const SET_SURVEY_BUILDINGS = 'SET_SURVEY_BUILDINGS';
export const ADD_SURVEY_BUILDING = 'ADD_SURVEY_BUILDING';
export const SET_SURVEY_LISTINGS_AND_BUILDINGS =
  'SET_SURVEY_LISTINGS_AND_BUILDINGS';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_ADD_LISTING_MODAL_STATE = 'SET_ADD_LISTING_MODAL_STATE';
export const SET_TABLE_ROW_CLICKS_ENABLED = 'SET_TABLE_ROW_CLICKS_ENABLED';

export function setSurvey(survey) {
  return { type: SET_SURVEY, survey };
}

export function updateSurvey(survey) {
  return { type: UPDATE_SURVEY, survey };
}

export function addSurveyListingAndBuilding(surveyListing, surveyBuilding) {
  return {
    type: ADD_SURVEY_LISTING_AND_BUILDING,
    surveyListing,
    surveyBuilding,
  };
}

export function setSurveyListing(surveyListing) {
  return { type: SET_SURVEY_LISTING, surveyListing };
}

export function setSurveyListings(surveyListings) {
  return { type: SET_SURVEY_LISTINGS, surveyListings };
}

export function setSurveyBuildings(surveyBuildings) {
  return { type: SET_SURVEY_BUILDINGS, surveyBuildings };
}

export function addSurveyBuilding(surveyBuilding) {
  return { type: ADD_SURVEY_BUILDING, surveyBuilding };
}

export function setSurveyListingsAndBuildings(surveyListings, surveyBuildings) {
  return {
    type: SET_SURVEY_LISTINGS_AND_BUILDINGS,
    surveyListings,
    surveyBuildings,
  };
}

export function setSelectedTab(tab) {
  return { type: SET_SELECTED_TAB, tab };
}

export function setAddListingModalState(modalState) {
  return { type: SET_ADD_LISTING_MODAL_STATE, modalState };
}

export function setTableRowClicksEnabled(enabled) {
  return { type: SET_TABLE_ROW_CLICKS_ENABLED, enabled };
}
