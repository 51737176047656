import axios from 'axios'
import Api from 'rest-fetcher-redux'

export const upload = (url, file, data = {}) => {
  const form = new FormData()

  form.append('file', file, file.name)

  Object.keys(data).forEach((key) => {
    form.append(key, data[key])
  })

  return axios.post(url, form, {
    headers: { Authorization: Api.baseOptions.headers.Authorization },
  })
}
