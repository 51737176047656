import React from 'react'
import { makeStyles, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import {
  FitScreenIcon,
  LeftChevronIcon,
  RightChevronIcon,
  SquareIconButton,
  ZoomInIcon,
  ZoomOutIcon,
} from '~/legacy/components'
import { createPageSize } from '~/libraries/print'
import { useSend } from '~/support/useSend'
import { Canvas } from './Canvas'
import { flipVector2 } from '~/libraries/math'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  canvas: {
    height: '100%',
    width: '100%',
  },
  controls: {
    position: 'absolute',
    bottom: '16px',
    right: '0px',
    borderRadius: '4px 0 0 4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 0',
    width: '44px',
    gap: '8px',
  },
})

const paperSizes = {
  letter: createPageSize(8.5, 11),
  legal: createPageSize(11, 17),
}

export const PdfCanvas = ({
  children,
  className,
  index,
  size,
  format,
  landscape,
  width,
  height,
}) => {
  const classes = useStyles()
  const send = useSend()
  const paperSize =
    width && height
      ? { x: width, y: height }
      : paperSizes[format] ?? paperSizes.letter
  const canvasSize = landscape ? flipVector2(paperSize) : paperSize

  return (
    <div className={clsx(classes.container, className)}>
      <Canvas className={classes.canvas} size={canvasSize} padding={48}>
        {children}
      </Canvas>
      <Paper className={classes.controls} elevation={3}>
        <Typography align="center" variant="h6">
          {`${index + 1} / ${size}`}
        </Typography>
        <SquareIconButton onClick={() => send('GotoNext')}>
          <RightChevronIcon />
        </SquareIconButton>
        <SquareIconButton onClick={() => send('GotoPrevious')}>
          <LeftChevronIcon />
        </SquareIconButton>
        <SquareIconButton onClick={() => send('ZoomIn')}>
          <ZoomInIcon />
        </SquareIconButton>
        <SquareIconButton onClick={() => send('ZoomOut')}>
          <ZoomOutIcon />
        </SquareIconButton>
        <SquareIconButton onClick={() => send('ZoomToFit')}>
          <FitScreenIcon />
        </SquareIconButton>
      </Paper>
    </div>
  )
}
