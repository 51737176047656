import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { format } from 'date-fns';

import { getBrokerRole, getUserName } from '~/legacy/utils';
import {
  TableCellText,
  TableHeaderCell,
  TableHeaderText,
  TableCheckboxCell,
  TableRow,
  TableHeaderRow,
  TableContentCell,
  TableMoreOptionsCell,
} from '~/legacy/components/tableComponents';
import {
  Button,
  DeleteIcon,
  TextButton,
  PageStickyNavBar,
  Typography,
  SortByTextField,
  sortUsers,
  USER_SORTS,
  DEFAULT_USER_SORT_OPTIONS,
  DeleteUserModalProvider,
  useAdminUserMenu,
  useAddBrokerToFirmModal,
} from '~/legacy/components';

const AdminUserRowContent = React.memo(({ user, handleMenuClick }) => {
  return [
    <TableContentCell key="user-name">
      <TableCellText>{getUserName(user)}</TableCellText>
    </TableContentCell>,
    <TableContentCell key="user-email">
      <TableCellText>{user.email}</TableCellText>
    </TableContentCell>,
    <TableContentCell key="user-role">
      <TableCellText>{getBrokerRole(user)}</TableCellText>
    </TableContentCell>,
    <TableContentCell key="user-last-active">
      <TableCellText>
        {user.last_active && user.last_active.timestamp
          ? format(Date.parse(user.last_active.timestamp), 'MM/dd/yyyy')
          : ''}
      </TableCellText>
    </TableContentCell>,
    <TableContentCell key="user-date-joined">
      <TableCellText>
        {format(Date.parse(user.date_joined), 'MM/dd/yyyy')}
      </TableCellText>
    </TableContentCell>,
    <TableMoreOptionsCell
      key="user-menu"
      onClick={(event) => handleMenuClick(event, user)}
    />,
  ];
});

const AdminUserRow = React.memo(
  ({ user, checked = false, checkUser = () => {}, handleMenuClick }) => {
    return (
      <TableRow key={user.id}>
        <TableCheckboxCell
          CheckboxProps={{
            checked,
            onClick: () => checkUser(user),
          }}
        />
        <AdminUserRowContent user={user} handleMenuClick={handleMenuClick} />
      </TableRow>
    );
  }
);

export const AdminUsersTab = withStyles({
  table: {
    borderRadius: '4px',
    borderCollapse: 'separate',
    border: '1px solid #E0E0E0',
    tableLayout: 'fixed',

    // Table border radiuses
    '& tr:first-child th:first-child': {
      borderTopLeftRadius: '4px',
    },
    '& tr:first-child th:last-child': {
      borderTopRightRadius: '4px',
    },
    '& tr:last-child td:first-child': {
      borderBottomLeftRadius: '4px',
    },
    '& tr:last-child td:last-child': {
      borderBottomRightRadius: '4px',
    },
  },
  pageHorizontalPadding: {
    paddingLeft: '80px',
    paddingRight: '80px',
  },
  navSort: {
    marginLeft: '16px',
  },
  deleteUsersButton: {
    height: '100%',
    padding: '0px 8px 0px 8px',
    justifyContent: 'space-between',
  },
  checkedUserActionsContainer: {
    marginLeft: 'auto',
    width: '130px',
    height: '36px',
  },
  addUserButtonContainer: {
    marginLeft: 'auto',
    width: '96px',
    height: '36px',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  tabContainer: {
    height: 'fit-content',
    width: '100%',
  },
  visible: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '80px',
  },
  hidden: {
    display: 'none',
  },
})(
  React.memo(
    ({
      adminUser,
      users,
      setUsers,
      isLoadingUsers,
      selected = false,
      classes,
    }) => {
      const [checkedUsers, setCheckedUsers] = useState(new Set());
      const [selectedSort, setSelectedSort] = useState(
        USER_SORTS.ROLE_DESCENDING.id
      );

      // Delete User Modal state
      // Could put in another provider but seems like overkill
      const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
      const [deletingOwnUser, setDeletingOwnUser] = useState(false);
      const [usersToDelete, setUsersToDelete] = useState(new Set());

      const someCheckedUsers = checkedUsers.size > 0;

      const allUsersChecked = !!(
        users &&
        users.length > 0 &&
        checkedUsers.size === users.length
      );
      const numUsers = users ? users.length : 0;
      const deletingOwnUserInCheckedUsers = useMemo(
        () => !!(someCheckedUsers && checkedUsers.has(adminUser.id)),
        [someCheckedUsers, checkedUsers, adminUser]
      );
      // The users to show on the page but sorted
      const sortedUsers = useMemo(
        () => (users && users.length ? sortUsers(users, selectedSort) : []),
        [users, selectedSort]
      );

      // Update the existing user or add it
      const updateUser = (newUserData) => {
        if (newUserData && users && users.length) {
          if (users.find((user) => user.id === newUserData.id)) {
            // update existing user
            setUsers([
              ...users.map((user) =>
                user.id === newUserData.id ? { ...user, ...newUserData } : user
              ),
            ]);
          } else {
            // append user
            setUsers([...users, newUserData]);
          }
        }
      };

      const {
        AdminUserMenuComponent,
        handleMenuClick,
        ViewUserOwnedSurveysMenuItemModalComponent,
        ResetPasswordMenuItemConfirmModalComponent,
        EditProfileMenuItemConfirmModalComponent,
      } = useAdminUserMenu({
        adminUser,
        users,
        updateUser,
      });

      const {
        setOpen: setAddBrokerToFirmModalOpen,
        AddBrokerToFirmModalComponent,
      } = useAddBrokerToFirmModal({
        firmUsers: users,
        updateUser,
      });

      useEffect(() => {
        setCheckedUsers(new Set());
      }, [users]);

      const checkUser = React.useCallback((user) => {
        setCheckedUsers((currentCheckedUsers) => {
          const newSet = new Set(currentCheckedUsers);
          currentCheckedUsers.has(user.id)
            ? newSet.delete(user.id)
            : newSet.add(user.id);
          return newSet;
        });
      }, []);

      if (isLoadingUsers && selected) {
        return (
          <div
            className={clsx(
              classes.pageHorizontalPadding,
              classes.loadingContainer
            )}
          >
            <CircularProgress size={40} />
          </div>
        );
      }

      return (
        <div
          className={clsx(
            classes.tabContainer,
            selected ? classes.visible : classes.hidden
          )}
        >
          <DeleteUserModalProvider
            users={sortedUsers}
            setUsers={setUsers}
            deleteUserModalOpen={deleteUserModalOpen}
            setDeleteUserModalOpen={setDeleteUserModalOpen}
            deletingOwnUser={deletingOwnUser}
            usersToDelete={usersToDelete}
            setUsersToDelete={setUsersToDelete}
            setDeletingOwnUser={setDeletingOwnUser}
          >
            <PageStickyNavBar
              classes={{ root: clsx(classes.pageHorizontalPadding) }}
            >
              <Typography
                variant="boldText"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {`${numUsers} User${numUsers === 1 ? '' : 's'}`}
              </Typography>
              <SortByTextField
                className={classes.navSort}
                options={DEFAULT_USER_SORT_OPTIONS}
                selectedValue={selectedSort}
                setSelectedValue={setSelectedSort}
                defaultValue={USER_SORTS.ROLE_DESCENDING}
              />
              {someCheckedUsers ? (
                <div className={classes.checkedUserActionsContainer}>
                  <TextButton
                    fullWidth
                    classesIn={{ button: classes.deleteUsersButton }}
                    onClick={() => {
                      setDeletingOwnUser(deletingOwnUserInCheckedUsers);
                      setUsersToDelete(checkedUsers);
                      setDeleteUserModalOpen(true);
                    }}
                    disabled={allUsersChecked}
                  >
                    <DeleteIcon />
                    <Typography variant="boldText">Delete Users</Typography>
                  </TextButton>
                </div>
              ) : (
                <div className={classes.addUserButtonContainer}>
                  <Button
                    color="primary"
                    shrinkButton
                    fullWidth
                    customHeight
                    onClick={() => setAddBrokerToFirmModalOpen(true)}
                  >
                    <Typography variant="boldText">Add User</Typography>
                  </Button>
                </div>
              )}
            </PageStickyNavBar>

            <div
              className={clsx(
                classes.tableContainer,
                classes.pageHorizontalPadding
              )}
            >
              <TableContainer>
                <Table size="small" className={classes.table}>
                  <colgroup>
                    <col style={{ width: '52px', minWidth: '52px' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '60px', minWidth: '60px' }} />
                  </colgroup>
                  <TableHead>
                    <TableHeaderRow>
                      <TableCheckboxCell
                        isHeader
                        CheckboxProps={{
                          checked: allUsersChecked,
                          indeterminate: !!(
                            !allUsersChecked && checkedUsers.size
                          ),
                          onClick: () => {
                            let newSet;
                            if (allUsersChecked) {
                              newSet = new Set();
                            } else {
                              newSet = new Set(users.map((user) => user.id));
                            }
                            setCheckedUsers(newSet);
                          },
                        }}
                      />
                      <TableHeaderCell>
                        <TableHeaderText>NAME</TableHeaderText>
                      </TableHeaderCell>
                      <TableHeaderCell>
                        <TableHeaderText>EMAIL</TableHeaderText>
                      </TableHeaderCell>
                      <TableHeaderCell>
                        <TableHeaderText>ROLE</TableHeaderText>
                      </TableHeaderCell>
                      <TableHeaderCell>
                        <TableHeaderText>LAST ACTIVE</TableHeaderText>
                      </TableHeaderCell>
                      <TableHeaderCell>
                        <TableHeaderText>DATE JOINED</TableHeaderText>
                      </TableHeaderCell>
                      <TableHeaderCell>
                        <TableHeaderText />
                      </TableHeaderCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    {!!(sortedUsers && sortedUsers.length) &&
                      sortedUsers.map((user) => (
                        <AdminUserRow
                          key={user.id}
                          user={user}
                          checked={checkedUsers.has(user.id)}
                          checkUser={checkUser}
                          handleMenuClick={handleMenuClick}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {AdminUserMenuComponent}
            {ResetPasswordMenuItemConfirmModalComponent}
            {ViewUserOwnedSurveysMenuItemModalComponent}
            {EditProfileMenuItemConfirmModalComponent}
            {AddBrokerToFirmModalComponent}
          </DeleteUserModalProvider>
        </div>
      );
    }
  )
);
