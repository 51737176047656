import {
  ServerStyleSheets,
  createGenerateClassName,
} from '@material-ui/core/styles'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { renderToStaticMarkup } from 'react-dom/server'
import Api from 'rest-fetcher-redux'

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  seed: 'pdf',
})

export const renderRawPdf = async (options) => {
  const sheets = new ServerStyleSheets({
    serverGenerateClassName: generateClassName,
  })

  const root = sheets.collect(options.root)

  const data = {
    html: renderToStaticMarkup(root),
    format: options.format ?? '',
    landscape: options.landscape ?? false,
    height: options.height ? `${options.height}px` : '',
    width: options.width ? `${options.width}px` : '',
    cssString: `${sheets.toString()}/* next */`,
  }

  const response = await axios.post(options.url, data, {
    headers: { Authorization: Api.baseOptions.headers.Authorization },
    responseType: 'blob',
  })

  if (options.filename) {
    fileDownload(response.data, options.filename)
  }

  return response.data
}
