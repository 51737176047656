import { v4 } from 'uuid';

export const createId = () => {
  return v4();
};

export const createImageFile = (url, overrides) => {
  return {
    url,
    card_image_url: url,
    compressed_source_image_url: url,
    raw_image_url: url,
    thumbnail_image_url: url,
    ...overrides,
  };
};
