export const sendPageEventToGA = (page, _path) => {
  if (window.gtag) {
    window.gtag('config', 'UA-148841868-1', {
      page_location: page,
      page_path: _path || page,
    });
  }
};

export default sendPageEventToGA;
