export const image = (image, options) => {
  return parseImageUrl(image, options)
}

const parseImageId = (image) => {
  const url = image?.raw
  return url && url.slice(url.lastIndexOf('/') + 1)
}

const parseImageUrl = (image, options) => {
  const id = parseImageId(image)
  return id && nextImage({ ...options, src: createImageUrl(id) })
}

const createImageUrl = (id) => {
  return `https://${process.env.APP_IMAGE_BUCKET}.s3.amazonaws.com/${id}`
}

// const getBaseUrl = () => {
//   if (typeof window !== 'undefined') return ''
//   if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`
//   return `http://localhost:${process.env.PORT ?? 3000}`
// }

const nextImage = (params) => {
  const { quality = 75, width = Infinity, src = '' } = params
  const url = new URL(`/_next/image`, location.origin)
  const w = [
    16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1920, 2048,
    3840,
  ].reduce((a, b) => {
    return a > Number(width) ? a : b
  }, 0)

  url.searchParams.set('q', String(quality))
  url.searchParams.set('url', src)
  url.searchParams.set('w', String(w))
  return url.toString()
}

// AWS_RAW_IMAGES_BUCKET
