export default {
  addressSuggestion: {
    url: `https://api.mapbox.com/geocoding/v5/mapbox.places/:search.json`,
  },
  poi: {
    url: 'https://api.mapbox.com/geocoding/v5/mapbox.places/:term.json',
  },
  poiReverse: {
    url: 'https://api.mapbox.com/geocoding/v5/mapbox.places/:lng,:lat.json',
  },
};
