import Api from 'rest-fetcher-redux';

import {
  actionAddAmenities,
  actionAddCustomAmenity,
  addListingActions,
} from '~/legacy/store';
import { downloadFile } from '~/legacy/utils';

export const mockInitResolver = ({ data: { amenities }, dispatch }) => {
  dispatch(actionAddAmenities(amenities));
};

/* eslint-disable */
export const doInit = ({ data: { refresh, access } }) => {};
/* eslint-enable */

export const downloadFileExportedPdf = ({ data }) => {
  downloadFile(null, data);
};

export const refreshListing = ({ data: { id } }) => {
  Api.getListing({ id });
};

export const refreshAfterDeleteListing = ({ actions }) => {
  actions.getMyListings();
};

export const refreshAllAfterDeleteListing = ({ actions }) => {
  actions.getListings();
};

export const addCustomAmenities = ({
  getState,
  dispatch,
  data: { amenities },
}) => {
  const {
    api: {
      init: {
        data: { amenities: existingAmenities },
      },
    },
  } = getState();
  if (!amenities) {
    // eslint-disable-next-line
    amenities = [];
  }
  amenities.forEach((a) => {
    if (!existingAmenities.find((ea) => ea.name === a)) {
      dispatch(actionAddCustomAmenity(a));
    }
  });
};

export const setEditPageInitialData = ({ dispatch, data }) => {
  dispatch(addListingActions.setInitialData(data));
};
