import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import Api from 'rest-fetcher-redux';
import { Button, PasswordRequirements, TextInput } from '~/legacy/components';
import { useApiHelper } from '~/legacy/utils/hooks';
import { updateUserData } from '~/legacy/store';
import { formatUserObject } from '~/legacy/utils';
import { toast } from '~/legacy/utils/notifications';
import { ModalTitle } from '~/legacy/components/modals';

const useStyles = makeStyles({
  content: {
    width: '548px',
    minHeight: '578px',
    padding: '4px 0 16px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    marginBottom: '28px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstInput: {
    marginBottom: '28px',
  },
  currentPassword: {
    marginBottom: '40px',
  },
  newPassword: {
    marginBottom: '28px',
  },
  confirmNewPassword: {
    marginBottom: '12px',
  },
  passwordRequirements: {
    marginBottom: '32px',
  },
  buttons: {
    marginTop: 'auto',
    marginLeft: 'auto',
  },
  firstButton: {
    marginRight: '12px',
  },
});

const AccountEditModal = React.forwardRef(
  (
    { label, onClose, subtitle, inputLabel, inputType, format, validate },
    ref
  ) => {
    const classes = useStyles();
    const apiHelper = useApiHelper();
    const dispatch = useDispatch();
    const user = useSelector((s) => s.user);
    const [newValue, setNewValue] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorText, setErrorText] = useState('');
    const [buttonState, setButtonState] = useState({
      disabled: true,
      loading: false,
    });

    const updateButtonState = React.useCallback(
      (isDisabled) => {
        setButtonState({
          ...buttonState,
          disabled: isDisabled,
        });
      },
      [buttonState]
    );

    const close = () => {
      setNewValue('');
      setPassword('');
      onClose();
    };

    useEffect(() => {
      if (inputType !== 'password') {
        setButtonState({
          ...buttonState,
          disabled: !newValue || !password,
        });
      }
    }, [inputType, newValue, password]);

    return (
      <div className={classes.content} ref={ref} tabIndex={-1}>
        <ModalTitle onClose={close} title={`Change Your ${label}`} />
        <Typography className={classes.subtitle} variant="body1">
          {subtitle}
        </Typography>
        <div className={classes.formContainer}>
          {inputType !== 'password' && (
            <>
              <TextInput
                autoFocus
                className={classes.firstInput}
                errorText={errorText}
                label={`New ${inputLabel || label}`}
                onChange={(event) => setNewValue(event.target.value)}
                type={inputType}
                value={newValue}
              />
              <TextInput
                label="Password"
                onChange={(event) => setPassword(event.target.value)}
                type="password"
                value={password}
              />
            </>
          )}
          {inputType === 'password' && (
            <>
              <TextInput
                className={classes.currentPassword}
                label="Current Password"
                onChange={(event) => setPassword(event.target.value)}
                type="password"
                value={password}
              />
              <TextInput
                className={classes.newPassword}
                label="New Password"
                onChange={(event) => setNewPassword(event.target.value)}
                type="password"
                value={newPassword}
              />
              <TextInput
                className={classes.confirmNewPassword}
                label="Confirm New Password"
                onChange={(event) => setConfirmNewPassword(event.target.value)}
                type="password"
                value={confirmNewPassword}
              />
              <PasswordRequirements
                className={classes.passwordRequirements}
                password={newPassword}
                confirmPassword={confirmNewPassword}
                setButtonDisabled={updateButtonState}
              />
            </>
          )}
        </div>
        <div className={classes.buttons}>
          <Button
            color="secondary"
            className={classes.firstButton}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            disabled={buttonState.disabled}
            color="primary"
            onClick={() => {
              if (inputType !== 'password') {
                if (validate) {
                  const isValid = validate(newValue);
                  if (isValid !== true) {
                    setErrorText(isValid);
                    return;
                  }
                }
                apiHelper
                  .updateUserData(
                    format(newValue) || { password },
                    user.id,
                    password
                  )
                  .then((res) => {
                    if (res.data && res.data.id) {
                      const formattedUserData = formatUserObject(res.data);
                      dispatch(updateUserData(formattedUserData));
                      toast('Profile update successfully.', {
                        appearance: 'success',
                      });
                      close();
                    } else {
                      // there was an error
                      toast(res.detail, { appearance: 'error' });
                    }
                  });
              } else {
                Api.updateUserPassword({
                  id: user.id,
                  body: {
                    password,
                    new_password: newPassword,
                  },
                }).then((res) => {
                  if (res.data === 'success') {
                    toast('Profile updated successfully.', {
                      appearance: 'success',
                    });
                    close();
                  } else {
                    toast(res.detail, { appearance: 'error' });
                  }
                });
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
);

export default AccountEditModal;
