import React from 'react';
import { Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';

const Table = withStyles(theme => ({
  tableContainer: {
    padding: '0 24px',
    maxWidth: '100%',
    display: 'flex',
    flex: 3,
    borderBottom: `8px solid ${theme.palette.primary.main}`,
  },
  muiTableContainer: {
    display: 'flex',
  },
  table: {},
  tableHead: {
    backgroundColor: '#f9f9f9',
    borderBottom: '1px solid #e0e0e0',
    '&> th': {
      textTransform: 'uppercase',
      lineHeight: '24px',
      padding: '0 16px',
      fontSize: '8px',
      fontWeight: 500,
      letterSpacing: '.4px',
      color: '#666666',
    },
  },
  tableRow: {
    borderBottom: '1px solid #e0e0e0',
    flex: 1,
    maxHeight: '35px',
    minHeight: '20px',
    '&> td': {
      padding: '0 16px',
      fontSize: '10px',
    },
  },
  bottomBorder: {
    height: '12px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    marginTop: 'auto',
  }
}))(({
  headers,
  data, // [[d1, d2, d3, ...], [d1, d2, d3, ...], ...]
  classes
}) => {
  // manage the keys for react
  const rowKeys = {};
  const cellKeys = {};
  const getOrCreateRowKey = (rowIndex) => {
    const key = rowKeys[rowIndex];
    if (!key) {
      rowKeys[rowIndex] = uuid();
    }
    return rowKeys[rowIndex];
  }
  const getOrCreateCellKey = (rowIndex, cellIndex) => {
    const keyId = `${rowIndex}${cellIndex}`;
    const key = cellKeys[keyId];
    if (!key) {
      cellKeys[keyId] = uuid();
    }
    return cellKeys[keyId];
  }

  return (
    <div className={classes.tableContainer}>
      <TableContainer className={classes.muiTableContainer}>
        <MuiTable className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHead}>
              {headers.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((valuesArray, rowIndex) => (
              <TableRow key={getOrCreateRowKey(rowIndex)} className={classes.tableRow}>
                {valuesArray.map((value, cellIndex) => (
                  <TableCell key={getOrCreateCellKey(rowIndex, cellIndex)}>
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </div>
  );
});

export default Table;
