import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarUtils } from '~/legacy/utils';
import { CopyIcon, Typography, MenuItem } from '~/legacy/components';
import { BASE_ICON_STYLES } from '~/legacy/components/buttons/ButtonUtils';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}));

// Menu item to duplicate a survey
export const DuplicateSurveyMenuItem = React.forwardRef(
  ({ duplicateSurvey = () => {}, handleMenuClose, ...props }, ref) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    return [
      <MenuItem
        key="duplicate-survey-menu-item"
        ref={ref}
        className={classes.menuItemRoot}
        onClick={async () => {
          setIsLoading(true);
          try {
            await duplicateSurvey();
            SnackbarUtils.success('Successfully Created New Survey.');
          } finally {
            setIsLoading(false);
            handleMenuClose();
          }
        }}
        disabled={isLoading}
        {...props}
      >
        <CopyIcon />
        <Typography className={classes.text}> Duplicate Survey </Typography>
      </MenuItem>,
    ];
  }
);

// Bundle the modal and the menu item together for maximum convenience
export const useDuplicateSurveyMenuItem = ({
  duplicateSurvey = () => {},
  handleMenuClose,
}) => (
  <DuplicateSurveyMenuItem
    duplicateSurvey={duplicateSurvey}
    handleMenuClose={handleMenuClose}
  />
);
