/* eslint-disable camelcase */
import React, { useContext } from 'react'
import { orderBy } from 'lodash'
// import Api from 'rest-fetcher-redux'
import useSWR from 'swr'
import { SurveyBreadcrumb } from '~/legacy/components'
import { useMessage } from '~/libraries/messaging.react'
import * as Fields from '~/objects/fields'
import { PdfEditor } from '~/pdfs/patterns/PdfEditor'
import { renderPdfTemplate } from '~/pdfs/support/renderPdfTemplate'
import { Cresa } from '~/pdfs/templates/cresa/Cresa'
import { LeaseUp } from '~/pdfs/templates/leaseup/LeaseUp'
import { LocalEventStore } from '~/services/providers/LocalEventStore'
import { download } from '~/support/download'
import { apiUrl } from '~/support/apiUrl'
import { useSend } from '~/support/useSend'
import { getSubdomain } from '~/support/getSubdomain'
import { JLL } from '~/pdfs/templates/jll/JLL'
import { Calgary } from '~/pdfs/templates/calgary/Calgary'
import { sentence } from '~/support/sentence'
import { captureSentryExceptionError, useSkinnedTheme } from '~/legacy/utils'
import { Firm } from '~/services/Firm'
import { useFetch } from '~/support/useFetch'

const fieldMap = [
  null,
  Fields.Text,
  null,
  Fields.Number,
  null,
  Fields.Area,
  Fields.Currency,
  Fields.Date,
]

const templateMap = {
  cresa: [Cresa, LeaseUp],
  groundfloorrealty: [LeaseUp],
  jll: [JLL, Calgary],
}

export default ({ match }) => {
  return (
    <LocalEventStore>
      <PdfExporterForSurvey id={match.params.id} />
    </LocalEventStore>
  )
}

const parseImage = (image = {}) => ({
  card: image.card_image_url,
  compressed: image.compressed_source_image_url,
  raw: image.raw_image_url,
  thumbnail: image.thumbnail_image_url,
})

const parseFields = (source, fields, fieldset) => [
  ...fieldset.map((field) => ({
    id: field.id,
    name: field.name,
    value: source[field.key],
    type: field.type,
    custom: false,
  })),
  ...fields.map((field) => ({
    id: field.id,
    name: field.name,
    value: field.custom_field.value,
    type: fieldMap[field.custom_field.data_type] ?? Fields.Text,
    custom: true,
  })),
]

const orderFields = (fields, orders) => {
  return orderBy(
    fields.map((field) => {
      const order = orders.find((order) => order.field_id === field.id)
      return { ...field, order: order ? order.order : null }
    }),
    ['order'],
    ['asc']
  )
}

const parseUser = (data) => ({
  id: data.id,
  name: sentence([data.first_name, data.last_name]) || data.username,
  email: data.email,
  roles: [data.user_type === 'Tenant' ? 'Client' : 'Broker'],
})

const parseSurvey = (data) => ({
  id: data.id,
  name: data.name,
  users: [data.owner, ...data.users].map(parseUser),
  company: data.owner.company.name,
})

const parseLocation = (data) => ({
  address: data.address,
  city: data.city,
  coordinates: [data.longitude, data.latitude].map(Number),
  name: data.name,
  state: data.state,
  zip: data.zip,
})

const parseBuildingWalkscores = (data) => {
  if (!data) return []

  return [
    data.description && {
      type: 'walk',
      description: data.description,
      score: data.walkscore,
    },
    data.transit && {
      type: 'transit',
      description: data.transit.description,
      score: data.transit.score,
    },
    data.bikescore && {
      type: 'bike',
      description: data.bikescore.description,
      score: data.bikescore.score,
    },
  ].filter(Boolean)
}

const parseSurveyBuilding = (data, index) => {
  const fields = orderFields(
    parseFields(data.building, data.building.custom_fields, [
      Fields.BuildingDescription,
      Fields.Floors,
      Fields.PropertyType,
      Fields.BuildingSize,
      Fields.ParkingRatio,
    ]),
    data.field_order
  )

  return {
    id: data.building.id,
    fields,
    amenities: data.building.amenities ?? [],
    images: data.building.images.map(parseImage),
    location: parseLocation(data.building),
    spaces: [],
    walkscores: parseBuildingWalkscores(data.building.walkscore),
    order: index,
  }
}

export const parseSurveyBuildings = (data) => {
  const buildings = orderBy(data, ['order', 'building.id'], ['asc', 'asc'])
  return buildings.map(parseSurveyBuilding)
}

export const parseSurveyListing = (data, index) => {
  const fields = orderFields(
    parseFields(data.listing, data.listing.custom_fields, [
      Fields.SpaceName,
      Fields.SpaceNotes,
      Fields.Size,
      Fields.Price,
      Fields.DateAvailable,
      Fields.LeaseTerm,
      Fields.VacancyType,
      Fields.LeaseType,
    ]),
    data.field_order
  )

  const space = {
    id: data.listing.id,
    building: data.listing.building,
    fields,
    floorplans: data.listing.floorplan_photos.map(parseImage),
    images: data.listing.images.map(parseImage),
    order: index,
  }

  return space
}

export const parseSurveyListings = (data) => {
  const listings = orderBy(data, ['order', 'listing.id'], ['asc', 'asc'])
  return listings.map(parseSurveyListing)
}

const PdfExporterForSurvey = ({ id }) => {
  const { subdomain: subDomain } = useContext(Firm)
  const { theme } = useSkinnedTheme({ subDomain })
  const fetch = useFetch()
  const send = useSend()

  const survey = useSWR(`/surveys/${id}/pdf`, {
    onError: captureSentryExceptionError,
    fetcher: async () => {
      const requests = await Promise.all([
        fetch(apiUrl(`/surveys/${id}/`), { method: 'GET' }),
        fetch(apiUrl(`/surveys/${id}/all_listings/`), { method: 'GET' }),
      ])

      const survey = parseSurvey(requests[0])
      survey.buildings = parseSurveyBuildings(requests[1].survey_buildings)
      survey.spaces = parseSurveyListings(requests[1].survey_listings)

      survey.buildings.forEach((building) => {
        building.survey = survey
      })

      survey.spaces.forEach((space) => {
        space.building = survey.buildings.find((building) => {
          return building.id === space.building.id
        })

        space.building.spaces.push(space)
      })

      return survey
    },
  })

  useMessage('ExportPdf', async ({ pdf, pages }) => {
    const url = apiUrl(`/surveys/${survey.data.id}/export/`)
    const name = `${survey.data.name.replaceAll(' ', '')}.pdf`
    download(await renderPdfTemplate(url, pdf, pages, theme), name)
    send('PdfExported')
  })

  if (!survey.data) {
    return null
  }

  const template = getSubdomain()
  const templates = templateMap[template] ?? [LeaseUp]

  return (
    <PdfEditor data={survey.data} templates={templates}>
      <SurveyBreadcrumb
        pageType={5}
        surveyId={survey.data.id}
        surveyName={survey.data.name}
      />
    </PdfEditor>
  )
}
