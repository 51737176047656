import { API_ACTIONS } from '~/legacy/store/actions';
import { toast } from '~/legacy/utils/notifications';
import { doInit } from './postfetch';

const mockData = {
  amenities: [],
};

export default {
  userData: {
    url: '/user/me/',
  },
  init: {
    url: '/init/',
    // postfetch: [mockInitResolver],
    transformer: (data, oldData) => {
      let newData = { ...oldData };
      if (data) {
        newData = { ...newData, ...data };
      }
      return newData;
    },
    changeOnAction: {
      '@@INIT': (state) => ({
        ...state,
        data: mockData,
      }),
      [API_ACTIONS.ADD_AMENITY]: (state, action) => {
        const { data } = state;
        if (!data.amenities.find((a) => a.name === action.payload)) {
          data.amenities.push({ name: action.payload });
        }
        return { ...state, data };
      },
    },
  },
  login: {
    url: '/token/',
    options: {
      method: 'POST',
    },
    postfetch: [doInit],
  },
  changePassword: {
    url: '/useradmin/password_change/',
    options: {
      method: 'POST',
    },
  },
  registerUser: {
    url: '/useradmin/create_user/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      ({ data: { refresh, access } }) => {
        if (refresh && access) {
          toast('Successfully registered! Confirm your email address!', {
            appearance: 'success',
          });
        }
      },
    ],
  },
  resetPasswordEmail: {
    url: '/useradmin/password_reset_request/',
    options: {
      method: 'POST',
    },
  },
  resetPassword: {
    url: '/useradmin/password_reset/',
    options: {
      method: 'POST',
    },
  },
  createPassword: {
    url: '/useradmin/password_create/',
    options: {
      method: 'POST',
    },
  },
  confirmBrokerEmail: {
    url: '/useradmin/confirm_registration/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      ({ data: { refresh, access } }) => {
        if (refresh && access) {
          toast('Successfully registered! Check your email address!', {
            appearance: 'success',
          });
        }
      },
    ],
  },
  sendDemoRequest: {
    url: '/useradmin/send_demo_request/',
    options: {
      credentials: 'omit',
      method: 'POST',
    },
  },
  doesUserExist: {
    url: '/useradmin/user_exists/',
    options: {
      credentials: 'omit',
      method: 'POST',
    },
    headers: {
      Authorization:
        'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjAxMzMwMjk1LCJqdGkiOiI4ZTE2YmU5YmVhZjE0YWY4ODQyMTE2MGViNmNkNWVjMyIsInVzZXJfaWQiOjN9.RNGa7PE0QczrQmXkSR98EjNz_ZzlOL_QcS5RtEyHqqQ',
    },
  },
  getProductBoardToken: {
    url: '/users/:id/get_product_board_sso_token/',
  },
  getZendeskToken: {
    url: '/users/:id/get_zendesk_sso_token/',
  },
  getUsersInMyCompany: {
    url: '/users/:id/get_users_in_my_company/',
  },
  deleteUsers: {
    url: '/useradmin/delete_users/',
    options: {
      method: 'POST',
    },
  },
  removeAvatarImage: {
    url: '/users/:id/remove_avatar_image/',
    options: {
      method: 'POST',
    },
  },
  getNotificationSubscriptions: {
    url: '/users/:id/get_notification_subscriptions/',
  },
  updateNotificationSubscriptions: {
    url: '/users/:id/update_notification_subscriptions/',
    options: {
      method: 'POST',
    },
  },
};
