import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { isBroker, IsPreviewContext } from '~/legacy/utils';
import {
  EditSpacesModal,
  Link,
  SpaceCard,
  Typography,
} from '~/legacy/components';

const useStyles = makeStyles({
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spaces: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  spaceCard: {
    '&:not(:last-child)': {
      marginBottom: '16px',
    },
  },
});

export default function SpacesSection({
  className,
  surveySpaces,
  surveyId,
  surveyBuilding,
  buildingPrimaryName,
  mutate,
  deleteSurveyListing,
  updateListing,
  createCustomListingField,
  createCustomListingFields,
  updateListingCustomField,
  deleteListingCustomField,
  deleteListingCustomFields,
  renameListingCustomFields,
  changeListingCustomFieldDataType,
  clearSpacesField,
  createSurveyListing,
  setSpacesFieldOrder,
  copySpacesFromSurvey,
}) {
  const classes = useStyles();
  const [isEditingSpaces, setIsEditingSpaces] = useState(false);
  const isUserBroker = useSelector((s) => isBroker(s.user));
  const { isPreview } = React.useContext(IsPreviewContext);
  const spaces = surveySpaces.map((sl) => sl.listing);

  return (
    <div className={className}>
      {spaces.length ? (
        <>
          <div className={classes.titleSection}>
            <Typography variant="pageSubTitle">Spaces</Typography>
            {!!isUserBroker && !isPreview && (
              <Link onClick={() => setIsEditingSpaces(true)}>
                <Typography variant="boldText">Add/Edit Spaces</Typography>
              </Link>
            )}
          </div>
          <div className={classes.spaces}>
            {spaces.map((space) => (
              <SpaceCard
                key={space.id}
                className={classes.spaceCard}
                space={space}
                updateListing={updateListing}
              />
            ))}
          </div>
        </>
      ) : (
        <SpaceCard
          className={classes.spaceCard}
          createSurveyListing={() => {
            setIsEditingSpaces(true);
            createSurveyListing({ surveyBuildingId: surveyBuilding.id });
          }}
          isPlaceholder
        />
      )}
      <EditSpacesModal
        fullscreen
        ModalComponentProps={{
          open: isEditingSpaces,
          onClose: () => setIsEditingSpaces(false),
        }}
        buildingName={buildingPrimaryName}
        surveyId={surveyId}
        surveyBuilding={surveyBuilding}
        surveySpaces={surveySpaces}
        mutate={mutate}
        deleteSurveyListing={deleteSurveyListing}
        updateListing={updateListing}
        createCustomListingField={createCustomListingField}
        createCustomListingFields={createCustomListingFields}
        updateListingCustomField={updateListingCustomField}
        deleteListingCustomField={deleteListingCustomField}
        deleteListingCustomFields={deleteListingCustomFields}
        renameListingCustomFields={renameListingCustomFields}
        changeListingCustomFieldDataType={changeListingCustomFieldDataType}
        clearSpacesField={clearSpacesField}
        createSurveyListing={createSurveyListing}
        setSpacesFieldOrder={setSpacesFieldOrder}
        copySpacesFromSurvey={copySpacesFromSurvey}
      />
    </div>
  );
}
