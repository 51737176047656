import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from 'rest-fetcher-redux';
import { isBroker } from '~/legacy/utils';

const ZENDESK_ACCESS_URL = 'https://leaseuphelp.zendesk.com/access/jwt';
const ZENDESK_RETURN_TO_URL =
  'https://leaseuphelp.zendesk.com/hc/en-us/requests/new';

export default function () {
  const user = useSelector((store) => store.user);
  const [linkState, setLinkState] = useState({
    loaded: false,
    link: '',
  });

  const refreshToken = () => {
    if (user.id && isBroker(user)) {
      Api.getZendeskToken({ id: user.id }).then((res) =>
        setLinkState({
          loaded: true,
          link: `${ZENDESK_ACCESS_URL}?jwt=${res.data.key}&return_to=${ZENDESK_RETURN_TO_URL}`,
        })
      );
    }
  };

  useEffect(() => {
    refreshToken();
  }, [user]);

  return [linkState, refreshToken];
}
