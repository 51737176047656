import { surveyBuildingApi } from '~/legacy/fetchApi';

const tableDataPaddingLeft = 6;
const TABLE_MIN_WIDTH_PX = 790;
const TABLE_MARGIN_PX = 32;
const TABLE_WINDOW_MIN_WIDTH_PX = TABLE_MIN_WIDTH_PX + TABLE_MARGIN_PX * 2;
const MAP_WINDOW_MIN_WIDTH_PX = 300;
const TABLE_AND_MAP_VIEW_MIN_WIDTH_PX =
  TABLE_WINDOW_MIN_WIDTH_PX + MAP_WINDOW_MIN_WIDTH_PX;

/*
|     Drag      |      Checkbox     |      Expand     |      Hero Photo     |        Address        | Field 1 | Field 2 | Field 3 | Field 4 | Field 5 |      More Options     |
| DRAG_WIDTH_PX | CHECKBOX_WIDTH_PX | EXPAND_WIDTH_PX | HERO_PHOTO_WIDTH_PX | ADDRESS_WIDTH_PERCENT |            TOTAL_FIELDS_WIDTH_PERCENT           | MORE_OPTIONS_WIDTH_PX |
|     24px      |        32px       |       30px      |          60px       |                                 "100%"                                  |         62px         |
|     24px      |        32px       |       30px      |          60px       |         "35%"         |                       "65%"                     |         62px         |
*/

const ROW_LINK_ICON_WIDTH_PX = 24;
const LINK_ICON_BROKER_RIGHT_PADDING_PX = 16;

// Table column widths
const DRAG_WIDTH_PX = 32; // 24 + 8 padding
const CHECKBOX_WIDTH_PX = 32;
const EXPAND_WIDTH_PX = 30;
const HERO_PHOTO_WIDTH_PX = 60;
const MORE_OPTIONS_WIDTH_PX =
  30 + 16 + 16 + ROW_LINK_ICON_WIDTH_PX + LINK_ICON_BROKER_RIGHT_PADDING_PX; // options icon + left padding + right padding + link icon + between padding

const getNumberFieldColumns = (isCondensed, isBroker) => {
  if (isCondensed) {
    return 2;
  }
  return isBroker ? 5 : 4;
};

const getTotalNumberColumns = (isCondensed, isBroker) => {
  return 5 + getNumberFieldColumns(isCondensed, isBroker) + 1;
};

const getAddressWidthPercent = (isCondensed) => {
  if (isCondensed) {
    return 50;
  }
  return 35;
};

const getOtherWidthPercent = (isCondensed, isBroker) => {
  return (
    (100 - getAddressWidthPercent(isCondensed)) /
    getNumberFieldColumns(isCondensed, isBroker)
  );
};

// Tenant properties
const LEFT_CHECKBOX_SPACE_WIDTH_PX = 20;
const LINK_ICON_TENANT_RIGHT_PADDING_PX = 24;
// padding left + padding right + icon sizes + between space
// = 40 + 19 + (28 * 2) + 25 = 141
const TENANT_ACTIONS_WIDTH_PX =
  141 + ROW_LINK_ICON_WIDTH_PX + LINK_ICON_TENANT_RIGHT_PADDING_PX;

// TODO: replace with theme
const PRIMARY_TEXT = '#111';
const SECONDARY_TEXT = '#666';

const ROW_HEIGHT_PX = 78;
const ROW_VERTICAL_PADDING_PX = 11;
const BORDER_COLOR = '#E0E0E0';
const NESTED_ROW_BORDER_WIDTH_PX = '1.5';

const STICKY_BAR_HEIGHT_PX = 75;

const SURVEY_LISTING_TABLE_STYLES = (theme) => {
  return {
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderBottom: 'none',
      borderRadius: '4px 4px 0 0',
      minWidth: `${TABLE_MIN_WIDTH_PX}px`,
    },
    surveyListingsTable: {
      tableLayout: 'fixed',
      borderCollapse: 'separate',
      minWidth: `${TABLE_MIN_WIDTH_PX}px`,
    },
    head: {
      backgroundColor: 'rgb(249, 249, 249);',
      color: SECONDARY_TEXT,
      letterSpacing: '.4px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontFamily: ['Inter-Medium'],
    },
    headNoPadding: {
      padding: '0 !important',
    },
    hover: {
      '&:hover': {
        background: 'rgb(249, 249, 249)',
      },
    },
    checkboxCell: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dragIcon: {
      display: 'flex',
      color: theme.custom.secondaryText.color,
    },
    headerRow: {
      height: '52px',
    },
    pointer: {
      cursor: 'pointer',
    },
    placeholderText: {
      textAlign: 'center',
      padding: '20px',
    },
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    divTableData: {
      margin: 'auto',
      paddingLeft: 0,
      paddingRight: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.1px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    centerAlign: {
      textAlign: 'center',
    },
    tableDataLeft: {
      paddingLeft: `${tableDataPaddingLeft}px !important`,
    },
    flexRowContent: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    divRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    divColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    spaceName: {
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingLeft: '10px',
      width: '100%',
    },
    divRowPadding: {
      padding: `${ROW_VERTICAL_PADDING_PX}px 0`,
      height: `${ROW_HEIGHT_PX}px`,
    },
    rowTableCell: {
      padding: '0 0 0 0 !important',
    },
    rowBorder: {
      border: `1px solid ${BORDER_COLOR}`,
    },
    nestedDivRowPadding: {
      padding: '0 0 0 0 !important',
      height: `${ROW_HEIGHT_PX - ROW_VERTICAL_PADDING_PX * 2}px`,
    },
    dragPadding: {
      paddingLeft: '8px',
    },
    dragWidth: {
      width: `${DRAG_WIDTH_PX}px`,
      minWidth: `${DRAG_WIDTH_PX}px`,
    },
    checkboxWidth: {
      width: `${CHECKBOX_WIDTH_PX}px`,
      minWidth: `${CHECKBOX_WIDTH_PX}px`,
    },
    leftCheckboxSpaceWidth: {
      width: `${LEFT_CHECKBOX_SPACE_WIDTH_PX}px`,
      minWidth: `${LEFT_CHECKBOX_SPACE_WIDTH_PX}px`,
    },
    expandWidth: {
      width: `${EXPAND_WIDTH_PX}px`,
      minWidth: `${EXPAND_WIDTH_PX}px`,
    },
    heroPhotoWidth: {
      width: `${HERO_PHOTO_WIDTH_PX}px`,
      minWidth: `${HERO_PHOTO_WIDTH_PX}px`,
    },
    addressWidth: {
      width: ({ isCondensed }) => `${getAddressWidthPercent(isCondensed)}%`,
    },
    otherWidth: {
      width: ({ isCondensed, isBroker }) =>
        `${getOtherWidthPercent(isCondensed, isBroker)}%`,
    },
    moreOptionsWidth: {
      width: `${MORE_OPTIONS_WIDTH_PX}px`,
      minWidth: `${MORE_OPTIONS_WIDTH_PX}px`,
    },
    moreOptions: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tenantActionsWidth: {
      width: `${TENANT_ACTIONS_WIDTH_PX}px`,
      minWidth: `${TENANT_ACTIONS_WIDTH_PX}px`,
    },
    iconRoot: {
      padding: '6px',
    },
    checkbox: {
      padding: '2px',
      margin: 'auto !important',
      marginLeft: 'auto !important',
      color: `${PRIMARY_TEXT} !important`,
    },
    checkboxDisabled: {
      padding: '2px',
      margin: 'auto !important',
      marginLeft: 'auto !important',
      cursor: 'default',
    },
    black: {
      color: PRIMARY_TEXT,
    },
    nonNestedRowAddressImage: {
      width: '50px',
      height: '50px',
      margin: 'auto',
    },
    nestedListingIndentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '18px',
      height: '100%',
    },
    nestedListingIndentTop: {
      width: '100%',
      height: '25%',
    },
    nestedListingIndentMiddle: {
      width: '100%',
      height: '25%',
      borderLeft: `${NESTED_ROW_BORDER_WIDTH_PX}px solid ${BORDER_COLOR}`,
      borderBottom: `${NESTED_ROW_BORDER_WIDTH_PX}px solid ${BORDER_COLOR}`,
    },
    nestedListingIndentBottom: {
      width: '100%',
      height: '50%',
    },
    nestedLeftBorder: {
      borderLeft: `${NESTED_ROW_BORDER_WIDTH_PX}px solid ${BORDER_COLOR}`,
    },
    buildingAddressContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
    },
    loadingContainer: {
      width: '100%',
      display: 'flex',
    },
    loading: {
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
      marginTop: '40px',
      display: 'flex',
    },
    editableValue: {
      height: '33px',
      display: 'block',
      lineHeight: '33px',
      marginRight: '24px',
    },
    editableValueInput: {
      marginRight: '24px',
    },
    linkIconBroker: {
      width: '28px',
    },
    // TODO: remove by refactoring button class to use withStyles properly.
    hoverAction: {
      marginRight: `${LINK_ICON_BROKER_RIGHT_PADDING_PX}px !important`,
      margin: `0 ${LINK_ICON_BROKER_RIGHT_PADDING_PX}px 0 0 !important`,
    },
    linkIconTenant: {
      marginRight: `21px`,
      paddingLeft: '40px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
    },
    hidden: {
      visibility: 'hidden',
    },
  };
};

const getSurveyListingCardScrollToId = (listingId) => {
  return `survey-listing-card-${listingId}`;
};

const getSurveyListingTableScrollToId = (buildingId) => {
  return `survey-listing-table-row-${buildingId}`;
};

const updateSurveyBuildingInterestSdp = async (
  event,
  newInterest,
  surveyBuilding,
  allSurveyBuildings,
  dispatch,
  setSurveyBuildings,
  setLoadingInterest,
  theme
) => {
  event.stopPropagation();
  event.preventDefault();
  setLoadingInterest(true);
  return surveyBuildingApi
    .updatePartial({
      surveyBuildingId: surveyBuilding.id,
      partial: { interest: newInterest },
      theme,
    })
    .then(() => {
      const newSurveyBuildings = [];
      allSurveyBuildings.forEach((surveyBuildingLocal) => {
        if (surveyBuildingLocal.id === surveyBuilding.id) {
          newSurveyBuildings.push({
            ...surveyBuildingLocal,
            interest: newInterest,
          });
        } else {
          newSurveyBuildings.push(surveyBuildingLocal);
        }
      });
      dispatch(setSurveyBuildings(newSurveyBuildings));
      setLoadingInterest(false);
    });
};

export {
  SURVEY_LISTING_TABLE_STYLES,
  TABLE_MIN_WIDTH_PX,
  TABLE_WINDOW_MIN_WIDTH_PX,
  TABLE_AND_MAP_VIEW_MIN_WIDTH_PX,
  MAP_WINDOW_MIN_WIDTH_PX,
  STICKY_BAR_HEIGHT_PX,
  getNumberFieldColumns,
  getTotalNumberColumns,
  getSurveyListingCardScrollToId,
  getSurveyListingTableScrollToId,
  updateSurveyBuildingInterestSdp,
};
