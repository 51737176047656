import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ModalTitleGeneric } from '~/legacy/components/modals';

const useStyles = makeStyles({
  titleContainer: {
    justifyContent: 'space-between',
    marginBottom: '26px',
    display: 'flex',
  },
});

function ConfirmModalTitle({ onClose, title, classNames = {} }) {
  const classes = useStyles();
  return (
    <ModalTitleGeneric
      title={title}
      classNames={{ container: classes.titleContainer, ...classNames }}
      onClose={onClose}
    />
  );
}
ConfirmModalTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ConfirmModalTitle;
