import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { arePropsEqualLodash } from '~/legacy/utils';
import { File, Typography } from '~/legacy/components';
import { ActivityItem, ActivityTextFormatted } from './ActivityItem';

const IMAGE_PADDING_PX = 12;
const BELOW_IMAGE_PADDING_PX = 30;
export const SurveyBuildingCommentActivityItem = withStyles({
  fileContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
    paddingTop: '16px',
  },
  image: {
    width: '150px',
    height: '150px',
    display: 'flex',
    margin: `0 ${IMAGE_PADDING_PX}px ${IMAGE_PADDING_PX}px 0`,
    objectFit: 'cover',
    '&> div': {
      cursor: 'zoom-in',
    },
  },
  modalPaper: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '100%',
    backgroundColor: 'unset',
    boxShadow: 'none',
    maxHeight: '80%',
    cursor: 'zoom-out',
  },
  modalContainer: {
    cursor: 'zoom-out',
  },
  edited: {
    color: '#666',
  },
  activityItemRootWithImages: {
    paddingBottom: `${BELOW_IMAGE_PADDING_PX - IMAGE_PADDING_PX}px`,
  },
  activityItemRootNoImages: {
    paddingBottom: '24px',
  },
  activityContainer: {
    marginRight: '110px',
  },
})(
  React.memo(
    ({
      classes,
      activity,
      currentDate,
      setNewActivityMessage = () => {},
      openMenu = () => {},
      isMenuOpen = false,
    }) => {
      const [imageModalState, setImageModalState] = useState({
        visible: false,
        imageUrl: '',
      });
      const hasImages =
        activity && activity.photos && activity.photos.length > 0;
      return (
        <ActivityItem
          activity={activity}
          setNewActivityMessage={setNewActivityMessage}
          editable
          currentDate={currentDate}
          openMenu={openMenu}
          isMenuOpen={isMenuOpen}
          classes={{
            root: hasImages
              ? classes.activityItemRootWithImages
              : classes.activityItemRootNoImages,
            activityContainer: classes.activityContainer,
          }}
        >
          {!!(activity.text || activity.edited) && (
            <ActivityTextFormatted>
              {activity.text}
              {activity.text && activity.edited ? ' ' : ''}
              {!!activity.edited && (
                <Typography component="span" className={classes.edited}>
                  (edited)
                </Typography>
              )}
            </ActivityTextFormatted>
          )}

          {/* Show the uploaded photos */}
          {!!hasImages && [
            <div key="images-container" className={classes.fileContainer}>
              {activity.photos.map((activityPhoto) => {
                return (
                  <div
                    className={classes.image}
                    key={activityPhoto.id}
                    onClick={() =>
                      setImageModalState({
                        visible: true,
                        imageUrl: activityPhoto.raw_image_url,
                      })
                    }
                  >
                    <File file={activityPhoto} skipUploading showAsThumbnail />
                  </div>
                );
              })}
            </div>,
            <Dialog
              key="image-viewer"
              classes={{
                container: classes.modalContainer,
                paper: classes.modalPaper,
              }}
              fullWidth
              maxWidth="lg"
              onClick={() =>
                setImageModalState({ visible: false, imageUrl: '' })
              }
              onClose={() =>
                setImageModalState({ visible: false, imageUrl: '' })
              }
              open={imageModalState.visible}
              PaperProps={{
                style: { backgroundImage: `url(${imageModalState.imageUrl})` },
              }}
            />,
          ]}
        </ActivityItem>
      );
    },
    arePropsEqualLodash
  )
);
