import reactUseWebSocket from 'react-use-websocket';
import { getTokensFromLocalStorage } from '~/legacy/utils/localStorageHelper';
import {
  ACTIVITY_LISTING,
  ACTIVITY_SURVEY_LISTING,
  ACTIVITY_SURVEY,
  ACTIVITY_SURVEY_BUILDING,
} from '~/legacy/consts';

const getWebSocketUrl = (route) => {
  return `${process.env.APP_WEBSOCKET_URL}${route}?token=${
    getTokensFromLocalStorage().access
  }`;
};

const useWebSocket = ({ route, ...params }) => {
  return reactUseWebSocket(getWebSocketUrl(route), {
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
    reconnectAttempts: 3,
    ...params,
  });
};

const useListingActivityWebSocket = ({
  listingId,
  surveyId = null,
  ...websocketParams
}) => {
  let route;
  if (surveyId) {
    route = ACTIVITY_SURVEY_LISTING.replace(':survey_id', surveyId).replace(
      ':listing_id',
      listingId
    );
  } else {
    route = ACTIVITY_LISTING.replace(':listing_id', listingId);
  }
  return useWebSocket({ route, websocketParams });
};

const useSurveyBuildingActivityWebSocket = ({
  buildingId,
  surveyId,
  ...websocketParams
}) => {
  let route;
  if (surveyId) {
    route = ACTIVITY_SURVEY_BUILDING.replace(':survey_id', surveyId).replace(
      ':building_id',
      buildingId
    );
  }
  return useWebSocket({ route, websocketParams });
};

const useSurveyActivityWebSocket = ({ surveyId, ...websocketParams }) => {
  const route = ACTIVITY_SURVEY.replace(':survey_id', surveyId);
  return useWebSocket({ route, websocketParams });
};

export {
  getWebSocketUrl,
  useWebSocket,
  useListingActivityWebSocket,
  useSurveyActivityWebSocket,
  useSurveyBuildingActivityWebSocket,
};
