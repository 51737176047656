import { useOrder } from '~/legacy/utils/hooks';

export const projectSortOptions = [
  {
    value: 'company-asc',
    label: 'Company: Ascending',
    title: 'Company (Ascending)',
    sort: (a, b) => a.company.localeCompare(b.company),
  },
  {
    value: 'company-desc',
    label: 'Company: Descending',
    title: 'Company (Descending)',
    sort: (a, b) => b.company.localeCompare(a.company),
  },
  {
    value: 'project-asc',
    label: 'Project: Ascending',
    title: 'Project (Ascending)',
    sort: (a, b) => a.name.localeCompare(b.name),
  },
  {
    value: 'project-desc',
    label: 'Project: Descending',
    title: 'Project (Descending)',
    sort: (a, b) => b.name.localeCompare(a.name),
  },
  {
    value: 'status-asc',
    label: 'Status: Ascending',
    title: 'Status (Ascending)',
    sort: (a, b) => a.status.localeCompare(b.status),
  },
  {
    value: 'status-desc',
    label: 'Status: Descending',
    title: 'Status (Descending)',
    sort: (a, b) => b.status.localeCompare(a.status),
  },
];

export const useProjectOrder = (projects, initial) => {
  return useOrder(projects, projectSortOptions, initial);
};
