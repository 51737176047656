import React from 'react'
import {
  CloseIcon,
  Scroll,
  Spacer,
  SquareIconButton,
  TextInput,
} from '~/legacy/components'
import { ActivityCard } from '~/legacy/components/ActivityCard'
import { ActivityMessage } from '~/legacy/components/ActivityMessage'
import { ModalOverlay } from '~/legacy/components/ModalOverlay'
import { styled } from '~/legacy/utils/styleHelpers'

export const Documents = ({ onClose, ...props }) => {
  return (
    <ModalOverlay onClose={onClose}>
      <Container {...props}>
        <Header>
          Documents
          <Spacer />
          <SquareIconButton onClick={onClose}>
            <CloseIcon />
          </SquareIconButton>
        </Header>
        <Scroll>
          <Content>
            <ActivityMessage
              avatar={<Avatar src="/prototype/Profile1.png" />}
              title="Lisa Samuels, Ground Floor Realty"
              subtitle="1 Hour Ago"
              text={<span>Added a document</span>}
            >
              <ActivityCard image="/prototype/Message3.png">
                <Address>
                  The Nest Terms
                  <span>PDF</span>
                </Address>
              </ActivityCard>
            </ActivityMessage>
          </Content>
        </Scroll>
        <Footer>
          <TextInput label="Drag and drop or click to browse" />
        </Footer>
      </Container>
    </ModalOverlay>
  )
}

const Container = styled('div', {
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  width: 480,
  zIndex: 10000001,
})

const Header = styled('div', {
  borderBottom: '1px solid #E0E0E0',
  alignItems: 'center',
  display: 'flex',
  height: 60,
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '22px',
  letterSpacing: '0.1px',
  color: '#111111',
  paddingLeft: 32,
  paddingRight: 24,
})

const Footer = styled('div', {
  padding: '16px 24px 24px',
  borderTop: '1px solid #E0E0E0',
})

const Content = styled('div', {
  padding: '32px 24px',
})

const Avatar = styled('img', {
  backgroundColor: 'rgba(30, 132, 122, 0.1)',
  borderRadius: '100%',
  height: '32px',
  outline: '1px solid #e0e0e0',
  outlineOffset: '-1px',
  width: '32px',
  minWidth: '32px',
})

const Address = styled('div', {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: '0.1px',
  color: '#111',
  '& span': {
    fontWeight: 400,
    display: 'block',
  },
})
