const ACTIONS = {
  EDIT_KEY_VALUE_STRING: 'registration_edit_key_value_string',
  SET_INITIAL_DATA: 'registration_set_initial_data',
};

const getInitialState = () => ({
  first_name: '',
  last_name: '',
  email: '',
  password: '',
});

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.EDIT_KEY_VALUE_STRING:
      return {
        ...state,
        [action.key]: action.value,
      };
    case ACTIONS.SET_INITIAL_DATA: {
      return { ...getInitialState(), ...action.payload };
    }
    default:
      return state;
  }
}

export const registrationActions = {
  setKeyValueString: (key, value) => ({
    type: ACTIONS.EDIT_KEY_VALUE_STRING,
    key,
    value,
  }),

  setInitialData: payload => ({
    type: ACTIONS.SET_INITIAL_DATA,
    payload,
  }),
};
