import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ScheduleTourCheckedIcon({ checkColor = 'white' }) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 4.5H17V2.5H15.5V4.5H8.5V2.5H7V4.5H5C3.9 4.5 3 5.4 3 6.5V19.5C3 20.6 3.9 21.5 5 21.5H19C20.1 21.5 21 20.6 21 19.5V6.5C21 5.4 20.1 4.5 19 4.5ZM19.5 8H4.5V6.5C4.5 6.2 4.7 6 5 6H19C19.3 6 19.5 6.2 19.5 6.5V8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5676 11.7399L10.8689 18.3453L7.47461 15.0162L8.52492 13.9453L10.7777 16.1547L15.4319 10.7601L16.5676 11.7399Z"
        fill={checkColor}
      />
    </SvgIcon>
  );
}
