import React from 'react';
import { Typography } from '@material-ui/core';
import ActionModal from '../modals/ActionModal';

// TODO: Deprecate ConfirmModal in favor of this
const ConfirmModalComponent = ({ text, ...props }) => {
  return (
    <ActionModal {...props}>
      <Typography variant="body1" component="span">
        {text}
      </Typography>
    </ActionModal>
  );
};

export default ConfirmModalComponent;
