import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import { format } from 'date-fns';
import { surveyBuildingApi } from '~/legacy/fetchApi';
import { addSurveyBuilding } from '~/legacy/store/actions/viewSurvey';
import {
  getSpaceNameByListing,
  getViewBuildingRoute,
  SnackbarUtils,
} from '~/legacy/utils';
import { Typography } from '~/legacy/components/themeComponents';
import { TransparentScrollOverlay } from '~/legacy/components/utilities';
import {
  AddressAutocomplete,
  BuildingTitleCard,
  SquareCheckbox,
} from '~/legacy/components';
import ActionModal from '../modals/ActionModal';

export const CopySpacesModalContent = withStyles({
  selectSurveyTitle: {
    marginTop: '32px',
    marginBottom: '16px',
  },
  scrollableContainer: {
    maxHeight: '341px',
  },
  row: {
    height: '66px',
    width: '100%',
    borderTop: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: '1px solid #E0E0E0',
    },
    flexDirection: 'row',
  },
  rowText: {
    lineHeight: '21px',
  },
  rowUpdatedAt: {
    color: '#666666',
  },
  blackBackground: {
    color: '#111',
  },
})(
  ({
    classes,
    building,
    buildingSurveys,
    surveyIdToCopy,
    setSurveyIdToCopy = () => {},
  }) => {
    return (
      <div>
        <BuildingTitleCard building={building} />
        <Typography className={classes.selectSurveyTitle}>
          Select a survey to import spaces from
        </Typography>
        <TransparentScrollOverlay
          paddingPx="0"
          hideScroll
          gradientHeightPx={60}
          bottomPaddingPx={40}
          classes={{ scrollableContainer: classes.scrollableContainer }}
        >
          {buildingSurveys.map((survey) => (
            <div key={survey.id} className={classes.row}>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '12px',
                  marginRight: '12px',
                }}
              >
                <SquareCheckbox
                  className={classes.blackBackground}
                  checked={surveyIdToCopy === survey.id}
                  onClick={() => setSurveyIdToCopy(survey.id)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  paddingRight: '16px',
                }}
              >
                <Typography variant="boldText" className={classes.rowText}>
                  {survey.name}
                </Typography>
                <Typography variant="textSmall" className={classes.rowText}>
                  {survey.spaces_in_building
                    .map(
                      (space) => getSpaceNameByListing(space) || 'Availability'
                    )
                    .join(', ')}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  noWrap
                  variant="textSmall"
                  className={clsx(classes.rowText, classes.rowUpdatedAt)}
                >
                  {`Updated ${format(
                    Date.parse(survey.updated_at),
                    'MM/dd/yy'
                  )}`}
                </Typography>
              </div>
            </div>
          ))}
        </TransparentScrollOverlay>
      </div>
    );
  }
);

export const AddBuildingToSurveyModal = withStyles({
  autocompleteContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '6px',
  },
})(({ classes, surveyId, open, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedBuilding, setSelectedBuilding] = useState({});
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let selectedBuildingSurveys = [];
  const selectedExistingBuilding = !!(
    selectedBuilding && selectedBuilding.isFuseMatch
  );
  const doneLoadingExistingBuilding = !!(
    selectedExistingBuilding && selectedBuilding.address
  );
  const existingBuildingHasOtherSurveys = !!(
    doneLoadingExistingBuilding &&
    selectedBuilding.surveys_with_spaces &&
    selectedBuilding.surveys_with_spaces.length
  );
  if (existingBuildingHasOtherSurveys) {
    selectedBuildingSurveys = selectedBuilding.surveys_with_spaces.filter(
      (survey) => survey.id !== surveyId
    );
  }
  const existingBuildingHasOtherSurveysSafe = !!(
    selectedBuildingSurveys && selectedBuildingSurveys.length
  );

  const [selectedSurveyId, setSelectedSurveyId] = useState(null);

  const closeModal = () => {
    onClose();
  };

  const resetModal = () => {
    setSelectedBuilding({});
    setSelectedSurveyId(null);
  };

  const validate = useCallback(() => {
    if (!selectedBuilding || !selectedBuilding.address) {
      const newError = 'Please enter an address';
      return newError;
    }
    return '';
  }, [selectedBuilding]);

  useEffect(() => {
    if (errorText) {
      const newError = validate();
      if (!newError) {
        setErrorText('');
      } else if (newError !== errorText) {
        setErrorText(newError);
      }
    }
  }, [errorText, selectedBuilding, validate]);

  // When we click on and load the building, it's ready to be created, so stop loading
  useEffect(() => {
    if (selectedBuilding && selectedBuilding.address) {
      setIsLoading(false);
    }
  }, [selectedBuilding]);

  return (
    <ActionModal
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onAfterClose={resetModal}
      onConfirm={() => {
        const error = validate();
        if (error) {
          setErrorText(error);
        } else {
          setIsLoading(true);

          surveyBuildingApi
            .create({
              surveyId,
              building: selectedBuilding,
              serializerKey: 'sdp',
              copySpacesFromSurveyId: selectedSurveyId,
            })
            .then(([, responseData]) => {
              if (responseData.error_code) {
                // The survey has already been added
                if (
                  responseData.error_code ===
                  surveyBuildingApi.errorCodes.SURVEY_BUILDING_ALREADY_EXISTS
                ) {
                  SnackbarUtils.success('Building already added to survey.');
                  setIsLoading(false);
                  closeModal();
                } else {
                  setIsLoading(false);
                  SnackbarUtils.error('Error adding building to survey.');
                }
              } else {
                dispatch(addSurveyBuilding(responseData.data));
                SnackbarUtils.success('Sucessfully added building to Survey');
                history.push(
                  getViewBuildingRoute(surveyId, responseData.data.building.id)
                );
              }
            })
            .catch(() => {
              SnackbarUtils.error('Error adding building to survey.');
              setIsLoading(false);
            });
        }
      }}
      title={
        selectedExistingBuilding ? 'Add Existing Building' : 'Add Building'
      }
      confirmButtonLabel="Add to Survey"
      disableAction={!!(errorText || isLoading)}
      loading={isLoading}
    >
      {!!(
        selectedExistingBuilding &&
        doneLoadingExistingBuilding &&
        existingBuildingHasOtherSurveysSafe
      ) && (
        <CopySpacesModalContent
          building={selectedBuilding}
          buildingSurveys={selectedBuildingSurveys}
          surveyIdToCopy={selectedSurveyId}
          setSurveyIdToCopy={setSelectedSurveyId}
        />
      )}
      {!!(
        !selectedExistingBuilding ||
        (selectedExistingBuilding &&
          doneLoadingExistingBuilding &&
          !existingBuildingHasOtherSurveysSafe)
      ) && (
        <div className={classes.autocompleteContainer}>
          <AddressAutocomplete
            error={!!errorText}
            helperText={errorText}
            label="Address"
            showExistingBuildings
            excludeExistingSurveyBuildings
            autoFocus
            surveyId={surveyId}
            fetchFreshBuildingData
            preOnChange={(event, newValue) => {
              if (newValue) {
                setIsLoading(true);
              } else {
                setIsLoading(false);
              }
            }}
            setAddressValues={(newAddressValues) => {
              return setSelectedBuilding({
                ...selectedBuilding,
                ...newAddressValues,
              });
            }}
            setBuildingValues={(newBuildingValues) => {
              return setSelectedBuilding({
                ...selectedBuilding,
                ...newBuildingValues,
              });
            }}
            overrideBuildingValues={(...p) => {
              return setSelectedBuilding(...p);
            }}
            controlledValue={selectedBuilding}
          />
        </div>
      )}
    </ActionModal>
  );
});

export const useAddBuildingToSurveyModal = ({
  surveyId,
  onClose = () => {},
}) => {
  const [open, setOpen] = useState(false);

  const AddBuildingToSurveyModalComponent = (
    <AddBuildingToSurveyModal
      open={!!open}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      surveyId={surveyId}
    />
  );

  return {
    setOpen,
    AddBuildingToSurveyModalComponent,
  };
};
