import Api from 'rest-fetcher-redux';
import { actionUserLogout } from '~/legacy/store';
import { toast } from '~/legacy/utils/notifications';
import { LEASE_LOGIN } from '~/legacy/consts';

/**
 * Allows us to opt out of default error middleware handling for API requests.
 * Add to `skipGeneralErrorToast` to prevent general request failures from generating a toast
 * Add to `skipPermissionErrorToast` to prevent 403 request failures from generating a toast.
 * If you opt out of either, you should provide your own toast/handling in the
 *   `onError` API request attribute.
 */
const makeEndpointAction = (endpoints) =>
  endpoints.map((endpoint) => `${Api.basePrefix}${endpoint}_fail`);
const skipGeneralErrorToast = new Set(
  makeEndpointAction([
    'updateBuilding',
    'updateListing',
    'updateProject',
    'redeemMagicLinkToken',
    'getMagicLinkTokenByToken',
    'updateBuildingCustomField',
    'updateListingCustomField',
    'heartbeat',
    'saveAccountSettings',
    'updateUserPassword',
  ])
);
const skipPermissionErrorToast = new Set(
  makeEndpointAction([
    'getMagicLinkTokenByToken',
    'saveAccountSettings',
    'updateUserPassword',
  ])
);
// this redirects anonymous enabled pages to the login screen on 401 errors
const redirectToLoginOn401 = new Set(
  makeEndpointAction(['getProject', 'getSurveyListings'])
);

/**
 * Handle default API request errors.
 * API requests can be opted out of treatment by setting fields on `getErrorOptedRequests`
 */
const errorMiddleware = (store) => (next) => (action) => {
  // If the request failed, we are going to show a snackbar, just a matter of which one
  if (
    action &&
    action.type &&
    action.type.endsWith('_fail') &&
    action.type !== `${Api.basePrefix}login_fail`
  ) {
    // If the user's token isn't valid, log them out.
    if (
      action.payload.error &&
      action.payload.error.code === 'token_not_valid'
    ) {
      store.dispatch(actionUserLogout(true));
    }
    // Default error messaging
    let message = 'Something went wrong!';
    let title = 'Error';
    let appearance = 'danger';

    const hasErrorMesage = action.payload && action.payload.msg;
    const errorStatus = action.payload.msg.status;

    if (hasErrorMesage && errorStatus === 401) {
      // If the error is a 401, the user is unauthorized and needs to log back in.
      if (redirectToLoginOn401.has(action.type)) {
        // TODO: redirect and toast don't work well, history push could be better here.
        // TODO: Remove when redirect logic implemented on individual pages. This redirect happens twice on SDP.
        // User an existing redirect param if one exists
        const queryParams = new URLSearchParams(location.search);
        let redirect = '';
        const existingRedirect = queryParams.get('redirect');
        if (existingRedirect) {
          redirect = existingRedirect;
        } else {
          redirect = `${encodeURIComponent(
            location.pathname
          )}${encodeURIComponent(location.search || '')}`;
        }
        window.location.replace(`${LEASE_LOGIN}?redirect=${redirect}`);
      }
      message = 'Your session has expired, please log in again.';
      appearance = 'info';
      toast(message, { appearance, title });
    } else if (hasErrorMesage && errorStatus === 403) {
      if (!skipPermissionErrorToast.has(action.type)) {
        // If the error is a 403, the user doesn't have perms. Show the message unless API request has
        //   opted out of this treatment
        message = 'You do not have permission to perform this action.';
        toast(message, { appearance, title });
      }
    } else if (!skipGeneralErrorToast.has(action.type)) {
      // For other errors, show a snackbar if the API request hasn't been opted out of this treatment

      // Check for our custom api exception thrown, otherwise use general message
      if (action.payload.error && action.payload.error.api_error) {
        message = action.payload.error.api_error.user_message || message;
        title = action.payload.error.api_error.user_message_title || title;
      }
      toast(message, { appearance, title });
    }
  }

  next(action);
};

export default errorMiddleware;
