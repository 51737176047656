import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  getBuildingTertiaryName,
  getBuildingSecondaryName,
  getBuildingPrimaryName,
} from '~/legacy/utils';
import { SimpleThumbnailImage } from '~/legacy/components';
import { Typography } from '~/legacy/components/themeComponents';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#f9f9f9',
    borderBottom: '1px solid #e0e0e0',
    padding: '24px 12px',
    margin: '24px',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  address: {
    marginBottom: '4px',
    fontSize: '24px',
    lineHeight: '28px',
  },
  addressSecondary: {
    fontSize: '10px',
    lineHeight: '16px',
  },
  imageContainer: {
    height: '46px',
    width: '46px',
    marginRight: '12px',
  },
  heroImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
});

const AddressHeader = ({
  primaryName,
  secondaryName,
  tertiaryName,
  heroPhoto,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {heroPhoto && (
        <div className={classes.imageContainer}>
          <SimpleThumbnailImage
            imageFile={heroPhoto}
            ImgProps={{ className: classes.heroImage }}
          />
        </div>
      )}
      <div>
        <Typography className={classes.address} variant="h1">
          {primaryName}
        </Typography>
        <Typography className={classes.addressSecondary}>
          {`${secondaryName ? `${secondaryName} ` : ''}${tertiaryName}`}
        </Typography>
      </div>
    </div>
  );
};

export function ListingHeader({ listing, index }) {
  const { building } = listing;
  const listingPrimaryName = getBuildingPrimaryName(building);
  const listingSecondaryName = getBuildingSecondaryName(building);
  const buildingTertiaryName = getBuildingTertiaryName(building);

  return (
    <AddressHeader
      primaryName={
        listing.address2 ? listing.address2 : `Availability ${index + 1}`
      }
      tertiaryName={[
        listingPrimaryName,
        listingSecondaryName,
        buildingTertiaryName,
      ].join(', ')}
      heroPhoto={building.hero_photo}
    />
  );
}

export function BuildingHeader({ building, showImage }) {
  const listingPrimaryName = getBuildingPrimaryName(building);
  const listingSecondaryName = getBuildingSecondaryName(building);
  const buildingTertiaryName = getBuildingTertiaryName(building);

  return (
    <AddressHeader
      primaryName={listingPrimaryName}
      secondaryName={listingSecondaryName}
      tertiaryName={buildingTertiaryName}
      heroPhoto={showImage ? building.hero_photo : null}
    />
  );
}
