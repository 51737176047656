import { chunk, groupBy } from 'lodash'
import { defineTemplate } from '~/pdfs/support/defineTemplate'
import { conjunction } from '~/support/conjunction'
import { count } from '~/support/count'
import { findFieldAndFormat } from '~/support/findFieldAndFormat'
import { CalgaryBuilding } from './pages/CalgaryBuilding'
import { CalgaryCover } from './pages/CalgaryCover'
import { CalgaryMap } from './pages/CalgaryMap'
import { CalgarySpace } from './pages/CalgarySpace'
import { Area } from './CalgaryFields'

export const Calgary = defineTemplate({
  id: 'e48de022-5c99-4355-a659-88bb0a90bf39',

  name: 'Calgary',

  height: 1700,

  width: 2200,

  pages: [CalgaryCover, CalgaryMap, CalgaryBuilding, CalgarySpace],

  stats: ({ data, pages }) => {
    const areas = groupBy(data.buildings, (building) => {
      return findFieldAndFormat(building.fields, Area)
    })

    return conjunction([
      count('Area', Object.keys(areas).length),
      count('Building', data.buildings.length),
      count('Space', data.spaces.length),
      count('Page', pages.length),
    ])
  },

  build: (pdf) => {
    const areas = Object.entries(
      groupBy(pdf.data.buildings, (building) =>
        findFieldAndFormat(building.fields, Area)
      )
    ).map(([name, buildings]) => ({
      id: name,
      name,
      buildings: buildings.map((building, index) => {
        // super dumb hack to get the building order right
        building.order = index
        return building
      }),
    }))

    return [
      {
        id: CalgaryCover.id,
        props: CalgaryCover.props(pdf, areas),
        template: CalgaryCover,
      },
      ...areas.flatMap((area) => [
        ...chunk(area.buildings, 20).map((buildings, chunk) => ({
          id: `${CalgaryMap.id}.${area.id}.${chunk}`,
          props: CalgaryMap.props(pdf, { name: area.name, buildings }),
          template: CalgaryMap,
        })),
        ...area.buildings.flatMap((building) => [
          {
            id: `${CalgaryBuilding.id}.${building.id}`,
            props: CalgaryBuilding.props(pdf, building),
            template: CalgaryBuilding,
          },
          ...building.spaces.map((space) => ({
            id: `${CalgarySpace.id}.${space.id}`,
            props: CalgarySpace.props(pdf, space),
            template: CalgarySpace,
          })),
        ]),
      ]),
    ]
  },

  settings: {
    state: {
      //
    },
  },
})
