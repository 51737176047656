import { useMemo } from 'react'
import useSWR from 'swr'
import { surveyBuildingCustomFieldApi } from '~/legacy/fetchApi/surveyBuildingCustomField'
import { defaultMutateBinder, defaultMutateOptimisticBinder } from './selectors'

const DEFAULT_API_ERROR_MESSAGE =
  'Error updating custom field value, please try again.'

export const useSurveyBuildingCustomFieldSelector = ({
  surveyId,
  buildingId = undefined,
  enabled = true,
}) => {
  const { data, error, mutate } = useSWR(
    enabled && surveyId
      ? `surveys/${surveyId}/buildings/${buildingId}/building_custom_fields`
      : null,
    async () =>
      surveyBuildingCustomFieldApi
        .list({ surveyId, buildingId })
        .then(([, response]) => {
          return response
        })
  )

  const { customFieldValues } = useMemo(() => {
    const customFieldValues = data || []

    return {
      customFieldValues,
    }
  }, [data])

  // Bind our mutators with our default settings and error handling
  const mutateSurveyBuildingCustomFields = defaultMutateBinder(
    mutate,
    DEFAULT_API_ERROR_MESSAGE
  )

  const mutateSurveyBuildingCustomFieldsOptimistic =
    defaultMutateOptimisticBinder(
      mutateSurveyBuildingCustomFields,
      DEFAULT_API_ERROR_MESSAGE
    )

  const mutateCreateBuildingCustomFieldValue = async ({
    surveyId,
    buildingId,
    customFieldId,
    newValue,
    optimistic = true,
  }) => {
    const apiMutator = async () =>
      surveyBuildingCustomFieldApi
        .createFieldValue({
          surveyId,
          buildingId,
          customFieldId,
          value: newValue,
        })
        .then(([, response]) => {
          return [...data, response]
        })

    if (optimistic) {
      return mutateSurveyBuildingCustomFieldsOptimistic({
        newObject: [
          ...data,
          {
            survey_id: surveyId,
            building_id: buildingId,
            value: newValue,
            custom_field: { id: customFieldId },
          },
        ],
        mutator: apiMutator,
      })
    }

    return mutateSurveyBuildingCustomFields({ mutator: apiMutator })
  }

  const mutateChangeBuildingCustomFieldValue = async ({
    id,
    newValue,
    optimistic = true,
  }) => {
    const apiMutator = async () =>
      surveyBuildingCustomFieldApi
        .updateFieldValue({
          surveyBuildingCustomFieldId: id,
          value: newValue,
        })
        .then(([, response]) => {
          const index = data.findIndex((item) => item.id === response.id)

          return [...data.slice(0, index), response, ...data.slice(index + 1)]
        })

    if (optimistic) {
      const index = data.findIndex((item) => item.id === id)
      const element = data[index]

      const newData = [
        ...data.slice(0, index),
        { ...element, value: newValue },
        ...data.slice(index + 1),
      ]

      return mutateSurveyBuildingCustomFieldsOptimistic({
        newObject: newData,
        mutator: apiMutator,
      })
    }

    return mutateSurveyBuildingCustomFields({ mutator: apiMutator })
  }

  return {
    customFieldValues,
    error,
    isLoading: !data,
    mutateChangeBuildingCustomFieldValue,
    mutateCreateBuildingCustomFieldValue,
  }
}
