import * as Sentry from '@sentry/browser'
import throttle from 'lodash/throttle'
import { useMemo } from 'react'
import { usePromise } from './hooks/usePromise'
import { useThrottledValue } from './hooks/useThrottledValue'

const autocompleteService = new google.maps.places.AutocompleteService()

const PLACES_THROTTLE_MS = 350

const fetchPlacesResults = throttle((request, callback) => {
  return autocompleteService.getPlacePredictions(request, (results, status) => {
    if (!['OK', 'ZERO_RESULTS'].includes(status)) {
      Sentry.captureMessage('Unexpected Places API Status', {
        tags: {
          apiStatus: status,
        },
      })
    }
    callback(results)
  })
}, PLACES_THROTTLE_MS)

export const getPlacePredictions = async (input = '') => {
  return new Promise((resolve, reject) => {
    autocompleteService.getPlacePredictions({ input }, (results, status) => {
      if (results) {
        resolve(results)
      } else {
        Sentry.captureMessage('Unexpected Places API Status', {
          tags: { apiStatus: status },
        })

        reject(status)
      }
    })
  })
}

export const usePlacePredictions = (value = '', predictions = []) => {
  const input = useThrottledValue(value)

  const request = useMemo(async () => {
    try {
      if (!input) return []
      return getPlacePredictions(input)
    } catch {
      return []
    }
  }, [input])

  const [results] = usePromise(request, [])

  return value ? [...predictions, ...results] : []
}

// Get the string that we will use for the google places api call
const getSearchAddress = (building) => {
  return `${building.address}, ${building.city}, ${building.state} ${building.zipcode}`
}

const getSearchAddressForDisplay = (building) => {
  const buildlingParts = []
  if (building.address) buildlingParts.push(building.address)
  if (building.city) buildlingParts.push(building.city)
  if (building.state) buildlingParts.push(building.state)
  if (building.zipcode) buildlingParts.push(building.zipcode)
  return buildlingParts.join(', ')
}

export {
  fetchPlacesResults,
  getSearchAddress,
  getSearchAddressForDisplay,
  PLACES_THROTTLE_MS,
}
