import { Dialog, DialogContent, Grow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Button, CloseIcon, SquareIconButton } from '~/legacy/components'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
  },
  title: {
    display: 'flex',
    flex: 'none',
    justifyContent: 'space-between',
    marginTop: '4px',
  },
  close: {
    padding: 0,
    color: '#111111',
  },
  actions: {
    display: 'flex',
    flex: 'none',
    gap: '12px',
    justifyContent: 'flex-end',
    marginBottom: '16px',
  },
  actionButton: {
    textTransform: 'none',
  },
})

export const ActionModal2 = (props) => {
  const classes = useStyles()

  const cancel = () => {
    props.onClose?.()
  }

  const confirm = () => {
    props.onAction?.()
  }

  return (
    <Dialog
      id={props.id}
      maxWidth={false}
      open={!!props.open}
      onClose={cancel}
      TransitionComponent={Grow}
    >
      <DialogContent className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h2">{props.title}</Typography>
          <SquareIconButton
            aria-label="close"
            className={classes.close}
            onClick={cancel}
          >
            <CloseIcon />
          </SquareIconButton>
        </div>

        {props.children}

        <div className={classes.actions}>
          <Button color="secondary" onClick={cancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.actionButton}
            disabled={!!props.disabled || !!props.loading}
            loading={!!props.loading}
            onClick={confirm}
          >
            {props.action}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
