import { createIndexedDB, createMany, getWhereEqual } from './indexeddb'

export const createEventStore = (connection) => {
  const subscribers = new Set()

  const eventStore = {
    read: async (streamId) => {
      return connection.read(streamId)
    },

    write: async (...args) => {
      const createdAt = new Date()
      const events = args.flat().map((event) => ({ ...event, createdAt }))
      await connection.write(events)
      events.forEach(notify)
    },

    subscribe: (notify) => {
      subscribers.add(notify)
      return () => subscribers.delete(notify)
    },
  }

  const notify = (event) => {
    subscribers.forEach((notify) => notify(event))
  }

  return eventStore
}

export const createInMemoryConnection = (history = []) => {
  const connection = {
    read: async (streamId) => {
      return history.filter((event) => event.streamId === streamId)
    },

    write: async (events) => {
      history = [...history, ...events]
    },
  }

  return connection
}

export const createIndexedDBConnection = (name) => {
  const connection = {
    read: async (streamId) => {
      const db = await createEventStoreDB(name)
      return getWhereEqual(db, 'events', 'streamId', streamId)
    },

    write: async (events) => {
      const db = await createEventStoreDB(name)
      await createMany(db, 'events', events)
    },
  }

  return connection
}

const createEventStoreDB = async (name) => {
  return createIndexedDB(name, [
    (db) => {
      const store = db.createObjectStore('events', {
        keyPath: 'id',
        autoIncrement: true,
      })

      store.createIndex('streamId', 'streamId', {
        unique: false,
      })
    },
  ])
}
