import React from 'react';
import { Avatar, Skeleton, Typography } from '~/legacy/components';
import { styled } from '~/legacy/utils/styleHelpers';

export const Profile = ({ image, initials, name, company, ...props }) => (
  <Container {...props}>
    <Image image={image} text={initials} />
    <div>
      <Name>{name}</Name>
      <Typography variant="subSectionTitle">{company}</Typography>
    </div>
  </Container>
);

export const ProfileSkeleton = () => (
  <Container>
    <Skeleton circle={54} />
    <div>
      <Skeleton width={200} height={20} top={6} rounded={4} />
      <Skeleton width={150} height={14} top={10} rounded={4} />
    </div>
  </Container>
);

const Container = styled('div', {
  display: 'flex',
  gap: '16px',
});

const Image = styled(Avatar, {
  height: 54,
  width: 54,
});

const Name = styled(Typography, {
  lineHeight: '32px',
  fontWeight: 600,
  fontSize: 24,
  color: '#111111',
});
