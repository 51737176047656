import { IconButton } from '@material-ui/core'
import React, { Suspense, useState } from 'react'
import { useHistory } from 'react-router'
import {
  above,
  below,
  between,
  styled,
  unless,
} from '~/legacy/utils/styleHelpers'
import { Scroll, Section, ShowActivityIcon } from '~/legacy/components'
import { DocumentListSkeleton } from './components/RecentList'
import { Profile } from './components/Profile'
import { ProjectListSkeleton } from './components/ProjectList'
import { useUserProfile } from './hooks/useUserProfile'
import { Activity } from './views/Activity'
import { Recents } from './views/Recents'
import { Projects } from './views/Projects'
import { CreateProject } from './CreateProject'

export default function BrokerHome() {
  const [create, setCreate] = useState(false)
  const [open, setOpen] = useState(false)
  const profile = useUserProfile()
  const history = useHistory()

  const handleDocumentSelect = (doc) => {
    alert(`Open [${doc.id}] document.`)
  }

  const handleOpen = () => {
    setOpen((value) => !value)
  }

  const handleProjectCreate = () => {
    setCreate(true)
  }

  const handleProjectSelect = () => {
    history.push('/preview/projects/0')
  }

  const handleCancelProjectCreate = () => {
    setCreate(false)
  }

  return (
    <Container>
      <Scroll>
        <Content>
          <Section>
            <Profile {...profile} />

            <Toggle onClick={handleOpen}>
              <ShowActivityIcon />
            </Toggle>
          </Section>

          <Section top={56}>
            <Suspense fallback={<DocumentListSkeleton />}>
              <Recents onSelect={handleDocumentSelect} />
            </Suspense>
          </Section>

          <Section top={32} divider />

          <Section top={16}>
            <Suspense fallback={<ProjectListSkeleton />}>
              <Projects
                onAction={handleProjectCreate}
                onSelect={handleProjectSelect}
              />
            </Suspense>
          </Section>
        </Content>
      </Scroll>

      <Sidebar open={open}>
        <Activity />
      </Sidebar>

      {create && <CreateProject onCancel={handleCancelProjectCreate} />}
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
  width: '100%',
})

const Content = styled('div', {
  marginBottom: 62,
  marginRight: 'auto',
  marginLeft: 'auto',
  marginTop: 72,
  position: 'relative',
  flex: 1,

  [below(1150)]: {
    paddingRight: 32,
    paddingLeft: 32,
  },

  [between(1151, 1367)]: {
    paddingRight: 64,
    paddingLeft: 64,
  },

  [between(1368, 1440)]: {
    maxWidth: 760,
  },

  [above(1441)]: {
    paddingRight: 100,
    paddingLeft: 100,
  },
})

const Sidebar = styled('div', ['open'], {
  backgroundColor: 'white',
  borderLeft: '1px solid #E0E0E0',
  transition: 'transform 300ms ease-out',
  width: 480,

  [below(1100)]: {
    transform: unless('open', 'translateX(100%)'),
    position: 'fixed',
    bottom: 0,
    right: 0,
    top: 60,
  },
})

const Toggle = styled(IconButton, {
  position: 'absolute',
  right: 30,
  top: -17,

  [above(1101)]: {
    display: 'none',
  },
})

Toggle.defaultProps = {
  size: 'small',
}
