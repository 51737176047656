import React from 'react'
import { Settings } from '~/pdfs/patterns/Settings'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { image } from '~/pdfs/support/image'
import { LeaseUpPage } from '../patterns/LeaseUpPage'
import { styled, select } from '~/libraries/styled'
import { LeaseUpHeader } from '../patterns/LeaseUpHeader'
import { buildSpaceName } from '../support/buildSpaceName'
import { buildAddress } from '../support/buildAddress'
import { thumbnail } from '~/pdfs/support/thumbnail'
import { Photo } from '~/pdfs/patterns/Photo'
import { useSend } from '~/support/useSend'
import { useRangeField } from '~/support/useRangeField'
import { Group } from '~/pdfs/patterns/Group'
import { Text } from '~/pdfs/patterns/Text'
import { CheckboxSetting } from '~/pdfs/patterns/CheckboxSetting'

export const LeaseUpPhotos = definePageTemplate({
  id: '87a5a748-e5df-4c98-8e91-68f1b1bb09a7',

  name: 'Space Photos',

  props: (pdf, space) => {
    const photosPerPage = pdf.settings.photosPerPage ?? 8

    return {
      photo: thumbnail(space.building.images[0]),
      name: buildSpaceName(space),
      address: buildAddress(space.building.location),
      photos: space.images
        .map((data) => image(data, { width: 612 * 2 }))
        .filter(Boolean)
        .slice(0, photosPerPage),
      limit: photosPerPage,
      fit: pdf.settings.fitPhotos,
    }
  },

  Page: (props) => {
    return (
      <Page>
        <LeaseUpHeader
          photo={props.photo}
          title={props.name}
          subtitle={props.address}
        />
        <Grid columns={props.photos.length}>
          {props.photos.map((photo) => (
            <Photo
              key={photo}
              src={photo}
              aspect={3 / 4}
              fit={props.fit ? 'contain' : 'cover'}
            />
          ))}
        </Grid>
      </Page>
    )
  },

  Settings: (props) => {
    const send = useSend()

    const limit = useRangeField(props.limit, 1, 8, (value) => {
      send('AddSettings', { photosPerPage: value })
    })

    return (
      <Settings>
        <Group label="Maximum Photos">
          <Text {...limit} />
        </Group>

        <Group label="Layout">
          <CheckboxSetting
            id="fitPhotos"
            label="Fit Photos"
            value={props.fit}
          />
        </Group>
      </Settings>
    )
  },
})

const Page = styled(LeaseUpPage, {
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
})

const Grid = styled('div', ['columns'], {
  gridTemplateColumns: select('columns', {
    default: '200px 200px',
    1: '100%',
    2: '404px',
  }),
  justifyContent: 'center',
  alignContent: 'center',
  display: 'grid',
  flex: 1,
  gap: '4px',
})
