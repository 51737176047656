import React from 'react'
import { styled } from '~/legacy/utils/styleHelpers'

export const ActivityCard = (props) => {
  const { children, image } = props
  return (
    <Container>
      {image && <Image src={image} />}
      <Content>{children}</Content>
    </Container>
  )
}

const Container = styled('div', {
  borderRadius: '4px',
  outline: '1px solid #e0e0e0',
  outlineOffset: '-1px',
  overflow: 'hidden',
  display: 'flex',
})

const Content = styled('div', {
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

const Image = styled('img', {
  width: '106px',
})
