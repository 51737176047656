export default {
  createSurveyListingComment: {
    url: '/survey_listing_comments/',
    options: {
      method: 'POST',
    },
  },
  deleteSurveyListingComment: {
    url: '/survey_listing_comments/:id/',
    options: {
      method: 'DELETE',
    },
  },
};
