import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PublicHeader } from '~/legacy/components';
import { LoginModal } from '~/legacy/components/modals';

const useStyles = makeStyles({
  loginContainer: {
    position: 'absolute',
    top: '25%',
    padding: '40px 60px',
  },
});

export function LoginContent() {
  const classes = useStyles();

  return (
    <Paper className={classes.loginContainer} elevation={0}>
      <LoginModal allowClose={false} />
    </Paper>
  );
}

function Login() {
  return <PublicHeader contentComponent={<LoginContent />} />;
}

export default Login;
