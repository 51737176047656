import { useSnackbar } from 'notistack';
import React from 'react';
import Snackbar from '~/legacy/components/Snackbar';

// Fancy ref stuff so that we can reference snackbar component outside of a component
const InnerSnackbarUtilsConfigurator = ({ setUseSnackbarRef }) => {
  setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

// Util functions for submitting a snackbar
export const SnackbarUtils = {
  success(msg, snackbarProps = {}) {
    this.toast(msg, 'success', { preventDuplicate: false, ...snackbarProps });
  },
  warning(msg, snackbarProps = {}) {
    this.toast(msg, 'warning', snackbarProps);
  },
  info(msg, snackbarProps = {}) {
    this.toast(msg, 'info', snackbarProps);
  },
  error(msg, snackbarProps = {}) {
    this.toast(msg, 'error', { autoHideDuration: 5000, ...snackbarProps });
  },
  toast(msg, variant = 'default', snackbarProps = {}) {
    useSnackbarRef.enqueueSnackbar(msg, {
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      preventDuplicate: true,
      content: () => <Snackbar msg={msg} variant={variant} />,
      ...snackbarProps,
    });
  },
};
