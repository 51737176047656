import merge from 'lodash/merge';
import { createData, valueOf } from '~/legacy/utils';

export const createParser = (config) => (data) => {
  const defaults = valueOf(config.defaults, data);
  const value = merge(defaults, data);
  const result = config.parse(createData(value));
  return result === undefined ? value : result;
};

export const createSerializer = (config) => {
  return {
    ...config,
    decode: createParser({
      defaults: config.defaults,
      parse: config.decode,
    }),
  };
};

export const parseType = (type) => (data) => {
  return { ...data, type };
};
