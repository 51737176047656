import React, { memo, useState } from 'react';
import Api from 'rest-fetcher-redux';
import { Icon, InputAdornment, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BULK_IMPORT_CONSTANTS } from '~/legacy/utils';
import { ClearTextInputSvg, TextInput } from '~/legacy/components';

const useStyles = makeStyles({
  cancel: {
    marginRight: 4,
  },
  clearInputIcon: {
    height: '16px',
    cursor: 'pointer',
  },
  input: {
    marginRight: 4,
  },
  label: {
    marginRight: 4,
  },
  name: {
    textAlign: 'right',
    minWidth: 140,
    marginRight: 10,
  },
  root: {
    display: 'flex',
    paddingBottom: 8,
    alignItems: 'center',
    height: '44px',
  },
  trashIcon: {
    cursor: 'pointer',
    fontSize: '16px',
    marginLeft: '12px',
  },
  trashIconContainer: {
    display: 'flex',
  },
  units: {
    minWidth: 'fit-content',
    marginLeft: 4,
  },
  valueInput: {
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '5px 10px',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '5px 10px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0',
    },
  },
});

function TextInputForEdit({
  valuesKey,
  values,
  setValues,
  onCancel,
  onSubmit,
}) {
  const classes = useStyles();

  return (
    <TextInput
      autoFocus
      className={classes.valueInput}
      fullWidth={false}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ClearTextInputSvg
              className={classes.clearInputIcon}
              onClick={() =>
                setValues({
                  ...values,
                  [valuesKey]: '',
                })
              }
              onMouseDown={(event) => event.preventDefault()}
            />
          </InputAdornment>
        ),
      }}
      margin="none"
      onBlur={() => {}}
      onChange={(event) =>
        setValues({
          ...values,
          [valuesKey]: event.target.value,
        })
      }
      onKeyDown={(event) => {
        if (event.key === 'Tab') {
          if (values[valuesKey]) {
            onSubmit();
          } else {
            setValues({
              ...values,
              [valuesKey]: '',
            });
            onCancel();
          }
          event.preventDefault();
        }
      }}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          if (values[valuesKey]) {
            onSubmit();
          } else {
            setValues({
              ...values,
              [valuesKey]: '',
            });
            onCancel();
          }
        } else if (event.key === 'Escape') {
          setValues({
            ...values,
            [valuesKey]: '',
          });
          onCancel();
        }
      }}
      placeholder={`Enter ${valuesKey}`}
      size="small"
      value={values[valuesKey]}
    />
  );
}

function Create({ objectId, onCancel, onSubmit = () => {}, type }) {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    value: '',
    data_type: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id,
  });
  const [creatingValue, setCreatingValue] = useState(false);

  let endpoint = '';
  if (type === 'building') {
    endpoint = 'addBuildingCustomField';
  } else if (type === 'listing') {
    endpoint = 'addListingCustomField';
  }

  const submit = () => {
    if (values.name && values.value) {
      Api[endpoint]({
        id: objectId,
        body: {
          ...values,
        },
      }).then((data) => onSubmit(data.data));
    }
  };

  return (
    <div className={classes.root}>
      {!creatingValue && (
        <TextInputForEdit
          onCancel={onCancel}
          onSubmit={() => setCreatingValue(true)}
          setValues={setValues}
          values={values}
          valuesKey="name"
        />
      )}
      {creatingValue && (
        <>
          <Typography className={classes.label} variant="h3">
            {`${values.name}:`}
          </Typography>
          <TextInputForEdit
            onCancel={onCancel}
            onSubmit={submit}
            setValues={setValues}
            values={values}
            valuesKey="value"
          />
        </>
      )}
      <div className={classes.trashIconContainer} onClick={onCancel}>
        <Icon
          className={`fal fa-trash-alt ${classes.cancel} ${classes.trashIcon}`}
        />
      </div>
    </div>
  );
}

export default memo(Create);
