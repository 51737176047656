import Api from 'rest-fetcher-redux'
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import { configureStore } from '~/legacy/store'
import { getTokensFromLocalStorage } from '~/legacy/utils/localStorageHelper'

import apiCalls from '~/legacy/api'

let store = null

export function getStore() {
  return store
}

export function setupAuthorizationHeaders() {
  const tokens = getTokensFromLocalStorage()
  if (tokens && tokens.access) {
    Api.setBaseOptions({
      headers: {
        Authorization: `Bearer ${tokens.access}`,
        'Content-Type': 'application/json',
      },
    })
    Api.init()
  } else {
    Api.setBaseOptions({
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true
  }
  return false
}

export const BACKEND_FOR_DND = isTouchDevice() ? TouchBackend : HTML5Backend

export default function init() {
  if (!store) {
    Api.setBaseUrl(process.env.APP_API_URL)
    Api.setEndpoints(apiCalls)
    setupAuthorizationHeaders()
    store = configureStore()
    Api.setDispatcher(store.dispatch)
    Api.setGetState(store.getState)
    Api.resetAction = 'LOGOUT'
  }

  return { store }
}
