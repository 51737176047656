import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Api from 'rest-fetcher-redux';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import { setSurveyListingsAndBuildings } from '~/legacy/store/actions/viewSurvey';
import { Button, ImageDropzone, File } from '~/legacy/components';
import { uploadFile, SnackbarUtils } from '~/legacy/utils';
import ModalTitle from './ModalTitle';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '570px',
    height: 'fit-content',
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '24px',
    marginBottom: '16px',
    justifyContent: 'end',
  },
  cancelButton: {
    marginRight: '10px',
  },
  button: {
    minWidth: '140px',
  },
  sendButtonWrapper: {
    position: 'relative',
  },
  fileContainer: {
    minHeight: '380px',
    display: 'flex',
  },
  imageDropzone: {
    display: 'flex',
    height: '380px',
    margin: '-24px',
  },
  dropzoneRoot: {
    backgroundColor: '#e0e0e0 !important',
    color: '#666 !important',
  },
  cameraIcon: {
    color: '#666 !important',
  },
});

function UploadBuildingHeroPhotoModal({
  onClose,
  uploadBuildingHeroPhotoModalData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const surveyListings = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  );
  const surveyBuildings = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  );

  const building = uploadBuildingHeroPhotoModalData
    ? uploadBuildingHeroPhotoModalData.building
    : {};

  const [newBuildingHeroImageFile, setNewBuildingHeroImageFile] =
    useState(null);
  const [loading, setLoading] = useState(false);

  // Copy the file and set its url to the local blob so we can show without uploading
  const onFileDrop = (filesIn) => {
    if (filesIn && filesIn.length) {
      const originalFile = filesIn[0];
      const localImageUrl = URL.createObjectURL(filesIn[0]);
      const newFile = new Blob([originalFile], { type: originalFile.type });
      newFile.name = originalFile.name;
      newFile.url = localImageUrl;
      newFile.thumbnail_image_url = localImageUrl;
      newFile.is_uploaded = false;
      newFile.is_uploading = false;
      setNewBuildingHeroImageFile({
        uuid: uuidv4(),
        data: newFile,
      });
    }
  };

  const photoExists = !!(
    newBuildingHeroImageFile && newBuildingHeroImageFile.data
  );

  const showUploadError = () =>
    SnackbarUtils.error('Error uploading image, please try again');

  return (
    <div className={classes.content}>
      <ModalTitle onClose={onClose} title="Add a Building Photo" />

      {photoExists ? (
        <div className={classes.fileContainer}>
          <File
            file={newBuildingHeroImageFile.data}
            missingFileStyleOverrides={{}}
            fileImageContainerStyleOverrides={{}}
            skipUploading
            showCancelButton={false}
            imageStyle={{
              objectFit: 'contain',
              objectPosition: 'top center',
            }}
            borderRadius={0}
          />
        </div>
      ) : (
        <div className={classes.imageDropzone}>
          <ImageDropzone
            onFileDrop={onFileDrop}
            acceptedFileTypes={['image/jpeg', 'image/png', 'image/webp']}
            overrideClasses={{
              dropzoneRoot: classes.dropzoneRoot,
              cameraIcon: classes.cameraIcon,
            }}
            headerText={null}
          />
        </div>
      )}

      <div className={classes.buttonsRow}>
        <Button
          className={`${classes.cancelButton} ${classes.button}`}
          color="primary"
          variant="outlined"
          shrinkButton
          onClick={onClose}
        >
          Cancel
        </Button>
        <div className={classes.sendButtonWrapper}>
          <Button
            className={classes.button}
            color="primary"
            shrinkButton
            disabled={!newBuildingHeroImageFile || loading}
            loading={loading}
            onClick={() => {
              if (newBuildingHeroImageFile) {
                setLoading(true);
                uploadFile(newBuildingHeroImageFile.data, 'building_photos')
                  .then((result) => {
                    if (
                      result &&
                      result.data &&
                      result.data.file &&
                      result.data.file.id
                    ) {
                      // Save the photo to the building via API
                      Api.addBuildingHeroPhoto({
                        id: building.id,
                        body: { photo_id: result.data.file.id },
                      })
                        .then((result2) => {
                          if (result2 && result2.files) {
                            // Update the building state for the sdp
                            dispatch(
                              setSurveyListingsAndBuildings(
                                surveyListings.map((surveyListing) => {
                                  if (
                                    surveyListing.listing.building.id ===
                                    building.id
                                  ) {
                                    return {
                                      ...surveyListing,
                                      listing: {
                                        ...surveyListing.listing,
                                        hero_photo: result2.files[0],
                                        building: {
                                          ...surveyListing.listing.building,
                                          images: result2.files,
                                          hero_photo: result2.files[0],
                                        },
                                      },
                                    };
                                  }
                                  return surveyListing;
                                }),
                                surveyBuildings.map((surveyBuilding) => {
                                  if (
                                    surveyBuilding.building.id === building.id
                                  ) {
                                    return {
                                      ...surveyBuilding,
                                      building: {
                                        ...surveyBuilding.building,
                                        images: result2.files,
                                        hero_photo: result2.files[0],
                                      },
                                    };
                                  }
                                  return surveyBuilding;
                                })
                              )
                            );
                            SnackbarUtils.success(
                              'Image successfully uploaded'
                            );
                            onClose();
                            setLoading(false);
                          } else {
                            showUploadError();
                          }
                        })
                        .catch(() => {
                          showUploadError();
                        });
                    } else {
                      showUploadError();
                    }
                    setLoading(false);
                  })
                  .catch(() => {
                    showUploadError();
                    setLoading(false);
                  });
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UploadBuildingHeroPhotoModal;
