import { sendPageEventToGA } from '~/legacy/utils';

export const ACTIONS = {
  TOGGLE_SIDEBAR: 'toggle_sidebar',
  ADD_AMENITIES: 'add_amenities',
  LOCATION_CHANGED: 'LOCATION_CHANGED',
  VIEW_LISTING_BACK_BUTTON_URL: 'VIEW_LISTING_BACK_BUTTON_URL',
};

const initialState = {
  amenities: [],
  viewListingBackUrl: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTIONS.ADD_AMENITIES:
      return {
        ...state,
        amenities: action.payload,
      };
    case ACTIONS.LOCATION_CHANGED:
      sendPageEventToGA(action.payload.pathname);
      return {
        ...state,
        location: action.payload,
      };
    case ACTIONS.VIEW_LISTING_BACK_BUTTON_URL:
      return {
        ...state,
        viewListingBackUrl: action.payload,
      };
    default:
      return state;
  }
}

export const actionToggleSidebar = () => ({
  type: ACTIONS.TOGGLE_SIDEBAR,
});
export const actionAddAmenities = (payload) => ({
  type: ACTIONS.ADD_AMENITIES,
  payload,
});
export const actionLocationChanged = (payload) => ({
  type: ACTIONS.LOCATION_CHANGED,
  payload,
});

export const actionViewListingBackButton = (payload) => ({
  type: ACTIONS.VIEW_LISTING_BACK_BUTTON_URL,
  payload,
});
