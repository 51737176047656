import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Api from 'rest-fetcher-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { format, parse } from 'date-fns';
import { Chip, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleMap, Marker } from '@react-google-maps/api';
import {
  Amenities,
  BikeScoreBadge,
  CustomField,
  EditableAttribute,
  Modal,
  MODALS,
  TransitScoreBadge,
  WalkScoreBadge,
  ListingActivityFeed,
  Button,
  ActivityProvider,
  Linkify,
  AnonymousActivityCta,
  EditIcon,
} from '~/legacy/components';
import { setSurveyListings } from '~/legacy/store/actions/viewSurvey';
import {
  formatDateAvailable,
  formatMoney,
  formatSizeByListing,
  formatSqftPriceByListing,
  getBuildingTertiaryName,
  getListingPrimaryName,
  getListingSecondaryName,
  getViewListingRoute,
  numberWithCommas,
  isUserTenantOrBrokerPreview,
  BULK_IMPORT_CONSTANTS,
} from '~/legacy/utils';

// eslint-disable-next-line
import { userTypes } from '~/legacy/consts';

const useStyles = makeStyles((theme) => ({
  addCustomField: {
    marginTop: '20px',
    marginLeft: '2px',
  },
  amenities: {
    marginBottom: '40px',
  },
  availabilityTitle: {
    fontSize: '18px',
    paddingTop: (props) => !props.isTenantOrPreview && '30px',
  },
  chip: {
    margin: '0 8px 8px 0',
    ...theme.typography.h3,
  },
  clearInputIcon: {
    height: '16px',
    cursor: 'pointer',
  },
  customField: {
    display: 'flex',
    height: '42px',
  },
  description: {
    cursor: (props) => !props.isTenantOrPreview && 'pointer',
    whiteSpace: 'pre-line',
    '&:hover': {
      backgroundColor: (props) => !props.isTenantOrPreview && '#f9f9f9',
    },
  },
  descriptionPlaceholder: {
    opacity: '.6',
    width: 'fit-content',
  },
  divider: {
    margin: '40px 0',
  },
  editablAmenities: {
    paddingTop: '10px',
  },
  editIcon: {
    fontSize: '22px',
    marginBottom: '2px',
    marginLeft: '12px',
    cursor: 'pointer',
  },
  metadataBlock: {
    '& > *': {
      marginTop: '20px',
    },
  },
  sectionHeader: {
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  siblingListingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
  },
  siblingListing: {
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  siblingListingBorder: {
    borderTop: '1px solid #e0e0e0',
  },
  siblingListingAddListingLink: {
    color: theme.palette.primary.main,
  },
  walkscores: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  activityContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  activityContainerGrid: {
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  activityCutoffContainer: {
    width: '100%',
    height: '120px',
    marginTop: '-120px',
    zIndex: 2,
    position: 'relative',
    display: 'flex',
  },
  activityCutoffButton: {
    zIndex: 3,
    position: 'relative',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
  },
  activityCutoffGradient: {
    width: '100%',
    height: '100%',
    backgroundImage:
      'linear-gradient(to bottom, rgba(255,255,255,0), white 60%)',
    pointerEvents: 'none',
    position: 'absolute',
  },
  enterEmailCta: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    height: '68px',
    width: '100%',
    backgroundColor: '#f9f9f9',
    color: '#666666',
    padding: '24px',
  },
  enterEmailCtaText: {
    marginRight: '4px',
  },
  loginModalContent: {
    padding: '40px 60px !important',
  },
}));

function TitleWithEditIcon({ isTenantOrPreview, onClick, title }) {
  const classes = useStyles();

  return (
    <div className={classes.sectionHeader}>
      <Typography variant="h2">{title}</Typography>
      {!isTenantOrPreview && (
        <EditIcon className={classes.editIcon} onClick={onClick} />
      )}
    </div>
  );
}

function ViewListingBody({
  listing,
  setListing,
  siblingListings,
  fetchSiblingListings,
  requestedSurveyId,
}) {
  const user = useSelector((s) => s.user);
  const isPreviewTenantView = useSelector(
    (state) => state.pages.previewTenantView.isPreviewTenantView
  );
  const isTenantOrPreview =
    isUserTenantOrBrokerPreview(user.userType, isPreviewTenantView) ||
    user.isAnonymous;

  const classes = useStyles({ isTenantOrPreview });
  const dispatch = useDispatch();
  const [showEditListingDetailsModal, setShowEditListingDetailsModal] =
    useState(false);
  const [showEditBuildingDetailsModal, setShowEditBuildingDetailsModal] =
    useState(false);
  const [showAddListingModal, setShowAddListingModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  // TODO talk to squad to clear this up
  const primaryName = getListingPrimaryName(listing);
  const secondaryName = getListingSecondaryName(listing);
  const tertiaryName = getBuildingTertiaryName(listing.building);

  // Enure the activity feed right hand column matches the left listing details column height
  const leftColumn = React.createRef();
  const [leftColumnHeight, setLeftColumnHeight] = useState(1250);
  // As the user presses "show more activity", we increase the multiplier of the hight of the activity feed
  const [rightColumnHeightMult, setRightColumnHeightMult] = useState(1);
  // Whether or not we have more acctivity to show
  const [hasMoreActivity, setHasMoreActivity] = useState(false);
  useEffect(() => {
    const leftColumnHeightValue =
      leftColumn && leftColumn.current ? leftColumn.current.offsetHeight : null;
    if (leftColumnHeightValue) {
      setLeftColumnHeight(leftColumnHeightValue);
    }
  }, [leftColumn]);

  const fetchSurveyListings = () => {
    return Api.getSurveyListings({ id: requestedSurveyId }).then((results) => {
      dispatch(setSurveyListings(results.survey_listings));
    });
  };

  const PLACEHOLDER = null;
  const listingAttributes = [
    // required
    {
      getValue: (_listing) => _listing.vacancy_type || PLACEHOLDER,
      label: 'Vacancy Type',
      type: 'text',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING,
      value_key: 'vacancy_type',
    },
    {
      getDisplayValue: (_listing) =>
        _listing.size ? `${numberWithCommas(_listing.size)} sqft` : PLACEHOLDER,
      getValue: (_listing) => _listing.size,
      label: 'SqFt Available',
      type: 'number',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT,
      value_key: 'size',
    },
    {
      getDisplayValue: (_listing) =>
        _listing.sqft_price ? formatMoney(_listing.sqft_price) : '',
      getValue: (_listing) => _listing.sqft_price,
      label: 'Price / SqFt',
      type: 'number',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD,
      value_key: 'sqft_price',
    },
    {
      getDisplayValue: (_listing) => {
        return formatDateAvailable(_listing);
      },
      getValue: (_listing) => {
        const date = parse(_listing.date_available, 'yyyy-MM-dd', new Date());
        if (date && date instanceof Date && !isNaN(date)) {
          return format(date, 'MM-dd-yyyy');
        }
        return null;
      },
      label: 'Date Available',
      type: 'date',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE,
      value_key: 'date_available',
    },
    // optional
    {
      getValue: (_listing) => _listing.lease_type || PLACEHOLDER,
      label: 'Lease Type',
      type: 'text',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING,
      value_key: 'lease_type',
    },
    {
      getValue: (_listing) => _listing.lease_term || PLACEHOLDER,
      label: 'Lease Term',
      type: 'text',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING,
      value_key: 'lease_term',
    },
  ];

  const buildingAttributes = [
    {
      getValue: (building) => building.property_type || PLACEHOLDER,
      label: 'Property Type',
      type: 'text',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING,
      value_key: 'property_type',
    },
    {
      getValue: (building) => building.floors || PLACEHOLDER,
      label: 'Floors',
      type: 'number',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER,
      value_key: 'floors',
    },
    {
      getDisplayValue: (building) =>
        building.building_size
          ? `${numberWithCommas(building.building_size)} SqFt`
          : PLACEHOLDER,
      getValue: (building) => building.building_size,
      label: 'Building Size',
      type: 'number',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT,
      value_key: 'building_size',
    },
    {
      getValue: (building) => building.parking_ratio || PLACEHOLDER,
      label: 'Parking Ratio',
      type: 'text',
      fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING,
      value_key: 'parking_ratio',
    },
  ];

  return (
    <Grid container spacing={8}>
      <Grid className={classes.metadataBlocksContainer} item xs={12} sm={6}>
        <TitleWithEditIcon
          isTenantOrPreview={isTenantOrPreview}
          onClick={() => setShowEditListingDetailsModal(true)}
          title="Space Details"
        />
        <div ref={leftColumn}>
          <div className={classes.metadataBlock}>
            {(!isTenantOrPreview ||
              (isTenantOrPreview && listing.description)) && (
              <Typography
                className={clsx(
                  classes.description,
                  !listing.description && classes.descriptionPlaceholder
                )}
                onClick={() => {
                  if (isTenantOrPreview) {
                    return;
                  }
                  setShowEditListingDetailsModal(true);
                }}
                variant="body1"
              >
                <Linkify>
                  {listing.description || 'Add a description of the space'}
                </Linkify>
              </Typography>
            )}
            <Grid container>
              {listingAttributes.map((attribute) => {
                if (isTenantOrPreview && !attribute.getValue(listing)) {
                  return null;
                }
                return (
                  <Grid key={attribute.label} item md={6} xs={12}>
                    <EditableAttribute
                      displayValue={
                        attribute.getDisplayValue &&
                        attribute.getDisplayValue(listing)
                      }
                      isTenantOrPreview={isTenantOrPreview}
                      label={attribute.label}
                      options={attribute.options}
                      placeholder={`Add ${attribute.label}`}
                      type={attribute.type}
                      fieldDataType={attribute.fieldDataType}
                      updateRootObject={(newValues) => {
                        return setListing({
                          ...listing,
                          ...newValues,
                        });
                      }}
                      value={attribute.getValue(listing)}
                      value_key={attribute.value_key}
                    />
                  </Grid>
                );
              })}
              {listing.custom_fields.map((customField) => (
                <Grid
                  key={customField.id}
                  className={classes.customField}
                  item
                  md={6}
                  xs={12}
                >
                  <CustomField
                    customField={customField}
                    isTenantOrPreview={isTenantOrPreview}
                    object={listing}
                    setObject={setListing}
                    type="listing"
                  />
                </Grid>
              ))}
              {!isTenantOrPreview && (
                <Grid className={classes.addCustomField} item xs={12}>
                  <CustomField
                    object={listing}
                    setObject={setListing}
                    surveyId={requestedSurveyId}
                    type="listing"
                  />
                </Grid>
              )}
            </Grid>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.metadataBlock}>
            <TitleWithEditIcon
              isTenantOrPreview={isTenantOrPreview}
              onClick={() => setShowEditBuildingDetailsModal(true)}
              title="Building Details"
            />
            {(!isTenantOrPreview ||
              (isTenantOrPreview && listing.building.description)) && (
              <Typography
                className={clsx(
                  classes.description,
                  !listing.building.description &&
                    classes.descriptionPlaceholder
                )}
                onClick={() => {
                  if (isTenantOrPreview) {
                    return;
                  }
                  setShowEditBuildingDetailsModal(true);
                }}
                variant="body1"
              >
                <Linkify>
                  {listing.building.description ||
                    'Add a description of the building'}
                </Linkify>
              </Typography>
            )}
            <Grid container>
              {buildingAttributes.map((attribute) => {
                if (
                  isTenantOrPreview &&
                  !attribute.getValue(listing.building)
                ) {
                  return null;
                }
                return (
                  <Grid key={attribute.label} item md={6} xs={12}>
                    <EditableAttribute
                      displayValue={
                        attribute.getDisplayValue &&
                        attribute.getDisplayValue(listing.building)
                      }
                      isTenantOrPreview={isTenantOrPreview}
                      label={attribute.label}
                      options={attribute.options}
                      placeholder={`Add ${attribute.label}`}
                      type={attribute.type}
                      fieldDataType={attribute.fieldDataType}
                      updateRootObject={(newValues) => {
                        return setListing({
                          ...listing,
                          building: {
                            ...listing.building,
                            ...newValues,
                          },
                        });
                      }}
                      value={attribute.getValue(listing.building)}
                      value_key={attribute.value_key}
                    />
                  </Grid>
                );
              })}
              {listing.building.custom_fields.map((customField) => (
                <Grid
                  key={customField.id}
                  className={classes.customField}
                  item
                  md={6}
                  xs={12}
                >
                  <CustomField
                    customField={customField}
                    isTenantOrPreview={isTenantOrPreview}
                    object={listing.building}
                    setObject={(newBuilding) =>
                      setListing({
                        ...listing,
                        building: {
                          ...newBuilding,
                        },
                      })
                    }
                    type="building"
                  />
                </Grid>
              ))}
              {!isTenantOrPreview && (
                <Grid className={classes.addCustomField} item xs={12}>
                  <CustomField
                    object={listing.building}
                    setObject={(newBuilding) =>
                      setListing({
                        ...listing,
                        building: {
                          ...newBuilding,
                        },
                      })
                    }
                    surveyId={requestedSurveyId}
                    type="building"
                  />
                </Grid>
              )}
            </Grid>
            {isTenantOrPreview && (
              <div className={classes.amenities}>
                {listing.building.amenities &&
                  listing.building.amenities.map((amenity) => (
                    <Chip
                      className={classes.chip}
                      color="primary"
                      key={amenity}
                      label={amenity}
                    />
                  ))}
              </div>
            )}
            {!isTenantOrPreview && (
              <div className={classes.editablAmenities}>
                <Amenities
                  amenities={listing.building.amenities}
                  updateAmenities={(newAmenities) =>
                    setListing({
                      ...listing,
                      building: {
                        ...listing.building,
                        amenities: newAmenities,
                      },
                    })
                  }
                />
              </div>
            )}
            {(!isTenantOrPreview || siblingListings.length > 0) && (
              <>
                <Typography className={classes.availabilityTitle} variant="h3">
                  {`Availability at ${listing.building.address}`}
                </Typography>
                <div className={classes.siblingListingsContainer}>
                  {siblingListings.map((siblingListing, index) => (
                    <Grid
                      key={siblingListing.id}
                      className={clsx(
                        classes.siblingListing,
                        index > 0 && classes.siblingListingBorder
                      )}
                      container
                      onClick={() => {
                        window.location = getViewListingRoute(
                          siblingListing.id,
                          requestedSurveyId
                        );
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography variant="h3">
                          {siblingListing.address2}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography align="right" variant="body1">
                          {`${formatSizeByListing(siblingListing)}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography align="right" variant="body1">
                          {`${
                            formatSqftPriceByListing(siblingListing) || ''
                          } / SqFt`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                  {!isTenantOrPreview && (
                    <div
                      className={clsx(
                        classes.siblingListing,
                        classes.siblingListingAddListingLink,
                        siblingListings.length && classes.siblingListingBorder
                      )}
                      onClick={() => setShowAddListingModal(true)}
                    >
                      <Typography variant="h3">+ Add A Listing</Typography>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.metadataBlock}>
            <Typography className={classes.sectionHeader} variant="h2">
              Location
            </Typography>
            <GoogleMap
              center={{
                lat: listing.building.geo.latitude,
                lng: listing.building.geo.longitude,
              }}
              mapContainerStyle={{
                height: '330px',
                width: '100%',
              }}
              options={{ scrollwheel: false }}
              zoom={15}
            >
              <Marker
                position={{
                  lat: listing.building.geo.latitude,
                  lng: listing.building.geo.longitude,
                }}
              />
            </GoogleMap>
            <Grid container>
              <Grid className={classes.buildingAddressInfo} item md={5} sm={12}>
                <Typography variant="h3">{primaryName}</Typography>
                {secondaryName && (
                  <>
                    <Typography variant="h3">{secondaryName}</Typography>
                  </>
                )}
                <Typography variant="h3">{tertiaryName}</Typography>
              </Grid>
              <Grid className={classes.walkscores} item md={7} sm={12}>
                {listing.building.walkscore &&
                  listing.building.walkscore.walkscore && (
                    <div>
                      <WalkScoreBadge
                        walkscore={listing.building.walkscore.walkscore}
                        description={listing.building.walkscore.description}
                        helpLink={listing.building.walkscore.help_link}
                        titleVariant="h3"
                        height="48px"
                        fontSize="284px"
                      />
                    </div>
                  )}
                {listing.building.walkscore &&
                  listing.building.walkscore.transit && (
                    <div>
                      <TransitScoreBadge
                        transitScore={listing.building.walkscore.transit.score}
                        description={
                          listing.building.walkscore.transit.description
                        }
                        helpLink={listing.building.walkscore.help_link}
                        titleVariant="h3"
                        height="48px"
                        fontSize="284px"
                      />
                    </div>
                  )}
                {listing.building.walkscore &&
                  listing.building.walkscore.bikescore && (
                    <div>
                      <BikeScoreBadge
                        bikeScore={listing.building.walkscore.bikescore.score}
                        description={
                          listing.building.walkscore.bikescore.description
                        }
                        helpLink={listing.building.walkscore.help_link}
                        titleVariant="h3"
                        height="48px"
                        fontSize="32px"
                      />
                    </div>
                  )}
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      <Grid className={classes.metadataBlock} item xs={12} sm={6}>
        <Typography className={classes.sectionHeader} variant="h2">
          Activity
        </Typography>
        <Grid
          className={classes.activityContainerGrid}
          style={{ maxHeight: `${leftColumnHeight * rightColumnHeightMult}px` }}
        >
          {listing && listing.id && !user.isAnonymous && (
            <ActivityProvider>
              <ListingActivityFeed
                listing={listing}
                surveyId={requestedSurveyId || null}
                setHasMoreActivity={setHasMoreActivity}
              />
            </ActivityProvider>
          )}
          {user.isAnonymous && (
            <AnonymousActivityCta setModalOpen={setShowLoginModal} />
          )}
        </Grid>
        {!!hasMoreActivity && (
          <div className={classes.activityCutoffContainer}>
            <div className={classes.activityCutoffGradient} />
            <Button
              color="primary"
              variant="text"
              className={classes.activityCutoffButton}
              onClick={() => setRightColumnHeightMult((h) => h + 1)}
            >
              See more activity
            </Button>
          </div>
        )}
      </Grid>
      <div>
        <Modal
          childProps={{
            space: listing,
            updateListing: (newValues) =>
              setListing({
                ...listing,
                ...newValues,
              }),
          }}
          content={MODALS.EDIT_LISTING_DETAILS}
          onClose={() => setShowEditListingDetailsModal(false)}
          open={showEditListingDetailsModal}
        />
        <Modal
          childProps={{
            building: listing.building,
            updateBuilding: (newValues) =>
              setListing({
                ...listing,
                building: {
                  ...listing.building,
                  ...newValues,
                },
              }),
          }}
          content={MODALS.EDIT_BUILDING_DETAILS}
          onClose={() => setShowEditBuildingDetailsModal(false)}
          open={showEditBuildingDetailsModal}
        />
        <Modal
          content={MODALS.ADD_LISTING_MODAL}
          onClose={() => {
            fetchSiblingListings(listing);
            setShowAddListingModal(false);
          }}
          childProps={{
            surveyId: requestedSurveyId,
            fetchSurveyListings,
            preSelectedBuilding: listing.building,
          }}
          open={showAddListingModal}
        />
        <Modal
          content={MODALS.LOGIN_MODAL}
          onClose={() => setShowLoginModal(false)}
          open={showLoginModal}
          childProps={{
            surveyId: requestedSurveyId,
            contentClassName: classes.loginModalContent,
          }}
        />
      </div>
    </Grid>
  );
}

ViewListingBody.propTypes = {
  listing: PropTypes.object.isRequired,
};

export default ViewListingBody;
