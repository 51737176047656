import React, { Fragment } from 'react'
import { Settings } from '~/pdfs/patterns/Settings'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { LeaseUpPage } from '../patterns/LeaseUpPage'
import { styled } from '~/libraries/styled'
import { LeaseUpHeader } from '../patterns/LeaseUpHeader'
import { buildSpaceName } from '../support/buildSpaceName'
import { buildAddress } from '../support/buildAddress'
import { image } from '~/pdfs/support/image'
import { Photo } from '~/pdfs/patterns/Photo'
import { convertFieldsToList } from '../support/convertFieldsToList'
import { Font } from '~/components/Font'
import { Group } from '~/pdfs/patterns/Group'
import { CheckboxSetting } from '~/pdfs/patterns/CheckboxSetting'
import { SpaceName, SpaceNotes } from '~/objects/fields'
import { isLike } from '~/support/isLike'
import { findFieldAndFormat } from '~/support/findFieldAndFormat'
import { Spacer } from '~/components/Spacer'

export const LeaseUpSpace = definePageTemplate({
  id: 'b920d762-a100-426c-8083-de44f0983c2b',

  name: 'Space Details',

  props: (pdf, space) => {
    const fields = space.fields.filter((field) => {
      return !isLike(field, SpaceNotes) && !isLike(field, SpaceName)
    })

    return {
      photo: image(space.building.images[0], { width: 153 * 2 }),
      name: buildSpaceName(space),
      address: buildAddress(space.building.location),
      image: image(space.images[0], { width: 306 * 2 }),
      fields: convertFieldsToList(fields),
      description: findFieldAndFormat(space.fields, SpaceNotes),
      flags: {
        description: pdf.settings['space.description'] ?? true,
      },
    }
  },

  Page: (props) => {
    return (
      <Page>
        <LeaseUpHeader
          photo={props.photo}
          title={props.name}
          subtitle={props.address}
        />

        <Details>
          {props.image && <Photo src={props.image} />}
          {props.flags.description && props.description && (
            <div>
              <Header>
                <Font format="8/8/500" color="#666666" upper>
                  Space Notes
                </Font>
              </Header>

              <Font format="10/16" padding="8px 12px">
                {props.description}
              </Font>
            </div>
          )}
        </Details>

        <Spacer height={24} />

        {props.fields.length > 0 && (
          <Fragment>
            <Header>
              <Font format="8/8/500" color="#666666" upper>
                Space Details
              </Font>
            </Header>

            <Fields>
              {props.fields.map((field) => (
                <Field key={field.id}>
                  <Font format="10/16/600">{`${field.name}:`}</Font>
                  <Font format="10/16">{field.value}</Font>
                </Field>
              ))}
            </Fields>
          </Fragment>
        )}
      </Page>
    )
  },

  Settings: (props) => (
    <Settings>
      <Group label="Details">
        <CheckboxSetting
          id="space.description"
          label="Show Notes"
          value={props.flags.description}
        />
      </Group>
    </Settings>
  ),
})

const Page = styled(LeaseUpPage, {
  padding: '24px',
})

const Details = styled('div', {
  gridTemplateColumns: '1fr 1fr',
  marginTop: '24px',
  minHeight: '250px',
  display: 'grid',
  gap: '12px',
})

const Fields = styled('div', {
  gridTemplateColumns: '1fr 1fr',
  display: 'grid',
  gap: '0px 12px',
})

const Field = styled('div', {
  borderBottom: '1px solid #e0e0e0',
  display: 'flex',
  padding: '8px 12px',
  gap: '8px',
})

const Header = styled('div', {
  backgroundColor: '#f9f9f9',
  borderBottom: '1px solid #e0e0e0',
  padding: '12px',
})
