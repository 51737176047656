import React from 'react';
import { Link, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  enterEmailCta: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    height: '68px',
    width: '100%',
    backgroundColor: props => props.withBackground && '#f9f9f9',
    color: '#666666',
    padding: '24px',
    borderRadius: '4px',
  },
  enterEmailCtaText: {
    marginRight: '4px',
  },
});

export default function AnonymousActivityCta({
  setModalOpen,
  withBackground = true,
}) {
  const classes = useStyles({ withBackground });

  return (
    <div className={classes.enterEmailCta}>
      <Typography className={classes.enterEmailCtaText} variant="h3">
        To view activity or make a comment,
      </Typography>
      <Link variant="h3" onClick={() => setModalOpen(true)}>
        enter your email.
      </Link>
    </div>
  );
}
