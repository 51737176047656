import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Collapse } from '@material-ui/core';

import { TransitionGroup } from 'react-transition-group';
import clsx from 'clsx';
import { useProjectSelector } from '~/legacy/utils/hooks';
import { PROJECT } from '~/legacy/consts';
import { getPlural } from '~/legacy/utils';

import {
  ProjectBreadcrumb,
  Typography,
  SquareIconButton,
  // ProjectTeamIcon,
  // DocumentIcon,
  // MessageIcon,
  MoreIcon,
  ClientAvatar,
  UserAvatar,
  AvatarGroup,
  PageStickyNavBar,
  // SortChevronDownIcon,
  Button,
  useProjectSortByTextField,
  sortProjectCollections,
  PageDivider,
  ProjectSurveyCard,
  // ProjectTourCard,
  // ProjectRequirementTextChip,
  // ProjectRequirementAddChip,
  EmptyStateProjectCollectionCard,
  ProjectState,
  useProjectMenu,
  useAddSurveyModal,
  useSurveyMenu,
} from '~/legacy/components';

const AddNewSurveyButton = withStyles({
  endIcon: {
    marginLeft: 0,
  },
})(({ classes }) => {
  const { addSurvey } = useProjectSelector();
  const { setOpen: setAddSurveyModalOpen, AddSurveyModalComponent } =
    useAddSurveyModal(async (newSurvey) => addSurvey(newSurvey, false));

  return (
    <>
      <Button
        color="primary"
        // TODO: Put back when more menu options
        // endIcon={<SortChevronDownIcon />}
        onClick={() => setAddSurveyModalOpen(true)}
        shrinkButton
        customHeight
        fullWidth
        classes={{
          endIcon: classes.endIcon,
        }}
      >
        <Typography variant="boldText">Add New Survey</Typography>
      </Button>
      {AddSurveyModalComponent}
    </>
  );
});

const EmptyStateAddSurveyCard = () => {
  const { addSurvey } = useProjectSelector();
  const { setOpen: setAddSurveyModalOpen, AddSurveyModalComponent } =
    useAddSurveyModal(async (newSurvey) => addSurvey(newSurvey, false));

  return (
    <>
      <EmptyStateProjectCollectionCard
        onClick={() => setAddSurveyModalOpen(true)}
      />
      {AddSurveyModalComponent}
    </>
  );
};

// We memo here since we share one menu/modal component across cards. To prevent re-rendering every card every time
//   we open/close the modal, we shallow memoize the entire group here.
const ProjectCollectionGroupCards = withStyles({
  pageHorizontalPadding: {},
  collectionContainer: {},
  rootTransition: {
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
})(
  React.memo(
    ({
      classes,
      project,
      projectCollections = [],
      handleMenuClick = () => {},
    }) => {
      return (
        <TransitionGroup
          component="div"
          className={clsx(
            classes.pageHorizontalPadding,
            classes.collectionContainer
          )}
        >
          {projectCollections.map((collection) => (
            // Could not for the life of me get the Collapse to work inside the nested component...
            <Collapse
              className={classes.rootTransition}
              in
              mountOnEnter
              unmountOnExit
              key={`survey-collapse-${collection.id}`}
            >
              {!!(collection.type === PROJECT.STUFF_TYPES.SURVEY_TYPE) && (
                <ProjectSurveyCard
                  key={`survey-${collection.id}`}
                  projectId={project.id}
                  survey={collection}
                  openMenu={handleMenuClick} // this is still changing somehow...
                />
              )}
              {/* TODO: Tours */}
              {/* {!!(collection.type === PROJECT.STUFF_TYPES.TOUR_TYPE) && (
            <ProjectTourCard key={`tour-${collection.id}`} tour={collection} />
          )} */}
            </Collapse>
          ))}
        </TransitionGroup>
      );
    }
  )
);

const ProjectCollectionGroup = withStyles({
  pageHorizontalPadding: {},
  collectionContainer: {},
})(({ classes, projectCollections }) => {
  const { project, deleteSurvey, duplicateSurvey } = useProjectSelector();
  const {
    SurveyMenuComponent,
    handleMenuClick,
    DeleteSurveyMenuItemModalComponent,
  } = useSurveyMenu(
    async (surveyId) => deleteSurvey(surveyId, false),
    async (survey) => duplicateSurvey(survey, false)
  );

  return (
    <>
      <ProjectCollectionGroupCards
        classes={classes}
        project={project}
        handleMenuClick={handleMenuClick}
        projectCollections={projectCollections || []}
      />
      {SurveyMenuComponent}
      {DeleteSurveyMenuItemModalComponent}
    </>
  );
});

// TODO: As we build out less read-only, compose and memoize to reduce rerendering
// TODO: Make Tenant / Anonymous Friendly
const ProjectDetailPage = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    padding: 0,
    marginRight: 0,
    marginLeft: 0,
    overflowY: 'scroll',
  },
  pageHorizontalPadding: {
    paddingLeft: '100px',
    paddingRight: '100px',
  },
  topPagections: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    marginTop: '24px',
  },
  projectBreadcrumbAndActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  projectActoinButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '34px',
  },
  projectActionButton: {
    marginRight: '12px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  projectMembersAndHilightsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '12px',
  },
  projectRequirement: {
    marginRight: '8px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  endIcon: {
    marginLeft: 0,
  },
  navSort: {
    marginLeft: '16px',
  },
  collectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: '80px',
  },
  addButtonContainer: {
    // TODO: put back when adding more menu options
    // minwidth: '76px',
    // width: '76px',
    height: '36px',
    marginLeft: 'auto',
  },
  numberOfCollections: {
    display: 'flex',
    alignItems: 'center',
  },
  dividerContainer: {
    marginTop: '24px',
  },
  requirementsContainer: {
    marginLeft: '30px',
  },
})(({ classes, match }) => {
  const requestedProjectId = match.params.project_id;

  // TODO: implement actual loading state so we don't have to mock an empty project here to prevent errors
  const {
    project: loadingProject,
    mutateUpdate,
    deleteProject,
  } = useProjectSelector(requestedProjectId);
  const project = loadingProject || {};

  const {
    ProjectMenuComponent,
    handleMenuClick: handleProjectMenuClick,
    EditProjectMenuItemModalComponent,
    DeleteProjectMenuItemModalComponent,
  } = useProjectMenu(
    async (newProjectPartial) => mutateUpdate(newProjectPartial, false),
    async () => deleteProject(false)
  );

  const { selectedSort, ProjectSortByTextFieldComponent } =
    useProjectSortByTextField({
      componentProps: { classes: { root: classes.navSort } },
    });

  // TODO: Replace these with real data
  const clientCompany = {
    id: 2,
    name: 'Slack',
    image: {
      thumbnail_image_url:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm9_ufj10NFgL1Dtg5SAIpB4oFk4_OaWQ8d9bcxIUkgx5tjSueRL94aMFMRcg_O2fQqWE&usqp=CAU',
    },
  };

  const projectCollections = project.collections || [];
  const projectUsers = project && project.users ? project.users : [];
  const numSurveys = project && project.surveys ? project.surveys.length : 0;
  // TODO: Add after tours
  // const numTours = project && project.tours ? project.tours.length : 0;
  const hasProjectCollections = !(
    !projectCollections || !projectCollections.length
  );

  // The surveys/tours to show but sorted
  const sortedProjectCollections = useMemo(
    () =>
      projectCollections && projectCollections.length
        ? sortProjectCollections(projectCollections, selectedSort)
        : [],
    [projectCollections, selectedSort]
  );

  return (
    <Container className={classes.root}>
      {/* Breadcrumbs and project actions */}
      <div
        className={clsx(classes.pageHorizontalPadding, classes.topPagections)}
      >
        <div>
          <ProjectBreadcrumb
            projectName={project.name}
            clientCompanyName={clientCompany.name}
          />
        </div>
        <div className={classes.projectBreadcrumbAndActions}>
          <ProjectState projectState={project.state} />
          <div className={classes.projectActoinButtonsContainer}>
            {/* TODO: Add later */}
            {/* <SquareIconButton className={classes.projectActionButton}>
              <ProjectTeamIcon />
            </SquareIconButton>
            <SquareIconButton className={classes.projectActionButton}>
              <DocumentIcon />
            </SquareIconButton>
            <SquareIconButton
              className={classes.projectActionButton}
              hasNotificationBadge
            >
              <MessageIcon />
            </SquareIconButton> */}
            <SquareIconButton
              className={classes.projectActionButton}
              onClick={(event) => handleProjectMenuClick(event, project)}
            >
              <MoreIcon />
            </SquareIconButton>
          </div>
        </div>
      </div>

      {/* Project name  */}
      <div className={clsx(classes.pageHorizontalPadding, classes.pageTitle)}>
        <Typography variant="pageTitle">{project.name}</Typography>
      </div>

      {/* Project Client and Users */}
      <div
        className={clsx(
          classes.pageHorizontalPadding,
          classes.projectMembersAndHilightsContainer
        )}
      >
        <AvatarGroup size="small">
          {[
            <ClientAvatar
              className={classes.avatar}
              client={clientCompany}
              key={`client-${clientCompany.id}`}
            />,
            ...projectUsers.map((projectUser) => (
              <UserAvatar
                className={classes.avatar}
                user={projectUser.user}
                key={`project-user-${projectUser.id}`}
              />
            )),
          ]}
        </AvatarGroup>

        {/* Requirements */}
        {/* TODO: Add after requirements */}
        {/* <div className={classes.requirementsContainer}>
          <ProjectRequirementTextChip
            label="Add Requirements"
            classes={{ root: classes.projectRequirement }}
          />
          <ProjectRequirementAddChip
            classes={{ root: classes.projectRequirement }}
          />
        </div> */}
      </div>

      {/* Bottom border divider */}
      <PageDivider
        classes={{
          root: clsx(classes.pageHorizontalPadding, classes.dividerContainer),
        }}
      />

      <PageStickyNavBar classes={{ root: clsx(classes.pageHorizontalPadding) }}>
        {/* TODO: Sorting */}
        <Typography variant="boldText" className={classes.numberOfCollections}>
          {
            hasProjectCollections
              ? // TODO: Add back after tours
                // `${getPlural(numSurveys, 'Survey')}, ${getPlural(numTours, 'Tour')}`
                `${getPlural(numSurveys, 'Survey')}`
              : '0 Surveys'
            // TODO: Add back after tours
            // '0 Items'
          }
        </Typography>

        {hasProjectCollections && ProjectSortByTextFieldComponent}

        {/* Add button */}
        <div className={classes.addButtonContainer}>
          <AddNewSurveyButton />
        </div>
      </PageStickyNavBar>

      {/* Project Collections - surveys and tours */}
      {sortedProjectCollections && sortedProjectCollections.length ? (
        <ProjectCollectionGroup
          classes={{
            pageHorizontalPadding: classes.pageHorizontalPadding,
            collectionContainer: classes.collectionContainer,
          }}
          projectCollections={sortedProjectCollections}
        />
      ) : (
        <div
          className={clsx(
            classes.pageHorizontalPadding,
            classes.collectionContainer
          )}
        >
          <EmptyStateAddSurveyCard />
        </div>
      )}

      {/* Project Menu */}
      {ProjectMenuComponent}
      {EditProjectMenuItemModalComponent}
      {DeleteProjectMenuItemModalComponent}
    </Container>
  );
});

export default ProjectDetailPage;
