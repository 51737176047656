import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import lodash from 'lodash';
import {
  DeleteIcon,
  DeleteUserModalContext,
  MenuItem,
  Typography,
} from '~/legacy/components';
import { BASE_ICON_STYLES } from './ButtonUtils';

const useStyles = makeStyles((theme) => ({
  ...lodash.merge(BASE_ICON_STYLES(theme), {}),
}));

// A menu item to delete a user from a firm
export const DeleteUserMenuItem = React.forwardRef(
  (
    {
      deletingOwnUser,
      userToDelete,
      handleMenuClose,
      setDeleteUserModalOpen,
      setUsersToDelete,
      setDeletingOwnUser,
      setOnModalClose = () => {},
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    // Update the modal close to also close the menu
    useEffect(() => {
      setOnModalClose(() => () => {
        setDeleteUserModalOpen(false);
        handleMenuClose();
      });
    }, [handleMenuClose, setDeleteUserModalOpen, setOnModalClose]);
    return (
      <MenuItem
        ref={ref}
        onClick={() => {
          setDeletingOwnUser(deletingOwnUser);
          setUsersToDelete(
            userToDelete ? new Set([userToDelete.id]) : new Set()
          );
          setDeleteUserModalOpen(true);
        }}
        {...props}
      >
        <DeleteIcon className={classes.icon} />
        <Typography className={classes.text}> Delete User </Typography>
      </MenuItem>
    );
  }
);

// The DeleteUserMenuItem with necessary context from the provider
export const DeleteUserMenuItemWithContext = ({ ...props }) => {
  return (
    <DeleteUserModalContext.Consumer>
      {(value) => (
        <DeleteUserMenuItem
          setDeleteUserModalOpen={value.setDeleteUserModalOpen}
          setUsersToDelete={value.setUsersToDelete}
          setDeletingOwnUser={value.setDeletingOwnUser}
          setOnModalClose={value.setOnClose}
          {...props}
        />
      )}
    </DeleteUserModalContext.Consumer>
  );
};
