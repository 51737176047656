import camelCase from 'lodash/camelCase';
import {
  userTypes,
  USER_SUBSCRIPTION_STATUSES,
  BROKER_ROLES,
} from '~/legacy/consts';
import { isValidDate } from './dateHelpers';

const BROKER_DISPLAY_STRING = 'broker';
const TENANT_DISPLAY_STRING = 'client';

const getSubscriptionStatus = (user) => {
  if (user && typeof user === 'object') {
    const { expiryDate, expired, hasSubscription } = user;
    const expiry = new Date(expiryDate);

    if (expired || !isValidDate(expiry)) {
      return USER_SUBSCRIPTION_STATUSES.FREE;
    }

    if (hasSubscription && +expiry > Date.now()) {
      return USER_SUBSCRIPTION_STATUSES.PAID;
    }

    if (!hasSubscription && +expiry > Date.now()) {
      return USER_SUBSCRIPTION_STATUSES.TRIAL;
    }
  }

  return USER_SUBSCRIPTION_STATUSES.FREE;
};

const isTenantUserType = (userType) => {
  return userType === userTypes.tenant;
};

const isBrokerUserType = (userType) => {
  return userType === userTypes.tenantBroker;
};

const isAdminUserType = (userType) => {
  return userType === userTypes.admin;
};

const isTenant = (user) => {
  return isTenantUserType(user.userType);
};

const isBroker = (user) => {
  return isBrokerUserType(user.userType);
};

const isBrokerAdminRole = (userRole) => {
  return userRole === BROKER_ROLES.ADMIN;
};

const isBrokerAdmin = (user) => {
  return isBrokerUserType(user.user_type) && isBrokerAdminRole(user.user_role);
};

const isBrokerAdminByTypeAndRole = (userType, userRole) => {
  return isBrokerUserType(userType) && isBrokerAdminRole(userRole);
};

const getBrokerRole = (user) => {
  if (user.user_type === userTypes.tenantBroker) {
    if (user.user_role === BROKER_ROLES.ADMIN) return 'Admin';
    return 'Broker';
  }
  return null;
};

const isUserTenantOrBrokerPreview = (userType, isPreviewTenantView) => {
  return (
    isTenantUserType(userType) ||
    (isBrokerUserType(userType) && isPreviewTenantView)
  );
};

const getUserTypeDisplayString = (userType, capitalizeFirstLetter = false) => {
  const copy =
    isBrokerUserType(userType) || isAdminUserType(userType)
      ? BROKER_DISPLAY_STRING
      : TENANT_DISPLAY_STRING;
  return capitalizeFirstLetter
    ? copy.charAt(0).toUpperCase() + copy.slice(1)
    : copy;
};

const getUserInitials = (user) => {
  let initials = '';
  if (user.firstName && user.lastName) {
    initials = `${user.firstName[0] || ''}${user.lastName[0] || ''}`;
  } else if (user.firstName) {
    initials = `${user.firstName[0] || ''}${user.firstName[1] || ''}`;
  } else if (user.email) {
    initials = `${user.email[0]}${user.email[1]}`;
  }
  return initials.toUpperCase();
};

const getUserName = (user) =>
  user
    ? [user.first_name, user.last_name].filter((name) => !!name).join(' ')
    : null;

const getAdminUserDisplayName = (user) => {
  return getUserName(user) || (user ? user.email : null);
};

const isEmailAddress = (email) => {
  // eslint-disable-next-line
  const emailRregex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
  return email && emailRregex.test(email);
};

const formatUserObject = (user) => {
  const userData = Object.entries(user);
  return userData && userData.length
    ? userData
        .map(([key, value]) => ({
          [camelCase(key)]: value,
        }))
        .reduce((accumulator, obj) => ({
          ...accumulator,
          ...obj,
        }))
    : {};
};

const parseUserName = (text) => {
  const nameParts = text.split(' ');
  return {
    first_name: nameParts.shift(),
    last_name: nameParts.join(' '),
  };
};

const isSameUser = (a, b) => {
  return a === b || a.id === b.id;
};

export {
  getSubscriptionStatus,
  isUserTenantOrBrokerPreview,
  isTenantUserType,
  isBrokerUserType,
  isAdminUserType,
  isTenant,
  isBroker,
  isBrokerAdminRole,
  isBrokerAdmin,
  getUserTypeDisplayString,
  getUserInitials,
  getUserName,
  getAdminUserDisplayName,
  isEmailAddress,
  getBrokerRole,
  isBrokerAdminByTypeAndRole,
  formatUserObject,
  parseUserName,
  isSameUser,
};
