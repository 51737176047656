import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, SquareCheckbox, TextInput } from '~/legacy/components';

import ModalTitle from './ModalTitle';

const useStyles = makeStyles({
  brokerCheckbox: {
    marginLeft: 0,
    marginRight: '12px',
    color: '#111111',
  },
  brokerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '60px',
    borderBottom: '1px solid #e0e0e0',
    '&:first-child': {
      borderTop: '1px solid #e0e0e0',
    },
  },
  brokersContainer: {
    margin: '30px 0',
    flex: 1,
    overflow: 'auto',
    cursor: 'pointer',
  },
  brokerText: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: 'auto',
    marginLeft: 'auto',
  },
  modalContent: {
    background: 'white',
    outline: 'none',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
    height: '580px',
    marginTop: '4px',
    marginBottom: '16px',
  },
});

function PdfConfigModal({ onClose, brokers, onSubmit }) {
  const classes = useStyles();
  const [subtitle, setSubtitle] = useState('');
  const [selectedBrokers, setSelectedBrokers] = useState([brokers[0].id]); // first broker is owner

  const submit = () => {
    onSubmit({
      titlePage: {
        subtitle,
        brokers: brokers.filter((broker) =>
          selectedBrokers.includes(broker.id)
        ),
      },
    });
  };

  const toggleBrokerSelected = (broker) => {
    selectedBrokers.includes(broker.id)
      ? setSelectedBrokers(selectedBrokers.filter((id) => id !== broker.id))
      : setSelectedBrokers(selectedBrokers.concat(broker.id));
  };

  return (
    <div className={classes.modalContent}>
      <ModalTitle onClose={onClose} title="Export" />
      <TextInput
        className={classes.input}
        label="Cover Page Subtitle"
        onChange={(e) => setSubtitle(e.target.value)}
        value={subtitle}
      />
      <div className={classes.brokersContainer}>
        {brokers.map((broker) => (
          <div
            key={broker.id}
            className={classes.brokerContainer}
            onClick={() => toggleBrokerSelected(broker)}
          >
            <SquareCheckbox
              className={classes.brokerCheckbox}
              disabled={
                selectedBrokers.length === 3 &&
                !selectedBrokers.includes(broker.id)
              }
              checked={selectedBrokers.includes(broker.id)}
              onClick={() => toggleBrokerSelected(broker)}
            />
            <div className={classes.brokerText}>
              <Typography variant="h3">
                {`${broker.first_name} ${broker.last_name}`}
              </Typography>
              <Typography variant="body1">{broker.email}</Typography>
            </div>
          </div>
        ))}
      </div>
      <Button className={classes.button} color="primary" onClick={submit}>
        Export PDF
      </Button>
    </div>
  );
}

export default PdfConfigModal;
