import Api from 'rest-fetcher-redux';

const actionList = {
  add: {
    icon: 'plus',
    action: () => console.log('Add something'),
  },
  like: {
    icon: 'heart-o',
    action: () => console.log('Like!!'),
  },
  watch: {
    icon: 'eye',
    action: () => console.log('Watch!!'),
  },
  share: {
    icon: 'share-alt',
    action: () => console.log('sharing...'),
  },
  trash: {
    icon: 'trash-o',
    action: async id => {
      /*eslint-disable */
      const confirmation = await confirm(
        'Are you sure you want to delete this listing?'
      );
      if (!confirmation) return;
      Api.deleteListing({ id })
        .then(res => {
          if (res.detail === 'Deleted') {
            return true;
          }
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },
    /* eslint-enable */
  },
  options: {
    icon: 'ellipsis-v',
    action: () => console.log('more...'),
  },
};

export default list => list.map(l => actionList[l]); // (list, dispatch)
