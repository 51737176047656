import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { arrayMove } from '~/legacy/utils';
import {
  Button,
  DragIcon,
  PreviewIcon,
  HideIcon,
  TransparentScrollOverlay,
} from '~/legacy/components';

import { toast } from '~/legacy/utils/notifications';
import ModalTitle from './ModalTitle';

// TODO: We should probably do this once on init?
const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true;
  }
  return false;
};
const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

const useStyles = makeStyles({
  modalContent: {
    background: 'white',
    outline: 'none',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
    height: '580px',
    marginTop: '4px',
    marginBottom: '16px',
  },
  fieldLabel: {
    paddingLeft: '8px',
  },
  previewIcon: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  saveButton: {
    width: '150px',
    height: '44px',
    marginLeft: 'auto',
  },
  dragIcon: {
    cursor: 'grab',
  },

  row: {
    height: '54px',
    width: '100%',
    borderTop: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: '1px solid #E0E0E0',
    },
  },
});

const ListingFieldRow = ({
  field,
  index,
  moveListingField,
  hideListingField,
}) => {
  const classes = useStyles();

  const ref = useRef(null);
  const previewRef = useRef(null);
  const ItemTypes = {
    CARD: 'card',
  };

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveListingField(dragIndex, hoverIndex);
      // eslint-disable-next-line
      item.index = hoverIndex;
    },
  });
  const opacity = isDragging ? 0 : 1;
  drag(ref);
  drop(previewRef);
  preview(previewRef);

  return (
    <div
      ref={previewRef}
      data-handler-id={handlerId}
      key={field.label}
      className={classes.row}
      style={{
        opacity,
      }}
    >
      <div ref={ref} style={{ display: 'flex' }}>
        <DragIcon className={classes.dragIcon} />
      </div>
      <Typography variant="h3" className={classes.fieldLabel}>
        {field.label}
      </Typography>
      {field.hidden ? (
        <HideIcon
          className={classes.previewIcon}
          onClick={() => hideListingField(index, false)}
        />
      ) : (
        <PreviewIcon
          className={classes.previewIcon}
          onClick={() => hideListingField(index, true)}
        />
      )}
    </div>
  );
};

function ComparisonTableSettingsModal({
  onClose,
  orderedReadOnlyListingFields,
  setOrderedReadOnlyListingFields = () => {},
}) {
  const classes = useStyles();

  const [
    modalOrderedReadOnlyListingFields,
    setModalOrderedReadOnlyListingFields,
  ] = useState(orderedReadOnlyListingFields);

  useEffect(() => {
    setModalOrderedReadOnlyListingFields(orderedReadOnlyListingFields);
  }, [orderedReadOnlyListingFields]);

  const moveModalListingField = useCallback(
    (dragIndex, hoverIndex) => {
      setModalOrderedReadOnlyListingFields(
        arrayMove(modalOrderedReadOnlyListingFields, dragIndex, hoverIndex)
      );
    },
    [modalOrderedReadOnlyListingFields]
  );

  const hideListingField = (index, hide) => {
    const newModalOrderedReadOnlyListingFields = [
      ...modalOrderedReadOnlyListingFields,
    ];
    newModalOrderedReadOnlyListingFields[index] = {
      ...newModalOrderedReadOnlyListingFields[index],
      hidden: hide,
    };
    setModalOrderedReadOnlyListingFields(newModalOrderedReadOnlyListingFields);
  };

  return (
    <div className={classes.modalContent}>
      <ModalTitle onClose={onClose} title="Table Settings" />
      <DndProvider backend={backendForDND}>
        <TransparentScrollOverlay
          paddingPx="0"
          hideScroll
          gradientHeightPx={60}
          bottomPaddingPx={40}
        >
          {modalOrderedReadOnlyListingFields.map((field, index) => (
            <ListingFieldRow
              field={field}
              index={index}
              moveListingField={moveModalListingField}
              hideListingField={hideListingField}
              key={field.label}
            />
          ))}
        </TransparentScrollOverlay>
      </DndProvider>
      <div style={{ width: '100%', display: 'flex' }}>
        <Button
          color="primary"
          onClick={() => {
            setOrderedReadOnlyListingFields(modalOrderedReadOnlyListingFields);
            onClose();
            toast('Table settings applied!');
          }}
          className={classes.saveButton}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default ComparisonTableSettingsModal;
