import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import { Modal, MODALS } from '~/legacy/components';
import Create from './Create';
import Edit from './Edit';
import CustomFieldMenu from './CustomFieldMenu';

const modes = {
  BUTTON: 'button',
  CREATE: 'create',
  DISPLAY: 'display',
  EDIT: 'edit',
};

function CustomField({
  customField,
  isTenantOrPreview,
  object,
  setObject,
  surveyId,
  type,
}) {
  const [mode, setMode] = useState(customField ? modes.DISPLAY : modes.BUTTON);
  const [menuAnchor, setMenuAnchor] = useState();
  const [showAddFromAnotherModal, setShowAddFromAnotherModal] = useState(false);

  if (mode === modes.BUTTON) {
    return (
      <>
        <Link
          underline="none"
          onClick={(e) => {
            if (surveyId) {
              setMenuAnchor(e.currentTarget);
            } else {
              setMode(modes.CREATE);
            }
          }}
          variant="h3"
        >
          + Add Another Field
        </Link>
        <CustomFieldMenu
          id="custom-field-menu"
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
          onClickCreateNew={() => {
            setMode(modes.CREATE);
            setMenuAnchor(null);
          }}
          onClickAddAnother={() => {
            setShowAddFromAnotherModal(true);
            setMenuAnchor(null);
          }}
          type={type}
          elevation={2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
        />
        <Modal
          content={MODALS.ADD_FIELDS_FROM_ANOTHER}
          onClose={() => setShowAddFromAnotherModal(false)}
          open={showAddFromAnotherModal}
          childProps={{
            updateCustomFields: (objectList) =>
              setObject({
                ...object,
                custom_fields: objectList[0].custom_fields,
              }),
            parentObjectIds: [object.id],
            surveyId,
            type,
          }}
        />
      </>
    );
  }
  if (mode === modes.CREATE) {
    return (
      <Create
        objectId={object.id}
        onCancel={() => setMode(modes.BUTTON)}
        onSubmit={(newValues) => {
          setMode(modes.BUTTON);
          setObject({
            ...object,
            custom_fields: object.custom_fields.concat(newValues),
          });
        }}
        type={type}
      />
    );
  }

  if (mode === modes.EDIT || mode === modes.DISPLAY) {
    return (
      <>
        <Edit
          key={`${customField.id}-${customField.custom_field.data_type}`}
          customField={customField}
          objectId={object.id}
          viewOnly={isTenantOrPreview}
          onSubmit={(newCustomField, isDelete = false) => {
            setMode(modes.DISPLAY);
            if (newCustomField && isDelete) {
              setObject({
                ...object,
                custom_fields: object.custom_fields.filter(
                  (field) => field.id !== customField.id
                ),
              });
            } else {
              setObject({
                ...object,
                custom_fields: object.custom_fields.map((field) =>
                  field.id === customField.id ? newCustomField : field
                ),
              });
            }
          }}
          type={type}
        />
      </>
    );
  }
  return null;
}

export default CustomField;
