import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function EditIconSvg(props) {
  return (
    <SvgIcon
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.87979 13.0431L7.91466 13.0393L7.93946 13.0145L16.4844 4.46954C16.9719 3.98205 16.9719 3.19139 16.4844 2.7039L15.0461 1.26562C14.5586 0.778127 13.768 0.778128 13.2805 1.26562L4.73554 9.81054L4.71074 9.83534L4.70686 9.87021L4.40336 12.6018C4.40335 12.6018 4.40335 12.6018 4.40335 12.6018C4.35524 13.032 4.7181 13.3948 5.14823 13.3466L7.87979 13.0431ZM14.0426 2.02501L14.0435 2.02411C14.1091 1.9568 14.22 1.95833 14.2867 2.02501L15.725 3.46329C15.7931 3.53139 15.7918 3.64235 15.7259 3.7065L15.725 3.70741L14.4723 4.96014L12.7899 3.27773L14.0426 2.02501ZM12.9004 9.00937L12.3548 9.55491C12.3009 9.6053 12.275 9.67314 12.275 9.7418V13.6875C12.275 13.8729 12.1229 14.025 11.9375 14.025H2.3125C2.1271 14.025 1.975 13.8729 1.975 13.6875V4.0625C1.975 3.8771 2.1271 3.725 2.3125 3.725H9.7582C9.82776 3.725 9.89641 3.69384 9.94376 3.64649L10.4897 3.10055C10.4899 3.10037 10.4901 3.10019 10.4902 3.1C10.6591 2.93472 10.5374 2.65 10.3051 2.65H2.3125C1.53266 2.65 0.9 3.28266 0.9 4.0625V13.6875C0.9 14.4673 1.53266 15.1 2.3125 15.1H11.9375C12.7173 15.1 13.35 14.4673 13.35 13.6875V9.19492C13.35 8.96134 13.0676 8.84216 12.9004 9.00937ZM5.73851 10.3291L12.0305 4.03984L13.7129 5.72228L7.42364 12.0142L5.52913 12.2236L5.73851 10.3291Z"
        fill="currenColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </SvgIcon>
  );
}
