import { createElement } from 'react'
import { renderPdf } from './renderPdf'

export const renderPdfTemplate = (url, pdf, pages, theme) => {
  const children = pages.map((page) => {
    return createElement(page.template.Page, {
      ...page.props,
      key: page.id,
      pages,
    })
  })

  return renderPdf(url, children, {
    format: pdf.template.format,
    landscape: pdf.template.landscape,
    height: pdf.template.height,
    width: pdf.template.width,
    theme,
  })
}
