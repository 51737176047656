import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '~/legacy/components';
// eslint-disable-next-line
import { SURVEY_LISTING_STATUSES } from '~/legacy/consts';

const baseStyles = {
  buttons: {
    display: 'flex',
  },
  firstButton: {
    marginRight: '8px',
  },
};

const useStylesBase = makeStyles({
  ...baseStyles,
});

const useStylesBuilding = makeStyles({
  ...baseStyles,
  buttons: {
    ...baseStyles.buttons,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});

/**
 * Actions a broker can take on a listing. Should be used alongside a ADD_TO_PROJECT modal to handle
 * broker listing actions. The modal will be controlled via supplied params setListingToShare
 * and setShowAddToProjectModal.
 *
 * TODO: Add in preview functionality, and refactor other use cases to use this BrokerActions
 */
function BrokerActions({
  listing,
  setListingToShare,
  setShowAddToProjectModal,
  inBuilding,
}) {
  const classesBase = useStylesBase();
  const classesBuilding = useStylesBuilding();
  const classes = inBuilding ? classesBuilding : classesBase;

  return (
    <div className={classes.buttons}>
      <Button
        className={classes.firstButton}
        color="primary"
        onClick={(event) => {
          event.stopPropagation();
          setShowAddToProjectModal(true);
          setListingToShare(listing);
        }}
      >
        Add To Survey
      </Button>
    </div>
  );
}
BrokerActions.propTypes = {
  listing: PropTypes.object.isRequired,
  setListingToShare: PropTypes.func.isRequired,
  setShowAddToProjectModal: PropTypes.func.isRequired,
  inBuilding: PropTypes.bool,
};
BrokerActions.defaultProps = {
  inBuilding: false,
};

export default BrokerActions;
