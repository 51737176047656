import { first, styled, when } from '~/legacy/utils/styleHelpers';

const keys = ['divider', 'bottom', 'right', 'left', 'top', 'x', 'y'];

export const Section = styled('div', keys, {
  borderTop: when('divider', '1px solid #E0E0E0'),
  marginBottom: first(['bottom', 'y']),
  marginRight: first(['right', 'x']),
  marginLeft: first(['left', 'x']),
  marginTop: first(['top', 'y']),
});
