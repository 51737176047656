import { useMemo, useState } from 'react';
import { findByKeyOrFirstItem } from '~/legacy/utils/miscUtils';

export default function (data = [], options, initial) {
  const [option, setOption] = useState(() => {
    return findByKeyOrFirstItem(options, 'value', initial);
  });

  return {
    data: useMemo(() => data.sort(option.sort), [option, data]),
    label: option.title || option.label || option.value,
    options,
    select: setOption,
    value: option.value,
  };
}
