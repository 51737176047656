import React from 'react'
import { HistoryIcon } from '~/legacy/components'
import { Empty } from '../components/Empty'
import { RecentList } from '../components/RecentList'
import { useRecents } from '../hooks/useRecents'

export const Recents = ({ id, ...props }) => {
  const { data } = useRecents(id)

  return (
    <RecentList data={data} {...props}>
      <Empty icon={<HistoryIcon />} {...props}>
        As you use LeaseUp, your most recent
        <br />
        items will appear in this area.
      </Empty>
    </RecentList>
  )
}
