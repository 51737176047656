import React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { MODALS, Modal } from '~/legacy/components';
import { useApiHelper } from '~/legacy/utils/hooks';
import { toast } from '~/legacy/utils/notifications';
import { SURVEY_LISTING_STATUSES } from '~/legacy/consts';
import { setSurveyListings } from '~/legacy/store/actions/viewSurvey';

export default function DeclineListingModalReal({
  surveyListingToDecline,
  allSurveyListings,
  setSurveyListingToDecline = () => {},
}) {
  const apiHelper = useApiHelper();
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Modal
      childProps={{
        clientListingId: surveyListingToDecline
          ? surveyListingToDecline.id
          : null,
        address: surveyListingToDecline
          ? surveyListingToDecline.listing.building.address
          : '',
        onSubmitOverride: () => {
          const newStatus = SURVEY_LISTING_STATUSES.DECLINED;
          apiHelper
            .updateSurveyListingStatus({
              surveyListingId: surveyListingToDecline.id,
              newStatus,
              colors: theme.palette.primary,
              statusChange: newStatus,
            })
            .then(() => {
              const newSurveyListings = [];
              allSurveyListings.forEach((surveyListing) => {
                if (surveyListing.id === surveyListingToDecline.id) {
                  newSurveyListings.push({
                    ...surveyListing,
                    status: newStatus,
                  });
                } else {
                  newSurveyListings.push(surveyListing);
                }
              });
              dispatch(setSurveyListings(newSurveyListings));
              toast('Feedback submitted!', { appearance: 'success' });
              setSurveyListingToDecline(null);
            });
        },
      }}
      content={MODALS.DECLINE_LISTING}
      open={!!surveyListingToDecline}
      onClose={() => setSurveyListingToDecline(null)}
    />
  );
}
