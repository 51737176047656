import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '~/legacy/components';
import { ContentModal } from '~/legacy/components/modals';

/**
 * A modal where a user can confirm taking an action. Provide your own content
 */
const ActionModal = withStyles({
  actionButton: {
    marginLeft: '12px',
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 'auto',
  },
  optionTextContainer: {
    marginBottom: '40px',
  },
})(
  ({
    classes,
    onClose,
    onAfterClose = () => {},
    onConfirm,
    title,
    confirmButtonLabel,
    children,
    loading = false,
    disableAction = loading,
    ModalComponentProps = {},
  }) => {
    return (
      <ContentModal
        title={title}
        onClose={onClose}
        onAfterClose={onAfterClose}
        ModalComponentProps={ModalComponentProps}
      >
        <div className={classes.optionTextContainer}>{children}</div>

        <div className={classes.optionsContainer}>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={classes.actionButton}
            color="primary"
            onClick={onConfirm}
            loading={loading}
            disabled={disableAction}
          >
            {confirmButtonLabel}
          </Button>
        </div>
      </ContentModal>
    );
  }
);

export default ActionModal;
