const ACTIONS = {
  EDIT_KEY_VALUE_STRING: 'editListing_edit_key_value_string',
  SET_INITIAL_DATA: 'editListing_set_initial_data',
};

const initialState = {
  name: '',
  address: '',
  address2: '',
  city: '',
  zipcode: '',
  state: '',
  propertyType: null,
  buildingSize: '',
  sqft_price: '',
  monthly_price: '',
  negotiable: false,
  vacancy_type_id: null,
  vacancyType: null,
  size: '',
  leaseTerm: '',
  availability: '',
  availabilityDate: '',
  features: [],
  description: '',
  amenities: [],
  images: [],
  status: 'MARKETPLACE',
  parking_ratio: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.EDIT_KEY_VALUE_STRING:
      return {
        ...state,
        [action.key]: action.value,
      };

    /* {
  payload: {
    id: 32,
    created_at: '2019-10-19T07:35:36.435322Z',
    updated_at: '2019-10-19T07:35:36.435393Z',
    owner: {
      id: 1,
      username: 'admin',
      first_name: '',
      last_name: '',
      email: 'admin@somecompany.com',
      companycontact: null
    },
    'private': false,
    name: 'Spajza',
    description: 'Do you see any Teletubbies in here?',
    status: 'MARKETPLACE',
    file_ids: [],
    address: '2nd human street',
    address2: '',
    city: 'zagreb',
    zipcode: '3e3dd',
    state: 'Massachusetts',
    geo: {
      latitude: null,
      longitude: null
    },
    latitude: null,
    longitude: null,
    size: 123,
    building_size: 12312,
    price: 222,
    sqft_price: null,
    total_price: 222,
    is_negotiable: null,
    property_type: null,
    property_type_id: null,
    vacancy_type: null,
    vacancy_type_id: null,
    date_available: null,
    date_term: null,
    lease_term: '',
    amenities: [
      'Wifi',
      'spek'
    ],
    features: [
      'shelfs',
      'doors',
      ''
    ],
    s3_images: [],
    toured: false
  }
} */
    case ACTIONS.SET_INITIAL_DATA: {
      const newState = { ...initialState, ...action.payload };
      return newState;
    }
    default:
      return state;
  }
}

export const editListingActions = {
  editKeyValueString: (key, value) => ({
    type: ACTIONS.EDIT_KEY_VALUE_STRING,
    key,
    value,
  }),
  setInitialData: payload => ({
    type: ACTIONS.SET_INITIAL_DATA,
    payload,
  }),
};
