import React, { useState, useMemo, useCallback } from 'react';
import { Menu } from '@material-ui/core';
import { isBrokerAdmin } from '~/legacy/utils';
import {
  DeleteUserMenuItemWithContext,
  useViewSurveyMenuItem,
  useResetPasswordMenuItem,
  useEditProfileMenuItem,
} from '~/legacy/components';

export const useAdminUserMenu = ({
  adminUser,
  users,
  updateUser = () => {},
}) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState({});
  const selectedUser =
    anchorMenuEl && anchorMenuEl.user ? anchorMenuEl.user : {};
  const selectedOwnUser = selectedUser && selectedUser.id === adminUser.id;

  // Admin users in the company other than the selected user
  const anotherAdminExists = useMemo(
    () =>
      users && selectedUser
        ? users.filter(
            (user) => isBrokerAdmin(user) && user.id !== selectedUser.id
          ).length
        : false,
    [users, selectedUser]
  );

  const handleMenuClick = useCallback((event, user) => {
    setAnchorMenuEl({ anchor: event.currentTarget, user });
  }, []);

  const handleMenuClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorMenuEl({ ...anchorMenuEl, anchor: null });
  };

  const {
    ViewSurveysMenuItemComponent,
    ViewUserOwnedSurveysMenuItemModalComponent,
  } = useViewSurveyMenuItem({
    surveys: selectedUser.owned_surveys || [],
    disabled:
      !selectedUser ||
      !selectedUser.owned_surveys ||
      !selectedUser.owned_surveys.length,
    handleMenuClose,
  });

  const {
    ResetPasswordMenuItemComponent,
    ResetPasswordMenuItemConfirmModalComponent,
  } = useResetPasswordMenuItem({
    user: selectedUser,
    handleMenuClose,
  });

  const {
    EditProfileMenuItemComponent,
    EditProfileMenuItemConfirmModalComponent,
  } = useEditProfileMenuItem({
    user: selectedUser,
    editingSelf: selectedOwnUser,
    anotherAdminExists,
    updateUser,
    handleMenuClose,
  });

  const DeleteSurveyMenuComponent = (
    <DeleteUserMenuItemWithContext
      userToDelete={selectedUser}
      deletingOwnUser={selectedOwnUser}
      handleMenuClose={handleMenuClose}
    />
  );

  const AdminUserMenuComponent = (
    <AdminUserMenu
      anchorMenuEl={anchorMenuEl ? anchorMenuEl.anchor : null}
      handleMenuClose={handleMenuClose}
      ViewSurveysMenuItemComponent={ViewSurveysMenuItemComponent}
      ResetPasswordMenuItemComponent={ResetPasswordMenuItemComponent}
      DeleteSurveyMenuComponent={DeleteSurveyMenuComponent}
      EditProfileMenuItemComponent={EditProfileMenuItemComponent}
    />
  );

  return {
    AdminUserMenuComponent,
    handleMenuClick,
    ViewUserOwnedSurveysMenuItemModalComponent,
    ResetPasswordMenuItemConfirmModalComponent,
    EditProfileMenuItemConfirmModalComponent,
  };
};

export const AdminUserMenu = ({
  anchorMenuEl,
  handleMenuClose,
  ViewSurveysMenuItemComponent,
  ResetPasswordMenuItemComponent,
  EditProfileMenuItemComponent,
  DeleteSurveyMenuComponent,
}) => {
  return (
    <Menu
      id="more-options-menu"
      anchorEl={anchorMenuEl}
      open={!!anchorMenuEl}
      onClose={handleMenuClose}
      elevation={2}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
    >
      {ResetPasswordMenuItemComponent}
      {EditProfileMenuItemComponent}
      {ViewSurveysMenuItemComponent}
      {DeleteSurveyMenuComponent}
    </Menu>
  );
};
