import unionBy from 'lodash/unionBy';
import {
  SET_SURVEY,
  UPDATE_SURVEY,
  ADD_SURVEY_LISTING_AND_BUILDING,
  SET_SURVEY_LISTING,
  SET_SURVEY_LISTINGS,
  SET_SURVEY_BUILDINGS,
  ADD_SURVEY_BUILDING,
  SET_SURVEY_LISTINGS_AND_BUILDINGS,
  SET_SELECTED_TAB,
  SET_ADD_LISTING_MODAL_STATE,
  SET_TABLE_ROW_CLICKS_ENABLED,
} from '../actions/viewSurvey';

const initialState = {
  survey: {},
  surveyListings: [],
  surveyBuildings: [],
  selectedTab: 0,
  addListingModalState: {
    open: false,
    building: null,
  },
  tableRowClicksEnabled: true,
};

function viewSurvey(state = initialState, action) {
  switch (action.type) {
    case SET_SURVEY:
      return Object.assign({}, state, {
        survey: action.survey,
      });
    case UPDATE_SURVEY:
      return {
        ...state,
        survey: {
          ...state.survey,
          ...action.survey,
        },
      };
    case ADD_SURVEY_LISTING_AND_BUILDING: {
      const newData = {
        surveyBuildings: unionBy(
          state.surveyBuildings,
          [action.surveyBuilding],
          'id'
        ),
      };
      if (action.surveyListing) {
        newData.surveyListings = state.surveyListings.concat(action.surveyListing);
      }
      return {
        ...state,
        ...newData,
      };
    }
    case SET_SURVEY_LISTING:
      return {
        ...state,
        surveyListings: state.surveyListings.map(surveyListing => {
          if (surveyListing.id === action.surveyListing.id) {
            return { ...action.surveyListing };
          }
          return surveyListing;
        }),
      };
    case SET_SURVEY_LISTINGS:
      return Object.assign({}, state, {
        surveyListings: action.surveyListings,
      });
    case SET_SURVEY_BUILDINGS:
      return Object.assign({}, state, {
        surveyBuildings: action.surveyBuildings,
      });
    case ADD_SURVEY_BUILDING:
      return Object.assign({}, state, {
        surveyBuildings: [...state.surveyBuildings, action.surveyBuilding],
      });
    case SET_SURVEY_LISTINGS_AND_BUILDINGS:
      return Object.assign({}, state, {
        surveyListings: action.surveyListings,
        surveyBuildings: action.surveyBuildings,
      });
    case SET_SELECTED_TAB:
      return Object.assign({}, state, {
        selectedTab: action.tab,
      });
    case SET_ADD_LISTING_MODAL_STATE:
      return {
        ...state,
        addListingModalState: {
          ...action.modalState,
        },
      };
    case SET_TABLE_ROW_CLICKS_ENABLED:
      return {
        ...state,
        tableRowClicksEnabled: action.enabled,
      };
    default:
      return state;
  }
}

export default viewSurvey;
