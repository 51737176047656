import { createId, createImageFile } from '~/legacy/utils'

export const recentProjects = [
  {
    id: createId(),
    name: 'Central Texas Office',
    logo: createImageFile('/prototype/Logo3.png'),
    company: 'Unified Logistics',
    status: 'Search',
    steps: [2, 4],
    tags: ['Apr 01, 2022', '3,100 sqft', '$32.50 psf'],
  },
  {
    id: createId(),
    name: 'Austin HQ',
    logo: createImageFile('/prototype/Logo2.png'),
    company: 'Insight Technologies',
    status: 'Signed',
    steps: [4, 4],
    tags: ['May 01, 2022', '3,200 sqft', '$38.00 psf'],
  },
  {
    id: createId(),
    name: 'AV North Austin',
    logo: createImageFile('/prototype/Logo1.png'),
    company: 'AV Engineering Group',
    status: 'Search',
    steps: [2, 4],
    tags: ['June 05, 2022', '5,800 sqft', '$23.50 psf'],
  },
]

export const fullProjectDetail = {
  name: 'Central Texas Office',
  company: 'Unified Logistics',
  stage: 'Search',
  stages: ['Draft', 'Search', 'Proposal', 'Signed'],
  tags: ['Apr 28, 2022', '3,100 sqft', '$32.50 psf'],
  profiles: [
    { image: '/prototype/Logo3.png' },
    { image: '/prototype/Profile1.png' },
    { image: '/prototype/Profile5.png' },
    { image: '/prototype/Profile3.png' },
    { image: '/prototype/Profile2.png' },
  ].reverse(),
  items: [
    {
      type: 'survey',
      name: 'SW Austin',
      link: '/surveys/1/buildings/1',
      updatedAt: new Date('04/11/2022'),
      count: 17,
      photos: [
        '/prototype/Building6.png',
        '/prototype/Building5.png',
        '/prototype/Building10.png',
        '/prototype/Building8.png',
        '/prototype/Building9.png',
        '/prototype/Building4.png',
        '/prototype/Building3.png',
      ],
    },
    {
      type: 'survey',
      name: 'North Austin',
      updatedAt: new Date('04/09/2022'),
      count: 15,
      photos: [
        '/prototype/Building2.png',
        '/prototype/Building5.png',
        '/prototype/Building6.png',
        '/prototype/Building1.png',
        '/prototype/Building3.png',
        '/prototype/Building4.png',
        '/prototype/Building7.png', //
        '/prototype/Building7.png',
        '/prototype/Building7.png',
        '/prototype/Building7.png',
        '/prototype/Building7.png',
      ],
    },
  ],
}

export const emptyProjectDetail = {
  name: 'The Name',
  company: 'The Company',
  stage: 'Draft',
  stages: ['Draft', 'Search', 'Proposal', 'Signed'],
  tags: [],
  profiles: [{ image: '/prototype/Profile1.png' }],
  items: [],
}
