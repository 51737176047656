import React from 'react'
import ReactLinkify from 'react-linkify'

const Linkify = ({ children, properties = {}, ...props }) => {
  return (
    <ReactLinkify
      properties={{
        ...properties,
        onClick: (event) => event.stopPropagation(),
      }}
      {...props}
    >
      {children}
    </ReactLinkify>
  )
}

export { Linkify }
