import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import {
  MenuItem,
  Typography,
  useDeleteSpaceFieldModal,
} from '~/legacy/components'
import { BASE_ICON_STYLES } from '~/legacy/components/buttons/ButtonUtils'
import { DeleteIcon } from '~/legacy/components/svgs'

const useMenuItemStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}))

// Menu item to edit a project
export const DeleteSpaceFieldMenuItem = React.forwardRef(
  ({ isCustomField, openModal, ...props }, ref) => {
    const classes = useMenuItemStyles()

    return [
      <MenuItem
        key="delete-field-menu-item"
        ref={ref}
        className={classes.menuItemRoot}
        onClick={openModal}
        {...props}
      >
        <DeleteIcon className={classes.icon} />
        <Typography className={classes.text}>
          {isCustomField ? 'Delete Field' : 'Clear Field'}
        </Typography>
      </MenuItem>,
    ]
  }
)

// Bundle the modal and the menu item together for maximum convenience
export const useDeleteSpaceFieldMenuItem = ({
  fieldName,
  buildingName,
  isCustomField,
  deleteSpaceField,
  isBuildingField,
  handleMenuClose,
}) => {
  // Get the modal
  const { setOpen: setOpenModal, DeleteSpaceFieldModalComponent } =
    useDeleteSpaceFieldModal(
      fieldName,
      buildingName,
      isCustomField,
      deleteSpaceField,
      isBuildingField,
      () => {
        setOpenModal(false)
        handleMenuClose()
      }
    )

  // Get the item component for the modal
  const DeleteSpaceFieldMenuItemComponent = (
    <DeleteSpaceFieldMenuItem
      isCustomField={isCustomField}
      openModal={() => {
        setOpenModal(true)
        handleMenuClose()
      }}
    />
  )

  return {
    DeleteSpaceFieldMenuItemComponent,
    DeleteSpaceFieldModalComponent,
  }
}
