// this should most likely calculate zoom also
/**
 *
 * @param {Array} Listing items from which to calculate middle.
 * Items shuld have geo key with {longitute, latitude}
 * @author Prpa
 * @returns {Object} {longitute, latitude}
 */
export const getCenter = items => {
  const out = {
    lng: -71.0709518,
    lat: 42.3601924,
  };
  if (!items || items.length === 0) return out;
  let long = 0;
  let lat = 0;
  let itemsWithGeo = 0;
  items.forEach(i => {
    long += i.building.geo.longitude;
    lat += i.building.geo.latitude;
    if (i.geo && (i.building.geo.longitude || i.building.geo.latitude))
      itemsWithGeo++;
  });
  if (itemsWithGeo === 0) return out;
  return {
    lng: long / (itemsWithGeo || 1),
    lat: lat / (itemsWithGeo || 1),
  };
};

export const getItemsInViewport = (items, bounds) => {
  if (bounds && Array.isArray(items)) {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const neLat = ne.lat();
    const neLng = ne.lng();
    const swLat = sw.lat();
    const swLng = sw.lng();

    const newItems = items.filter(i => {
      if (
        i &&
        i.building &&
        i.building.geo &&
        i.building.geo.latitude >= swLat &&
        i.building.geo.latitude <= neLat &&
        i.building.geo.longitude >= swLng &&
        i.building.geo.longitude <= neLng
      ) {
        return true;
      }
      return false;
    });

    return newItems;
  }

  return [];
};

export const parseGoogleAddressComponents = addressComponents => {
  const pluckedValues = {};
  addressComponents.forEach(component => {
    pluckedValues[component.types[0]] = component.long_name;
  });

  // Sometimes the best match doesn't have a street number
  const streetNumber = pluckedValues.street_number || null;
  const route = pluckedValues.route || null;
  const premise = pluckedValues.premise || null;
  let address = null;
  if (streetNumber && route) {
    address = `${pluckedValues.street_number} ${pluckedValues.route}`;
  } else if (streetNumber) {
    address = streetNumber;
  } else if (route) {
    address = route;
  } else if (premise) {
    address = premise;
  }

  return {
    address,
    city:
      pluckedValues.locality ||
      pluckedValues.political ||
      pluckedValues.neighborhood,
    state: pluckedValues.administrative_area_level_1,
    zipcode: pluckedValues.postal_code,
    country: pluckedValues.country,
  };
};

export const parseGoogleAddressValues = (addressComponents, geometry) => {
  const parsedAddressComponents = parseGoogleAddressComponents(
    addressComponents
  );
  return {
    ...parsedAddressComponents,
    latitude: geometry.location.lat(),
    longitude: geometry.location.lng(),
  };
};

export function filterListingsWithGeo(listings) {
  if (listings && Array.isArray(listings)) {
    return listings.filter(
      l =>
        l &&
        l.building &&
        l.building.geo &&
        l.building.geo.longitude &&
        l.building.geo.latitude
    );
  }

  return [];
}

export function filterListingsWithoutGeo(listings) {
  if (listings && Array.isArray(listings)) {
    return listings.filter(
      l =>
        l &&
        l.building &&
        l.building.geo &&
        (!l.building.geo.latitude || !l.building.geo.longitude)
    );
  }

  return [];
}

export function mapSavedImages(images) {
  return images.map(item => ({
    name: item,
    id: item.id,
    src: item.url,
    preview: item.url,
    loaded: true,
  }));
}

export function cleanPolygon(polyData) {
  const out = [];
  if (!Array.isArray(polyData) || polyData.length === 0) return null;
  polyData.forEach(p => {
    out.push(p.coordinates);
  });
  return out;
}

export function cleanCircle(circleData) {
  const out = [];
  if (!Array.isArray(circleData) || circleData.length === 0) return null;
  circleData.forEach(p => {
    const { center, radius } = p;
    const temp = { center, radius };
    out.push(temp);
  });
  return out;
}
