import { Link, Menu, MenuItem, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
// eslint-disable-next-line
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  CircleIconButton,
  Logo,
  Typography,
  UserAvatar,
} from '~/legacy/components'
import { QuestionMarkIcon } from '~/legacy/components/svgs'
import {
  LEASE_ADMIN,
  LEASE_LOGIN,
  LEASE_SETTINGS,
  userTypes,
} from '~/legacy/consts'
import { isBrokerAdminByTypeAndRole, useIsLeaseupLogo } from '~/legacy/utils'

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '32px',
    marginLeft: 'auto',
    minWidth: (props) => (props.isLeaseUpLogo ? '120px' : '158px'),
    maxWidth: (props) => (props.isLeaseUpLogo ? '120px' : '158px'),
  },
  loginLink: {
    marginRight: '32px',
    marginLeft: 'auto',
    cursor: 'pointer',
    '&:hover': {
      color: 'inherit',
    },
  },
  helpButtonWrapper: {
    marginRight: '8px',
  },
  helpButton: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.85),
    },
  },
  accountButton: {
    marginLeft: 'auto',
    marginRight: '20px',
  },
  avatarContainer: {
    display: 'flex',
    minWidth: '158px',
    maxWidth: '158px',
  },
  flexContainer: {
    height: '100%',
  },
  logoContainer: {
    display: 'flex',
    height: '100%',
    minWidth: (props) => (props.isLeaseUpLogo ? '120px' : '158px'),
    maxWidth: (props) => (props.isLeaseUpLogo ? '120px' : '158px'),
    overflow: 'hidden',
  },
  paper: {
    background: '#ffffff',
    borderBottom: '1px solid #e8e8e8',
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    paddingLeft: '32px',
  },
  tabs: {
    height: '100%',
    width: '100%',
  },
  navMenuItem: {
    width: '186px',
    height: '38px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
}))

const TopNavMenuItem = React.forwardRef(
  ({ isLeaseUpLogo, children, ...props }, ref) => {
    const classes = useStyles({ isLeaseUpLogo })
    return (
      <MenuItem ref={ref} className={classes.navMenuItem} {...props}>
        <Typography variant="boldText">{children}</Typography>
      </MenuItem>
    )
  }
)

function TopNav() {
  const isLeaseUpLogo = useIsLeaseupLogo()
  const classes = useStyles({ isLeaseUpLogo })
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((store) => store.user)
  const { userType } = user

  const profilePopupState = usePopupState({
    variant: 'popover',
    popupId: 'profile-menu',
  })

  const helpPopupState = usePopupState({
    variant: 'popover',
    popupId: 'help-menu',
  })

  return (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.logoContainer}>
        <Logo />
      </div>
      {!user.isAnonymous && (
        <div className={classes.buttons}>
          {userType === userTypes.tenantBroker && (
            <CircleIconButton
              {...bindTrigger(helpPopupState)}
              active={helpPopupState.isOpen}
              className={classes.helpButton}
              wrapperClassName={classes.helpButtonWrapper}
              icon={<QuestionMarkIcon />}
            />
          )}
          <CircleIconButton
            {...bindTrigger(profilePopupState)}
            active={profilePopupState.isOpen}
            icon={<UserAvatar user={user} />}
          />
        </div>
      )}
      {user.isAnonymous && (
        <Link
          className={classes.loginLink}
          color="inherit"
          onClick={() => history.push(LEASE_LOGIN)}
          underline="none"
          variant="h3"
        >
          Login
        </Link>
      )}
      <Menu
        {...bindMenu(helpPopupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <TopNavMenuItem>
          <a
            href="https://help.leaseup.co"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            Help Center
          </a>
        </TopNavMenuItem>
        <TopNavMenuItem>
          <a
            href="mailto:help@leaseup.co"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            Contact Support
          </a>
        </TopNavMenuItem>
        <TopNavMenuItem>
          <a
            href="/feedback"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            Share an Idea
          </a>
        </TopNavMenuItem>
      </Menu>
      <Menu
        {...bindMenu(profilePopupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <TopNavMenuItem
          onClick={() => {
            profilePopupState.close()
            history.push(LEASE_SETTINGS)
          }}
        >
          Settings
        </TopNavMenuItem>
        {isBrokerAdminByTypeAndRole(user.userType, user.user_role) && (
          <TopNavMenuItem
            onClick={() => {
              profilePopupState.close()
              history.push(LEASE_ADMIN)
            }}
          >
            Admin
          </TopNavMenuItem>
        )}
        <TopNavMenuItem
          onClick={() => {
            profilePopupState.close()
            dispatch({ type: 'LOGOUT' })
            history.push(LEASE_LOGIN)
          }}
        >
          Logout
        </TopNavMenuItem>
      </Menu>
    </Paper>
  )
}

export default TopNav
