import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  getAdminUserDisplayName,
  isBrokerAdminByTypeAndRole,
} from '~/legacy/utils';
import {
  UserAvatar,
  Typography,
  ExpandMoreIcon,
  MenuSelectedItemIcon,
} from '~/legacy/components';

const useUserAutocompleteStyles = makeStyles({
  optionText: {
    marginLeft: '12px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  autocomplete: {
    // Easier than targeting Autocomplete -> renderInput -> TextField -> InputProps -> style,
    //   since you need to also pass in style coming in from TextField ...params
    '& .MuiInputBase-root': {
      height: '54px',
      padding: '0 0 0 14px',
    },
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '38px',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  muiOption: {
    paddingLeft: '12px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  expandLessIcon: {
    color: '#111',
  },
  selectedItem: {
    marginLeft: '20px',
    color: '#666',
  },
  optionLeftContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const UserOption = React.memo(({ option, showRole, isSelected = false }) => {
  const classes = useUserAutocompleteStyles();

  const displayName = React.useMemo(() => {
    if (
      showRole &&
      isBrokerAdminByTypeAndRole(option.user_type, option.user_role)
    ) {
      return ' (Admin)';
    }
    return '';
  }, [option, showRole]);

  return (
    <div className={classes.optionContainer}>
      <div className={classes.optionLeftContent}>
        <UserAvatar user={option} size="tiny" />
        <Typography variant="boldText" className={classes.optionText}>
          {getAdminUserDisplayName(option)}
          {displayName}
        </Typography>
      </div>
      {!!isSelected && (
        <MenuSelectedItemIcon className={classes.selectedItem} />
      )}
    </div>
  );
});

const UserAutocomplete = ({
  label = '',
  placeholder = '',
  userOptions,
  selectedUser,
  setSelectedUser = () => {},
  errorText = '',
  setErrorText = () => {},
  showRole = false,
}) => {
  const classes = useUserAutocompleteStyles();

  return (
    <Autocomplete
      onChange={(event, newUser) => {
        setSelectedUser(newUser);
        setErrorText('');
      }}
      variant="outlined"
      className={classes.autocomplete}
      options={userOptions}
      renderInput={(params) => {
        const otherInputLabelProps =
          params && params.InputLabelProps ? params.InputLabelProps : {};
        return (
          <TextField
            {...params}
            helperText={errorText}
            error={!!errorText}
            label={label}
            placeholder={placeholder}
            open
            variant="outlined"
            InputLabelProps={{ ...otherInputLabelProps }}
          />
        );
      }}
      classes={{ option: classes.muiOption }}
      // Render the label of the option in the dropdown. Not used in favor of renderOption but still required
      getOptionLabel={(option) => getAdminUserDisplayName(option)}
      // Render the label of the option in the dropdown
      renderOption={(option) => {
        return (
          <UserOption
            option={option}
            showRole={showRole}
            key={option.id}
            isSelected={selectedUser && selectedUser.id === option.id}
          />
        );
      }}
      popupIcon={<ExpandMoreIcon className={classes.expandLessIcon} />}
    />
  );
};

export default UserAutocomplete;
