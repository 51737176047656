import get from 'lodash/get'

export const columns = [
  { id: 0, value: '800', key: '# of Tenants' },
  { id: 1, value: '50%', key: '% Vacant' },
  {
    id: 2,
    value: '$12.95',
    key: 'Achieved Gross Rent $/SF',
    map: mapCurrencyCell([
      'Avg. Achieved Net Rent $/SF',
      'Additional Rent $/SF',
    ]),
  },
  {
    id: 3,
    value: '$10.00',
    key: 'Achieved Price $/SF',
    map: 'Bldg Price Per Area',
  },
  {
    id: 4,
    value: '$300,000',
    key: 'Achieved Sale Price',
    map: 'Sales Price',
  },
  {
    id: 5,
    value: '$2.95',
    key: 'Additional Rent $/SF',
    map: 'Operating Expenses Per Area',
  },
  { id: 6, value: '0.035', key: 'Ann. Esc. %', map: 'Escalation Amount' },
  { id: 7, value: '$10.00', key: 'Asking Net Rent $/SF', map: 'Rent Low' },
  {
    id: 8,
    value: '$10.00',
    key: 'Asking Price $/SF',
  },
  {
    id: 9,
    value: '$300,000',
    key: 'Asking Sale Price',
  },
  {
    id: 10,
    value: '107,000',
    key: 'Avail Size (SF)',
    map: 'Max Available Area',
  },
  {
    id: 11,
    value: '11/1/2022',
    key: 'Availability Date',
    map: 'Earliest Move In Date',
  },
  {
    id: 12,
    value: '$10.00',
    key: 'Avg. Achieved Net Rent $/SF',
    map: 'Average Rent per Area',
  },
  {
    id: 13,
    value: '107,000',
    key: 'Building SF',
    map: (row) => get(row, 'building.building_size'),
  },
  { id: 14, value: '7.04%', key: 'CAP Rate' },
  { id: 15, value: '7.04%', key: 'CAP Rate (Y1)' },
  {
    id: 16,
    value: 'Kitchener',
    key: 'City',
    map: (row) => get(row, 'building.city'),
  },
  { id: 17, value: 'A', key: 'Class', map: 'Building Class' },
  {
    id: 18,
    value: '16-20',
    key: 'Clear Height (ft.)',
    map: mapJoinedCell('-', ['Avail. Clr Ht Min', 'Avail. Clr Ht Max']),
  },
  { id: 19, value: '11/1/2022', key: 'Closed date', map: 'Sign Date' },
  {
    id: 20,
    value: "50' x 56'",
    key: 'Column Span',
    map: mapJoinedCell(' x ', ['Column Spacing Width', 'Column Spacing Depth']),
  },
  {
    id: 21,
    value: 'Comment text',
    key: 'Comments',
    map: 'Public Comments',
    size: '8fr',
  },
  { id: 22, value: '3', key: 'Free Rent (Months)', map: 'Free Rent (mos)' },
  {
    id: 23,
    value: '$12.95',
    key: 'Gross Rent $/SF',
    map: mapCurrencyCell(['Rent Low', 'Operating Expenses per area']),
  },
  {
    id: 24,
    value: '$3,000,000',
    key: 'Guidance or Sale Price',
  },
  { id: 25, value: 'Thumbnail', key: 'Image', map: 'Not accessible' },
  {
    id: 26,
    value: '1787377 Ontario Inc.',
    key: 'Landlord',
    map: 'Building Primary Owner',
  },
  {
    id: 27,
    value: 'Y1-$10.75\nY2-$11.13\nY3-$11.52',
    key: 'Lease Schedule',
    map: 'Rent Comments',
  },
  { id: 28, value: 'Direct', key: 'Lease Type', map: 'Space Type' },
  {
    id: 29,
    value: '10 TL, 1 DI',
    key: 'Loading',
    map: mapJoinedCell(', ', ['Avail. Docks Total', 'Avail. Drive Ins']),
  },
  {
    id: 30,
    value: '.35',
    key: 'Lot Coverage',
  },
  {
    id: 31,
    value: '7',
    key: 'Lot Size (Acres)',
    map: 'Property Parcel (Acres/Hectares)',
  },
  { id: 32, value: 'Exclusive', key: 'Market Status' },
  {
    id: 33,
    value: '107,000',
    key: 'Min. Divisible (SF)',
    map: 'Min Available Area',
  },
  { id: 34, value: '$12.95', key: 'NER', map: 'Effective Rent per Area' },
  { id: 35, value: '$3,000,000', key: 'NOI' },
  { id: 36, value: '18.5%', key: 'Office %' },
  { id: 37, value: '19,835', key: 'Office SF', map: 'Included Office Space' },
  { id: 38, value: '???', key: 'Owner' },
  {
    id: 39,
    value: 'Existing',
    key: 'Physical Status',
    map: 'Building Status',
  },
  {
    id: 40,
    value: '600V / 800A',
    key: 'Power',
    map: mapJoinedCell(' / ', ['Avail. Volts Low', 'Avail. Amps Low']),
  },
  {
    id: 41,
    value: '100 Hollinger Crescent',
    key: 'Property',
    map: (row) => get(row, 'building.address'),
  },
  {
    id: 42,
    value: '$15,000',
    key: 'Property Taxes',
  },
  {
    id: 43,
    value: '$1.50',
    key: 'Property Taxes $/SF',
    map: 'Taxes Per Area',
  },
  { id: 44, value: '???', key: 'Rent Roll' },
  {
    id: 45,
    value: '11/1/2022',
    key: 'Sale Closed Date',
  },
  { id: 46, value: '$300,000', key: 'Sale Price', map: 'Sale Price' },
  {
    id: 47,
    value: '$10.00',
    key: 'Sale Price $/SF',
    map: mapCurrencyCell(['Sale Price (Total)', 'Building Size']),
  },
  { id: 48, value: '$10.00', key: 'Sale Price $/SF' },
  {
    id: 49,
    value: 'Dickinson & Sons,\nPagac Group,',
    key: 'Tenant Name(s)',
  },
  { id: 50, value: 'Existing', key: 'Term (Months)', map: 'Terms (mos)' },
  { id: 51, value: '$34.50', key: 'TIA $/SF', map: 'TIs per area' },
  { id: 52, value: '107,000', key: 'Total SF', map: 'Lease Size' },
  { id: 53, value: '112A', key: 'Unit / Suite', map: 'Suite' },
  {
    id: 54,
    value: '$10.00',
    key: 'Y1 Achieved Net Rent $/SF',
    map: 'First Year Rent per Area',
  },
  { id: 55, value: '2022', key: 'Year Built', map: 'Property Build Year' },
  { id: 56, value: 'B2', key: 'Zoning', map: 'PLAT - Zoning' },
]

export const templates = [
  {
    id: 0,
    title: 'Industrial Lease Availabilities',
    label: 'Lease Availabilities',
    columns: [
      25, 41, 16, 13, 17, 55, 39, 53, 28, 10, 33, 37, 36, 31, 30, 11, 18, 29,
      40, 20, 56, 7, 5, 23, 26, 21,
    ],
  },
  {
    id: 1,
    title: 'Industrial Sale Availabilities',
    label: 'Sale Availabilities',
    columns: [
      25, 41, 16, 13, 37, 36, 17, 55, 39, 31, 30, 11, 18, 29, 40, 20, 56, 46,
      47, 43, 21,
    ],
  },
  {
    id: 2,
    title: 'Industrial Investment Availabilities',
    label: 'Investment Availabilities',
    columns: [
      25, 41, 16, 13, 37, 36, 17, 32, 31, 30, 18, 35, 14, 24, 48, 0, 1, 49, 44,
      38, 21,
    ],
  },
  {
    id: 3,
    title: 'Industrial Lease Transaction',
    label: 'Lease Transaction',
    columns: [
      25, 41, 16, 17, 13, 37, 36, 19, 50, 7, 54, 12, 6, 27, 5, 2, 18, 29, 26,
      22, 51, 34, 21,
    ],
  },
  {
    id: 4,
    title: 'Industrial Sale Transaction',
    label: 'Sale Transaction',
    columns: [
      25, 41, 16, 17, 13, 37, 36, 31, 30, 45, 9, 8, 4, 3, 42, 18, 29, 21,
    ],
  },
  {
    id: 5,
    title: 'Industrial Investment Transaction',
    label: 'Investment Transaction',
    columns: [25, 41, 16, 13, 37, 36, 17, 31, 30, 18, 45, 35, 15, 4, 3, 1, 21],
  },
]

export function findTemplate(id) {
  return templates.find((template) => template.id === id)
}

export function findColumn(id) {
  return columns.find((column) => column.id === id)
}

export function findColumns(ids) {
  return ids.map(findColumn)
}

export function findField(key, fields = []) {
  return fields.find((field) => {
    return field.name.toLowerCase() === key.toLowerCase()
  })
}

export function findFieldValue(key, fields = []) {
  return get(findField(key, fields), 'custom_field.value')
}

export function findSurveyBuildingField(key, building) {
  return findFieldValue(key, get(building, 'building.custom_fields'))
}

export function mapSurveyBuildingColumn(column, building) {
  if (typeof column.map === 'function') return column.map(building)
  return findSurveyBuildingField(column.map || column.key, building)
}

const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function parseCurrency(value = 0) {
  return value ? String(value).replace(/[^0-9.]/g, '') : 0
}

export function formatCurrency(value) {
  const number = Number(value)
  return isNaN(number) ? '' : currency.format(value)
}

export function add(a, b) {
  return a + b
}

export function sum(...values) {
  return values
    .flat(5)
    .filter(Boolean)
    .map(Number)
    .filter(Boolean)
    .reduce(add, 0)
}

export function sumCurrency(...values) {
  const currencies = values.flat(5).filter(Boolean).map(parseCurrency)
  return sum(currencies)
}

export function sumCurrencyCell(...values) {
  const value = sumCurrency(values)
  return value ? formatCurrency(value) : undefined
}

export function mapCurrencyCell(keys) {
  return (row) => {
    const values = keys.map((key) => findSurveyBuildingField(key, row))
    return sumCurrencyCell(values)
  }
}

export function mapJoinedCell(seperator, keys) {
  return (row) => {
    return keys
      .map((key) => findSurveyBuildingField(key, row))
      .filter(Boolean)
      .join(seperator)
  }
}
