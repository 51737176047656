import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  WalkScoreIcon,
  BikeScoreIcon,
  TransitScoreIcon,
  CopyrightIcon,
  Typography,
} from '~/legacy/components';

const BaseBadge = withStyles({
  base: {
    height: '96px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    padding: '12px 8px 8px',
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginRight: '8px',
    },
    overflow: 'hidden',
  },
  titleSection: {
    marginBottom: '4px',
    display: 'flex',
  },
  title: {},
  description: {},
  copyrightIcon: {
    marginLeft: '2px',
  },
  iconsContainer: {
    display: 'flex',
    marginTop: 'auto',
  },
  score: {
    marginLeft: 'auto',
    paddingRight: '2px',
  },
})(
  ({
    title,
    description,
    svg,
    score,
    classes,
    copyrightIconProps,
    scoreIconProps,
  }) => {
    return (
      <div className={classes.base}>
        <div>
          <div className={classes.titleSection}>
            <Typography
              className={classes.title}
              noWrap
              variant="boldTextSmall"
            >
              {title}
            </Typography>
            <CopyrightIcon
              className={classes.copyrightIcon}
              {...copyrightIconProps}
            />
          </div>
          <Typography
            className={classes.description}
            noWrap
            variant="textSmall"
          >
            {description}
          </Typography>
        </div>
        <div className={classes.iconsContainer}>
          {React.createElement(svg, scoreIconProps)}
          <Typography className={classes.score} variant="numberOnCard">
            {score}
          </Typography>
        </div>
      </div>
    );
  }
);

const WalkScores = withStyles({
  root: {
    display: 'flex',
  },
})(
  ({
    classes,
    walkscore,
    badgeClasses,
    copyrightIconProps,
    scoreIconProps,
  }) => {
    const { bikescore, transit } = walkscore;
    return (
      <div className={classes.root}>
        {walkscore.walkscore && (
          <BaseBadge
            title="Walk Score"
            description={walkscore.description}
            svg={WalkScoreIcon}
            score={walkscore.walkscore}
            classes={badgeClasses}
            copyrightIconProps={copyrightIconProps}
            scoreIconProps={scoreIconProps}
          />
        )}
        {transit && (
          <BaseBadge
            title="Transit Score"
            description={transit.description}
            svg={TransitScoreIcon}
            score={transit.score}
            classes={badgeClasses}
            copyrightIconProps={copyrightIconProps}
            scoreIconProps={scoreIconProps}
          />
        )}
        {bikescore && (
          <BaseBadge
            title="Bike Score"
            description={bikescore.description}
            svg={BikeScoreIcon}
            score={bikescore.score}
            classes={badgeClasses}
            copyrightIconProps={copyrightIconProps}
            scoreIconProps={scoreIconProps}
          />
        )}
      </div>
    );
  }
);

export default WalkScores;
