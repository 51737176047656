import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogContent, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CreateModal,
  NewSurveyModal,
  NewUserModal,
  ConfirmModal,
  DeclineListingModal,
  AddToProjectModal,
  SignUpModal,
  SignUpConfirmModal,
  AddListingModal,
  EditSpaceDetailsModal,
  EditBuildingDetailsModal,
  ImageUploadModal,
  ImageGalleryModalOld,
  ConfirmDeleteSurveyModal,
  ConfirmDeleteListingModal,
  AddFieldsFromAnotherModal,
  PdfConfigModal,
  ComparisonTableSettingsModal,
  ComparisonTableAddMoreListingsModal,
  ComparisonShareModal,
  BulkImportFieldMatchModal,
  UploadBuildingHeroPhotoModal,
  LoginModal,
  ResetPasswordModal,
  AccountEditModal,
} from '~/legacy/components/modals';

export const MODALS = {
  CREATE: 'create',
  NEW_SURVEY: 'newSurvey',
  NEW_USER: 'newUser',
  NEW_USER_INFO: 'newUserInfo',
  DELETE_LISTING: 'deleteListing',
  DECLINE_LISTING: 'declineListing',
  ADD_TO_PROJECT: 'addToProject',
  SIGN_UP: 'signUp',
  SIGN_UP_CONFIRM: 'signUpConfirm',
  ADD_LISTING_MODAL: 'addListingModal',
  EDIT_SPACE_DETAILS: 'editSpaceDetails',
  EDIT_BUILDING_DETAILS: 'editBuildingDetails',
  IMAGE_UPLOAD: 'imageUpload',
  IMAGE_GALLERY_OLD: 'imageGalleryOld',
  CONFIRM_DELETE_SURVEY: 'confirmDeleteSurvey',
  CONFIRM_DELETE_LISTING: 'confirmDeleteListing',
  ADD_FIELDS_FROM_ANOTHER: 'addFieldsFromAnother',
  PDF_CONFIG: 'pdfConfig',
  COMPARISON_TABLE_SETTINGS: 'COMPARISON_TABLE_SETTINGS',
  COMPARISON_ADD_MORE_LISTINGS: 'COMPARISON_ADD_MORE_LISTINGS',
  COMPARISON_SHARE_MODAL: 'COMPARISON_SHARE_MODAL',
  CONFIRM_REMOVE_PDF_PAGE: 'CONFIRM_REMOVE_PDF_PAGE',
  BULK_IMPORT_FIELD_MATCH: 'BULK_IMPORT_FIELD_MATCH',
  BULK_IMPORT_CONFIRM_FIELD_TYPE_MODAL: 'BULK_IMPORT_CONFIRM_FIELD_TYPE_MODAL',
  UPLOAD_BUILDING_HERO_PHOTO: 'UPLOAD_BUILDING_HERO_PHOTO',
  LOGIN_MODAL: 'loginModal',
  CONFIRM_MODAL: 'CONFIRM_MODAL',
  RESET_PASSWORD_MODAL: 'resetPasswordModal',
  ACCOUNT_EDIT_MODAL: 'accountEditModal',
};

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noPadding: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
  fullHeight: {
    height: '100%',
  },
});

const OpenCloseTransition = React.forwardRef((props, ref) => {
  return <Grow ref={ref} {...props} />;
});

function Modal({
  content,
  childProps,
  fullScreen = false,
  open,
  onClose,
  disableBackDropClickClose = false,
}) {
  const classes = useStyles();
  const getContentComponent = () => {
    switch (content) {
      case MODALS.CREATE:
        return <CreateModal />;
      case MODALS.NEW_SURVEY:
        return <NewSurveyModal />;
      case MODALS.NEW_USER:
        return <NewUserModal />;
      case MODALS.DELETE_LISTING:
        // TODO: revisit this factory, as original components are created without
        //   props and destroyed, breaking proptype validation
        return (
          <ConfirmModal
            onClose={onClose}
            onConfirm={childProps.onConfirm}
            title={childProps.title}
            text={childProps.text}
            onfirmButtonLabel={childProps.confirmButtonLabel}
          />
        );
      case MODALS.DECLINE_LISTING:
        return (
          <DeclineListingModal
            onClose={onClose}
            address={childProps.address}
            clientListingId={childProps.clientListingId}
            onSubmitOverride={childProps.onSubmitOverride}
          />
        );
      case MODALS.ADD_TO_PROJECT:
        return (
          <AddToProjectModal
            onClose={onClose}
            listingId={childProps.listingId}
            address={childProps.address}
          />
        );
      case MODALS.SIGN_UP:
        return <SignUpModal />;
      case MODALS.SIGN_UP_CONFIRM:
        return <SignUpConfirmModal />;
      case MODALS.ADD_LISTING_MODAL:
        return <AddListingModal onClose={onClose} />;
      case MODALS.EDIT_Space_DETAILS:
        return <EditSpaceDetailsModal />;
      case MODALS.EDIT_BUILDING_DETAILS:
        return <EditBuildingDetailsModal />;
      case MODALS.IMAGE_UPLOAD:
        return <ImageUploadModal />;
      case MODALS.IMAGE_GALLERY_OLD:
        return <ImageGalleryModalOld />;
      case MODALS.CONFIRM_DELETE_SURVEY:
        return <ConfirmDeleteSurveyModal />;
      case MODALS.CONFIRM_DELETE_LISTING:
        return (
          <ConfirmDeleteListingModal
            listingId={childProps.listingId}
            buildingId={childProps.buildingId}
            surveyId={childProps.surveyId}
          />
        );
      case MODALS.ADD_FIELDS_FROM_ANOTHER:
        return <AddFieldsFromAnotherModal />;
      case MODALS.PDF_CONFIG:
        return <PdfConfigModal />;
      case MODALS.COMPARISON_TABLE_SETTINGS:
        return (
          <ComparisonTableSettingsModal
            orderedReadOnlyListingFields={
              childProps.orderedReadOnlyListingFields
            }
            setOrderedReadOnlyListingFields={
              childProps.setOrderedReadOnlyListingFields
            }
          />
        );
      case MODALS.COMPARISON_ADD_MORE_LISTINGS:
        return (
          <ComparisonTableAddMoreListingsModal
            allListingsInSurvey={childProps.allListingsInSurvey}
            selectedListingsSet={childProps.selectedListingsSet}
            setSelectedListingsSet={childProps.setSelectedListingsSet}
          />
        );
      case MODALS.COMPARISON_SHARE_MODAL:
        return <ComparisonShareModal survey={childProps.survey} />;
      case MODALS.CONFIRM_REMOVE_PDF_PAGE:
        // TODO: revisit this factory, as original components are created without
        //   props and destroyed, breaking proptype validation
        return (
          <ConfirmModal
            onClose={onClose}
            onConfirm={childProps.onConfirm}
            title={childProps.title}
            text={childProps.text}
            confirmButtonLabel={childProps.confirmButtonLabel}
          />
        );
      case MODALS.BULK_IMPORT_FIELD_MATCH:
        return <BulkImportFieldMatchModal {...childProps} />;
      case MODALS.UPLOAD_BUILDING_HERO_PHOTO:
        return <UploadBuildingHeroPhotoModal {...childProps} />;
      case MODALS.LOGIN_MODAL:
        return <LoginModal />;
      case MODALS.CONFIRM_MODAL:
        return <ConfirmModal onClose={onClose} {...childProps} />;
      case MODALS.RESET_PASSWORD_MODAL:
        return <ResetPasswordModal />;
      case MODALS.ACCOUNT_EDIT_MODAL:
        return <AccountEditModal {...childProps} />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      id="dialog-backdrop"
      className={classes.modal}
      fullScreen={fullScreen}
      maxWidth={false}
      open={open}
      onClose={(event, reason) => {
        if (!disableBackDropClickClose || reason !== 'backdropClick') {
          onClose(event, reason);
        }
      }}
      TransitionComponent={OpenCloseTransition}
    >
      <DialogContent
        className={clsx(
          childProps && childProps.noPadding && classes.noPadding,
          childProps && childProps.fullHeight && classes.fullHeight,
          childProps && childProps.contentClassName
        )}
      >
        {React.cloneElement(getContentComponent(), {
          ...childProps,
          onClose,
        })}
      </DialogContent>
    </Dialog>
  );
}

export default Modal;
