import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const MockDroppableProvided = React.createContext({});

const DraggableTableBodyWrapper = ({
  isUserBroker,
  handleDragEnd,
  children,
}) => {
  if (isUserBroker) {
    return (
      <DragDropContext onDragEnd={result => handleDragEnd(result)}>
        <Droppable droppableId="droppable" direction="vertical">
          {children}
        </Droppable>
      </DragDropContext>
    );
  }
  return (
    <MockDroppableProvided.Consumer>{children}</MockDroppableProvided.Consumer>
  );
};

export default DraggableTableBodyWrapper;
