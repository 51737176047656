import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

// FIXME: I've added a default value for children, but it's not being used with children anywhere in the app.
// We can probably remove it later.
function Loading({ children = undefined, isLoading, ...rest }) {
  if (isLoading) {
    return <CircularProgress {...rest} />
  }
  return children || ''
}

export default Loading
