import { useSelector } from 'react-redux';
import {
  getAdminUserDisplayName,
  getUserInitials,
  isSameUser,
} from '~/legacy/utils';
import { createParser } from '~/legacy/utils/serializeHelpers';

export const parseUserProfile = createParser({
  parse: (user) => ({
    id: user.get('id'),
    name: getAdminUserDisplayName({
      first_name: user.get('firstName'),
      last_name: user.get('lastName'),
      email: user.get('email'),
    }),
    company: user.get('company.name'),
    initials: getUserInitials(user.data),
    image: user.get('userAvatarImage'),
  }),
});

export const useUserProfile = () => {
  return useSelector((state) => {
    return parseUserProfile(state.user);
  }, isSameUser);
};
