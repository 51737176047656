import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Group } from '~/pdfs/patterns/Group'
import { Settings } from '~/pdfs/patterns/Settings'
import { Text } from '~/pdfs/patterns/Text'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { useFieldWithDelay } from '~/support/useFieldDelay'
import { useSend } from '~/support/useSend'
import { CresaPage } from '../patterns/CresaPage'

const useStyles = makeStyles({
  container: {
    paddingTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  item: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  photo: {
    width: '67px',
    height: '58px',
    background: '#D9D9D9',
    objectFit: 'cover',
  },
  time: {
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '20px',
    color: '#001E5A',
    paddingBottom: '6px',
  },
  address: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '11px',
    color: '#000000',
  },
})

export const CresaItenerary = definePageTemplate({
  id: 'fff4fe03-bb87-4acb-8a87-b181ae565f9a',

  name: 'Tour Itenerary',

  props: ({ data: survey, settings }) => {
    const buildings = survey.buildings.filter((building) => {
      return building.spaces.length > 0
    })

    return {
      buildings: buildings.map((building) => {
        const id = `${survey.id}.${building.id}.itenerary`
        const address = ['name', 'address', 'city', 'state']
          .map((key) => building.location[key])
          .filter(Boolean)

        return {
          id,
          time: settings[id],
          photo: building.images[0]?.thumbnail,
          name: address[0],
          address: address.join(', '),
        }
      }),
    }
  },

  Page: (props) => {
    const classes = useStyles()
    return (
      <CresaPage title="Tour Itinerary">
        <div className={classes.container}>
          {props.buildings.map((building) => (
            <div key={building.id} className={classes.item}>
              {building.photo ? (
                <img
                  className={classes.photo}
                  src={building.photo}
                  alt={building.name}
                />
              ) : (
                <div className={classes.photo} />
              )}
              <div>
                {building.time && (
                  <div className={classes.time}>{building.time}</div>
                )}
                <div className={classes.address}>{building.address}</div>
              </div>
            </div>
          ))}
        </div>
      </CresaPage>
    )
  },

  Settings: (props) => {
    return (
      <Settings>
        {props.buildings.map((building) => (
          <Time
            key={building.id}
            id={building.id}
            label={building.name}
            value={building.time}
          />
        ))}
      </Settings>
    )
  },
})

const Time = (props) => {
  const send = useSend()

  const value = useFieldWithDelay(props.value, (value) => {
    send('AddSettings', { [props.id]: value })
  })

  return (
    <Group label={props.label}>
      <Text {...value} placeholder="Tour time" nullable />
    </Group>
  )
}
