import React, { useEffect, useState } from 'react'
import { Hidden, Slide, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Api from 'rest-fetcher-redux'
import EmailIcon from '@material-ui/icons/Email'
import posthog from 'posthog-js'
import { Button, Loading, Logo, TextInput, TextLink } from '~/legacy/components'
import { ModalTitle } from '~/legacy/components/modals'
import {
  ENTER_USER,
  ENTER_PASSWORD,
  CREATE_PASSWORD,
  REQUEST_MAGIC_LINK,
  MAGIC_LINK_SENT,
  FORGOT_PASSWORD,
  RESET_PASSWORD_SENT,
  CREATE_PASSWORD_SENT,
  LEASE_LOGIN,
} from '~/legacy/consts'
import {
  isTenantUserType,
  useLogoUrl,
  isEmailAddress,
  setAuthLocalStorage,
} from '~/legacy/utils'

const useStyles = makeStyles({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalTitle: {
    position: 'absolute',
    top: '24px',
    right: '24px',
  },
  button: {
    margin: '20px 0',
    width: '250px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '250px',
  },
  confirmPasswordInput: {
    marginTop: '20px',
  },
  passwordRequirements: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-12px',
    marginBottom: '12px',
  },
  logoContainer: {
    maxWidth: (props) => (props.isLeaseUpLogo ? '120px' : '158px'),
    maxHeight: '72px',
    display: 'flex',
  },
  logo: {
    justifyContent: 'center !important',
  },
  subTitle: {
    marginTop: '20px',
    marginBottom: '20px',
    maxWidth: '250px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: 'relative',
  },
  error: {
    color: '#FF0000',
  },
  subText: {
    paddingTop: '15px',
  },
  emailIcon: {
    width: '60px',
    height: '60px',
    paddingTop: '15px',
  },
  helperText: {
    fontSize: '12px',
    letterSpacing: '0.2px',
    lineHeight: '21px',
  },
})

function LoginModal({
  allowClose = true,
  onClose,
  initialWindow = ENTER_USER,
  surveyId,
}) {
  const queryParams = new URLSearchParams(location.search)
  const urlParamEmail = queryParams.get('email')
  const classes = useStyles({ isLeaseUpLogo: !localStorage.skin })
  const theme = useTheme()
  const [firstTimeUser, setFirstTimeUser] = useState(false)
  const [requestingMagicLinkEmail, setRequestingMagicLinkEmail] =
    useState(false)
  const [email, setEmail] = useState(urlParamEmail)
  const [password, setPassword] = useState('')
  const [errorText, setErrorText] = useState('')
  const [currentWindow, setCurrentWindow] = useState(initialWindow)
  const [checkingIfUserExists, setCheckingIfUserExists] = useState(false)

  // A redirect parameter indicates where to send the user after logging in
  const redirectParam =
    location.pathname === '/login'
      ? queryParams.get('redirect') || ''
      : location.pathname

  const redirectSurveyId =
    redirectParam.includes('surveys') && redirectParam.match(/\d+/)
      ? redirectParam.match(/\d+/)[0]
      : ''
  const zendeskRedirect = queryParams.get('zendesk_redirect')

  useEffect(() => {
    const errorReason = queryParams.get('reason')
    const zendeskLink = queryParams.get('zendesk_redirect')

    if (errorReason === 'expired') {
      setErrorText(
        'The magic link you clicked is expired. Re-enter your email to request a new magic link.'
      )
    } else if (errorReason === 'redeemed') {
      setErrorText(
        'The magic link you clicked has already been used. Re-enter your email to request a new magic link.'
      )
    } else if (zendeskLink) {
      setErrorText('Please log in to continue to Zendesk')
    }
  }, [])

  const logoUrl = useLogoUrl()

  const checkIfUserExists = () => {
    if (!email) {
      return
    }

    setCheckingIfUserExists(true)
    Api.doesUserExist({
      body: {
        email,
        redirect_survey_id: redirectSurveyId || surveyId,
        color: theme.palette.primary.main,
        color_hover: theme.palette.primary.dark,
        logo_url: logoUrl,
      },
    })
      .then((userData) => {
        if (userData) {
          // If we want to redirect the user to another domain, do that. Redirect them to subdomain's login page with other url params included and their email already entered
          if (userData.redirect_subdomain) {
            console.log('redirecting to subdomain', userData.redirect_subdomain)
            const rawRedirectUrl = `${process.env.APP_SUBDOMAIN_TEMPLATE.replace(
              'subdomain',
              userData.redirect_subdomain
            )}${LEASE_LOGIN}`

            const params = []
            params.push(`email=${encodeURIComponent(email)}`)
            const paramsRedirect = queryParams.get('redirect')
            if (paramsRedirect) {
              params.push(`redirect=${paramsRedirect}`)
            }
            const redirectUrl = `${rawRedirectUrl}?${params.join('&')}`
            window.location.replace(redirectUrl)
          } else if (userData.exists) {
            if (isTenantUserType(userData.user_type)) {
              // New/existing tenant on new invite magic link flow
              setCurrentWindow(REQUEST_MAGIC_LINK)
              setErrorText('')

              // Is this a first time user?
              if (!userData.confirmed) {
                setFirstTimeUser(true)
              }
            } else {
              setErrorText('')
              if (userData.sent_create_email) {
                // Unconfirmed broker, sent a password create email
                setCurrentWindow(CREATE_PASSWORD_SENT)
              } else {
                // Broker logging in
                setCurrentWindow(ENTER_PASSWORD)
              }
            }
          } else {
            // Old invite flow
            // TODO: DELETE when we think all invites are used up
            setCurrentWindow(CREATE_PASSWORD)
            setErrorText('')
          }
        } else {
          // this user will not be able to log in
          // because they dont exist
          setErrorText(`You must be registered to log in to LeaseUp.
          Please reach out to your broker to ensure that this email has been registered.`)
        }
      })
      .finally(() => {
        setCheckingIfUserExists(false)
      })
  }

  // If we start the page with an email, check if the user exists immediately
  useEffect(() => {
    if (urlParamEmail) {
      checkIfUserExists()
    }
  }, [urlParamEmail])

  const handleLogin = () => {
    setErrorText('')

    Api.login({
      body: {
        username: email,
        email,
        password,
      },
    }).then((res) => {
      if (res.access && res.refresh) {
        setAuthLocalStorage(res.access, res.refresh, res.skin, true)
        posthog.identify(email, { email })
      } else if (
        res.detail === 'No active account found with the given credentials' ||
        res.detail === 'User cannot access this sub-domain!' ||
        res.detail === 'Unauthorized sub-domain'
      ) {
        setErrorText('Incorrect Email or Password.')
      } else {
        // Better than clicking login and seeing nothing...
        setErrorText('Login Failed')
      }
    })
  }

  const sendMagicLinkEmail = () => {
    setErrorText('')
    setRequestingMagicLinkEmail(true)
    return Api.sendMagicLinkEmail({
      body: {
        email,
        redirect: redirectParam,
        zendesk_redirect: zendeskRedirect,
        color: theme.palette.primary.main,
        color_hover: theme.palette.primary.dark,
        logo_url: logoUrl,
      },
    })
      .then((resp) => {
        if (resp.api_error) {
          setErrorText('There was an issue sending the email, please try again')
        } else {
          setCurrentWindow(MAGIC_LINK_SENT)
        }
      })
      .finally(() => setRequestingMagicLinkEmail(false))
  }

  const handleRegister = () => {
    Api.registerUser({
      body: {
        username: email,
        email,
        password,
        password2: password,
      },
    })
  }

  const requestForgotPasswordEmail = () => {
    if (isEmailAddress(email)) {
      setErrorText('')
      Api.resetPasswordEmail({
        body: {
          email,
          color: theme.palette.primary.main,
          color_hover: theme.palette.primary.dark,
          logo_url: logoUrl,
        },
      }).then(() => setCurrentWindow(RESET_PASSWORD_SENT))
    } else {
      setErrorText('Entry is not a valid email address.')
    }
  }

  const ForgotPasswordLink = () => (
    <TextLink onClick={() => setCurrentWindow(FORGOT_PASSWORD)}>
      Forgot your password?
    </TextLink>
  )
  const BackToLoginLink = () => (
    <TextLink onClick={() => setCurrentWindow(ENTER_USER)}>
      Go back to Login
    </TextLink>
  )

  return (
    <div className={classes.modalContent}>
      {allowClose && (
        <ModalTitle
          classNames={{
            container: classes.modalTitle,
          }}
          title=""
          onClose={onClose}
        />
      )}
      {currentWindow === ENTER_USER && (
        <div className={classes.logoContainer}>
          <Logo className={classes.logo} forLogin />
        </div>
      )}
      <Hidden xlDown={currentWindow !== ENTER_USER}>
        <div className={classes.content}>
          <Typography className={classes.subTitle} variant="body1">
            Enter your email to continue
          </Typography>
          <TextInput
            autoFocus
            className={classes.input}
            errorText={errorText}
            label="Email"
            onChange={(event) => setEmail(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                checkIfUserExists()
              }
            }}
            type="username"
            name="username"
            value={email}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
          />
          <Button
            className={classes.button}
            color="primary"
            onClick={checkIfUserExists}
            disabled={checkingIfUserExists}
            loading={checkingIfUserExists}
          >
            Continue
          </Button>
          <ForgotPasswordLink />
        </div>
      </Hidden>
      <Hidden xlDown={currentWindow !== ENTER_PASSWORD}>
        <Slide
          direction="left"
          in={currentWindow === ENTER_PASSWORD}
          mountOnEnter
        >
          <div className={classes.content}>
            <Typography align="center" variant="h2">
              Welcome back
            </Typography>
            <Typography
              align="center"
              className={classes.subTitle}
              variant="body1"
            >
              Enter your password to sign in
            </Typography>
            <TextInput
              autoFocus
              className={classes.input}
              errorText={errorText}
              label="Password"
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  handleLogin()
                }
              }}
              type="password"
              value={password}
            />
            <Button
              className={classes.button}
              color="primary"
              onClick={handleLogin}
            >
              Sign In
            </Button>
            <ForgotPasswordLink />
          </div>
        </Slide>
      </Hidden>
      <Hidden xlDown={currentWindow !== CREATE_PASSWORD}>
        <Slide
          direction="left"
          in={currentWindow === CREATE_PASSWORD}
          mountOnEnter
        >
          <div className={classes.content}>
            <Typography
              align="center"
              className={classes.subTitle}
              variant="body1"
            >
              Please create a password to continue.
            </Typography>
            <TextInput
              autoFocus
              className={classes.input}
              errorText={errorText}
              label="Password"
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  handleRegister()
                }
              }}
              type="password"
              value={password}
            />
            <Button
              className={classes.button}
              color="primary"
              onClick={handleRegister}
            >
              Sign In
            </Button>
            <ForgotPasswordLink />
          </div>
        </Slide>
      </Hidden>
      <Hidden xlDown={currentWindow !== REQUEST_MAGIC_LINK}>
        <Slide
          direction="left"
          in={currentWindow === REQUEST_MAGIC_LINK}
          mountOnEnter
        >
          <div className={classes.content}>
            <div className={classes.subTitle}>
              <Typography align="center" variant="h2">
                {firstTimeUser ? 'Welcome to LeaseUp' : 'Welcome back'}
              </Typography>
              <Typography
                align="center"
                variant="body1"
                className={classes.subText}
              >
                {`Click below to request a magic link to ${email} to log in`}
              </Typography>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                color="primary"
                disabled={requestingMagicLinkEmail}
                onClick={() => {
                  sendMagicLinkEmail()
                }}
              >
                Send magic link
              </Button>
              <Loading
                size={24}
                isLoading={requestingMagicLinkEmail}
                className={classes.buttonProgress}
              />
            </div>
            <div>
              <Typography
                align="center"
                variant="body2"
                className={classes.error}
              >
                {errorText}
              </Typography>
            </div>
          </div>
        </Slide>
      </Hidden>
      <Hidden xlDown={currentWindow !== MAGIC_LINK_SENT}>
        <Slide
          direction="left"
          in={currentWindow === MAGIC_LINK_SENT}
          mountOnEnter
        >
          <div className={classes.content}>
            <EmailIcon color="primary" className={classes.emailIcon} />
            <div className={classes.subTitle}>
              <Typography align="center" variant="h2">
                Check your email
              </Typography>
              <Typography
                align="center"
                variant="body1"
                className={classes.subTitle}
              >
                {`We sent an email to you at ${email}. It has a magic link that'll log you in.`}
              </Typography>
            </div>
          </div>
        </Slide>
      </Hidden>
      <Hidden xlDown={currentWindow !== FORGOT_PASSWORD}>
        <Slide
          direction="left"
          in={currentWindow === FORGOT_PASSWORD}
          mountOnEnter
        >
          <div className={classes.content}>
            <Typography align="center" variant="h2">
              Forgot Password?
            </Typography>
            <Typography
              align="center"
              variant="body1"
              className={classes.subTitle}
            >
              Enter your email to reset password
            </Typography>
            <TextInput
              autoFocus
              className={classes.input}
              errorText={errorText}
              label="Email"
              onChange={(event) => setEmail(event.target.value)}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  requestForgotPasswordEmail()
                }
              }}
              type="username"
              name="username"
              value={email}
            />
            <Button
              className={classes.button}
              color="primary"
              onClick={requestForgotPasswordEmail}
            >
              Send Email
            </Button>
            <BackToLoginLink />
          </div>
        </Slide>
      </Hidden>
      <Hidden xlDown={currentWindow !== RESET_PASSWORD_SENT}>
        <Slide
          direction="left"
          in={currentWindow === RESET_PASSWORD_SENT}
          mountOnEnter
        >
          <div className={classes.content}>
            <Typography align="center" variant="h2">
              Reset Password Sent
            </Typography>
            <Typography
              align="center"
              variant="body1"
              className={classes.subTitle}
            >
              If a LeaseUp account for this email exists, we’ll send you an
              email with a link to reset your password.
            </Typography>
            <BackToLoginLink />
          </div>
        </Slide>
      </Hidden>
      <Hidden xlDown={currentWindow !== CREATE_PASSWORD_SENT}>
        <Slide
          direction="left"
          in={currentWindow === CREATE_PASSWORD_SENT}
          mountOnEnter
        >
          <div className={classes.content}>
            <Typography align="center" variant="h2">
              Create Password Sent
            </Typography>
            <Typography
              align="center"
              variant="body1"
              className={classes.subTitle}
            >
              If a LeaseUp account for this email exists, we’ll send you an
              email with a link to create your password.
            </Typography>
            <BackToLoginLink />
          </div>
        </Slide>
      </Hidden>
    </div>
  )
}

export default LoginModal
