import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { LEASE_CREATE_LISTING } from '~/legacy/consts';
import { Button } from '~/legacy/components';
import { ModalTitle } from '~/legacy/components/modals';
import ClientIcon from './ClientIcon';
import ListingIcon from './ListingIcon';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    backgroundColor: 'white',
    paddingBottom: 10,
    outline: 'none',
    width: 600,
    overflowY: 'hidden',
  },
  iconContainer: {
    width: 64,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionsContainer: {
    '&:last-child': {
      // TODO: figure out last child selectors
      marginBottom: -30,
    },
  },
  optionContainer: {
    display: 'flex',
    marginBottom: 30,
  },
  optionTextContainer: {
    marginLeft: 50,
    marginRight: 20,
  },
}));

const options = [
  {
    icon: ListingIcon,
    header: 'Listing',
    text: 'Add a listing to your account. You’ll have the option to bulk upload or create a single listing.',
    path: LEASE_CREATE_LISTING,
  },
  {
    icon: ClientIcon,
    header: 'Survey',
    modalKey: 'newSurvey',
    text: "Add a new survey to your account. You'll have the option to add spaces and share with other brokers and clients.",
  },
];

function CreateModal({ onClose, setModalContent }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.content} tabIndex={-1}>
      <ModalTitle onClose={onClose} title="Add a new item to your account" />
      <div className={classes.optionsContainer}>
        {options.map((option) => {
          const Icon = option.icon;
          return (
            <div className={classes.optionContainer} key={option.header}>
              <div className={classes.iconContainer}>
                <Icon />
              </div>
              <div className={classes.optionTextContainer}>
                <Typography variant="h2">{option.header}</Typography>
                <Typography variant="body1">{option.text}</Typography>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  color="primary"
                  onClick={() => {
                    if (option.path) {
                      history.push(option.path);
                      onClose();
                    } else if (option.modalKey) {
                      setModalContent(option.modalKey);
                    }
                  }}
                  variant="outlined"
                >
                  Add
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CreateModal;
