import { createContext, createElement, useContext, useEffect } from 'react'
import { createEventStore, createInMemoryConnection } from './eventstore'

const connection = createInMemoryConnection()
const eventStore = createEventStore(connection)
const context = createContext(eventStore)

export const useEventStore = () => {
  return useContext(context)
}

export const useEvents = (subject, callback) => {
  useEffect(() => {
    const unsubscribe = subject.subscribe((events) => {
      localStorage.setItem('events.sync', JSON.stringify(events))
      events.forEach(callback)
    })

    const sync = (event) => {
      if (event.key === 'events.sync') {
        JSON.parse(event.newValue).forEach(callback)
      }
    }

    window.addEventListener('storage', sync)

    return () => {
      window.removeEventListener('storage', sync)
      unsubscribe()
    }
  }, [subject, callback])
}

export const EventStoreProvider = ({ children, store }) => {
  return createElement(context.Provider, { value: store }, children)
}
