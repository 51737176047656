import { CardMedia } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  LeftChevronIcon,
  RightChevronIcon,
  TextButton,
  TextLink,
  Typography,
} from '~/legacy/components'
import { ContentModal } from '~/legacy/components/modals'
import { updateUserData } from '~/legacy/store'
import { openLink } from '~/legacy/utils/openLink'

const slideStyles = {
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
  },
  video: {
    borderRadius: '4px',
    marginTop: 'auto',
    height: '438px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}

export const PaginationCircle = withStyles((theme) => ({
  root: {
    cursor: 'pointer',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    backgroundColor: '#E0E0E0',
    '&:not(:first-child)': {
      marginLeft: '8px',
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
}))(({ classes, swiperRef, pageNum, selected }) => {
  return (
    <div
      className={clsx(classes.root, selected ? classes.selected : '')}
      onClick={() => swiperRef.current.swiper.slideTo(pageNum)}
    />
  )
})

const VideoSlideContent = withStyles(slideStyles)(
  ({ classes, text, videoName, loop, showing }) => {
    const [imageError, setImageError] = useState(false)

    // Reset the video when the slide is navigated to
    const videoRef = useRef(null)
    useEffect(() => {
      if (showing && videoRef && videoRef.current) {
        videoRef.current.currentTime = 0
      }
    }, [showing])

    return (
      <div className={classes.root}>
        <Typography className={classes.text}>{text}</Typography>
        {!imageError ? (
          <CardMedia
            component="video"
            autoPlay
            loop={loop}
            muted
            className={classes.video}
            ref={videoRef}
          >
            <source
              src={`https://leaseup-production.s3.us-east-2.amazonaws.com/video/${videoName}.webm`}
              type="video/webm"
            />
            <source
              src={`https://leaseup-production.s3.us-east-2.amazonaws.com/video/${videoName}.mp4`}
              type="video/mp4"
              onError={() => setImageError(true)}
            />
          </CardMedia>
        ) : (
          <Typography className={classes.video}>
            Sorry, your browser does not support embedded videos.
          </Typography>
        )}
      </div>
    )
  }
)

const SLIDES = [
  {
    id: 0,
    text: 'We’re launching a new building page that organizes all of your building information into one screen. This simplified workflow makes it easier to see all available spaces and space data, edit multiple spaces at once, and consolidate comments and feedback for a building in one place.',
    videoName: 'bdp',
    loop: false,
    title: 'One place for building data',
  },
  {
    id: 1,
    text: 'The new building page is organized with clients in mind, with separate sections for spaces, comments, building data, and a new map with street view. Coming soon, a section for attachments, and a way to control how your building address is displayed and appears on the map.',
    videoName: 'page_sections',
    loop: true,
    title: 'Improved layout and organization',
  },
  {
    id: 2,
    text: (
      <Typography component="span">
        This is the beginning of a reimagined product experience for you and
        your clients, which we’ll roll out soon.
        <TextLink
          TypographyProps={{ component: 'span' }}
          onClick={openLink('https://leaseup.co/demo')}
        >
          Visit our demo page
        </TextLink>
        to see the full demo, and reach out if you’d like to learn more about
        this or any of the other features we’re working on.
      </Typography>
    ),
    videoName: 'screen_carousel',
    loop: true,
    title: 'See what’s coming next',
  },
]

export const FtuxBdpModal = withStyles({
  dialogContent: {
    '&:first-child': {
      padding: '48px 48px 0 48px',
    },
    width: '768px',
    height: '688px',
  },
  modalTitleContainer: {
    marginBottom: '12px',
  },
  swiper: {
    width: '100%',
    height: '100%',
    '&> div': {
      alignItems: 'center',
    },
  },
  circles: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
  buttonStartIcon: {
    marginRight: 0,
  },
  buttonEndIcon: {
    marginLeft: 0,
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '72px',
    minHeight: '72px',
  },
  prevButtonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'start',
  },
  nextButtonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'end',
  },
})(({ classes, showFtuxBdpModal = false }) => {
  const user = useSelector((s) => s.user)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(showFtuxBdpModal)
  const [title, setTitle] = useState(SLIDES[0].title)
  const [swiperActiveIndex, setSwiperActiveIndex] = useState(0)
  const swiperRef = useRef(null)

  useEffect(() => setOpen(showFtuxBdpModal), [showFtuxBdpModal])
  // Change the title when changing the index
  useEffect(() => {
    setTitle(SLIDES.find((slide) => slide.id === swiperActiveIndex).title)
  }, [swiperActiveIndex])

  // Pagination and which buttons to show / hide
  let isEnd = false
  let isBeginning = true
  if (!swiperActiveIndex) {
    isEnd = false
    isBeginning = true
  } else if (swiperActiveIndex === SLIDES.length - 1) {
    isEnd = true
    isBeginning = false
  } else {
    isEnd = false
    isBeginning = false
  }

  // Close the modal, update the local user data, and then update via api
  const onCloseModal = () => {
    setOpen(false)
    dispatch(updateUserData({ showFtuxBdpModal: false }))
    Api.saveAccountSettings({
      id: user.id,
      body: {
        show_ftux_bdp_modal: false,
      },
    })
  }

  return (
    <ContentModal
      title={title}
      onClose={onCloseModal}
      ModalComponentProps={{
        open,
        onClose: onCloseModal,
        classes: { dialogContent: classes.dialogContent },
      }}
      ConfirmModalTitleProps={{
        classNames: { container: classes.modalTitleContainer },
      }}
    >
      <Swiper
        ref={swiperRef}
        allowTouchMove={false}
        observer
        observeParents
        spaceBetween={30}
        slidesPerView={1}
        modules={[Navigation]}
        className={classes.swiper}
        onRealIndexChange={(swiper) => setSwiperActiveIndex(swiper.activeIndex)}
      >
        {SLIDES.map((slide) => (
          <SwiperSlide key={slide.id}>
            <VideoSlideContent
              text={slide.text}
              videoName={slide.videoName}
              loop={slide.loop}
              showing={slide.id === swiperActiveIndex}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={classes.actionRow}>
        <div className={classes.prevButtonContainer}>
          {!isBeginning && (
            <TextButton
              onClick={() => swiperRef.current.swiper.slidePrev()}
              startIcon={<LeftChevronIcon />}
              classes={{ startIcon: classes.buttonStartIcon }}
            >
              <Typography variant="boldText">Previous</Typography>
            </TextButton>
          )}
        </div>
        <div className={classes.circles}>
          {SLIDES.map((slide) => (
            <PaginationCircle
              key={slide.id}
              swiperRef={swiperRef}
              pageNum={slide.id}
              selected={swiperActiveIndex === slide.id}
            />
          ))}
        </div>
        <div className={classes.nextButtonContainer}>
          <TextButton
            onClick={() => {
              if (isEnd) {
                onCloseModal()
              } else {
                swiperRef.current.swiper.slideNext()
              }
            }}
            endIcon={<RightChevronIcon />}
            classes={{ endIcon: classes.buttonEndIcon }}
          >
            <Typography variant="boldText">
              {isEnd ? 'Finish' : 'Next'}
            </Typography>
          </TextButton>
        </div>
      </div>
    </ContentModal>
  )
})
