import get from 'lodash/get'
import has from 'lodash/has'
import set from 'lodash/set'
import { valueOf } from './valueOf'

export const createData = (data = {}) => {
  const object = {
    data,

    get: (key, value) => {
      return valueOf(get(data, key, value))
    },

    has: (key) => {
      return has(data, key)
    },

    mapTo: (key, callback) => {
      const value = object.get(key, []).map(callback)
      return object.set(key, value)
    },

    select: (key, values) => {
      return valueOf(values[object.get(key)] || values.default)
    },

    set: (key, value) => {
      const result = valueOf(value)
      set(data, key, result)
      return result
    },

    setTo: (key, value) => {
      if (object.has(key)) return object.get(key)
      return object.set(key, valueOf(value))
    },

    unless: (key, a, b) => {
      return object.when(key, b, a)
    },

    when: (key, a, b) => {
      return valueOf(object.get(key) ? a : b)
    },
  }

  return object
}
