import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { actionLocationChanged } from '~/legacy/store';
import { LEASE_LOGIN, LEASE_CREATE_PASSWORD } from '~/legacy/consts';
import { useTrackPageView } from '~/legacy/utils/hooks';
import { isBrokerAdminByTypeAndRole, isBrokerUserType } from '~/legacy/utils';

function PrivateRoute({
  allowAnonymous,
  allowMobile,
  location,
  path,
  computedMatch,
  trackingPageViewName,
  requireAdmin = false,
  ...props
}) {
  const user = useSelector((store) => store.user);
  const loggedIn = useSelector((store) => store.user.loggedIn);
  const setRedirectUrl = useSelector((store) => store.user.setRedirectUrl);
  const lastLocation = useSelector((store) => store.misc.location);
  const dispatch = useDispatch();

  if (!lastLocation || lastLocation.pathname !== location.pathname) {
    dispatch(actionLocationChanged(location));
  }

  const metaViewport = document.getElementsByName('viewport')[0];
  if (allowMobile) {
    metaViewport.content =
      'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no';
  } else {
    metaViewport.content = 'width=device-width, initial-scale=0';
  }

  useTrackPageView({
    eventName: trackingPageViewName,
    url: location.pathname,
    path,
    computedMatch,
  });

  const waitForFirmAdminData =
    !allowAnonymous && loggedIn && requireAdmin && !user.id;
  const waitForUnconfirmedBrokerData = !allowAnonymous && loggedIn && !user.id;

  // If we're requiring the user be a firm admin, but the user role hasn't loaded yet, wait
  if (waitForFirmAdminData || waitForUnconfirmedBrokerData) {
    return null;
  }

  let redirectUrl = LEASE_LOGIN;
  let setRedirectUrlLocal = setRedirectUrl;

  // If we have an unconfirmed user, they can only access create password flow basically. This will confirm the user
  if (
    loggedIn &&
    isBrokerUserType(user.userType) &&
    !user.confirmed &&
    !location.pathname.includes('reset_password') &&
    !location.pathname.includes('create_password')
  ) {
    redirectUrl = LEASE_CREATE_PASSWORD;
    setRedirectUrlLocal = false;
  } else {
    const canAccessPageByRole =
      allowAnonymous ||
      (loggedIn &&
        (!requireAdmin ||
          isBrokerAdminByTypeAndRole(user.userType, user.user_role)));

    if (canAccessPageByRole) {
      return <Route path={path} computedMatch={computedMatch} {...props} />;
    }
  }

  // If there's a redirect URL, attach it to the login URL so that the user is redirected after login
  if (setRedirectUrlLocal) {
    redirectUrl = `${LEASE_LOGIN}?redirect=${encodeURIComponent(
      lastLocation.pathname + lastLocation.search
    )}`;
  }

  return <Redirect to={redirectUrl} />;
}

export default memo(PrivateRoute);
