import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  DeleteIcon,
  Typography,
  MenuItem,
  ConfirmModalComponent,
} from '~/legacy/components';
import { SnackbarUtils } from '~/legacy/utils';
import { LEASE_PROJECTS } from '~/legacy/consts';
import { BASE_ICON_STYLES } from '~/legacy/components/buttons/ButtonUtils';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
  textInputFieldContainer: {
    width: '100%',
    marginTop: '32px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const DeleteProjectMenuItemModal = ({
  project,
  deleteProject,
  open,
  onClose,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    onClose();
  };

  // Update the project
  const updateProjectApi = async () => {
    setIsLoading(true);

    try {
      await deleteProject();
      SnackbarUtils.success('Project Successfully Deleted.');
      history.push(LEASE_PROJECTS);
      closeModal();
    } catch {
      // supress
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmModalComponent
      ModalComponentProps={{
        open: !!open,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onConfirm={updateProjectApi}
      title="Delete Project"
      text={`Are you sure you want to delete the ${project.name} project? This action can’t be undone.`}
      confirmButtonLabel="Delete"
      loading={isLoading}
    />
  );
};

// Menu item to edit a project
export const DeleteProjectMenuItem = React.forwardRef(
  ({ openModal, ...props }, ref) => {
    const classes = useStyles();

    return [
      <MenuItem
        key="delete-project-menu-item"
        ref={ref}
        className={classes.menuItemRoot}
        onClick={openModal}
        {...props}
      >
        <DeleteIcon className={classes.icon} />
        <Typography className={classes.text}> Delete Project </Typography>
      </MenuItem>,
    ];
  }
);

// Bundle the modal and the menu item together for maximum convenience
export const useDeleteProjectMenuItem = ({
  project,
  deleteProject,
  handleMenuClose,
}) => {
  const [open, setOpen] = React.useState(false);

  const DeleteProjectMenuItemComponent = (
    <DeleteProjectMenuItem openModal={() => setOpen(true)} />
  );

  const DeleteProjectMenuItemModalComponent = (
    <DeleteProjectMenuItemModal
      key="delete-project-modal"
      project={project}
      deleteProject={deleteProject}
      open={open}
      onClose={() => {
        setOpen(false);
        handleMenuClose();
      }}
    />
  );

  return {
    DeleteProjectMenuItemComponent,
    DeleteProjectMenuItemModalComponent,
  };
};
