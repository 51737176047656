import {
  createContext,
  createElement,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { createDispatcher } from './messaging'

const dispatcher = createDispatcher()
const context = createContext(dispatcher)

export const useDispatcher = () => {
  return useContext(context)
}

export const useChannel = (channel, subscribe, callback) => {
  useEffect(() => {
    const unsubscribe = subscribe((message) => {
      localStorage.setItem(channel, JSON.stringify(message))
      callback(message)
    })

    const sync = (event) => {
      if (event.key === channel) {
        callback(JSON.parse(event.newValue))
      }
    }

    window.addEventListener('storage', sync)

    return () => {
      window.removeEventListener('storage', sync)
      unsubscribe()
    }
  }, [channel, subscribe, callback])
}

export const useMessage = (type, callback) => {
  useMessages((message) => {
    if (message.type === type) {
      callback(message.data)
    }
  })
}

export const useMessages = (callback) => {
  const dispatcher = useDispatcher()
  useEffect(() => dispatcher.subscribe(callback))
}

export const DispatcherProvider = ({ children }) => {
  const value = useMemo(() => createDispatcher(), [])
  return createElement(context.Provider, { value }, children)
}
