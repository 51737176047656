import React from 'react';
import { MenuItem as MuiMenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TooltipV2 } from '~/legacy/components';

const styles = {
  menuItemRoot: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: '24px',
    paddingLeft: '12px',
    '&:hover': {
      backgroundColor: '#F3F3F3',
    },
  },
};

const MenuItem = React.forwardRef(
  ({ children, tooltip, classes = {}, ...props }, ref) => {
    const MenuComponent = (
      <MuiMenuItem ref={ref} {...props} className={classes.menuItemRoot}>
        {children}
      </MuiMenuItem>
    );

    return tooltip ? (
      <TooltipV2
        title={tooltip}
        PopperProps={{
          style: { pointerEvents: 'none', marginBottom: '-10px' },
        }}
        arrow
      >
        <div>{MenuComponent}</div>
      </TooltipV2>
    ) : (
      MenuComponent
    );
  }
);

const MenuItemStyled = withStyles(styles)(MenuItem);

export default MenuItemStyled;
