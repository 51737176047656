import React from 'react'
import { styled } from '~/legacy/utils/styleHelpers'

export const ActivityMessage = (props) => {
  const { avatar, children, subtitle, title, text } = props
  return (
    <Container>
      {avatar || (
        <Avatar>
          <svg position="center" width="18" height="20" viewBox="0 0 18 20">
            <path
              d="M16.772 12.912C16.32 12.641 15 12.073 15 10.853V7.502C15 4.443 12.709 1.924 9.75 1.554V0H8.25V1.554C5.291 1.924 3 4.443 3 7.502V10.853C3 12.072 1.68 12.641 1.228 12.912C0.776 13.183 0.5 13.672 0.5 14.199V15.35C0.5 16.178 1.172 16.85 2 16.85H6C6 18.504 7.346 19.85 9 19.85C10.654 19.85 12 18.504 12 16.85H16C16.828 16.85 17.5 16.178 17.5 15.35V14.199C17.5 13.672 17.224 13.183 16.772 12.912ZM9 18.349C8.172 18.349 7.5 17.677 7.5 16.849H10.5C10.5 17.678 9.828 18.349 9 18.349ZM16 15.349H2V14.198C2.04 14.174 2.089 14.147 2.144 14.117C2.788 13.761 4.5 12.815 4.5 10.853V7.502C4.5 5.021 6.519 3.002 9 3.002C11.481 3.002 13.5 5.021 13.5 7.502V10.853C13.5 12.815 15.212 13.761 15.856 14.117C15.911 14.148 15.96 14.174 16 14.198V15.349Z"
              fill="#111111"
            />
          </svg>
        </Avatar>
      )}
      <Content>
        <div>
          <Headline>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Headline>
          {text && <Text>{text}</Text>}
        </div>
        {children}
      </Content>
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
  gap: '18px',
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '22px',
  letterSpacing: '0.1px',
  color: '#111',
  '& + &': {
    marginTop: '30px',
  },
})

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  flex: 1,
})

const Headline = styled('div', {
  display: 'flex',
  gap: '12px',
})

const Avatar = styled('div', {
  backgroundColor: 'rgba(30, 132, 122, 0.1)',
  borderRadius: '100%',
  height: '32px',
  outline: '1px solid #e0e0e0',
  outlineOffset: '-1px',
  width: '32px',
  minWidth: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const Text = styled('div', {
  marginTop: '2px',
})

const Title = styled('div', {
  fontWeight: 600,
})

const Subtitle = styled('div', {
  color: '#666',
})
