import React, { useRef, useState } from 'react';
import { InputAdornment, Typography, makeStyles } from '@material-ui/core';
import {
  SquareCheckbox,
  TextInput,
  ExportSurveySettingsAndLayout,
  UploadIcon,
  ClearTextInputSvg,
  Loading,
} from '~/legacy/components';
import { userTypes } from '~/legacy/consts';
import { uploadFile } from '~/legacy/utils';

const useStyles = makeStyles({
  brokerCheckbox: {
    margin: '-3px 12px 0 0',
    color: '#111111',
    alignSelf: 'flex-start',
  },
  brokersContainer: {
    marginTop: '30px',
    flex: 1,
    overflow: 'auto',
  },
  addBrokersTitle: {
    color: '#666666',
  },
  inputLabel: {
    fontFamily: ['Inter-Medium'],
    letterSpacing: '.2px',
  },
  brokerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '16px 0',
    borderBottom: '1px solid #e0e0e0',
    cursor: (props) => (props.replacementImage.id ? 'default' : 'pointer'),
    overflow: 'hidden',
  },
  brokerText: {
    display: 'flex',
    flexDirection: 'column',
    color: (props) => (props.replacementImage.id ? '#808080' : '#111111'),
  },
  replacementImageInput: {
    marginTop: '32px',
  },
  uploadIcon: {
    cursor: 'pointer',
  },
  closeIcon: {
    cursor: 'pointer',
    color: '#e0e0e0',
    height: '14px',
    width: '14px',
  },
});

const layoutThumbs = [
  'https://leaseup-development.s3.us-east-2.amazonaws.com/TitlePage_Template1.png',
  'https://leaseup-development.s3.us-east-2.amazonaws.com/TitlePage_Template2.png',
  'https://leaseup-development.s3.us-east-2.amazonaws.com/TitlePage_Template3.png',
];

function CoverPageSettings(props) {
  const { settings, updateSettings, survey } = props;
  const { subtitle, replacementImage } = settings;
  const hiddenReplacementImageInputRef = useRef(null);

  const brokersList = [survey.owner].concat(
    survey.users
      ? survey.users.filter(
          (u) =>
            u.user_type === userTypes.tenantBroker && u.id !== survey.owner.id
        )
      : []
  );
  const classes = useStyles({ replacementImage });
  const [selectedBrokers, setSelectedBrokers] = useState(
    settings.brokers.map((broker) => broker.id)
  );
  const [loadingReplacementImage, setLoadingReplacementImage] = useState(false);

  const updateSubtitle = (newSubtitle) =>
    updateSettings({
      ...settings,
      subtitle: newSubtitle,
    });

  const toggleBrokerSelected = (broker) => {
    const brokerAlreadySelected = selectedBrokers.includes(broker.id);
    let newSelectedBrokers = [];
    if (brokerAlreadySelected) {
      newSelectedBrokers = selectedBrokers.filter((id) => id !== broker.id);
      setSelectedBrokers(newSelectedBrokers);
    } else {
      newSelectedBrokers = selectedBrokers.concat(broker.id);
      setSelectedBrokers(newSelectedBrokers);
    }

    updateSettings({
      ...settings,
      // this isn't the most performant but the lists should be small
      // alternative is to make a brokers map
      brokers: newSelectedBrokers.map((id) =>
        brokersList.find((b) => id === b.id)
      ),
    });
  };

  const updateReplacementImage = (newImage) =>
    updateSettings({
      ...settings,
      replacementImage: newImage,
    });

  return (
    <ExportSurveySettingsAndLayout layoutThumbs={layoutThumbs} {...props}>
      <TextInput
        label="Subtitle"
        placeholder="Add a subtitle"
        onChange={(event) => updateSubtitle(event.target.value)}
        onKeyUp={(event) => {
          if (event.key === 'Enter') updateSubtitle(event.target.value);
        }}
        value={subtitle}
        variant="standard"
        disabled={!!replacementImage.id}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.inputLabel,
          },
        }}
        InputProps={{
          endAdornment: subtitle && (
            <ClearTextInputSvg
              className={classes.closeIcon}
              onClick={(e) => {
                e.stopPropagation();
                updateSubtitle('');
              }}
            />
          ),
        }}
      />
      <div className={classes.brokersContainer}>
        <Typography className={classes.addBrokersTitle} variant="h5">
          Add Brokers
        </Typography>
        {brokersList.map((broker) => (
          <div
            key={broker.id}
            onClick={() => !replacementImage.id && toggleBrokerSelected(broker)}
            className={classes.brokerContainer}
          >
            <SquareCheckbox
              className={classes.brokerCheckbox}
              disabled={
                (selectedBrokers.length === 3 &&
                  !selectedBrokers.includes(broker.id)) ||
                !!replacementImage.id
              }
              checked={selectedBrokers.includes(broker.id)}
              onClick={() =>
                !replacementImage.id && toggleBrokerSelected(broker)
              }
            />
            <div className={classes.brokerText}>
              <Typography variant="h3">
                {`${broker.first_name} ${broker.last_name}`}
              </Typography>
              <Typography variant="body1">{broker.email}</Typography>
            </div>
          </div>
        ))}
      </div>
      <TextInput
        className={classes.replacementImageInput}
        label="Replace Cover With Image"
        placeholder={loadingReplacementImage ? '' : 'Upload Image'}
        onChange={() => {}}
        onClick={() => hiddenReplacementImageInputRef.current.click()}
        value={loadingReplacementImage ? '' : replacementImage.name}
        variant="standard"
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.inputLabel,
          },
        }}
        InputProps={{
          readOnly: true,
          startAdornment: loadingReplacementImage && (
            <InputAdornment position="start">
              <Loading size={16} isLoading />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {!replacementImage.id && (
                <UploadIcon
                  className={classes.uploadIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    hiddenReplacementImageInputRef.current.click();
                  }}
                />
              )}
              {replacementImage.id && (
                <ClearTextInputSvg
                  className={classes.closeIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    updateReplacementImage({});
                  }}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      <input
        ref={hiddenReplacementImageInputRef}
        accept="image/jpeg,image/png,image/webp"
        hidden
        id="file-input"
        onChange={(event) => {
          setLoadingReplacementImage(true);
          const file = event.target.files[0];
          /* eslint-disable no-param-reassign */
          event.target.value = '';
          uploadFile(file, 'survey_export_custom_cover_photos', {
            survey_id: survey.id,
          }).then((res) => {
            updateReplacementImage(res.data.file);
            setLoadingReplacementImage(false);
          });
        }}
        type="file"
      />
    </ExportSurveySettingsAndLayout>
  );
}

export default CoverPageSettings;
