import {
  Container,
  lighten,
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core'
import { GoogleMap, Marker } from '@react-google-maps/api'
import clsx from 'clsx'
import lodash from 'lodash'
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import React, { useCallback, useMemo, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useSelector } from 'react-redux'
// import { useHistory } from 'react-router-dom'

import {
  ActivityProvider,
  AnonymousActivityCta,
  BuildingActivityFeed,
  BuildingMapModal,
  CardImage,
  CompareSpacesButton,
  EditBuildingModal,
  EditIcon,
  EmptyCardsViewIcon,
  FavoriteBuildingButton,
  ImageGalleryModal,
  LeftChevronIcon,
  // FtuxBdpModal,
  // FtuxBdpBanner,
  Loading,
  MapMarkerIcon,
  Modal,
  MODALS,
  MoreIcon,
  NotInterestedBuildingButton,
  PhotosButton,
  PreviewIcon,
  RightChevronIcon,
  SquareIconButton,
  SurveyBreadcrumb,
  TenantPreviewNavBar,
  TextChip,
  Typography,
  useBdpBuildingMenu,
  WalkScores,
} from '~/legacy/components'
import { roadMapStyles } from '~/legacy/components/Map/GoogleMaps/style'
import { IMAGE_UPLOAD_MODAL_TAB_KEYS } from '~/legacy/components/modals'
import { BUILDING_INTEREST } from '~/legacy/consts'
import {
  getBuildingPrimaryName,
  getBuildingSecondaryName,
  getBuildingTertiaryName,
  getViewBuildingRoute,
  getViewSurveyRoute,
  isBroker,
  IsPreviewContext,
  mod,
  useSurveyBuildingSelector,
  useSurveySelector,
} from '~/legacy/utils'
import { useBuildingDetailPageLayout } from '~/legacy/utils/hooks'
import { px } from '~/legacy/utils/layoutUtils'
import AttachmentsSection from './AttachmentsSection'
import SpacesSection from './SpacesSection'

const LEFT_CONTENT_BIGGEST_PX = 416
const LEFT_CONTENT_SECOND_BIGGEST_PX = 454
const LEFT_CONTENT_THIRD_BIGGEST_PX = 337

const RIGHT_CONTENT_BIGGEST_PX = 1104
const RIGHT_CONTENT_SECOND_BIGGEST_PX = 724
const RIGHT_CONTENT_THIRD_BIGGEST_PX = 584
const RIGHT_CONTENT_FOURTH_BIGGEST_PX = LEFT_CONTENT_SECOND_BIGGEST_PX
const RIGHT_CONTENT_FIFTH_BIGGEST_PX = LEFT_CONTENT_THIRD_BIGGEST_PX

const DIVIDER_SECOND_BIGGEST_PX = 100
const DIVIDER_THIRD_BIGGEST_PX = 64
const DIVIDER_FOURTH_BIGGEST_PX = 32

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
  },
  contentRoot: {
    padding: '26px 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  contentRootMaxWidth: {
    maxWidth: 'unset',
  },
  breadcrumbContainer: {
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionsContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '0 16px',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: '#f3f3f3',
    },
  },
  body: {
    display: 'flex',
    flexGrow: 2,
  },
  textRoot: {
    whiteSpace: 'nowrap',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px',
    width: '100%',
    flexGrow: 1,
  },
  headerStage1: {
    width: px(LEFT_CONTENT_BIGGEST_PX + RIGHT_CONTENT_BIGGEST_PX),
  },
  headerStage3: {
    width: px(LEFT_CONTENT_BIGGEST_PX + RIGHT_CONTENT_SECOND_BIGGEST_PX),
  },
  headerStage4: {
    width: px(LEFT_CONTENT_BIGGEST_PX + RIGHT_CONTENT_THIRD_BIGGEST_PX),
  },
  headerStage6: {
    width: px(LEFT_CONTENT_SECOND_BIGGEST_PX + RIGHT_CONTENT_FOURTH_BIGGEST_PX),
  },
  headerStage7: {
    width: px(LEFT_CONTENT_THIRD_BIGGEST_PX + RIGHT_CONTENT_FIFTH_BIGGEST_PX),
  },
  headerStage8: {
    width: '100%',
    minWidth: '1px',
    flexGrow: 1,
  },

  divider: {
    display: 'flex',
  },
  headerDividerStage1: {
    flexGrow: 1,
  },
  headerDividerStage2: {
    minWidth: px(DIVIDER_SECOND_BIGGEST_PX),
  },
  headerDividerStage3: {
    maxWidth: px(DIVIDER_SECOND_BIGGEST_PX),
    flexGrow: 1,
  },
  headerDividerStage4: {
    minWidth: px(DIVIDER_THIRD_BIGGEST_PX),
  },
  headerDividerStage6: {
    maxWidth: px(DIVIDER_THIRD_BIGGEST_PX),
    flexGrow: 1,
  },
  headerDividerStage7: {
    minWidth: px(DIVIDER_FOURTH_BIGGEST_PX),
  },

  contentDividerStage1: {
    width: px(DIVIDER_SECOND_BIGGEST_PX),
    flexGrow: 1,
  },
  contentDividerStage2: {
    minWidth: px(DIVIDER_SECOND_BIGGEST_PX),
  },
  contentDividerStage3: {
    width: px(DIVIDER_SECOND_BIGGEST_PX),
    maxWidth: px(DIVIDER_SECOND_BIGGEST_PX),
    flexGrow: 1,
  },
  contentDividerStage4: {
    minWidth: px(DIVIDER_THIRD_BIGGEST_PX),
  },
  contentDividerStage6: {
    width: px(DIVIDER_FOURTH_BIGGEST_PX),
    maxWidth: px(DIVIDER_THIRD_BIGGEST_PX),
    flexGrow: 1,
  },
  contentDividerStage7: {
    minWidth: px(DIVIDER_FOURTH_BIGGEST_PX),
  },

  leftColumnStage1: {
    width: px(LEFT_CONTENT_BIGGEST_PX),
  },
  leftColumnStage2: {
    width: px(LEFT_CONTENT_BIGGEST_PX),
  },
  leftColumnStage3: {
    width: px(LEFT_CONTENT_BIGGEST_PX),
  },
  leftColumnStage6: {
    minWidth: px(LEFT_CONTENT_SECOND_BIGGEST_PX),
    width: px(LEFT_CONTENT_SECOND_BIGGEST_PX),
  },
  leftColumnStage7: {
    width: px(LEFT_CONTENT_THIRD_BIGGEST_PX),
    flexGrow: 1,
  },
  leftColumnStage8: {
    width: '100%;',
    flexGrow: 1,
  },

  rightColumnStage1: {
    minWidth: px(RIGHT_CONTENT_BIGGEST_PX),
    width: px(RIGHT_CONTENT_BIGGEST_PX),
  },
  rightColumnStage2: {
    width: px(RIGHT_CONTENT_BIGGEST_PX),
    flexGrow: 1,
  },
  rightColumnStage3: {
    minWidth: px(RIGHT_CONTENT_SECOND_BIGGEST_PX),
    width: px(RIGHT_CONTENT_SECOND_BIGGEST_PX),
  },
  rightColumnStage4: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  rightColumnStage6: {
    minWidth: px(RIGHT_CONTENT_FOURTH_BIGGEST_PX),
    width: px(RIGHT_CONTENT_FOURTH_BIGGEST_PX),
  },
  rightColumnStage7: {
    width: px(RIGHT_CONTENT_FIFTH_BIGGEST_PX),
    flexGrow: 1,
  },
  rightColumnStage8: {
    width: '100%;',
    flexGrow: 1,
  },
})

const EMPTY_BUILDING = {}
const EMPTY_SURVEY_BUILDINGS = []

const CommentsSection = withStyles({
  commentsContainer: {
    marginTop: '24px',
  },
})(({ classes, surveyBuilding, surveyId, setShowLoginModal }) => {
  const isAnonymous = useSelector((s) => s.user.isAnonymous)

  return (
    <div>
      <Typography variant="pageSubTitle">Comments</Typography>
      {isAnonymous ? (
        <div className={classes.commentsContainer}>
          <AnonymousActivityCta setModalOpen={setShowLoginModal} />
        </div>
      ) : (
        <ActivityProvider>
          <BuildingActivityFeed
            buildingId={surveyBuilding.building.id}
            surveyId={surveyId}
            surveyBuildingId={surveyBuilding.id}
          />
        </ActivityProvider>
      )}
    </div>
  )
})

const SpacesAttachmentsComments = withStyles({
  rightColumnSection: {
    marginBottom: '56px',
  },
  attachmentsSectionContainer: {
    marginBottom: '56px',
  },
})(
  ({
    classes,
    surveyBuilding,
    buildingId,
    buildingPrimaryName,
    surveyId,
    setShowLoginModal,
  }) => {
    const isUserBroker = useSelector((s) => isBroker(s.user))
    const { isPreview } = React.useContext(IsPreviewContext)
    const isAnonymous = useSelector((s) => s.user.isAnonymous)

    const {
      mutate,
      mutateDeleteSurveyListing: deleteSurveyListing,
      mutateUpdateListing: updateListing,
      mutateCreateListingCustomField: createCustomListingField,
      mutateCreateListingCustomFields: createCustomListingFields,
      mutateUpdateListingCustomField: updateListingCustomField,
      mutateDeleteListingCustomField: deleteListingCustomField,
      mutateDeleteListingCustomFields: deleteListingCustomFields,
      mutateRenameListingCustomFields: renameListingCustomFields,
      mutateChangeListingCustomFieldsType: changeListingCustomFieldDataType,
      mutateClearSpacesField: clearSpacesField,
      mutateCreateSurveyListing: createSurveyListing,
      mutateAddSurveyBuildingAttachments: addSurveyBuildingAttachments,
      mutateDeleteSurveyBuildingAttachment: deleteSurveyBuildingAttachment,
      mutateSetSpacesFieldOrder: setSpacesFieldOrder,
      mutateCopySpacesFromSurvey: copySpacesFromSurvey,
    } = useSurveyBuildingSelector(surveyId, buildingId, isAnonymous)

    return [
      surveyBuilding.survey_listings.length > 0 ||
      (isUserBroker && !isPreview) ? (
        <SpacesSection
          className={classes.rightColumnSection}
          surveySpaces={surveyBuilding.survey_listings}
          surveyId={surveyId}
          surveyBuilding={surveyBuilding}
          buildingPrimaryName={buildingPrimaryName}
          mutate={mutate}
          deleteSurveyListing={deleteSurveyListing}
          updateListing={updateListing}
          createCustomListingField={createCustomListingField}
          createCustomListingFields={createCustomListingFields}
          updateListingCustomField={updateListingCustomField}
          deleteListingCustomField={deleteListingCustomField}
          deleteListingCustomFields={deleteListingCustomFields}
          renameListingCustomFields={renameListingCustomFields}
          changeListingCustomFieldDataType={changeListingCustomFieldDataType}
          clearSpacesField={clearSpacesField}
          createSurveyListing={createSurveyListing}
          setSpacesFieldOrder={setSpacesFieldOrder}
          copySpacesFromSurvey={copySpacesFromSurvey}
          key="SpacesSection"
        />
      ) : null,
      <div
        className={classes.attachmentsSectionContainer}
        key="AttachmentsSection"
      >
        <AttachmentsSection
          buildingName={buildingPrimaryName}
          attachments={surveyBuilding.attachments || []}
          addSurveyBuildingAttachments={addSurveyBuildingAttachments}
          deleteSurveyBuildingAttachment={deleteSurveyBuildingAttachment}
        />
      </div>,
      <CommentsSection
        surveyBuilding={surveyBuilding}
        surveyId={surveyId}
        setShowLoginModal={setShowLoginModal}
        key="CommentsSection"
      />,
    ]
  }
)

const HeroSection = withStyles({
  heroImageContainer: {
    width: '100%',
    height: '234px',
    borderRadius: '4px',
    marginBottom: '16px',
    background: '#e0e0e0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  heroImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
})(({ classes, building, onPhotoClick }) => {
  const isUserBroker = useSelector((s) => isBroker(s.user))
  const { isPreview } = React.useContext(IsPreviewContext)

  if ((!isUserBroker || isPreview) && !building.hero_photo) {
    return null
  }

  return (
    <div className={classes.heroImageContainer}>
      {building.hero_photo && (
        <CardImage
          imageFile={building.hero_photo}
          ImgProps={{
            alt: 'building hero',
            className: classes.heroImage,
            onClick: onPhotoClick,
          }}
        />
      )}
      {!building.hero_photo && !!(isUserBroker && !isPreview) && (
        <div className={classes.heroImage} onClick={onPhotoClick}>
          <EmptyCardsViewIcon />
        </div>
      )}
      <PhotosButton
        imageCount={
          building.images.length + building.neighborhood_photos.length
        }
        isUserBroker={!!(isUserBroker && !isPreview)}
        openUploadModal={onPhotoClick}
      />
    </div>
  )
})

const MapAndAddressSection = withStyles({
  mapAndAddressContainer: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    marginBottom: '32px',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  mapAndAddressGradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: `linear-gradient(
      90deg, rgba(255, 255, 255, 0) 37.5%, #ffffff 71.68%)
    `,
    zIndex: 1,
  },
  addressContainer: {
    position: 'absolute',
    right: 0,
    width: '50%',
    padding: '18px',
    zIndex: 2,
  },
  addressPart: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})(({ classes, building, setIsViewingMap, buildingPrimaryName }) => {
  const theme = useTheme()
  const buildingSecondaryName = getBuildingSecondaryName(building)
  const buildingTertiaryName = getBuildingTertiaryName(building)
  return (
    <div
      className={classes.mapAndAddressContainer}
      onClick={() => setIsViewingMap(true)}
    >
      <div className={classes.mapAndAddressGradient} />
      <GoogleMap
        center={{
          lat: building.geo.latitude,
          lng: building.geo.longitude,
        }}
        mapContainerStyle={{
          height: '130%',
          width: '150%',
          marginLeft: '-50%',
        }}
        options={{
          scrollwheel: false,
          fullscreenControl: false,
          styles: roadMapStyles,
        }}
        zoom={16}
      >
        <Marker
          position={{
            lat: building.geo.latitude,
            lng: building.geo.longitude,
          }}
          icon={`data:image/svg+xml,${escape(
            ReactDOMServer.renderToStaticMarkup(
              <MapMarkerIcon isActive={false} theme={theme} />
            )
          )}`}
        />
      </GoogleMap>
      <div className={classes.addressContainer}>
        <Typography className={classes.addressPart} variant="bodyBold">
          {buildingPrimaryName}
        </Typography>
        {buildingSecondaryName && (
          <Typography className={classes.addressPart} variant="bodyText">
            {buildingSecondaryName}
          </Typography>
        )}
        <Typography className={classes.addressPart} variant="bodyText">
          {buildingTertiaryName}
        </Typography>
      </div>
    </div>
  )
})

const DescriptionSection = withStyles({
  notes: {
    width: '100%',
    whiteSpace: 'pre-line',
    marginBottom: '32px',
  },
})(({ classes, building }) => {
  return (
    <div className={classes.notes}>
      <Typography variant="body1">{building.description}</Typography>
    </div>
  )
})

const PropertiesSection = withStyles((theme) => ({
  properties: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '14px',
  },
  property: {
    display: 'flex',
    marginBottom: '10px',
  },
  propertyTitle: {
    marginRight: '8px',
  },
  amenities: {
    marginBottom: '36px',
  },
  amenity: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
    marginRight: '8px',
    marginBottom: '8px',
  },
}))(({ classes, building }) => {
  return (
    <>
      <div className={classes.properties}>
        {building.metadata.map(
          (entry) =>
            entry.formattedValue !== undefined &&
            entry.formattedValue !== null &&
            entry.formattedValue !== '' && (
              <div
                key={`${entry.id}-${entry.field_id}`}
                className={classes.property}
              >
                <Typography
                  className={classes.propertyTitle}
                  variant="bodyBold"
                >
                  {`${entry.name}:`}
                </Typography>
                <Typography variant="bodyText">
                  {entry.formattedValue}
                </Typography>
              </div>
            )
        )}
      </div>
      {building.amenities && (
        <div className={classes.amenities}>
          {building.amenities.map((amenity) => (
            <TextChip
              key={amenity}
              className={classes.amenity}
              label={amenity}
            />
          ))}
        </div>
      )}
    </>
  )
})

const WalkscoreSection = withStyles({
  walkscores: {
    marginBottom: '60px',
  },
  walkscoreRoot: {
    '& > *': {
      width: '100%',
    },
  },
})(({ classes, building }) => {
  if (!building.walkscore) {
    return null
  }

  return (
    <div className={classes.walkscores}>
      <WalkScores
        walkscore={building.walkscore}
        classes={{ root: classes.walkscoreRoot }}
      />
    </div>
  )
})

const LeftColumnPiecesMediumScreen = ({
  building,
  onPhotoClick,
  setIsViewingMap,
  buildingPrimaryName,
}) => [
  <HeroSection
    building={building}
    onPhotoClick={onPhotoClick}
    key="HeroSection"
  />,
  <MapAndAddressSection
    building={building}
    setIsViewingMap={setIsViewingMap}
    buildingPrimaryName={buildingPrimaryName}
    key="MapAndAddressSection"
  />,
  <WalkscoreSection building={building} key="WalkscoreSection" />,
]

const RightColumnPiecesMediumScreen = ({ building }) => {
  return [
    <DescriptionSection building={building} key="DescriptionSection" />,
    <PropertiesSection building={building} key="PropertiesSection" />,
  ]
}

const LocationPieces = ({
  building,
  onPhotoClick,
  setIsViewingMap,
  buildingPrimaryName,
}) => {
  return [
    <HeroSection
      building={building}
      onPhotoClick={onPhotoClick}
      key="HeroSection"
    />,

    <MapAndAddressSection
      building={building}
      setIsViewingMap={setIsViewingMap}
      buildingPrimaryName={buildingPrimaryName}
      key="MapAndAddressSection"
    />,

    <DescriptionSection building={building} key="DescriptionSection" />,
    <PropertiesSection building={building} key="PropertiesSection" />,

    <WalkscoreSection building={building} key="WalkscoreSection" />,
  ]
}

export default function BuildingDetailPage({ match }) {
  const classes = useStyles()
  // const history = useHistory()
  const user = useSelector((s) => s.user)
  const isUserBroker = useSelector((s) => isBroker(s.user))
  const isAnonymous = useSelector((s) => s.user.isAnonymous)
  const surveyId = match && match.params && match.params.survey_id
  const buildingId = match && match.params && match.params.building_id

  const [isEditing, setIsEditing] = useState(false)
  const [isViewingImages, setIsViewingImages] = useState(false)
  const [isViewingMap, setIsViewingMap] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { isPreview, setIsPreview } = React.useContext(IsPreviewContext)

  const buildingMenuState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })

  const {
    surveyBuilding,
    error: surveyBuildingError,
    loading: surveyBuildingLoading,
    mutate,
    mutateUpdate: updateSurveyBuilding,
    mutateUpdateBuilding: updateBuilding,
    mutateCreateBuildingCustomField: createCustomField,
    mutateUpdateBuildingCustomField: updateCustomField,
    mutateDeleteBuildingCustomField: deleteCustomField,
    mutateRenameBuildingCustomField: renameCustomField,
    mutateChangeBuildingCustomFieldType: changeCustomFieldDataType,
    mutateSetFieldOrder: setFieldOrder,
  } = useSurveyBuildingSelector(surveyId, buildingId, isAnonymous)
  const {
    survey,
    error: surveyError,
    loading: surveyLoading,
  } = useSurveySelector(surveyId, 'bdp', isAnonymous)

  const {
    stage1,
    stage2,
    stage3,
    stage4,
    stage5,
    stage6,
    stage7,
    largeScreenLayout,
    mediumScreenLayout,
    smallScreenLayout,
  } = useBuildingDetailPageLayout()

  let headerClass = ''
  let headerDividerClassName = ''
  let contentDividerClassName = ''
  let leftColumnClassName = ''
  let rightColumnClassName = ''

  if (stage1) {
    headerClass = classes.headerStage1
    headerDividerClassName = classes.headerDividerStage1
    contentDividerClassName = classes.contentDividerStage1
    leftColumnClassName = classes.leftColumnStage1
    rightColumnClassName = classes.rightColumnStage1
  } else if (stage2) {
    headerDividerClassName = classes.headerDividerStage2
    contentDividerClassName = classes.contentDividerStage2
    leftColumnClassName = classes.leftColumnStage2
    rightColumnClassName = classes.rightColumnStage2
  } else if (stage3) {
    headerClass = classes.headerStage3
    headerDividerClassName = classes.headerDividerStage3
    contentDividerClassName = classes.contentDividerStage3
    leftColumnClassName = classes.leftColumnStage3
    rightColumnClassName = classes.rightColumnStage3
  } else if (stage4) {
    headerClass = classes.headerStage4
    headerDividerClassName = classes.headerDividerStage4
    contentDividerClassName = classes.contentDividerStage4
    leftColumnClassName = classes.leftColumnStage3
    rightColumnClassName = classes.rightColumnStage4
  } else if (stage5) {
    headerClass = classes.headerStage4
    headerDividerClassName = classes.headerDividerStage4
    contentDividerClassName = classes.contentDividerStage4
    leftColumnClassName = classes.leftColumnStage3
    rightColumnClassName = classes.rightColumnStage4
  } else if (stage6) {
    headerClass = classes.headerStage6
    headerDividerClassName = classes.headerDividerStage6
    contentDividerClassName = classes.contentDividerStage6
    leftColumnClassName = classes.leftColumnStage6
    rightColumnClassName = classes.rightColumnStage6
  } else if (stage7) {
    headerClass = classes.headerStage7
    headerDividerClassName = classes.headerDividerStage7
    contentDividerClassName = classes.contentDividerStage7
    leftColumnClassName = classes.leftColumnStage7
    rightColumnClassName = classes.rightColumnStage7
  } else {
    headerClass = classes.headerStage8
    headerDividerClassName = classes.headerDividerStage7
    contentDividerClassName = classes.contentDividerStage7
    leftColumnClassName = classes.leftColumnStage8
    rightColumnClassName = classes.rightColumnStage8
  }

  // Next/previous building
  const surveyBuildings = survey
    ? survey.survey_buildings
    : EMPTY_SURVEY_BUILDINGS
  const surveuBuildingId = surveyBuilding ? surveyBuilding.id : null
  const buildingIdIndex = useMemo(
    () =>
      surveuBuildingId && surveyBuildings
        ? lodash.findIndex(
            surveyBuildings,
            (surveyBuildingLocal) => surveyBuildingLocal.id === surveuBuildingId
          )
        : -1,
    [surveuBuildingId, surveyBuildings]
  )
  const [showLoginModal, setShowLoginModal] = useState(false)

  const {
    BdpBuildingMenuComponent,
    ConfirmRemoveBuildingFromSurveyModalComponent,
  } = useBdpBuildingMenu({
    menuState: buildingMenuState,
    surveyId,
    surveyName: survey ? survey.name : '',
    buildingId,
    redirect: getViewSurveyRoute(surveyId),
  })

  const LoadingState = withStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '60px', // header height
    },
  })(({ classes: _classes }) => {
    return (
      <Container classes={{ root: _classes.root }}>
        <Loading isLoading size={40} />
      </Container>
    )
  })
  const building = surveyBuilding ? surveyBuilding.building : EMPTY_BUILDING

  const onPhotoClick = useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      if (isUserBroker && !isPreview) {
        setShowUploadModal(true)
      } else {
        setIsViewingImages(true)
      }
    },
    [isPreview, isUserBroker, setShowUploadModal, setIsViewingImages]
  )

  // If we don't have the user or the non-anonymous user is still logging in, wait.
  if (
    !user ||
    (!user.isAnonymous &&
      user.isAnonymous !== undefined &&
      user.loggedIn &&
      !user.id)
  )
    return <LoadingState />
  if ((surveyBuildingError && !surveyBuilding) || surveyError)
    return 'There was an error loading the page, please try refreshing.'
  if ((surveyBuildingLoading && !surveyBuilding) || (surveyLoading && !survey))
    return <LoadingState />

  let nextBuildingId = null
  let prevBuildingId = null
  if (buildingIdIndex !== -1) {
    nextBuildingId =
      survey.survey_buildings[
        mod(buildingIdIndex + 1, survey.survey_buildings.length)
      ].building.id
    prevBuildingId =
      survey.survey_buildings[
        mod(buildingIdIndex - 1, survey.survey_buildings.length)
      ].building.id
  }

  const isFavorited =
    surveyBuilding && surveyBuilding.interest === BUILDING_INTEREST.FAVORITED
  const isNotInterested =
    !isFavorited &&
    surveyBuilding &&
    surveyBuilding.interest === BUILDING_INTEREST.NOT_INTERESTED
  const buildingPrimaryName = getBuildingPrimaryName(building)

  const Divider = ({ className }) => (
    <div className={clsx(classes.divider, className)} />
  )
  const HeaderDivider = (props) =>
    Divider({ className: headerDividerClassName, ...props })
  const ContentDivider = (props) =>
    Divider({ className: contentDividerClassName, ...props })

  return (
    <div className={classes.root}>
      <TenantPreviewNavBar
        classes={{
          innerContentRoot: headerClass,
          divider: headerDividerClassName,
        }}
      />
      {/* <FtuxBdpBanner /> */}
      <Container
        className={clsx(classes.contentRoot, classes.contentRootMaxWidth)}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <HeaderDivider />

          <div className={clsx(classes.header, headerClass)}>
            <div className={classes.breadcrumbContainer}>
              <SurveyBreadcrumb
                surveyId={surveyId}
                surveyName={survey.name}
                buildingName={buildingPrimaryName}
              />
              <div className={classes.actionsContainer}>
                <SquareIconButton
                  tooltipText="Previous Building"
                  disabled={!prevBuildingId}
                  className={classes.iconButton}
                  onClick={() => {
                    location.href = getViewBuildingRoute(
                      survey.id,
                      prevBuildingId
                    )
                  }}
                >
                  <LeftChevronIcon />
                </SquareIconButton>
                <SquareIconButton
                  tooltipText="Next Building"
                  disabled={!nextBuildingId}
                  className={classes.iconButton}
                  onClick={() => {
                    location.href = getViewBuildingRoute(
                      survey.id,
                      nextBuildingId
                    )
                  }}
                >
                  <RightChevronIcon />
                </SquareIconButton>
                {!!isUserBroker &&
                  !isPreview && [
                    <SquareIconButton
                      tooltipText="Edit Building Details"
                      className={classes.iconButton}
                      key="edit-icon"
                    >
                      <EditIcon onClick={() => setIsEditing(true)} />
                    </SquareIconButton>,
                    <SquareIconButton
                      tooltipText="Preview as Client"
                      className={classes.iconButton}
                      key="preview-icon"
                      onClick={() => setIsPreview(!isPreview)}
                    >
                      <PreviewIcon />
                    </SquareIconButton>,
                  ]}
                {(!!(isUserBroker && isPreview) ||
                  !!(!isUserBroker && !isAnonymous)) && [
                    <FavoriteBuildingButton
                      classes={{ iconButton: classes.iconButton }}
                      isFavorited={isFavorited}
                      isNotInterested={isNotInterested}
                      key="favorite-icon"
                      onClick={() =>
                      !isPreview &&
                      updateSurveyBuilding({
                        interest: isFavorited
                          ? BUILDING_INTEREST.NEUTRAL
                          : BUILDING_INTEREST.FAVORITED,
                      })
                    }
                    />,
                    <NotInterestedBuildingButton
                      classes={{ iconButton: classes.iconButton }}
                      isNotInterested={isNotInterested}
                      isFavorited={isFavorited}
                      key="not-interested-icon"
                      onClick={() =>
                      !isPreview &&
                      updateSurveyBuilding({
                        interest: isNotInterested
                          ? BUILDING_INTEREST.NEUTRAL
                          : BUILDING_INTEREST.NOT_INTERESTED,
                      })
                    }
                    />,
                ]}
                <CompareSpacesButton
                  classes={{ iconButton: classes.iconButton }}
                  surveyId={survey.id}
                  spaceIds={surveyBuilding.survey_listings.map(
                    (sl) => sl.listing.id
                  )}
                />
                {isUserBroker && !isPreview && (
                  <SquareIconButton
                    tooltipText="More Options"
                    className={classes.iconButton}
                    {...bindTrigger(buildingMenuState)}
                  >
                    <MoreIcon />
                  </SquareIconButton>
                )}
              </div>
            </div>
            <Typography variant="pageTitle">{buildingPrimaryName}</Typography>
          </div>

          <HeaderDivider />
        </div>

        {largeScreenLayout && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ContentDivider />

            <div className={leftColumnClassName}>
              <LocationPieces
                building={building}
                onPhotoClick={onPhotoClick}
                setIsViewingMap={setIsViewingMap}
                buildingPrimaryName={buildingPrimaryName}
              />
            </div>

            <ContentDivider />

            <div className={rightColumnClassName}>
              <SpacesAttachmentsComments
                surveyBuilding={surveyBuilding}
                buildingId={buildingId}
                buildingPrimaryName={buildingPrimaryName}
                surveyId={surveyId}
                setShowLoginModal={setShowLoginModal}
                key="SpacesAttachmentsComments"
              />
            </div>

            <ContentDivider />
          </div>
        )}

        {mediumScreenLayout && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ContentDivider />

            <div className={leftColumnClassName}>
              <LeftColumnPiecesMediumScreen
                building={building}
                onPhotoClick={onPhotoClick}
                setIsViewingMap={setIsViewingMap}
                buildingPrimaryName={buildingPrimaryName}
                key="LeftColumnPiecesMediumScreen"
              />
            </div>

            <ContentDivider />

            <div className={rightColumnClassName}>
              <RightColumnPiecesMediumScreen building={building} />
            </div>

            <ContentDivider />
          </div>
        )}

        {mediumScreenLayout && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <HeaderDivider />

            <div className={clsx(classes.header, headerClass)}>
              <SpacesAttachmentsComments
                surveyBuilding={surveyBuilding}
                buildingId={buildingId}
                buildingPrimaryName={buildingPrimaryName}
                surveyId={surveyId}
                setShowLoginModal={setShowLoginModal}
                key="SpacesAttachmentsComments"
              />
            </div>

            <HeaderDivider />
          </div>
        )}

        {smallScreenLayout && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <HeaderDivider />

            <div className={clsx(classes.header, headerClass)}>
              <LocationPieces
                building={building}
                onPhotoClick={onPhotoClick}
                setIsViewingMap={setIsViewingMap}
                buildingPrimaryName={buildingPrimaryName}
              />
              <SpacesAttachmentsComments
                surveyBuilding={surveyBuilding}
                buildingId={buildingId}
                buildingPrimaryName={buildingPrimaryName}
                surveyId={surveyId}
                setShowLoginModal={setShowLoginModal}
                key="SpacesAttachmentsComments"
              />
            </div>

            <HeaderDivider />
          </div>
        )}

        <EditBuildingModal
          fullscreen
          ModalComponentProps={{
            open: isEditing,
            onClose: () => setIsEditing(false),
          }}
          mutate={mutate}
          surveyId={surveyId}
          surveyBuilding={surveyBuilding}
          building={building}
          updateBuilding={updateBuilding}
          createCustomField={createCustomField}
          updateCustomField={updateCustomField}
          deleteCustomField={deleteCustomField}
          renameCustomField={renameCustomField}
          changeCustomFieldDataType={changeCustomFieldDataType}
          setFieldOrder={setFieldOrder}
        />
        <ImageGalleryModal
          title={buildingPrimaryName}
          imageLists={[
            { label: 'Building', images: building.images },
            { label: 'Neighborhood', images: building.neighborhood_photos },
          ]}
          ModalComponentProps={{
            open: isViewingImages,
            onClose: () => setIsViewingImages(false),
          }}
        />
        <BuildingMapModal
          title={buildingPrimaryName}
          primarySurveyBuilding={surveyBuilding}
          surveyBuildings={survey.survey_buildings}
          surveyId={surveyId}
          ModalComponentProps={{
            open: isViewingMap,
            onClose: () => setIsViewingMap(false),
          }}
        />
        {BdpBuildingMenuComponent}
        {ConfirmRemoveBuildingFromSurveyModalComponent}
        <Modal
          content={MODALS.IMAGE_UPLOAD}
          childProps={{
            parentObjects: {
              building,
            },
            objectName: buildingPrimaryName,
            noPadding: true,
            setListing: async (newListingData) => {
              const { building: newBuilding } = newListingData
              const newBuildingPartial = {
                images: newBuilding.images,
                neighborhood_photos: newBuilding.neighborhood_photos,
              }
              await updateBuilding(newBuildingPartial)
            },
            tabsToShow: [
              IMAGE_UPLOAD_MODAL_TAB_KEYS.BUILDING,
              IMAGE_UPLOAD_MODAL_TAB_KEYS.NEIGHBORHOOD,
            ],
          }}
          open={showUploadModal}
          onClose={() => setShowUploadModal(false)}
        />
        <Modal
          content={MODALS.LOGIN_MODAL}
          onClose={() => setShowLoginModal(false)}
          open={showLoginModal}
          childProps={{
            surveyId: survey.id,
            contentClassName: classes.loginModalContent,
          }}
        />
      </Container>
      {/* <FtuxBdpModal
        showFtuxBdpModal={isUserBroker && !isPreview && user.showFtuxBdpModal}
      /> */}
    </div>
  )
}
