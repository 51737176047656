import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import lodash from 'lodash';

import { PlusIcon, IconChip, TextChip } from '~/legacy/components';

const COMMON_CHIP_STYLES = {
  root: {
    backgroundColor: '#F3F3F3',
  },
};

export const ProjectRequirementTextChip = withStyles(
  lodash.merge({}, COMMON_CHIP_STYLES, {
    root: {
      color: '#666',
    },
  })
)(({ label, classes, ...props }) => {
  return (
    <TextChip
      classes={{
        root: classes.root,
      }}
      label={label}
      {...props}
    />
  );
});

export const ProjectRequirementAddChip = withStyles(
  lodash.merge({}, COMMON_CHIP_STYLES, {
    root: {
      color: '#111',
      height: '32px',
      width: '32px',
    },
    label: {
      padding: 0,
      display: 'flex',
    },
  })
)(({ classes, ...props }) => {
  return (
    <IconChip
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      icon={<PlusIcon />}
      {...props}
    />
  );
});
