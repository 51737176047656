import React from 'react'
import {
  useChannel,
  useDispatcher,
  useMessages,
} from '~/libraries/messaging.react'
import {
  createEventStore,
  createIndexedDBConnection,
} from '~/libraries/eventstore'
import { EventStoreProvider } from '~/libraries/eventstore.react'

const connection = createIndexedDBConnection('events')
const store = createEventStore(connection)

export const LocalEventStore = ({ children }) => {
  const dispatcher = useDispatcher()
  useChannel('events', store.subscribe, dispatcher.send)
  useMessages((message) => console.log(message.type, ':', message.data))
  return <EventStoreProvider store={store}>{children}</EventStoreProvider>
}
