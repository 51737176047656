import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Api from 'rest-fetcher-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { UserAutocomplete } from '~/legacy/components';
import { LEASE_LOGIN } from '~/legacy/consts';
import {
  SnackbarUtils,
  getAdminUserDisplayName,
  isBrokerAdmin,
} from '~/legacy/utils';
import ActionModal from '../modals/ActionModal';

export const DeleteUserModalContext = React.createContext({});

export const DeleteUserModalProvider = ({
  deleteUserModalOpen,
  setDeleteUserModalOpen,
  deletingOwnUser,
  setDeletingOwnUser,
  users,
  usersToDelete,
  setUsersToDelete,
  setUsers,
  children,
}) => {
  const [onClose, setOnClose] = useState(() => () => {});

  const value = {
    setDeleteUserModalOpen,
    setUsersToDelete,
    setDeletingOwnUser,
    setOnClose,
  };

  return (
    <DeleteUserModalContext.Provider value={value}>
      {children}
      <DeleteUserModal
        open={deleteUserModalOpen}
        onClose={() => {
          onClose();
          setDeleteUserModalOpen(false);
        }}
        deletingOwnUser={deletingOwnUser}
        users={users}
        usersToDelete={usersToDelete}
        setUsers={setUsers}
      />
    </DeleteUserModalContext.Provider>
  );
};

const useStyles = makeStyles({
  autocompleteContainer: {
    marginTop: '28px',
  },
});

export const DeleteUserModal = ({
  open,
  onClose,
  deletingOwnUser,
  users,
  usersToDelete,
  setUsers,
  ModalComponentProps = {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const adminUser = useSelector((s) => s.user);

  // Admin users in the company other than the viewing admin user
  const otherUsers = useMemo(
    () => (users ? users.filter((user) => !usersToDelete.has(user.id)) : []),
    [users, usersToDelete]
  );
  const otherAdminUsers = useMemo(
    () => (otherUsers ? otherUsers.filter((user) => isBrokerAdmin(user)) : []),
    [otherUsers]
  );

  const anotherAdminExists = !!(otherAdminUsers && otherAdminUsers.length > 0);
  const deletingMany = !!(usersToDelete && usersToDelete.size > 1);

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [errorText, setErrorText] = React.useState('');

  const closeModal = () => {
    onClose();
  };
  const afterModalClose = () => {
    // Reinitialize modal
    setErrorText('');
    setSelectedUser(null);
    setIsLoading(false);
  };

  const getDeleteUsersModalText = () => {
    // Deleting your own user?
    if (deletingOwnUser) {
      // Are there other admin accounts that we aren't deleting?
      if (anotherAdminExists) {
        if (deletingMany) {
          return 'Are you sure you want to delete the selected users, including your admin account? The deleted users’ survey ownership will be transferred to another admin account, and you will be logged out. This action cannot be undone.';
        }
        return 'Are you sure you want to delete your admin account? If so, please select another admin to transfer your survey ownership to. Afterwards, you will be logged out. This action cannot be undone.';
      }
      // There are not any other admin accounts to transfer ownership to
      if (deletingMany) {
        return 'Are you sure you want to delete the selected users, including your admin account? If so, please select a new admin from the remaining users below. The deleted users’ survey ownership will be transferred to the new admin account, and you will be logged out. This action cannot be undone.';
      }
      return 'Are you sure you want to delete your admin account? If so, please select a new admin from the remaining users below. Your surveys’ ownership will be transferred to the new admin account, and you will be logged out. This action cannot be undone.';
    }
    // Deleting many users, not your own
    if (deletingMany) {
      return 'Are you sure you want to delete the selected users? This action cannot be undone. The deleted users’ survey ownership will be transferred to your account.';
    }

    // Deleting one user, not your own
    const [userId] = usersToDelete;
    const deletedUser = users.find((user) => user.id === userId) || {};
    const deletedUserName = getAdminUserDisplayName(deletedUser);
    // The deleted user is an admin
    if (isBrokerAdmin(deletedUser)) {
      return `Are you sure you want to delete ${deletedUserName}? This admin’s survey ownership will be transferred to your admin account. This action cannot be undone.`;
    }
    // The deleted user is not an admin
    return `Are you sure you want to delete ${deletedUserName}? This action cannot be undone. This user’s survey ownership will be transferred to your admin account.`;
  };

  const deleteUsers = () => {
    // Delete the users, log the admin out if they deleted their own account, and refresh the users on the page
    if (deletingOwnUser && !selectedUser) {
      if (anotherAdminExists) {
        setErrorText('Please select an admin account');
      } else {
        setErrorText('Please select a new admin account');
      }
    } else {
      setIsLoading(true);
      Api.deleteUsers({
        body: {
          user_ids: [...usersToDelete],
          transfer_to_user_id: selectedUser ? selectedUser.id : adminUser.id,
        },
      })
        .then((results) => {
          const deletingManyUsers = !!(usersToDelete && usersToDelete.size > 1);
          closeModal();
          if (deletingOwnUser) {
            if (deletingManyUsers) {
              SnackbarUtils.success(
                'The selected users were successfully deleted, including your own account. You have been logged out.',
                { autoHideDuration: 10000 }
              );
            } else {
              SnackbarUtils.success(
                'Your account was successfully deleted. You have been logged out.',
                { autoHideDuration: 10000 }
              );
            }
            dispatch({ type: 'LOGOUT' });
            history.push(LEASE_LOGIN);
          } else {
            if (deletingManyUsers) {
              SnackbarUtils.success(
                'The selected users were successfully deleted!'
              );
            } else {
              SnackbarUtils.success(
                'The selected user was successfully deleted!'
              );
            }
            // Refresh the users
            if (results && results.data) {
              setUsers(results.data);
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <ActionModal
      key="delete-user-modal"
      ModalComponentProps={{
        open,
        ...ModalComponentProps,
        onClose: closeModal,
      }}
      onClose={closeModal}
      onAfterClose={afterModalClose}
      title={`Delete User${deletingMany ? 's' : ''}`}
      confirmButtonLabel="Delete"
      loading={isLoading}
      disableAction={isLoading || !!errorText}
      onConfirm={deleteUsers}
    >
      <div>
        <Typography variant="body1" component="span">
          {getDeleteUsersModalText()}
        </Typography>
      </div>

      {!!deletingOwnUser && (
        <div className={classes.autocompleteContainer}>
          <UserAutocomplete
            label="New Admin"
            userOptions={anotherAdminExists ? otherAdminUsers : otherUsers}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            errorText={errorText}
            setErrorText={setErrorText}
          />
        </div>
      )}
    </ActionModal>
  );
};
