import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Tabs, Typography, CloseIcon } from '~/legacy/components';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '60px',
    maxHeight: '60px',
    padding: '0 32px',
    borderBottom: '1px solid #e0e0e0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    backgroundColor: '#ffffff',
    zIndex: 2,
  },
  firstLast: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
  },
  close: {
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  tabs: {
    height: '60px',
  },
  tabRoot: {
    height: '60px',
  },
});

export default function FullScreenTitle({
  id,
  title,
  onClose,
  tabLabels = [],
  selectedTab,
  setSelectedTab,
}) {
  const classes = useStyles();
  return (
    <div id={id} className={classes.root}>
      <Typography className={classes.firstLast} variant="bodyBold">
        {title}
      </Typography>
      {tabLabels.length > 0 && (
        <Tabs
          className={classes.tabs}
          classes={{ tabRoot: classes.tabRoot }}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabLabels={tabLabels}
        />
      )}
      <div className={classes.firstLast}>
        <CloseIcon className={classes.close} onClick={onClose} />
      </div>
    </div>
  );
}
