import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Api from 'rest-fetcher-redux';
import { Typography, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { previewTenantViewActions } from '~/legacy/store';
import {
  Button,
  EditIcon,
  Modal,
  MODALS,
  SquareIconButton,
  ListingMenu,
  PreviewIcon,
  EditBuildingAddressModal,
} from '~/legacy/components';
import {
  getListingPrimaryName,
  getListingSecondaryName,
  getBuildingTertiaryName,
} from '~/legacy/utils';
import { toast } from '~/legacy/utils/notifications';
import { useApiHelper } from '~/legacy/utils/hooks';

// eslint-disable-next-line
import {
  userTypes,
  SURVEY_LISTING_STATUSES,
  SURVEY_LISTING_STATUS_CHANGES,
} from '~/legacy/consts';

import ImageGrid from './ImageGrid';

const useStyles = makeStyles((theme) => ({
  addressSubTitle: {
    fontSize: '18px',
  },
  addressTitle: {
    marginBottom: '-5px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.only('xs')]: {
      marginTop: '8px',
      display: 'flex',
      marginBottom: '8px',
    },
  },
  buttonsOuter: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  firstButton: {
    marginRight: '8px',
  },
  titleContainer: {
    borderBottom: '2px solid #e8e8e8',
    padding: '24px 8px 24px 4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px 0 16px 0',
    },
  },
  callMadeIcon: {
    width: '15px',
    height: '15px',
    marginTop: '8px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  buildingAddress: {
    display: 'flex',
    flexDirection: 'column',
  },
  editIcon: {
    alignSelf: 'center',
    marginLeft: '16px',
    cursor: 'pointer',
  },
  moreOptionsMenuContainer: {
    marginLeft: '16px',
  },
}));

const useTenantStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    marginLeft: '8px',
    [theme.breakpoints.only('xs')]: {
      marginTop: '8px',
      marginLeft: '0',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  declinedText: {
    width: '300px',
  },
  firstButton: {
    marginRight: '8px',
  },
  link: {
    margin: '0 4px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

// TODO: pass in the survey listing rather than retrieve it
// TODO: make general component and use on tenant dash
function TenantListingActions({
  listingId,
  isPreviewingTenantView,
  surveyId = null,
}) {
  const classes = useTenantStyles();
  const theme = useTheme();
  const [surveyListing, setSurveyListing] = useState();
  const [declineListingModalOpen, setDeclineListingModalOpen] = useState(false);
  const fetchSurveyListing = (showToast) => {
    Api.getSurveyListing({ id: listingId, GET: { survey_id: surveyId } }).then(
      (sl) => {
        setSurveyListing(sl);
        if (sl.status === 'TOUR_REQUESTED' && showToast) {
          toast('Listing favorited!', {
            appearance: 'success',
          });
        }
      }
    );
  };
  const apiHelper = useApiHelper();

  if (!surveyListing) {
    // If we are previewing the tenant view as a broker, then just mock the status. Else,
    //   get it from an API call.
    if (isPreviewingTenantView) {
      setSurveyListing({ id: listingId, status: 'SHARED' });
    } else {
      fetchSurveyListing();
    }
  }

  if (surveyListing) {
    const tourRequested =
      surveyListing.status === SURVEY_LISTING_STATUSES.TOUR_REQUESTED;
    return (
      <div>
        {surveyListing.status === 'DECLINED' && (
          <Typography
            align="center"
            className={classes.declinedText}
            variant="body1"
          >
            You have declined this listing. If you change your mind
            <a
              className={classes.link}
              onClick={() =>
                apiHelper
                  .updateSurveyListingStatus({
                    surveyListingId: surveyListing.id,
                    newStatus: SURVEY_LISTING_STATUSES.TOUR_REQUESTED,
                    colors: theme.palette.primary,
                    statusChange: SURVEY_LISTING_STATUSES.TOUR_REQUESTED,
                  })
                  .then(() => {
                    fetchSurveyListing(true);
                  })
              }
            >
              click here
            </a>
            to request a tour.
          </Typography>
        )}
        {surveyListing.status !== SURVEY_LISTING_STATUSES.DECLINED && (
          <div className={classes.buttons}>
            <Button
              className={classes.firstButton}
              color={tourRequested ? 'default' : 'primary'}
              disabled={tourRequested}
              onClick={() => {
                // Disable action if we are previewing as tenant broker
                if (!isPreviewingTenantView) {
                  apiHelper
                    .updateSurveyListingStatus({
                      surveyListingId: surveyListing.id,
                      newStatus: SURVEY_LISTING_STATUSES.TOUR_REQUESTED,
                      colors: theme.palette.primary,
                      statusChange: SURVEY_LISTING_STATUSES.TOUR_REQUESTED,
                    })
                    .then(() => {
                      fetchSurveyListing(true);
                    });
                }
              }}
            >
              {tourRequested ? 'Favorited' : 'Favorite'}
            </Button>
            <Button
              onClick={() => {
                // Disable action if we are previewing as tenant broker
                if (!isPreviewingTenantView) {
                  if (tourRequested) {
                    // cancel
                    apiHelper
                      .updateSurveyListingStatus({
                        surveyListingId: surveyListing.id,
                        newStatus: SURVEY_LISTING_STATUSES.SHARED,
                        statusChange:
                          SURVEY_LISTING_STATUS_CHANGES.TOUR_CANCELED,
                      })
                      .then(() => {
                        fetchSurveyListing();
                      });
                  } else {
                    setDeclineListingModalOpen(true);
                  }
                }
              }}
            >
              {tourRequested ? 'Unfavorite' : 'Not Interested'}
            </Button>
          </div>
        )}
        {!isPreviewingTenantView && (
          <Modal
            // TODO: Refactor to using data.surveyListings state and remove onSubmitOverride
            childProps={{
              onSubmitOverride: () => {
                apiHelper
                  .updateSurveyListingStatus({
                    surveyListingId: surveyListing.id,
                    newStatus: SURVEY_LISTING_STATUSES.DECLINED,
                    colors: theme.palette.primary,
                    statusChange: SURVEY_LISTING_STATUSES.DECLINED,
                  })
                  .then(fetchSurveyListing);
                setDeclineListingModalOpen(false);
                toast('Feedback submitted!', { appearance: 'success' });
              },
              address:
                surveyListing && surveyListing.listing
                  ? surveyListing.listing.building.address
                  : '',
            }}
            content={MODALS.DECLINE_LISTING}
            open={declineListingModalOpen}
            onClose={() => setDeclineListingModalOpen(false)}
          />
        )}
      </div>
    );
  }
  return null;
}
const StyledTooltip = withStyles({
  tooltipPlacementTop: {
    margin: '8px 0',
  },
})(Tooltip);

function ViewListingHeadline({
  isTenantOrBrokerPreview,
  listing,
  setListing,
  updateBuilding,
  requestedSurveyId = null,
}) {
  const dispatch = useDispatch();
  const classes = useStyles(listing);
  const user = useSelector((s) => s.user);
  const isPreviewTenantView = useSelector(
    (state) => state.pages.previewTenantView.isPreviewTenantView
  );
  const [isDeletListingConfirmModal, setIsDeletListingConfirmModal] =
    useState(false);
  const [showEditBuildingAddressModal, setShowEditBuildingAddressModal] =
    useState(false);

  // TODO: remove this when addtoprojectpopup gets revamped
  useEffect(() => {
    if (user.userType === userTypes.tenantBroker) {
      Api.getSurveys();
    }
  }, [user]);

  const isTenant = user.userType === userTypes.tenant;
  const isTenantBroker = user.userType === userTypes.tenantBroker;
  // Is the broker viewing this listing as a tenant? If so, we'll show the
  //   tenant view and effectively disable buttons/actions
  const isPreviewingTenantView = isTenantBroker && isPreviewTenantView;

  const listingPrimaryName = getListingPrimaryName(listing);
  const listingSecondaryName = getListingSecondaryName(listing);
  const buildingTertiaryName = getBuildingTertiaryName(listing.building);

  return (
    <div>
      <ImageGrid
        isTenantOrBrokerPreview={isTenantOrBrokerPreview}
        listing={listing}
        setListing={setListing}
      />
      <div className={classes.titleContainer}>
        <div className={classes.buildingAddress}>
          <div className={classes.flexRow}>
            <Typography variant="h1">{listingPrimaryName}</Typography>
            {isTenantBroker && !isPreviewingTenantView && (
              <EditIcon
                className={classes.editIcon}
                onClick={() => setShowEditBuildingAddressModal(true)}
              />
            )}
          </div>
          <Typography className={classes.addressSubTitle} variant="body1">
            {`${
              listingSecondaryName ? `${listingSecondaryName} ` : ''
            }${buildingTertiaryName}`}
          </Typography>
        </div>
        <div>
          {isTenantBroker && !isPreviewingTenantView && (
            <div className={classes.buttonsOuter}>
              <div className={classes.buttons}>
                <StyledTooltip
                  title="Preview Listing"
                  placement="bottom"
                  color="secondary"
                >
                  <SquareIconButton
                    onClick={(event) => {
                      dispatch(
                        previewTenantViewActions.setPreviewTenantView(true)
                      );
                      // Scroll the user to the top of the page
                      const anchor = (
                        event.target.ownerDocument || document
                      ).querySelector('#back-to-top-anchor');
                      if (anchor) {
                        anchor.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        });
                      }
                    }}
                  >
                    <PreviewIcon />
                  </SquareIconButton>
                </StyledTooltip>
                <div className={classes.moreOptionsMenuContainer}>
                  <ListingMenu
                    openDeleteListingsModal={() =>
                      setIsDeletListingConfirmModal(true)
                    }
                  />
                </div>
              </div>
              <Modal
                content={MODALS.CONFIRM_DELETE_LISTING}
                childProps={{
                  listingId: listing.id,
                  buildingId: listing.building.id,
                  surveyId: requestedSurveyId,
                }}
                open={isDeletListingConfirmModal}
                onClose={() => setIsDeletListingConfirmModal(false)}
              />
            </div>
          )}
          {(isTenant || isPreviewingTenantView) && (
            <TenantListingActions
              listingId={listing.id}
              isPreviewingTenantView={isPreviewingTenantView}
              surveyId={requestedSurveyId}
            />
          )}
        </div>
      </div>
      <EditBuildingAddressModal
        ModalComponentProps={{
          open: showEditBuildingAddressModal,
          onClose: () => setShowEditBuildingAddressModal(false),
        }}
        building={listing.building}
        updateBuilding={updateBuilding}
      />
    </div>
  );
}

export default ViewListingHeadline;
