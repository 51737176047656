import { styled as mui } from '@material-ui/core/styles';
import get from 'lodash/get';
import has from 'lodash/has';
import omit from 'lodash/omit';
import React from 'react';
import { createData } from '~/legacy/utils';

export const join = (queries) => {
  return queries.map((value) => `(${value})`).join(' and ');
};

export const screen = (...queries) => {
  return `@media only screen and ${join(queries)}`;
};

export const breakpoint = (type, value) => {
  return `${type}-width: ${value}px`;
};

export const above = (min) => {
  return screen(breakpoint('min', min));
};

export const below = (max) => {
  return screen(breakpoint('max', max));
};

export const between = (min, max) => {
  return screen(breakpoint('min', min), breakpoint('max', max));
};

export const first = (keys) => (props) => {
  return props[keys.find((key) => has(props, key))];
};

export const percent = (value) => {
  return Math.abs(value) <= 1 ? `${value * 100}%` : `${value}px`;
};

export const prop = (key, fallback) => (props) => {
  return get(props, key, fallback);
};

export const select = (key, values) => (props) => {
  return values[get(props, key)] || values.default;
};

export const unless = (key, a = '', b = '') => {
  return (props) => {
    return get(props, key) ? b : a;
  };
};

export const when = (key, a, b) => {
  return unless(key, b, a);
};

// Wrapper to make working with `styled` MUI syntax easier
// Base: any stylable component, 'div', Button, etc.
// keys: list of prop names that you don't want to be passed as html attributes
// styles: an object of styles or function to build styles
export const styled = (Base, keys, styles) => {
  // this makes the keys prop optional and allows the styles prop to be the second arg
  if (!styles) {
    return styled(Base, null, keys);
  }

  // create a component to stop MUI from passing our props as html attributes
  const Component = keys
    ? (props) => {
        return <Base {...omit(props, keys)} />;
      }
    : Base;

  // if style is an object, create a normal MUI `styled` component
  if (typeof styles !== 'function') {
    return mui(Component)(styles);
  }

  // if style is a function, wrap props to make working with optional data easier
  // props has your props + theme
  return mui(Component)((props) => {
    return styles(createData(props));
  });
};
