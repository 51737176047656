import range from 'lodash/range'
import React from 'react'
import { DocumentIcon, CalendarIcon, SurveyIcon } from '~/legacy/components'
import { styled } from '~/legacy/utils/styleHelpers'
import { Recent, DocumentSkeleton } from './Recent'

export const RecentList = ({ children, data, onSelect, ...props }) => {
  const handleSelect = (doc) => () => {
    if (onSelect) onSelect(doc)
  }

  return (
    <Container {...props}>
      {data.length
        ? data.map((doc) => (
          <Recent
            key={doc.id}
            icon={
                {
                  Document: <DocumentIcon />,
                  Survey: <SurveyIcon />,
                  Tour: <CalendarIcon />,
                }[doc.kind]
              }
            onSelect={handleSelect(doc)}
            {...doc}
          />
          ))
        : children}
    </Container>
  )
}

export const DocumentListSkeleton = ({ count = 4, ...props }) => (
  <Container {...props}>
    {range(count).map((key) => (
      <DocumentSkeleton key={key} />
    ))}
  </Container>
)

const Container = styled('div', {
  gridAutoColumns: '1fr',
  gridAutoFlow: 'column',
  display: 'grid',
  gap: '16px',
})
