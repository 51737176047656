import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useBuildingDetailPagePhotoModalLayout } from '~/legacy/utils';
import { px } from '~/legacy/utils/layoutUtils';
import { ModalComponent, SourceImage } from '~/legacy/components';
import FullScreenTitle from './FullScreenTitle';

const MARGIN_VERTICAL_LARGEST_PX = 56;
const MARGIN_VERTICAL_SECOND_LARGEST_PX = 32;
const MARGIN_HORIZONTAL_LARGEST_PX = 64;
const MARGIN_HORIZONTAL_SECOND_LARGEST_PX = 32;

const useStyles = makeStyles({
  modal: {
    width: '100vw',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:first-child': {
      padding: '0',
    },
  },
  body: {
    width: '100%',
    marginTop: '60px', // same size as header
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& div:not(:first-child)': {
      marginTop: '32px',
      minHeight: 'calc(100vh - 91px)',
      flexShrink: 0,
    },
  },
  bodyClassStage1: {
    padding: px(
      MARGIN_VERTICAL_LARGEST_PX,
      MARGIN_HORIZONTAL_LARGEST_PX,
      0,
      MARGIN_HORIZONTAL_LARGEST_PX
    ),
  },
  bodyClassStage2: {
    padding: px(
      MARGIN_VERTICAL_SECOND_LARGEST_PX,
      MARGIN_HORIZONTAL_SECOND_LARGEST_PX,
      0,
      MARGIN_HORIZONTAL_SECOND_LARGEST_PX
    ),
  },
  imageSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img:last-child': {
      marginBottom: (props) => (props.imageListCount > 1 ? 0 : '32px'),
    },
    width: '100%',
  },
  image: {
    marginBottom: '32px',
    maxWidth: '100%',
  },
});

// expects [{ label: '', images: [] }, {...}]
export default function ImageGalleryModal({
  title,
  imageLists,
  ModalComponentProps,
}) {
  const { stage1 } = useBuildingDetailPagePhotoModalLayout();
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredImageLists, setFilteredImageLists] = useState(imageLists);
  const classes = useStyles({ imageListCount: filteredImageLists.length });

  let bodyClass = '';

  if (stage1) {
    bodyClass = classes.bodyClassStage1;
  } else {
    bodyClass = classes.bodyClassStage2;
  }

  useEffect(
    () =>
      setFilteredImageLists(
        imageLists.filter((imageList) => imageList.images.length > 0)
      ),
    [imageLists]
  );

  // we use a callback ref here to make sure we don't run any of this logic
  // until the body element has been rendered
  const bodyEl = useCallback(
    (node) => {
      if (node != null && filteredImageLists.length > 1) {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0,
        };

        filteredImageLists.forEach((imageList, index) => {
          if (!imageList.images.length) return;
          const observer = new IntersectionObserver((intersectionEntries) => {
            const intersectionEntry = intersectionEntries[0];
            if (intersectionEntry) {
              if (
                intersectionEntry.isIntersecting &&
                intersectionEntry.intersectionRect.top === 60
              ) {
                // this section of images is entering from the top -- change to that tab
                setSelectedTab(index);
              } else if (
                !intersectionEntry.isIntersecting &&
                intersectionEntry.boundingClientRect.top < 0
              ) {
                // this section of images is no longer intersection the viewport, leaving out of the top --
                // we need to swap to the next tab of images
                setSelectedTab(index + 1);
              }
            }
          }, options);

          const target = document.getElementById(`section-${imageList.label}`);
          observer.observe(target);
        });
      }
    },
    [filteredImageLists]
  );

  return (
    <ModalComponent
      classesIn={{ dialogContent: classes.modal }}
      fullScreen
      {...ModalComponentProps}
    >
      <FullScreenTitle
        title={title}
        onClose={() => {
          ModalComponentProps.onClose();
          const firstTabLabel = filteredImageLists[0].label;
          const el = document.getElementById(`scroll-anchor-${firstTabLabel}`);
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }}
        tabLabels={
          filteredImageLists.length > 1 &&
          filteredImageLists.map((imageList) => imageList.label)
        }
        selectedTab={selectedTab}
        setSelectedTab={(selectedTabIndex) => {
          const selectedTabLabel = filteredImageLists[selectedTabIndex].label;
          const el = document.getElementById(
            `scroll-anchor-${selectedTabLabel}`
          );
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setSelectedTab(selectedTabIndex);
        }}
      />
      <div className={clsx(classes.body, bodyClass)} ref={bodyEl}>
        {filteredImageLists.map((imageList, index) => (
          <div
            id={`section-${imageList.label}`}
            key={imageList.label}
            className={classes.imageSection}
          >
            <div
              style={{ scrollMarginTop: index === 0 ? '56px' : '31px' }}
              id={`scroll-anchor-${imageList.label}`}
            />
            {imageList.images.map((image) => (
              <SourceImage
                key={`${imageList.label}:${image.id}`}
                imageFile={image}
                ImgProps={{ className: classes.image }}
              />
            ))}
          </div>
        ))}
      </div>
    </ModalComponent>
  );
}
