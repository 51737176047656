import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  BulkImportCsvReader,
  Button,
  SampleBulkCsvLink,
  UploadIcon,
} from '~/legacy/components'
import { isBroker } from '~/legacy/utils'

const useStyles = makeStyles({
  emptyContainer: {
    height: '100%',
    color: '#666666',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyContainerText: {
    marginTop: '6px',
  },
  importListingsTitle: {
    fontSize: '18px',
    marginTop: '10px',
  },
  importCsvButton: {
    marginTop: '16px',
    height: '36px',
    width: '112px',
  },
})

const ImportListingsSection = ({
  isBdpBuildout = false,
  setBackdropOpen,
  setLoadedListingsFromCsv,
  setRawCsvHeaders,
  setBulkImportFieldMatchModalOpen,
  setUploadedCsvFile,
}) => {
  const classes = useStyles()
  const linkRef = React.useRef(null)
  const user = useSelector((s) => s.user)

  return isBroker(user) ? (
    <div className={classes.emptyContainer}>
      <UploadIcon />
      <Typography className={classes.importListingsTitle} variant="h3">
        Import
        {isBdpBuildout ? ' Buildings' : ' Spaces'}
      </Typography>
      <Typography className={classes.emptyContainerText} variant="h3">
        Easily add multiple
        {isBdpBuildout ? ' buildings ' : ' spaces '}
        at once using a CSV file.
      </Typography>
      <Typography variant="h3">
        Download the 
        {' '}
        <SampleBulkCsvLink />
        <span>.</span>
      </Typography>

      {/* Button for the CSV reader... only works with link labels for whatever reason */}
      <Button
        color="primary"
        onClick={() => {
          linkRef.current.click()
        }}
        className={classes.importCsvButton}
        shrinkButton
        customHeight
      >
        Import CSV
      </Button>
      <BulkImportCsvReader
        linkRef={linkRef}
        setBackdropOpen={setBackdropOpen}
        setUploadedCsvFile={setUploadedCsvFile}
        setLoadedListingsFromCsv={setLoadedListingsFromCsv}
        setRawCsvHeaders={setRawCsvHeaders}
        setBulkImportFieldMatchModalOpen={setBulkImportFieldMatchModalOpen}
      />
    </div>
  ) : null
}

export default ImportListingsSection
