import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExportSurveySettingsAndLayout,
  SquareCheckbox,
} from '~/legacy/components';

const useStyles = makeStyles({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '60px',
    cursor: 'pointer',
  },
  checkbox: {
    marginLeft: 0,
    marginRight: '12px',
    color: '#111111',
  },
});

function MapAndBuildingsPageSettings(props) {
  const { settings, updateSettings } = props;
  const { showMap } = settings;
  const classes = useStyles();

  const toggleMap = () =>
    updateSettings({
      ...settings,
      showMap: !showMap,
    });
  return (
    <ExportSurveySettingsAndLayout {...props}>
      <div onClick={toggleMap} className={classes.checkboxContainer}>
        <SquareCheckbox
          className={classes.checkbox}
          checked={showMap}
          onClick={toggleMap}
        />
        <Typography variant="h3">Map</Typography>
      </div>
    </ExportSurveySettingsAndLayout>
  );
}

export default MapAndBuildingsPageSettings;
