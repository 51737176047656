import React, { forwardRef } from 'react';
import { TableRow as MuiTableRow, TableCell } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import {
  SquareCheckbox,
  DragIcon,
  SquareIconButton,
  MoreIcon,
  Typography,
} from '~/legacy/components';

const useTableHeaderText = makeStyles({
  tableHeadText: {
    lineHeight: '54px',
  },
});
export const TableHeaderText = ({ children }) => {
  const classes = useTableHeaderText();
  return (
    <Typography
      variant="tableHeader"
      classes={{ tableHeader: classes.tableHeadText }}
    >
      {children}
    </Typography>
  );
};

export const TableHeaderCell = withStyles({
  tableCell: {
    backgroundColor: '#F9F9F9',
  },
})(({ classes, children }) => {
  return (
    <TableContentCell
      classes={{
        tableCell: classes.tableCell,
      }}
    >
      {children}
    </TableContentCell>
  );
});

const useTableCellStyles = makeStyles({
  headerTableCell: {
    backgroundColor: '#F9F9F9',
  },
  blackBackground: {
    color: '#111',
  },
  cellDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
});

// Wrapper for TableCell
export const TableContentCell = withStyles({
  tableCell: {
    padding: '0px 0px 0px 22px',
    borderBottom: '1px solid #E0E0E0',
    overflow: 'hidden',
  },
})(({ children, classes, TableCellProps = {} }) => {
  return (
    <TableCell align="left" className={classes.tableCell} {...TableCellProps}>
      {children}
    </TableCell>
  );
});

// A checkbox in a TableCell
const useTableCheckboxCellStyles = makeStyles({
  checkboxCellDiv: {
    marginLeft: '12px',
  },
});
export const TableCheckboxCell = ({ isHeader = false, CheckboxProps = {} }) => {
  const tableCellClasses = useTableCellStyles();
  const tableCheckboxCellClasses = useTableCheckboxCellStyles();
  return (
    <TableContentCell
      classes={{
        tableCell: isHeader ? tableCellClasses.headerTableCell : '',
      }}
    >
      <div
        className={clsx(
          tableCellClasses.cellDiv,
          tableCheckboxCellClasses.checkboxCellDiv
        )}
      >
        <SquareCheckbox
          selectedCheckboxIconClasses={tableCellClasses.blackBackground}
          deSelectedCheckboxIconClasses={tableCellClasses.blackBackground}
          indeterminateCheckboxIconClasses={tableCellClasses.blackBackground}
          {...CheckboxProps}
        />
      </div>
    </TableContentCell>
  );
};

// A drag handle in a TableCell
const useTableDragCellStyles = makeStyles({
  cell: {
    padding: '0 0 0 8px',
  },
  iconContainer: {
    display: 'flex',
    cursor: 'pointer',
  },
});
export const TableDragCell = () => {
  const dragStyles = useTableDragCellStyles();
  return (
    <TableCell className={dragStyles.cell}>
      <div className={dragStyles.iconContainer}>
        <DragIcon />
      </div>
    </TableCell>
  );
};

// A more options icon in a TableCell
const useTableMoreOptionsCellStyles = makeStyles({
  moreOptionsTableCell: {
    padding: '0px 0px 0px 16px',
    '&:last-child': {
      paddingRight: '14px',
    },
  },
});
export const TableMoreOptionsCell = ({ onClick = () => {} }) => {
  const tableCellClasses = useTableCellStyles();
  const tableMoreOptionsCellClasses = useTableMoreOptionsCellStyles();
  return (
    <TableContentCell
      classes={{
        tableCell: tableMoreOptionsCellClasses.moreOptionsTableCell,
      }}
    >
      <div className={clsx(tableCellClasses.cellDiv)}>
        <SquareIconButton onClick={onClick}>
          <MoreIcon />
        </SquareIconButton>
      </div>
    </TableContentCell>
  );
};

// Text in a table cell
const useTableCellTextStyles = makeStyles({
  tableCellText: {
    letterSpacing: '0.1px',
    lineHeight: '21px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
});
export const TableCellText = ({ children }) => {
  const classes = useTableCellTextStyles();
  return (
    <Typography variant="body1" className={classes.tableCellText} noWrap>
      {children}
    </Typography>
  );
};

// Wrapper for TableRow
export const TableRow = withStyles({
  row: {
    height: '56px',
    '&:last-child td': {
      borderBottom: 'unset',
    },
  },
})(
  forwardRef(({ classes, children, TableRowClasses = {}, ...props }, ref) => {
    return (
      <MuiTableRow
        ref={ref}
        classes={TableRowClasses}
        className={clsx(classes.row)}
        {...props}
      >
        {children}
      </MuiTableRow>
    );
  })
);

// Header row for a table
const useTableHeaderRowStyles = makeStyles({
  headerRow: {
    height: '54px',
  },
});
export const TableHeaderRow = ({ children }) => {
  const classes = useTableHeaderRowStyles();
  return <TableRow classes={{ row: classes.headerRow }}>{children}</TableRow>;
};
