import React, { useEffect, useState } from 'react';
import Api from 'rest-fetcher-redux';
import {
  FormControl,
  OutlinedInput,
  Button,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSegment } from '~/legacy/utils/hooks';
import { Loading } from '~/legacy/components';

const useStyles = makeStyles({
  messageInputContainer: {
    paddingTop: '5px',
    width: '100%',
  },
  activityMessageInput: {
    paddingRight: '5px',
    letterSpacing: '0.1px',
  },
  adornment: {
    marginRight: '-9px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  filesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
    paddingTop: '5px',
  },
  nestedInputPadding: {
    '& .MuiOutlinedInput-inputMultiline': {
      paddingTop: '1px',
      paddingBottom: '1px',
    },
  },
});

function SurveyActivityCommentInput({ surveyId, setNewActivityMessage }) {
  const classes = useStyles();

  // New message content for the activity feed, allowing a user to create a new message
  const [newMessage, setNewMessage] = useState('');
  // Whether or not we are currently creating a new message
  const [creatingNewMessage, setCreatingNewMessage] = useState(false);
  // Whether or not there's missing required info for the new message input
  const [newMessageInputError, setNewMessageInputError] = useState(false);

  const segment = useSegment();
  const theme = useTheme();

  const createNewMessage = () => {
    // Create a new message by sending it through to the backend
    if (newMessage) {
      setCreatingNewMessage(true);
      Api.createSurveyComment({
        body: {
          text: newMessage,
          survey_id: surveyId,
          colors: theme.palette.primary,
        },
      })
        .then((results) => {
          if (results) {
            // Clear the message fields
            setNewMessage('');
            setNewActivityMessage(results);
            segment.trackCreateSurveyComment({
              surveyId,
              hasText: true,
            });
          }
        })
        .finally(() => {
          setCreatingNewMessage(false);
        });
    } else {
      setNewMessageInputError(true);
    }
  };

  useEffect(() => {
    // Whenever the user enters text, lets clear the error text
    if (newMessage) {
      setNewMessageInputError(false);
    }
  }, [newMessage]);

  return (
    <div className={classes.messageInputContainer}>
      <FormControl variant="filled" fullWidth>
        <OutlinedInput
          id="send-message"
          placeholder="Send a message"
          type="text"
          multiline
          className={clsx(
            classes.activityMessageInput,
            classes.nestedInputPadding
          )}
          value={newMessage}
          onChange={(event) => setNewMessage(event.target.value)}
          disabled={creatingNewMessage}
          error={newMessageInputError}
          endAdornment={
            <InputAdornment position="end">
              <Button
                color="primary"
                variant="text"
                disabled={creatingNewMessage}
                onClick={createNewMessage}
              >
                Send
              </Button>
              <Loading
                size={24}
                isLoading={creatingNewMessage}
                className={classes.buttonProgress}
              />
            </InputAdornment>
          }
        />
        <div className={classes.filesContainer}>
          {newMessageInputError && (
            <FormHelperText
              error
              id="send-message-error"
              key="send-message-error"
            >
              You must include a message
            </FormHelperText>
          )}
        </div>
      </FormControl>
    </div>
  );
}

export default SurveyActivityCommentInput;
