import {
  BUILDING_DETAIL_PAGE,
  LEASE_CREATE_BUILDING_LISTING,
  LEASE_CREATE_LISTING,
  LEASE_DASHBOARD,
  LEASE_EDIT_LISTINGS_BY_ID,
  LEASE_EDIT_SURVEY_LISTINGS_BY_ID,
  LEASE_PROJECTS,
  LEASE_PROJECT_BY_ID,
  LEASE_SURVEY_BUILDING_CREATE_LISTING,
  LEASE_SURVEY_CREATE_LISTING,
  LEASE_SURVEY_VIEW_LISTING,
  LEASE_VIEW_LISTINGS_BY_ID_YOUR,
  PROJECT_SURVEY_VIEW_BY_ID,
} from '~/legacy/consts'
import {
  captureSentryExceptionError,
  captureSentryMessageError,
  isBrokerUserType,
} from '~/legacy/utils'

const getCreateListingRoute = (
  buildingId = null,
  surveyId = null,
  placeId = null
) => {
  let url
  if (surveyId) {
    if (buildingId) {
      return LEASE_SURVEY_BUILDING_CREATE_LISTING.replace(
        ':survey_id',
        surveyId
      ).replace(':building_id', buildingId)
    }
    url = LEASE_SURVEY_CREATE_LISTING.replace(':survey_id', surveyId)
  } else if (buildingId) {
    url = LEASE_CREATE_BUILDING_LISTING.replace(':building_id', buildingId)
  } else {
    url = LEASE_CREATE_LISTING
  }

  if (placeId) {
    url += `?place_id=${placeId}`
  }

  return url
}

const getViewBuildingRoute = (surveyId = null, buildingId = null) =>
  BUILDING_DETAIL_PAGE.replace(':survey_id', surveyId).replace(
    ':building_id',
    buildingId
  )
const getViewListingRoute = (listingId, surveyId = null) => {
  if (surveyId) {
    return LEASE_SURVEY_VIEW_LISTING.replace(':survey_id', surveyId).replace(
      ':listing_id',
      listingId
    )
  }
  return LEASE_VIEW_LISTINGS_BY_ID_YOUR.replace(':listing_id', listingId)
}

const getEditListingRoute = (listingId, surveyId = null) => {
  if (surveyId) {
    return LEASE_EDIT_SURVEY_LISTINGS_BY_ID.replace(
      ':survey_id',
      surveyId
    ).replace(':listing_id', listingId)
  }
  return LEASE_EDIT_LISTINGS_BY_ID.replace(':listing_id', listingId)
}

const getViewSurveyRoute = (surveyId) => {
  return LEASE_PROJECT_BY_ID.replace(':id', surveyId)
}

const getViewProjectSurveyRoute = (projectId, surveyId) => {
  return PROJECT_SURVEY_VIEW_BY_ID.replace(':project_id', projectId).replace(
    ':survey_id',
    surveyId
  )
}

const openLinkInNewTab = (link) => {
  window.open(link, '_blank', 'noopener,noreferrer')
}

const getUserRedirectForHomepage = (userType) => {
  if (isBrokerUserType(userType)) {
    return LEASE_PROJECTS
  }
  return LEASE_DASHBOARD
}

class RootPermissionError extends Error {
  // pass
}

class LoggableError extends Error {
  constructor(error, logged = false) {
    super(error.message || 'Error accessing API')
    this.error = error
    this.logged = logged
  }

  logToSentry() {
    captureSentryExceptionError(this.error)
    this.logged = true
  }
}

class ApiError extends LoggableError {
  constructor(response, logged = false) {
    super('Error accessing API', logged)
    this.response = response
  }

  logToSentry({ message = '', fetchArgs = {} }) {
    captureSentryMessageError({
      message:
        message || `Error fetching from API - ${this.response.statusText}`,
      tags: { url: this.response.url, status: this.response.status },
      extras: { statusText: this.response.statusText, fetchArgs },
    })
    this.logged = true
  }
}

export {
  getViewListingRoute,
  getViewBuildingRoute,
  getCreateListingRoute,
  getEditListingRoute,
  getViewSurveyRoute,
  openLinkInNewTab,
  getUserRedirectForHomepage,
  ApiError,
  LoggableError,
  RootPermissionError,
  getViewProjectSurveyRoute,
}
