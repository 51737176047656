import React from 'react';
import { withStyles } from '@material-ui/core/styles';

export const PageDivider = withStyles({
  root: {
    width: '100%',
    minHeight: '1px',
    height: '1px',
  },
  divider: {
    width: '100%',
    height: '100%',
    backgroundColor: '#E0E0E0',
  },
})(({ classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.divider} />
    </div>
  );
});
