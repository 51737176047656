import React from 'react';
import { Menu } from '@material-ui/core';
import {
  CreateNewFieldMenuItem,
  AddFromAnotherMenuItem,
} from '~/legacy/components';

export default function CustomFieldMenu({
  onClickCreateNew,
  onClickAddAnother,
  type,
  ...menuProps
}) {
  return (
    <Menu disableAutoFocusItem {...menuProps}>
      <CreateNewFieldMenuItem onClick={onClickCreateNew} />
      <AddFromAnotherMenuItem onClick={onClickAddAnother} type={type} />
    </Menu>
  );
}
