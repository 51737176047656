import React, { useState } from 'react';
import { Menu } from '@material-ui/core';
import {
  DeleteListingMenuItem,
  SquareIconButton,
  MoreIcon,
} from '~/legacy/components';

export default function ListingMenu({ openDeleteListingsModal }) {
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  // Handle popover menu open/close
  const handleMenuClick = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };
  return (
    <>
      <SquareIconButton
        onClick={(e) => {
          handleMenuClick(e);
        }}
      >
        <MoreIcon />
      </SquareIconButton>
      <Menu
        anchorEl={anchorMenuEl}
        open={!!anchorMenuEl}
        onClose={handleMenuClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <DeleteListingMenuItem
          handleMenuClose={handleMenuClose}
          openDeleteListingsModal={openDeleteListingsModal}
        />
      </Menu>
    </>
  );
}
