import React, { useState } from 'react'
import { TextInput } from '~/legacy/components'
import { styled } from '~/legacy/utils/styleHelpers'

export const SimpleInputSelect = ({
  children,
  label,
  options = [],
  onSelect,
  onCreate,
  value = '',
  onChange,
  ...props
}) => {
  const [focused, setFocused] = useState(false)

  const filtered = options
    .filter((option) => option.value.includes(value))
    .slice(0, 5)

  return (
    <Container {...props}>
      <TextInput
        label={label}
        value={value}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setTimeout(() => setFocused(false), 500)}
      />

      {focused && (
        <Menu>
          {filtered.length ? (
            filtered.map((option) => (
              <Item
                key={option.value}
                tabIndex="0"
                onClick={() => {
                  if (onSelect) onSelect(option)
                  setFocused(false)
                }}
                dangerouslySetInnerHTML={{
                  __html: option.value.replace(value, `<b>${value}</b>`),
                }}
              />
            ))
          ) : (
            <Item
              onClick={() => {
                if (onCreate) onCreate()
                setFocused(false)
              }}
            >
              {children}
            </Item>
          )}
        </Menu>
      )}
    </Container>
  )
}

const Container = styled('div', {
  position: 'relative',
})

const Menu = styled('div', {
  zIndex: 100000000000,
  backgroundColor: 'white',
  borderRadius: '4px',
  color: '#111',
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)',
  position: 'absolute',
  padding: '8px 0px',
  marginTop: '4px',
  width: '100%',
})

const Item = styled('div', {
  height: '38px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 20px',
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '14px',
  letterSpacing: '0.1px',
  textAlign: 'left',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f3f3f3',
  },
  '&:focus': {
    backgroundColor: '#f3f3f3',
  },
})
