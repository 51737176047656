import { useState } from 'react'
import { useEventListener } from './useEventListener'
import { valueOf } from './valueOf'

export const useSetting = (key, initial) => {
  const [state, setState] = useState(() => {
    const cache = localStorage.getItem(key)
    return cache === null ? valueOf(initial) : JSON.parse(cache)
  })

  useEventListener('storage', [key], (event) => {
    if (event.key === key && event.newValue) {
      setState(JSON.parse(event.newValue))
    }
  })

  const save = (value) => {
    setState((current) => {
      const resolved = valueOf(value, current)
      localStorage.setItem(key, JSON.stringify(resolved))
      return resolved
    })
  }

  return [state, save]
}
