import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loading: {
    position: 'absolute',
    color: '#111',
  },
});

export default function TextButton({
  loading = false,
  disabled = false,
  children,
  classesIn = {},
  ...buttonProps
}) {
  const classes = useStyles();

  return (
    <Button
      size="small"
      variant="text"
      disabled={loading || disabled}
      className={classesIn.button}
      {...buttonProps}
    >
      {loading ? (
        <>
          <CircularProgress
            classes={{ root: classes.exportLoader }}
            size={18}
            className={classes.loading}
          />
          {children}
        </>
      ) : (
        children
      )}
    </Button>
  );
}
