import { Segment } from '~/legacy/utils/hooks';
import { fetchApi } from './rootApi';

const SURVEY_BUILDING_COMMENT_ENDPOINT_ROOT = 'survey_building_comments';

export const surveyBuildingCommentApi = {
  updateComment: async (buildingCommentId, newCommentPartial) =>
    fetchApi({
      endpoint: `${SURVEY_BUILDING_COMMENT_ENDPOINT_ROOT}/${buildingCommentId}/`,
      fetchArgs: {
        method: 'PATCH',
        body: JSON.stringify(newCommentPartial),
      },
    }),

  deleteComment: async (buildingCommentId) =>
    fetchApi({
      endpoint: `${SURVEY_BUILDING_COMMENT_ENDPOINT_ROOT}/${buildingCommentId}/`,
      fetchArgs: {
        method: 'DELETE',
      },
    }),

  createComment: async (surveyId, buildingId, newComment) => {
    const promise = fetchApi({
      endpoint: `${SURVEY_BUILDING_COMMENT_ENDPOINT_ROOT}/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify(newComment),
      },
    });
    promise.then(() => {
      Segment.trackCreateSurveyBuildingComment({
        surveyId,
        buildingId,
        hasText: !!newComment,
      });
    });
    return promise;
  },
};
