import React, { Fragment, useMemo } from 'react'

export const Database = (props) => {
  const { className, columns, size, data } = props

  const gridTemplateColumns = useMemo(() => {
    return columns.map((column) => column.size || size || '1fr').join(' ')
  }, [columns, size])

  return (
    <div style={{ display: 'grid', gridTemplateColumns }} className={className}>
      {columns.map((column) => {
        const key = `header-${column.id}`
        if (column.header) return column.header(key, column)
        if (props.header) return props.header(key, column)
        return <div key={key}>{column.name ?? column.key}</div>
      })}

      {data.map((row) => (
        <Fragment key={`row-${row.id}`}>
          {columns.map((column) => {
            const key = `cell-${row.id}-${column.id}`
            if (column.cell) return column.cell(key, column, row)
            if (props.cell) return props.cell(key, column, row)
            return <div key={key}>{row[column.key]}</div>
          })}
        </Fragment>
      ))}
    </div>
  )
}
