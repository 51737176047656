import React from 'react';
import { useSelector } from 'react-redux';

import { Breadcrumbs } from '~/legacy/components';
import {
  LEASE_PROJECTS,
  LEASE_YOUR_LISTINGS,
  LEASE_PROJECT_BY_ID,
  LEASE_DASHBOARD,
} from '~/legacy/consts';
import {
  numberWithCommas,
  isBrokerUserType,
  getViewListingRoute,
} from '~/legacy/utils';

// Poor man's composition. Rules for specific page breadcrumbs
export const DEFAULT = 1;
export const CREATE_LISTING = 2;
export const EDIT_LISTING = 3;
export const COMPARE_SURVEY_SPACES = 4;
export const EXPORT_SURVEY = 5;

/**
 * Build the breadcrumb for the Survey flow (start at a survey, navigate further to listings/buildings)
 * If no survey is provided, then we fallback to Listing flow (no survey associated)
 * ie:
 *   DEFAULT
 *     Survey > {survey_name} > {building_name} > {listing_name}
 *     Listings > {building_name} > {listing_name}
 *  CREATE_LISTING
 *     Survey > {survey_name} > Create Listing
 *     Listings > {building_name} > Create listing
 *  COMPARE_SURVEY_SPACES
 *     Survey > {survey_name} > > Compare Spaces
 *
 */
function SurveyBreadcrumb({
  surveyId = null,
  surveyName = null,
  buildingName = null,
  listingAddress2 = null,
  listingSize = null,
  listingSqftPrice = null,
  listingId = null,
  pageType = DEFAULT, // TODO: Poor man's composition
}) {
  const userType = useSelector((s) => s.user.userType);
  const pieces = [];

  // Survey > {survey_name} >
  // Listings >
  if (surveyId) {
    pieces.push({ path: LEASE_PROJECTS, name: 'Surveys' });
    pieces.push({
      path: LEASE_PROJECT_BY_ID.replace(':id', surveyId),
      name: surveyName,
    });
  } else {
    pieces.push({
      path: isBrokerUserType(userType) ? LEASE_YOUR_LISTINGS : LEASE_DASHBOARD,
      name: 'Listings',
    });
  }

  if (buildingName) {
    pieces.push({
      name: buildingName,
    });
  }

  // ... {listing_name} >
  if (listingAddress2 || (listingSize && listingSqftPrice)) {
    pieces.push({
      name:
        listingAddress2 ||
        `${numberWithCommas(listingSize)} SqFt, $${numberWithCommas(
          listingSqftPrice
        )}`,
      path: listingId ? getViewListingRoute(listingId, surveyId) : null,
    });
  }

  // ... Create listing
  if (pageType === CREATE_LISTING) {
    pieces.push({
      name: 'Create Listing',
    });
    // ... Edit Listing
  } else if (pageType === EDIT_LISTING) {
    pieces.push({
      name: 'Edit Listing',
    });
  } else if (pageType === COMPARE_SURVEY_SPACES) {
    pieces.push({
      name: 'Compare Spaces',
    });
    // Export Survey
  } else if (pageType === EXPORT_SURVEY) {
    pieces.push({
      name: 'Survey PDF',
    });
  }

  return <Breadcrumbs pieces={pieces} />;
}

export default SurveyBreadcrumb;
