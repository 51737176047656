import React, { useEffect, useState, useCallback } from 'react'
import { COMMENT_DELETE, COMMENT_EDIT } from '~/legacy/consts'

const UPDATE_ACTIVITY_TIME_MS = 1000 * 60 * 5
const ActivityContext = React.createContext({})

/**
 * Provider of activity info used for activity feeds
 */
function ActivityProvider({ children }) {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [activities, setActivities] = useState([])
  const [isLoadingFeed, setIsLoadingFeed] = useState(true)
  const [show, setShow] = useState(false)

  useEffect(() => {
    // Update the activity time badges every 5 minutes
    const interval = setInterval(
      () => setCurrentDate(new Date()),
      UPDATE_ACTIVITY_TIME_MS
    )

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    // Immediately set show to true after the component mounts
    if (!show) {
      setShow(true)
    }
  }, [show])

  // Handle a new incoming activity message - can add, update, delete
  const setNewActivityMessage = useCallback((newActivityMessage) => {
    if (newActivityMessage) {
      setActivities((currentActivities) => {
        // Delete the activity
        if (newActivityMessage.type === COMMENT_DELETE) {
          return [
            ...currentActivities.filter(
              (msg) => msg.uuid !== newActivityMessage.uuid_to_remove
            ),
          ]
        }
        // Edit the existing activity
        if (newActivityMessage.type === COMMENT_EDIT) {
          return currentActivities.map((activity) =>
            activity.uuid === newActivityMessage.uuid_to_edit
              ? {
                  ...activity,
                  text: newActivityMessage.text,
                  edited: newActivityMessage.edited,
                }
              : activity
          )
        }
        // Update if we have it
        if (currentActivities.find((a) => a.uuid === newActivityMessage.uuid)) {
          return currentActivities.map((activity) =>
            activity.uuid === newActivityMessage.uuid
              ? { ...activity, ...newActivityMessage }
              : { ...activity }
          )
        }
        // Otherwise, add it
        return [{ ...newActivityMessage, isNew: true }, ...currentActivities]
      })
    }
  }, [])

  const value = {
    currentDate,
    activities,
    setActivities,
    setNewActivityMessage,
    isLoadingFeed,
    setIsLoadingFeed,
    show,
  }

  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  )
}

export { ActivityContext, ActivityProvider }
