import { styled } from '~/libraries/styled'

const fonts = {
  400: 'Inter-Regular',
  500: 'Inter-Medium',
  600: 'Inter-Semibold',
}

const props = [
  'color',
  'format',
  'grow',
  'inline',
  'nowrap',
  'padding',
  'truncate',
  'upper',
]

export const Font = styled('div', props, (props) => {
  const format = props.get('format', '').split('/')
  const size = format[0] ?? 16
  const height = format[1] ?? size
  const color = props.get('color', '#111111')

  return {
    display: props.when('inline', 'inline'),
    fontFamily: fonts[format[2]] ?? fonts['400'],
    fontSize: `${size}px`,
    lineHeight: `${height}px`,
    color: props.get(color, color),
    letterSpacing: props.when('upper', '0.4px'),
    overflow: props.when('truncate', 'hidden'),
    padding: props.get('padding'),
    textOverflow: props.when('truncate', 'ellipsis'),
    textTransform: props.when('upper', 'uppercase'),
    whiteSpace: (props.get('nowrap') || props.get('truncate')) && 'nowrap',
    flexGrow: props.when('grow', 1),
    textRendering: 'geometricprecision',
  }
})
