import React from 'react'
import { makeStyles } from '@material-ui/core'
import Logo from '../assets/Cresa'

const useStyles = makeStyles({
  grid: {
    gridTemplateColumns: 'repeat(6, 1fr)',
    position: 'absolute',
    top: '20px',
    left: '16px',
    display: 'grid',
    gap: '19.75px',
    zIndex: -1,
    '& > *': {
      width: '3px',
      height: '3px',
    },
  },

  dot: {
    background: '#FFB600',
  },

  logo: {
    position: 'absolute',
    top: '36px',
    right: '36px',
    color: '#001E5A',
    zIndex: 1,
  },

  title: {
    position: 'absolute',
    left: '66px',
    top: '232px',
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: '50px',
    lineHeight: '38px',
    letterSpacing: '-0.0175em',
    color: '#001E5A',
  },

  underline: {
    position: 'absolute',
    left: '62px',
    right: '30px',
    top: '293px',
    background: '#FFB600',
    height: '3px',
  },

  list: {
    position: 'absolute',
    top: '311px',
    left: '211px',
  },

  item: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '36px',
    color: '#000000',
  },

  divider: {
    color: '#FFB600',
  },
})

export const CresaDivider = ({ title, items = [] }) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.grid}>
        {Array.from({ length: 6 * 34 }, (_, id) => {
          const y = Math.floor(id / 6)
          const x = id % 6
          return (
            <div
              key={id}
              className={y < 9 || y > 12 || x < 2 ? classes.dot : undefined}
            />
          )
        })}
      </div>
      <Logo className={classes.logo} />
      <div className={classes.title}>{title}</div>
      <div className={classes.underline} />
      <div className={classes.list}>
        {items.map((item, index) => (
          <div key={item} className={classes.item}>
            {`${index + 1} `}
            <span className={classes.divider}>|</span>
            {` ${item}`}
          </div>
        ))}
      </div>
    </div>
  )
}
