import React from 'react';
import { makeStyles } from '@material-ui/core';
import { PhotoIcon } from './svgs';
import { Typography } from './themeComponents';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    paddingRight: '8px',
    borderRadius: '4px',
    position: 'absolute',
    bottom: '8px',
    left: '8px',
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff',
    cursor: 'pointer',
  },
  icon: {
    marginRight: '4px',
  }
}));

export default function PhotosButton({ imageCount, isUserBroker, openUploadModal = () => {} }) {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={openUploadModal}>
      <PhotoIcon className={classes.icon} />
      <Typography variant="bodyBold">
        {isUserBroker && 'Add'}
        {!isUserBroker && `${imageCount || ''}`}
      </Typography>
    </div>
  )
}
