import React from 'react';

export default function TransitScoreIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="7" y="2" width="10" height="1.5" fill="#111111" />
      <path d="M11.6309 5.75L13.1309 2.75H14.1309" stroke="#111111" strokeWidth="1.5" />
      <rect x="6" y="8.25" width="12" height="1.5" fill="#111111" />
      <rect x="6" y="14.25" width="12" height="1.5" fill="#111111" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 5.25C10.4761 5.25 9.30088 5.3385 8.39482 5.50629C7.4939 5.67313 6.80836 5.92725 6.30662 6.2952C5.24737 7.07198 5.24898 8.17582 5.25007 8.92949L5.25014 16.5C5.25014 17.1518 5.31087 17.7636 5.56073 18.2747C5.83436 18.8344 6.28799 19.1849 6.87289 19.3938C7.42044 19.5894 8.1076 19.6704 8.91655 19.71C9.7318 19.75 10.7468 19.75 11.9811 19.75H12.0192C13.2535 19.75 14.2685 19.75 15.0837 19.71C15.8927 19.6704 16.5798 19.5894 17.1274 19.3938C17.7123 19.1849 18.1659 18.8344 18.4396 18.2747C18.6894 17.7636 18.7501 17.1518 18.7501 16.5L18.7502 8.92949C18.7513 8.17582 18.7529 7.07198 17.6937 6.2952C17.1919 5.92725 16.5064 5.67313 15.6055 5.50629C14.6994 5.3385 13.5242 5.25 12.0001 5.25ZM12.0001 6.75C10.5242 6.75 9.4494 6.8365 8.66796 6.98121C7.88138 7.12687 7.44192 7.32275 7.19366 7.5048C6.78645 7.80343 6.75014 8.15396 6.75014 9V16.5C6.75014 17.0982 6.81441 17.4239 6.9083 17.6159C6.97842 17.7593 7.0873 17.8776 7.37739 17.9812C7.70484 18.0981 8.20518 18.1734 8.98999 18.2118C9.7643 18.2498 10.743 18.25 12.0001 18.25C13.2572 18.25 14.236 18.2498 15.0103 18.2118C15.7951 18.1734 16.2954 18.0981 16.6229 17.9812C16.913 17.8776 17.0219 17.7593 17.092 17.6159C17.1859 17.4239 17.2501 17.0982 17.2501 16.5V9C17.2501 8.15396 17.2138 7.80343 16.8066 7.5048C16.5584 7.32275 16.1189 7.12687 15.3323 6.98121C14.5509 6.8365 13.4761 6.75 12.0001 6.75Z"
        fill="#111111"
      />
      <path d="M10.2803 19.5304L9.21967 18.4697L6 21.6894V22H7.81072L10.2803 19.5304Z" fill="#111111" />
      <path d="M13.7197 19.5304L14.7803 18.4697L18 21.6894V22H16.1893L13.7197 19.5304Z" fill="#111111" />
      <rect x="11.25" y="15" width="1.5" height="4" fill="#111111" />
    </svg>
  );
}
