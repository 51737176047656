import React from 'react'
import { definePageTemplate } from '~/pdfs/support/definePageTemplate'
import { CresaDivider } from '../patterns/CresaDivider'

export const CresaSummaryDivider = definePageTemplate({
  id: 'b8b29fea-ac76-4250-9e7c-ab8838a0d80e',

  name: 'Summary of Properties Divider',

  Page: () => {
    return <CresaDivider title="Summary of Properties" />
  },
})
