import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextInput } from '~/legacy/components';

import ModalTitle from './ModalTitle';

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '12px 0',
  },
  cancelButton: {
    marginRight: '8px',
  },
  input: {
    width: '400px',
    marginBottom: '16px',
  },
  modalContent: {
    background: 'white',
    outline: 'none',
    borderRadius: 7,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: 'auto',
  },
});

function EditSpaceDetailsModal({ space, onClose, updateListing }) {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: space.address2,
    description: space.description,
  });

  return (
    <div className={classes.modalContent}>
      <ModalTitle onClose={onClose} title="Space Details" />
      <TextInput
        className={classes.input}
        label="Space Name"
        onChange={(event) => setValues({ ...values, name: event.target.value })}
        placeholder="Add space name"
        value={values.name}
      />
      <TextInput
        className={classes.input}
        label="Description"
        multiline
        onChange={(event) =>
          setValues({ ...values, description: event.target.value })
        }
        placeholder="Add space description"
        rows={10}
        value={values.description}
      />
      <div className={classes.buttons}>
        <Button className={classes.cancelButton} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={
            values.name === space.address2 &&
            values.description === space.description
          }
          onClick={() => {
            const newValues = {};
            if (values.name !== space.address2) {
              newValues.address2 = values.name;
            }
            if (values.description !== space.description) {
              newValues.description = values.description;
            }
            updateListing(newValues);
            onClose();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default EditSpaceDetailsModal;
