import React from 'react';
import { ButtonBase, CircularProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { TooltipV2 } from '~/legacy/components';

const PADDING_PX = 2;
const SQUARE_SVG_DIMENSION_PX = 24;
const BUTTON_Z_INDEX = 2;
const useStyles = makeStyles({
  buttonRoot: {
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#E0E0E0',
      transition: '200ms',
    },
  },
  buttonBase: {
    borderRadius: '4px',
    padding: `${PADDING_PX}px`,
    width: `${SQUARE_SVG_DIMENSION_PX + PADDING_PX * 2}px`,
    height: `${SQUARE_SVG_DIMENSION_PX + PADDING_PX * 2}px`,
    zIndex: BUTTON_Z_INDEX,
  },
  opaque: {
    opacity: '25%',
    margin: 'auto',
    zIndex: 2,
  },
  loading: {
    position: 'absolute',
    color: '#111',
  },
});

const NotificationBadge = withStyles({
  circle: {
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    backgroundColor: '#DD421A',
    position: 'absolute',
    top: `${PADDING_PX}px`,
    right: `${PADDING_PX}px`,
    zIndex: BUTTON_Z_INDEX + 1,
  },
})(({ classes }) => {
  return <div className={classes.circle} />;
});

const SquareIconButton = React.forwardRef(
  (
    {
      loading = false,
      showAsDisabled = false,
      hasNotificationBadge = false,
      className = '',
      children,
      containedIconClasses = '',
      buttonRootClasses = '',
      tooltipText = '',
      TooltipProps = {},
      ...iconButtonProps
    },
    ref
  ) => {
    const classes = useStyles();

    const ContainedIcon = () => {
      if (showAsDisabled || loading) {
        return (
          <div className={clsx(classes.opaque, containedIconClasses)}>
            {children}
          </div>
        );
      }
      return children;
    };

    const items = [];
    items.push(<ContainedIcon key="contained-icon" />);
    if (loading) {
      items.push(
        <CircularProgress
          key="circular-progress"
          classes={{ root: classes.exportLoader }}
          size={18}
          className={classes.loading}
        />
      );
    } else if (hasNotificationBadge) {
      items.push(<NotificationBadge key="notification-badge" />);
    }

    const buttonComponent = (
      <ButtonBase
        size="small"
        className={clsx(classes.buttonBase, className)}
        classes={{ root: clsx(classes.buttonRoot, buttonRootClasses) }}
        disabled={loading}
        ref={ref}
        {...iconButtonProps}
      >
        {items}
      </ButtonBase>
    );

    if (tooltipText) {
      return (
        <TooltipV2
          title={tooltipText}
          placement="bottom"
          enterDelay={0}
          {...TooltipProps}
        >
          <span>{buttonComponent}</span>
        </TooltipV2>
      );
    }
    return buttonComponent;
  }
);

export default SquareIconButton;
