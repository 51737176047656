import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import clsx from 'clsx'

export const Group = withStyles({
  container: { '& + &': { marginTop: '32px' } },
  label: { color: '#666666', marginBottom: -2 },
})(({ children, classes, className, label }) => (
  <div className={clsx(classes.container, className)}>
    {label && (
      <Typography className={classes.label} variant="h5">
        {label}
      </Typography>
    )}
    {children}
  </div>
))
