import * as Sentry from '@sentry/browser'

if (process.env.APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.APP_SENTRY_DSN,
    environment: process.env.APP_DEPLOY_ENV,
    integrations: [
      Sentry.feedbackIntegration({
        colorScheme: 'system',
        autoInject: false,
      }),
    ],
  })
}
