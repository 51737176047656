import { toast } from '~/legacy/utils/notifications';

export default {
  getBuildingDetails: {
    url: '/buildings/:id/',
  },
  updateBuilding: {
    url: '/buildings/:id/',
    options: {
      method: 'PATCH',
    },
    onSuccess: [
      () =>
        toast('Building updated successfully', {
          appearance: 'success',
        }),
    ],
    onError: [
      (result) => {
        if (
          result &&
          result.message &&
          result.message.file_ids &&
          result.message.file_ids.length === 1 &&
          result.message.file_ids[0].includes('it should contain no more than')
        ) {
          toast('Only 50 photos and attachments are allowed for a building.', {
            appearance: 'danger',
          });
        }
      },
    ],
  },
  getRecentBuildings: {
    url: '/buildings/get_recent_buildings/',
  },
  getAvailableAddresses: {
    url: '/buildings/get_available_addresses/',
  },
  addBuildingCustomField: {
    url: '/buildings/:id/add_custom_field/',
    options: {
      method: 'POST',
    },
  },
  addBuildingCustomFieldsFromBuilding: {
    url: '/buildings/add_custom_fields_from_another_building/',
    options: {
      method: 'PUT',
    },
  },
  updateBuildingCustomField: {
    url: '/buildings/:id/update_custom_field/',
    options: {
      method: 'POST',
    },
  },
  deleteBuildingCustomField: {
    url: '/buildings/:id/delete_custom_field/',
    options: {
      method: 'POST',
    },
  },
  addBuildingHeroPhoto: {
    url: '/buildings/:id/add_building_hero_photo/',
    options: {
      method: 'POST',
    },
  },
  updateBuildingAddress: {
    url: '/buildings/:id/update_address/',
    options: {
      method: 'POST',
    },
  },
};
