import React, { forwardRef } from 'react';
import { Typography as MuiTypography, withStyles } from '@material-ui/core';
import clsx from 'clsx';

const style = {
  boldTextSmall: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: 12,
    lineHeight: '12px',
  },
  boldText: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  boldTiny: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '10px',
    lineHeight: '10px',
    letterSpacing: '0.1px',
  },
  textTiny: {
    fontFamily: ['Inter-Regular'],
    fontSize: 10,
    lineHeight: '16px',
  },
  textSmall: {
    fontFamily: ['Inter-Regular'],
    fontSize: 12,
    lineHeight: '12px',
  },
  textNormal: {
    fontFamily: ['Inter-Regular'],
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.1px',
  },
  pageTitle: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '32px',
    lineHeight: '32px',
    letterSpacing: 0,
  },
  pageSubTitle: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: 22,
    lineHeight: '22px',
  },
  tableHeader: {
    fontFamily: ['Inter-Medium'],
    fontSize: '12px',
    letterSpacing: '.4px',
    lineHeight: '12px',
    color: '#666666',
  },
  tableHeaderAlt: {
    fontFamily: ['Inter-Medium'],
    fontSize: '14px',
    letterSpacing: '.4px',
    lineHeight: '12px',
    color: '#666666',
  },
  bodyBold: {
    fontFamily: ['Inter-SemiBold'],
    letterSpacing: '0.1px',
    fontSize: 14,
    lineHeight: '22px',
  },
  bodyText: {
    fontFamily: ['Inter-Regular'],
    fontSize: 14,
    lineHeight: '22px',
  },
  numberOnCard: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: 20,
    lineHeight: '24px',
  },
  sectionTitle: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '22px',
    lineHeight: '22px',
  },
  link: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  projectState: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '12px',
    letterSpacing: '.4px',
    lineHeight: '12px',
    color: '#666',
  },
  chip: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.1px',
  },
  subSectionTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
    color: '#666',
  },
  imageOverlayText: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
    color: '#FFF',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: 0,
  },
  menuItemText: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  emptyStateHeader: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '18px',
    lineHeight: '24px',
  },
  tooltip: {
    fontFamily: ['Inter-SemiBold'],
    fontSize: '10px',
    lineHeight: '10px',
    letterSpacing: '0.1px',
  },
};

/**
 * Overrides default material UI typography with some additional variants
 * Poor man's custom theme variations for MUI V4.X
 * TODO: When updating to v5, can easily replace with custom theme variants
 */
const Typography = forwardRef(({ ellipsis, variant, classes, className, ...props }, ref) => {
  const classVariant = classes[variant];

  return (
    <MuiTypography
      ref={ref}
      className={clsx(classVariant, ellipsis && classes.ellipsis, className)}
      variant={classVariant ? undefined : variant}
      {...props}
    />
  );
});

export default withStyles(style)(Typography);
