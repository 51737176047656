import React from 'react';
import { Link } from '@material-ui/core';

const SampleBulkCsvLink = () => {
  return (
    <Link
      underline="none"
      href="/static/media/listings_template.csv"
      download="listings_template.csv"
    >
      example CSV
    </Link>
  );
};

export default SampleBulkCsvLink;
