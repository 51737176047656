import React, { useState } from 'react';
import { Menu as MenuMui } from '@material-ui/core';

export const Menu = ({children, ...props}) => {
  return (
    <MenuMui
      elevation={2}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    >
      {children}
    </MenuMui>
  );
}

export const useMenuAnchor = () => {
  const [anchorMenuEl, setAnchorMenuEl] = useState({});

  const handleMenuClose = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorMenuEl({ ...anchorMenuEl, anchor: null });
  };

  return {
    anchorMenuEl,
    setAnchorMenuEl,
    handleMenuClose,
  }
}

export const DefaultMenu = ({
  children,
  anchorMenuEl,
  handleMenuClose,
  ...props
}) => {
  return (
    <Menu
      anchorEl={anchorMenuEl ? anchorMenuEl.anchor : null}
      open={!!(anchorMenuEl && anchorMenuEl.anchor)}
      onClose={handleMenuClose}
      getContentAnchorEl={null}
      {...props}
    >
      {children}
    </Menu>
  );
}
