export function createCache(namespace) {
  namespace = Array.isArray(namespace) ? namespace : [namespace]
  return {
    get: (id) => {
      try {
        const key = namespace.concat(id).join(':')
        const cache = JSON.parse(localStorage.getItem(key))

        if (Date.now() > cache.exp) {
          localStorage.removeItem(key)
          return null
        }

        return cache.value
      } catch {
        return null
      }
    },
    set: (id, duration, value) => {
      try {
        const key = namespace.concat(id).join(':')
        const cache = { exp: Date.now() + duration, value }
        localStorage.setItem(key, JSON.stringify(cache))
      } catch {
        //
      }
    },
  }
}
