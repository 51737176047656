import {
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tabs,
} from '@material-ui/core'
import debounce from 'lodash/debounce'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import Api from 'rest-fetcher-redux'
import {
  AddressAutocomplete,
  Amenities,
  Button,
  Modal,
  ModalComponent,
  MODALS,
  SquareIconButton,
  TextChevronDown,
  TextInput,
  Typography,
  useFieldMenu,
} from '~/legacy/components'
import { DragIcon } from '~/legacy/components/svgs'
import {
  EditableCell,
  TableContentCell,
  TableHeaderCell,
  TableHeaderRow,
  TableHeaderText,
  TableRow,
} from '~/legacy/components/tableComponents'
import { EditLocationForm } from '~/legacy/pages/Surveys/Survey/EditLocationForm'
import {
  arrayMove,
  BACKEND_FOR_DND,
  getBuildingPrimaryName,
  getIconByDataTypeId,
  getModelFieldNameByDisplayName,
  getPlural,
} from '~/legacy/utils'
import { useStupidRefresh } from '~/support/useStupidRefresh'
import CustomFieldMenu from '../customFields/CustomFieldMenu'
import FullScreenTitle from './FullScreenTitle'
import { AddCustomFieldTableRow } from './shared/AddCustomFieldTableRow'

const useStyles = makeStyles({
  tabs: {
    // padding: '0px',
    borderBottom: '1px solid #e0e0e0',
    // backgroundColor: '#ffffff',
    // position: 'sticky',
    // width: '100%',
  },
  tab: {
    minWidth: 'unset',
  },
  modal: {
    width: '100vw',
    overflow: 'hidden',
    '&:first-child': {
      padding: '0',
    },
  },
  body: {
    // width: '100%',
    width: '100%',
    marginTop: '60px', // for the header
    paddingTop: '70px',
    overflowY: 'auto',
    display: 'flex',
  },
  innerBody: {
    // maxWidth: '640px',
    width: '640px',
    height: 'fit-content',
    margin: '0 auto',
    marginBottom: '80px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    paddingBottom: '24px',
    width: '100%',
    // borderBottom: '1px solid #e0e0e0',
  },
  formArea: {
    display: 'grid',
    gap: '28px',
    padding: '40px 0',
    borderBottom: '1px solid #e0e0e0',
  },
  tableArea: {
    marginBottom: '40px',
  },
  tableAreaHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '16px 0',
  },
  addFieldButton: {
    minWidth: '0',
    height: '36px',
    textTransform: 'none',
  },
  addFieldButtonIcon: {
    marginLeft: 0,
  },
  addFieldMenu: {
    marginTop: '8px',
  },
  metadataTable: {
    tableLayout: 'fixed',
    borderRadius: '4px',
    borderCollapse: 'separate',
    border: '1px solid #E0E0E0',

    '& tr': {
      '& th:not(:last-child), td:not(:last-child)': {
        borderRight: '1px solid #e0e0e0',
      },
    },

    // Table border radiuses
    '& tr:first-child th:first-child': {
      borderTopLeftRadius: '4px',
    },
    '& tr:first-child th:last-child': {
      borderTopRightRadius: '4px',
    },
    '& tr:last-child td:first-child': {
      borderBottomLeftRadius: '4px',
    },
    '& tr:last-child td:last-child': {
      borderBottomRightRadius: '4px',
    },
  },
  metadataKeyCell: {
    paddingLeft: '10px',
    paddingRight: '22px',
  },
  metadataKeyCellFieldName: {
    paddingLeft: '8px',
  },
  metadataKey: {
    display: 'flex',
    alignItems: 'center',
  },
  metadataKeyIcon: {
    marginRight: '12px',
    '&:hover': {
      backgroundColor: '#f3f3f3',
    },
  },
  metadataKeyText: {
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editableCell: {
    paddingLeft: '12px',
  },
  deleteFieldMenuSection: {
    marginTop: '20px',
  },
  deleteFieldMenuTitle: {
    marginLeft: '12px',
    marginBottom: '6px',
  },
  menuItem: {
    paddingLeft: '12px',
    paddingRight: '12px',
    alignItems: 'center',
  },
  fieldNameHeader: {
    paddingLeft: '38px',
  },
  dragIcon: {
    cursor: 'grab',
  },
})

const transformMetadataToFieldOrder = (metadataLocal, order = null) => ({
  field_id: metadataLocal.id,
  reserved: metadataLocal.reserved,
  order,
})

const useEditableCellStyles = makeStyles({
  container: {
    height: '36px',
    paddingLeft: (props) => (props.isName ? '8px' : '12px'),
    cursor: 'pointer',
    marginTop: (props) => props.isKey && '1px',
    marginRight: '16px',
  },
  value: {
    padding: 0,
  },
  valueHovered: {
    backgroundColor: '#f3f3f3',
  },
  valueInput: {
    margin: 0,
    marginTop: '1px',
  },
  valueClearInputIcon: {
    color: '#e0e0e0',
    '&:hover': {
      color: '#e0e0e0',
    },
  },
})

function MetadataRow({
  label,
  index,
  value,
  building,
  buildingMetadata,
  updateBuilding,
  updateCustomField,
  deleteCustomField,
  renameCustomField,
  setFieldMenuAnchorEl,
  moveField,
  saveFieldOrder,
  uniqueFieldNames,
}) {
  const classes = useStyles()
  const editableCellKeyClasses = useEditableCellStyles({ isKey: true })
  const editableCellClasses = useEditableCellStyles()
  const modelFieldName = useMemo(
    () => getModelFieldNameByDisplayName(label),
    [label]
  )
  const customField = building.custom_fields.find((cf) => cf.name === label)
  const fieldType = useMemo(() => {
    const field = buildingMetadata.find((entry) => entry.name === label)
    return field.dataType
  }, [buildingMetadata, label])
  const IconClass = getIconByDataTypeId(fieldType.id)

  const updateValue = (newValue) => {
    if (modelFieldName) {
      // updating a regular field
      updateBuilding({ [modelFieldName]: newValue })
    } else if (newValue !== '') {
      // updating a custom field
      updateCustomField({
        ...customField,
        custom_field: {
          ...customField.custom_field,
          value: newValue,
        },
      })
    } else {
      deleteCustomField(customField.id)
    }
  }

  const openMenu = (event) =>
    setFieldMenuAnchorEl({
      anchor: event.currentTarget,
      fieldName: label,
      buildingName: getBuildingPrimaryName(building),
      modelFieldName,
      fieldId: customField ? customField.id : null,
      fieldType: fieldType.id,
      buildingFieldValue: value,
    })

  const ref = useRef(null)
  const previewRef = useRef(null)
  const ItemTypes = {
    CARD: 'card',
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      moveField(dragIndex, hoverIndex)
      // eslint-disable-next-line
      item.index = hoverIndex
    },
    drop: () => {
      saveFieldOrder()
    },
  })
  const opacity = isDragging ? 0 : 1
  drag(ref)
  drop(previewRef)
  preview(previewRef)

  return (
    <TableRow
      ref={previewRef}
      data-handler-id={handlerId}
      style={{
        opacity,
      }}
    >
      <TableContentCell
        classes={{ tableCell: classes.metadataKeyCellFieldName }}
      >
        <div className={classes.metadataKey}>
          <div ref={ref} style={{ display: 'flex', paddingRight: '6px' }}>
            <DragIcon className={classes.dragIcon} />
          </div>
          <SquareIconButton onClick={openMenu}>
            <IconClass />
          </SquareIconButton>
          {customField ? (
            <EditableCell
              value={label}
              updateValue={() => {}}
              updateValueApi={(newName) =>
                new Promise((resolve) => {
                  if (newName && !uniqueFieldNames.has(newName)) {
                    renameCustomField(customField.id, newName)
                    resolve(newName)
                  } else {
                    resolve()
                  }
                })
              }
              classesIn={editableCellKeyClasses}
              textContentVariant="bodyBold"
            />
          ) : (
            <Typography variant="bodyBold" className={classes.metadataKeyText}>
              {label}
            </Typography>
          )}
        </div>
      </TableContentCell>
      <TableContentCell classes={{ tableCell: classes.editableCell }}>
        <EditableCell
          value={value}
          updateValue={updateValue}
          updateValueApi={(newValue) =>
            new Promise((resolve) => {
              updateValue(newValue)
              resolve(newValue)
            })
          }
          fieldType={fieldType.id}
          classesIn={editableCellClasses}
        />
      </TableContentCell>
    </TableRow>
  )
}

export default function EditBuildingModal({
  surveyId,
  surveyBuilding,
  building,
  mutate,
  updateBuilding,
  createCustomField,
  updateCustomField,
  deleteCustomField,
  renameCustomField,
  changeCustomFieldDataType,
  setFieldOrder,
  ModalComponentProps,
}) {
  const classes = useStyles()
  const editableCellClasses = useEditableCellStyles()

  // store building state locally for a few values, so we can debounce updates
  const [buildingData, setBuildingData] = useState(building)
  const buildingPrimaryName = useMemo(
    () => getBuildingPrimaryName(buildingData),
    [buildingData]
  )

  // TODO: do we need this?
  const [newAddressValues, setNewAddressValues] = useState(building)
  const [addressErrorText, setAddressErrorText] = useState('')

  const [showAddFromAnotherModal, setShowAddFromAnotherModal] = useState(false)
  const [addingNewField, setAddingNewField] = useState(false)
  const [newMetadataProperOrdered, setNewMetadataProperOrdered] = useState(
    building.metadata
  )
  const uniqueFieldNames = new Set(
    newMetadataProperOrdered.map((of) => of.name)
  )

  useEffect(() => {
    setNewMetadataProperOrdered(building.metadata)
  }, [building.metadata])

  const debouncedUpdateBuilding = useCallback(
    debounce(
      (newData, updateBuildingLocal) => updateBuildingLocal(newData),
      1000
    ),
    []
  )

  const updateBuildingData = (newData) => {
    setBuildingData({ ...buildingData, ...newData })
    debouncedUpdateBuilding(newData, updateBuilding)
  }

  const menuState = usePopupState({
    variant: 'popover',
    popupId: 'add-field-menu',
  })

  const {
    setAnchorMenuEl: setFieldMenuAnchorEl,
    SpaceFieldMenuComponent: FieldMenuComponent,
    DeleteSpaceFieldModalComponent: DeleteFieldModalComponent,
    ConfirmChangeFieldTypeModalComponent,
  } = useFieldMenu({
    isBuildingField: true,
    deleteField: ({ modelFieldName, fieldId }) => {
      if (modelFieldName) {
        // Delete a regular field
        return updateBuilding({ [modelFieldName]: null })
      }
      // Delete a custom field
      return deleteCustomField(fieldId)
    },
    changeCustomFieldDataType,
  })

  const saveFieldOrder = useCallback(async () => {
    const localNewFieldOrder = newMetadataProperOrdered.map((m, index) =>
      transformMetadataToFieldOrder(m, index)
    )
    setFieldOrder({ fieldOrder: localNewFieldOrder })
  }, [setFieldOrder, newMetadataProperOrdered])

  const moveField = useCallback(
    (dragIndex, hoverIndex) => {
      setNewMetadataProperOrdered((a) => arrayMove(a, dragIndex, hoverIndex))
    },
    [setNewMetadataProperOrdered]
  )

  const [selectedTab, setSelectedTab] = useState(0)

  useStupidRefresh()

  return (
    <ModalComponent
      classesIn={{ dialogContent: classes.modal }}
      fullScreen
      {...ModalComponentProps}
    >
      <FullScreenTitle
        title={buildingPrimaryName}
        onClose={ModalComponentProps.onClose}
      />
      <div className={classes.body}>
        <div className={classes.innerBody}>
          <Typography className={classes.title} variant="pageTitle">
            Building Details
          </Typography>
          <Tabs
            className={classes.tabs}
            value={selectedTab}
            onChange={(_, newIndex) => setSelectedTab(newIndex)}
            indicatorColor="primary"
          >
            <Tab className={classes.tab} label="Overview" />
            <Tab className={classes.tab} label="Location" />
          </Tabs>
          {selectedTab === 0 && (
            <div>
              <div className={classes.formArea}>
                <AddressAutocomplete
                  autoFocus={false}
                  label="Address"
                  error={!!addressErrorText}
                  helperText={addressErrorText}
                  fetchFreshBuildingData={false}
                  controlledValue={newAddressValues}
                  onBlur={() => {
                    if (!newAddressValues.address) {
                      setNewAddressValues(building)
                    }
                  }}
                  setAddressValues={(newValues) => {
                    setAddressErrorText('')
                    setNewAddressValues(newValues)
                    if (newValues.address) {
                      Api.updateBuildingAddress({
                        id: building.id,
                        body: {
                          ...newValues,
                        },
                      }).then((res) => {
                        if (res.data.error) {
                          setAddressErrorText(res.data.error)
                        } else {
                          setBuildingData({ ...buildingData, ...res.data })
                          updateBuilding({ ...res.data })
                        }
                      })
                    }
                  }}
                />
                <TextInput
                  label="Building Name"
                  onChange={(e) => updateBuildingData({ name: e.target.value })}
                  value={buildingData.name}
                />
                <TextInput
                  label="Notes"
                  multiline
                  rows={6}
                  onChange={(e) =>
                    updateBuildingData({ description: e.target.value })
                  }
                  value={buildingData.description}
                />
              </div>
              <div className={classes.tableArea}>
                <div className={classes.tableAreaHeader}>
                  <Typography variant="boldText">
                    {getPlural(newMetadataProperOrdered.length, 'Field')}
                  </Typography>
                  <Button
                    {...bindTrigger(menuState)}
                    className={classes.addFieldButton}
                    classes={{ endIcon: classes.addFieldButtonIcon }}
                    color="primary"
                    endIcon={<TextChevronDown />}
                  >
                    Add a Field
                  </Button>
                  <CustomFieldMenu
                    classes={{ paper: classes.addFieldMenu }}
                    {...bindMenu(menuState)}
                    onClickCreateNew={() => {
                      setAddingNewField(true)
                      menuState.close()
                    }}
                    onClickAddAnother={() => {
                      setShowAddFromAnotherModal(true)
                      menuState.close()
                    }}
                    type="building"
                    elevation={2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                  />
                </div>
                <TableContainer>
                  <Table size="small" className={classes.metadataTable}>
                    <colgroup>
                      <col style={{ width: 'calc(50% - 16px)' }} />
                      <col style={{ width: 'calc(50% - 16px)' }} />
                    </colgroup>
                    <TableHead>
                      <TableHeaderRow>
                        <TableHeaderCell
                          classes={{ tableCell: classes.fieldNameHeader }}
                        >
                          <TableHeaderText>FIELD NAME</TableHeaderText>
                        </TableHeaderCell>
                        <TableHeaderCell>
                          <TableHeaderText>FIELD VALUE</TableHeaderText>
                        </TableHeaderCell>
                      </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                      <DndProvider backend={BACKEND_FOR_DND}>
                        {newMetadataProperOrdered.map((entry, index) => (
                          <MetadataRow
                            label={entry.name}
                            value={entry.value}
                            index={index}
                            building={building}
                            buildingMetadata={newMetadataProperOrdered}
                            updateBuilding={(newValue) =>
                              updateBuilding(newValue)
                            }
                            updateCustomField={updateCustomField}
                            deleteCustomField={deleteCustomField}
                            renameCustomField={renameCustomField}
                            setFieldMenuAnchorEl={setFieldMenuAnchorEl}
                            moveField={moveField}
                            saveFieldOrder={saveFieldOrder}
                            uniqueFieldNames={uniqueFieldNames}
                          />
                        ))}
                      </DndProvider>
                      {addingNewField && (
                        <AddCustomFieldTableRow
                          classes={{
                            metadataKeyCell: classes.metadataKeyCell,
                            editableCell: classes.editableCell,
                          }}
                          objectIds={[building.id]}
                          editableCellClasses={editableCellClasses}
                          createCustomFields={(newCustomFieldData) => {
                            if (
                              newCustomFieldData.name &&
                              !uniqueFieldNames.has(newCustomFieldData.name)
                            ) {
                              createCustomField(
                                newCustomFieldData.name,
                                newCustomFieldData.valueMap[building.id]
                              )
                            }
                            setAddingNewField(false)
                          }}
                          setFieldMenuAnchorEl={setFieldMenuAnchorEl}
                        />
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Amenities
                amenities={buildingData.amenities}
                updateAmenities={(newAmenities) => {
                  updateBuildingData({ amenities: newAmenities })
                }}
              />
            </div>
          )}
          {selectedTab === 1 && (
            <EditLocationForm
              building={buildingData}
              padding="30px 0px"
              onChange={updateBuildingData}
            />
          )}
        </div>
      </div>
      <Modal
        content={MODALS.ADD_FIELDS_FROM_ANOTHER}
        onClose={() => setShowAddFromAnotherModal(false)}
        open={showAddFromAnotherModal}
        childProps={{
          // this modal handles updating the building on the backend,
          // mutate just refreshes the local SWR data
          updateCustomFields: (updatedBuildings) =>
            mutate({
              ...surveyBuilding,
              building: { ...updatedBuildings[0] },
            }),
          parentObjectIds: [building.id],
          surveyId,
          type: 'building',
        }}
      />
      {FieldMenuComponent}
      {DeleteFieldModalComponent}
      {ConfirmChangeFieldTypeModalComponent}
    </ModalComponent>
  )
}
